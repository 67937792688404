const REPLICAR_API_URL = process.env.REACT_APP_SERVER_URL

const REPLICAR_API_KHWARIZMI_URL = process.env.REACT_APP_KHWARIZMI_SERVER_URL

const REPLICAR_API_EDUCAGENT_URL = process.env.REACT_APP_EDUCAGENT_SERVER_URL


// API_KHWARIZMI_URL -------------------------------------------------------------------------

export const GET_SHOW_ODDS_MAPS = `${REPLICAR_API_KHWARIZMI_URL}/aai-map-library/region-image`

// API_KHWARIZMI_URL -------------------------------------------------------------------------

// Platform_URL -------------------------------------------------------------------------

/**
 * User Guide
 */
export const userGuide = `/user-guide/user_guide.html`

/**
 * User Authentication
 */
export const LOGIN_ENDPOINT = `${REPLICAR_API_URL}/login`
export const REGISTRATION_ENDPOINT = `${REPLICAR_API_URL}/signup`
export const PASSWORD_FORGOT_ENDPOINT = `${REPLICAR_API_URL}/login/recover_password`
export const Logout = `${REPLICAR_API_URL}/logout`

/**
 * Project
 */
export const GET_ALL_PROJECT_LIST_PROJECT = `${REPLICAR_API_URL}/project`
export const CREATE_PROJECT = `${REPLICAR_API_URL}/project`
export const PROJECT_TRACEBILITY = `${REPLICAR_API_URL}/project_trace`

/**
 * Simulation
 */
export const COSIM_CLIENT = `${REPLICAR_API_URL}/cosim-client`
export const CLIENTS_BUILD_DOWNLOAD = `${REPLICAR_API_URL}/replicar_downloadables`


/**
 * My Scenario
 */
export const GET_PROJECT_LIST_MYSCENARIO = `${REPLICAR_API_URL}/my_scenario`
export const GET_PROJECT_EXEC_LIST_MYSCENARIO = `${REPLICAR_API_URL}/extracted_scenario_list`
export const GET_PROJECT_STATUS_MYSCENARIO = `${REPLICAR_API_URL}/app-state`
export const GET_MAP_GID_LIST = `${REPLICAR_API_URL}/map_gid`;
export const GET_VEHICLE_ATTACHEMENT = `${REPLICAR_API_URL}/vehicle_attachment`

/**
 * Test suits
 */
export const TEST_SUITS = `${REPLICAR_API_URL}/test_suite`
export const CARLA = `${REPLICAR_API_URL}/carla_hf_execution`
export const CARLA_EXECUTION = `${REPLICAR_API_URL}/carla_hf_download`
export const TEST_SUITS_SCENARIOS = `${REPLICAR_API_URL}/test_suite_list`
export const TEST_SUITE_EXPORT = `${REPLICAR_API_URL}/start_test_suite_export`
export const GET_PROJECT_STATUS = `${REPLICAR_API_URL}/app-state`


/**
 * Low Fidelity
 */
export const LOW_FIDELITY_LIST = `${REPLICAR_API_URL}/low_fidelity_list`

// export const Get_ALL_Tests_Count = `${REPLICAR_API_URL}/job_mgmt/all/count`
// export const serverSentEvents = `${REPLICAR_API_URL}/notifications`
export const FETCH_ROUTE = `${REPLICAR_API_URL}/route_to_odr`
export const FETCH_VEHICLES = `${REPLICAR_API_URL}/vehicle_profile`
export const FETCH_ODDS = `${REPLICAR_API_URL}/route_to_odd`
export const GET_ODD_IMAGES = `${REPLICAR_API_URL}/odd_image`
export const FETCH_ODD_KEYWORDS = `${REPLICAR_API_URL}/odd_keywords`
export const ODD_TO_GEOJSON = `${REPLICAR_API_URL}/odd_to_geojson`

// API_KHWARIZMI_URL 
export const ODD_TO_GEOJSON_KHWARIZMI_URL = `${REPLICAR_API_KHWARIZMI_URL}/aai-map-library/region-geojson-layers`


export const GET_ALL_AVAILABILITY_ZONES = `${REPLICAR_API_URL}/availability_zone`
export const BBOX_TO_ODD = `${REPLICAR_API_URL}/bbox_to_odd`
export const GET_USER_MAPS = `${REPLICAR_API_URL}/map`
export const START_MY_SCENARIO = `${REPLICAR_API_URL}/start_my_scenario`

/**
 * Auto Scenario
*/
export const AUTO_SCENARIO_MAIN = `${REPLICAR_API_URL}/auto_scenario`
export const START_AUTO_SCENARIO = `${REPLICAR_API_URL}/start_auto_scenario`
export const GET_PROJECT_EXEC_LIST_AUTO_SCENARIO = `${REPLICAR_API_URL}/extracted_auto_scenario_list`;
export const GET_AUTOSCENARIO_VEHICLE_LIST = `${REPLICAR_API_URL}/vehicle_profile`;
// export const Get_ALL_Tests_Count = `${REPLICAR_API_URL}/job_mgmt/all/count`
// export const serverSentEvents = `${REPLICAR_API_URL}/notifications`


/**
 * EducAgent
*/
export const EDUC_AGENT_MAIN = `${REPLICAR_API_URL}/educ_agent`
export const TENSOR_BOARD = `${REPLICAR_API_EDUCAGENT_URL}/tensorboard`

/**
 * HighFidelity
*/
export const HIGH_FIDELITY_MAIN = `${REPLICAR_API_URL}/high_fidelity`
export const HIGH_FIDELITY_TRIGGER_DAG = `${REPLICAR_API_URL}/low_fidelity_execute`
export const HIGH_FIDELITY_COSIM_CLIENTS = `${REPLICAR_API_URL}/live-cosim-clients`
export const HIGH_FIDELITY_START = `${REPLICAR_API_URL}/start_hf_execution`
export const HIGH_FIDELITY_EXECUTION_JOB = `${REPLICAR_API_URL}/execution-job`

/**
 * Test Vehicle
*/
export const TEST_VEHICLE_PROJECT = `${REPLICAR_API_URL}/test_vehicle`
export const GET_TEST_VEHICLE_PROJECT = `${REPLICAR_API_URL}/test_vehicle`

/**
 * Co-simulation Dspace
*/
export const GET_DSPACE_TEST_PROJECT = `${REPLICAR_API_URL}/dspace_test`
export const GET_DSPACE_USER_MAPS = `${REPLICAR_API_URL}/dspace_map`
export const GET_VEHICLE_COMPOSITION_LIST = `${REPLICAR_API_URL}/dspace_driver_profile`;
export const GET_VEHICLE_LIST = `${REPLICAR_API_URL}/dspace_vehicle_profile`;

// export const Get_ALL_Tests_Count = `${REPLICAR_API_URL}/job_mgmt/all/count`
// export const serverSentEvents = `${REPLICAR_API_URL}/notifications`

/**
 * MAP Generation
*/
// export const MAP_GENERATION_PROJECT = `${REPLICAR_API_URL}/map_generation`
export const MAP_APP_PROJECT = `${REPLICAR_API_URL}/map_app`
export const MAP_GENERATION_PROJECT = `${REPLICAR_API_URL}/map_generation`
export const VERSATILE_MAP = `${REPLICAR_API_URL}/versatile_map`
export const MAP_STATUS = `${REPLICAR_API_URL}/map_status`
export const SELECTED_MAP_STATUS = `${REPLICAR_API_KHWARIZMI_URL}/aai-map-library/geojson-from-gids`

// export const Get_ALL_Tests_Count = `${REPLICAR_API_URL}/job_mgmt/all/count`
// export const serverSentEvents = `${REPLICAR_API_URL}/notifications`

/**
 * ODD
*/
export const ODD_APP_ENDPOINT = `${REPLICAR_API_URL}/odd_app`
export const ODD_REQUIREMENT_ENDPOINT = `${REPLICAR_API_KHWARIZMI_URL}/odd-requirement/`
export const ODD_MULTI_POINT_ENDPOINT = `${REPLICAR_API_KHWARIZMI_URL}/mapbox/multi-point-routing`
export const ODD_ZONE_AVAILABILITY_ENDPOINT = `${REPLICAR_API_KHWARIZMI_URL}/aai-map-library/regions-in-zone`
export const ODD_SELECTED_ZONE_AVAILABILITY_ENDPOINT = `${REPLICAR_API_KHWARIZMI_URL}/aai-map-library/geojson-from-gids`
export const ODD_TAXONOMIES = `${REPLICAR_API_KHWARIZMI_URL}/odd-taxonomies`

/**
 * General Endpoint
*/
export const TRAFFIC_PROFILE_LIST = `${REPLICAR_API_URL}/traffic_profiles`
export const GET_TEST_VEHICLE_PROFILE = `${REPLICAR_API_URL}/vehicle_profile`
export const GET_CODE_FOR_OBSERVER = `${REPLICAR_API_URL}/observer_sample`
export const POST_CODE_FOR_OBSERVER = `${REPLICAR_API_URL}/observer`
export const GET_CODE_FOR_OBSERVER_METRIC = `${REPLICAR_API_URL}/observer_metric`
export const GET_CODE_FOR_OBSERVER_RULE_METRIC = `${REPLICAR_API_URL}/observer_rule_metric`

export const GET_RIL_EGO_FEATURE = `${REPLICAR_API_URL}/ril_ego_feature`
export const GET_RIL_PERPCETION_FEATURE = `${REPLICAR_API_URL}/ril_perception_feature`
export const GET_RIL_MAP_FEATURE = `${REPLICAR_API_URL}/ril_map_feature`

export const GET_SEN_SIM_DATA = `${REPLICAR_API_URL}/sen_vironment`
export const GET_SYNTH_SIM_DATA = `${REPLICAR_API_URL}/synth_data`
export const GET_REAL_SIM_DATA = `${REPLICAR_API_URL}/real_sim`
export const GET_EXTRACTED_SCENARIO_DATA = `${REPLICAR_API_URL}/extracted_scenario`

export const GET_RIL_REWARD_INTERFACES = `${REPLICAR_API_URL}/get_reward_doc_string`
export const GET_RIL_REWARD_FUNC_LIST = `${REPLICAR_API_URL}/get_reward_function_names`
export const POST_RIL_REWARD_FUNC_LIST = `${REPLICAR_API_URL}/validate_reward_functions`
export const GET_RIL_REWARD_FUNC_CODE = `${REPLICAR_API_URL}/get_reward_definition_by_name`
export const GET_PRE_DEFINE_FUNC_CODE = `${REPLICAR_API_URL}/get_predefined_test_cases_for_reward_functions`

export const GET_RIL_TEST_CODE = `${REPLICAR_API_URL}/get_testcases_doc_string`
export const POST_RIL_TEST_FUNCTION = `${REPLICAR_API_URL}/run_test_cases`
export const GET_RIL_TEST_FUNCTION = `${REPLICAR_API_URL}/rl_interface/testcases_code` // need to be remove not using but not sure

export const GET_RIL_HYPER_PARAMETER_INTERFACE = `${REPLICAR_API_URL}/get_hyperparameter_template` 
export const POST_RIL_HYPER_PARAMETER_INTERFACE = `${REPLICAR_API_URL}/validate_hyperparameters`
export const GET_FEATURE_LIST = `${REPLICAR_API_URL}/get_features_description`
export const GET_RL_TENSOR_BOARD = `${REPLICAR_API_URL}/tensorboard`
export const POST_RL_START_TRAINING = `${REPLICAR_API_URL}/start_training`

export const GET_OBSTACLE_LIST = `${REPLICAR_API_URL}/static_obstacle`

// export const Get_ALL_Tests_Count = `${REPLICAR_API_URL}/job_mgmt/all/count`
// export const serverSentEvents = `${REPLICAR_API_URL}/notifications`

/**
 * Scenario Execution 
*/
export const SCENARIO_EXECUTION_ENDPOINT = `${REPLICAR_API_URL}/scenario_execution`




/**
 * Dashboard
*/
export const GET_ALL_PROJECT_LIST = `${REPLICAR_API_URL}/job_mgmt/all/list`
export const Get_ALL_Tests_Count = `${REPLICAR_API_URL}/job_mgmt/all/count`
export const serverSentEvents = `${REPLICAR_API_URL}/notifications`

/**
 * FIles
*/
export const Check_Map_Availability = `${REPLICAR_API_URL}/check_file_availability/odr-map-file/`
export const Download_Map_for_OdrViewer = `${REPLICAR_API_URL}/download_file/odr-map-file/`

/**
 * Vehicle Controllers and Profiles
*/
export const Ego_Controllers = `${REPLICAR_API_URL}/ego_controller`
export const Get_RoadUser_Profiles = `${REPLICAR_API_URL}/vehicle_profile`
export const RoadUser_Types = `${REPLICAR_API_URL}/vehicle_profile_type`

/**
 * Job/Operations
*/
export const Test_States = `${REPLICAR_API_URL}/job_state`
export const Test_Types = `${REPLICAR_API_URL}/job_type`
export const Start_Test = `${REPLICAR_API_URL}/job_op/start`
export const Stop_Test = `${REPLICAR_API_URL}/job_op/stop`
export const Duplicate_Test = `${REPLICAR_API_URL}/job_op/duplicate`
export const Analyze_Test = `${REPLICAR_API_URL}/job_op/analyze`
export const Extract_Scenarios_From_Test = `${REPLICAR_API_URL}/job_mgmt/extraction_job/create`
export const SCM_EXTRACTED_JOBS_DELETE_API = `${REPLICAR_API_URL}/scenario_extraction/delete`
export const SCM_GET_EXTRACTED_SCENARIOS_DATA_TABLEDATA_API = `${REPLICAR_API_URL}/job_mgmt/extraction_job/list-scenarios`
export const SCM_GET_EXTRACTION_SUMMARY_GRAPH_DATA = `${REPLICAR_API_URL}/job_op/extraction_summary`
export const Create_Traffic_Analysis_Job = `${REPLICAR_API_URL}/job_mgmt/traffic_analysis_job/create`
export const Delete_Extraction_Job = `${REPLICAR_API_URL}/job_mgmt/extraction_job/delete`

/**
 * Endurance Test
 */
export const Endurance_Test = `${REPLICAR_API_URL}/job_mgmt/endurance_test/create`
export const Endurance_Test_Details = `${REPLICAR_API_URL}/job_mgmt/endurance_test/record`
export const Delete_Endurance_Test = `${REPLICAR_API_URL}/job_mgmt/endurance_test/delete`
export const TrafficConfigPresets = `${REPLICAR_API_URL}/traffic_profiles`
export const Visualize3dEnduranceTest = `${REPLICAR_API_URL}/analyze`
export const Analyzer_Cloud_EnduranceTest = `${REPLICAR_API_URL}/visualize`

/**
 * Scenario Test
*/
export const SCM_HOME_SCENARIOS_API = `${REPLICAR_API_URL}/scenario/xosc`
export const Scenario_Test = `${REPLICAR_API_URL}/job_mgmt/scenario_test/create`
export const Scenario_Test_Details = `${REPLICAR_API_URL}/job_mgmt/scenario_test/record`
export const Delete_Scenario_Test = `${REPLICAR_API_URL}/job_mgmt/scenario_test/delete`

/**
 * Map
*/
export const Endurance_Maps = `${REPLICAR_API_URL}/map`
export const Endurance_MapImage = `${REPLICAR_API_URL}/map_image`
export const Endurance_MapImport = (testType) => `${REPLICAR_API_URL}/import_map/${testType}`

/**
 * Weather
*/
export const Endurance_Weathers = `${REPLICAR_API_URL}/weather_condition`
export const Endurance_Weather_Severities = `${REPLICAR_API_URL}/weather_severity`

/**
 * Sensors
 */
export const Add_Camera_Sensor = `${REPLICAR_API_URL}/camera_sensor`
export const Camera_Sensor_Setups = `${REPLICAR_API_URL}/sensor_setup`

/**
 * Apps
 */
export const Map_Editor = `${REPLICAR_API_URL}/map_editor`
export const Map_Generator = `${REPLICAR_API_URL}/map_generator`
export const Sensor_Calibration_Room = `${REPLICAR_API_URL}/calibration_room`
export const Scene_Generator = `${REPLICAR_API_URL}/scene_generator`

/**
 * Car Maker
 */
export const Car_Maker_Ego_Model = `${REPLICAR_API_URL}/import_ego`
export const Car_Maker_Ego_Model_Select = `${REPLICAR_API_URL}/select_ego`
export const Car_Maker_init = `${REPLICAR_API_URL}/init_project`
export const Car_Maker_Test_Run = `${REPLICAR_API_URL}/job_mgmt/carmaker_test/create`
export const Car_Maker_Test_Run_Path = `${REPLICAR_API_URL}/job_mgmt/carmaker_test/record`
export const Car_Maker_Path_Of_Last_Project = `${REPLICAR_API_URL}/carmaker_project_path`
export const Car_Maker_vehicle_Models = `${REPLICAR_API_URL}/carmaker_vehicle_profile`
export const Delete_CarMaker_Test = `${REPLICAR_API_URL}/job_mgmt/carmaker_test/delete`
export const Car_Maker_Test_Details = `${REPLICAR_API_URL}/job_mgmt/carmaker_test/record`


/**
 * MapValidator
 */
export const ODD_TO_GEOJSON_V2 = `${REPLICAR_API_KHWARIZMI_URL}/aai-map-library/region-geojson-layers`;
export const FETCH_ODDS_V2 = `${REPLICAR_API_KHWARIZMI_URL}/aai-map-library/regions-on-route`;
export const GET_SHOW_ODDS_MAPS_V2 = `${REPLICAR_API_KHWARIZMI_URL}/aai-map-library/region-image`;


export const FETCH_ROUTE_V2 = `${REPLICAR_API_KHWARIZMI_URL}/here/routing`;
export const VALIDATE_ODR_GID = `${REPLICAR_API_KHWARIZMI_URL}/here/validate-odr-gid`;

/**
 * Scene Analytics
 */
export const GET_TRACEABILITY = `${REPLICAR_API_URL}/low_fidelity_trace`;
export const GET_MY_SCENARIO_SCENE_ANALYTICS = `${REPLICAR_API_URL}/my_scenario_scen_analytics`;


/**
 * All Builds Download Endpoints
 */

export const VERSATILE_BUILDS = {
    windows:"https://temp-replicar-assets.s3.eu-central-1.amazonaws.com/downloads/v_5_3_2/Versatile-v.1.3.2_Windows_Release.zip",
    linux:"https://temp-replicar-assets.s3.eu-central-1.amazonaws.com/downloads/v_5_3_2/Versatile-v.1.3.2_Linux_Release.zip",
    version:'1.3.2'
}

export const CO_SIM_CLIENT_BUILDS = {
    windows:"",
    linux:"https://temp-replicar-assets.s3.eu-central-1.amazonaws.com/downloads/v_5_3_2/ReplicarR_CoSim_Client_Dev_Release_0.1.1.zip",
    version:''
}