import React, { Fragment, useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import "./interactiveMap.css";
import { SwitchButtonX, CollapseContent, Typos, IconButtonX, SquareImage, ButtonX, ButtonWithIcon, ReadOnlyTag, ModalX } from "components";
import { useNavigate, useParams } from "react-router-dom";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Avatar,
	Checkbox,
	Link,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Skeleton,
	TextField,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip,
	IconButton,
	Grid,
	Button,
	CircularProgress,
	Typography,
	FormGroup,
	FormControlLabel,
	Switch,
} from "@mui/material";
import * as MIcons from "@mui/icons-material";
import { httpGet } from "utils/httpMethods";
import { ProgressX } from "components";
import TEST_VEHICLE from "assets/images/mainVehicle.png";
import { createRoot } from "react-dom/client";
import VirtualizedList from "./virtualizedList";
import { FETCH_VEHICLES } from "constants/endpoints";
import { OmitKeyValuePair, mapTypes, PresentableText, IsValidURL } from "utils";
import { ReduxUpdateMyScenarioJourney } from "services/redux-store/actions/actions-myscenario"
import { connect } from "react-redux";
import { getMapLayers, getObstacleList } from "endpointServices/myScenarioServices";
import STORE from "services/redux-store/store";
import * as turf from '@turf/turf';
import { DEFAULT_PEDESTRIAN_SPEED } from "constants/defaults";
import _ from "lodash";
import { getAllTestVehicleProjects, getTestVehicleDetails } from "endpointServices/testVehicleService";
import { toast } from "react-toastify";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { useCallback } from "react";
import ConfirmationModal from "components/confirmationModal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { Delete as DeleteIcon } from '@mui/icons-material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
  });


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "xyz";

const laneColors = {
	selection: "#00FF00",
	option: "#EFCB00",
};

export const LAYER2 = {
	id: "state-fills",
	type: "fill",
	source: "states",
	layout: {},
	filter: ["==", "$type", "Polygon"],
	paint: {
		// "fill-color": "#0080ff", // blue color fill
		// "fill-opacity": 0.2,
		// "fill-color": "rgba(0, 163, 181, 0.2)",
		"fill-outline-color": "rgba(0, 188, 212, 1)",
		"fill-color": [
			"case",
			["boolean", ["feature-state", "click"], false],
			"#64bdbb", // if selected true, paint in blue
			"#ff0000", // else paint in red
		],
	},
};

const randomColorsForActors = [
	"#008000",
	"#FF7F50",
	"#E6E6FA",
	"#800000"
]


const TEMP_STATIC_OBJECTS = [
	{
		icon: "CakeRounded",
		name: "Cake",
		selected: false,
	},
	{
		icon: "HouseRounded",
		name: "House",
		selected: false,
	},
	{
		icon: "LocationCityRounded",
		name: "Building",
		selected: false,
	},
	{
		icon: "StarRounded",
		name: "Junk",
		selected: false,
	},
	{
		icon: "CategoryRounded",
		name: "Random Object",
		selected: false,
	},
]

const PEDESTRIAN_ALLOWED_CIRCLE_RADIUS = 100 // meters
const MY_SCENARIO_INTERACTION_PANELS = {
	egoRoute: "ego-route-panel",
	actorsRoutes: "actors-routes-panel",
	staticObjects: "static-objects-panel",
	pedestrians: "pedestrian-panel"
}
const ALLOWED_ACTORS_COUNT = 4;
const ALLOWED_STATIC_OBJECTS_COUNT = 10;
const ALLOWED_PEDESTRIAN_COUNT = 2;

const generateNewLayer = (layerId, type, layerType) => {
	return {
		id: "route" + layerId,
		type: layerType === "linestring" ? "line" : "fill",
		source: layerId,
		paint: layerType === "linestring" ? {
			'line-color': ['get', 'stroke'], // Use a data-driven expression to get the color from the GeoJSON properties
			'line-width': ['get', 'stroke-width']
		} : {
			"fill-opacity": 0.7,
			"fill-color": ["get", "fill"], //type.fill, //"rgba(0, 163, 181, 0.2)",
			"fill-outline-color": type.stroke, //"rgba(0, 188, 212, 1)",
		},
	};
};

const InteractiveMap = ({ ReduxUpdateMyScenarioJourney }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [lng, setLng] = useState(13.405252);
	const [lat, setLat] = useState(52.517644);
	const [zoom, setZoom] = useState(8);
	const [geojsonFilesURLs, setGeojsonFilesURLs] = useState(null);
	const [expandedPanel, setExpandedPanel] = useState(false);
	const [showEditingPanel, setShowEditingPanel] = useState(false);
	const [mapFunctionalityActiveButton, setMapFunctionalityActiveButton] = useState("view");
	const [primaryActionActiveButton, setPrimaryActionActiveButton] = useState(null);
	const [loadingEgoTestVehicleDetails, setLoadingEgoTestVehicleDetails] = useState(false);
	const [selectedEgoTestVehicle, setSelectedEgoTestVehicle] = useState(null);
	const [vehiclesListForActors, setVehiclesListForActors] = useState([]);
	const [loadingActorVehicles, setLoadingActorVehicles] = useState(false);
	const [egoTestVehiclesList, setEgoTestVehiclesList] = useState([]);
	const [loadingEgoTestVehicles, setLoadingEgoTestVehicles] = useState(false);
	const [selectedEgoTestVehicleDetails, setSelectedEgoTestVehicleDetails] = useState(null);
	const [secondaryActionActiveButton, setSecondaryActionActiveButton] = useState(null);
	const [savedEgoRoute, setSavedEgoRoute] = useState(null); // will be an object
	const [savedActorsRoutes, setSavedActorsRoutes] = useState([]); // will be an array of objects
	const [currentColor, setCurrentColor] = useState("#000");
	const [showSaveAndDeleteButton, setShowSaveAndDeleteButton] = useState(false)
	const [selectedActorVehicle, setSelectedActorVehicle] = useState(null);
	const [laneIdsLookupTable, setLaneIdsLookupTable] = useState({});
	const [markersOnMapState, setMarkersOnMapState] = useState([]);
	const [showEgoRoute, setShowEgoRoute] = useState(false);
	const [geojsonAsJson, setGeojsonAsJson] = useState(null);
	const [loadingStaticObjects, setLoadingStaticObjects] = useState(false);
	const [staticObsticaleList, setStaticObsticaleList] = useState([]);
	const [showStaticObjects, setShowStaticObjects] = useState(false);
	const [showPedestrianCrossings, setShowPedestrianCrossings] = useState(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	const [allActorsViewState, setAllActorsViewState] = useState([]);
	const [open, setOpen] = useState(false);
	const [selectedFile, setSelectedFile] = useState([]);
	const [fileContent, setFileContent] = useState(null);
	const [usingFile, setUsingFile] = useState(false);
	const [isLoad, setIsLoad] = useState(false);
	const [inputKey, setInputKey] = useState(Date.now()); 

	const map = useRef(null);
	const mapContainer = useRef(null);
	const accelerationTextField = useRef("");
	const allowEditRoute = useRef(false);
	const allowAddAccelerationMarker = useRef(false);
	const vehicleSelectedRoutePolygons = useRef([]);
	const vehicleInitialPosition = useRef(null);
	const vehicleCarLaneChanged = useRef({
		status: false,
		polygon: null,
		location: null,
	});
	const markersOnMap = useRef([]);
	const vehicleAccelerationMarkerAdded = useRef(false);
	const actionMode = useRef(null);
	const egoRoute = useRef(null);
	const pedestrianToFrom = useRef(null);
	const definedPedestrianRoute = useRef([]);
	const selectedStaticObject = useRef(null);
	const activeSelectionColor = useRef("#000");
	const staticObsticaleListRef = useRef([]);

	const { MyScenario, Projects } = STORE.getState();
	const { gid_kwh } = MyScenario.oddData;

	const param = useParams();

	/**
	 * ########################################################################################
	 * # Markers on map handlers
	 * ########################################################################################
	 */

	/**
	 * 
	 * @param {object} lnglat: object with {lng, lat} keys
	 * @param {object} polygon: relevant polygon
	 * @param {object} destination: map object
	 */
	const _addVehicleCarMarker = (lnglat, polygon, destination = map.current) => {
		const ref = React.createRef();
		ref.current = document.createElement("div");
		const root = createRoot(ref.current);
		const element = <img src={TEST_VEHICLE} style={{ width: 40, height: 40 }} alt="icon"/>; //<DirectionsCarRounded />;
		root.render(element);
		const newMarker = new mapboxgl.Marker(ref.current).setLngLat(lnglat).addTo(destination);
		_saveMarkerInfo(lnglat, polygon, "vehicle", newMarker);
	};
	/**
	 * 
	 * @param {object} lnglat: object with {lng, lat} keys
	 * @param {string} laneChangeDirection: expected r2l or l2r
	 * @param {object} polygon: relevant polygon object
	 * @param {object} destination: map object
	 */
	const _addLaneChangeIcon = (lnglat, laneChangeDirection, polygon, destination = map.current) => {
		const ref = React.createRef();
		ref.current = document.createElement("div");
		const root = createRoot(ref.current);
		const element = laneChangeDirection === "l2r" ? <MIcons.TurnSharpRightRounded /> : <MIcons.TurnSharpLeftRounded />;
		root.render(element);
		const newMarker = new mapboxgl.Marker(ref.current, {
			color: "#000",
			// draggable: true,
		})
			.setLngLat(lnglat)
			.addTo(destination);
		_saveMarkerInfo(lnglat, polygon, "lane-change", newMarker, { laneChangeDirection });
	};

	/**
	 * 
	 * @param {object} lnglat: object with {lng, lat} keys
	 * @param {int} acceleration: integer value 
	 * @param {object} polygon: relevant polygon object
	 * @param {object} destination: map object
	 */
	const _addAccelerationIcon = (lnglat, acceleration, polygon, destination = map.current) => {
		const element = (
			<div className={``}>
				<MIcons.SpeedRounded />
				{acceleration} m/s^2
			</div>
		);
		const ref = React.createRef();
		ref.current = document.createElement("div");
		const root = createRoot(ref.current);
		root.render(element);
		const newMarker = new mapboxgl.Marker(ref.current, {
			color: "#000",
			// draggable: true,
		})
			.setLngLat(lnglat)
			.addTo(destination);
		_saveMarkerInfo(lnglat, polygon, "acceleration", newMarker, { acceleration });
		accelerationTextField.current = "";
	};

	/**
	 * Function not used right now - actor marker is not placed anywhere
	 * @param {object} lnglat: object with {lng, lat} keys
	 * @param {object} polygon: relevant polygon
	 * @param {object} destination: map object
	 */
	const _addActorMarker = (lnglat, polygon, destination = map.current) => {
		const ref = React.createRef();
		ref.current = document.createElement("div");
		const root = createRoot(ref.current);
		root.render(<MIcons.DirectionsBusFilledRounded />);
		const newMarker = new mapboxgl.Marker(ref.current, {
			color: "#000",
			draggable: true,
		})
			.setLngLat(lnglat)
			.addTo(destination);
		_saveMarkerInfo(lnglat, polygon, "actor", newMarker);
		// add newMarker to actorslist
	};

	/**
	 * 
	 * @param {object} lnglat: object with {lng, lat} keys
	 * @param {string} icon: MUI icon name as string
	 * @param {object} polygon: relevant polygon 
	 * @param {string} type @enum {"pedestrian-start", "pedestrian-end", "static-object"} 
	 * @param {object} metadata: Any extra data to save 
	 * @param {object} destination: map object
	 */
	const _addRandomMarkerIcon = (lnglat, icon, polygon, type, metadata = null, destination = map.current) => {
		const ref = React.createRef();
		ref.current = document.createElement("div");
		const root = createRoot(ref.current);
		const Ico = MIcons[icon];
		const element = <Ico />;
		root.render(element);
		const newMarker = new mapboxgl.Marker(ref.current).setLngLat(lnglat).addTo(destination);
		_saveMarkerInfo(lnglat, polygon, type, newMarker, { icon, ...metadata });
	}

	/**
	 * 
	 * @param {object} lnglat: object with {lng, lat} keys 
	 * @param {string} iconUrl: url as a string to fetch as img 
	 * @param {object} polygon: relevant polygon 
	 * @param {string} type @enum {"static-object"} 
	 * @param {object} metadata: Any extra data to save  
	 * @param {object} destination: map object 
	 */
	const _addCustomMarkerIcon = (lnglat, iconUrl, polygon, type, metadata = null, destination = map.current) => {
		const ref = React.createRef();
		ref.current = document.createElement("div");
		const root = createRoot(ref.current);
		// const Ico = MIcons[icon];
		const element = <img src={iconUrl} style={{ width: 35, height: 35 }} alt="icon"/>;
		root.render(element);
		const newMarker = new mapboxgl.Marker(ref.current).setLngLat(lnglat).addTo(destination);
		_saveMarkerInfo(lnglat, polygon, type, newMarker, { icon: iconUrl, ...metadata });
	}

	/**
	 * @private {Internal function} Used to save marker info in an array state
	 * @param {object} lnglat: object with {lng, lat} keys  
	 * @param {object} polygon: relevant polygon 
	 * @param {string} saveFor @enum {"pedestrian-start", "pedestrian-end", "static-object"}  
	 * @param {object} marker: Marker raw object 
	 * @param {object} additional: Any extra data to save   
	 */
	const _saveMarkerInfo = (lnglat, polygon, saveFor, marker, additional = null) => {
		const temp = {
			id: Date.now(),
			lnglat: lnglat,
			marker: marker,
			type: saveFor,
			polygon: polygon,
			additional: additional,
		};
		// we save marker in both state and a ref
		// ref is for mapbox bcz it does not responds to state changes in react
		// state is for react bcz react does not responds to ref value changes
		markersOnMap.current = [...markersOnMap.current, { ...temp }];
		setMarkersOnMapState([...markersOnMap.current]);
	};

	/**
	 * ########################################################################################
	 * # Click on map handlers
	 * ########################################################################################
	 */

	/**
	 * @todo Function for ego route - not used right now - functionality handled in _calculateClickEvent
	 */
	// const __handlePlaceEgoRoute = () => {}

	/**
	 * @todo Function for actor route - not used right now - functionality handled in _calculateClickEvent
	 */
	// const __handlePlaceActorsRoute = () => {}

	/**
	 * Saves the marker with its location after checking if its placed on ego route
	 * @param {event} e click event object
	 * @returns nothing
	 */
	const __handlePlaceStaticObject = (e) => {
		const polygon = e.features[0];
		// console.log('polygon', polygon);
		if (markersOnMap.current.filter((item) => item.type === "static-object").length >= ALLOWED_STATIC_OBJECTS_COUNT) {
			return;
		}
		// console.log('egoRoute.current', egoRoute.current)
		if (egoRoute.current) { // route should be defined and should be visible
			// check if polygon is in the route of ego
			if (egoRoute.current?.route?.find(item => item.id === polygon.id) && (selectedStaticObject.current || selectedStaticObject.current === 0)) {
				_addCustomMarkerIcon(
					e.lngLat,
					staticObsticaleListRef.current[selectedStaticObject.current].meta.png_icon,
					polygon,
					"static-object",
					{
						id: Date.now(),
						name: staticObsticaleListRef.current[selectedStaticObject.current].name
					}
				)
				setShowStaticObjects(true);
			}
			else {
				console.log('Ego route not defined');
			}
		}

	}

	/**
	 * Saves the pedistrian starting and ending marker with its location.
	 * @param {event} e click event object
	 * @returns nothing
	 * @extends definedPedestrianRoute.current saves the starting and ending positions of pedestrians as a 2d array
	 */
	const __handlePlacePedestrian = (e) => {
		if (markersOnMap.current.filter((item) => item.type === "pedestrian-end").length >= ALLOWED_PEDESTRIAN_COUNT) {
			return;
		}

		setShowPedestrianCrossings(true);
		const polygon = e.features[0];
		const clickCoordinates = e.lngLat;
		// Generate a random id
		const id = Date.now();
		if (!pedestrianToFrom.current) { // first point
			// Create a point for the first click
			pedestrianToFrom.current = turf.point([clickCoordinates.lng, clickCoordinates.lat]);
			pedestrianToFrom.current.properties = polygon.properties;
			pedestrianToFrom.current.id = id;
			// Add a circle with a radius around the point
			const buffered = turf.buffer(pedestrianToFrom.current, PEDESTRIAN_ALLOWED_CIRCLE_RADIUS, { units: 'meters' });
			const circleLayer = {
				id: `circle-layer-${id}`,
				type: 'fill',
				source: {
					type: 'geojson',
					data: buffered
				},
				layout: {},
				paint: {
					'fill-color': 'blue',
					'fill-opacity': 0.3
				}
			};
			map.current.addLayer(circleLayer);
			_addRandomMarkerIcon(clickCoordinates, "DirectionsRunRounded", polygon, "pedestrian-start", { id })
		} else { // second point
			// Create a point for the second click
			const endPoint = turf.point([clickCoordinates.lng, clickCoordinates.lat]);
			endPoint.properties = polygon.properties;
			endPoint.id = id;
			// Check if the second point is within the 1-kilometer radius of the first point
			const distance = turf.distance(endPoint, pedestrianToFrom.current, { units: 'meters' })
			// console.log('distance', distance);
			if (distance <= PEDESTRIAN_ALLOWED_CIRCLE_RADIUS) {//turf.booleanPointInPolygon(endPoint, pedestrianToFrom.current)) {
				// Draw a line from the first point to the second point
				const line = turf.lineString([
					turf.getCoord(pedestrianToFrom.current),
					turf.getCoord(endPoint)
				]);
				const lineLayer = {
					id: `line-layer-${id}`,
					type: 'line',
					source: {
						type: 'geojson',
						data: line
					},
					layout: {
						'line-join': 'round',
						'line-cap': 'round'
					},
					paint: {
						'line-color': 'red',
						'line-width': 2
					}
				};
				map.current.addLayer(lineLayer);
				map.current.removeLayer(`circle-layer-${pedestrianToFrom.current.id}`)
				definedPedestrianRoute.current = [
					...definedPedestrianRoute.current,
					[pedestrianToFrom.current, endPoint]
				];
				pedestrianToFrom.current = null;
				_addRandomMarkerIcon(clickCoordinates, "AdjustRounded", polygon, "pedestrian-end", { id })
			} else {
				alert('A person must be within 1 kilometer of the starting point.');
			}
		}
	}

	// console.log('laneIds', laneIdsLookupTable)

	/**
	 * @private {Function for handling click events}
	 * @param {event} clickEvent Object with the click event
	 * @param {string} targetId the target id of the polygon on which an operation is performed
	 * @param {object} json geojson converted to json
	 */
	const _calculateClickEvent = async (clickEvent, targetId, json) => {
		setLoading(true);
		const defaultColor = json["features"][0]["properties"].fill;
		const polygon = clickEvent.features[0]; // clicked polygon
		// console.log('polygon', polygon);
		if ([MY_SCENARIO_INTERACTION_PANELS.egoRoute, MY_SCENARIO_INTERACTION_PANELS.actorsRoutes].includes(actionMode.current)) {
			if (allowEditRoute.current) { // check if user can edit the the route or not
				if (vehicleInitialPosition.current === null) { // store the initial position
					_addVehicleCarMarker(clickEvent.lngLat, polygon.properties.lane_polygon_id); // add a marker on the polygon that is clicked
					if (clickEvent.features.length > 0) { // sanity check to prevent invalid data error
						json.features[polygon.id].properties.fill = activeSelectionColor.current; // change color of polygon that is clicked
						const successors = json.features[polygon.id].properties["successor_polygon_ids"]; // get all successors array
						successors.forEach((item) => {
							// highlight the polygons that can be next clicked
							const lookupTableIndex = laneIdsLookupTable[item];
							json.features[lookupTableIndex].properties.fill = laneColors.option;
						});
						map.current.getSource(targetId).setData(json); // set the updated data
					}
					// store the initial position of the vehicle
					vehicleInitialPosition.current = clickEvent.lngLat;
					// start saving the route that is made by the user.

					vehicleSelectedRoutePolygons.current.push({
						id: clickEvent.features[0].id,
						properties: clickEvent.features[0].properties,
						polygonColor: activeSelectionColor.current,
						location: clickEvent.lngLat,
					});
				} else { // store the rest of the route
					console.log('color', json.features[laneIdsLookupTable[polygon.properties.lane_polygon_id]].properties.fill, laneColors.option)
					if (json.features[laneIdsLookupTable[polygon.properties.lane_polygon_id]].properties.fill === laneColors.option){
						// console.log('is option', )
					}
					else {
						// console.log('is not option', )
						setLoading(false);
						return;
					}
					/**
					 * We have to First reset the previously unselected polygons which were highlighted as an 
					 * option, set the default color to clear the route
					 */
					const previousClickedPolygon = vehicleSelectedRoutePolygons.current[vehicleSelectedRoutePolygons.current.length - 1]; //* get the previously clicked polygon
					const previousLeftLaneSuccessor = previousClickedPolygon.properties.left_polygon_id; //* left available lane 
					const previousRightLaneSuccessor = previousClickedPolygon.properties.right_polygon_id; //* right available lane
					//* if left lane is not available and is not clicked
					if (previousLeftLaneSuccessor !== "" && json.features[laneIdsLookupTable[previousLeftLaneSuccessor]].properties.fill === laneColors.option)
						json.features[laneIdsLookupTable[previousLeftLaneSuccessor]].properties.fill = defaultColor;
					//* if right lane is not available and is not clicked
					if (previousRightLaneSuccessor !== "" && json.features[laneIdsLookupTable[previousRightLaneSuccessor]].properties.fill === laneColors.option)
						json.features[laneIdsLookupTable[previousRightLaneSuccessor]].properties.fill = defaultColor;
					//* The successors that are not clicked reset them with default color
					const previousClickedPolygonSuccessors = JSON.parse(previousClickedPolygon.properties.successor_polygon_ids);
					previousClickedPolygonSuccessors.forEach((item) => {
						if (item !== json.features[polygon.id].properties.lane_polygon_id) {
							//give default color
							const previousUnselectedPolygonIndex = laneIdsLookupTable[item];
							json.features[previousUnselectedPolygonIndex].properties.fill = defaultColor;
						}
					});
					/**
					 * Check if the clicked polygon is from the available route option. means if clicked polygon is a successor
					 */
					if (previousClickedPolygonSuccessors.includes(polygon.properties.lane_polygon_id)) {
						json.features[polygon.id].properties.fill = activeSelectionColor.current;
						const successors = json.features[polygon.id].properties["successor_polygon_ids"];
						const leftLaneSuccessor = json.features[polygon.id].properties["left_polygon_id"];
						const rightLaneSuccessor = json.features[polygon.id].properties["right_polygon_id"];
						successors.forEach((item, i) => {
							const lookupTableIndex = laneIdsLookupTable[item];
							json.features[lookupTableIndex].properties.fill = laneColors.option;
						});
						if (vehicleCarLaneChanged.current.status === false && leftLaneSuccessor !== "")
							json.features[laneIdsLookupTable[leftLaneSuccessor]].properties.fill = laneColors.option;
						if (vehicleCarLaneChanged.current.status === false && rightLaneSuccessor !== "")
							json.features[laneIdsLookupTable[rightLaneSuccessor]].properties.fill = laneColors.option;
						map.current.getSource(targetId).setData(json);
						vehicleSelectedRoutePolygons.current.push({
							id: clickEvent.features[0].id,
							properties: clickEvent.features[0].properties,
							location: clickEvent.lngLat,
						});
					} else if ( //* check if the click is on left or right available polygon and has not already chnaged the lane
						vehicleCarLaneChanged.current.status === false &&
						[previousClickedPolygon.properties.right_polygon_id, previousClickedPolygon.properties.left_polygon_id].includes(
							polygon.properties.lane_polygon_id
						)
					) {
						const previousLeftLaneSuccessor = previousClickedPolygon.properties.left_polygon_id;
						const previousRightLaneSuccessor = previousClickedPolygon.properties.right_polygon_id;
						if (previousLeftLaneSuccessor !== "") json.features[laneIdsLookupTable[previousLeftLaneSuccessor]].properties.fill = defaultColor;
						if (previousRightLaneSuccessor !== "") json.features[laneIdsLookupTable[previousRightLaneSuccessor]].properties.fill = defaultColor;
						json.features[polygon.id].properties.fill = activeSelectionColor.current;
						//* check if current polygon is the left polygon of previously clicked
						if (previousClickedPolygon.properties.left_polygon_id === polygon.properties.lane_polygon_id) {
							_addLaneChangeIcon(clickEvent.lngLat, "r2l", polygon.properties.lane_polygon_id);
						}
						//* check if current polygon is the right polygon of previously clicked
						if (previousClickedPolygon.properties.right_polygon_id === polygon.properties.lane_polygon_id) {
							_addLaneChangeIcon(clickEvent.lngLat, "l2r", polygon.properties.lane_polygon_id);
						}
						const successors = json.features[polygon.id].properties["successor_polygon_ids"];
						//* color the next polygons to allow user to see as an option
						successors.forEach((item, i) => {
							const lookupTableIndex = laneIdsLookupTable[item];
							json.features[lookupTableIndex].properties.fill = laneColors.option;
						});
						//* setting the updated json data
						map.current.getSource(targetId).setData(json);
						//* saving the updated json data
						vehicleSelectedRoutePolygons.current.push({
							id: clickEvent.features[0].id,
							properties: clickEvent.features[0].properties,
							location: clickEvent.lngLat,
						});
						//* saving the lane change info
						vehicleCarLaneChanged.current = {
							status: true,
							polygon: polygon,
							location: clickEvent.lngLat,
						};
					}
				}
			} else if (allowAddAccelerationMarker.current) { // Adding the acceleration marker
				// check if the polygon is in the route or not 
				if (vehicleSelectedRoutePolygons.current.find((item) => item.properties.lane_polygon_id === polygon.properties.lane_polygon_id)) {
					// check if acceleration value is valid
					if (accelerationTextField.current && accelerationTextField.current !== "" && !vehicleAccelerationMarkerAdded.current) {
						vehicleAccelerationMarkerAdded.current = true;
						_addAccelerationIcon(clickEvent.lngLat, accelerationTextField.current, polygon.properties.lane_polygon_id);
					}
				}
			}
			// the save button and delete button visibility
			setShowSaveAndDeleteButton(true)
			setLoading(false);
		}
		else if (actionMode.current === MY_SCENARIO_INTERACTION_PANELS.staticObjects) {
			__handlePlaceStaticObject(clickEvent);
			setLoading(false);
		}
		else if (actionMode.current === MY_SCENARIO_INTERACTION_PANELS.pedestrians) {
			__handlePlacePedestrian(clickEvent)
			setLoading(false);
		}
		else {
			setLoading(false);
		}
	}

	/**
	 * ########################################################################################
	 * # State handlers
	 * ########################################################################################
	 */

	/**
	 * Fetch all the map layers
	 */
	const fetchMapLayers = useCallback(async () => {
		setLoading(true);
		// function removeAllSourcesAndLayers() {
		// 	// Get the base style layers
		// 	var baseStyleLayers = map.current.getStyle().layers.filter(function (layer) {
		// 		return layer.id.startsWith('background') || layer.type === 'background';
		// 	});
		// 	// Remove all layers except the base style layers
		// 	map.current.getStyle().layers.forEach(function (layer) {
		// 		if (!baseStyleLayers.includes(layer)) {
		// 			map.current.removeLayer(layer.id);
		// 		}
		// 	});

		// 	// Remove all sources
		// 	// var sources = Object.keys(map.current.getStyle().sources);
		// 	// sources.forEach(function (source) {
		// 	// 	map.current.removeSource(source);
		// 	// });
		// }
		try {
			// removeAllSourcesAndLayers();
			const response = await getMapLayers(gid_kwh);
			if (response.status === 200) {
				// converting the string data against the keys to object
				const files = Object.fromEntries(
					Object
						.keys(response.data.data)
						.map(item => ([item, {
							url: response.data.data[item],
							count: null,
							active: false,
							defaultColor: "#fff",
							layerType: item.split("_")[item.split("_").length - 1]
						}])
						)
				)
				setGeojsonFilesURLs(files);
				// fetch the json from url
				await fetch(files.lane_polygons.url)
					.then((resp) => resp.json())
					.then(async (json) => {
						const temp = {};
						const defaultColor = json["features"][0]["properties"].fill;

						const lanePolygons = {
							url: files.lane_polygons.url,
							count: json.features.length,
							active: true,
							defaultColor: defaultColor,
							layerType: "polygons"
						}
						// constructing a lookup table
						await json.features.forEach((feature, index) => {
							//* saving the indexes against lane ids
							temp[feature.properties.lane_polygon_id] = index;
						});
						// store default colors for later use to rectify the selections
						// setDefaultLayerColors({ ...defaultLayerColors, lane_polygons: defaultColor });

						// loading the lane polygons as default
						await map.current.addSource("lane_polygons", { type: "geojson", data: json, generateId: true });
						const layerProps = json["features"][0]["properties"];
						await map.current.addLayer(generateNewLayer("lane_polygons", layerProps));
						// setShowEditingPanel(true);
						await map.current.setCenter(json["features"][0]["geometry"]["coordinates"][0][0]);
						await map.current.setZoom(14);
						setLaneIdsLookupTable(temp);
						setGeojsonFilesURLs({ ...files, lane_polygons: lanePolygons })
						setShowEditingPanel(true);
						setGeojsonAsJson(json);

						if (MyScenario.readonly) {
							const prevScenarioData = MyScenario.prevUserScenario;
							// Ego route
							const prevEgoRoute = prevScenarioData.vut.lane_route.map(item => `${item[0]}_${item[1]}`);
							const prevEgoRoutePolygons = json.features.filter(polygon => prevEgoRoute.includes(`${polygon.properties.road_id}_${polygon.properties.lane_id}`));
							// console.log('egoRoute', prevEgoRoutePolygons);
							setSavedEgoRoute({ route: prevEgoRoutePolygons });
							egoRoute.current = { route: prevEgoRoutePolygons, polygonColor: "#000" };
							// Actor routes
							const prevActorRoutes = prevScenarioData.traffic_Participants; //* Array of objects
							const prevActorRoutesPolygons = [];
							for (let i = 0; i < prevActorRoutes.length; ++i) {
								const prevActorRoute = prevActorRoutes[i].lane_route.map(item => `${item[0]}_${item[1]}`);
								const prevActorRoutePolygons = json.features.filter(polygon => prevActorRoute.includes(`${polygon.properties.road_id}_${polygon.properties.lane_id}`));
								prevActorRoutesPolygons.push({
									id: Date.now(),
									route: prevActorRoutePolygons,
									vehicle: { value: prevActorRoutes[i].vehicle_profile },
									polygonColor: randomColorsForActors[i]
								});
							}
							// console.log('prevActorRoutesPolygons', prevActorRoutesPolygons)
							setSavedActorsRoutes(prevActorRoutesPolygons)
							//Static objects
							const objects = prevScenarioData.stationary_objects;
							const staticObjList = (await getObstacleList()).data.data;
							// console.log('staticObjList', staticObjList);
							const newObjectMarkers = objects.map((item, i) => {
								const ref = React.createRef();
								ref.current = document.createElement("div");
								const root = createRoot(ref.current);
								const found = staticObjList.find(obj => obj.name === item.classification_id)
								if (found) {
									const element = <img src={found.meta.png_icon} style={{ width: 40, height: 40 }} alt="icon"/>;
									root.render(element);
									const newMarker = new mapboxgl.Marker(ref.current).setLngLat({ lat: item.position[0], lng: item.position[1] }).addTo(map.current);
									return ({
										lnglat: { lat: item.position[0], lng: item.position[1] },
										additional: { icon: found.meta.png_icon, name: item.classification_id },
										marker: newMarker,
										polygon: json.features.find(polygon => polygon.id === `${item.road_id}_${item.lane_id}`),
										type: "static-object"
									})
								}
								else {
									const StaticObjectIcon = MIcons[TEMP_STATIC_OBJECTS[4].icon];
									const element = <StaticObjectIcon />;
									root.render(element);
									const newMarker = new mapboxgl.Marker(ref.current).setLngLat({ lat: item.position[0], lng: item.position[1] }).addTo(map.current);
									return ({
										lnglat: { lat: item.position[0], lng: item.position[1] },
										additional: { icon: TEMP_STATIC_OBJECTS[4].icon, name: `Static Object ${i + 1}` },
										marker: newMarker,
										polygon: json.features.find(polygon => polygon.id === `${item.road_id}_${item.lane_id}`),
										type: "static-object"
									})
								}

							})
							// Pedestrians
							const crossings = prevScenarioData.Pedestrians;
							const newCrossingsMarkers = crossings.flatMap(item => {
								//starting point
								const sref = React.createRef();
								sref.current = document.createElement("div");
								const sroot = createRoot(sref.current);
								const SIco = MIcons["DirectionsRunRounded"];
								const selement = <SIco />;
								sroot.render(selement);
								const sMarker = new mapboxgl.Marker(sref.current).setLngLat({ lat: item.spawn_point.position[0], lng: item.spawn_point.position[1] }).addTo(map.current);

								//end point
								const eref = React.createRef();
								eref.current = document.createElement("div");
								const eroot = createRoot(eref.current);
								const EIco = MIcons["AdjustRounded"];
								const eelement = <EIco />;
								eroot.render(eelement);
								const eMarker = new mapboxgl.Marker(eref.current).setLngLat({ lat: item.end_point.position[0], lng: item.end_point.position[1] }).addTo(map.current);
								return [{
									lnglat: { lat: item.spawn_point.position[0], lng: item.spawn_point.position[1] },
									additional: { icon: "DirectionsRunRounded" },
									marker: sMarker,
									polygon: json.features.find(polygon => polygon.id === `${item.spawn_point.road_id}_${item.spawn_point.lane_id}`),
									type: "pedestrian-start"
								}, {
									lnglat: { lat: item.end_point.position[0], lng: item.end_point.position[1] },
									additional: { icon: "AdjustRounded" },
									marker: eMarker,
									polygon: json.features.find(polygon => polygon.id === `${item.end_point.road_id}_${item.end_point.lane_id}`),
									type: "pedestrian-end"
								}]
							})
							setMarkersOnMapState([...newObjectMarkers, ...newCrossingsMarkers])
							markersOnMap.current = [...newObjectMarkers, ...newCrossingsMarkers];
							setShowStaticObjects(true);
							setShowPedestrianCrossings(true);

						}
					});
			}
		}
		catch (err) {
			console.log('err', err);
		}
		finally {
			console.log('end loading', )
			setLoading(false);
		}

	}, [gid_kwh, MyScenario.prevUserScenario, MyScenario.readonly]);


	/**
	 * * Loading the Saved data if user returns to this page
	 * getting the data from redux and saving into state and refs
	 */
	const loadPreviousState = useCallback(async () => {
		if (!MyScenario.readonly) {
			setSavedEgoRoute(MyScenario?.routeConfiguration?.ego || null)
			egoRoute.current = MyScenario?.routeConfiguration?.ego || [];
			setSavedActorsRoutes(MyScenario?.routeConfiguration?.actors || [])
			const staticObjList = (await getObstacleList()).data.data;
			const markers = MyScenario?.markersOnMap || [];
			const newMarkers = markers.map(item => {
				const ref = React.createRef();
				ref.current = document.createElement("div");
				const root = createRoot(ref.current);
				if (item.type === "static-object") {
					const found = staticObjList.find(obj => obj.name === item.additional.name)
					if (found) {
						const element = <img src={found.meta.png_icon} style={{ width: 40, height: 40 }} alt="icon"/>;
						root.render(element);
						const newMarker = new mapboxgl.Marker(ref.current).setLngLat(item.lnglat).addTo(map.current);
						return ({ ...item, marker: newMarker })
					}
					else {
						const Ico = MIcons[TEMP_STATIC_OBJECTS[4].icon];
						const element = <Ico />;
						root.render(element);
						const newMarker = new mapboxgl.Marker(ref.current).setLngLat(item.lnglat).addTo(map.current);
						return ({ ...item, marker: newMarker })
					}
				}
				const Ico = MIcons[item.additional.icon];
				const element = <Ico />;
				root.render(element);
				const newMarker = new mapboxgl.Marker(ref.current).setLngLat(item.lnglat).addTo(map.current);
				return ({ ...item, marker: newMarker })
			})

			setMarkersOnMapState(newMarkers)
			markersOnMap.current = newMarkers;
			if (MyScenario?.pedestrianRawData && MyScenario?.pedestrianRawData.length > 0) {
				definedPedestrianRoute.current = MyScenario?.pedestrianRawData;
			}
			else {
				const pedestriansStartings = newMarkers.filter(marker => marker.type === 'pedestrian-start').map(marker => ({ ...marker.polygon, id: marker.additional.id })) || [];
				const pedestriansEndings = newMarkers.filter(marker => marker.type === 'pedestrian-end').map(marker => ({ ...marker.polygon, id: marker.additional.id })) || [];
				if (pedestriansStartings.length !== pedestriansEndings.length) {
					throw new Error("Arrays must have the same length");
				}
				definedPedestrianRoute.current = Array.from(pedestriansStartings.keys()).map(index => [pedestriansStartings[index], pedestriansEndings[index]]);
			}
			setShowStaticObjects(true);
			setShowPedestrianCrossings(true);
			// _addRandomMarkerIcon(e.lngLat, TEMP_STATIC_OBJECTS[0].icon, polygon, "static-object", { id: Date.now() })
		}

	}, [
		MyScenario.markersOnMap,
		MyScenario.pedestrianRawData,
		MyScenario.readonly,
		MyScenario.routeConfiguration?.actors,
		MyScenario.routeConfiguration?.ego
	])

	useEffect(() => {
		if (!map.current) {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: mapTypes.streetView,
				center: [13.405252, 52.517644],
				zoom: 8,
			});
			map.current.on("load", () => {
				map.current.on("move", _handleOnMapMove);
				fetchMapLayers();
				loadPreviousState();
				// map.current.on("sourcedataloading", () => {
				// 	console.log("A sourcedataloading event occurred.");
				// });

				// map.current.on("mousemove", "route", handleOnMouseMove);
				// map.current.on("click", "state-fills", handleOnClickPolygon);
			});

		}
		// return () => {
		// 	map.current.off("click", `route0`, (e) => console.log('e', e));
		// }
	}, [fetchMapLayers, loadPreviousState]);

	useEffect(() => {
		if (showEditingPanel) {
			if (!_.isEmpty(laneIdsLookupTable) && geojsonAsJson && geojsonFilesURLs) {
				/**
				 * @satisfies if the lookup table is not empty
				 * @satisfies if the geojson is not empty
				 * @satisfies if the urls are not empty
				 * 
				 * @access {for click listener granted if it satisfies all}
				 */	
				map.current.on("click", `routelane_polygons`, (e) => _calculateClickEvent(e, "lane_polygons", geojsonAsJson));
			}
		}
		else {
			// Disable the click listener if editing not allowed
			map.current.off("click", `routelane_polygons`, _calculateClickEvent);
		}
		return () => {
			map.current.off("click", `routelane_polygons`, _calculateClickEvent);
		}
	},[laneIdsLookupTable, geojsonAsJson, geojsonFilesURLs, showEditingPanel])

	/**
	 * 
	 * @param {event} e onchange object
	 * @param {string} type poygon type string to load 
	 */
	const handleSourceLoad = (e, type) => {
		// console.log("handleSourceLoad", e, type);
		setLoading(true);
		if (e.target.checked) {
			fetch(geojsonFilesURLs[type].url)
				.then((resp) => resp.json())
				.then(async (json) => {
					console.log('json', json)
					const defaultColor = json["features"][0]["properties"].fill;

					setGeojsonFilesURLs({
						...geojsonFilesURLs,
						[type]: {
							...geojsonFilesURLs[type],
							count: json.features.length,
							active: true,
							defaultColor: defaultColor
						}
					})
					// store default colors for later use to rectify the selections
					// setDefaultLayerColors({ ...defaultLayerColors, [type]: defaultColor });
					await map.current.addSource(e.target.id, { type: "geojson", data: json, generateId: true });
					const layerProps = json["features"][0]["properties"];
					await map.current.addLayer(generateNewLayer(e.target.id, layerProps, geojsonFilesURLs[type].layerType));
					// console.log("json", json);
					if (type === "lane_polygons") {
						setShowEditingPanel(true);
						await map.current.setCenter(json["features"][0]["geometry"]["coordinates"][0][0]);
						await map.current.setZoom(14);
						// await map.current.on("click", `route${e.target.id}`, (clickEvent) => _calculateClickEvent(clickEvent, e.target.id, json));
					}
					// if (type === "road_polygons") {
					// 	map.current.setCenter(json["features"][0]["geometry"]["coordinates"][0][0]);
					// 	map.current.setZoom(14);
					// }
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			try {
				setGeojsonFilesURLs({
					...geojsonFilesURLs,
					[type]: {
						...geojsonFilesURLs[type],
						active: false,
					}
				})
				// map.current.off("click", `route${e.target.id}`, _calculateClickEvent);
				map.current.removeLayer(`route${e.target.id}`);
			} catch (err) {
				console.log("err", err);
			}
			try {
				if (type === "lane_polygons") {
					setShowEditingPanel(false);
				}
				map.current.removeSource(e.target.id);
			} catch (err) {
				console.log("err", err);
			} finally {
				setLoading(false);
			}
		}
	};

	/**
	 * ########################################################################################
	 * # Payload handlers
	 * ########################################################################################
	 */

	/**
	 * pedestrians markers data with locations
	 * @returns converted payload
	 */
	const makepedestrianPayload = () => {
		const pedestriansStartings = markersOnMap.current.filter(marker => marker.type === 'pedestrian-start') || [];
		const pedestriansEndings = markersOnMap.current.filter(marker => marker.type === 'pedestrian-end') || [];
		// console.log('pedestriansStartings', pedestriansStartings);
		if (pedestriansStartings.length !== pedestriansEndings.length) {
			toast("Pedestrians data is corrupt. Kindly start the journey again", "warning");
			return;
		}
		const temp = pedestriansStartings.map((item, i) => {
			const spawnPoint = [item.lnglat.lat, item.lnglat.lng]
			const endPoint = [pedestriansEndings[i].lnglat.lat, pedestriansEndings[i].lnglat.lng]
			const items = {
				"spawn_point": {
					"position": spawnPoint,
					"lane_id": item.polygon.properties.lane_id,
					"road_id": item.polygon.properties.road_id
				},
				"end_point": {
					"position": endPoint,
					"lane_id": pedestriansEndings[i].polygon.properties.lane_id,
					"road_id": pedestriansEndings[i].polygon.properties.road_id
				},
				"speed": _.cloneDeep(DEFAULT_PEDESTRIAN_SPEED)
			}
			return items
		})
		return temp
	}

	/**
	 * static objects markers with locations
	 * @returns converted payload
	 */
	const makeObstaclePayload = () => {
		const data = markersOnMap.current.filter(marker => marker.type === 'static-object') || []
		const temp = data.map((item) => {
			const items = {
				"position": [item.lnglat.lat, item.lnglat.lng],
				"orientation": "0",
				"road_id": item.polygon?.properties.road_id,
				"lane_id": item.polygon?.properties.lane_id,
				"classification_id": item.additional.name,
				"dimensions": {
					"length": "",
					"width": "",
					"height": "",
					"radius": ""
				}
			}
			return items
		})
		return temp
	}

	/**
	 * 
	 * @param {event} e button click event
	 * @returns nothing
	 * @navigates next logical params screen if all checks are clear
	 */
	const onClickNext = (e) => {

		const staticObsticalePayload = makeObstaclePayload() || [];
		const pedestrianPayload = makepedestrianPayload() || [];

		let isEdited = false;
		if (!MyScenario.readonly) {
			if (MyScenario.routeConfiguration?.ego?.route?.length !== savedEgoRoute?.route?.length) { isEdited = true; }
			if (MyScenario.routeConfiguration?.actors?.length !== savedActorsRoutes?.length) { isEdited = true; }
			if (MyScenario.routeConfiguration?.staticObjects?.length !== staticObsticalePayload.length) { isEdited = true; }
			if (MyScenario.routeConfiguration?.pedestrians?.length !== pedestrianPayload.length) { isEdited = true; }
		}
		// If there is anything edited, then the previous data is lost
		const prevObj = isEdited ? {} : { ...MyScenario.routeConfiguration }
		const objToSend = {
			...prevObj,
			ego: savedEgoRoute,
			actors: savedActorsRoutes,
			polygonsLookupTable: laneIdsLookupTable,
			url: geojsonFilesURLs["lane_polygons"].url,
			staticObjects: staticObsticalePayload,
			pedestrians: pedestrianPayload,
		};
		const pralionId = localStorage.getItem("pralion_id");
		let tvp = null;
		let tv = null;
		if (!MyScenario.readonly) {
			// console.log('selectedEgoTestVehicle', selectedEgoTestVehicle);
			if (selectedEgoTestVehicle === null)
				tvp = MyScenario.testVehicleProject;
			else
				tvp = egoTestVehiclesList[selectedEgoTestVehicle]
			// console.log('tvp', tvp);
			if (!tvp) {
				toast("Please select a test vehicle for EGO");
				return;
			}

			// console.log('selectedEgoTest details', selectedEgoTestVehicleDetails)
			if (selectedEgoTestVehicleDetails === null)
				tv = MyScenario.testVehicle;
			else
				tv = selectedEgoTestVehicleDetails;
			if (!tv) {
				toast("Please select a test vehicle for EGO");
				return;
			}

		}
		ReduxUpdateMyScenarioJourney({
			routeConfiguration: objToSend,
			testVehicleProject: tvp,
			testVehicle: tv,
			markersOnMap: markersOnMap.current.map(item => ({ ...item, marker: undefined })),
			// pedestrianRawData: definedPedestrianRoute.current.map(item => item.map(nitem => ({
			// 	geometry: nitem.geometry,
			// 	id: nitem.id,
			// 	properties: nitem.properties,
			// 	type: nitem.type
			// })))
		});
		if (pralionId === null || pralionId === undefined) {
			localStorage.setItem("pralion_id", param.id);
		}

		if(usingFile === true) {
			navigate(AUTHENTICATED_ROUTES
				.defineScenarioObserver
				.replace(":id", Projects.projectId)
				.replace(":aid", MyScenario.pralionId)
				.replace(":mid", MyScenario.oddData.gid_kwh), {
				state: { ...objToSend },
			})
		} else {
			navigate(AUTHENTICATED_ROUTES
				.logicalScenerioParams
				.replace(":id", Projects.projectId)
				.replace(":aid", MyScenario.pralionId)
				.replace(":mid", MyScenario.oddData.gid_kwh), {
				state: { ...objToSend },
			});
		}
		if(MyScenario.readonly && MyScenario.usingFile === true) {
			navigate(AUTHENTICATED_ROUTES
				.defineScenarioObserver
				.replace(":id", Projects.projectId)
				.replace(":aid", MyScenario.pralionId)
				.replace(":mid", MyScenario.oddData.gid_kwh), {
				state: { ...objToSend },
			})}
	};

	/**
	 * Handle the back click
	 */
	const onClickBack = () => {
		if (!MyScenario.readonly) {
			if (openConfirmationModal) {
				navigate(-1)
			}
			else {
				// console.log('check', savedActorsRoutes.length, savedEgoRoute, markersOnMapState.filter(item => ["pedestrian-start", "pedestrian-end", "static-object"].includes(item.type)).length)
				if ((savedActorsRoutes.length > 0 ||
					savedEgoRoute !== null ||
					markersOnMapState.filter(item => ["pedestrian-start", "pedestrian-end", "static-object"].includes(item.type)).length > 0) && !MyScenario.routeConfiguration) {
					setOpenConfirmationModal(true);

				}
				else {
					navigate(-1)
				}
			}
		}
		else {
			navigate(-1)
		}
	}

	/**
	 * ########################################################################################
	 * # Map Event handlers
	 * ########################################################################################
	 */

	/**
	 * 
	 * @param {event} e event object
	 */
	const _handleOnMapMove = (e) => {
		setLng(map.current.getCenter().lng.toFixed(4));
		setLat(map.current.getCenter().lat.toFixed(4));
		setZoom(map.current.getZoom().toFixed(2));
	};

	/**
	 * ########################################################################################
	 * # Panels handlers
	 * ########################################################################################
	 */

	/**
	 * 
	 * @param {string} panel string or null
	 * @returns nothing
	 */
	const handleChangePanel = (panel) => (event, isExpanded) => {
		setExpandedPanel(isExpanded ? panel : false);
	};

	/**
	 * Left panel toggle buttons handler
	 * @param {event} e toggle button event object
	 * @param {string} value toggle button string value
	 */
	const onMapFunctionalityActionButtonClick = (e, value) => {
		setMapFunctionalityActiveButton(value);
		switch (value) {
			case "view":
				// allow read only functionality
				// map.current.off("click", `routelane_polygons`, (e) => _calculateClickEvent(e, "lane_polygons", geojsonAsJson));
				break;
			case "interact":
				//allow edit of one actor or ego functionality
				// map.current.on("click", `routelane_polygons`, (e) => _calculateClickEvent(e, "lane_polygons", geojsonAsJson));
				break;
			default:
				break;
		}
	};

	/**
	 * Right panel toggle buttons handler
	 * @param {event} e toggle button event object
	 * @param {string} value toggle button string value
	 */
	const onPrimaryActionButtonClick = (e, value) => {
		// onSelectVehicleTypeFromList(null);
		setPrimaryActionActiveButton(value);
		actionMode.current = value;
		switch (value) {
			case MY_SCENARIO_INTERACTION_PANELS.egoRoute:
				// allow ego functionality
				setExpandedPanel(MY_SCENARIO_INTERACTION_PANELS.egoRoute)
				fetchEgoTestVehicles();
				break;
			case MY_SCENARIO_INTERACTION_PANELS.actorsRoutes:
				setExpandedPanel(MY_SCENARIO_INTERACTION_PANELS.actorsRoutes)
				fetchActorVehicles();
				//allow actors functionality
				break;
			case MY_SCENARIO_INTERACTION_PANELS.staticObjects:
				setExpandedPanel(MY_SCENARIO_INTERACTION_PANELS.staticObjects)
				fetchObsticaleList();
				if (savedEgoRoute?.route?.length) {
					_showEgoRoute(true);
				}
				break;
			case MY_SCENARIO_INTERACTION_PANELS.pedestrians:
				// Allow pedestrian functionality
				setExpandedPanel(MY_SCENARIO_INTERACTION_PANELS.pedestrians)
				break;
			default:
				// _showEgoRoute(false);
				break;
		}
	};

	/**
	 * ########################################################################################
	 * # Data handlers
	 * ########################################################################################
	 */

	const fetchEgoTestVehicles = async () => {
		try {
			setLoadingEgoTestVehicles(true);
			const response = await getAllTestVehicleProjects(Projects.projectId);
			// console.log('response', response);
			if (response?.status === 200) {
				const data = response?.data?.data?.test_vehicles || [];
				setEgoTestVehiclesList(data);
			} else {
				const err = response?.message || "Failed to get request";
				toast.error(`${err}. Please try again later`);
				setEgoTestVehiclesList([]);
			}
		}
		catch (err) {
			console.log('err', err);
			toast.error(`${err}. Please try again later`);
		}
		finally {
			setLoadingEgoTestVehicles(false);
		}

	}

	const fetchActorVehicles = async () => {
		try {
			setLoadingActorVehicles(true);
			await httpGet({ url: FETCH_VEHICLES }).then((resp) => {
				// console.log("vehicles", resp.data.data);
				const remodeledVehiclesList = Object.entries(resp.data.data).flatMap(([vehicle, values]) => values.map(value => ({ vehicle, value })));
				// console.log('remodeledVehiclesList', remodeledVehiclesList)
				setVehiclesListForActors(remodeledVehiclesList);
			});
		}
		catch (err) {
			setVehiclesListForActors([]);
		}
		finally {
			setLoadingActorVehicles(false);
		}
	};

	const fetchObsticaleList = async () => {
		setLoadingStaticObjects(true);
		const resp = await getObstacleList()
		console.log('fetchObsticaleList resp', resp)
		if (resp.status === 200) {
			const list = resp.data.data || []
			const updatelist = list.map((item) => {
				item['selected'] = false
				return item
			})
			staticObsticaleListRef.current = updatelist;
			setStaticObsticaleList(updatelist)
		}
		else {
			console.log('err fetchObsticaleList', resp)
		}
		setLoadingStaticObjects(false);
	}

	/**
	 * ########################################################################################
	 * # List and button handlers
	 * ########################################################################################
	 */

	const onSelectEgoTestVehicleTypeFromList = async (index) => {
		setLoadingEgoTestVehicleDetails(true);
		try {
			if (index !== selectedEgoTestVehicle) {
				setSelectedEgoTestVehicle(index);
				// console.log('egoTestVehiclesList[index]', egoTestVehiclesList[index])
				// TODO: Call the api with pralion id
				const response = await getTestVehicleDetails(egoTestVehiclesList[index].pralion_id);
				// console.log('response getTestVehicleDetails', response);
				//! This is an object which contains the type of vehicle as a key value pair and then there is an array as its value
				//! What im doing is i am picking the first key value pair from this object and then the first element from the array of that value.
				const details = response.data.data.vehicle_profile;
				const firstKey = Object.keys(details)[0];
				setSelectedEgoTestVehicleDetails(details[Object.keys(details)[0]][0]);
				if (fileContent) {
					fileContent.scenario.vut.vehicle_profile = details[firstKey][0];
					console.log("details ---", details)
				  } else {
					console.error("vehicle_profile not found in JSON");
				  }
			}
			else {
				setSelectedEgoTestVehicle(null);
				// TODO: Reset the details as well
				setSelectedEgoTestVehicleDetails(null);
			}
		}
		catch (err) {
			console.log('err', err);
		}
		finally {
			setLoadingEgoTestVehicleDetails(false);
		}
	};

	const onSecondaryActionButtonClick = (e, value) => {
		setSecondaryActionActiveButton(value);
		switch (value) {
			case "edit":
				// allow edit functionality
				allowEditRoute.current = true;
				break;
			case "clear":
				//allow to delete all the polygons functionality
				break;
			case "add":
				// allow to edit
				allowEditRoute.current = true;
				break;
			case "acceleration":
				allowEditRoute.current = false;
				allowAddAccelerationMarker.current = true;
				break;
			default:
				allowEditRoute.current = false;
				allowAddAccelerationMarker.current = false;
				break;
		}
	};

	const onSaveClick = async (e) => {
		// console.log('e', e);
		const route = {
			id: Date.now(),
			initialPosition: vehicleInitialPosition.current,
			route: vehicleSelectedRoutePolygons.current,
			polygonColor: activeSelectionColor.current,
			markers: OmitKeyValuePair(markersOnMap.current, "marker"),
			vehicle: selectedActorVehicle,
		};
		// console.log("route", route);
		if (primaryActionActiveButton === MY_SCENARIO_INTERACTION_PANELS.egoRoute) {
			egoRoute.current = route;
			setSavedEgoRoute(route)
		}
		else if (primaryActionActiveButton === MY_SCENARIO_INTERACTION_PANELS.actorsRoutes)
			setSavedActorsRoutes([...savedActorsRoutes, route]);
		// console.log('markersOnMap.current', markersOnMap.current)
		// reset the geojson with default color
		await _resetRouteToDefaultStateGeojson();
		vehicleSelectedRoutePolygons.current = [];
		vehicleCarLaneChanged.current = { status: false, polygon: null, location: null };
		vehicleAccelerationMarkerAdded.current = false;
		vehicleInitialPosition.current = null;
		allowEditRoute.current = false;
		setSecondaryActionActiveButton(null);
		setSelectedActorVehicle(null);
		markersOnMap.current.forEach(item => {
			if (['vehicle', 'lane-change', 'acceleration'].includes(item.type)) //only remove the vehicle markers
				item.marker.remove();
		})
		markersOnMap.current = markersOnMap.current.filter(item => !['vehicle', 'lane-change', 'acceleration'].includes(item.type));
		setShowSaveAndDeleteButton(false);
	};

	const onDeleteClick = async (e) => {
		// clear the complete route
		await _resetRouteToDefaultStateGeojson();
		vehicleSelectedRoutePolygons.current = [];
		vehicleCarLaneChanged.current = { status: false, polygon: null, location: null };
		vehicleAccelerationMarkerAdded.current = false;
		vehicleInitialPosition.current = null;
		setSelectedActorVehicle(null);
		markersOnMap.current.forEach(item => {
			if (item.type === 'vehicle')
				item.marker.remove();
		})
		markersOnMap.current = markersOnMap.current.filter(item => item.type !== 'vehicle');
		setShowSaveAndDeleteButton(false);
		// map.current.removeMarkers();
	};

	const _resetRouteToDefaultStateGeojson = async (route) => {
		// console.log('map.current.getSource("0")', map.current)
		const json = map.current.getSource("lane_polygons")._data;
		const tempFeatures = [];
		await json.features.forEach((item) => {
			item.properties.fill = geojsonFilesURLs.lane_polygons.defaultColor;
			tempFeatures.push(item);
		});
		json.features = tempFeatures;
		map.current.getSource("lane_polygons").setData(json);
	};

	const onSelectVehicleTypeFromList = (e, value) => {
		// console.log('e, value', e, value);
		setSelectedActorVehicle(value);
	};

	const onClickViewSavedAllActorsRoutesButton = (view) => {
		console.log('savedActorsRoutes', savedActorsRoutes)
		if (view) {
			try {
				const json = map.current.getSource("lane_polygons")._data;
				savedActorsRoutes.forEach((route) => {
					const chossenColor = route.polygonColor;
					route.route.forEach((item) => {
						json.features[laneIdsLookupTable[item.properties.lane_polygon_id]].properties.fill = chossenColor;
					});
				})
				map.current.getSource("lane_polygons").setData(json);
			}
			catch (err) {
				console.log('err', err);
			}
		}
		else {
			try {
				const json = map.current.getSource("lane_polygons")._data;
				savedActorsRoutes.forEach((route) => {
					route.route.forEach((item) => {
						json.features[laneIdsLookupTable[item.properties.lane_polygon_id]].properties.fill = geojsonFilesURLs.lane_polygons.defaultColor;
					});
				})
				map.current.getSource("lane_polygons").setData(json);
			}
			catch (err) {
				console.log('err', err);
			}
		}
		setAllActorsViewState(view ? savedActorsRoutes.map(item => item.id): []);
	};

	const onClickDeleteSavedAllActorsRoutesButton = (e) => {
		try {
			const json = map.current.getSource("lane_polygons")._data;
			savedActorsRoutes.forEach((route) => {
				route.route.forEach((item) => {
					json.features[laneIdsLookupTable[item.properties.lane_polygon_id]].properties.fill = geojsonFilesURLs.lane_polygons.defaultColor;
				});
			})
			setSavedActorsRoutes([]);
			setAllActorsViewState([]);
			map.current.getSource("lane_polygons").setData(json);
		}
		catch (err) {
			console.log('err', err);
		}
	}

	const onColorChange = (e) => {
		setCurrentColor(e.target.value);
		activeSelectionColor.current = e.target.value;
	};

	const onClickViewSavedEgoRouteButton = (e) => {
		_showEgoRoute(!showEgoRoute);
		setShowEgoRoute(!showEgoRoute);
	};

	const _showEgoRoute = (status) => {
		try {
			if (status) {
				const json = map.current.getSource("lane_polygons")._data;
				savedEgoRoute.route.forEach((item) => {
					json.features[laneIdsLookupTable[item.properties.lane_polygon_id]].properties.fill = savedEgoRoute.polygonColor;
				});
				map.current.getSource("lane_polygons").setData(json);
			}
			else {
				const json = map.current.getSource("lane_polygons")._data;
				savedEgoRoute.route.forEach((item) => {
					json.features[laneIdsLookupTable[item.properties.lane_polygon_id]].properties.fill = geojsonFilesURLs.lane_polygons.defaultColor;
				});
				map.current.getSource("lane_polygons").setData(json)
			}

		} catch (err) {
			console.log("err", err);
		}
	};

	const onClickDeleteSavedEgoRouteButton = (e) => {
		try {
			const json = map.current.getSource("lane_polygons")._data;
			savedEgoRoute.route.forEach((item) => {
				json.features[laneIdsLookupTable[item.properties.lane_polygon_id]].properties.fill = geojsonFilesURLs.lane_polygons.defaultColor;
			});
			map.current.getSource("lane_polygons").setData(json);
			egoRoute.current = null;
			markersOnMap.current.forEach(item => {
				if (['vehicle', 'static-object'].includes(item.type))
					item.marker.remove();
			})
			markersOnMap.current = markersOnMap.current.filter(item => !['vehicle', 'static-object'].includes(item.type));
			setMarkersOnMapState(markersOnMap.current)
			setSavedEgoRoute(null);
		} catch (err) {
			console.log("err", err);
		}
	};

	const onClickViewSavedSingleActorRouteButton = (index) => {
		try {
			const json = map.current.getSource("lane_polygons")._data;
			const chossenColor = savedActorsRoutes[index].polygonColor;
			// check if the selected route is visible by checking its color code
			// TODO: Use an array to detect the visibility of routes
			const idx = allActorsViewState.findIndex(element => element === savedActorsRoutes[index].id) 
			if (idx === -1) {
				setAllActorsViewState([...allActorsViewState, savedActorsRoutes[index].id])
			}
			else {
				const tempActorsViewState = [...allActorsViewState];
				tempActorsViewState.splice(idx, 1);
				setAllActorsViewState([...tempActorsViewState])
			}
			if (json.features[laneIdsLookupTable[savedActorsRoutes[index].route[0].properties.lane_polygon_id]].properties.fill === geojsonFilesURLs.lane_polygons.defaultColor)
				savedActorsRoutes[index].route.forEach((item) => {
					json.features[laneIdsLookupTable[item.properties.lane_polygon_id]].properties.fill = chossenColor;
				});
			else
				savedActorsRoutes[index].route.forEach((item) => {
					json.features[laneIdsLookupTable[item.properties.lane_polygon_id]].properties.fill = geojsonFilesURLs.lane_polygons.defaultColor;
				});
			map.current.getSource("lane_polygons").setData(json);
		}
		catch (err) {
			console.log('err', err);
		}
	};

	const onClickSelectStaticObject = (index) => {
		if (selectedStaticObject.current === index) {
			selectedStaticObject.current = null;
		}
		else {
			selectedStaticObject.current = index;
		}
		const temp = [...staticObsticaleList];
		temp[index].selected = !temp[index].selected;
		staticObsticaleListRef.current = temp;
		setStaticObsticaleList(
			temp.map((item, i) => {
				if (i !== index) {
					item.selected = false;
					return item;
				} else {
					return item;
				}
			})
		);
	};

	const deletePedestrianCrossingMarkers = (deletedItem) => {
		// * delete from markerOnMap
		// * delete from definedPedestrianRoute.current
		// * delete from markerOnMapState
		// * remove the icons of particular markers
		const indexToDeleteInRoute = definedPedestrianRoute.current.findIndex((innerArray) => innerArray.some((item) => item.id === deletedItem.additional.id));
		if (indexToDeleteInRoute === -1) {
			toast.info("Cannot find the crossings id")
			return;
		}
		// removing the line layer as well
		map.current.removeLayer(`line-layer-${definedPedestrianRoute.current[indexToDeleteInRoute][1].id}`)
		definedPedestrianRoute.current.splice(indexToDeleteInRoute, 1);
		// finding the index of the crossing to be removed
		const indexToDelete = markersOnMap.current.findIndex(item => item.additional.id === deletedItem.additional.id);
		// removing the icons on the map
		markersOnMap.current[indexToDelete].marker.remove();
		markersOnMap.current[indexToDelete + 1].marker.remove();
		// deleting the two markers to and from
		markersOnMap.current.splice(indexToDelete, 2);
		// setting updated state
		setMarkersOnMapState([...markersOnMap.current]);
	}
	
	const deleteStaticObjectsMarkers = (deletedItem) => {
		// * Delete the markers from markersOnMap array
		// * Delete the markers from markersOnMapState array
		// * Remove the icons of markers from map
		const indexToDelete = markersOnMap.current.findIndex(item => item.additional.id === deletedItem.additional.id);
		// removing the icons on the map
		markersOnMap.current[indexToDelete].marker.remove();
		// Deleting the markers
		markersOnMap.current.splice(indexToDelete, 1);
		// setting updated state
		setMarkersOnMapState([...markersOnMap.current]);
	}

	const onClickViewSavedStaticObjectsButton = (e) => {
		setShowStaticObjects(!showStaticObjects);
		const staticObjects = markersOnMapState.filter(item => item.type === "static-object");

		if (showStaticObjects) { // visible! make it go away
			staticObjects.forEach(obj => {
				obj.marker.remove()
			})
		}
		else {
			if (staticObjects.length > 0) {
				// TODO: Fly to first static object

			}
			staticObjects.forEach(obj => {
				obj.marker.addTo(map.current)
			})
		}
	}

	const onClickViewSavedPedistrianCrossingButton = (e) => {
		setShowPedestrianCrossings(!showPedestrianCrossings);
		const crossings = markersOnMapState.filter((item) => item.type === "pedestrian-start" || item.type === "pedestrian-end");
		if (showPedestrianCrossings) { // visible! make it go away
			crossings.forEach(obj => obj.marker.remove())
		}
		else {
			if (crossings.length > 0) {
				// TODO: Fly to first static object
			}
			crossings.forEach(obj => {
				obj.marker.addTo(map.current);
			});
		}
	}
	
	/**
	 * ########################################################################################
	 * # UI Renderers
	 * ########################################################################################
	 */

	const renderActions = () => {
		if (primaryActionActiveButton === MY_SCENARIO_INTERACTION_PANELS.egoRoute) {
			return (
				<Fragment>
					<Typos.Body1>{PresentableText(primaryActionActiveButton || "")}</Typos.Body1>
					{
					savedEgoRoute ? 
					<Typos.Caption color="gray">You have to delete your previous route to be able to create new one.</Typos.Caption> :
					<Typos.Caption>Select test vehicle for the EGO car</Typos.Caption>  
					}
					{loadingEgoTestVehicles ? (
						<div className={``}>
							{[...Array(7).keys()].map((item, i) => (
								<Skeleton animation="wave" key={i} width={"100%"} height={36} />
							))}
							{/* <ProgressX /> */}
						</div>
					) : (
						<Fragment>
							<VirtualizedList
								data={egoTestVehiclesList}
								disableRecordsQuery={{ application_version_id: null }}
								namekey={"name"}
								callback={onSelectEgoTestVehicleTypeFromList}
								selectedIndex={selectedEgoTestVehicle}
							/>
							<CollapseContent show={selectedEgoTestVehicle !== null && !savedEgoRoute}>
								<hr />
								{loadingEgoTestVehicleDetails ?
									<div className="">
										<ProgressX block={true} height={120} width={"100%"} className={`mb-1`} />
										<ProgressX block={true} height={30} width={"100%"} className={`mb-1`} />
										<ProgressX block={true} height={30} width={"100%"} className={`mb-1`} />
										<ProgressX block={true} height={30} width={"100%"} className={`mb-1`} />
										<hr />
										<ProgressX block={true} height={40} width={"100%"} />
									</div> :
									(selectedEgoTestVehicle !== null && (
										<Fragment>
											<div className={`d-flex justify-content-center`}>
												<SquareImage src={TEST_VEHICLE} alt="test-vehicle" size={120} />
											</div>
											<div className={`text-center`}>
												<Typos.H6>{selectedEgoTestVehicleDetails?.display_name}</Typos.H6>
												<Typos.Caption>{egoTestVehiclesList[selectedEgoTestVehicle].name}</Typos.Caption>
											</div>
											<div className={``}>
												{mapFunctionalityActiveButton === "interact" && primaryActionActiveButton && (
													<div className={`mt-2`}>
														{secondaryActionActiveButton === "acceleration" && (
															<TextField
																type="number"
																className={`me-2`}
																variant="outlined"
																size="small"
																label="acceleration"
																onChange={(e) => {
																	accelerationTextField.current = e.target.value;
																}}
															/>
														)}
														<ToggleButtonGroup fullWidth size="small" exclusive value={secondaryActionActiveButton} onChange={onSecondaryActionButtonClick}>
															<ToggleButton value="edit" disabled={usingFile}>
																<MIcons.EditRoadRounded />
															</ToggleButton>
															<ToggleButton value="acceleration" disabled={usingFile}>
																<MIcons.AddLocationRounded />
																<MIcons.SpeedRounded
																	sx={{
																		marginLeft: "-5px",
																		marginBottom: "-10px",
																		width: "21px",
																		height: "21px",
																	}}
																/>
															</ToggleButton>
														</ToggleButtonGroup>
													</div>
												)}
											</div>
											<hr />
											<div className={``}>
												{vehicleSelectedRoutePolygons.current.length > 0 && (
													<ToggleButtonGroup fullWidth size="small">
														<Tooltip title="Save Route">
															<ToggleButton value="add" onClick={onSaveClick}>
																<MIcons.SaveRounded />
															</ToggleButton>
														</Tooltip>
														<Tooltip title="Delete Route">
															<ToggleButton value="acceleration" onClick={onDeleteClick}>
																<MIcons.DeleteRounded />
															</ToggleButton>
														</Tooltip>
													</ToggleButtonGroup>
												)}
											</div>
											<div className={`mt-2`}>
												<ButtonX
													fullwidth={true}
													onClick={onClickNext}
													disabled={savedEgoRoute === null || savedActorsRoutes.length === 0 || vehicleInitialPosition.current !== null}
												>
													Next
												</ButtonX>
											</div>
										</Fragment>
									))}

							</CollapseContent>
						</Fragment>
					)}
				</Fragment>
			);
		}
		else if (primaryActionActiveButton === MY_SCENARIO_INTERACTION_PANELS.actorsRoutes) {
			return (
				<Fragment>
					<Typos.Body1>{PresentableText(primaryActionActiveButton || "")}</Typos.Body1>
					<Typos.Caption>Select vehicle for the actor</Typos.Caption>
					{loadingActorVehicles ? (
						<div className={``}>
							{[...Array(7).keys()].map((item, i) => (
								<Skeleton animation="wave" key={i} width={"100%"} height={36} />
							))}
							{/* <ProgressX /> */}
						</div>
					) : (
						<Fragment>
							<Autocomplete
								groupBy={(option) => option.vehicle}
								filterOptions={(options) => options.filter(option => option.vehicle !== "Pedestrian")}
								disabled={savedActorsRoutes.length >= ALLOWED_ACTORS_COUNT}
								getOptionLabel={(option) => option.value.display_name}
								options={vehiclesListForActors}
								renderInput={(params) => <TextField {...params} size="small" variant="filled" label="Vehicles" helperText={`You can add upto ${ALLOWED_ACTORS_COUNT} actors`} />}
								onChange={onSelectVehicleTypeFromList}
								value={selectedActorVehicle}
								isOptionEqualToValue={(option, value) => value.value.id === option.value.id}
							/>
							<CollapseContent show={selectedActorVehicle !== null}>
								<hr />
								{selectedActorVehicle !== null && (
									<Fragment>
										<div className={`d-flex justify-content-center`}>
											<SquareImage src={TEST_VEHICLE} alt="test-vehicle" size={120} />
										</div>
										<div className={`text-center`}>
											<Typos.H6>{selectedActorVehicle.value.display_name}</Typos.H6>
											<Typos.Caption>
												{selectedActorVehicle.vehicle} | {selectedActorVehicle.value.prefab_name}
											</Typos.Caption>
										</div>

										<div className={``}>
											{mapFunctionalityActiveButton === "interact" && primaryActionActiveButton && (
												<div className={`mt-2`}>
													{secondaryActionActiveButton === "acceleration" && (
														<TextField
															type="number"
															className={`me-2`}
															variant="outlined"
															size="small"
															label="acceleration"
															onChange={(e) => {
																accelerationTextField.current = e.target.value;
															}}
														/>
													)}
													<ToggleButtonGroup fullWidth size="small" exclusive value={secondaryActionActiveButton} onChange={onSecondaryActionButtonClick}>
														<ToggleButton value="add" disabled={savedActorsRoutes.length === ALLOWED_ACTORS_COUNT}>
															<MIcons.EditRoadRounded />
														</ToggleButton>
														<ToggleButton value="acceleration">
															<MIcons.SpeedRounded />
														</ToggleButton>
														<ToggleButton value="clear">
															<input type="color" color={currentColor} onChange={onColorChange} />
														</ToggleButton>
													</ToggleButtonGroup>
												</div>
											)}
										</div>
										<hr />
										<div className={``}>
											{vehicleSelectedRoutePolygons.current.length > 0 && (
												<ToggleButtonGroup fullWidth size="small">
													<Tooltip title="Save Route">
														<ToggleButton value="add" onClick={onSaveClick}>
															<MIcons.SaveRounded />
														</ToggleButton>
													</Tooltip>
													<Tooltip title="Delete Route">
														<ToggleButton value="acceleration" onClick={onDeleteClick}>
															<MIcons.DeleteRounded />
														</ToggleButton>
													</Tooltip>
												</ToggleButtonGroup>
											)}
										</div>
										<div className={`mt-2`}>
											<ButtonX
												fullwidth={true}
												onClick={onClickNext}
												disabled={
													savedEgoRoute === null ||
													savedActorsRoutes.length === 0 ||
													vehicleInitialPosition.current !== null ||
													savedActorsRoutes.length === 4
												}
											>
												Next
											</ButtonX>
										</div>
									</Fragment>
								)}
							</CollapseContent>
						</Fragment>
					)}
				</Fragment>
			);
		}
		else if (primaryActionActiveButton === MY_SCENARIO_INTERACTION_PANELS.staticObjects) {
			return (
				<Fragment>
					<div className="text-center mt-3">
						<Typos.Body1>Select static objects to place on EGO route</Typos.Body1>
						<Typos.Caption color="gray">Objects can be placed on EGO route only</Typos.Caption>
					</div>
					<hr />
					{/* List of objects */}
					<List dense style={{ maxHeight: "35vh", overflow: "auto" }}>
						{loadingStaticObjects
							? [...Array(7).keys()].map((item, i) => <Skeleton animation="wave" key={i} width={"100%"} height={36} />)
							: staticObsticaleList.map((item, i) => {
								return (
									<ListItemButton
										key={i}
										disabled={!savedEgoRoute?.route?.length || markersOnMap.current.filter(item => item.type === "static-object").length >= ALLOWED_STATIC_OBJECTS_COUNT}
										onClick={() => onClickSelectStaticObject(i)}>
										<ListItemIcon>{<Checkbox size="small" checked={item.selected} />}</ListItemIcon>
										<ListItemText>{item.name}</ListItemText>
										<ListItemSecondaryAction>
											<Avatar src={item.meta.png_icon} variant="rounded" sx={{ width: 35, height: 35 }}>
												<MIcons.CategoryRounded fontSize="small" />
											</Avatar>
										</ListItemSecondaryAction>
									</ListItemButton>
								);
							})}

					</List>
					<Typos.Caption color={"primary"} className={`text-center mt-2`}>You can place upto {ALLOWED_STATIC_OBJECTS_COUNT} Objects</Typos.Caption>
					{
						!savedEgoRoute?.route?.length &&
						<Typos.Caption color={"red"} className={`text-center mt-2`}>
							Please define EGO route to place static objects
						</Typos.Caption>
					}
				</Fragment>
			);
		}
		else if (primaryActionActiveButton === MY_SCENARIO_INTERACTION_PANELS.pedestrians) {
			return (
				<Fragment>
					<Typos.Caption className={`text-center`}>Click on any polygon to create pedestrian crossing on roads</Typos.Caption>
					<hr />
					<Typos.Caption color="primary" className={`text-center`}>You can place upto {ALLOWED_PEDESTRIAN_COUNT} crossings</Typos.Caption>
					{/* <ButtonX className={`mt-2`} fullwidth={true} >
						Start Pedestrian Route
					</ButtonX> */}
				</Fragment>
			);
		} else {
			return "";
		}
	};

	const renderPedestrians = () => {
		const filteredArray = markersOnMapState.filter((item) => item.type === "pedestrian-start" || item.type === "pedestrian-end");
		if (filteredArray.length === 0) {
			return <Typos.Caption>Click on the Pedestrian from right panel to create crossings</Typos.Caption>
		}
		if (filteredArray.length > 1 && filteredArray.length % 2 === 0) {
			// pedestrian routes are complete
			return (
				<List dense>
					{filteredArray.map((item, i) => {
						if (i % 2 === 0) {
							const nextItem = filteredArray[i + 1];
							const CrossingIcon1 = MIcons[item.additional.icon];
							const CrossingIcon2 = nextItem ? MIcons[nextItem.additional.icon] : MIcons["StarRounded"];
							return (
								<ListItem key={i} disablePadding>
									<ListItemIcon>
										<CrossingIcon1 />
										<MIcons.HorizontalRuleRounded color="error" />
										<CrossingIcon2 />
									</ListItemIcon>
									<ListItemText className="ms-2" primary={`Pedestrian Crossing ${(i + 2) / 2}`} />
									{
										!MyScenario.readonly &&
										<ListItemSecondaryAction>
											{/* <IconButtonX size={"small"} icon={"RemoveRedEyeRounded"} /> */}
											<IconButtonX size={"small"} icon={"DeleteRounded"} onClick={() => deletePedestrianCrossingMarkers(item)} />
										</ListItemSecondaryAction>
									}
								</ListItem>
							);
						} else {
							return null;
						}
					})}
				</List>
			);
		}
		else {
			return (
				<div className={`text-center`}>
					<Typos.Caption>Pedestrian Route in progress</Typos.Caption>
					<ProgressX block={true} />
				</div>
			);
		}
	}

	const renderStaticObjects = () => {
		const filteredArray = markersOnMapState.filter((item) => item.type === "static-object")
		if (!savedEgoRoute) {
			return <Typos.Caption>Define an EGO route and select static objects from right panel to place.</Typos.Caption>
		}
		if (filteredArray.length === 0) {
			return <Typos.Caption>Select static objects from right panel to place on EGO route.</Typos.Caption>
		}
		return <List dense>
			{
				filteredArray.map((item, i) => {
					let Ico = null;
					if (IsValidURL(item.additional.icon)) {
						Ico = <Avatar src={item.additional.icon} sx={{ width: 25, height: 25 }} variant="rounded" />;
					}
					else {
						Ico = MIcons[TEMP_STATIC_OBJECTS[4].icon];
						Ico = <Ico />
					}
					return (
						<ListItem key={i} disablePadding>
							<ListItemIcon>{Ico}</ListItemIcon>
							<ListItemText primary={`${item.additional.name} ${i + 1}`} />
							{
								!MyScenario.readonly &&
								<ListItemSecondaryAction>
									<IconButtonX size={"small"} icon={"DeleteRounded"} onClick={() => deleteStaticObjectsMarkers(item)} />
								</ListItemSecondaryAction>
							}
						</ListItem>
					);
				})}
		</List>
	}

	const handleOpen = () => {
		setOpen(true);
	  };

	const handleClose = () => setOpen(false);

	  const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
		  setSelectedFile(file);
		  const reader = new FileReader();
		  reader.onload = (e) => {
			const content = e.target.result;
			try {
			  const json = JSON.parse(content);
			  if (Object.keys(json.scenario).length === 0) {
				toast.error("The uploaded file does contain an empty object");
				setFileContent(null);
			} else {
				setFileContent(json);
				console.log("File content", json);
				ReduxUpdateMyScenarioJourney({scenarioFile: json})
			}
			} catch (err) {
			  console.error("Error parsing JSON:", err);
			}
		  };
		  reader.readAsText(file);
		}
	  };

	  const handleFileUpload = async () => {
		setIsLoad(true)
		if (selectedFile) {
		  console.log("Uploading file", selectedFile);
		  setTimeout(() => {
			toast.info(selectedEgoTestVehicleDetails ? "Vehicle profile selected, click to change" : "Select vehicle profile");
			handleClose()
			setIsLoad(false)
			setMapFunctionalityActiveButton("interact")
		  }, 1000);
		} else {
		  toast.error("No file selected");
		  setIsLoad(false)
		}
	  };

	  const handleUsingFile = (e) => {
		setUsingFile(e.target.checked)
		ReduxUpdateMyScenarioJourney({usingFile: e.target.checked})
	  }

	  const handleFileDelete = () => {
		setSelectedFile(null);
		setFileContent(null);
		setInputKey(Date.now());
		setSelectedEgoTestVehicle(null)
		setSelectedEgoTestVehicleDetails(null)
	  };

	  const downloadFile = () => {
		const fileUrl = MyScenario.prevUserScenario;

		const jsonString = JSON.stringify(fileUrl, null, 2);

		const fileBlob = new Blob([jsonString], { type: "application/json" });

		// Create a temporary anchor element
		const downloadLink = document.createElement("a");
		downloadLink.href = URL.createObjectURL(fileBlob);

		// Set the file name
		downloadLink.download = "scenario.json";

		// Append the anchor element to the DOM
		document.body.appendChild(downloadLink);

		// Simulate a click event to trigger the download
		downloadLink.click();

		// Clean up by removing the anchor element
		document.body.removeChild(downloadLink);
	};

	return (
		<>
		<ModalX
			open={open}
			close={handleClose}
			heading={<span>Upload Scenario</span>}
			subheading={<span>Select JSON file</span>}
      	>
			<Grid
			container
			direction="column"
			alignItems="center"
			sx={{
				border: "1px solid #31496e",
				borderRadius: "4px",
				maxHeight: "30em",
				overflowY: "auto",
			}}
			>
			<Typography sx={{padding: "1em"}}>
				{fileContent ? (
				<>
					{selectedFile.name}
					<IconButton onClick={handleFileDelete} sx={{ marginLeft: 1 }}>
					<DeleteIcon />
					</IconButton>
				</>
				) : "No file selected"}
			</Typography>
			</Grid>
			<p
				style={{
				marginTop: "1rem",
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				}}
			>
				{/* Click to select files */}
				<Button
				component="label"
				role={undefined}
				tabIndex={-1}
				sx={{
					mt: 2,
					backgroundColor: "#00A3B5",
					color: "white",
					height: "40px",
					textTransform: "capitalize",
					fontSize: "16px",
					fontWeight: 600,
					"&:hover": {
					backgroundColor: "#00A3B5",
					},
				}}
				disabled={fileContent}
				>
				Select file
				<VisuallyHiddenInput
					key={inputKey}
					type="file"
					// multiple
					accept=".json"
					onChange={handleFileChange}
				/>
				</Button>
			</p>
			<Grid sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
			<ButtonX
				onClick={handleFileUpload}
				disabled={!fileContent || isLoad}
			>
				Upload
				{isLoad ? (
				<CircularProgress
					size={20}
					style={{ marginLeft: "8px" }}
					color="inherit"
				/>
				) : (
				""
				)}
			</ButtonX>
			</Grid>
		</ModalX>
		
		<div className={`container-fluid`}>
			{loading && <ProgressX fullscreen={true} />}
			<ConfirmationModal
				open={openConfirmationModal}
				heading={<span>Confirm Action</span>}
				subheading={<span>Going back will discard unsaved changes</span>}
				close={() => setOpenConfirmationModal(false)}
				confirmationMessage={
					<span>
						Are you sure you want to go back? If you go back, your changes will
						be lost.
					</span>
				}
				onConfirm={onClickBack}
			/>
			<div className={`row`}>
				<div className={`col-12 d-flex justify-content-between`}>
					<div className={`d-flex`}>
						{/* <ButtonX>Load existing map</ButtonX>
						<ButtonX className={`ms-2`}>Create New Map</ButtonX> */}
						{!MyScenario.readonly && (
							<>
								<FormGroup>
								<FormControlLabel
									control={<Switch checked={usingFile} onChange={handleUsingFile} disabled={savedEgoRoute} />}
									label="Import Scenario"
								/>
								</FormGroup>
								<IconButton
								sx={{ ml: 0, mr: 1 }}
								onClick={handleOpen}
								disabled={!usingFile}
								>
								<CloudUploadIcon />
								</IconButton>
							</>
						)}
						<div className={``}>
							{
								MyScenario.readonly && <ReadOnlyTag />
							}
						</div>
						{MyScenario.readonly && MyScenario.usingFile === true &&
							<Tooltip title="Download File">
							  <IconButton onClick={downloadFile} sx={{marginLeft: "10px"}}>
								<CloudDownloadIcon />
							  </IconButton>
							</Tooltip>
						}
					</div>
					<div className={`d-flex`}>
						<ButtonX className={`me-2`} onClick={onClickBack} disabled={false}>
							Back
						</ButtonX>
						{MyScenario.readonly ?
							<ButtonX onClick={onClickNext}>
								Next
							</ButtonX> 
							:
							<ButtonX
								onClick={onClickNext}
								disabled={
									fileContent !== null ? selectedEgoTestVehicleDetails === null : '' || savedEgoRoute === null ||
									(savedActorsRoutes.length === 0 && markersOnMapState.filter(item => item.type === "pedestrian-end").length === 0) ||
									vehicleInitialPosition.current !== null
								}>
								Next
							</ButtonX>
						}
					</div>
				</div>
			</div>
			<div className={`map-container mt-3`}>
				<div className={`map-available-geojsons-sidebar px-3 py-3`} style={{ maxHeight: "70vh", overflowX: "hidden", overflowY: "auto" }}>
					{/* Map layers list panel */}
					<Accordion sx={{ backgroundColor: "rgba(35, 55, 75, 0.6)" }} expanded={expandedPanel === "map-layers-panel"} onChange={handleChangePanel("map-layers-panel")}>
						<AccordionSummary expandIcon={<MIcons.ExpandMoreRounded />}>
							<Typos.Body1 className={`fw-bold`}>Available Map Layers</Typos.Body1>
						</AccordionSummary>
						<AccordionDetails sx={{ paddingTop: 0 }}>
							<Typos.Caption color="gray">Enable the layer to see the number of polygons in it.</Typos.Caption>
							{geojsonFilesURLs && Object.keys(geojsonFilesURLs).map((item, i) => {
								return (
									<div key={i} className={`mt-1`}>
										<SwitchButtonX
											disabled={loading}
											id={item}
											checked={geojsonFilesURLs[item].active}
											onChange={(e) => handleSourceLoad(e, item)}
											label={`${PresentableText(item)} ${geojsonFilesURLs[item].count ? `(${geojsonFilesURLs[item].count})` : ''}`}
										/>
									</div>
								);
							})}
						</AccordionDetails>
					</Accordion>
					{
						!MyScenario.readonly &&
						<div className={`row mt-2`}>
							<div className={`col-12`}>
								<ToggleButtonGroup
									fullWidth
									color="info"
									size="small"
									className={`me-2`}
									disabled={loading}
									exclusive
									value={mapFunctionalityActiveButton}
									onChange={onMapFunctionalityActionButtonClick}
								>
									<ToggleButton sx={{ fontSize: "1rem" }} value="view">
										View
									</ToggleButton>
									<ToggleButton sx={{ fontSize: "1rem" }} value="interact">
										Interact
									</ToggleButton>
								</ToggleButtonGroup>
							</div>
						</div>
					}
					{showEditingPanel && (
						<Fragment>
							<hr />
							<Accordion
								sx={{ backgroundColor: "rgba(35, 55, 75, 0.6)" }}
								expanded={expandedPanel === MY_SCENARIO_INTERACTION_PANELS.egoRoute}
								onChange={handleChangePanel(MY_SCENARIO_INTERACTION_PANELS.egoRoute)}
							>
								<AccordionSummary expandIcon={<MIcons.ExpandMoreRounded />}>
									<div className={`w-100 d-flex align-items-center`}>
										<div className={`d-flex`}>
											<MIcons.DirectionsCarRounded fontSize="small" />
											<Typos.Body1 className={`ms-1`}>EGO route</Typos.Body1>
										</div>
									</div>
								</AccordionSummary>
								<AccordionDetails>
									{savedEgoRoute?.route?.length > 0 ? (
										<Fragment>
											<div style={{ maxHeight: "15vh", overflowX: "hidden", overflowY: "auto" }}>
												<List dense>
													{savedEgoRoute?.route.map((item, i) => {
														return (
															<ListItem key={i} disablePadding>
																<ListItemIcon>
																	<MIcons.DirectionsCarRounded edge="start" size={"small"} />
																</ListItemIcon>
																<ListItemText primary={item.id} />
																<ListItemSecondaryAction>
																	<IconButtonX icon={"NearMeRounded"} />
																</ListItemSecondaryAction>
															</ListItem>
														);
													})}
												</List>
											</div>
											<hr />
											<div className="d-flex justify-content-end">
												{
													!MyScenario.readonly && savedEgoRoute?.route.length && (
														<ButtonWithIcon icon={<MIcons.DeleteRounded />} color={"error"} size={"small"} onClick={onClickDeleteSavedEgoRouteButton}>
															Delete Route
														</ButtonWithIcon>
													)}

												<ButtonWithIcon
													className={`ms-2`}
													icon={showEgoRoute ? <MIcons.VisibilityOffRounded /> : <MIcons.RemoveRedEyeRounded />}
													size={"small"}
													onClick={onClickViewSavedEgoRouteButton}
												>
													{showEgoRoute ? "Hide" : "View"} Route
												</ButtonWithIcon>
											</div>
										</Fragment>
									) : (
										<Typos.Caption>
											Select <Link onClick={(e) => onMapFunctionalityActionButtonClick(e, "interact")}>Interact</Link> from above options to open EGO route
											options from the right panel and then define the EGO route.
										</Typos.Caption>
									)}
								</AccordionDetails>
							</Accordion>
							<Accordion
								sx={{ backgroundColor: "rgba(35, 55, 75, 0.6)" }}
								expanded={expandedPanel === MY_SCENARIO_INTERACTION_PANELS.actorsRoutes}
								onChange={handleChangePanel(MY_SCENARIO_INTERACTION_PANELS.actorsRoutes)}
							>
								<AccordionSummary expandIcon={<MIcons.ExpandMoreRounded />}>
									<div className={`w-100 d-flex align-items-center`}>
										<div className={`d-flex`}>
											<MIcons.CommuteRounded />
											<Typos.Body1 className={`ms-1`}>Actors routes ({savedActorsRoutes.length}/{ALLOWED_ACTORS_COUNT})</Typos.Body1>
										</div>
									</div>
								</AccordionSummary>
								<AccordionDetails>
									{savedActorsRoutes?.length > 0 ? (
										<Fragment>
											<div style={{ maxHeight: "15vh", overflowX: "hidden", overflowY: "auto" }}>
												<List dense>
													{savedActorsRoutes.map((item, i) => {
														return (
															<ListItem key={i} disablePadding>
																<ListItemIcon>
																	<MIcons.CommuteRounded
																		sx={{ color: item.polygonColor }}
																		edge="start"
																		fontSize={"small"}
																	/>
																</ListItemIcon>
																<ListItemText
																	primary={
																		<div className={`d-flex`}>
																			<Typos.Body1 className={`ms-0`}>
																				Id: {item.vehicle.value.id}, {item.vehicle.value.type}: {item.vehicle.value.display_name}
																			</Typos.Body1>
																		</div>
																	}
																/>
																<ListItemSecondaryAction id={i} onClick={() => onClickViewSavedSingleActorRouteButton(i)}>
																	{allActorsViewState.includes(item.id)? <IconButtonX icon={"RemoveRedEyeRounded"} />: <IconButtonX icon={"VisibilityOffRounded"}/>}
																</ListItemSecondaryAction>
																{/* {mapFunctionalityActiveButton === "view" && ""} */}
															</ListItem>
														);
													})}
												</List>
											</div>
											<hr />
											<div className={`d-flex justify-content-end mt-2`}>
												{
													!MyScenario.readonly && <ButtonWithIcon icon={<MIcons.DeleteRounded />} color={"error"} size={"small"} onClick={onClickDeleteSavedAllActorsRoutesButton}>
														Delete All
													</ButtonWithIcon>
												}
												<ButtonWithIcon className={`ms-2`} icon={<MIcons.ViewWeekRounded />} size={"small"} onClick={() => onClickViewSavedAllActorsRoutesButton(allActorsViewState.length === 0)}>
													{allActorsViewState.length === 0 ? 'View': 'Hide'} All
												</ButtonWithIcon>
											</div>
										</Fragment>
									) : (
										<Typos.Caption>Select Actor from right panel to add a new traffic vehicle.</Typos.Caption>
									)}
								</AccordionDetails>
							</Accordion>
							{/* Left panel added Static objects on EGO Route display */}
							<Accordion
								sx={{ backgroundColor: "rgba(35, 55, 75, 0.6)" }}
								expanded={expandedPanel === MY_SCENARIO_INTERACTION_PANELS.staticObjects}
								onChange={handleChangePanel(MY_SCENARIO_INTERACTION_PANELS.staticObjects)}
							>
								<AccordionSummary expandIcon={<MIcons.ExpandMoreRounded />}>
									<div className={`w-100 d-flex align-items-center`}>
										<div className={`d-flex`}>
											<MIcons.CategoryRounded fontSize="small" />
											<Typos.Body1 className={`ms-1`}>Static Objects ({markersOnMapState.filter((item) => item.type === "static-object").length}/{ALLOWED_STATIC_OBJECTS_COUNT})</Typos.Body1>
										</div>
									</div>
								</AccordionSummary>
								<AccordionDetails>
									<div style={{ maxHeight: "15vh", overflowX: "hidden", overflowY: "auto" }}>
										{renderStaticObjects()}
									</div>
									{
										markersOnMapState.filter(item => item.type === "static-object").length > 0 &&
										<Fragment>
											<hr />
											<div className={`d-flex justify-content-end`}>
												<ButtonWithIcon
													className={`ms-2`}
													icon={showStaticObjects ? <MIcons.VisibilityOffRounded /> : <MIcons.RemoveRedEyeRounded />}
													size={"small"}
													onClick={onClickViewSavedStaticObjectsButton}
												>
													{showStaticObjects ? "Hide" : "View"} All Objects
												</ButtonWithIcon>
											</div>

										</Fragment>
									}
								</AccordionDetails>
							</Accordion>
							{/* Left panel Pedestrian crossings display */}
							<Accordion
								sx={{ backgroundColor: "rgba(35, 55, 75, 0.6)" }}
								expanded={expandedPanel === MY_SCENARIO_INTERACTION_PANELS.pedestrians}
								onChange={handleChangePanel(MY_SCENARIO_INTERACTION_PANELS.pedestrians)}
							>
								<AccordionSummary expandIcon={<MIcons.ExpandMoreRounded />}>
									<div className={`w-100 d-flex align-items-center`}>
										<div className={`d-flex`}>
											<MIcons.TransferWithinAStationRounded fontSize="small" />
											<Typos.Body1 className={`ms-1`}>Pedestrians ({markersOnMapState.filter((item) => item.type === "pedestrian-start").length}/{ALLOWED_PEDESTRIAN_COUNT})</Typos.Body1>
										</div>
									</div>
								</AccordionSummary>
								<AccordionDetails>
									<div style={{ maxHeight: "15vh", overflowX: "hidden", overflowY: "auto" }}>
										{renderPedestrians()}
									</div>
									{
										markersOnMapState.filter((item) => item.type === "pedestrian-start" || item.type === "pedestrian-end").length > 0 &&
										<Fragment>
											<hr />
											<div className={`d-flex justify-content-end`}>
												<ButtonWithIcon
													className={`ms-2`}
													icon={showPedestrianCrossings ? <MIcons.VisibilityOffRounded /> : <MIcons.RemoveRedEyeRounded />}
													size={"small"}
													onClick={onClickViewSavedPedistrianCrossingButton}
												>
													{showPedestrianCrossings ? "Hide" : "View"} Crossings
												</ButtonWithIcon>
											</div>

										</Fragment>
									}
								</AccordionDetails>
							</Accordion>
						</Fragment>
					)}

				</div>
				{mapFunctionalityActiveButton === "interact" && showEditingPanel && (
					<div className={`map-elements p-3`}>
						<ToggleButtonGroup 
							fullWidth 
							size="small" 
							exclusive 
							value={primaryActionActiveButton} 
							onChange={onPrimaryActionButtonClick}
							disabled={allowEditRoute.current}>
							<ToggleButton value={MY_SCENARIO_INTERACTION_PANELS.egoRoute}>
								<Tooltip title={PresentableText(MY_SCENARIO_INTERACTION_PANELS.egoRoute)}>
									<MIcons.DirectionsCarRounded />
								</Tooltip>
							</ToggleButton>
							<ToggleButton value={MY_SCENARIO_INTERACTION_PANELS.actorsRoutes} disabled={usingFile}>
								<Tooltip title={PresentableText(MY_SCENARIO_INTERACTION_PANELS.actorsRoutes)}>
									<MIcons.EmojiTransportationRounded />
								</Tooltip>
							</ToggleButton>
							<ToggleButton value={MY_SCENARIO_INTERACTION_PANELS.staticObjects} disabled={usingFile}>
								<Tooltip title={PresentableText(MY_SCENARIO_INTERACTION_PANELS.staticObjects)}>
									<MIcons.CategoryRounded />
								</Tooltip>
							</ToggleButton>
							<ToggleButton value={MY_SCENARIO_INTERACTION_PANELS.pedestrians} disabled={usingFile}>
								<Tooltip title={PresentableText(MY_SCENARIO_INTERACTION_PANELS.pedestrians)}>
									<MIcons.TransferWithinAStationRounded />
								</Tooltip>
							</ToggleButton>
						</ToggleButtonGroup>

						<div className={`mt-2`}>{mapFunctionalityActiveButton === "interact" &&
							renderActions()
						}</div>
					</div>
				)}
				<div className="interactive-map-corrdinates-bar">
					Lng: {lng} | Lat: {lat} | Zoom: {zoom}
				</div>
				<div ref={mapContainer} className="map-view" />
			</div>
		</div>
		</>
	);
}
export default connect(null, { ReduxUpdateMyScenarioJourney })(InteractiveMap)
