import React, { useState } from 'react'

import { Grid, Typography, Box, Button, TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { getStaticActors, setExecuteTime } from '../../stateMaintain';

export default function StaticActorForm() {
    const [state, setState] = useState({
        executionTime: 3,
    })
    const [selectStochastic, setSelectStochastic] = useState([])
    const [selectedStochastic, setSelectedStochastic] = useState([])
    const [stochasticList, setStochasticList] = useState(
        [
            {
                "p1": 0,
                "p2": 0,
                "p3": 0,
                "p4": 1,
                "name": "S-Fast Break Rules Aggressive",
                "target_speed": 250,
                "rule_abiding": 11,
                "uniform_distribution": "Uniform"
            },
            {
                "p1": 0,
                "p2": 0,
                "p3": 0,
                "p4": 1,
                "name": "S-Fast Obey Rules Aggressive",
                "target_speed": 250,
                "rule_abiding": -1,
                "uniform_distribution": "Uniform"
            },
            {
                "p1": 0.1,
                "p2": 0.4,
                "p3": 0.4,
                "p4": 0.1,
                "name": "S-Break Rules Moderate (Car)",
                "target_speed": 150,
                "rule_abiding": 11,
                "uniform_distribution": "Uniform"
            },
            {
                "p1": 0.1,
                "p2": 0.4,
                "p3": 0.4,
                "p4": 0.1,
                "name": "S-Break Rules Moderate (Bus)",
                "target_speed": 100,
                "rule_abiding": 11,
                "uniform_distribution": "Uniform"
            },
            {
                "p1": 0.1,
                "p2": 0.4,
                "p3": 0.4,
                "p4": 0.1,
                "name": "S-Break Rules Moderate (Van)",
                "target_speed": 100,
                "rule_abiding": 11,
                "uniform_distribution": "Uniform"
            },
        ])

    const [selectTrafficComp, setSelectTrafficComp] = useState([])
    const [selectedTrafficComp, setSelectedTrafficComp] = useState([])
    const [trafficCompList, setTrafficCompList] = useState(
        [
            {
                "driver_profile": "S-Break Rules Moderate (Car)",
                "vehicle_profile": "Toyota-Prius-2019",
                "percentage": 20
            },
            {
                "driver_profile": "S-Break Rules Moderate (Car)",
                "vehicle_profile": "Toyota-Corolla-Hatchback-2019",
                "percentage": 20
            },
            {
                "driver_profile": "S-Break Rules Moderate (Car)",
                "vehicle_profile": "Toyota-Camry",
                "percentage": 20
            },
            {
                "driver_profile": "S-Fast Obey Rules Aggressive",
                "vehicle_profile": "Honda-Life",
                "percentage": 20
            },
            {
                "driver_profile": "S-Fast Break Rules Aggressive",
                "vehicle_profile": "Audi S5 2012",
                "percentage": 20
            },
        ])

    const handleSelectState = (event) => {
        const { name, value } = event.target
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const handleSelectStochasticMultiple = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        setSelectStochastic(value);
        const list = stochasticList.filter(item => value.includes(item.name))
        setSelectedStochastic(list)
    };

    const handleSelectTrafficMultiple = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value)
            }
        }
        setSelectTrafficComp(value);
        const list = trafficCompList.filter(item => value.includes(item.vehicle_profile))
        setSelectedTrafficComp(list)
    };

    const verifyTrafficComp = (data) => {
        const defineLength = 100 / data?.length
        const updateTrafficProfile = data && data.map(row => {
            row.percentage = parseInt(defineLength.toFixed(2))
            return row
        })
        return updateTrafficProfile
    }


    const onSaveData = () => {
        const trafficData = verifyTrafficComp(selectedTrafficComp)
        console.log('trafficData', trafficData) 
        const payload = {
            "driver_profiles": {
                "stochastic":   [
                    {
                        "p1": 0,
                        "p2": 0,
                        "p3": 0,
                        "p4": 1,
                        "name": "S-Fast Break Rules Aggressive",
                        "target_speed": 250,
                        "rule_abiding": 11,
                        "uniform_distribution": "Uniform"
                    },
                    {
                        "p1": 0,
                        "p2": 0,
                        "p3": 0,
                        "p4": 1,
                        "name": "S-Fast Obey Rules Aggressive",
                        "target_speed": 250,
                        "rule_abiding": -1,
                        "uniform_distribution": "Uniform"
                    },
                    {
                        "p1": 0.1,
                        "p2": 0.4,
                        "p3": 0.4,
                        "p4": 0.1,
                        "name": "S-Break Rules Moderate (Car)",
                        "target_speed": 150,
                        "rule_abiding": 11,
                        "uniform_distribution": "Uniform"
                    },
                    {
                        "p1": 0.1,
                        "p2": 0.4,
                        "p3": 0.4,
                        "p4": 0.1,
                        "name": "S-Break Rules Moderate (Bus)",
                        "target_speed": 100,
                        "rule_abiding": 11,
                        "uniform_distribution": "Uniform"
                    },
                    {
                        "p1": 0.1,
                        "p2": 0.4,
                        "p3": 0.4,
                        "p4": 0.1,
                        "name": "S-Break Rules Moderate (Van)",
                        "target_speed": 100,
                        "rule_abiding": 11,
                        "uniform_distribution": "Uniform"
                    },
                ],
                "ai": [],
                "rl": []
            },
            "traffic_composition": trafficCompList,
        }
        getStaticActors(payload)
        setExecuteTime(state.executionTime)
    }

    return (
        <Grid
            sx={{
                backgroundColor: "secondary.dark",
                paddingBottom: "12px",
                borderRadius: "8px",
            }}
        >

            <Grid className="vehicle-details-table-headings">
                <Typography
                    color="text.main"
                    sx={{ marginLeft: "1rem", marginTop: "0.5rem" }}
                >
                    Driver Profile
                </Typography>
            </Grid>
            <Grid className="vehicle-details-table-data">
                <Box p={2}>
                    <TextField
                        name="executionTime"
                        required
                        type="number"
                        variant="outlined"
                        label="Execution Time"
                        placeholder="Enter time"
                        value={state.executionTime}
                        onChange={handleSelectState}
                        fullWidth
                        disabled={false}
                    />
                </Box>
                <Box p={2} >
                    <FormControl sx={{ minWidth: "100%" }}>
                        <Typography
                            color="text.main"
                            sx={{ marginBottom: "0.5rem" }}
                        >
                            Stochastic
                        </Typography>
                        <Select
                            name="selectStochastic"
                            multiple
                            native
                            value={selectStochastic}
                            onChange={handleSelectStochasticMultiple}
                            sx={{
                                "& option:checked": {
                                    color: "text.main",
                                    backgroundColor: "primary.dark",
                                },
                            }}
                        >
                            {stochasticList.map((item, id) => (
                                <option key={id} value={item.name} style={{ padding: "6px" }}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Box p={2} >
                    <FormControl sx={{ minWidth: "100%" }}>
                        <Typography
                            color="text.main"
                            sx={{ marginBottom: "0.5rem" }}
                        >
                            Traffic Composition
                        </Typography>
                        <Select
                            name="selectTrafficComp"
                            multiple
                            native
                            value={selectTrafficComp}
                            onChange={handleSelectTrafficMultiple}
                            sx={{
                                "& option:checked": {
                                    color: "text.main",
                                    backgroundColor: "primary.dark",
                                },
                            }}
                        >
                            {trafficCompList.map((item, id) => (
                                <option key={id} value={item.vehicle_profile} style={{ padding: "6px" }}>
                                    {item.vehicle_profile}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box p={2} display="flex" justifyContent="end">
                    <Button
                        sx={{
                            backgroundColor: "primary.main",
                            "&:hover": {
                                backgroundColor: "primary.main",
                            },
                            color: "text.main",
                            float: 'right',
                            width: "200px",
                        }}
                        disabled={false}
                        onClick={onSaveData}
                        type="submit"
                    >
                        Save
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}
