import { SET_MYSCENARIO, UPDATE_MYSCENARIO } from ".."

const INITIAL_STATE = {
    pralionId: null,
    projectName: null,
    mapGenObject: null,
    routeConfiguration: null,
    testVehicle: null,
    mapGeometry: null,
    observerId: null,
    oddData: null,
}

export default function ReducerMyScenario(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_MYSCENARIO:
            return action.payload;
        case UPDATE_MYSCENARIO:
            return { ...state, ...action.payload }
        default:
            return state
    }
}