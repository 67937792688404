import React, { useState, Fragment, useEffect } from "react";
import { Grid, Button, Box, Radio } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonX, ModalX } from "components";
import LowFidelityForm from "./lowFidelityComponent/lowFidelityForm";
import { LowFidelityTableData } from "utils";
import { ReduxCreateLowFidelityJourney } from "services/redux-store/actions/actions-lowFidelity";
import { connect } from "react-redux";
import MainTable from "components/mainTable";
import { getScenarioExecutionList } from "endpointServices/scenarioExecutionServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dateConverter from "utils/dateConverter";
import AirplayIcon from "@mui/icons-material/Airplay";
import LowFidelityStatus from "./lowFidelityComponent/lowFidelityStatus";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { pageSize } from "utils/staticsData";

const LowFidelity = ({ ReduxCreateLowFidelityJourney }) => {
  const param = useParams();
  const navigator = useNavigate();

  const [open, setOpen] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [list, setList] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const [edited, setEdited] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [statusData, setStatusData] = useState({
    name: "",
    pralionId: "",
  });

  const handleOpen = () => {
    setOpen(true);
    setEdited(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getProjectList({ pageNumber: page, testsPerPage: pageSize });
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getProjectList = async () => {
    setFetching(true);
    const projectId = parseInt(param.id);
    const response = await getScenarioExecutionList(projectId);
    if (response?.status === 200) {
      setFetching(false);
      const { data } = response?.data;
      console.log("data", data);
      setList(data["applications"]);
      setTotalRows(data?.no_of_applications);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setList([]);
      setFetching(false);
    }
  };

  const goto = (data) => {
    console.log("data", data);
    const projectId = parseInt(param.id);
    const route = data.application_version_id
      ? `/projects/${projectId}/applications/low-fidelity/scenarios`
      : `/projects/${projectId}/applications/low-fidelity/vehicle-select`;

    navigator(route);
    ReduxCreateLowFidelityJourney(data.pralion_id, data.name);
  };

  const handleSummary = (data) => {
    console.log("data", data);
    setEdited(true);
    setOpen(true);
    setSummaryData(data);
  };

  const handleStatusOpenModal = (data) => {
    setStatusData({
      name: data.name,
      pralionId: data.pralion_id,
    });
    setStatusOpen(true);
  };

  const columns = [
    {
      field: "name",
      headerName: <span className="heading-2">Execution ID</span>,
      width: 550,
      renderCell: (params) => (
        <Grid>
          <Button
            className="Main-link-simple"
            onClick={() => goto(params.row)}
            sx={{ color: "white" }}
          >
            {params.value}
          </Button>
        </Grid>
      ),
    },
    {
      field: "date_created",
      renderHeader: () => <span className="heading-2">{"Created At"}</span>,
      width: 280,
      renderCell: (params) => (
        <span className="row-heading">{dateConverter(params.value)}</span>
      ),
    },
    {
      renderHeader: () => <span className="heading-2">{"Summary"}</span>,
      width: 180,
      renderCell: (params) => (
        <Grid
          onClick={() => handleSummary(params.row)}
          sx={{ cursor: "pointer", marginLeft: "2em" }}
        >
          <AirplayIcon />
        </Grid>
      ),
    },
    {
      field: "action",
      renderHeader: () => <span className="heading-2">{"Status"}</span>,
      width: 235,
      renderCell: (params) => {
        const status = params.row.dag_state.dag_state;
        return (
          <>
            <div className="scenarioStatusRadioButton">
              <Radio
                checked={
                  status === "pending" || !params.row.dag_state.dag_state
                }
                sx={{
                  transform: "scale(0.6)",
                  width: "1px",
                  height: "1px",
                  "& > input": {
                    backgroundColor: "gray",
                    color: "red",
                    "&:checked + span": {
                      backgroundColor: "gray",
                      color: "gray",
                      borderRadius: "16px",
                      width: "22px",
                      height: "22px",
                    },
                  },
                }}
              />
              <Radio
                checked={status === "queued"}
                sx={{
                  transform: "scale(0.6)",
                  width: "1px",
                  height: "1px",
                  "& > input": {
                    backgroundColor: "#ffff00",
                    color: "#ffff00",
                    "&:checked + span": {
                      backgroundColor: "#ffff00",
                      color: "#ffff00",
                      borderRadius: "16px",
                      width: "22px",
                      height: "22px",
                    },
                  },
                }}
              />
              <Radio
                checked={status === "running"}
                sx={{
                  transform: "scale(0.6)",
                  width: "1px",
                  height: "1px",
                  "& > input": {
                    backgroundColor: "#ff9800",
                    color: "#ff9800",
                    "&:checked + span": {
                      backgroundColor: "#ff9800",
                      color: "#ff9800",
                      borderRadius: "16px",
                      width: "22px",
                      height: "22px",
                    },
                  },
                }}
              />
              <Radio
                color="success"
                checked={status === "success"}
                sx={{
                  transform: "scale(0.6)",
                  width: "1px",
                  height: "1px",
                  "& > input": {
                    backgroundColor: "#4caf50",
                    color: "#4caf50",
                    "&:checked + span": {
                      backgroundColor: "#4caf50",
                      color: "#4caf50",
                      borderRadius: "16px",
                      width: "22px",
                      height: "22px",
                    },
                  },
                }}
              />
              <Radio
                checked={status === "failed"}
                sx={{
                  transform: "scale(0.6)",
                  width: "1px",
                  height: "1px",
                  "& > input": {
                    backgroundColor: "red",
                    color: "red",
                    "&:checked + span": {
                      backgroundColor: "red",
                      color: "red",
                      borderRadius: "16px",
                      width: "22px",
                      height: "22px",
                    },
                  },
                }}
              />
            </div>
            <Button
              sx={{ color: "text.main" }}
              onClick={() => handleStatusOpenModal(params.row)}
            >
              <MoreVertIcon />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Fragment>
      <ModalX
        open={open}
        close={handleClose}
        heading={
          !edited ? "Create New LowFidelity" : `${summaryData.name} Summary`
        }
        subheading={
          !edited
            ? "Enter LowFidelity details to continue"
            : "LowFidelity application details"
        }
      >
        <LowFidelityForm
          handleClose={handleClose}
          syncList={getProjectList}
          summaryData={summaryData}
          isEdit={edited}
        />
      </ModalX>

      <ModalX
        open={statusOpen}
        close={() => setStatusOpen(false)}
        heading={"State Management"}
        subheading={`${statusData.name}`}
      >
        <LowFidelityStatus
          statusObject={statusData}
          handleClose={handleClose}
        />
      </ModalX>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        mb={2}
      >
        <ButtonX onClick={handleOpen}>New LowFidelity</ButtonX>
      </Box>

      <MainTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />
    </Fragment>
  );
};
export default connect(null, { ReduxCreateLowFidelityJourney })(LowFidelity);
