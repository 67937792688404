import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import "./interactiveMap.css";
import { ButtonX, SwitchButtonX, Typos, IconButtonX } from "components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	MenuItem,
	TextField,
} from "@mui/material";
import {
	ExpandMoreRounded,
} from "@mui/icons-material";
import { httpGet, httpPost } from "utils/httpMethods";
import { ProgressX } from "components";
import { ODD_TO_GEOJSON,ODD_TO_GEOJSON_KHWARIZMI_URL } from "constants/endpoints";
import { mapTypes, GetInfoFromLocalStorage } from "utils";
import { getAllTestVehicleProjects } from "endpointServices/testVehicleService";
import { getAutoScenarioTrafficProfileList } from "endpointServices/autoScenarioServices";
import { getVehicleProfile } from "pages/educAgents/modules/stateMaintain";
import VehicleProfile from "./vehicleProfiles";
import DynamicActorForm from "./dynamicActorForm";
import { getMyVehicle, getTrafficPreset } from "../../stateMaintain";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createMapProjects, updateMapProjects } from "endpointServices/mapsServices";
import { ReduxUpdateAutoScenarioJourney } from "services/redux-store/actions/actions-autoscenario";
import { connect } from "react-redux";
import STORE from "services/redux-store/store";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "xyz";

const laneColors = {
	selection: "#00FF00",
	option: "#EFCB00",
};

export const LAYER2 = {
	id: "state-fills",
	type: "fill",
	source: "states",
	layout: {},
	filter: ["==", "$type", "Polygon"],
	paint: {
		// "fill-color": "#0080ff", // blue color fill
		// "fill-opacity": 0.2,
		// "fill-color": "rgba(0, 163, 181, 0.2)",
		"fill-outline-color": "rgba(0, 188, 212, 1)",
		"fill-color": [
			"case",
			["boolean", ["feature-state", "click"], false],
			"#64bdbb", // if selected true, paint in blue
			"#ff0000", // else paint in red
		],
	},
};

const AsInteractiveMap = ({ReduxUpdateAutoScenarioJourney}) => {
	const param = useParams()
	const mapContainer = useRef(null);
	const map = useRef(null);
	const {AutoScenario} = STORE.getState()
	const { gid_kwh } = AutoScenario.oddData;
	console.log('gid_kwh', gid_kwh)
	const navigate = useNavigate();
	const location = useLocation();

	const [lng, setLng] = useState(13.405252);
	const [lat, setLat] = useState(52.517644);
	const [zoom, setZoom] = useState(8);
	const [loading, setLoading] = useState(false);
	const [url, setUrl] = useState(null);

	const [vehicleList, setVehicleList] = useState([]);
	const [selectCategory, setSelectCategory] = useState('');

	const [trafficPresetList, setTrafficPresetList] = useState([]);
	const [selectTraficPreset, setSelectTraficPreset] = useState("");
	const [geojsonFilesURLs, setGeojsonFilesURLs] = useState({
		lane_polygons: "./lane_polygons.geojson",
		sign_polygons: "./sign_polygons.geojson",
		pole_polygons: "./pole_polygons.geojson",
		barrier_polygons: "./barrier_polygons.geojson",
		road_polygons: "./road_polygons.geojson",
	});

	const [clickNextStage, setClickNextStage] = useState(0);

	const [isSave, setIsSave] = useState(true);

	// const mapGenerationFunc = async () => {
	// 	const data = location.state
	// 	console.log('data', data)
	// 	const proj_id = GetInfoFromLocalStorage('p_id')
	// 	console.log('proj_id', proj_id)
	// 	const createMapPayload = {
	// 		project_id: parseInt(proj_id),
	// 		name: data.name,
	// 		description: ''
	// 	}
	// 	console.log('cvreateMapPayload', createMapPayload)
	// 	const response = await createMapProjects(createMapPayload)
	// 	if (response?.status === 200) {
	// 		const resp = response?.data?.data
	// 		const updateMapPayload = {
	// 			pralion_id:resp.pralion_id,
	// 			name:data.name,
	// 			description:'',
	// 			open_drive_local:data.localOpenDrive,
	// 			open_drive_s3:data.openDrive
	// 		}
	// 		const updateResponse = await updateMapProjects(updateMapPayload)
	// 		if (updateResponse?.status === 200) {
	// 			const updateResp = response?.data?.data
	// 			console.log('map_gen_id', updateResp)
	// 			ReduxUpdateAutoScenarioJourney({mapGenObject: updateResp})
	// 			localStorage.setItem('map_id',updateResp.pralion_id)
	// 		}else {
	// 			const err = response?.response?.data?.message
	// 			console.log('err', err)
	// 		}
	// 	} else {
	// 		const err = response?.response?.data?.message
	// 		console.log('err', err)
	// 	}
	// }

	// useEffect(() => {
	// 	mapGenerationFunc()
	// }, [location]);


	useEffect(() => {
		getAllList();
		getTrafficProfileList();
	}, []);

	useEffect(() => {
		getStoreValues()
	},[param])

	const getStoreValues = () => {
		if(AutoScenario.trafficPreset !== undefined && AutoScenario.trafficPreset !== null ) {
			setSelectTraficPreset(AutoScenario.trafficPreset)
		}
		if(AutoScenario.testVehicle!== undefined && AutoScenario.testVehicle !== null) {
			setSelectCategory( AutoScenario.testVehicle)
		}
		setIsSave(false)
	}

	const getTestVehicleObject = (value) => {
		console.log('value', value)
		getMyVehicle(value)
		setIsSave(false)
		ReduxUpdateAutoScenarioJourney({
			testVehicle: value
		})
	};

	const handleSelectTrafficPreset = (value) => {
		getTrafficPreset(value)
		ReduxUpdateAutoScenarioJourney({trafficPreset: value})
	};

	const getAllList = async () => {
		const projectId = GetInfoFromLocalStorage('p_id')
		const response = await getAllTestVehicleProjects(parseInt(projectId))
		if (response?.status === 200) {
			const data = response?.data?.data?.test_vehicles || []
			const list = data.map((row) => {
				const returnValue = {
					label: row['name'],
					value: row['pralion_id']
				}
				return returnValue
			})
		  setVehicleList(list)
		} else {
			const err = response?.message || 'Failed to sumbit request'
      		toast.error(`${err}. Please try again later`)
			setVehicleList([])
		}
	};

	const getTrafficProfileList = async () => {
		const response = await getAutoScenarioTrafficProfileList();
		if (response?.status === 200) {
			const { data } = response?.data;
			const setKeysName = Object.keys(data);
			setTrafficPresetList(setKeysName);
		} else {
			const err = response?.message || 'Failed to sumbit request'
      		toast.error(`${err}. Please try again later`)
			  setTrafficPresetList([])
		}
	};



	const trafficPreset = (
		<Box p={1} pt={2}>
			<TextField
				label="Select Traffic preset"
				name="selectTraficPreset"
				size="small"
				type="select"
				variant="outlined"
				value={selectTraficPreset}
				onChange={(e)=> {
					const {value} = e.target
					setSelectTraficPreset(value);
					handleSelectTrafficPreset(value)
				}}
				fullWidth
				disabled={false}
				select
				SelectProps={{
					MenuProps: {
						className: "customDropdown",
					},
				}}
			>
				{trafficPresetList.map((item, id) => {
					return (
						<MenuItem key={id} value={item}>
							{item}
						</MenuItem>
					);
				})}
			</TextField>
		</Box>
	);

	console.log('selectCategory', selectCategory)
	const testVehicle = (
		<Box p={1} pt={2}>
			<TextField
				label="Select Test Vehicle"
				name="selectCategory"
				type="select"
				size="small"
				variant="outlined"
				value={selectCategory}
				onChange={e => {
					const {value} = e.target
					setSelectCategory(value)
					getTestVehicleObject(value)
				}}
				fullWidth
				disabled={false}
				select
				SelectProps={{
					MenuProps: {
						className: "customDropdown",
					},
				}}
			>
				{vehicleList.map((item,id) => {
					return (
						<MenuItem key={id} value={item.value}>
							{item.label}
						</MenuItem>
					);
				})}
			</TextField>
		</Box>
	);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	
	const generateNewLayer = (layerId, type) => {
		return {
			id: "route" + layerId,
			type: "fill",
			source: layerId,
			paint: {
				"fill-opacity": 0.7,
				"fill-color": ["get", "fill"], //type.fill, //"rgba(0, 163, 181, 0.2)",
				"fill-outline-color": type.stroke, //"rgba(0, 188, 212, 1)",
			},
		};
	};

	useEffect(() => {
		console.log("location", location);

		fetchMapLayers();
		// fetchVehicles();

		if (!map.current) {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: mapTypes.streetView,
				center: [lng, lat],
				zoom: zoom,
			});
			map.current.on("load", () => {
				map.current.on("move", handleOnMapMove);
			});
		}
		// return () => {
		// 	map.current.off("click", `route0`, (e) => console.log('e', e));
		// }
	}, []);

	const fetchMapLayers = async () => {
		setLoading(true);
		// if (!location.state) {
		// 	return;
		// } else {
		// 	if (location.state.isGeojsonAvailable) {
		// 		// geojsons are all available
		// 		const data = {
		// 			lane_polygons: location.state.availableGeojsons.lane_polygons,
		// 			sign_polygons: location.state.availableGeojsons.sign_polygons,
		// 			pole_polygons: location.state.availableGeojsons.pole_polygons,
		// 			barrier_polygons: location.state.availableGeojsons.barrier_polygons,
		// 			road_polygons: location.state.availableGeojsons.road_polygons,
		// 		};
		// 		// setUrl(location.state.availableGeojsons.lane_polygons);
		// 		setGeojsonFilesURLs(data);
		// 		setLoading(false);
		// 	} else {
		// 		try {
		// 			await httpPost({
		// 				url: ODD_TO_GEOJSON,
		// 				payload: {
		// 					odd_polygon: location.state.geojson,
		// 					map_id: location.state.mapId,
		// 					gid: location.state.gId
		// 				},
		// 			})
		// 				.then((resp) => {
		// 					// console.log("resp", resp);
		// 					const data = {
		// 						lane_polygons: resp.data.data.lane_polygons,
		// 						sign_polygons: resp.data.data.sign_polygons,
		// 						pole_polygons: resp.data.data.pole_polygons,
		// 						barrier_polygons: resp.data.data.barrier_polygons,
		// 						road_polygons: resp.data.data.road_polygons,
		// 					};
		// 					lanePolygon = resp.data.data.lane_polygons;
		// 					setUrl(resp.data.data.lane_polygons);
		// 					setGeojsonFilesURLs(data);
		// 					// "https://temp-rayan.s3.eu-central-1.amazonaws.com/lane_polygons_bc29ccac-fdc2-40a6-862b-9011418a8608.geojson"
		// 					setLoading(false);
		// 				})
		// 				.catch((err) => {
		// 					toast.error(`${err}. Please try again later`)
		// 					setLoading(false);
		// 				});
		// 		} catch (err) {
		// 			console.log("err", err);
		// 			setLoading(false);
		// 		}
		// 	}
		// }
		let lanePolygon = null;
		try {
			await httpGet({
				url:  `${ODD_TO_GEOJSON_KHWARIZMI_URL}/${gid_kwh}`,
			}).then((resp) => {
					console.log("resp", resp);
					const data = {
						lane_polygons: resp.data.data.lane_polygons,
						sign_polygons: resp.data.data.sign_polygons,
						pole_polygons: resp.data.data.pole_polygons,
						barrier_polygons: resp.data.data.barrier_linestring,
						road_polygons: resp.data.data.road_polygons,
						lane_marking_linestring: resp.data.data.lane_marking_linestring
					};
					lanePolygon = resp.data.data.lane_polygons;
					setUrl(resp.data.data.lane_polygons);
					setGeojsonFilesURLs(data);
					// "https://temp-rayan.s3.eu-central-1.amazonaws.com/lane_polygons_bc29ccac-fdc2-40a6-862b-9011418a8608.geojson"
					setLoading(false);
				})
				.catch((err) => {
					toast.error(`${err}. Please try again later`)
					setLoading(false);
				});
		} catch (err) {
			console.log("err", err);
			setLoading(false);
		}
	};

	// https://temp-rayan.s3.eu-central-1.amazonaws.com/lane_polygons_bc29ccac-fdc2-40a6-862b-9011418a8608.geojson
	const handleSourceLoad = (e, type) => {
		console.log("e", e);
		if (e.target.checked) {
			fetch(url || geojsonFilesURLs[type])
				// fetch(url || "https://temp-rayan.s3.eu-central-1.amazonaws.com/lane_polygons_bc29ccac-fdc2-40a6-862b-9011418a8608.geojson")
				.then((resp) => resp.json())
				.then((json) => {
					map.current.addSource(e.target.id, { type: "geojson", data: json, generateId: true });
					const layerProps = json["features"][0]["properties"];
					map.current.addLayer(generateNewLayer(e.target.id, layerProps));
					// console.log("json", json);
					if (type === "lane_polygons") {
						map.current.setCenter(json["features"][0]["geometry"]["coordinates"][0][0]);
						map.current.setZoom(14);
					}
				});
		} else {
			try {
				map.current.removeLayer(`route${e.target.id}`);
				map.current.removeSource(e.target.id);
			} catch (err) {
				console.log("err", err);
			}
		}
	};

	const handleOnMapMove = (e) => {
		setLng(map.current.getCenter().lng.toFixed(4));
		setLat(map.current.getCenter().lat.toFixed(4));
		setZoom(map.current.getZoom().toFixed(2));
	};

	const onClickChangeMapType = (type) => {
		map.current.setStyle(type);
	};

	const checkNext = () => {
		const pralionId = localStorage.getItem('pralion_id')
		if(pralionId === null || pralionId === undefined) {
			localStorage.setItem('pralion_id',param.id)
		}
	}

	const onClickNext = () => {
		switch (clickNextStage) {
			case 0:
				const payload = {
					test_vehicle_id: selectCategory.id,
					selected_traffic_preset: selectTraficPreset,
				};
				getVehicleProfile(payload);
				setClickNextStage(1)	
				checkNext()			
				break;
			case 1:
				navigate(`/projects/${param.id}/applications/auto-scenarios/define-observer`)
				break;
			default:
				break;
		}
		// navigate();
	};

	const onClickBack = () => {
		switch (clickNextStage) {
			case 0:
				navigate(-1)				
				break;
			case 1:
				setClickNextStage(0);
				break;
			default:
				break;
		}
		// navigate();
	};


	return (
		<>
		
		<div className={`container-fluid`}>
			<div className={`row`}>
				<div className={`col-12 d-flex justify-content-between`}>
					<div className={`d-flex`}></div>
					<div className={`d-flex`}>
						<ButtonX className={`me-2`} onClick={onClickBack} disabled={false}>
							Back
						</ButtonX>
						<ButtonX onClick={onClickNext} disabled={isSave}>Next</ButtonX>
					</div>
				</div>
			</div>
			<div className={`map-container mt-3`}>
				<div className={`map-available-geojsons-sidebar px-3 py-3`}>
					<Accordion sx={{ backgroundColor: "rgba(35, 55, 75, 0.6)" }}>
						<AccordionSummary expandIcon={<ExpandMoreRounded />}>
							<Typos.Body1>Available Map Layers</Typos.Body1>
						</AccordionSummary>
						<AccordionDetails>
							{loading ? (
								<ProgressX />
							) : (
								Object.keys(geojsonFilesURLs).map((item, i) => {
									return (
										<div key={i} className={`mt-1`}>
											<SwitchButtonX id={`${i}`} onChange={(e) => handleSourceLoad(e, item)} label={item}></SwitchButtonX>
										</div>
									);
								})
							)}
						</AccordionDetails>
					</Accordion>
				</div>
				<div className={`map-types-bar`}>
					<IconButtonX wrapper size={"small"} icon={"StreetviewRounded"} onClick={() => onClickChangeMapType(mapTypes.streetView)} />
					<IconButtonX wrapper size={"small"} icon={"SatelliteRounded"} onClick={() => onClickChangeMapType(mapTypes.satelliteView)} />
				</div>
				<div className={`map-elements`}>
					{clickNextStage === 0 && <VehicleProfile selectedCar={selectCategory} fields={{ trafficPreset, testVehicle }} />}
					{clickNextStage === 1 && <DynamicActorForm setIsSave={setIsSave} />}
				</div>

				<div ref={mapContainer} className="map-view" />
			</div>
		</div>
		</>
	);
}
export default connect(null, { ReduxUpdateAutoScenarioJourney })(AsInteractiveMap);
