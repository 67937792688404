import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import React from "react";
import * as Icons from "@mui/icons-material";

function TextFieldWithEndButton({ id, name, label, value, onChange, onButtonClick, buttonIcon, className, fullwidth, readonly }) {
	const ChoosenIcon = Icons[buttonIcon];
	return (
		<FormControl fullWidth={fullwidth} variant="outlined" className={className}>
			<InputLabel size="small" htmlFor={id || name}>
				{label}
			</InputLabel>
			<OutlinedInput
				id={id || name}
				size="small"
				fullWidth={fullwidth}
				readOnly={readonly}
				value={value}
				onChange={onChange}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							onClick={onButtonClick}
							size="small"
							//   onMouseDown={handleMouseDownPassword}
							edge="end"
						>
							<ChoosenIcon />
						</IconButton>
					</InputAdornment>
				}
				label={label}
			/>
		</FormControl>
	);
}
export default TextFieldWithEndButton;
