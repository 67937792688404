import { SET_SCENEXTRACT, UPDATE_SCENEXTRACT } from ".."

export const ReduxCreateScenExtractJourney = (appName, pId, pName, vId) => {
    return {
        type: SET_SCENEXTRACT,
        payload: {
            applicationName: appName,
            pralionId: pId,
            projectName: pName,
            applicationVersionId: vId,
        }
    }
}

export const ReduxUpdateScenExtractJourney = (obj) => {
    return {
        type: UPDATE_SCENEXTRACT,
        payload: obj
    }
}