import React, { useState, useEffect, Fragment, useMemo } from "react";
import { Box, Grid, Radio, Button } from "@mui/material";
import ButtonX from "components/buttonX";
import TestVehicleTable from "./testVehiclesComponent/testVehicleTable";
import { ModalX } from "components";
import { useParams, useNavigate } from "react-router-dom";
import {
  createTestVehicleProjectService,
  getTestVehicleProjectsById,
} from "endpointServices/testVehicleService";
import { toast } from "react-toastify";
import dateConverter from "utils/dateConverter";
import { ReduxCreateMyVehicleJourney } from "services/redux-store/actions/actions-vehicles";
import { connect } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { wrapText } from "utils";
import { pageSize } from "utils/staticsData";
import ApplicationForm from "components/applicationForm";
import TestVehicleForm from "./testVehiclesComponent/testVehicleForm";
import AirplayIcon from "@mui/icons-material/Airplay";

const TestVehicles = ({ ReduxCreateMyVehicleJourney }) => {
  const param = useParams();
  let navigator = useNavigate();
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [summaryData, setSummaryData] = useState({});
  const [edited, setEdited] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setEdited(false);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    getAllList({ pageNumber: page, testsPerPage: pageSize });
  }, [page]);

  const getAllList = async (pagination) => {
    setFetching(true);
    const projectId = param?.id;
    const response = await getTestVehicleProjectsById(projectId, pagination);
    if (response?.status === 200) {
      setFetching(false);
      const { data } = response?.data;
      console.log("data", data);
      setList(data.test_vehicles);
      setTotalRows(data.no_of_test_vehicles);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setList([]);
      setFetching(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const goto = (id, data) => {
    console.log("data", data);
    localStorage.setItem("p_id", param.id);
    localStorage.setItem("pralion_id", data.pralion_id);
    localStorage.setItem("vehicleProjectName", data.name);
    navigator(
      `/projects/${data.pralion_id}/applications/test-vehicles/replica-sim`
    );
    const versions = !!data?.versions?.length;
    const vehicleProfileId =
      data?.versions[0]?.replicar_test_vehicle_profile_id;
    ReduxCreateMyVehicleJourney(
      data.pralion_id,
      data.name,
      versions,
      vehicleProfileId
    );
  };

  const handleSummary = (data) => {
    console.log("data", data);
    setEdited(true);
    setOpen(true);
    setSummaryData(data);
  };

  const columns = useMemo(
    () => [
      {
        field: "name",
        renderHeader: () => <span className="heading-2">{"Vehicle ID "}</span>,
        width: 550,
        renderCell: (params) => (
          <Grid>
            <Button
              className="Main-link-simple row-heading"
              onClick={() => goto(params.id, params.row)}
              sx={{ color: "white" }}
            >
              {wrapText(params.value)}
            </Button>
          </Grid>
        ),
      },
      {
        field: "date_created",
        renderHeader: () => <span className="heading-2">{"Created At"}</span>,
        width: 280,
        renderCell: (params) => (
          <span className="row-heading">{dateConverter(params.value)}</span>
        ),
      },
      {
        renderHeader: () => <span className="heading-2">{"Summary"}</span>,
        width: 180,
        renderCell: (params) => (
          <Grid
            onClick={() => handleSummary(params.row)}
            sx={{ cursor: "pointer" ,marginLeft:'2em'}}
          >
            <AirplayIcon />
          </Grid>
        ),
      },
    ],
    [list, page]
  );

  return (
    <Fragment>
      <ModalX
        open={open}
        close={handleClose}
        heading={!edited ? "Create New TestVehicle" : `${summaryData.name} Summary`}
        subheading={
          !edited
            ? "Enter TestVehicle details to continue"
            : "Vehicle application details"
        }
      >
        <TestVehicleForm
          apiFunction={createTestVehicleProjectService}
          syncList={getAllList}
          handleClose={handleClose}
          summaryData={summaryData}
          isEdit={edited}
        />
      </ModalX>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        mb={2}
      >
        <ButtonX onClick={handleOpen}>Create New Vehicle</ButtonX>
      </Box>
      <TestVehicleTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />
    </Fragment>
  );
};
export default connect(null, { ReduxCreateMyVehicleJourney })(TestVehicles);
