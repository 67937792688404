import { HIGH_FIDELITY_SET_VEHICLE, HIGH_FIDELITY_UPDATE_VEHICLE } from "..";

const INITIAL_STATE = {
    pralionId: null,
    projectName: null,
    vehicleProperties: null,
    vehicleSuits: null,
    dataStorage: null,
    observerId: null,
    desktopClientId: null,
    precipitation: null,
    precipitationRate: null,
    time: null,
}

export default function ReducerHighFidelity(state = INITIAL_STATE, action) {
    switch (action.type) {
        case HIGH_FIDELITY_SET_VEHICLE:
            return action.payload;
        case HIGH_FIDELITY_UPDATE_VEHICLE:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}