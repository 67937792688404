import * as React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { FixedSizeList } from "react-window";
import { Checkbox, ListItemIcon } from "@mui/material";

export default function VirtualizedList({ data, callback, namekey, disableRecordsQuery, selectedIndex }) {
	const [checkedIndex, setCheckedIndex] = React.useState(null);

	React.useEffect(() => {
		setCheckedIndex(selectedIndex)
	},[selectedIndex])

	const renderRow = ({ index, style }) => {
		const item = data[index];
		return (
			<ListItem key={index} style={style} component="div" disablePadding>
				<ListItemButton
                    // onClick={callback} 
					disabled={Object.keys(disableRecordsQuery).filter(key => item[key] === disableRecordsQuery[key]).length > 0}
                    onClick={(e) => {
						if (index !== checkedIndex) {
							callback(index)
							setCheckedIndex(index); 
						}
						else {
							callback(null)
							setCheckedIndex(null);
						}
					}}
                    >
					<ListItemIcon>
						<Checkbox edge="start" checked={checkedIndex === index} disableRipple size={"small"} />
					</ListItemIcon>
					<ListItemText primary={item[namekey]} primaryTypographyProps={{variant: "body1"}}/>
				</ListItemButton>
			</ListItem>
		);
	};
	return (
		<FixedSizeList height={150} itemSize={40} itemCount={data.length} overscanCount={5}>
			{renderRow}
		</FixedSizeList>
	);
}
