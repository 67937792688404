import React, { useState, Fragment, useEffect } from "react";
import { Grid, Button, Box, Radio } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonX, ModalX } from "components";
import { ReduxCreateHighFidelityJourney } from "services/redux-store/actions/actions-highFidelity";
import { connect } from "react-redux";
import MainTable from "components/mainTable";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dateConverter from "utils/dateConverter";
import { getHighFidelityLists } from "endpointServices/highFidelityServices";
import HighFidelityForm from "./highFidelityComponent/highFidelityForm";
import HighFidelityStatus from "./highFidelityComponent/highFidelityStatus";
import { pageSize } from "utils/staticsData";

const HighFidelity = ({ ReduxCreateHighFidelityJourney }) => {
  const param = useParams();
  const navigator = useNavigate();

  const [open, setOpen] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const [list, setList] = useState([]);
  const [statusOpen, setStatusOpen] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [statusData, setStatusData] = useState({
    name: "",
    pralionId: "",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getProjectList({ pageNumber: page, testsPerPage: pageSize });
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getProjectList = async (pagination) => {
    setFetching(true);
    const projectId = parseInt(param.id);
    const response = await getHighFidelityLists(projectId, pagination);
    if (response?.status === 200) {
      setFetching(false);
      const { data } = response?.data;
      console.log("data", data);
      setList(data["applications"]);
      setTotalRows(data?.no_of_applications);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setList([]);
      setFetching(false);
    }
  };

  const goto = (data) => {
    const projectId = parseInt(param.id);
    const route = data.application_version_id
      ? `/projects/${projectId}/applications/high-fidelity/execution-scenarios`
      : `/projects/${projectId}/applications/high-fidelity/vehicle-selection`;

    navigator(route);
    ReduxCreateHighFidelityJourney(data.pralion_id, data.name);
  };

  const handleStatusOpenModal = (data) => {
    setStatusData({
      name: data.name,
      pralionId: data.pralion_id,
    });
    setStatusOpen(true);
  };

  const columns = [
    {
      field: "name",
      headerName: <span className="heading-2">Project Name</span>,
      width: 550,
      renderCell: (params) => (
        <Grid>
          <Button
            className="Main-link-simple"
            onClick={() => goto(params.row)}
            sx={{ color: "white" }}
          >
            {params.value}
          </Button>
        </Grid>
      ),
    },
    {
      field: "description",
      renderHeader: () => <span className="heading-2">{"Description"}</span>,
      width: 360,
      renderCell: (params) => (
        <span className="row-heading">
          {params.value ? params.value : "N/A"}
        </span>
      ),
    },
    {
      field: "date_created",
      renderHeader: () => <span className="heading-2">{"Created At"}</span>,
      width: 183,
      renderCell: (params) => (
        <span className="row-heading">{dateConverter(params.value)}</span>
      ),
    },
    {
      field: "action",
      renderHeader: () => <span className="heading-2">{"Status"}</span>,
      width: 235,
      renderCell: (params) => {
        const status = params.row.dag_state.dag_state;
        return (
          <>
            <div className="scenarioStatusRadioButton">
              <Radio
                checked={
                  status === "pending" || !params.row.dag_state.dag_state
                }
                sx={{
                  transform: "scale(0.6)",
                  width: "1px",
                  height: "1px",
                  "& > input": {
                    backgroundColor: "gray",
                    color: "red",
                    "&:checked + span": {
                      backgroundColor: "gray",
                      color: "gray",
                      borderRadius: "16px",
                      width: "22px",
                      height: "22px",
                    },
                  },
                }}
              />
              <Radio
                checked={status === "queued"}
                sx={{
                  transform: "scale(0.6)",
                  width: "1px",
                  height: "1px",
                  "& > input": {
                    backgroundColor: "#ffff00",
                    color: "#ffff00",
                    "&:checked + span": {
                      backgroundColor: "#ffff00",
                      color: "#ffff00",
                      borderRadius: "16px",
                      width: "22px",
                      height: "22px",
                    },
                  },
                }}
              />
              <Radio
                checked={status === "running"}
                sx={{
                  transform: "scale(0.6)",
                  width: "1px",
                  height: "1px",
                  "& > input": {
                    backgroundColor: "#ff9800",
                    color: "#ff9800",
                    "&:checked + span": {
                      backgroundColor: "#ff9800",
                      color: "#ff9800",
                      borderRadius: "16px",
                      width: "22px",
                      height: "22px",
                    },
                  },
                }}
              />
              <Radio
                color="success"
                checked={status === "success"}
                sx={{
                  transform: "scale(0.6)",
                  width: "1px",
                  height: "1px",
                  "& > input": {
                    backgroundColor: "#4caf50",
                    color: "#4caf50",
                    "&:checked + span": {
                      backgroundColor: "#4caf50",
                      color: "#4caf50",
                      borderRadius: "16px",
                      width: "22px",
                      height: "22px",
                    },
                  },
                }}
              />
              <Radio
                checked={status === "failed"}
                sx={{
                  transform: "scale(0.6)",
                  width: "1px",
                  height: "1px",
                  "& > input": {
                    backgroundColor: "red",
                    color: "red",
                    "&:checked + span": {
                      backgroundColor: "red",
                      color: "red",
                      borderRadius: "16px",
                      width: "22px",
                      height: "22px",
                    },
                  },
                }}
              />
            </div>

            {/* <Button
            variant="contained"
            sx={{
              backgroundColor: "secondary.dark",
              textTransform: "none",
              borderRadius: "8px",
            }}
          >
            Stop
          </Button> */}
            <Button
              sx={{ color: "text.main" }}
              onClick={() => handleStatusOpenModal(params.row)}
            >
              <MoreVertIcon />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Fragment>
      <ModalX
        open={open}
        close={handleClose}
        heading={"Create New HighFidelity"}
        subheading={"Enter HighFidelity details to continue"}
      >
        <HighFidelityForm handleClose={handleClose} syncList={getProjectList} />
      </ModalX>

      <ModalX
        open={statusOpen}
        close={() => setStatusOpen(false)}
        heading={"State Management"}
        subheading={`${statusData.name}`}
      >
        <HighFidelityStatus
          statusObject={statusData}
          handleClose={handleClose}
        />
      </ModalX>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        mb={2}
      >
        <ButtonX onClick={handleOpen}>New HighFidelity</ButtonX>
      </Box>

      <MainTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />
    </Fragment>
  );
};

export default connect(null, { ReduxCreateHighFidelityJourney })(HighFidelity);
