import { Card, CardHeader } from "@mui/material";
import React from "react";

function CardX({ children, className, title, nodefaultclasses, headeractions }) {
	return (
		<Card
			sx={!nodefaultclasses ? {
				minWidth: 230,
				border: "2px solid",
				borderColor: "primary.main",
				backgroundColor: "secondary.dark",
				// textAlign: "center",
				// height: "11rem",
				borderRadius: "6px",
				p: 1,
				// m: 2,
			}: {}}
			className={className}
		>
			{title && title !== "" ? <CardHeader title={title} action={headeractions}/> : ""}
			{children}
		</Card>
	);
}
export default CardX;
