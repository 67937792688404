import React, { useState } from 'react'
import { Grid, Typography, Box, Slider, FormControlLabel, Checkbox, TextField, Button } from '@mui/material'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { autoScenarioMainState, autoScenarioPlaceState, getDynamicActors } from '../stateMaintain';

export default function DynamicActorForm() {
    const [state, setState] = useState({
        perceptionRange: 0,
        amount: 200,
        seedValue: -1,
        egoDensity: 50
    })

    const [onComingTraffic, setOnComingTraffic] = useState(false)
    const [isRandomMode, setIsRandomMode] = useState(false)

    const [selectTrafficVehicleProfile, setSelectTrafficVehicleProfile] = useState([])
    const [selectedTrafficVehicleProfile, setSelectedTrafficVehicleProfile] = useState([])
    const [trafficVehicleProfile, setTrafficVehicleProfile] = useState(
        [
            {
                "base_vehicle_profile_id": 17,
                "name": "Honda-Life",
                "cda": 0.36,
                "maximum_speed": 150
            },
            {
                "base_vehicle_profile_id": 65,
                "name": "Toyota-Camry",
                "cda": 0.62,
                "maximum_speed": 250
            },
            {
                "base_vehicle_profile_id": 66,
                "name": "Toyota-Corolla-Hatchback-2019",
                "cda": 0.58,
                "maximum_speed": 200
            },
            {
                "base_vehicle_profile_id": 67,
                "name": "Toyota-Prius-2019",
                "cda": 0.58,
                "maximum_speed": 160
            },
            {
                "base_vehicle_profile_id": 4,
                "name": "Audi S5 2012",
                "cda": 0.62,
                "maximum_speed": 209
            },
        ])

    const handleSelectState = (event) => {
        const { name, value } = event.target
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const handleSelectTrafficMultiple = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        setSelectTrafficVehicleProfile(value);
        const list = trafficVehicleProfile.filter(item => value.includes(item.name))
        setSelectedTrafficVehicleProfile(list)
    };

    const onSaveData = () => {
        const payload = {
            "ego": {
                "perception_range": state.perceptionRange,
                "oncoming_traffic": onComingTraffic
            },
            "traffic": {
                "amount": 200,
                "seed_value": 1,
                "random_mode": false,
                "density_around_ego": 50,
                "vehicle_profiles": [
                    {
                        "base_vehicle_profile_id": 17,
                        "name": "Honda-Life",
                        "cda": 0.36,
                        "maximum_speed": 150
                    },
                    {
                        "base_vehicle_profile_id": 65,
                        "name": "Toyota-Camry",
                        "cda": 0.62,
                        "maximum_speed": 250
                    },
                    {
                        "base_vehicle_profile_id": 66,
                        "name": "Toyota-Corolla-Hatchback-2019",
                        "cda": 0.58,
                        "maximum_speed": 200
                    },
                    {
                        "base_vehicle_profile_id": 67,
                        "name": "Toyota-Prius-2019",
                        "cda": 0.58,
                        "maximum_speed": 160
                    },
                    {
                        "base_vehicle_profile_id": 4,
                        "name": "Audi S5 2012",
                        "cda": 0.62,
                        "maximum_speed": 209
                    },
                    {
                        "base_vehicle_profile_id": 55,
                        "name": "Setra S515 HD 2016",
                        "cda": 3.17,
                        "maximum_speed": 100
                    },
                    {
                        "base_vehicle_profile_id": 28,
                        "name": "Mercedes Atego CabinS-0",
                        "cda": 2.4,
                        "maximum_speed": 90
                    },
                    {
                        "base_vehicle_profile_id": 63,
                        "name": "Suzuki GSX-R-0",
                        "cda": 0.37,
                        "maximum_speed": 152
                    },
                    {
                        "base_vehicle_profile_id": 68,
                        "name": "Triumph Bobber-0",
                        "cda": 0.37,
                        "maximum_speed": 200
                    },
                    {
                        "base_vehicle_profile_id": 56,
                        "name": "Setra S519HD-0",
                        "cda": 3.17,
                        "maximum_speed": 120
                    },
                    {
                        "base_vehicle_profile_id": 23,
                        "name": "Mercedes Arocs-0",
                        "cda": 2.4,
                        "maximum_speed": 87
                    },
                    {
                        "base_vehicle_profile_id": 24,
                        "name": "Mercedes Arocs-1",
                        "cda": 2.4,
                        "maximum_speed": 87
                    },
                    {
                        "base_vehicle_profile_id": 29,
                        "name": "Mercedes Atego CabinL-0",
                        "cda": 2.4,
                        "maximum_speed": 140
                    },
                    {
                        "base_vehicle_profile_id": 30,
                        "name": "Mercedes Atego CabinL-1",
                        "cda": 2.4,
                        "maximum_speed": 140
                    },
                    {
                        "base_vehicle_profile_id": 32,
                        "name": "Mercedes Atego CabinS-1",
                        "cda": 2.4,
                        "maximum_speed": 140
                    },
                    {
                        "base_vehicle_profile_id": 48,
                        "name": "Scania RSeries Semi-0",
                        "cda": 2.66,
                        "maximum_speed": 90
                    },
                    {
                        "base_vehicle_profile_id": 49,
                        "name": "Scania RSeries Semi-1",
                        "cda": 2.66,
                        "maximum_speed": 90
                    },
                    {
                        "base_vehicle_profile_id": 52,
                        "name": "Scania RSeries Semi-2",
                        "cda": 2.66,
                        "maximum_speed": 90
                    },
                    {
                        "base_vehicle_profile_id": 53,
                        "name": "Scania RSeries Semi-3",
                        "cda": 2.66,
                        "maximum_speed": 90
                    },
                    {
                        "base_vehicle_profile_id": 1,
                        "name": "Audi A8-0",
                        "cda": 0.67,
                        "maximum_speed": 250
                    },
                    {
                        "base_vehicle_profile_id": 2,
                        "name": "Audi Q7-0",
                        "cda": 1.06,
                        "maximum_speed": 230
                    },
                    {
                        "base_vehicle_profile_id": 3,
                        "name": "Audi Q7 Etron-0",
                        "cda": 0.86,
                        "maximum_speed": 235
                    },
                    {
                        "base_vehicle_profile_id": 8,
                        "name": "BMW 330i-0",
                        "cda": 0.59,
                        "maximum_speed": 250
                    },
                    {
                        "base_vehicle_profile_id": 5,
                        "name": "Audi TTs-0",
                        "cda": 0.65,
                        "maximum_speed": 250
                    },
                    {
                        "base_vehicle_profile_id": 6,
                        "name": "BMW M5 Touring-0",
                        "cda": 0.65,
                        "maximum_speed": 225
                    },
                    {
                        "base_vehicle_profile_id": 7,
                        "name": "BMW X6-0",
                        "cda": 1.08,
                        "maximum_speed": 250
                    },
                    {
                        "base_vehicle_profile_id": 14,
                        "name": "Fiat 500 2015-0",
                        "cda": 0.62,
                        "maximum_speed": 200
                    },
                    {
                        "base_vehicle_profile_id": 16,
                        "name": "Honda E-0",
                        "cda": 0.76,
                        "maximum_speed": 145
                    },
                    {
                        "base_vehicle_profile_id": 19,
                        "name": "Jeep Wrangler-0",
                        "cda": 1.53,
                        "maximum_speed": 180
                    },
                    {
                        "base_vehicle_profile_id": 20,
                        "name": "Jeep Wrangler-1",
                        "cda": 1.53,
                        "maximum_speed": 180
                    },
                    {
                        "base_vehicle_profile_id": 21,
                        "name": "Maserati GT-0",
                        "cda": 0.75,
                        "maximum_speed": 300
                    },
                    {
                        "base_vehicle_profile_id": 22,
                        "name": "Mazda MX-5-0",
                        "cda": 0.66,
                        "maximum_speed": 220
                    },
                    {
                        "base_vehicle_profile_id": 25,
                        "name": "Mercedes ML500-0",
                        "cda": 1.23,
                        "maximum_speed": 250
                    },
                    {
                        "base_vehicle_profile_id": 26,
                        "name": "Mercedes SClass W22-0",
                        "cda": 0.62,
                        "maximum_speed": 250
                    },
                    {
                        "base_vehicle_profile_id": 27,
                        "name": "Mercedes SClass W22-1",
                        "cda": 0.62,
                        "maximum_speed": 250
                    },
                    {
                        "base_vehicle_profile_id": 35,
                        "name": "Nissan_Leaf_2018-0",
                        "cda": 0.7,
                        "maximum_speed": 144
                    },
                    {
                        "base_vehicle_profile_id": 36,
                        "name": "Nissan_Leaf_2018-1",
                        "cda": 0.7,
                        "maximum_speed": 144
                    },
                    {
                        "base_vehicle_profile_id": 37,
                        "name": "Nissan_Leaf_2018-2",
                        "cda": 0.7,
                        "maximum_speed": 144
                    },
                    {
                        "base_vehicle_profile_id": 41,
                        "name": "Opel Corsa 2020-0",
                        "cda": 0.62,
                        "maximum_speed": 207
                    },
                    {
                        "base_vehicle_profile_id": 42,
                        "name": "Opel Corsa 2020-1",
                        "cda": 0.62,
                        "maximum_speed": 207
                    },
                    {
                        "base_vehicle_profile_id": 43,
                        "name": "Opel Corsa 2020-2",
                        "cda": 0.62,
                        "maximum_speed": 207
                    },
                    {
                        "base_vehicle_profile_id": 44,
                        "name": "Porsche Taycan-0",
                        "cda": 0.51,
                        "maximum_speed": 260
                    },
                    {
                        "base_vehicle_profile_id": 45,
                        "name": "RangeRover Evoque-0",
                        "cda": 1.15,
                        "maximum_speed": 195
                    },
                    {
                        "base_vehicle_profile_id": 46,
                        "name": "Renault Zoe-0",
                        "cda": 0.71,
                        "maximum_speed": 140
                    },
                    {
                        "base_vehicle_profile_id": 39,
                        "name": "Opel Adam 2013-0",
                        "cda": 0.66,
                        "maximum_speed": 185
                    },
                    {
                        "base_vehicle_profile_id": 39,
                        "name": "Opel Adam 2013-1",
                        "cda": 0.66,
                        "maximum_speed": 185
                    },
                    {
                        "base_vehicle_profile_id": 54,
                        "name": "SeatLeon-0",
                        "cda": 0.65,
                        "maximum_speed": 172
                    },
                    {
                        "base_vehicle_profile_id": 59,
                        "name": "Smart ForTwo 2017-0",
                        "cda": 0.8,
                        "maximum_speed": 130
                    },
                    {
                        "base_vehicle_profile_id": 69,
                        "name": "Volvo V90 2016-0",
                        "cda": 0.7,
                        "maximum_speed": 250
                    },
                    {
                        "base_vehicle_profile_id": 60,
                        "name": "Smart ForTwo 2017-1",
                        "cda": 0.8,
                        "maximum_speed": 130
                    },
                    {
                        "base_vehicle_profile_id": 61,
                        "name": "Smart ForTwo 2017-2",
                        "cda": 0.8,
                        "maximum_speed": 130
                    },
                    {
                        "base_vehicle_profile_id": 71,
                        "name": "VW Beetle 1963-0",
                        "cda": 0.86,
                        "maximum_speed": 110
                    },
                    {
                        "base_vehicle_profile_id": 73,
                        "name": "VW Golf-0",
                        "cda": 0.59,
                        "maximum_speed": 230
                    },
                    {
                        "base_vehicle_profile_id": 74,
                        "name": "VW ID3-0",
                        "cda": 0.6,
                        "maximum_speed": 160
                    },
                    {
                        "base_vehicle_profile_id": 36,
                        "name": "Nissan_Leaf_2018-3",
                        "cda": 0.7,
                        "maximum_speed": 144
                    },
                    {
                        "base_vehicle_profile_id": 57,
                        "name": "Skoda Octavia RS-0",
                        "cda": 0.66,
                        "maximum_speed": 232
                    },
                    {
                        "base_vehicle_profile_id": 58,
                        "name": "Smart ForTwo 2017-3",
                        "cda": 0.8,
                        "maximum_speed": 130
                    },
                    {
                        "base_vehicle_profile_id": 70,
                        "name": "VW Beetle 1963-1",
                        "cda": 0.86,
                        "maximum_speed": 110
                    },
                    {
                        "base_vehicle_profile_id": 75,
                        "name": "VW Scirocco-0",
                        "cda": 0.8,
                        "maximum_speed": 220
                    },
                    {
                        "base_vehicle_profile_id": 9,
                        "name": "Citreon Berlingo 2019",
                        "cda": 1.05,
                        "maximum_speed": 186
                    },
                    {
                        "base_vehicle_profile_id": 10,
                        "name": "Citreon C4 GrandPicasso",
                        "cda": 0.75,
                        "maximum_speed": 207
                    },
                    {
                        "base_vehicle_profile_id": 11,
                        "name": "Citreon C4 GrandPicasso Taxi",
                        "cda": 0.75,
                        "maximum_speed": 207
                    },
                    {
                        "base_vehicle_profile_id": 18,
                        "name": "Hymer Exsis Motorhome",
                        "cda": 1.225,
                        "maximum_speed": 90
                    },
                    {
                        "base_vehicle_profile_id": 77,
                        "name": "VW T6 Multivan Twotone",
                        "cda": 1.175,
                        "maximum_speed": 90
                    },
                    {
                        "base_vehicle_profile_id": 72,
                        "name": "VW Crafter L1H2 2017",
                        "cda": 1.27,
                        "maximum_speed": 160
                    },
                    {
                        "base_vehicle_profile_id": 76,
                        "name": "VW T6 Multivan",
                        "cda": 1.175,
                        "maximum_speed": 182
                    }
                ],
                "driver_profiles":{
                    "stochastic":
                    [
                        {
                            "p1": 0,
                            "p2": 0,
                            "p3": 0,
                            "p4": 1,
                            "name": "S-Fast Break Rules Aggressive",
                            "target_speed": 250,
                            "rule_abiding": 11,
                            "uniform_distribution": "Uniform"
                        },
                        {
                            "p1": 0,
                            "p2": 0,
                            "p3": 0,
                            "p4": 1,
                            "name": "S-Fast Obey Rules Aggressive",
                            "target_speed": 250,
                            "rule_abiding": -1,
                            "uniform_distribution": "Uniform"
                        },
                        {
                            "p1": 0.1,
                            "p2": 0.4,
                            "p3": 0.4,
                            "p4": 0.1,
                            "name": "S-Break Rules Moderate (Car)",
                            "target_speed": 150,
                            "rule_abiding": 11,
                            "uniform_distribution": "Uniform"
                        },
                        {
                            "p1": 0.1,
                            "p2": 0.4,
                            "p3": 0.4,
                            "p4": 0.1,
                            "name": "S-Break Rules Moderate (Bus)",
                            "target_speed": 100,
                            "rule_abiding": 11,
                            "uniform_distribution": "Uniform"
                        },
                        {
                            "p1": 0.1,
                            "p2": 0.4,
                            "p3": 0.4,
                            "p4": 0.1,
                            "name": "S-Break Rules Moderate (Van)",
                            "target_speed": 100,
                            "rule_abiding": 11,
                            "uniform_distribution": "Uniform"
                        },
                        {
                            "p1": 0.1,
                            "p2": 0.4,
                            "p3": 0.4,
                            "p4": 0.1,
                            "name": "S-Break Rules Moderate (Truck)",
                            "target_speed": 80,
                            "rule_abiding": 11,
                            "uniform_distribution": "Uniform"
                        },
                        {
                            "p1": 0.1,
                            "p2": 0.4,
                            "p3": 0.4,
                            "p4": 0.1,
                            "name": "S-Break Rules Moderate (Motorcycle)",
                            "target_speed": 100,
                            "rule_abiding": 11,
                            "uniform_distribution": "Uniform"
                        }
                    ],
                    "ai":
                    [],
                    "rl":
                    []
                },
                "traffic_composition":[
                    {
                        "driver_profile": "S-Break Rules Moderate (Car)",
                        "vehicle_profile": "Toyota-Prius-2019",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Car)",
                        "vehicle_profile": "Toyota-Corolla-Hatchback-2019",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Car)",
                        "vehicle_profile": "Toyota-Camry",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Honda-Life",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Opel Adam 2013-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Audi S5 2012",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Audi A8-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Audi Q7-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Audi Q7 Etron-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "BMW 330i-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Audi TTs-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "BMW M5 Touring-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "BMW X6-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Fiat 500 2015-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Honda E-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Jeep Wrangler-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Jeep Wrangler-1",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Maserati GT-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Mazda MX-5-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Mercedes ML500-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Mercedes SClass W22-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Mercedes SClass W22-1",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Nissan_Leaf_2018-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Nissan_Leaf_2018-1",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Nissan_Leaf_2018-2",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Opel Corsa 2020-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Opel Corsa 2020-1",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Opel Corsa 2020-2",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Porsche Taycan-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "RangeRover Evoque-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Renault Zoe-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Opel Adam 2013-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Opel Adam 2013-1",
                        "percentage": 2
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "SeatLeon-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Smart ForTwo 2017-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Volvo V90 2016-0",
                        "percentage": 2
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Smart ForTwo 2017-1",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Smart ForTwo 2017-2",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "VW Golf-0",
                        "percentage": 2
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "VW ID3-0",
                        "percentage": 2
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Nissan_Leaf_2018-3",
                        "percentage": 2
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Skoda Octavia RS-0",
                        "percentage": 2
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "Smart ForTwo 2017-3",
                        "percentage": 2
                    },
                    {
                        "driver_profile": "S-Fast Obey Rules Aggressive",
                        "vehicle_profile": "VW Scirocco-0",
                        "percentage": 2
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Truck)",
                        "vehicle_profile": "Mercedes Atego CabinS-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Bus)",
                        "vehicle_profile": "Setra S515 HD 2016",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Motorcycle)",
                        "vehicle_profile": "Suzuki GSX-R-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Motorcycle)",
                        "vehicle_profile": "Triumph Bobber-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Bus)",
                        "vehicle_profile": "Setra S519HD-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Van)",
                        "vehicle_profile": "Citreon C4 GrandPicasso",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Truck)",
                        "vehicle_profile": "Mercedes Arocs-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Truck)",
                        "vehicle_profile": "Mercedes Arocs-1",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Truck)",
                        "vehicle_profile": "Mercedes Atego CabinL-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Truck)",
                        "vehicle_profile": "Mercedes Atego CabinL-1",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Truck)",
                        "vehicle_profile": "Mercedes Atego CabinS-1",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Truck)",
                        "vehicle_profile": "Scania RSeries Semi-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Truck)",
                        "vehicle_profile": "Scania RSeries Semi-1",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Truck)",
                        "vehicle_profile": "Scania RSeries Semi-2",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Truck)",
                        "vehicle_profile": "Scania RSeries Semi-3",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Car)",
                        "vehicle_profile": "Nissan_Leaf_2018-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Car)",
                        "vehicle_profile": "Nissan_Leaf_2018-1",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Car)",
                        "vehicle_profile": "Nissan_Leaf_2018-2",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Car)",
                        "vehicle_profile": "Honda E-0",
                        "percentage": 2
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Car)",
                        "vehicle_profile": "Jeep Wrangler-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Car)",
                        "vehicle_profile": "Jeep Wrangler-1",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Audi A8-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Audi Q7-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Audi Q7 Etron-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "BMW 330i-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Audi TTs-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "BMW M5 Touring-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "BMW X6-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Fiat 500 2015-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Maserati GT-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Mazda MX-5-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Mercedes ML500-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Mercedes SClass W22-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Mercedes SClass W22-1",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Opel Corsa 2020-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Opel Corsa 2020-1",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Opel Corsa 2020-2",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Porsche Taycan-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "RangeRover Evoque-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Break Rules Moderate (Car)",
                        "vehicle_profile": "Renault Zoe-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Opel Adam 2013-1",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "SeatLeon-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Smart ForTwo 2017-0",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Smart ForTwo 2017-1",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Smart ForTwo 2017-2",
                        "percentage": 1
                    },
                    {
                        "driver_profile": "S-Fast Break Rules Aggressive",
                        "vehicle_profile": "Nissan_Leaf_2018-3",
                        "percentage": 2
                    }
                ]       
            }
        }
        getDynamicActors(payload)
    }

    console.log('autoScenarioMainState', autoScenarioMainState)

    return (
        <Grid
            sx={{
                backgroundColor: "secondary.dark",
                paddingBottom: "12px",
                borderRadius: "8px",
            }}
        >
            <Grid className="vehicle-details-table-headings">
                <Typography
                    color="text.main"
                    sx={{ marginLeft: "1rem", marginTop: "1.5rem" }}
                >
                    Ego
                </Typography>

            </Grid>
            <Grid className="vehicle-details-table-data">
                <Box p={2} >
                    <Typography gutterBottom>Perception Range</Typography>
                    <Slider
                        size="small"
                        defaultValue={70}
                        value={state.perceptionRange}
                        onChange={handleSelectState}
                        name="perceptionRange"
                        aria-label="Small"
                        valueLabelDisplay="auto"
                    />
                    <FormControlLabel control={<Checkbox name={"onComingTraffic"} value={onComingTraffic} onChange={() => setOnComingTraffic(!onComingTraffic)} />} label="On Coming Traffic" />
                </Box>
            </Grid>

            <Grid className="vehicle-details-table-headings">
                <Typography
                    color="text.main"
                    sx={{ marginLeft: "1rem", marginTop: "0.5rem" }}
                >
                    Traffic
                </Typography>

            </Grid>
            <Grid className="vehicle-details-table-data">
                <Box p={2} >
                    <TextField
                        sx={{ marginBottom: '1em' }}
                        name="amount"
                        required
                        type="number"
                        variant="outlined"
                        label="Amount"
                        placeholder="Enter amount"
                        value={state.amount}
                        onChange={handleSelectState}
                        fullWidth
                        disabled={false}
                    />
                    <TextField
                        sx={{ marginBottom: '1em' }}
                        name="seedValue"
                        required
                        type="number"
                        variant="outlined"
                        label="Seed Value"
                        placeholder="Enter seed value"
                        value={state.seedValue}
                        onChange={handleSelectState}
                        fullWidth
                        disabled={false}
                    />
                    <TextField
                        sx={{ marginBottom: '1em' }}
                        name="egoDensity"
                        required
                        type="number"
                        variant="outlined"
                        label="Ego Density"
                        placeholder="Enter ego density"
                        value={state.egoDensity}
                        onChange={handleSelectState}
                        fullWidth
                        disabled={false}
                    />
                    <FormControlLabel
                        sx={{ marginBottom: '1em' }}
                        control={<Checkbox
                            value={isRandomMode}
                            onChange={() => setIsRandomMode(!isRandomMode)}
                        />} label="Random Mode"
                    />
                    <FormControl sx={{ minWidth: "100%" }}>
                        <Typography
                            color="text.main"
                            sx={{ marginBottom: "0.5rem" }}
                        >
                            Vehicle Profile
                        </Typography>

                        <Select
                            name="selectTrafficVehicleProfile"
                            multiple
                            native
                            value={selectTrafficVehicleProfile}
                            onChange={handleSelectTrafficMultiple}
                            sx={{
                                "& option:checked": {
                                    color: "text.main",
                                    backgroundColor: "primary.dark",
                                },
                            }}
                        >
                            {trafficVehicleProfile.map((item, id) => (
                                <option key={id} value={item.name} style={{ padding: "6px" }}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box p={2} display="flex" justifyContent="center">
                    <Button
                        sx={{
                            backgroundColor: "primary.main",
                            "&:hover": {
                                backgroundColor: "primary.main",
                            },
                            color: "text.main",
                            width: "200px",
                        }}
                        disabled={false}
                        type="submit"
                        onClick={onSaveData}
                    >
                        Save
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}
