import React from 'react';
import { Box, Button, Grid } from "@mui/material";
import DynamicActorForm from './dynamicActorForm';
import { Link, useParams } from 'react-router-dom';


export default function PlaceDynamicsActor() {
  const param = useParams()
  return (
    <Grid container>
     
      <Grid item xs={8}>
        <Grid>
          <h2>Map</h2>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Box sx={{marginBottom:'3em'}}>
        <Link to={`/projects/${param.id}/applications/educ-agents/educ-static-actor`}>
        <Button
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            color: "white",
            width: "130px",
            marginBottom: '1em',
            float: 'right'
          }}
        >
          Next
        </Button>
      </Link>
        </Box>
        <DynamicActorForm />
      </Grid>
    </Grid>
  )
}
