import React, { Fragment } from 'react'
import SenVironmentTable from './senVironmentComponent/senVironmentTable'

export default function SenVironment() {
    return (
        <Fragment>
            <SenVironmentTable />
        </Fragment>
    )
}
