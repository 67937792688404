import { ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { AlertX, IconButtonX, ProgressX, UxSpace } from "components";
import ButtonX from "components/buttonX";
import { FETCH_ODDS, GET_SHOW_ODDS_MAPS } from "constants/endpoints";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { httpPost } from "utils/httpMethods";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReduxUpdateMyScenarioJourney } from "services/redux-store/actions/actions-myscenario";
import { connect } from "react-redux";

// This page receives the id or URL from the map screen
// call another api after this, "odd on route" using an id or url returns searched_odd_on_route.geojson
const Odds = ({ ReduxUpdateMyScenarioJourney }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const param = useParams();

	const [oddsList, setOddsList] = useState([]);
	const [selectedOddId, setSelectedOddId] = useState(null);
	const [oddGeojsonURL, setOddGeojsonURL] = useState(null);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (location.state) {
			if (location.state.mapId) {
				// route odd loading portion
				fetchOdds(location.state.mapId);
			} else {
				// polygons received loading portion
				loadPolygonsList(location.state.oddUrl);
			}
		}
	}, []);

	const fetchOdds = async (mapId) => {
		setLoading(true);
		await httpPost({ url: FETCH_ODDS, payload: { map_id: mapId } })
			.then((resp) => {
				setOddGeojsonURL(resp.data.data.geo_json);
				fetch(resp.data.data.geo_json)
					.then((response) => response.json())
					.then((json) => {
						setOddsList(json.features);
						setLoading(false);
					});
			})
			.catch((err) => {
				toast.error(`${err}. Please try again later`);
				setLoading(false);
			});
	};

	const loadPolygonsList = (geojson) => {
		fetch(geojson)
			.then((resp) => resp.json())
			.then((json) => {
				setOddsList(json.features);
				setOddGeojsonURL(geojson);
			});
	};

	const onClickItemCheckbox = (e) => {
		setSelectedOddId(e.id === selectedOddId ? null : e.id);
		ReduxUpdateMyScenarioJourney({ mapGeometry: e });
	};

	const onClickNext = () => {
		navigate(`/projects/${param.id}/applications/my-scenarios/map/odds/selected-odds`, {
			state: {
				oddId: selectedOddId,
				geojson: oddGeojsonURL,
				mapId: location.state.mapId,
			},
		});
	};

	return (
		<>
			<div className={`container-fuild`}>
				<div className={`row`}>
					<div className={`col-12 d-flex justify-content-between`}>
						<div className={`d-flex`}></div>
						<div className={`d-flex`}>
							<ButtonX className={`me-2`} onClick={() => navigate(-1)} variant={"outlined"} disabled={true}>
								Back
							</ButtonX>
							<ButtonX onClick={onClickNext} disabled={selectedOddId === null}>
								Next
							</ButtonX>
						</div>
					</div>
				</div>
				<div className={`row mt-3`}>
					{
						<ImageList sx={{ width: "100%", maxHeight: "78vh" }} gap={10} cols={5} rowHeight={260}>
							{loading ? (
								[...Array(15).keys()].map(() => <div ><ProgressX block={true} height={230} width={"100%"} /></div>)
							) : oddsList && oddsList.length === 0 ? (
								<AlertX text="No ODDs found" badge={"info"} />
							) : (
								oddsList.map((item) => (
									<ImageListItem key={item.id} >
										<ImageListItemBar
											sx={{
												background: "linear-gradient(to bottom, rgba(35, 55, 75, 0.7) 0%, " + "rgba(35, 55, 75, 0.3) 70%, rgba(35, 55, 75, 0) 100%)",
												borderRadius: "10px",
											}}
											position="top"
											actionPosition="left"
											actionIcon={
												<IconButtonX
													sx={{ color: "white" }}
													icon={item.id === selectedOddId ? "CheckBoxRounded" : "CheckBoxOutlineBlankRounded"}
													onClick={() => onClickItemCheckbox(item)}
												/>
											}
										/>
										<img
											src={`${GET_SHOW_ODDS_MAPS}/${item.properties.id}`}
											alt={item.properties.odd_keyword}
											loading="lazy"
											className={`img-fluid`}
											style={{ borderRadius: "10px", borderColor: "fff", maxHeight: 260 }}
										/>
										<ImageListItemBar
											sx={{
												background: "linear-gradient(to bottom, rgba(35, 55, 75, 0.7) 100%, " + "rgba(35, 55, 75, 0.3) 70%, rgba(35, 55, 75, 0) 0%)",
												borderEndEndRadius: "10px",
											}}
											position="bottom"
											actionPosition="right"
											title={item.properties.odd_keyword}
										/>
									</ImageListItem>
								))
							)}
						</ImageList>
					}
					{oddsList && oddsList.length > 15 && <UxSpace />}
				</div>
			</div>
		</>
	);
};

export default connect(null, { ReduxUpdateMyScenarioJourney })(Odds);
