import { Autocomplete, Slider, Stack, TextField } from "@mui/material";
import { CheckboxX, IconButtonX, Typos } from "components";
import _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ReduxUpdateDSpaceTrafficConfigurations } from "services/redux-store/actions";

function mapStateToProps(state) { 
	return {
		DSpaceTrafficConfigs: state.DSpaceTrafficConfigs
	}
}

function AlgorithmSettings({ selectedVehicle, selectedDriverProfileIndexAndType, saveUpdatedDataCallback, DSpaceTrafficConfigs, ReduxUpdateDSpaceTrafficConfigurations , trainingAgents}) {


	const onEditAgrressivness = (e) => {
		const tempDSpaceTrafficConfigs = _.cloneDeep({ ...DSpaceTrafficConfigs });
		const profileToEdit = _.cloneDeep(DSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type])
		profileToEdit[selectedDriverProfileIndexAndType.index]['settings']['aggressiveness'][e.target.name] = e.target.value;
		tempDSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type] = profileToEdit;
		ReduxUpdateDSpaceTrafficConfigurations(tempDSpaceTrafficConfigs);
	};

	const onClickCloseAlgorithmSettings = () => {
		saveUpdatedDataCallback()
	}

	const onEditTargetSpeed = (value) => {
		console.log('value', value, selectedVehicle);
		const tempDSpaceTrafficConfigs = _.cloneDeep({ ...DSpaceTrafficConfigs });
		const profileToEdit = _.cloneDeep(DSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type])
		profileToEdit[selectedDriverProfileIndexAndType.index]['settings']['target_speed'] = value;
		tempDSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type] = profileToEdit;
		ReduxUpdateDSpaceTrafficConfigurations(tempDSpaceTrafficConfigs);
	}

	const onChangeTrainAgent = (e, value) => { 
		if (value){
		const tempDSpaceTrafficConfigs = _.cloneDeep({ ...DSpaceTrafficConfigs });
		const profileToEdit = _.cloneDeep(DSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type])
		profileToEdit[selectedDriverProfileIndexAndType.index]['settings']['train_agent_pralion_id'] = value;
		tempDSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type] = profileToEdit;
		ReduxUpdateDSpaceTrafficConfigurations(tempDSpaceTrafficConfigs);
		}
	}

	const onEditRuleAbiding = (value) => {
		const tempDSpaceTrafficConfigs = _.cloneDeep({ ...DSpaceTrafficConfigs });
		const profileToEdit = _.cloneDeep(DSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type])
		profileToEdit[selectedDriverProfileIndexAndType.index]['settings']['rule_abiding'] = value;
		tempDSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type] = profileToEdit;
		ReduxUpdateDSpaceTrafficConfigurations(tempDSpaceTrafficConfigs);
	}

    const calculateDriverProfileSums = () => {
		const sums = [];
		const aggressiveness = DSpaceTrafficConfigs[selectedVehicle].driverProfiles[selectedDriverProfileIndexAndType.type][selectedDriverProfileIndexAndType.index]['settings']['aggressiveness'];
        const totalSum = Math.round(Object.values(aggressiveness).reduce((acc, item) => acc + item, 0) * 100) / 100;
		return {is1: totalSum === 1, sums, total: totalSum};
	}

    const aggressivenessValuesAudit = calculateDriverProfileSums();

    console.log('valuesAudit', aggressivenessValuesAudit);

	return (
		<div className={``}>
			<div className={`d-flex justify-content-between align-items-center`}>
				<Typos.H6>Algorithm Settings</Typos.H6>
				<IconButtonX disabled={!aggressivenessValuesAudit.is1} icon={"ClearRounded"} onClick={onClickCloseAlgorithmSettings} />
			</div>
			<hr />
			<Typos.Body1 className={`fw-bold`}>Target Speed (km/h)</Typos.Body1>
			<Slider
				name="target_speed"
				color="primary"
				value={
					DSpaceTrafficConfigs[selectedVehicle]["driverProfiles"][selectedDriverProfileIndexAndType.type][selectedDriverProfileIndexAndType.index]["settings"][
						"target_speed"
					]
				}
				onChange={(e) => onEditTargetSpeed(e.target.value)}
				min={10}
				max={200}
				valueLabelDisplay="auto"
				marks={[
					{ value: 10, label: 10 },
					{ value: 60, label: 60 },
					{ value: 100, label: 100 },
					{ value: 200, label: 200 },
				]}
			/>
			<hr />

    	{
				DSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type][selectedDriverProfileIndexAndType.index]['settings']['train_agent'] &&
				<Fragment>
					<Autocomplete
						options={trainingAgents}
						getOptionLabel={option => option.name}
						size="small"
						onChange={onChangeTrainAgent}
						value={trainingAgents.find(item => item.pralion_id === DSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type][selectedDriverProfileIndexAndType.index]['settings']['train_agent_pralion_id'])}
						renderInput={(params) => <TextField {...params} variant="filled" label="Training Agent" />}
					/>
					<hr />
				</Fragment>
			}

			<Typos.Body1 className={`fw-bold`}>Aggressiveness..</Typos.Body1>
			<Typos.Caption>Values ranges from 0 to 1</Typos.Caption>
			<Typos.Caption className={`d-flex align-items-center`}>
				Complete sum of{" "}
				<Typos.Caption color={aggressivenessValuesAudit.is1 ? "lightgreen" : "red"} className={`mx-1`}>
					({aggressivenessValuesAudit.total}/1)
				</Typos.Caption>{" "}
				to proceed
			</Typos.Caption>
			{DSpaceTrafficConfigs !== null &&
				Object.keys(
					DSpaceTrafficConfigs[selectedVehicle]["driverProfiles"][selectedDriverProfileIndexAndType.type][selectedDriverProfileIndexAndType.index]["settings"][
						"aggressiveness"
					]
				).map((attr, i) => {
					return (
						<Fragment key={i}>
							<Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
								<Typos.Body1 className={`fw-bold d-flex align-items-end`}>
									{attr}
									<Typos.Caption className={`ms-2`}>(%)</Typos.Caption>
								</Typos.Body1>
								<Slider
									name={attr}
									size="small"
									valueLabelDisplay="auto"
									min={0}
									max={1}
									step={0.1}
									marks
									value={
										DSpaceTrafficConfigs[selectedVehicle]["driverProfiles"][selectedDriverProfileIndexAndType.type][selectedDriverProfileIndexAndType.index][
											"settings"
										]["aggressiveness"][attr]
									}
									onChange={onEditAgrressivness}
								/>
							</Stack>
						</Fragment>
					);
				})}
			<hr />
			<CheckboxX
				name="rule_abiding"
				label={"Rule Abiding"}
				labelplacement={"end"}
				checked={
					DSpaceTrafficConfigs[selectedVehicle]["driverProfiles"][selectedDriverProfileIndexAndType.type][selectedDriverProfileIndexAndType.index]["settings"][
						"rule_abiding"
					] === 1
				}
				onChange={(e) => onEditRuleAbiding(e.target.checked === true ? 1 : -1)}
			/>
		</div>
	);
}
export default connect(mapStateToProps, { ReduxUpdateDSpaceTrafficConfigurations }) (AlgorithmSettings);
