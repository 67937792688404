import React, { useEffect, useState } from "react";
import {
  Button,
  CardContent,
  Grid,
  MenuItem,
  TextField,
  Box,
} from "@mui/material";
import { specialValue } from "utils";
import { createMapProjects } from "endpointServices/mapsServices";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getOddProjectAService } from "endpointServices/oddServices";
import { ReduxUpdateMapsJourney } from "services/redux-store/actions/actions-maps";
import { connect } from "react-redux";

const MapsForm = ({
  handleClose,
  syncList,
  getOddId,
  getProjectId,
  ReduxUpdateMapsJourney,
  summaryData,
  isEdit,
}) => {
  const param = useParams();
  const [state, setState] = useState({
    name: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);

  const [selectOddCategory, setSelectOddCategory] = useState("");
  const [oddList, setOddList] = useState([]);

  useEffect(() => {
    if (isEdit && summaryData) {
      setState({
        name: summaryData.name,
        description: summaryData.description,
        objective: summaryData.objective,
      });
      console.log('summaryData', summaryData)
      const oddObject = oddList.find(
        (item) => item.gid === summaryData.objective
      );
      if (oddObject) {
        setSelectOddCategory(oddObject);
      }
    }
  }, [isEdit, summaryData, oddList]);

  useEffect(() => {
    getOddList();
  }, []);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      if (!specialValue.test(value)) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleOddSelect = (e) => {
    const { value } = e.target;
    console.log("value", value);
    setSelectOddCategory(value);
  };

  const getOddList = async () => {
    const projectId = parseInt(param.id);
    const response = await getOddProjectAService(projectId);
    console.log("odd project list response", response);
    if (response.status === 200) {
      const { data } = response.data;
      console.log("dataaaa", data);
      const filteredApplications = data.applications.filter(
        (application) => application?.application_version_id !== null
      );
      setOddList(filteredApplications);
    } else {
      console.log("error");
    }
  };

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      project_id: parseInt(param.id),
      name: state.name,
      // "category": selectAccCategory,
      objective: selectOddCategory.gid,
      description: state.description,
    };
    console.log("payload", payload);
    getOddId(selectOddCategory.gid);
    getProjectId(selectOddCategory.pralion_id);
    const response = await createMapProjects(payload);
    console.log("map form response", response);
    if (response?.status === 200) {
      const { data } = response?.data;
      toast.success(`MyMap Project Created`);
      console.log("data", data);
      handleClose();
      setLoading(false);
      syncList();
    } else {
      const errorResponse = response;
      const err = errorResponse?.message || "Failed to submit request";
      if (errorResponse?.response.request.status === 400) {
        toast.error(errorResponse.response.data.message);
      } else {
        toast.error(`${err}. Please try again later`);
      }
      console.log("error", errorResponse);
      setLoading(false);
    }
  };

  return (
    <Box>
      <CardContent>
        <form onSubmit={onSubmitLogin}>
          <Grid item xs={6}>
            <TextField
              name="name"
              required
              type="text"
              variant="outlined"
              label="Map Catalog ID"
              placeholder="Enter id"
              inputProps={{ maxLength: 40 }}
              value={state.name}
              onChange={handleInputs}
              disabled={isEdit}
              fullWidth
              helperText="Enter alphanumeric characters and hyphens only"
            />
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="description"
              type="text"
              variant="outlined"
              label="Map Catalog Description"
              placeholder="Write description"
              value={state.description}
              onChange={handleInputs}
              disabled={isEdit}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="odd"
              label="ODD ID"
              type="select"
              variant="outlined"
              value={selectOddCategory}
              onChange={handleOddSelect}
              disabled={isEdit}
              fullWidth
              select
              required
              SelectProps={{
                MenuProps: {
                  className: "customDropdown",
                },
              }}
              helperText="The defined ODDs can only be referred"
            >
              {oddList.map((item) => (
                <MenuItem key={item.id} value={item}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <br />
          <div className={`d-flex justify-content-end`}>
            {isEdit ? '' :
            <Button
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                color: "text.main",
                textTransform: "none",
                fontSize: "13px",
              }}
              disabled={loading || isEdit }
              type="submit"
            >
              Submit
            </Button>
            }
          </div>
        </form>
      </CardContent>
    </Box>
  );
};
export default connect(null, { ReduxUpdateMapsJourney })(MapsForm);
