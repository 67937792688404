import { SET_DSPACE_TRAFFIC_CONFIG, UPDATE_DSPACE_TRAFFIC_CONFIG } from ".."

export const ReduxCreateDSpaceTrafficConfigurations = (data) => {
    return {
        type: SET_DSPACE_TRAFFIC_CONFIG,
        payload: data
    }
}

export const ReduxUpdateDSpaceTrafficConfigurations = (obj) => {
    return {
        type: UPDATE_DSPACE_TRAFFIC_CONFIG,
        payload: obj
    }
}