import { Box } from "@mui/material";
import ButtonX from "components/buttonX";
import React, { Fragment } from "react";
import { ModalX } from "components";
import CarMakerForm from "./carMakerComponent/carMakerForm";
import CarMakerTable from "./carMakerComponent/carMakerTable";

export default function CoSimCarMaker() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Fragment>
      <ModalX open={open} close={handleClose}>
        <CarMakerForm handleClose={handleClose} />
      </ModalX>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        mb={2}
      >
        <ButtonX onClick={handleOpen}>New Project</ButtonX>
      </Box>
      <CarMakerTable />
    </Fragment>
  );
}
