import { MAP_APP_PROJECT, MAP_STATUS, ODD_ZONE_AVAILABILITY_ENDPOINT, SELECTED_MAP_STATUS, VERSATILE_MAP } from "constants/endpoints"
import { httpGet, httpPost, httpPostFormdata, httpPostFromData, httpPut } from "utils/httpMethods"

export const getMapProjectsById = async (body, pagination) => {
    const {pageNumber = 1, testsPerPage = 10 } = pagination || {}
    return await httpGet({ url: `${MAP_APP_PROJECT}?project_id=${body}&page=${pageNumber}&page_size=${testsPerPage}`, payload: body })
        .then((res) => {
            return res
            
        })
        .catch((err) => {
            return err?.response
        })
}

export const getMapListById = async (projectId) => {
    return await httpGet({ url: `${MAP_APP_PROJECT}?project_id=${projectId}` })
        .then((res) => {
            return res
            
        })
        .catch((err) => {
            return err?.response
        })
}

export const getMapListByPralionId = async (pralionId) => {
    return await httpGet({ url: `${MAP_APP_PROJECT}?pralion_id=${pralionId}` })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const createMapProjects = async (body) => {
    return await httpPost({ url: MAP_APP_PROJECT, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const updateMapProjects = async (body) => {
    return await httpPut({ url: MAP_APP_PROJECT, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getOddZoneMapProjects = async (body) => {
    return await httpGet({ url: `${ODD_ZONE_AVAILABILITY_ENDPOINT}/652f8b37e9bf9b360a93ef50`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const createVersatileMap = async (body) => {
    return await httpPostFormdata({ url: VERSATILE_MAP, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getMapStatus = async (mapId) => {
    return await httpGet({ url: `${MAP_STATUS}?map_gid=${mapId}`, payload: mapId })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getMapLogStatus = async (mapId) => {
    return await httpGet({ url: `${MAP_STATUS}?map_gid=${mapId}&logs=true`, payload: mapId })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const createMapStatus = async (body) => {
    return await httpPost({ url: SELECTED_MAP_STATUS, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}