import React, { Fragment, useState } from "react";
import { Box, Grid, Radio, Button, Menu, MenuItem } from "@mui/material";
import ButtonX from "components/buttonX";
import { ModalX, ProgressX, Typos } from "components";
import { Link, useNavigate, useParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EducAgentsForm from "./educAgentsComponent/educAgentsForm";
import EducAgentsTable from "./educAgentsComponent/educAgentsTable";
import {
  getEducAgentByPralionId,
  getEducAgentProjectsById,
} from "endpointServices/educAgentServices";
import { SetInfoToLocalStorage } from "utils";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dateConverter from "utils/dateConverter";
import { ReduxCreateEducAgentsJourney } from "services/redux-store/actions/actions-educAgents";
import { connect } from "react-redux";
import underConstruction from "../../assets/images/underConstruction.png";
import EducAgentsStatus from "./educAgentsComponent/educAgentsStatus";
import { TENSOR_BOARD } from "constants/endpoints";
import { pageSize } from "utils/staticsData";
import { getMapProjectsById } from "endpointServices/mapsServices";
import { getAllTestVehicleProjects } from "endpointServices/testVehicleService";

const EducAgents = ({ ReduxCreateEducAgentsJourney }) => {
  const param = useParams();
  let navigator = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [list, setList] = React.useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [page, setPage] = useState(1);
  const [MapsExistFlag, setMapsExistFlag] = useState(false);
  const [loadingVehicles, setLoadingVehicles] = useState(false);
  const [vehiclesExistFlag, setVehiclesExistFlag] = useState(false);
  const [loadingMaps, setLoadingMaps] = useState(false);
  const [isFetching, setFetching] = React.useState(false);
  const [underConstructionModal, setUnderConstructionModal] =
    React.useState(false);

  const [statusOpen, setStatusOpen] = React.useState(false);
  const [trafficVizUrl, setTrafficVizUrl] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [VisualiserDisable, setVisualiserDisable] = useState(false);
  const [tensorBoardDisable, setTensorBoardDisable] = useState(false);
  const [statusData, setStatusData] = React.useState({
    name: "",
    pralionId: "",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleStatusOpenModal = async (event, data) => {
    setVisualiserDisable(true);
    setTensorBoardDisable(true);
    setAnchorEl(event.target);
    setStatusData({
      name: data?.name,
      pralionId: data?.pralion_id,
    });

    const response = await getEducAgentByPralionId(data?.pralion_id);
    console.log("response", response);
    if (response?.status === 200) {
      const { data } = response?.data;
      console.log("data", data)
      setSessionId(data?.training_session_id);
      setTrafficVizUrl(data?.visualizer_url);
      setVisualiserDisable(false);
      setTensorBoardDisable(false);
    } else {
      setVisualiserDisable(false);
      setTensorBoardDisable(false);
      setSessionId(null);
    }
    // const matchingVisualiser = data.versions.find(version => version.version_number === data.application_version_id);
    // setTrafficVizUrl(matchingVisualiser)
  };

  const handleMenuItem = (action) => {
    console.log('action', action)
    setAnchorEl(null);
    if (action === "Status") {
      setStatusOpen(true);
    } else if (action === "visualiser") {
      if (trafficVizUrl) {
        window.open(trafficVizUrl, "_blank");
      } else {
        toast.info("Visualiser URL not available");
      }
    } else if (action === "tensorboard") {
      if (sessionId === null) {
      toast.info(`Tensor board URL not available`);
    } else {
      console.log('${TENSOR_BOARD}/${sessionId}', `${TENSOR_BOARD}/${sessionId}`)
        window.open(`${TENSOR_BOARD}/${sessionId}`, "_blank");
    }
  }
  };

  React.useEffect(() => {
    getProjectList();
    fetchVehicles();
    getMapList({ pageNumber: page, testsPerPage: pageSize });
    localStorage.removeItem("pralion_id");
    localStorage.removeItem("observer_id");
  }, []);

  const getProjectList = async () => {
    setFetching(true);
    const projectId = parseInt(param.id);
    const response = await getEducAgentProjectsById(projectId);
    console.log("response", response);
    if (response?.status === 200) {
      setFetching(false);
      const data = response.data?.data?.applications;
      setList(data);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setList([]);
      setFetching(false);
    }
  };

const fetchVehicles = async () => {
  setLoadingVehicles(true);

  try {
    const response = await getAllTestVehicleProjects(parseInt(param.id));

    if (response?.status === 200) {
      if (response?.data?.data?.test_vehicles.length > 0) {
        const hasVehicleId = response.data.data.test_vehicles.some(
          (vehicle) => vehicle.application_version_id !== null
        );

        if (hasVehicleId) {
          setVehiclesExistFlag(true);
        } else {
          setVehiclesExistFlag(false);
        }
      } else {
        setVehiclesExistFlag(false);
      }
    } else {
      setVehiclesExistFlag(false);
    }
  } catch (err) {
    setVehiclesExistFlag(false);
  } finally {
    setLoadingVehicles(false);
  }
};

const getMapList = async (pagination) => {
  setLoadingMaps(true);

  const projectId = parseInt(param.id);
  const response = await getMapProjectsById(projectId, pagination);

  if (response?.status === 200) {
    if (response?.data?.data?.applications.length > 0) {
      const hasVersionId = response.data.data.applications.some(
        (application) => application.application_version_id !== null
      );

      if (hasVersionId) {
        setMapsExistFlag(true); 
      } else {
        setMapsExistFlag(false); 
      }
    } else {
      setMapsExistFlag(false);
    }
  } else {
    setMapsExistFlag(false);
  }
  setLoadingMaps(false);
};

  const goto = (data) => {
    SetInfoToLocalStorage("p_id", param.id);
    console.log("data", data);
    navigator(
      AUTHENTICATED_ROUTES.environmentSetup
        .replace(":id", param.id)
        .replace(":aid", data.pralion_id)
    );
    ReduxCreateEducAgentsJourney(data.pralion_id, data.name);
  };

  const columns = [
    {
      field: "name",
      headerName: <span className="heading-2">Project Name</span>,
      width: 465,
      renderCell: (params) => (
        <Grid>
          <Button
            className="Main-link-simple heading-3"
            onClick={() => goto(params.row)}
            sx={{ color: "white" }}
          >
            {params.value}
          </Button>
        </Grid>
      ),
    },
    {
      field: "description",
      headerName: <span className="heading-2">Description</span>,
      width: 360,
      renderCell: (params) => (
        <span className="row-heading">{params.value}</span>
      ),
    },
    {
      field: "date_created",
      headerName: <span className="heading-2">Created At</span>,
      width: 183,
      renderCell: (params) => (
        <span className="row-heading">{dateConverter(params.value)}</span>
      ),
    },
    {
      field: "action",
      renderHeader: () => <span className="heading-2">{"Status"}</span>,
      width: 235,
      renderCell: (params) => {
        const status = params.row.dag_state.dag_state;
        // const pendingState = status === "pending" ? "pending" : status === "queued" ? "queued" : !params.row.dag_state.dag_state 
        return (
          <>
            <div className="scenarioStatusRadioButton">
              <Radio
                checked={
                  status === "pending" || !params.row.dag_state.dag_state 
                }
                sx={{
                  transform: "scale(0.6)",
                  width: "1px",
                  height: "1px",
                  "& > input": {
                    backgroundColor: "gray",
                    color: "red",
                    "&:checked + span": {
                      backgroundColor: "gray",
                      color: "gray",
                      borderRadius: "16px",
                      width: "22px",
                      height: "22px",
                    },
                  },
                }}
              />
              <Radio
                  checked={
                    status === "queued" 
                  }
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "#ffff00",
                      color: "#ffff00",
                      "&:checked + span": {
                        backgroundColor: "#ffff00",
                        color: "#ffff00",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
              />
              <Radio
                checked={status === "running"}
                sx={{
                  transform: "scale(0.6)",
                  width: "1px",
                  height: "1px",
                  "& > input": {
                    backgroundColor: "#ff9800",
                    color: "#ff9800",
                    "&:checked + span": {
                      backgroundColor: "#ff9800",
                      color: "#ff9800",
                      borderRadius: "16px",
                      width: "22px",
                      height: "22px",
                    },
                  },
                }}
              />
              <Radio
                color="success"
                checked={status === "success"}
                sx={{
                  transform: "scale(0.6)",
                  width: "1px",
                  height: "1px",
                  "& > input": {
                    backgroundColor: "#4caf50",
                    color: "#4caf50",
                    "&:checked + span": {
                      backgroundColor: "#4caf50",
                      color: "#4caf50",
                      borderRadius: "16px",
                      width: "22px",
                      height: "22px",
                    },
                  },
                }}
              />
              <Radio
                checked={status === "failed"}
                sx={{
                  transform: "scale(0.6)",
                  width: "1px",
                  height: "1px",
                  "& > input": {
                    backgroundColor: "red",
                    color: "red",
                    "&:checked + span": {
                      backgroundColor: "red",
                      color: "red",
                      borderRadius: "16px",
                      width: "22px",
                      height: "22px",
                    },
                  },
                }}
              />
            </div>

            <Button
              sx={{ color: "text.main" }}
              onClick={(event) => handleStatusOpenModal(event, params.row)}
              disabled={status === "pending" || !params.row.dag_state.dag_state}
            >
              <MoreVertIcon />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <Fragment>

 <ModalX
  open={open}
  close={handleClose}
  heading={"Create New EducAgents"}
  subheading={"Enter EducAgents details to continue"}
>
  {loadingMaps || loadingVehicles ? (
    <>
      <ProgressX />
    </>
  ) : MapsExistFlag && vehiclesExistFlag ? (
    <>
      <EducAgentsForm
        syncList={getProjectList}
        handleClose={handleClose}
        
      />
    </>
  ) : !MapsExistFlag ? (
    <>
      <Typos.Body1>
        No Maps found to create a scenario. Please go and create a{" "}
        <Link to={`/projects/${param.id}/applications/maps`}>
          Map
        </Link>
        .
      </Typos.Body1>
    </>
  ) : (
    <>
      <Typos.Body1>
        No vehicles found to create a scenario. Please go and create a{" "}
        <Link to={`/projects/${param.id}/applications/test-vehicles`}>
          test vehicle
        </Link>
        .
      </Typos.Body1>
    </>
  )}
</ModalX>

      <ModalX
        open={underConstructionModal}
        close={() => setUnderConstructionModal(false)}
        heading={"EducAgents"}
        subheading={"Service Under Maintenance "}
      >
        <Box display="flex" flexDirection="row" alignItems="center" p={2}>
          <Box flex="1" p={2}>
            <div>
              <p>
                We apologize for any inconvenience, this application is
                currently under maintenance. Only limited functionality is
                available where you can perform the application journey but
                would not be able to execute or generate scenarios.
              </p>
            </div>
          </Box>
          <Box
            flex="1"
            p={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={underConstruction}
              alt="Under Construction"
              style={{ maxWidth: "80%", maxHeight: "100%" }}
            />
          </Box>
        </Box>
      </ModalX>

      <ModalX
        open={statusOpen}
        close={() => setStatusOpen(false)}
        heading={`${statusData.name} Status`}
        subheading={"Status Details"}
      >
        <EducAgentsStatus statusObject={statusData} handleClose={handleClose} />
      </ModalX>

      <Menu
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            color: "black",
          },
        }}
      >
        <MenuItem onClick={() => handleMenuItem("Status")}>Status</MenuItem>
        <MenuItem
          onClick={() => handleMenuItem("visualiser")}
          disabled={VisualiserDisable}
        >
          Visualiser
        </MenuItem>
        <MenuItem
          onClick={() => handleMenuItem("tensorboard")}
          disabled={tensorBoardDisable}
        >
          Tensor Board
        </MenuItem>
      </Menu>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        mb={2}
      >
        <ButtonX onClick={handleOpen}>Train New Agent</ButtonX>
      </Box>
      <EducAgentsTable table={list} headers={columns} fetching={isFetching} />
    </Fragment>
  );
};
export default connect(null, { ReduxCreateEducAgentsJourney })(EducAgents);
