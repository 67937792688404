import { AUTO_SCENARIO_MAIN, GET_CODE_FOR_OBSERVER, TRAFFIC_PROFILE_LIST,POST_CODE_FOR_OBSERVER, GET_CODE_FOR_OBSERVER_METRIC,GET_RIL_MAP_FEATURE, GET_RIL_PERPCETION_FEATURE,GET_RIL_EGO_FEATURE, GET_RIL_REWARD_INTERFACES, GET_RIL_TEST_CODE, GET_RIL_REWARD_FUNC_LIST, POST_RIL_REWARD_FUNC_LIST, GET_RIL_TEST_FUNCTION, POST_RIL_TEST_FUNCTION, GET_RIL_REWARD_FUNC_CODE, GET_RIL_HYPER_PARAMETER_INTERFACE, POST_RIL_HYPER_PARAMETER_INTERFACE, GET_RL_TENSOR_BOARD, EDUC_AGENT_MAIN, POST_RL_START_TRAINING, GET_FEATURE_LIST, GET_PRE_DEFINE_FUNC_CODE} from "constants/endpoints"
import { httpGet, httpPost, httpPut } from "utils/httpMethods"

export const getEducAgentProjectsById = async (body) => {
    return await httpGet({ url: `${EDUC_AGENT_MAIN}?project_id=${body}`, payload: body })
        .then((res) => {
            return res
            
        })
        .catch((err) => {
            return err?.response
        })
}

export const getEducAgentByPralionId = async (body) => {
    return await httpGet({ url: `${EDUC_AGENT_MAIN}?pralion_id=${body}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getEducAgentTrafficProfileList = async (body) => {
    return await httpGet({ url: `${TRAFFIC_PROFILE_LIST}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}


export const createEducAgentProjects = async (body) => {
    return await httpPost({ url: EDUC_AGENT_MAIN, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const udateEducAgentProjects = async (body) => {
    return await httpPut({ url: EDUC_AGENT_MAIN, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getCodeForObserver = async (body) => {
    return await httpGet({ url: GET_CODE_FOR_OBSERVER, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const postCodeForObserver = async (body) => {
    return await httpPost({ url: POST_CODE_FOR_OBSERVER, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getCodeForObserverMetric = async (body) => {
    return await httpGet({ url: GET_CODE_FOR_OBSERVER_METRIC, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}


export const getRilEgoFeatures = async (body) => {
    return await httpGet({ url: GET_RIL_EGO_FEATURE, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getRilPerceptionFeature = async (body) => {
    return await httpGet({ url: GET_RIL_PERPCETION_FEATURE, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getRilMapFeature = async (body) => {
    return await httpGet({ url: GET_RIL_MAP_FEATURE, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

///// Code screens api ////////

export const getRilInterfaces = async (body) => {
    return await httpGet({ url: `${GET_RIL_REWARD_INTERFACES}?pralion_id=${body}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getRilSidePanelInterfaces = async (body) => {
    return await httpGet({ url: `${GET_RIL_REWARD_FUNC_LIST}?pralion_id=${body}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getRilUpdateCodeInterfaces = async (body) => {
    return await httpGet({ url: `${GET_RIL_REWARD_FUNC_CODE}?pralion_id=${body.pralionId}&reward_func_name=${body.name}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getPreDefineCodeInterfaces = async (body) => {
    return await httpGet({ url: `${GET_PRE_DEFINE_FUNC_CODE}?pralion_id=${body}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}



export const postRilValidateInterfaces = async (body) => {
    return await httpPost({ url:`${POST_RIL_REWARD_FUNC_LIST}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}



export const getRilTestInterfaces = async (body) => {
    return await httpGet({ url: `${GET_RIL_TEST_CODE}?pralion_id=${body}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getRilSidePlanelTestInterfaces = async (body) => {
    return await httpGet({ url: `${GET_RIL_TEST_FUNCTION}?pralion_id=${body}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const postRilTestValidateInterfaces = async (body) => {
    return await httpPost({ url:`${POST_RIL_TEST_FUNCTION}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}


export const getRlHyperParametersInterfaces = async (body) => {
    return await httpGet({ url: `${GET_RIL_HYPER_PARAMETER_INTERFACE}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const postRlHyperParametersInterfaces = async (body) => {
    return await httpPost({ url:`${POST_RIL_HYPER_PARAMETER_INTERFACE}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getRlTensorBoard = async (body) => {
    return await httpGet({ url: `${GET_RL_TENSOR_BOARD}?pralion_id=${body}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const postRlStartTraining = async (body) => {
    return await httpPost({ url: `${POST_RL_START_TRAINING}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getEducAgentFeatureDescription = async (body) => {
    return await httpGet({ url: `${GET_FEATURE_LIST}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}