import React, { useEffect, useState } from 'react'
import { Box, Grid, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { startMyScenario, updateScenarioProjects } from 'endpointServices/myScenarioServices';
import { getAllProjectService } from 'endpointServices/projectServices';
import { GetInfoFromLocalStorage } from 'utils';
import { allScenarioState } from '../mainState';
import execute from "assets/images/exe.png";
import { ExceptionHandlerModal } from 'components/exceptionHandlerModal'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import STORE from "services/redux-store/store";
import { AUTHENTICATED_ROUTES } from 'router/constants';


export default function ScenarioExecution() {
  // const prelinId = GetInfoFromLocalStorage('pralion_id') || ''
  // const observerId = GetInfoFromLocalStorage('observer_id') || ''
  // const projectId = GetInfoFromLocalStorage('p_id') || ''
  const mapGenId = GetInfoFromLocalStorage('map_id') || ''

  const param = useParams()
  const navigator = useNavigate();
  const { MyScenario, Projects } = STORE.getState();
  const { projectId } = Projects;
  const {oddData, pralionId, observerId } = MyScenario
  const { pedestrians, staticObjects, plausibility } = MyScenario.routeConfiguration

  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState({})
  const [isDisable, setDisable] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const[errorMsg, setErrMsg] = useState('')

  useEffect(() => {
    getProjectDetails()
  }, [param])

  const getProjectDetails = async () => {
    const id = JSON.parse(projectId)
    setLoading(true)
    const response = await getAllProjectService(id)
    if (response?.status === 200) {
      const { data } = response?.data
      const projectFileter = data.projects.filter((item) => item.id === id)[0] || {}
      setProject(projectFileter)
      setDisable(false)
    }
    else {
      setProject({})
      setDisable(true)
    }
  }

  const onSaveData = async () => {
    try {
      const scenarioData = allScenarioState

      const scenario = {
        ...scenarioData.user_scenario,
        vut: {
          ...scenarioData.user_scenario?.vut,
          speed: {
            ...scenarioData.user_scenario?.vut?.speed,
            step_size: scenarioData.user_scenario?.vut?.speed.step_size.toString()
          }
        },
        traffic_Participants: scenarioData.user_scenario.traffic_Participants?.map(obj => ({
          ...obj,
          speed: {
            ...obj.speed,
            step_size: obj.speed.step_size.toString()
          }
        })),
        Pedestrians: pedestrians.map(obj => ({
          ...obj,
          speed: {
            ...obj.speed,
            step_size: obj.speed.step_size.toString(),
          },
        })),
        stationary_objects: staticObjects,
        plausibility
      }
  
  
      const payload = {
        pralion_id: pralionId,
        function: 'Acc',
        observer_id: observerId,
        map_gid: oddData.gid_kwh,
        map_generation_id: MyScenario.selectedMap,
        test_vehicle_id: MyScenario.testVehicleProject.pralion_id,
        is_scenario_uploaded: MyScenario.usingFile === true ? true : false,
        user_scenario: {
          scenario: MyScenario.usingFile ? MyScenario.scenarioFile.scenario : scenario,
        },
      };
  

      setDisable(true)
      
      const response = await updateScenarioProjects(payload)
      if (response?.status === 200) {
        // const { pralion_id } = response?.data.data
        const body = {
          pralion_id: pralionId
        }
        const secondResponse = await startMyScenario(body)
        if (secondResponse?.status === 200) {
          setTimeout(() => {
            setDisable(false);
            navigator(AUTHENTICATED_ROUTES.myScenarios.replace(":id", projectId));
          }, 4000);
          console.log('secondResponse', secondResponse)
          toast.success(`Successfuly started myScenario generation`);
        }
        else {
          const errr = response?.response?.data?.message
          console.log('errr', errr)
          setOpenModal(true)
          toast.error(`Error! Failed to start my-scenario. Please see the logs`)
          setErrMsg(errr)

          // const err = response?.message || 'Failed to start my scenario. Please see the logs'
        }

      } else {
        setDisable(false)
        const errr = response?.response?.data?.message
        console.log('errr', errr)
        if (response?.status === 500) {
          setOpenModal(true)
          toast.error(`Error! Failed to start my-scenario. Please see the logs`)
          setErrMsg(errr)
        }
        toast.error(`${errr}. Please try again later`)

        // const err = response?.message || 'Failed to update. Please see the logs'
      }
    }
    catch (error) {
      toast.error(error.toString())
    }
  }

  const handleCloseModal = () => {
        setOpenModal(false)
    }

  return (
    <>
   <ExceptionHandlerModal
    open={openModal}
    close={handleCloseModal}
    applicationName={'MyScenarios'}
    errorMessage ={errorMsg}
    />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} justifyContent="end">
          <Grid item>
            <Button
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                color: "text.main",
                width: "200px",
                marginRight: "10px"
              }}
              onClick={() => navigator(-1)}
            >

              Back
            </Button>
            <Button
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                color: "text.main",
                width: "200px",
                float: 'right'
              }}
              disabled={isDisable}
              type="submit"
              onClick={onSaveData}
            >
              Generate
            </Button>

          </Grid>
        </Grid>
      </Box>
      <Grid container sx={{
        backgroundColor: "secondary.dark",
        padding: "4rem",
        borderRadius: "4px",
        marginTop: "1rem",
        height: "30rem",
        justifyContent: 'center'
      }}>
        {/* <Grid item xs={6}> */}
        <span >
          <div style={{ position: 'absolute', top: '50%', left: '45%' }}>
            <p>To begin, press the "Generate" button and monitor</p>
            <p style={{ textAlign: 'center' }}>the results in the corresponding application</p>
          </div>

          <img
            src={execute}
            style={{ width: "100%", marginBottom: 0 }}
            alt="icons"
          />
        </span>
      </Grid>
    </>
  )
}
