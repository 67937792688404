import React from "react";
import { Typography } from "@mui/material";
import PaperCanvas from "../paperCanvas";

export default function InputSegment({ segmentNumber, vehSegmentNumber }) {
  return (
    <div>
      <div
        style={{
          backgroundColor: "#00A3B5",
          position: "absolute",
          top: "13rem",
          left: "16.5rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "15rem",
          borderTopLeftRadius: "6px",
          borderBottomRightRadius: "6px",
        }}
      >
        <Typography sx={{ marginLeft: "10px" }}>
          No of segment : {segmentNumber}
        </Typography>
        <Typography sx={{ marginLeft: "10px" }}>
          No of Veh per segment : {vehSegmentNumber}
        </Typography>
      </div>
      <PaperCanvas selectedValue={segmentNumber} vehSegmentNumber={vehSegmentNumber} />
    </div>
  );
}
