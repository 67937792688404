

export let autoScenarioMainState = {
  placeVehicle:{},
  placeDynamic: {},
  placeActor: {},
  agents:[],
  egoAgents:[],
}

export let educAgentPayload = {}

export const getVehicleProfile = (data) => {
  autoScenarioMainState.placeVehicle = data
}

export const getDynamicActors = (data) => {
  autoScenarioMainState.placeDynamic = data
}

export const getStaticActors = (data) => {
  autoScenarioMainState.placeActor = data
}

export const getAgents = (data) => {
  autoScenarioMainState.agents = data
}

export const getEgoAgents = (data) => {
  autoScenarioMainState.egoAgents = data
}

export const getEducAgentPayload = (data) => {
  console.log('data', data)
  educAgentPayload = data
}