import { SET_VEHICLE, UPDATE_VEHICLE } from ".."

export const ReduxCreateMyVehicleJourney = (pid,pname,readOn,vehiclePId,vehicleDName,vehicleprop, rendering_Type) => {
    return {
        type: SET_VEHICLE,
        payload: { pralionId: pid, projectName: pname, readOnly: readOn, vehicleProfileId: vehiclePId, vehicleDisplayName: vehicleDName, vehicleProperties: vehicleprop, rendering_Type: rendering_Type }
    }
}

export const ReduxUpdateMyVehicleJourney = (obj) => {
    return {
        type: UPDATE_VEHICLE,
        payload: obj
    }
}