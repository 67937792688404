import * as React from "react";
import { Button, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useParams } from "react-router-dom";
import { CoSimulationTableData } from "utils";
import { ReduxCreateDSpaceJourney } from "services/redux-store/actions/actions-dspace";
import { connect } from "react-redux";
import { AUTHENTICATED_ROUTES } from "router/constants";

function SimulationTable({ ReduxCreateDSpaceJourney }) {
	const searchParams = useParams();

	console.log("searchParams", searchParams);
	const columns = [
    {
      field: "projectName",
      headerName: <span className="heading-2">Co - Simulation Platform</span>,
      width: 400,
      renderCell: (params) => {
        const disabledStyle = params.row.disabled
          ? { opacity: 0.5, pointerEvents: "none" }
          : {};
        return (
          <Grid>
            <Link
              className="Main-link-simple"
              onClick={() => ReduxCreateDSpaceJourney(searchParams.id)}
              to={AUTHENTICATED_ROUTES.simulationDetails.replace(
                ":id",
                searchParams.id
              )}
              // to={`/applications/test-vehicles/co-simulation/co-simulation-details/${params.id}`}
              style={disabledStyle}
            >
              {params.value}
            </Link>
          </Grid>
        );
      },
    },
  ];

	return (
		<div className="mt-4">
			<DataGrid
				rows={CoSimulationTableData}
				columns={columns}
				autoHeight={true}
				getRowClassName={(params) => (params.row.disabled ? "disabled-row" : "")}
				sx={{
					border: "none",
					borderRadius: "12px",
					backgroundColor: "secondary.dark",
					"& .MuiDataGrid-cell": {
						color: "text.main",
					},
					"& .MuiDataGrid-footerContainer": { display: "none" },
				}}
			/>
		</div>
	);
}
export default connect(null, { ReduxCreateDSpaceJourney })(SimulationTable);
