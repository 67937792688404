import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  Box,
  Button
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { rilEgoList } from "utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ButtonX } from "components";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { getEgoAgents } from "../../stateMaintain";
import { ReduxUpdateEducAgentsJourney } from "services/redux-store/actions/actions-educAgents";
import { connect } from "react-redux";
import STORE from "services/redux-store/store"

const HyperParameterTable = ({ReduxUpdateEducAgentsJourney}) => {
  const param = useParams()
  const navigate = useNavigate();
  const {EducAgents} = STORE.getState()

  const [clickNextStage, setClickNextStage] = useState(0);
  const [checked, setChecked] = useState(false);
  const [egoParameterList, setEgoParameterList] = useState([])
  const [isDisable, setIsDisabled] = useState(true);

  const onClickNext = () => {
    getEgoAgents(egoParameterList);
    switch (clickNextStage) {
      case 0:
        navigate(`/projects/${param.id}/applications/educ-agents/modal-input-agents`)
        break;
      default:
        break;
    }
    // navigate();
    console.log("egoParameterList", egoParameterList)
    ReduxUpdateEducAgentsJourney({hyperParameterEgo: egoParameterList})
  };
  
  const onClickBack = () => {
		switch (clickNextStage) {
			case 0:
				navigate(-1)				
				break;
			case 1:
				setClickNextStage(0);
				break;
      case 2:
        setClickNextStage(1);
				break;
			default:
				break;
		}
		// navigate();
	};

  useEffect(() => {
    getStoreValues();
  }, [EducAgents.hyperParameterEgo]);

  const getStoreValues = () => {
    if(EducAgents.hyperParameterEgo !== undefined && EducAgents.hyperParameterEgo !== null && EducAgents.hyperParameterEgo.length > 0 ) {
      console.log("Ego Parameter List", EducAgents.hyperParameterEgo);
      setEgoParameterList(EducAgents.hyperParameterEgo)
    }
  }

  const handleCheckBoxChange = (item) => {
    const updatedChecked = [
      ...egoParameterList,
    ];
    console.log("updatedChecked", updatedChecked)
    const itemIndex = egoParameterList.findIndex((obj) => obj.id === item.id);
    if (itemIndex !== -1) {
      updatedChecked.splice(itemIndex, 1);
    } else {
      updatedChecked.push({
        id: item.id,
        json_representation: item.json_representation,
      });
    }
    setEgoParameterList(updatedChecked);
    const selectedCheck = Object.values(updatedChecked).some((value) => value);
    console.log("selectedCheck", selectedCheck)
    setIsDisabled(!selectedCheck);
  };

  return (
    <>
      {/* <Grid container justifyContent={"end"}>
        <ButtonX className={`me-2`} onClick={onClickBack}>
          Back
        </ButtonX>
        <ButtonX onClick={onClickNext} disabled={isDisable}>Next</ButtonX>
      </Grid> */}
      
      <Grid
        sx={{
          backgroundColor: "secondary.dark",
          paddingBottom: "12px",
          borderRadius: "8px",
        }}
      >
        <Grid className="vehicle-details-table-headings">
          <Typography
            color="text.main"
            sx={{ marginLeft: "1rem", marginTop: "1.5rem" }}
          >
            Ego Parameters
          </Typography>
          <Typography
            color="text.main"
            sx={{ marginTop: "1.5rem", marginRight: "1rem", opacity: 0.5, pointerEvents: "none" }}
          >
            Add Parameter
            <AddCircleOutlineIcon style={{ fontSize: "large" }} />
          </Typography>
        </Grid>
        <Grid className="vehicle-details-table-data">

          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Selection</TableCell>
                  <TableCell>Feature</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rilEgoList.map((item) => {
                  return (
                    <TableRow key={item.feature}>
                      <TableCell>
                        <Checkbox
                          checked={egoParameterList.some((value) => value.id === item.id)}
                          onChange={() => handleCheckBoxChange(item)}
                        />
                      </TableCell>
                      <TableCell>{item.feature}</TableCell>
                      <TableCell>{item.type}</TableCell>
                      <TableCell>
                        <Grid
                          style={{
                            display: "flex",
                            opacity: 0.5,
                            pointerEvents: "none",
                          }}
                        >
                          <IconButton>
                            <EditIcon />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  )
}
export default connect(null, { ReduxUpdateEducAgentsJourney })(HyperParameterTable);