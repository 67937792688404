import { Grid } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import DownloadTemplateTable from "./downloadTemplateComponent/downloadTemplateTable";
import DownloadIcon from "@mui/icons-material/Download";


const VersatileDownloadata = (downloadList) => {
  const VERSATILE_BUILDS = downloadList?.data;
  return [
    {
      id: 1,
      name: (
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href={VERSATILE_BUILDS?.versatile_windows?.url}
        >
          <DownloadIcon /> Windows
        </a>
      ),
      last_updated_version: VERSATILE_BUILDS?.versatile_windows?.version,
      date: "12-2-2023",
    },
    {
      id: 2,
      name: (
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href={VERSATILE_BUILDS?.versatile_linux?.url}
        >
          <DownloadIcon /> Linux
        </a>
      ),
      last_updated_version: VERSATILE_BUILDS?.versatile_linux?.version,
      date: "12-2-2023",
    },
  ];
}

const VisualTemplatesDownloadata = (downloadList) => {
  const VERSATILE_BUILDS = downloadList?.data;
  return [
    {
      id: 1,
      name: (
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href={VERSATILE_BUILDS?.visual_templates?.url}
        >
          <DownloadIcon /> Windows/Linux
        </a>
      ),
      last_updated_version: VERSATILE_BUILDS?.visual_templates?.version,
      date: "12-2-2023",
    },
  ];
}

const DspaceTempDownloadata = (downloadList) => {
  const VERSATILE_BUILDS = downloadList?.data;
  return [
    {
      id: 1,
      name: (
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href={VERSATILE_BUILDS?.traffic_fmu_linux?.url}
        >
          <DownloadIcon /> AAI Intelligent Traffic - Linux FMU
        </a>
      ),
      last_updated_version: VERSATILE_BUILDS?.traffic_fmu_linux?.version,
      date: "12-2-2023",
    },
    {
      id: 2,
      name: (
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href={VERSATILE_BUILDS?.rr_worker_linux?.url}
        >
          <DownloadIcon />
          RR Worker - Linux
        </a>
      ),
      last_updated_version: VERSATILE_BUILDS?.rr_worker_linux?.version,
      date: "12-2-2023",
    },
    {
      id: 3,
      name: (
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href={VERSATILE_BUILDS?.dspace_cosim_windows?.url}
        >
          <DownloadIcon />
          dSPACE Co-Sim Client - Windows
        </a>
      ),
      last_updated_version: VERSATILE_BUILDS?.dspace_cosim_windows?.version,
      date: "12-2-2023",
    },
  ];
}

const CarlaDownloadata = (downloadList) => {
  const VERSATILE_BUILDS = downloadList?.data;
  return [
    {
      id: 1,
      name: (
        <a
          style={{ textDecoration: "none", color: "inherit" }}
          href={VERSATILE_BUILDS?.carla_cosim_linux?.url}
        >
          <DownloadIcon /> Linux
        </a>
      ),
      last_updated_version: VERSATILE_BUILDS?.carla_cosim_linux?.version,
      date: "12-2-2023",
    },
  ];
}

export default function DownloadTemplate() {
  const param = useParams();
  const location = useLocation();
  const [data, setData] = useState([]);
  const downloadList = location.state ? location.state.downloadList : {};
  console.log("downloadList", downloadList);

  console.log("param", param);

  console.log("data", data);
  useEffect(() => {
    if (param.id === "1") {
      setData(VersatileDownloadata(downloadList));
    } else if (param.id === "2") {
      setData(DspaceTempDownloadata(downloadList));
    } else if (param.id === "5") {
      setData(VisualTemplatesDownloadata(downloadList));
    }
    else if (param.id === "6") {
      setData(CarlaDownloadata(downloadList));
    }
  }, [param.id]);

  const columns = [
    {
      field: "name",
      headerName: <span className="heading-2">Compatibility</span>,
      width: 465,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          <Grid item>{params.value}</Grid>
        </Grid>
      ),
    },
    {
      field: "last_updated_version",
      headerName: <span className="heading-2">Last Updated Version</span>,
      width: 465,
    },
  ];

  return (
    <Fragment>
      <DownloadTemplateTable headers={columns} tableRow={data} />
    </Fragment>
  );
}
