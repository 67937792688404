import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function SimulationDetailsTable({ table, headers,  fetching}) {
    return (
        <div className="mt-4">
          <DataGrid
            rows={table}
            columns={headers}
            className="main-table-height"
            autoHeight={true}
            loading={fetching}
            sx={{
              border: "none",
              borderRadius: "12px",
              backgroundColor: "secondary.dark",
              "& .MuiDataGrid-cell": {
                color: "text.main",
              },
              "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": { marginTop: "15px", }
            }}
           
            initialState={{
              pagination: {
                paginationModel: { pageSize: 10, page: 0 },
              },
              sorting: {
                sortModel: [{ field: 'name', sort: 'desc' }],
              },
            }}
          />
        </div>
    );
}
