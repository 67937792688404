import React, { useEffect, useState } from "react";
import { TEST_SUITS } from "components/container/leftSideBarList";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { GetCurrentPagePathname } from "utils";
import { useParams } from "react-router-dom";
import { ModalX, ProgressX } from "components";
import { Fragment } from "react";
import {
  getTestSuits,
} from "endpointServices/testSuitServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import testSuits from "assets/icons/testSuits.png";
import { ReduxCreateTestSuitsJourney } from "services/redux-store/actions/actions-testSuits";
import { connect } from "react-redux";
import TestSuitsForm from "./testSuitsComponent/testSuitsForm";

const TestSuitsApp = ({ ReduxCreateTestSuitsJourney }) => {
  const param = useParams();
  const projectPath = GetCurrentPagePathname();
  const [isOpen, setOpen] = useState(false);
  const [state, setState] = useState({});
  const [suits, setSuits] = useState(TEST_SUITS);
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    getTestSuitsList();
  }, []);

  const handleOpen = (data) => {
    setOpen(true);
    setState(data);
  };

  const handleClose = () => setOpen(false);

  const getTestSuitsList = async () => {
    setLoader(true)
    const projectId = parseInt(param.id);
    const response = await getTestSuits(projectId);
    console.log("test suits list response", response);
    if (response?.status === 200) {
      const { data } = response?.data;
      const newApplications = data?.applications || [];
      const updatedSuits = [...TEST_SUITS, ...newApplications];
      console.log("updatedSuits", updatedSuits);
      setSuits(updatedSuits);
      setLoader(false)
    } else {
      setLoader(false)
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
    }
  };

  return (
    <Fragment>
      <ModalX
        open={isOpen}
        close={handleClose}
        heading={state?.title}
        subheading={"Application"}
      >
        {state?.title === "Add Test Suite" ? (
          <TestSuitsForm
            syncList={getTestSuitsList}
            handleClose={handleClose}
          />
        ) : (
          <p>
            Please contact sales for the new Applications.{" "}
            <b>sales@automotive-ai.com</b>
          </p>
        )}
      </ModalX>

        <Grid className="test-suits-scroll">
          <Grid container className="mt-2 mb-4">
          {loader ?
            <ProgressX
              application={true} height={110} width={"44%"}
            />
          : 
          suits.map((application, index) => (
              <>
                <Grid
                  item
                  sm={5}
                  linkcomponent={Link}
                  to={`${projectPath}/${application.url}`}
                  className={`d-flex flex-column justify-content-center application-box-size`}
                  key={index}
                  sx={{
                    border: "2px solid",
                    borderColor:
                      application.url !== "none"
                        ? "primary.main"
                        : "primary.main",
                    backgroundColor:
                      application.title === "NCAP"
                        ? "#c0cdd9ad"
                        : "secondary.dark",
                    textAlign: "center",
                    borderRadius: "10px",
                    m: 1,
                  }}
                >
                  {application.url === "none" ? (
                    <Link
                      className="Main-link-simple"
                      aria-disabled={true}
                      onClick={() => handleOpen(application)}
                    >
                      <div>{application.icons}</div>
                      <Typography
                        sx={{
                          color: "text.main",
                        }}
                      >
                        {application.title}
                      </Typography>
                    </Link>
                  ) : (
                    <Link
                      className="Main-link-simple"
                      aria-disabled={true}
                      to={
                        application.title === "NCAP" ||
                        application.title === "Parking"
                          ? "#"
                          : `${projectPath}/${
                              application.url === undefined
                                ? "test-suits"
                                : application.url
                            }/${encodeURIComponent(
                              application.title === undefined
                                ? application.name
                                : application.title
                            )}`
                      }
                      onClick={() => {
                        if (
                          application.title !== "NCAP" &&
                          application.title !== "Parking"
                        ) {
                          const title =
                            application.title === undefined
                              ? application.name
                              : application.title;
                          ReduxCreateTestSuitsJourney(
                            application.pralion_id,
                            title,
                            application.application_version_id
                          );
                        }
                      }}
                    >
                      <div>
                        {application.icons === undefined ? (
                          <img src={testSuits} alt="icons" />
                        ) : (
                          application.icons
                        )}
                      </div>
                      <Typography
                        sx={{
                          color: "text.main",
                        }}
                      >
                        {application.title === undefined
                          ? application.name
                          : application.title}
                      </Typography>
                    </Link>
                  )}
                </Grid>
                {application.name === "Platooning" ? (
                  <hr
                    style={{ color: "#52637F", width: "20em", opacity: "1" }}
                  />
                ) : (
                  ""
                )}
              </>
            ))
          }
          </Grid>
        </Grid>
      
    </Fragment>
  );
};
export default connect(null, { ReduxCreateTestSuitsJourney })(TestSuitsApp);
