import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import ExtractAutoScenario from "./modules/extractAutoScenario";
import ExtractMyScenario from "./modules/extractMyScenario";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function ScenExtract() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider", marginTop: "2rem" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTab-root": {
              fontSize: "1.2rem",
            },
            backgroundColor: "#304A74",
            border: "1px solid #808080a6",
          }}
        >
          <Tab
            label="Auto Scenario"
            sx={{ borderRight: "1px solid #808080a6" }}
          />
          <Tab label="My Scenario" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ExtractAutoScenario />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ExtractMyScenario />
      </CustomTabPanel>
    </Box>
  );
}
