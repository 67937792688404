import { GET_TEST_VEHICLE_PROJECT, HIGH_FIDELITY_COSIM_CLIENTS, HIGH_FIDELITY_TRIGGER_DAG, LOW_FIDELITY_LIST } from "constants/endpoints"
import { httpGet, httpPost, httpPut } from "utils/httpMethods"

export const getLowFidelityList = async (body, pagination) => {
    const {pageNumber = 1, testsPerPage = 10 } = pagination || {}
    return await httpGet({ url: `${LOW_FIDELITY_LIST}?pralion_id=${body}&page=${pageNumber}&page_size=${testsPerPage}&order=desc`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getTestVehicleProjectsByIdLowfidelity = async (body) => {
    return await httpGet({ url: `${GET_TEST_VEHICLE_PROJECT}?project_id=${body}`, payload: body })
        .then((res) => {
            return res
            
        })
        .catch((err) => {
            return err
        })
}

export const getCosimClients = async (body) => {
    return await httpGet({ url: HIGH_FIDELITY_COSIM_CLIENTS, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const triggerLowFidelityDag = async (body) => {
    return await httpPost({ url: HIGH_FIDELITY_TRIGGER_DAG, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}