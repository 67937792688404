import React, { Fragment, useEffect, useState } from "react";
import { Button, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { ButtonX, ModalX, ReadOnlyTag, Typos } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { getMapListById } from "endpointServices/mapsServices";
import STORE from "services/redux-store/store";
import MapOdds from "./mapListComponent/mapOdds";
import { getMapGidListByPralionId, getScenarioByPralionId } from "endpointServices/myScenarioServices";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { ReduxUpdateMyScenarioJourney } from "services/redux-store/actions";
import { connect } from "react-redux";


export default connect(null, { ReduxUpdateMyScenarioJourney })(function MapList({ ReduxUpdateMyScenarioJourney }) {
  const navigate = useNavigate();
  const param = useParams();
  const [selectCategory, setSelectCategory] = useState("");
  const [mapList, setMapList] = useState([]);
  const [mapGid, setMapGid] = useState(null)
  const [isDisabled, setIsDisabled] = useState(true)

  const { Projects, MyScenario } = STORE.getState();
  const projectId = Projects.projectId;
  const [loading, setLoading] = useState(false);
  const [selectedOdd, setSelectedOdd] = useState("");
  const [editWarningPopupVisibility, setEditWarningPopupVisibility] = useState(false);
  const [ifreadonly, setIfreadonly] = useState(false);
  
  useEffect(() => {
    getMapList();
  }, []);

  const updateNextButtonState = (isChecked, oddId) => {
    setIsDisabled(!isChecked);
    setSelectedOdd(oddId);
  };

  const handleOnChange = async (e) => {
    const { value } = e.target;
    setSelectCategory(value);
    await fetchOddsAgainstMapGid(value)
  };

  const fetchOddsAgainstMapGid = async (id) => {
    setLoading(true);
    const map = mapList.find(item => item.id === id);
    if (!map) {
      setLoading(false);
      return;
    }
    const pralionId = map.pralion_id;
    const response = await getMapGidListByPralionId(pralionId);
    if (response && response.status === 200) {
      const { data } = response.data;
      setMapGid(data);
    } else {
      console.log("error");
    }
    setLoading(false);
  }

  const getMapList = async () => {
    setLoading(true);
    const response = await getMapListById(projectId);
    if (response.status === 200) {
      const { data } = response.data;
      const filteredApplications = data.applications.filter(application => application?.application_version_id !== null);
      setMapList(filteredApplications);
      if (MyScenario.readonly) {
        setIfreadonly(true);
        const prevState = await getScenarioByPralionId(MyScenario.pralionId)
        if (prevState && prevState.status === 200) {
          console.log('prevState', prevState);
          setSelectCategory(prevState.data.data.meta.map_app_id);
          ReduxUpdateMyScenarioJourney({ 
            oddData: { gid_kwh: prevState.data.data.meta.map_gid }, 
            prevUserScenario: prevState.data.data.user_scenario_json,
            usingFile: prevState.data.data.is_scenario_uploaded
          })
          const map = filteredApplications.find(item => item.id === prevState.data.data.meta.map_app_id);
          if (!map) {
            setLoading(false);
            return;
          }
          const pralionId = map.pralion_id;
          const response = await getMapGidListByPralionId(pralionId);
          console.log("getMapGIdList response", response)
          if (response && response.status === 200) {
            const { data } = response.data;
            console.log("getMapGIdList data", data)
            setMapGid(data);
          } else {
            console.log("error");
          }
          setLoading(false);
        }
      }
      else {
        if (MyScenario?.selectedMap) {
          setSelectCategory(MyScenario?.selectedMap)
          setLoading(true);
          const map = filteredApplications.find(item => item.id === MyScenario?.selectedMap);
          if (!map) {
            setLoading(false);
            return;
          }
          const pralionId = map.pralion_id;
          const response = await getMapGidListByPralionId(pralionId);
          console.log("getMapGIdList response", response)
          if (response && response.status === 200) {
            const { data } = response.data;
            console.log("getMapGIdList data", data)
            setMapGid(data);
          } else {
            console.log("error");
          }
          setLoading(false);
        }
      }
    } else {
      console.log("error");
    }
    setLoading(false);
  };

  const onClickNext = () => {
    if ((MyScenario.selectedMap && MyScenario.selectedMap !== selectCategory) || (MyScenario.oddData && MyScenario.oddData.gid_kwh !== selectedOdd)) {
      setEditWarningPopupVisibility(true);
      return;
    }
    else {
      ReduxUpdateMyScenarioJourney({ selectedMap: selectCategory, oddData: { gid_kwh: selectedOdd }, })
      navigate(AUTHENTICATED_ROUTES.interactiveMap.replace(":id", projectId).replace(":aid", param.aid).replace(":mid", selectedOdd));
    }
  };

  const onContinueWithEditClick = () => {
    ReduxUpdateMyScenarioJourney({ 
      selectedMap: selectCategory, 
      oddData: { gid_kwh: selectedOdd }, 
      routeConfiguration: null,
      markersOnMap: [],
      pedestrianRawData: [],
    })
    navigate(AUTHENTICATED_ROUTES.interactiveMap.replace(":id", projectId).replace(":aid", param.aid).replace(":mid", selectedOdd));
  }

  const onCancelEditsClick = () => {
    setSelectCategory(MyScenario.selectedMap);
    setSelectedOdd(MyScenario.oddData.gid_kwh);
    fetchOddsAgainstMapGid(MyScenario.selectedMap);
    setEditWarningPopupVisibility(false);
  }

  return (
    <Fragment>
      <div className={`container-fluid`}>
        <div className={`row`}>
          <div className={`col-lg-6 d-flex align-items-center`}>
            <TextField
              label="Select Map"
              name="selectCategory"
              type="select"
              size="small"
              variant="outlined"
              value={selectCategory}
              onChange={handleOnChange}
              sx={{ maxWidth: "23rem", marginRight: "1em" }}
              helperText={"The defined Map Catalogs can only be referred"}
              fullWidth
              disabled={ifreadonly}
              select
              SelectProps={{
                MenuProps: {
                  className: "customDropdown",
                },
              }}
            >
              {mapList.map((item, i) => {
                return (
                  <MenuItem key={i} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </TextField>
            <div className={`ms-2 mb-4`}>
              {
                MyScenario.readonly && <ReadOnlyTag />
              }
            </div>
          </div>
          <div className={`col-lg-6`}>
            <div className={`d-flex justify-content-end`}>

              <Button
                sx={{
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.main",
                  },
                  color: "text.main",
                  fontWeight: "bold",
                  marginRight: "10px",
                  textTransform: "none",
                  fontSize: "inherit",
                }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <ButtonX className={`ms-2`} onClick={onClickNext} disabled={isDisabled}>
                Next
              </ButtonX>
            </div>
          </div>
        </div>
      </div>
      <MapOdds loading={loading} mapIds={mapGid} updateNextButtonState={updateNextButtonState} />
      <ModalX heading={"Warning!"} subheading={"Your Progress will be lost."} open={editWarningPopupVisibility} close={() => setEditWarningPopupVisibility(false)} >
        <Typos.Body1>If you edit your selection, you will loose any saved progress made after this screen.</Typos.Body1>
        <hr />
        <div className={`d-flex`}>
          <ButtonX className={"me-2"} onClick={onCancelEditsClick}>
            Cancel
          </ButtonX>
          <ButtonX variant={"outlined"} onClick={onContinueWithEditClick}>
            Continue
          </ButtonX>
        </div>
      </ModalX>
    </ Fragment>
  );
})
