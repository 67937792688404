import { HIGH_FIDELITY_SET_VEHICLE, HIGH_FIDELITY_UPDATE_VEHICLE } from "..";

export const ReduxCreateHighFidelityJourney = (
  pid,
  pname,
  vehicleprop,
  vehiclesuits,
  dataStor,
  observerId,
  clientId,
  precipit,
  precipitRate,
  time,
) => {
  return {
    type: HIGH_FIDELITY_SET_VEHICLE,
    payload: {
      pralionId: pid, 
      projectName: pname, 
      vehicleProperties: vehicleprop,
      observerId: observerId,
      vehicleSuits: vehiclesuits,
      dataStorage: dataStor,
      desktopClientId: clientId,
      precipitation: precipit,
      precipitationRate: precipitRate,
      time: time,
    },
  };
};

export const ReduxUpdateHighFidelityJourney = (obj) => {
  return {
    type: HIGH_FIDELITY_UPDATE_VEHICLE,
    payload: obj,
  };
};
