import { SET_AUTOSCENARIO, UPDATE_AUTOSCENARIO } from ".."

export const ReduxCreateAutoScenarioJourney = (pId, pName, readonly=false) => {
    return {
        type: SET_AUTOSCENARIO,
        payload: { pralionId: pId, projectName: pName, readonly }
    }
}

export const ReduxUpdateAutoScenarioJourney = (obj) => {
    return {
        type: UPDATE_AUTOSCENARIO,
        payload: obj
    }
}