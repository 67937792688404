import { GET_PROJECT, UPDATE_PROJECT } from "..";

const INITIAL_STATE = null

export default function ReducerProjects(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_PROJECT:
            return action.payload;
        case UPDATE_PROJECT:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}