import {  GET_EXTRACTED_SCENARIO_DATA, GET_REAL_SIM_DATA, GET_SEN_SIM_DATA, GET_SYNTH_SIM_DATA} from "constants/endpoints"
import { httpGet } from "utils/httpMethods"

export const getRealSimData = async (body) => {
    return await httpGet({ url: `${GET_REAL_SIM_DATA}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getSenSimData = async (body) => {
    return await httpGet({ url: `${GET_SEN_SIM_DATA}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getSynthSimData = async (body) => {
    return await httpGet({ url: `${GET_SYNTH_SIM_DATA}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getExtractedScenario = async (body, pagination) => {
    const {pageNumber = 1, testsPerPage = 10 } = pagination || {}
    return await httpGet({ url:`${GET_EXTRACTED_SCENARIO_DATA}?page=${pageNumber}&page_size=${testsPerPage}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}
