import React, { useState, useEffect, useRef } from "react";
import paper, { Point, Path, Raster, Group } from "paper";
import Caricon from "../../../../../../assets/icons/car-top.png";
import Carmid from "../../../../../../assets/icons/car-mid.png";

export default function PaperCanvas({ selectedValue, vehSegmentNumber }) {
  const canvasRef = useRef(null);
  const [carIcons, setCarIcons] = useState([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    paper.setup(canvas);

    // Clear previous car icons and lines
    paper.project.clear();

    if (selectedValue === "1") {
      // Draw car icon in the center
      const centerCarIcon = new Raster({
        source: Carmid,
        position: new Point(250, 180),
      });
      centerCarIcon.scale(0.1); // Adjust the scale as needed
    } else if (selectedValue === "2") {
      // Draw car icon to the left of the main line
      const leftCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 - 80, 100),
      });
      leftCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw the main line
      const mainLine = new Path.Line({
        from: new Point(250, 10),
        to: new Point(250, 200),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Calculate the end point of the main line
      const mainLineEndPoint = mainLine.getPointAt(mainLine.length);

      // Draw car icon to the right of the main line
      const rightCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 + 80, 100),
      });
      rightCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw additional car icons based on vehSegmentNumber
      for (let i = 2; i <= vehSegmentNumber; i++) {
        const leftIcon = new Raster({
          source: Caricon,
          position: new Point(250 - 80 * i, 100),
        });
        leftIcon.scale(0.1);

        const rightIcon = new Raster({
          source: Caricon,
          position: new Point(250 + 80 * i, 100),
        });
        rightIcon.scale(0.1);
      }
    } else if (selectedValue === "3") {
      // Draw car icon to the left of the main line
      const leftCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 - 80, 100),
      });
      leftCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw the main line
      const mainLine = new Path.Line({
        from: new Point(250, 20),
        to: new Point(250, 180),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Calculate the end point of the main line
      const mainLineEndPoint = mainLine.getPointAt(mainLine.length);

      // Draw the left tilted line beneath the main line
      const leftTiltedLine = new Path.Line({
        from: mainLineEndPoint,
        to: new Point(mainLineEndPoint.x - 150, mainLineEndPoint.y + 100),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Draw the right tilted line beneath the main line
      const rightTiltedLine = new Path.Line({
        from: mainLineEndPoint,
        to: new Point(mainLineEndPoint.x + 150, mainLineEndPoint.y + 100),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Draw car icon to the right of the main line
      const rightCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 + 80, 100),
      });
      rightCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw car icon between the tilted lines
      const centerCarIcon = new Raster({
        source: Caricon,
        position: vehSegmentNumber !== "2"
        ? new Point(250, 100 + 200) // Keep the same position
        : new Point(250 + 45, 100 + 200),
      });
      centerCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw additional car icons based on vehSegmentNumber
      for (let i = 2; i <= vehSegmentNumber; i++) {
        const leftIcon = new Raster({
          source: Caricon,
          position: new Point(250 - 80 * i, 100),
        });
        leftIcon.scale(0.1);

        const rightIcon = new Raster({
          source: Caricon,
          position: new Point(250 + 80 * i, 100),
        });
        rightIcon.scale(0.1);

        const bottomIcon = new Raster({
          source: Caricon,
          position: new Point(250 - 25 * i, 100 + 200),
        });
        bottomIcon.scale(0.1);
      }
    } else if (selectedValue === "4") {
      // Draw car icon to the left of the main line
      const leftCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 - 80, 100),
      });
      leftCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw the main line
      const mainLine = new Path.Line({
        from: new Point(250, 20),
        to: new Point(250, 180),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Calculate the end point of the main line
      const mainLineEndPoint = mainLine.getPointAt(mainLine.length);

      // Draw the left tilted line beneath the main line
      const leftTiltedLine = new Path.Line({
        from: mainLineEndPoint,
        to: new Point(mainLineEndPoint.x - 200, mainLineEndPoint.y - 1),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Draw the right tilted line beneath the main line
      const rightTiltedLine = new Path.Line({
        from: mainLineEndPoint,
        to: new Point(mainLineEndPoint.x + 200, mainLineEndPoint.y + 1),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Draw car icon to the right of the main line
      const rightCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 + 80, 100),
      });
      rightCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw the main line
      const mainBottomLine = new Path.Line({
        from: new Point(250, 20),
        to: new Point(250, 180),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Calculate the end point of the main line
      const mainBottomLineEndPoint = mainLine.getPointAt(mainBottomLine.length);

      // Draw the left tilted line beneath the main line
      const leftTiltedBottomLine = new Path.Line({
        from: mainLineEndPoint,
        to: new Point(mainLineEndPoint.x, mainLineEndPoint.y + 170),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Draw car icon to the right bottom of the main line
      const rightBotomCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 + 80, 100 + 180),
      });
      rightBotomCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw car icon to the left bottom of the main line
      const leftBotomCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 - 80, 100 + 180),
      });
      leftBotomCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw additional car icons based on vehSegmentNumber
      for (let i = 2; i <= vehSegmentNumber; i++) {
        const leftIcon = new Raster({
          source: Caricon,
          position: new Point(250 - 80 * i, 100),
        });
        leftIcon.scale(0.1);

        const rightIcon = new Raster({
          source: Caricon,
          position: new Point(250 + 80 * i, 100),
        });
        rightIcon.scale(0.1);

        const bottomLeftIcon = new Raster({
          source: Caricon,
          position: new Point(250 - 80 * i, 100 + 180),
        });
        bottomLeftIcon.scale(0.1);

        const bottomRightIcon = new Raster({
          source: Caricon,
          position: new Point(250 + 80 * i, 100 + 180),
        });
        bottomRightIcon.scale(0.1);
      }
    } else if (selectedValue === "5") {
      // Draw car icon to the left of the main line
      const leftCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 - 80, 100),
      });
      leftCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw the main line
      const mainLine = new Path.Line({
        from: new Point(250, 20),
        to: new Point(250, 180),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Calculate the end point of the main line
      const mainLineEndPoint = mainLine.getPointAt(mainLine.length);

      // Draw the left tilted line beneath the main line
      const leftTiltedLine = new Path.Line({
        from: mainLineEndPoint,
        to: new Point(mainLineEndPoint.x - 100, mainLineEndPoint.y + 150),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Draw the right tilted line beneath the main line
      const rightTiltedLine = new Path.Line({
        from: mainLineEndPoint,
        to: new Point(mainLineEndPoint.x + 100, mainLineEndPoint.y + 150),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Draw the left tilted line beneath the main line
      const leftTopTiltedLine = new Path.Line({
        from: mainLineEndPoint,
        to: new Point(mainLineEndPoint.x - 160, mainLineEndPoint.y - 40),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Draw the right tilted line beneath the main line
      const rightTopTiltedLine = new Path.Line({
        from: mainLineEndPoint,
        to: new Point(mainLineEndPoint.x + 160, mainLineEndPoint.y - 40),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Draw car icon to the right of the main line
      const rightMiddleCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 + 100, 200 + 20),
      });
      rightMiddleCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw car icon between the tilted lines
      const leftMiddleCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 - 100, 100 + 120),
      });
      leftMiddleCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw car icon to the right of the main line
      const rightCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 + 80, 100),
      });
      rightCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw car icon between the tilted lines
      const centerCarIcon = new Raster({
        source: Caricon,
        position: vehSegmentNumber !== "2"
        ? new Point(250, 100 + 200) // Keep the same position
        : new Point(250 + 41, 100 + 220),
      });
      centerCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw additional car icons based on vehSegmentNumber
      for (let i = 2; i <= vehSegmentNumber; i++) {
        const topLeftIcon = new Raster({
          source: Caricon,
          position: new Point(250 - 75 * i, 100 - 20),
        });
        topLeftIcon.scale(0.1);

        const topRightIcon = new Raster({
          source: Caricon,
          position: new Point(250 + 75 * i, 100 - 20),
        });
        topRightIcon.scale(0.1);

        const centerLeftIcon = new Raster({
          source: Caricon,
          position: new Point(250 - 85 * i, 100 + 120),
        });
        centerLeftIcon.scale(0.1);

        const centerRightIcon = new Raster({
          source: Caricon,
          position: new Point(250 + 85 * i, 100 + 120),
        });
        centerRightIcon.scale(0.1);

        const bottomLeftIcon = new Raster({
          source: Caricon,
          position: new Point(250 - 18 * i, 100 + 220),
        });
        bottomLeftIcon.scale(0.1);
      }
    } else if (selectedValue === "6") {
      // Draw car icon to the left of the main line
      const leftCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 - 60, 80),
      });
      leftCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw car icon to the right of the main line
      const rightCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 + 60, 80),
      });
      rightCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw the main line
      const mainLine = new Path.Line({
        from: new Point(250, 20),
        to: new Point(250, 180),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Calculate the end point of the main line
      const mainLineEndPoint = mainLine.getPointAt(mainLine.length);

      // Draw the left tilted line beneath the main line
      const leftTiltedLine = new Path.Line({
        from: mainLineEndPoint,
        to: new Point(mainLineEndPoint.x - 150, mainLineEndPoint.y + 100),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Draw the right tilted line beneath the main line
      const rightTiltedLine = new Path.Line({
        from: mainLineEndPoint,
        to: new Point(mainLineEndPoint.x + 150, mainLineEndPoint.y + 100),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Draw the left tilted line beneath the main line
      const leftTopTiltedLine = new Path.Line({
        from: mainLineEndPoint,
        to: new Point(mainLineEndPoint.x - 160, mainLineEndPoint.y - 70),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Draw the right tilted line beneath the main line
      const rightTopTiltedLine = new Path.Line({
        from: mainLineEndPoint,
        to: new Point(mainLineEndPoint.x + 160, mainLineEndPoint.y - 70),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Draw the main line
      const mainBottomLine = new Path.Line({
        from: new Point(250, 20),
        to: new Point(250, 180),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Calculate the end point of the main line
      const mainBottomLineEndPoint = mainLine.getPointAt(mainBottomLine.length);

      // Draw the left tilted line beneath the main line
      const leftTiltedBottomLine = new Path.Line({
        from: mainLineEndPoint,
        to: new Point(mainLineEndPoint.x, mainLineEndPoint.y + 170),
        strokeColor: "white",
        dashArray: [5, 5], // Make the line dotted
      });

      // Draw car icon to the right bottom of the main line
      const rightBotomCarIcon = new Raster({
        source: Caricon,
        position: vehSegmentNumber !== "2"
          ?
          new Point(250 + 60, 100 + 200)
          :
          new Point(250 + 40, 100 + 220),
      });
      rightBotomCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw car icon to the left bottom of the main line
      const leftBotomCarIcon = new Raster({
        source: Caricon,
        position: vehSegmentNumber !== "2" 
          ?
          new Point(250 - 60, 100 + 200)
          :
          new Point(250 - 40, 100 + 220),
      });
      leftBotomCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw car icon to the right bottom of the main line
      const rightCenterCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 + 110, 100 + 90),
      });
      rightCenterCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw car icon to the left bottom of the main line
      const leftCenterCarIcon = new Raster({
        source: Caricon,
        position: new Point(250 - 110, 100 + 90),
      });
      leftCenterCarIcon.scale(0.1); // Adjust the scale as needed

      // Draw additional car icons based on vehSegmentNumber
      for (let i = 2; i <= vehSegmentNumber; i++) {
        const topLeftIcon = new Raster({
          source: Caricon,
          position: new Point(250 - 65 * i, 100 - 40),
        });
        topLeftIcon.scale(0.1);

        const topRightIcon = new Raster({
          source: Caricon,
          position: new Point(250 + 65 * i, 100 - 40),
        });
        topRightIcon.scale(0.1);

        const centerLeftIcon = new Raster({
          source: Caricon,
          position: new Point(250 - 90 * i, 100 + 90),
        });
        centerLeftIcon.scale(0.1);

        const centerRightIcon = new Raster({
          source: Caricon,
          position: new Point(250 + 90 * i, 100 + 90),
        });
        centerRightIcon.scale(0.1);

        const bottomLeftIcon = new Raster({
          source: Caricon,
          position: new Point(250 - 55 * i, 100 + 220),
        });
        bottomLeftIcon.scale(0.1);

        const bottomRightIcon = new Raster({
          source: Caricon,
          position: new Point(250 + 55 * i, 100 + 220),
        });
        bottomRightIcon.scale(0.1);
      }
    }

    // Draw car icon using Raster for images
    const iconsGroup = new Group();
    let carIcon;
    if (selectedValue === "2") {
      // Different icon for selectedValue 2
      carIcon = new Raster({
        source: Carmid, 
        position: new Point(250, 100),
      });
    } else {
      carIcon = new Raster({
        source: Carmid,
        position: new Point(250, 180),
      });
    }
    carIcon.scale(0.2); // Adjust the scale as needed
    iconsGroup.addChild(carIcon);

    setCarIcons([iconsGroup]);
  }, [selectedValue, vehSegmentNumber]);

  return <canvas ref={canvasRef} width={500} height={400} />;
}