import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Box, Grid, Radio, Button, Menu, MenuItem } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getAutoScenarioProjectsById } from "endpointServices/autoScenarioServices";
import ExtractAutoScenarioTable from "./extractAutoScenarioComponent/extractAutoScenarioTable";
import AutoScenarioStatus from "../../../autoScenarios/autoScenariosComponent/autoScenarioStatus";
import dateConverter from "utils/dateConverter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { wrapText, checkDagStatus } from "utils";
import { ModalX } from "components";
import { pageSize } from "utils/staticsData";
import { ReduxCreateScenExtractJourney } from "services/redux-store/actions/actions-scenextract";
import { connect } from "react-redux";

const ExtractAutoScenario = ({ ReduxCreateScenExtractJourney }) => {
  const param = useParams();
  let navigator = useNavigate();

  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [statusOpen, setStatusOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [trafficVizUrl, setTrafficVizUrl] = useState([]);
  const [statusData, setStatusData] = useState({
    name: "",
    pralionId: "",
  });

  const handleStatusOpenModal = (event, data) => {
    setAnchorEl(event.target);
    setStatusData({
      name: data.name,
      pralionId: data.pralion_id,
    });
    setTrafficVizUrl(data.versions[0]);
  };

  const handleMenuItem = (action) => {
    setSelectedAction(action);
    setAnchorEl(null);
    if (action === "Status") {
      setStatusOpen(true);
    } else if (action === "TrafficViz") {
      if (trafficVizUrl && trafficVizUrl.visualizer_url) {
        window.open(trafficVizUrl.visualizer_url, "_blank");
      } else {
        toast.info("Visualizer URL not available");
      }
    }
  };

  const handleClose = () => setStatusOpen(false);

  useEffect(() => {
    getProjectList({ pageNumber: page, testsPerPage: pageSize });
    localStorage.removeItem("p_id");
    localStorage.removeItem("pralion_id");
  }, [page]);

  const getProjectList = async (pagination) => {
    setFetching(true);
    const projectId = parseInt(param.id);
    const response = await getAutoScenarioProjectsById(projectId, pagination);
    console.log("response", response);
    if (response?.status === 200) {
      setFetching(false);
      const data = response?.data?.data?.applications || [];
      // const listFilter = data.filter(ele => ele.no_of_scenarios !== 0)
      setList(data);
      setTotalRows(response?.data?.data?.no_of_applications);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setList([]);
      setFetching(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const goto = (data, versions) => {
    console.log("versions", versions);
    console.log("data", data);
    localStorage.setItem("pralion_id", data.pralion_id);
    navigator(
      {
        pathname: `/projects/${param.id}/applications/scen-extract/extract-auto-scenario`,
      },
      { state: { versions } }
    );
    ReduxCreateScenExtractJourney(
      "autoScenario",
      data.pralion_id,
      data.name,
      data.application_version_id
    );
  };

  const columns = useMemo(() => [
    {
      field: "name",
      renderHeader: () => (
        <span className="heading-2">
        {'Scenario ID '}
        </span>
      ),
      width: 550,
      renderCell: (params) => {
        const isRowDisabled = params.row?.dag_state?.dag_state
        return (
          <Grid>
            <Button
              className="Main-link-simple"
              onClick={() => goto(params.row, params.row.versions)}
              sx={{ color: "white" }}
              disabled={checkDagStatus(isRowDisabled)}
            >
              {wrapText(params.value)}
            </Button>
          </Grid>
        );
      },
    },
    {
      field: "date_created",
      renderHeader: () => (
        <span className="heading-2">
        {'Created At'}
        </span>
      ),
      width: 183,
      renderCell: (params) => <span className="row-heading">{dateConverter(params.value)}</span>,
    },
    {
      field: "action",
      renderHeader: () => (
        <span className="heading-2">
        {'Status'}
        </span>
      ),
      width: 235,
      renderCell: (params) => {
        const status = params.row?.dag_state?.dag_state;
        return (
          <>
            <div className="statusRadioButton">
            <Radio
                color="success"
                checked={status === "success"}
                sx={{
                  transform: "scale(0.6)", width: "1px", height: "1px",
                  "& > input": {
                      backgroundColor: "#4caf50",
                      color: "#4caf50",
                      "&:checked + span": {
                        backgroundColor: "#4caf50",
                        color: "#4caf50",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />

                <Radio
                  checked={status === "running"}
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "#ff9800",
                      color: "#ff9800",
                      "&:checked + span": {
                        backgroundColor: "#ff9800",
                        color: "#ff9800",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
                <Radio
                  checked={status === "failed"}
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "red",
                      color: "red",
                      "&:checked + span": {
                        backgroundColor: "red",
                        color: "red",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />

                <Radio
                  checked={
                    status === "pending" || !params.row.dag_state.dag_state
                  }
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "gray",
                      color: "red",
                      "&:checked + span": {
                        backgroundColor: "gray",
                        color: "gray",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
              </div>

              {/* <Button
              variant="contained"
              sx={{
                backgroundColor: "secondary.dark",
                textTransform: "none",
                borderRadius: "8px",
              }}
              disabled
            >
              Stop
            </Button> */}
              <Button
                sx={{ color: "text.main" }}
                onClick={(event) => handleStatusOpenModal(event, params.row)}
              >
                <MoreVertIcon />
              </Button>
            </>
          );
        },
      },
    ],
    [list, page]
  );

  return (
    <Fragment>
      <ExtractAutoScenarioTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />

      <Menu
        anchorEl={anchorEl}
        open={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            backgroundColor: "#ffffff",
            color: "black",
          },
        }}
      >
        <MenuItem onClick={() => handleMenuItem("Status")}>Status</MenuItem>
        <MenuItem onClick={() => handleMenuItem("TrafficViz")}>
          Traffic Viz
        </MenuItem>
      </Menu>

      <ModalX
        open={statusOpen}
        close={() => setStatusOpen(false)}
        heading={"State Management"}
        subheading={`${statusData.name}`}
      >
        <AutoScenarioStatus
          statusObject={statusData}
          handleClose={handleClose}
        />
      </ModalX>
    </Fragment>
  );
};

export default connect(null, { ReduxCreateScenExtractJourney })(
  ExtractAutoScenario
);
