import React, { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Chip,
  CircularProgress,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { LogoutRounded as LogoutIcon } from "@mui/icons-material";
import PersonIcon from '@mui/icons-material/Person';
import { Link, useNavigate, useParams } from "react-router-dom";
import GlobalModals from "./globalModals";
import { LOGOUT_SERVICE } from 'endpointServices/authServices'
// import STORE from 'services/redux-store/store';
import {
  LEFTSIDEBAR_ELEMENTS,
} from "./leftSideBarList";
import LogoImage from "assets/images/main-logo.png";
import BreadCrumb from "components/breadCrumb";
import SearchBar from "components/searchBar";
import { GetInfoFromLocalStorage } from "utils";
import { toast, ToastContainer } from 'react-toastify';
import STORE from "services/redux-store/store";
import 'react-toastify/dist/ReactToastify.css';
import InfoIcon from '@mui/icons-material/Info';

const drawerWidth = 240;

function Loader() {
  return (
    <div className={`d-flex justify-content-center mt-3`}>
      <CircularProgress />
    </div>
  );
}

function ContainerX(props) {
  const userInfo = GetInfoFromLocalStorage('aai_user')
  const url = window.location.pathname
  const param = useParams()
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [loading, set_loading] = useState(false);
  const [menuList, setMenuList] = useState([])

  const { Projects } = STORE.getState()
  const versionText = url === "/projects"

  /**
   * Scenario Menu
   */
  const scenariosMenu = [
    {
      title: "Map",
      nested: false,
      // icon: <HomeIcon fontSize="small" />,
      url: `/projects/${param.id}/applications/my-scenarios/map`,
      active: true,
      onclick: false,
    },
    {
      title: "Show Routes & ODDs ",
      nested: false,
      // icon: <HomeIcon fontSize="small" />,
      // url: AUTHENTICATED_ROUTES.routesOdds,
      active: true,
      onclick: false,
    },
    {
      title: "Define Observer(s)",
      nested: false,
      // icon: <HomeIcon fontSize="small" />,
      url: `/projects/${param.id}/applications/my-scenarios/define-observer`,
      active: true,
      onclick: false,
    },
    // {
    //   title: "Define Rule(s)",
    //   nested: false,
    //   // icon: <HomeIcon fontSize="small" />,
    //   url: `/projects/${param.id}/applications/my-scenarios/define-rule`,
    //   active: true,
    //   onclick: false,
    // },
    {
      title: "Generation",
      nested: false,
      // icon: <HomeIcon fontSize="small" />,
      url: `/projects/${param.id}/applications/my-scenarios/scenario-executation`,
      active: true,
      onclick: false,
    },
  ]


  /**
   * Odds Menu
   */
  const oddsMenu = [
    {
      title: "ODD Zone",
      nested: false,
      url: `/projects/${param.id}/applications/odd/odd-zones`,
      active: true,
      onclick: false,
    },
    {
      title: "ODD Definition",
      nested: false,
      url: `/projects/${param.id}/applications/odd/odd-definition`,
      active: true,
      onclick: false,
    }
  ]

  /**
 * Test Vehicle Menu
 */
  const testVehicleMenu = [
    {
      title: "ReplicaR SIM",
      nested: false,
      url: `/projects/${param.id}/applications/test-vehicles/replica-sim`,
      active: true,
      onclick: false,
    },
    // {
    //   title: "Co-Simulation",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/test-vehicles/co-simulation`,
    //   active: true,
    //   onclick: true,
    // },
    // {
    //   title: "Co-SIM - dSPACE",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/test-vehicles/cosim-dspace`,
    //   active: true,
    // },
    // {
    //   title: "Co-SIM - CarMaker",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/test-vehicles/cosim-car-maker`,
    //   active: true,
    // },
    // {
    //   title: "Others",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/test-vehicles/others`,
    //   active: true,
    // },
  ]

  /**
  * AutoScenario Menu
  */
  const autoScenarioMenu = [
    {
      title: "Map",
      nested: false,
      url: `/projects/${param.id}/applications/auto-scenarios/as-map`,
      active: true,
      onclick: false,
    },
    {
      title: "Show Routes & ODDs ",
      nested: false,
      url: `/projects/${param.id}/applications/auto-scenarios/routes-odds`,
      active: true,
      onclick: false,
    },
    {
      title: "Place Test Vehicles",
      nested: false,
      url: `/projects/${param.id}/applications/auto-scenarios/place-test-vehicles`,
      active: true,
      onclick: false,
    },
    {
      title: "Place Dynamic Actor",
      nested: false,
      url: `/projects/${param.id}/applications/auto-scenarios/place-dynamics-actor`,
      active: true,
      onclick: false,
    },
    // {
    //   title: "Place Static Actor",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/auto-scenarios/place-static-actor`,
    //   active: true,
    // },
    {
      title: "Define Observer(s)",
      nested: false,
      url: `/projects/${param.id}/applications/auto-scenarios/define-observer`,
      active: true,
      onclick: false,
    },
    // {
    //   title: "Define Rule(s)",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/auto-scenarios/define-rule`,
    //   active: true,
    //   onclick: false,
    // },
    {
      title: "Generation",
      nested: false,
      url: `/projects/${param.id}/applications/auto-scenarios/execute/execute-setting`,
      active: true,
      onclick: false,
    },
  ];

  /**
   * Maps Menu
   */
  const mapsMenu = [
    {
      title: "Search Maps",
      nested: false,
      url: `/projects/${param.id}/applications/maps/map`,
      active: true,
      onclick: false,
    },
    // {
    //   title: "Route Based Map",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/maps/route-based-map`,
    //   active: true,
    // },
    // {
    //   title: "Area Based Map",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/maps/area-based-map`,
    //   active: true,
    // },
  ]

  /**
 * ScenAnalytics Menu
 */
  const scenAnalyticsMenu = [
    {
      title: "Map",
      nested: false,
      url: `/projects/${param.id}/applications/scen-alytics/map`,
      active: false,
      onclick: true,
    },
    {
      title: "MyScenario",
      nested: false,
      url: `/projects/${param.id}/applications/scen-alytics/alytics-my-scenario`,
      active: true,
      onclick: true,
    },
    {
      title: "AutoScenario",
      nested: false,
      url: `/projects/${param.id}/applications/scen-alytics/alytics-auto-scenario`,
      active: false,
      onclick: true,
    },
    {
      title: "Traceability Matrix",
      nested: false,
      url: `/projects/${param.id}/applications/scen-alytics/traceability-matrix`,
      active: true,
      onclick: true,
    },
    // {
    //   title: "Scenario_User Defined",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/scen-analytics/scenario-user-defined`,
    //   active: true,
    // },
    // {
    //   title: "Scen-Intelligent Param",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/scen-analytics/scen-intelligent-param`,
    //   active: true,
    // },
    // {
    //   title: "Traffic",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/scen-analytics/traffic`,
    //   active: true,
    // },
  ]

  /**
 * SynthData Menu
 */
  const synthDataMenu = [
    {
      title: "Enviroments",
      nested: false,
      url: `/projects/${param.id}/applications/synth-data/environments`,
      active: true,
      onclick: true,
    },
  ]


  /**
 * Real2Sim Menu
 */
  const realSimMenu = [
    {
      title: "Enviroments",
      nested: false,
      url: `/projects/${param.id}/applications/real-sim/real-sim-environments`,
      active: true,
      onclick: true,
    },
  ]


  /**
 * SenVironment Menu
 */
  const senVironmentMenu = [
    {
      title: "Enviroments",
      nested: false,
      url: `/projects/${param.id}/applications/sen-vironment/sen-environment`,
      active: true,
      onclick: true,
    },
  ]

  /**
* Map Validator Menu
*/
  const mapValidatorMenu = [
    {
      title: "Map",
      nested: false,
      url: `/projects/${param.id}/applications/map-validator`,
      active: true,
      onclick: true,
    },
  ]

  /**
   * ScenExtract Menu
   */
  const scenExtractMenu = [
    {
      title: "Scenarios",
      nested: false,
      url: `/projects/${param.id}/applications/scen-extract`,
      active: true,
      onclick: false,
    },
  ]

  /**
   * EducAgents Menu
   */
  const educAgentsMenu = [
    // {
    //   title: "Map",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/educ-agents/educ-map`,
    //   active: true,
    // },
    {
      title: "Environment Setup",
      nested: false,
      url: `/projects/${param.id}/applications/educ-agents/environment-setup`,
      active: true,
      onclick: false,
    },
    // {
    //   title: "Show Routes & ODDs",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/educ-agents/educ-route-odds`,
    //   active: true,
    //   onclick: true,
    // },
    {
      title: "Traffic Configuration",
      nested: false,
      url: `/projects/${param.id}/applications/educ-agents/traffic-configuration`,
      active: true,
      onclick: false,
    },
    // {
    //   title: "Place Dynamic Actor",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/educ-agents/educ-dynamic-actor`,
    //   active: true,
    //   onclick: false,
    // },
    // {
    //   title: "Place Static Actor",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/educ-agents/educ-static-actor`,
    //   active: true,
    // },
    // {
    //   title: "Agents",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/educ-agents/agents`,
    //   active: true,
    // },
    // {
    //   title: "Modal Input",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/educ-agents/modal-input-ego`,
    //   active: true,
    //   onclick: false,
    // },
    {
      title: "Model Input",
      nested: false,
      url: `/projects/${param.id}/applications/educ-agents/modal-input-agents`,
      active: true,
      onclick: false,
    },
    {
      title: "Attribute Selection",
      nested: false,
      url: `/projects/${param.id}/applications/educ-agents/attribute-selection`,
      active: true,
      onclick: false,
    },
    {
      title: "Define Rewards",
      nested: false,
      url: `/projects/${param.id}/applications/educ-agents/educ-define-rewards`,
      active: true,
      onclick: false,
    },
    {
      title: "Define Test Cases",
      nested: false,
      url: `/projects/${param.id}/applications/educ-agents/educ-define-test`,
      active: true,
      onclick: false,
    },
    {
      title: "Hyper Parameter",
      nested: false,
      url: `/projects/${param.id}/applications/educ-agents/hyper-parameter`,
      active: true,
      onclick: false,
    },
    {
      title: "Execution",
      nested: false,
      url: `/projects/${param.id}/applications/educ-agents/educ-execution`,
      active: true,
      onclick: false,
    },
  ]


  /**
* Low Fidelity Menu
*/
  const lowFidelityMenu = [
    {
      title: "Vehicle Selection / SUT",
      nested: false,
      url: `/projects/${param.id}/applications/low-fidelity/vehicle-select`,
      active: true,
      onclick: false,
    },
    {
      title: "Test Suites",
      nested: false,
      url: `/projects/${param.id}/applications/low-fidelity/test-suits`,
      active: true,
      onclick: false,
    },
    // {
    //   title: "My Scenario",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/low-fidelity/my-scenario`,
    //   active: true,
    //   onclick: false,
    // },
    // {
    //   title: "Auto Scenario",
    //   nested: false,
    //   url: `/projects/${param.id}/applications/low-fidelity/auto-scenario`,
    //   active: true,
    //   onclick: false,
    // },
    {
      title: "Define Observer",
      nested: false,
      url: `/projects/${param.id}/applications/low-fidelity/observer`,
      active: true,
      onclick: false,
    },
    {
      title: "Define Rule",
      nested: false,
      url: `/projects/${param.id}/applications/low-fidelity/rule`,
      active: true,
      onclick: false,
    },
    {
      title: "Execute",
      nested: false,
      url: `/projects/${param.id}/applications/low-fidelity/execute`,
      active: true,
      onclick: false,
    },
    {
      title: "Scenarios",
      nested: false,
      url: `/projects/${param.id}/applications/low-fidelity/scenarios`,
      active: true,
      onclick: false,
    },
  ]

  /**
* Test Suits Menu
*/
  const testSuitsMenu = [
    {
      title: "Scenarios Selection",
      nested: false,
      url: `/projects/${param.id}/applications/test-suits/testing`,
      active: true,
      onclick: false,
    },
    {
      title: "Scenarios Save",
      nested: false,
      url: `/projects/${param.id}/applications/test-suits/test-suits-execute`,
      active: true,
      onclick: false,
    },
  ]

  /**
   * Carla Menu
   */
  const carlaMenu = [
    {
      title: "Scenarios",
      nested: false,
      url: `/projects/${param.id}/applications/carla`,
      active: true,
      onclick: false,
    },
    {
      title: "Execute",
      nested: false,
      url: `/projects/${param.id}/applications/carla/carla-execute`,
      active: true,
      onclick: false,
    },
  ]

  /**
* High Fidelity Menu
*/
  const highFidelityMenu = [
    {
      title: "Vehicle Selection / SUT",
      nested: false,
      url: `/projects/${param.id}/applications/high-fidelity/vehicle-selection`,
      active: true,
      onclick: false,
    },
    {
      title: "Test Suites",
      nested: false,
      url: `/projects/${param.id}/applications/high-fidelity/testsuits`,
      active: true,
      onclick: false,
    },
    {
      title: "Define Observer",
      nested: false,
      url: `/projects/${param.id}/applications/high-fidelity/define-observer`,
      active: true,
      onclick: false,
    },
    {
      title: "Define Rules",
      nested: false,
      url: `/projects/${param.id}/applications/high-fidelity/define-rule`,
      active: true,
      onclick: false,
    },
    {
      title: "Execution Setting",
      nested: false,
      url: `/projects/${param.id}/applications/high-fidelity/execution-setting`,
      active: true,
      onclick: false,
    },
    {
      title: "Execution Scenarios",
      nested: false,
      url: `/projects/${param.id}/applications/high-fidelity/execution-scenarios`,
      active: true,
      onclick: false,
    },
  ]

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const navigator = useNavigate();

  useEffect(() => {
    set_loading(false);
  }, []);

  useEffect(() => {
    if (url.includes("/maps")) {
      setMenuList(mapsMenu);
    } else if (url.includes(`/applications/my-scenarios`)) {
      setMenuList(scenariosMenu)
    } else if (url.includes("/applications/test-vehicles")) {
      setMenuList(testVehicleMenu);
    } else if (url.includes("/applications/auto-scenarios")) {
      setMenuList(autoScenarioMenu);
    } else if (url.includes("scen-alytics")) {
      setMenuList(scenAnalyticsMenu);
    } else if (url.includes("synth-data")) {
      setMenuList(synthDataMenu);
    } else if (url.includes("real-sim")) {
      setMenuList(realSimMenu);
    } else if (url.includes("sen-vironment")) {
      setMenuList(senVironmentMenu);
    } else if (url.includes("scen-extract")) {
      setMenuList(scenExtractMenu);
    } else if (url.includes("educ-agents")) {
      setMenuList(educAgentsMenu);
    } else if (url.includes("map-validator")) {
      setMenuList(mapValidatorMenu);
    } else if (url.includes("low-fidelity")) {
      setMenuList(lowFidelityMenu);
    } else if (url.includes("test-suits")) {
      setMenuList(testSuitsMenu)
    } else if (url.includes("carla")) {
      setMenuList(carlaMenu);
    } else if (url.includes("high-fidelity")) {
      setMenuList(highFidelityMenu)
    } else if (url.includes("odd")) {
      setMenuList(oddsMenu)
    }
    else {
      setMenuList(LEFTSIDEBAR_ELEMENTS);
    }
  }, [url]);

  const onLogOut = async () => {
    await LOGOUT_SERVICE()
  }

  const userDocument = () => {
    window.open('/replicaDocument/html/index.shtml')
  }

  const handleOnClickCheck = (value) => {
    if (value.onclick === true) {
      navigator(value.url)
    }
    if (value.title === 'API Docs') {
      window.open('/replicaDocument/html/index.shtml')
    }
  }

  const renderSideElements = () => {
    return menuList
      // .filter((route) => props.pages.includes(route.url))
      .map((elem, i) => {

        if (elem.nested) {
          return (
            <div key={elem.title}>
              <ListItemButton
                selected={window.location.pathname === elem.url}
                disabled={!elem.active}
                onClick={() => {
                  LEFTSIDEBAR_ELEMENTS[i].open = !LEFTSIDEBAR_ELEMENTS[i].open;
                  navigator(elem.url);
                }}
              >
                <ListItemText
                  primary={elem.title}
                  primaryTypographyProps={{ variant: "caption" }}
                />
              </ListItemButton>
              {elem.routes
                .filter((nroute) => props.pages.includes(nroute.url))
                .map((nelem, ni) => {
                  return (
                    <ListItemButton
                      selected={window.location.pathname === nelem.url}
                      key={`${nelem.title}-${ni}`}
                      onClick={() => navigator(nelem.url)}
                    >
                      <ListItemText
                        primary={nelem.title}
                        primaryTypographyProps={{ variant: "caption" }}
                      />
                    </ListItemButton>
                  );
                })}
              <Divider />
            </div>
          );
        } else {
          return (
            <div
              key={elem.title}
              className={`Main-Menu-item-Border mb-2`}
              style={{
                backgroundColor: !elem.active ? "" : "rgba(32, 60, 104, 0.8)",
              }}
            >
              <ListItemButton
                selected={window.location.pathname === elem.url}
                disabled={!elem.active}
                onClick={() => handleOnClickCheck(elem)}
              >
                <ListItemText
                  primary={<Typography className="heading-2">
                    {elem.title}
                  </Typography>}
                  primaryTypographyProps={{
                    variant: "caption",
                    color: "text.main",
                  }}
                />
              </ListItemButton>
              {/* <Divider /> */}
            </div>
          );
        }
      });
  };

  const gotoApplication = () => {
    const projectId = Projects?.projectId
    console.log('projectId', projectId)
    console.log('url', url)
    if (!projectId) {
      toast.info(`Cannot redirect to application page project Id not available`)
      setTimeout(() => {
      }, 2000)
    }
    else if (url === '/projects') {
      console.log('Redirection not available for this page')
      return false
    }
    else {
      navigator(`/projects/${projectId}/applications`)
    }

  }

  const drawer = (
    <div>
      <List className="p-0">
        <ListItemButton
          className="Main-Menu-Head mb-3"
          sx={{
            backgroundColor: "secondary.main",
            "&:hover": {
              backgroundColor: "secondary.main",
            },
          }}
          onClick={() => gotoApplication()}
        >
          {/* <ListItemIcon>
            <ListIcon style={{ color: "text.main" }} />
          </ListItemIcon> */}
          <ListItemText
            sx={{ color: "text.main" }}
            primary={
              <Typography variant="caption" className="heading-1">
                Applications
              </Typography>
            }
          />
        </ListItemButton>
        {renderSideElements()}
      </List>
    </div>
  );

  const container =
    props.window !== undefined ? () => props.window().document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        // height: "128vh",
        // backgroundImage: `url(${BgImage})`,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        // backgroundPosition: "center",
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={0}
        className="Header-border-bottom"
        sx={{
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar
          className={`d-flex justify-content-between`}
          sx={{ backgroundColor: "primary.dark" }}
        >
          <div className={`d-flex`}>
            <Link to={'/projects'}>
              <img src={LogoImage} className="header-logo" alt="logo" />
            </Link>
            <div className="mt-2">
              <Chip label={`v.5.5.0`} />
            </div>
          </div>
          <div className={``}>
            <IconButton>
              <PersonIcon fontSize="small" />
              <Typography
                variant="caption"
                display="block"
                className="heading-2 mt-1"
                ml={1}
                mr={2}
                mb={0}
                gutterBottom
              >
                {userInfo?.first_name}
              </Typography>
            </IconButton>
            {/* <IconButton onClick={() => userDocument()}>
              <InfoIcon fontSize="small" />
            </IconButton> */}
            <IconButton
              onClick={() => console.log("logout")}
              sx={{ color: "text.main" }}
            >
              {" "}
              {/* <Notifications fontSize="small" /> */}
            </IconButton>
            <IconButton onClick={() => onLogOut()}> <LogoutIcon fontSize="small" /></IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              top: "67px",
              borderRight: "2px solid #D9D9D9",
            },
          }}
        >
          {loading ? <Loader /> : drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              backgroundColor: "transparent",
              boxSizing: "border-box",
              width: drawerWidth,
              top: "67px",
              borderRight: "2px solid #D9D9D9",
            },
          }}
          open
        >
          {loading ? <Loader /> : drawer}

        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          marginTop: "3em",
        }}
      >
        <BreadCrumb />
        <Divider
          className="mb-3"
          sx={{ borderBottom: "1px solid #D9D9D9", opacity: "inherit" }}
        />
        {loading ? <Loader /> : props.children}
        <GlobalModals />
      </Box>
    </Box>
  );
}

export default ContainerX;
