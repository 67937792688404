import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Grid, Button } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { wrapText } from "utils";
import { pageSize } from "utils/staticsData";
import MainTable from "components/mainTable";
import { getLowFidelityList } from "endpointServices/lowFidelityServices";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

export default function ExecutedTable({ summaryData }) {
  console.log("summaryData", summaryData);

  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getProjectList({ pageNumber: page, testsPerPage: pageSize });
  }, [page]);

  const getProjectList = async (pagination) => {
    setFetching(true);
    const pralionId = parseInt(summaryData.pralion_id);
    const response = await getLowFidelityList(pralionId, pagination);
    console.log("response", response);
    if (response?.status === 200) {
      setFetching(false);
      const data = response?.data?.data || [];
      setTotalRows(data.count);
      setList(data.records);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setList([]);
      setFetching(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const goto = (data) => {
    console.log("data", data);
    if (data.log_url !== null) {
      window.open(data.visualizer_url, "_blank");
    } else {
      toast.error("Url not found");
    }
  };

  const columns = useMemo(
    () => [
      {
        field: "name",
        renderHeader: () => <span className="heading-2">{"Name "}</span>,
        width: 380,
        renderCell: (params) => {
          return (
            <Grid>
              <Button
                className="Main-link-simple row-heading"
                sx={{ color: "white" }}
                onClick={() => goto(params.row)}
              >
                {wrapText(params.value)}
              </Button>
            </Grid>
          );
        },
      },
     
      {
        field: "output.MinTTC",
        renderHeader: () => <span className="heading-2">{"Min TTC (s)"}</span>,
        width: 140,
        renderCell: (params) => (
          <span>
            {params.row.output.MinTTC?.toFixed(3)}
          </span>
        ),
      },
      {
        field: "output.MinTHW",
        renderHeader: () => <span className="heading-2">{"Min THW (s)"}</span>,
        width: 140,
        renderCell: (params) => (
          <span>
            {params.row.output.MinTHW?.toFixed(3)}
          </span>
        ),
      },
      {
        field: "output.LJavg",
        renderHeader: () => <span className="heading-2">{"LJ Avg (m/s^3)"}</span>,
        width: 155,
        renderCell: (params) => (
          <span>
            {params.row.output.LJavg?.toFixed(3)}
          </span>
        ),
      },
      {
        field: "output.LJ_Pass",
        renderHeader: () => <span className="heading-2">{"LJ Pass"}</span>,
        width: 155,
        renderCell: (params) => (
          <Grid>
            {params.row.output?.LJ_Pass === true ? (
              <p style={{ marginTop: "16px" }}>
                <DoneIcon sx={{ color: "green" }} />
              </p>
            ) : params.row.output?.LJ_Pass === false ? (
              <p style={{ marginTop: "16px" }}>
                <CloseIcon sx={{ color: "red" }} />
              </p>
            ) : (
              ""
            )}
          </Grid>
        ),
      },
      {
        field: "output.THW_Pass",
        renderHeader: () => <span className="heading-2">{"THW Pass"}</span>,
        width: 155,
        renderCell: (params) => (
          <Grid>
            {params.row.output?.THW_Pass === true ? (
              <p style={{ marginTop: "16px" }}>
                <DoneIcon sx={{ color: "green" }} />
              </p>
            ) : params.row.output?.THW_Pass === false ? (
              <p style={{ marginTop: "16px" }}>
                <CloseIcon sx={{ color: "red" }} />
              </p>
            ) : (
              ""
            )}
          </Grid>
        ),
      },
      {
        field: "output.TTC_Pass",
        renderHeader: () => <span className="heading-2">{"TTC Pass"}</span>,
        width: 155,
        renderCell: (params) => (
          <Grid>
            {params.row.output?.TTC_Pass === true ? (
              <p style={{ marginTop: "16px" }}>
                <DoneIcon sx={{ color: "green" }} />
              </p>
            ) : params.row.output?.TTC_Pass === false ? (
              <p style={{ marginTop: "16px" }}>
                <CloseIcon sx={{ color: "red" }} />
              </p>
            ) : (
              ""
            )}
          </Grid>
        ),
      },
      {
        field: "output.ScenarioPass",
        renderHeader: () => (
          <span className="heading-2">{"Scenario Pass"}</span>
        ),
        width: 185,
        renderCell: (params) => (
          <Grid>
            {params.row.output?.ScenarioPass === true ? (
              <p style={{ marginTop: "16px" }}>
                <DoneIcon sx={{ color: "green" }} />
              </p>
            ) : params.row.output?.ScenarioPass === false ? (
              <p style={{ marginTop: "16px" }}>
                <CloseIcon sx={{ color: "red" }} />
              </p>
            ) : (
              ""
            )}
          </Grid>
        ),
      },
    ],
    [list, page]
  );

  return (
    <Fragment>
      <MainTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />
    </Fragment>
  );
}
