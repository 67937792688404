import React from "react";
import { CardContent, Grid, TextField, Box } from "@mui/material";

export default function DescriptionField({
  handleClose,
  headerName,
  description,
}) {
  return (
    <Box>
      <CardContent>
        <form onSubmit={``}>
          <Grid item xs={6}>
            <TextField
              name="name"
              //   required
              type="text"
              variant="outlined"
              label={headerName}
              value={description}
              disabled={true}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
        </form>
      </CardContent>
    </Box>
  );
}
