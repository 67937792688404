import React, { Fragment, useState, useEffect, useMemo } from "react";
import {
  Grid,
  Button,
  Chip,
  IconButton,
  Radio,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ButtonX, ModalX } from "components";
import { hfDownloadFiles, pageSize } from "utils/staticsData";
import MainTable from "components/mainTable";
import STORE from "services/redux-store/store";
import { getHighFidelityExecutionJob } from "endpointServices/highFidelityServices";
import dateConverter from "utils/dateConverter";
import { CloudDownload } from "@mui/icons-material";
import { getCosimClientService } from "endpointServices/simulationServices";
import AirplayIcon from "@mui/icons-material/Airplay";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import DownloadIcon from "@mui/icons-material/Download";
import visualizerUrl from "../../../../assets/images/visualizer-url.png";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";

export default function HighFidelityExecute() {
  const navigator = useNavigate();
  const { HighFidelity } = STORE.getState();

  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [cosimClient, setCosimClient] = useState([]);
  const [clientId, setClientId] = useState([]);
  const [versionModalOpen, setVersionModalOpen] = useState(false);
  const [downloadsModalOpen, setDownloadsModalOpen] = useState(false);
  const [versionsList, setVersionsList] = useState({});
  const [downloadsLogs, setDownloadsLogs] = useState({});
  const [downloadsList, setDownloadsList] = useState([]);

  useEffect(() => {
    getCosimClient();
  }, [clientId]);

  useEffect(() => {
    getExecutionJob();
  }, []);

  const getCosimClient = async () => {
    const response = await getCosimClientService();
    if (response?.status === 200) {
      const { data } = response?.data;
      // Filter the cosimClient array based on clientId[0]
      const filteredClient = data.filter((client) => client.id === clientId[0]);
      setCosimClient(filteredClient || []);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setCosimClient([]);
    }
  };

  const getExecutionJob = async () => {
    setFetching(true);
    const pralionId = parseInt(HighFidelity?.pralionId);
    const response = await getHighFidelityExecutionJob(pralionId);
    console.log("responser", response);
    if (response?.status === 200) {
      setFetching(false);
      const data = response?.data?.data || [];
      setList(data);
      const versions = data.map((item) => item.meta?.observer_output || {});
      setVersionsList(versions);
      const clientIds = data.map((item) => item.client_id);
      console.log("clientIds", clientIds);
      setClientId(clientIds);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setList([]);
      setFetching(false);
      setClientId([]);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const goto = (data) => {
    console.log("dataaa", data);
    if (data.logs !== null) {
      window.open(data?.meta?.visualizer_url, "_blank");
    } else {
      alert("Url not found");
    }
  };

  const handleDownload = (url) => {
    console.log("url", url);
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", ""); // This will prompt the browser to download the file
    document.body.appendChild(link);

    // Trigger the click event on the anchor element
    link.click();

    // Cleanup: Remove the temporary anchor element from the DOM
    document.body.removeChild(link);
  };

  const handleVersionModal = (rowData) => {
    setVersionModalOpen(true);

    const filteredRow = list.filter((item) => item.id === rowData.id);
    setVersionsList(filteredRow[0]);
  };

  const handleDownloadModal = (data) => {
    const metaData = data.meta;
    console.log("metaData", metaData);
    setDownloadsLogs(metaData);
    const metaKeys = Object.keys(metaData);
    const filterDownloadList = hfDownloadFiles.filter((item) =>
      metaKeys.includes(item.path)
    );
    setDownloadsList(filterDownloadList);
    setDownloadsModalOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        field: "id",
        renderHeader: () => (
          <span className="heading-2" style={{ marginLeft: "20px" }}>
            {"Id"}
          </span>
        ),
        width: 120,
        renderCell: (params) => {
          return (
            <Grid className="Main-link-simple" sx={{ marginLeft: "20px" }}>
              {params.value}
            </Grid>
          );
        },
      },

      {
        field: "state",
        renderHeader: () => <span className="heading-2">{"State"}</span>,
        width: 200,
        renderCell: (params) => {
          let chipColor;
          switch (params.value) {
            case "running":
              chipColor = "primary";
              break;
            case "failed":
              chipColor = "error";
              break;
            case "queued":
              chipColor = "default";
              break;
            default:
              chipColor = "success";
              break;
          }
          return <Chip label={params.value} color={chipColor} />;
        },
      },
      {
        field: "job_type",
        renderHeader: () => <span className="heading-2">{"Job Type"}</span>,
        width: 210,
      },
      {
        field: "executor",
        renderHeader: () => <span className="heading-2">{"Executor"}</span>,
        width: 210,
      },
      {
        field: "start_time",
        renderHeader: () => <span className="heading-2">{"Start Time"}</span>,
        width: 260,
        renderCell: (params) => (
          <span className="row-heading">
            {params.value ? dateConverter(params.value) : "N/A"}
          </span>
        ),
      },
      {
        field: "end_time",
        renderHeader: () => <span className="heading-2">{"End Time"}</span>,
        width: 260,
        renderCell: (params) => (
          <span className="row-heading">
            {params.value ? dateConverter(params.value) : "N/A"}
          </span>
        ),
      },
      {
        field: "actions",
        renderHeader: () => <span className="heading-2">{"Actions"}</span>,
        width: 300,
        renderCell: (params) => {
          const disabled = !params.row.meta || !params.row.meta.visualizer_url;
          return (
            <div>
              <Tooltip
                title={!disabled ? "Visualizer Url" : ""}
                placement="top"
              >
                <IconButton
                  onClick={disabled ? null : () => goto(params.row)}
                  style={{
                    cursor: disabled ? "default" : "pointer",
                    opacity: disabled ? 0.4 : 1,
                  }}
                >
                  <img
                    src={visualizerUrl}
                    alt="visualizer Url"
                    style={{
                      maxWidth: "25px",
                      maxHeight: "100%",
                    }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Observer Output" placement="top">
                <IconButton
                  disabled={!params?.row?.meta?.observer_output}
                  onClick={() => handleVersionModal(params?.row)}
                  style={{ paddingLeft: "0.6em" }}
                >
                  <AirplayIcon />
                </IconButton>
              </Tooltip>

              {params.row.meta === null ? (
                <Tooltip title="Log Donwload" placement="top">
                  <IconButton 
                    onClick={() => handleDownload(params.row.logs)}
                    disabled={!params.row.logs}
                  >
                    <SimCardDownloadIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Fragment>
                  <Tooltip title="Files Download" placement="top">
                    <IconButton
                      disabled={!params.row.meta}
                      onClick={() => handleDownloadModal(params.row)}
                    >
                      <CloudDownload />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Log Donwload" placement="top">
                    <IconButton 
                      onClick={() => handleDownload(params.row.logs)}
                      disabled={!params.row.logs}
                    >
                      <SimCardDownloadIcon />
                    </IconButton>
                  </Tooltip>
                </Fragment>
              )}
            </div>
          );
        },
      },
    ],
    [list, page]
  );

  const handleMenuItem = (url) => {
    const pathName = url.path;
    const downloadFile = downloadsLogs[pathName];
    handleDownload(downloadFile);
  };

  const versionsColumns = useMemo(
    () => [
      {
        field: "THW_Pass",
        renderHeader: () => <span className="heading-2">{"THW Pass"}</span>,
        width: 128,
        renderCell: (params) => (
          <Grid container spacing={1} alignItems="center">
            {versionsList?.meta?.observer_output?.THW_Pass === true ? (
              <p style={{ marginTop: "16px" }}>
                <DoneIcon sx={{ color: "green" }} />
              </p>
            ) : versionsList?.meta?.observer_output?.THW_Pass === false ? (
              <p style={{ marginTop: "16px" }}>
                <CloseIcon sx={{ color: "red" }} />
              </p>
            ) : (
              ""
            )}
          </Grid>
        ),
      },
      {
        field: "TTC_Pass",
        renderHeader: () => <span className="heading-2">{"TTC Pass"}</span>,
        width: 128,
        renderCell: (params) => (
          <Grid container spacing={1} alignItems="center">
            {versionsList?.meta?.observer_output?.TTC_Pass === true ? (
              <p style={{ marginTop: "16px" }}>
                <DoneIcon sx={{ color: "green" }} />
              </p>
            ) : versionsList?.meta?.observer_output?.TTC_Pass === false ? (
              <p style={{ marginTop: "16px" }}>
                <CloseIcon sx={{ color: "red" }} />
              </p>
            ) : (
              ""
            )}
          </Grid>
        ),
      },
      {
        field: "LJ_Pass",
        renderHeader: () => <span className="heading-2">{"LJ Pass"}</span>,
        width: 128,
        renderCell: (params) => (
          <Grid container spacing={1} alignItems="center">
            {versionsList?.meta?.observer_output?.LJ_Pass === true ? (
              <p style={{ marginTop: "16px" }}>
                <DoneIcon sx={{ color: "green" }} />
              </p>
            ) : versionsList?.meta?.observer_output?.LJ_Pass === false ? (
              <p style={{ marginTop: "16px" }}>
                <CloseIcon sx={{ color: "red" }} />
              </p>
            ) : (
              ""
            )}
          </Grid>
        ),
      },
      {
        field: "LJavg",
        renderHeader: () => (
          <span className="heading-2">{"LJ Avg (m/s^3)"}</span>
        ),
        width: 150,
        renderCell: (params) => (
          <span className="row-heading">
            {versionsList?.meta?.observer_output?.LJavg?.toFixed(7)}
          </span>
        ),
      },
      {
        field: "MinTHW",
        renderHeader: () => <span className="heading-2">{"Min THW (s)"}</span>,
        width: 150,
        renderCell: (params) => (
          <span className="row-heading">
            {versionsList?.meta?.observer_output?.MinTHW?.toFixed(7)}
          </span>
        ),
      },
      {
        field: "MinTTC",
        renderHeader: () => <span className="heading-2">{"Min TTC (s)"}</span>,
        width: 150,
        renderCell: (params) => (
          <span className="row-heading">
            {versionsList?.meta?.observer_output?.MinTTC?.toFixed(7)}
          </span>
        ),
      },
    ],
    [versionsList]
  );

  const downloadColumns = [
    {
      field: "name",
      headerName: <span className="heading-2">Name</span>,
      width: 300,
      renderCell: (params) => {
        const mapKeyStyle = params.value === "Map" ? "pe-3" : "";
        return (
          <Grid>
            <Button
              className={`Main-link-simple ${mapKeyStyle}`}
              sx={{ color: "white" }}
            >
              {params.value}
            </Button>
          </Grid>
        );
      },
    },
    {
      field: "type",
      headerName: <span className="heading-2">Type</span>,
      width: 300,
      renderCell: (params) => (
        <Grid>
          <Typography>{params.value}</Typography>
        </Grid>
      ),
    },
    {
      field: "download",
      headerName: <span className="heading-2">Download</span>,
      width: 150,
      renderCell: (params) => (
        <Grid>
          <IconButton onClick={() => handleMenuItem(params.row)}>
            <DownloadIcon />
          </IconButton>
        </Grid>
      ),
    },
  ];

  return (
    <Fragment>
      <ModalX
        open={versionModalOpen}
        close={() => setVersionModalOpen(false)}
        heading={"Observer"}
        subheading={"Outputs"}
      >
        <MainTable
          table={[versionsList]}
          headers={versionsColumns}
          fetching={isFetching}
        />
      </ModalX>
      <ModalX
        open={downloadsModalOpen}
        close={() => setDownloadsModalOpen(false)}
        heading={"Downloads"}
        subheading={"Outputs"}
      >
        <MainTable
          table={downloadsList}
          headers={downloadColumns}
          // fetching={isFetching}
        />
      </ModalX>
      <Grid container alignItems="center" justifyContent="space-between" mb={2}>
        {cosimClient?.map((cluster, index) => (
          <Grid
            key={index}
            item
            sx={{
              backgroundColor: "secondary.dark",
              border: "2px solid",
              borderColor: "primary.main",
              borderRadius: "12px",
              width: "20rem",
              marginTop: "1rem",
            }}
          >
            <Grid container justifyContent="space-between" padding="1rem">
              <Typography>Machine-{cluster.id}</Typography>
              <Typography>
                Status
                <Radio
                  color={cluster.status === "active" ? "success" : "error"}
                  checked={cluster.status}
                  sx={{
                    marginLeft: "10px",
                    transform: "scale(0.6)",
                    width: "2px",
                    height: "2px",
                    "& > input": {
                      backgroundColor:
                        cluster.status === "active" ? "#4caf50" : "#f44336",
                      color:
                        cluster.status === "active" ? "#4caf50" : "#f44336",
                      "&:checked + span": {
                        backgroundColor:
                          cluster.status === "active" ? "#4caf50" : "#f44336",
                        color:
                          cluster.status === "active" ? "#4caf50" : "#f44336",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        ))}
        <Grid item>
          <ButtonX onClick={() => navigator(-1)}>Back</ButtonX>
        </Grid>
      </Grid>

      <MainTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />
    </Fragment>
  );
}
