import { ArrowUpward, DirectionsCarFilled } from "@mui/icons-material";
import { Button, Checkbox, Divider, Grid, IconButton, Menu, MenuItem, TextField, Typography } from "@mui/material";
import { getAgents } from "pages/educAgents/modules/stateMaintain";
import React, { useEffect, useState } from "react";
import { agentsParameters } from "utils";
import { ReduxUpdateEducAgentsJourney } from "services/redux-store/actions/actions-educAgents";
import { connect } from "react-redux";
import STORE from "services/redux-store/store";
import { ButtonX } from "components";
import { useNavigate, useParams } from "react-router-dom";
import Caricon from '../../../../assets/icons/car-top.png'
import InputSegment from "./modelInputComponent/segment";
import { AUTHENTICATED_ROUTES } from "router/constants";

const Arrow = () => (
  <Typography
    variant="body1"
    sx={{
      position: "absolute",
      top: "-1%",
      transform: "translateY(-50%)",
      right: "-13px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#00A3B5"
    }}
  >
    <ArrowUpward />
  </Typography>
);

const ModelInputParams = ({
  ReduxUpdateEducAgentsJourney,
}) => {
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [agentValue, setAgentValue] = useState(false);
  const [showNewGrid, setShowNewGrid] = useState(false);
  const [isDisable, setIsDisabled] = useState(true);
  const [showButtons, setShowButtons] = useState(false);
  const [selectSegmentNumber, setSelectSegmentNumber] = useState()
  const [selectVehSegmentNumber, setSelectVehSegmentNumber] = useState()
  const [selectTilt, setSelectTilt] = useState()
  const [displayedVehSegmentNumber, setDisplayedVehSegmentNumber] = useState('');

  const { EducAgents } = STORE.getState();

  const navigate = useNavigate();
  const param = useParams();

  const segmentNumber = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6"
  ];

  const vehSegmentNumber = [
    "1",
    "2",
  ];

  const tiltNumber = [
    0.0,
  ];

  const columnTexts = [
    "2nd left adjacent lane",
    "1st left adjacent lane",
    "ego's lane",
    "1st right adjacent lane",
    "2nd right adjacent lane",
  ];

  useEffect(() => {
    getStoreValues();
  }, [EducAgents.hyperParameterPerceptionAgent]);

  useEffect(() => {
    if (EducAgents.journey === 'segmented') {
      setShowNewGrid(true);
      setShowButtons(true);
    } else {
      setShowNewGrid(false);
      setShowButtons(false);
    }
  }, [EducAgents.journey]);

  useEffect(() => {
    setIsDisabled(selectedAgent.length === 0);
  }, [selectedAgent]);

  const handleSegmentChange = (e) => {
    const { value } = e.target;
    setSelectSegmentNumber(value);
    setIsDisabled(false);
    ReduxUpdateEducAgentsJourney({numberOfSegment: value})

    if (value) {
      setSelectVehSegmentNumber('1');
      setDisplayedVehSegmentNumber('1'); 
      ReduxUpdateEducAgentsJourney({ vehicleNumberOfSegment: '1' });
    } else {
      setDisplayedVehSegmentNumber(''); 
    }
  };

  const handleVehSegmentChange = (e) => {
    const { value } = e.target;
    setSelectVehSegmentNumber(value);
    ReduxUpdateEducAgentsJourney({vehicleNumberOfSegment: value})
  };

  const handleTiltChange = (e) => {
    const { value } = e.target;
    setSelectTilt(value);
    // ReduxUpdateEducAgentsJourney({tilt: value})
  };

  const handleCheck = (data) => {
    const value = data?.key;
    console.log("value", value);
    // selectedAgent.push(value)
    const updatedAgent = [...selectedAgent];
    const agentSelected = updatedAgent.findIndex((agent) => agent === value);
    if (agentSelected !== -1) {
      updatedAgent.splice(agentSelected, 1);
    } else {
      updatedAgent.push(value);
    }
    // const isEgoIndexSelected = updatedAgent.includes(agentsParameters[7].key);
    // if (!isEgoIndexSelected) {
    //   updatedAgent.push(agentsParameters[7].key);
    // }
    setSelectedAgent(updatedAgent);
    const selectedCheck = Object.values(updatedAgent).some((value) => value);
    setIsDisabled(!selectedCheck);
    ReduxUpdateEducAgentsJourney({
      hyperParameterPerceptionAgent: updatedAgent,
    });
    getAgents(updatedAgent);

    console.log("selectedAgents", updatedAgent);
  };

  const getStoreValues = () => {
    if (
      EducAgents.hyperParameterPerceptionAgent !== undefined &&
      EducAgents.hyperParameterPerceptionAgent !== null &&
      EducAgents.hyperParameterPerceptionAgent.length > 0
    ) {
      setSelectedAgent(EducAgents.hyperParameterPerceptionAgent);
      console.log(
        "Agent Parameter List",
        EducAgents.hyperParameterPerceptionAgent
      );
    }

    if (EducAgents.tilt === undefined || EducAgents.tilt === null) {
      setSelectTilt(0.0);
      ReduxUpdateEducAgentsJourney({ tilt: 0.0 });
    } else {
      setSelectTilt(EducAgents.tilt);
    }
  };

  const onClickNext = () => {
    const vehicleSelectionType = {
      type: showNewGrid ? 'segmented' : 'internal',
    };
  
    if (showNewGrid) {
      vehicleSelectionType.segments = parseInt(selectSegmentNumber);
      vehicleSelectionType.tilt = EducAgents?.tilt;
    }
    ReduxUpdateEducAgentsJourney({ vehicleSelectionType });

    const updatedSelectedView = showNewGrid ? "segmented" : "internal";
    ReduxUpdateEducAgentsJourney({ journey: updatedSelectedView });
    
    navigate(
      AUTHENTICATED_ROUTES.attributeSelection
        .replace(":id", param.id)
        .replace(":aid", param.aid)
        .replace(":oddid", param.oddid)
        .replace(":strategy", param.strategy)
    );
  };

  return (
    <>
      <div className={`row`}>
        <div className={`col-12 d-flex justify-content-between`}>
          <div className={`d-flex`}>
            <ButtonX
              className={`me-2`}
              variant={showNewGrid === false ? "contained" : "outlined"}
              onClick={() => {
                setShowNewGrid(false);
                setShowButtons(false);
              }}
            >
              Lane Based
            </ButtonX>
            <ButtonX
              onClick={() => {
                setShowNewGrid(true);
                setShowButtons(true);
              }}
              variant={showNewGrid === false ? "outlined" : "contained"}
            >
              Segment Based
            </ButtonX>
          </div>
          <div>
            {showButtons && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "28rem",
                }}
              >
                <TextField
                  label="Select No of segment"
                  name="selectCategory"
                  type="select"
                  size="small"
                  variant="outlined"
                  value={selectSegmentNumber}
                  onChange={handleSegmentChange}
                  sx={{ marginRight: "1rem" }}
                  fullWidth
                  select
                  SelectProps={{
                    MenuProps: {
                      className: "customDropdown",
                    },
                  }}
                >
                  {segmentNumber.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </TextField>
                <TextField
                  label="Max No of Veh per segment"
                  name="selectCategory"
                  type="select"
                  size="small"
                  variant="outlined"
                  value={selectVehSegmentNumber || displayedVehSegmentNumber}
                  onChange={handleVehSegmentChange}
                  sx={{ marginRight: "1rem" }}
                  fullWidth
                  select
                  SelectProps={{
                    MenuProps: {
                      className: "customDropdown",
                    },
                  }}
                >
                  {vehSegmentNumber.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </TextField>
                {/* <TextField
                  label="Tilt"
                  name="selectCategory"
                  type="select"
                  size="small"
                  variant="outlined"
                  value={selectTilt}
                  onChange={handleTiltChange}
                  sx={{ width: "17rem" }}
                  select
                  SelectProps={{
                    MenuProps: {
                      className: "customDropdown",
                    },
                  }}
                >
                  {tiltNumber.map((item) => {
                    return (
                      <MenuItem key={item.id} value={item}>
                        {item.toFixed(1)}
                      </MenuItem>
                    );
                  })}
                </TextField> */}
              </div>
            )}
          </div>
          <div className={`d-flex`}>
            <ButtonX className={`me-2`} onClick={() => navigate(-1)}>
              Back
            </ButtonX>
            <ButtonX onClick={onClickNext} disabled={isDisable}>
              Next
            </ButtonX>
          </div>
        </div>
        <div>
          <Divider style={{ marginTop: "1rem" }} />
        </div>
      </div>
      {showNewGrid ? (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            backgroundColor: "secondary.dark",
            textAlign: "center",
            borderRadius: "6px",
            height: "40rem",
            marginTop: "1rem"
          }}
        >
          <Grid item>
            <InputSegment segmentNumber={selectSegmentNumber} vehSegmentNumber={selectVehSegmentNumber} />
          </Grid>
        </Grid>
      ) : (
        <>
        <Typography sx={{marginTop: "2rem", fontSize: "1.3rem"}}>Configure Ego and Agents</Typography>
        <Grid container spacing={2} style={{ marginLeft: "5px", marginTop: "2rem" }}>
          {agentsParameters.map((agent, index) => (
            <Grid
              item
              key={agent.id}
              xs={2.2}
              sx={{
                borderLeft:
                  index < agentsParameters.length
                    ? "1px dashed #00A3B5"
                    : "none",
                borderRight:
                  index < agentsParameters.length
                    ? "1px dashed #00A3B5"
                    : "none",
                paddingRight: "20px",
                position: "relative",
              }}
            >
              {index < agentsParameters.length - 10 && <Arrow />}
              {index < agentsParameters.length - 10 && (
                <Typography
                  variant="body1"
                  sx={{
                    // position: "absolute",
                    // top: "-1%",
                    transform: "translateY(-50%)",
                    right: "70px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {columnTexts[index]}
                </Typography>
              )}
              {index == 7 ? (
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor:
                      index === 7
                        ? "#c73a3a"
                        : selectedAgent.some((value) => value === agent.key)
                        ? "#2c5c61"
                        : "secondary.dark",
                    borderRadius: "6px",
                    minWidth: "80px",
                    height: "8rem",
                    m: 1,
                    position: "relative",
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <div
                    style={{
                      backgroundColor: "#00A3B5",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "2rem",
                      borderTopLeftRadius: "6px",
                      borderBottomRightRadius: "6px",
                    }}
                  >
                    {agent.id}
                  </div>
                  <img src={Caricon} alt="icons" style={{ width: "36px" }} />
                  <Typography sx={{ marginTop: "8px" }}>Ego</Typography>
                </Grid>
              ) : (
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor:
                      index === 7
                        ? "#c73a3a"
                        : selectedAgent.some((value) => value === agent.key)
                        ? "#2c5c61"
                        : "secondary.dark",
                    borderRadius: "6px",
                    minWidth: "80px",
                    height: "8rem",
                    m: 1,
                    position: "relative",
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <div
                    style={{
                      backgroundColor: "#00A3B5",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "2rem",
                      borderTopLeftRadius: "6px",
                      borderBottomRightRadius: "6px",
                    }}
                  >
                    {agent.id}
                  </div>
                  <Checkbox
                    checked={selectedAgent.some((value) => value === agent.key)}
                    onChange={() => handleCheck(agent)}
                    sx={{ position: "absolute", top: 0, right: 0 }}
                    disabled={agent.disable}
                  />
                  <img src={Caricon} alt="icons" style={{ width: "36px" }} />
                  <Typography sx={{ marginTop: "8px" }}>Agent</Typography>
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
        </>
      )}
    </>
  );
};

export default connect(null, { ReduxUpdateEducAgentsJourney })(
  ModelInputParams
);
