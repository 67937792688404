import { ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { AlertX, IconButtonX, ProgressX, UxSpace } from "components";
import { GET_SHOW_ODDS_MAPS } from "constants/endpoints";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ReduxUpdateAutoScenarioJourney } from "services/redux-store/actions/actions-autoscenario";
import { connect } from "react-redux";
import STORE from "services/redux-store/store";

const AutoMapOdds = ({loading, mapIds, updateNextButtonState, ReduxUpdateAutoScenarioJourney}) => {

    // console.log("mapIds", mapIds);
  
    const [oddsList, setOddsList] = useState([]);
    // console.log("oddsList", oddsList);
    const [selectedOddId, setSelectedOddId] = useState(null);
    const { AutoScenario } = STORE.getState();
    
    useEffect(() => {
      if (mapIds && mapIds.length) {
        setOddsList(mapIds);
        if (AutoScenario?.AutoMapList?.oddData && AutoScenario?.AutoMapList?.oddData?.gid_kwh) {
          setSelectedOddId(AutoScenario?.AutoMapList?.oddData?.gid_kwh)
          updateNextButtonState(true, AutoScenario?.AutoMapList?.oddData?.gid_kwh);
        }
        else {
          setSelectedOddId(AutoScenario?.AutoMapList?.oddData?.gid_kwh)
          updateNextButtonState(false, AutoScenario?.AutoMapList?.oddData?.gid_kwh);
        }
      }
      else {
        setOddsList([]);
      }
    }, [mapIds]);
  
    const onClickItemCheckbox = (e) => {
      const updatedSelectOddId = e.gid_kwh === selectedOddId ? null : e.gid_kwh;
      setSelectedOddId(updatedSelectOddId);
      const isChecked = updatedSelectOddId !== null;
      updateNextButtonState(isChecked, updatedSelectOddId);
      // ReduxUpdateAutoScenarioJourney({ oddData: e });
    };

    const alertMessage = () => {
      if (!mapIds) {
        return "Select a Map to load ODDs."
      }
      else {
        return "No ODDs found in this map, Please select another."
      }
    }
  
    return (
      <>
        <div className={`container-fuild`} style={{marginLeft:'1em'}}>
          <div className={`row mt-3 `}>
            {
              <ImageList
                sx={{ width: "100%", maxHeight: "78vh", padding: "12px" }}
                gap={10}
                cols={5}
                rowHeight={260}
              >
                {loading ? (
                  [...Array(15).keys()].map((x, i) => (
                    <div key={i}>
                      <ProgressX block={true} height={230} width={"100%"} />
                    </div>
                  ))
                ) : oddsList && oddsList.length === 0 ? (
                    <AlertX text={alertMessage()} badge={"info"} style={{ width: "23rem", backgroundColor: "#2F4870" }} />
                ) : (
                  oddsList.map((item, i) => (
                    <ImageListItem key={i}>
                      <ImageListItemBar
                        sx={{
                          background: "linear-gradient(to bottom, rgba(35, 55, 75, 0.7) 0%, " + "rgba(35, 55, 75, 0.3) 70%, rgba(35, 55, 75, 0) 100%)",
                          borderRadius: "10px",
                        }}
                        position="top"
                        actionPosition="left"

                        actionIcon={
                          <IconButtonX
                            sx={{ color: "white" }}
                            disabled={AutoScenario.readonly}
                            icon={item.gid_kwh === selectedOddId ? "CheckBoxRounded" : "CheckBoxOutlineBlankRounded"}
                            onClick={() => onClickItemCheckbox(item)}
                          />
                        }
                      />
                      <img
                        src={`${GET_SHOW_ODDS_MAPS}/${item.gid_kwh}`}
                        alt={item.gid_kwh}
                        loading="lazy"
                        className={`img-fluid`}
                        
                        style={{
                          borderRadius: "10px",
                          borderColor: "fff",
                          maxHeight: 260,
                        }}
                      />
                      <ImageListItemBar
                        sx={{
                          background: "linear-gradient(to bottom, rgba(35, 55, 75, 0.7) 100%, " + "rgba(35, 55, 75, 0.3) 70%, rgba(35, 55, 75, 0) 0%)",
                          borderEndEndRadius: "10px",
                        }}

                        position="bottom"
                        actionPosition="right"
                        title={item.name || `Map ${i + 1}`}
                      />
                    </ImageListItem>
                  ))
                )}
              </ImageList>
            }
            {oddsList && oddsList.length > 15 && <UxSpace />}
          </div>
        </div>
      </>
    );
};
export default connect(null, { ReduxUpdateAutoScenarioJourney })(AutoMapOdds);