import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import * as Pages from "pages";
import { ContainerX, NoConnection, ProgressX } from "components";
import { AUTHENTICATED_ROUTES, UNAUTHENTICATED_ROUTES } from "./constants";
import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import { GetInfoFromLocalStorage, OUcFirst } from "utils";
import { ReduxAddProject } from "services/redux-store/actions";
import { connect } from "react-redux";

const INIITIAL_THEME_STATE = responsiveFontSizes(
  createTheme({
    palette: {
      mode: localStorage.getItem("mode") || "dark",
      primary: {
        main: "#00A3B5",
        dark: "#102C57",
      },
      secondary: {
        main: "#0E152D",
        dark: "rgba(32, 60, 104, 0.92)",
      },
      text: {
        main: "#FFFFFF",
        // primary: "#00A2BB",
        secondary: "#E0E0E0",
      },
    },
  })
);

export default function RouterX() {
  const getUser = GetInfoFromLocalStorage('aai_user')
  const navigator = useNavigate()
  const [theme, set_theme] = useState(INIITIAL_THEME_STATE);
  const [isLoggedIn, setIsLoggedIn] = useState(false);


  // useEffect(() => {
  //     set_theme(responsiveFontSizes(createTheme({ palette: { mode: themeMode } })));
  // }, [themeMode]);

  useEffect(() => {
    verifyUser()
  }, [isLoggedIn])

  const verifyUser = () => {
    if (getUser === undefined || getUser === null) {
      setIsLoggedIn(false)
      return navigator('/auth')
    }
    else {
      const currentUrl = window.location.pathname
      setIsLoggedIn(true)
      if(window.location.href === 'http://localhost:3000/')
        return navigator('/projects')
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Routes>
      {Object.keys(AUTHENTICATED_ROUTES).map((_route, i) => {
          const Page = Pages[OUcFirst(_route)];
          return (
            <Route
              key={i}
              path={AUTHENTICATED_ROUTES[_route]}
              element={
                <AuthenticatedRoute>
                  <Page />
                </AuthenticatedRoute>
              }
            />
          );
        })}

        {Object.keys(UNAUTHENTICATED_ROUTES).map((_route, i) => {
          const Page = Pages[OUcFirst(_route)];
          return (
            <Route
              key={i}
              path={UNAUTHENTICATED_ROUTES[_route]}
              element={
                <UnAuthenticatedRoute>
                  <Page />
                </UnAuthenticatedRoute>
              }
            />
          );
        })}
        {/* <Route path="*" element={<AuthenticatedRoute />} /> */}
      </Routes>
    </ThemeProvider>
  );
}



const AuthenticatedRoute = connect(null, {ReduxAddProject}) (function ({ children, ReduxAddProject }) {
  const params = useParams();
  //TODO: Enable this chunk of code when routing is fixed
  /**
   *  console.log('params', params);
   * useEffect(() => {
   *  ReduxAddProject(params.id);
   * },[params.id])
   * 
   * */  
  
  const [availableRoutes, set_availableRoutes] = useState(
    Object.values(AUTHENTICATED_ROUTES)
    );
    const [loading, set_loading] = useState(false);
    const [noConnection, set_noConnection] = useState(false);


  return false ? ( // if auth api has given a response
    <ProgressX fullscreen={true} />
  ) : true ? ( // if auth api has given a response and the user is authenticated
    <ContainerX pages={availableRoutes}>
      {loading ? ( // if auth api has given a response and user is authenticated and now fetching permission data
        <ProgressX />
      ) : noConnection ? (
        <NoConnection />
      ) : (
        children
      )}
    </ContainerX>
  ) : (
    <Navigate to={UNAUTHENTICATED_ROUTES.auth} />
  );
})

function UnAuthenticatedRoute({ children }) {
  return false ? ( // if auth api has given a response
    <ProgressX />
  ) : true ? ( // if auth api has given a response and is not authenticated
    children
  ) : (
    <Navigate to={AUTHENTICATED_ROUTES.dashboard} />
  );
}
