import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { coSimDSpaceDetailsTable } from "utils";

export default function CoSimDetailsTable() {
  return (
    <Grid
      sx={{
        backgroundColor: "secondary.dark",
        paddingBottom: "12px",
        borderRadius: "8px",
      }}
    >
      <Grid className="coSim-table-headings">
        <Typography
          color="text.main"
          sx={{ marginLeft: "1rem", marginTop: "1.5rem" }}
        >
          Layer 01
        </Typography>
        <Typography
          color="text.main"
          sx={{ marginTop: "1.5rem", marginRight: "1rem" }}
        >
          Add Parameter
          <AddCircleOutlineIcon style={{ fontSize: "large" }} />
        </Typography>
      </Grid>
      <Grid className="coSim-table-data">
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Para meter</TableCell>
                <TableCell>Default Value</TableCell>
                <TableCell>Units</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coSimDSpaceDetailsTable.map((data) => (
                <TableRow key={data.id}>
                  <TableCell>{data.parameter}</TableCell>
                  <TableCell>{data.defaultValue}</TableCell>
                  <TableCell>{data.units}</TableCell>
                  <TableCell>
                    <Grid style={{ display: "flex" }}>
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                      <IconButton>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
