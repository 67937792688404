import React, { Fragment, useEffect, useRef, useState } from "react";
import * as THREE from "three";
import { useLoader, useFrame } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { TextureLoader } from "three";
import STORE from "services/redux-store/store";
import camImage from "assets/images/cam.png";

export default function ThreeVehicleScene({ ...sceneProps }) {
  console.log("sceneProps?.lidarSensorPositions", sceneProps?.lidarSensorPositions)
  console.log("sceneProps?.gTSensorPositions", sceneProps?.gTSensorPositions)
  const { pos_x, pos_y, pos_z, pitch, roll, yaw } =
    sceneProps?.cameraSensorPositions;
  const { MyVehicle } = STORE.getState();
  const vehicleDetaitls = MyVehicle?.vehicleProperties;
  const modelPath =
    vehicleDetaitls?.model_path ||
    `${vehicleDetaitls?.prefab_name}.glb` ||
    `Audi_A8.glb`;
  const modalLoader = useLoader(
    GLTFLoader,
    `/3D_assets/3D_Models/${modelPath}`
  );

  console.log('sceneProps', sceneProps)
  const texture = useLoader(TextureLoader, camImage);
  const lidarsTexture = useLoader(TextureLoader, camImage);
  const [cameraSensorPreset, setCameraSensorPreset] = useState({});
  const [lidarSensorPreset, setlidarSensorPreset] = useState([]);
  const [gtSensorPreset, setGTSensorPreaet] = useState({})
  console.log("gtSensorPreset", gtSensorPreset)
  const [sensorPreset, setSensorPreset] = useState([]);
  const [selectedCameraData, setSelectedCameraData] = useState({});


  useEffect(() => {
    setSensorPreset(sceneProps?.sensorPreset[0]?.sensor_presets);
  }, [sceneProps?.sensorPreset]);

  useEffect(() => {
    getSelectedCameraData();
  }, [sceneProps?.selectedCameraId]);

  useEffect(() => {
    setCameraSensorPreset({
      posX: pos_x ? pos_x : 0,
      posY: pos_y ? pos_y : 0,
      posZ: pos_z ? pos_z : 0,
      pitch: pitch ? pitch : 0,
      roll: roll ? roll : 0,
      yaw: yaw ? yaw : 0,
    });
  }, [pos_x, pos_y, pos_z, pitch, roll, yaw]);

  useEffect(() => {
    setGTSensorPreaet({
      radius: sceneProps.gTSensorPositions?.radius ? sceneProps.gTSensorPositions?.radius : 30,
    });
  }, [sceneProps.gTSensorPositions?.radius]);

  const getSelectedCameraData = () => {
    const selectedCameraId = sceneProps?.selectedCameraId;
    const cameraData = sensorPreset?.find(
      (camera) => camera.id === selectedCameraId
    );
    setSelectedCameraData(cameraData);
    setCameraSensorPreset({
      posX: cameraData?.pos_x ? cameraData?.pos_x : 0,
      posY: cameraData?.pos_y ? cameraData?.pos_y : 0,
      posZ: cameraData?.pos_z ? cameraData?.pos_z : 0,
      pitch: cameraData?.rot_x ? cameraData?.rot_x : 0,
      roll: cameraData?.rot_z ? cameraData?.rot_z : 0,
      yaw: cameraData?.rot_y ? cameraData?.rot_y : 0,
    });
  };

  useEffect(() => {
    setlidarSensorPreset({
      pos_x: sceneProps?.lidarSensorPositions?.pos_x
        ? sceneProps?.lidarSensorPositions?.pos_x
        : 0,
      pos_y: sceneProps?.lidarSensorPositions?.pos_y
        ? sceneProps?.lidarSensorPositions?.pos_y
        : 0,
      pos_z: sceneProps?.lidarSensorPositions?.pos_z
        ? sceneProps?.lidarSensorPositions?.pos_z
        : 0,
      radius: sceneProps?.lidarSensorPositions?.radius
        ? sceneProps?.lidarSensorPositions?.radius
        : 1,
      pitch: sceneProps?.lidarSensorPositions?.pitch
        ? sceneProps?.lidarSensorPositions?.pitch
        : 0,
      roll: sceneProps?.lidarSensorPositions?.roll
        ? sceneProps?.lidarSensorPositions?.roll
        : 0,
      yaw: sceneProps?.lidarSensorPositions?.yaw
        ? sceneProps?.lidarSensorPositions?.yaw
        : 0,
    });
  }, [
    sceneProps?.lidarSensorPositions?.pos_x,
    sceneProps?.lidarSensorPositions?.pos_y,
    sceneProps?.lidarSensorPositions?.pos_z,
    sceneProps?.lidarSensorPositions?.radius,
    sceneProps?.lidarSensorPositions?.pitch,
    sceneProps?.lidarSensorPositions?.roll,
    sceneProps?.lidarSensorPositions?.yaw,
  ]);

  // const gizmoRef = useRef();
  // This will update the TransformControls gizmo in each frame
  // useFrame(() => gizmoRef.current.update());


  const renderLidars = () => {
    if (sceneProps.lidars) {
      return (
        <group
          rotation={[
            THREE.MathUtils.degToRad(lidarSensorPreset.pitch),
            THREE.MathUtils.degToRad(lidarSensorPreset.yaw),
            THREE.MathUtils.degToRad(lidarSensorPreset.roll),
          ]}
          enabled={false}
          position={[
            lidarSensorPreset.pos_x,
            lidarSensorPreset.pos_y,
            lidarSensorPreset.pos_z,
          ]}
        >
          <mesh scale={[0.08, 0.06, 0.08]} position={[0, 0.1, 0]} receiveShadow>
            <planeBufferGeometry args={[5, 5]} />
            <meshBasicMaterial map={lidarsTexture} side={THREE.DoubleSide} />
          </mesh>
          <mesh
            rotation-x={Math.PI * 0.6}
            rotation-y={Math.PI * 0.5}
            rotation-z={Math.PI * 0.92}
            scale={[1, 1, 0.2]}
            position={[0, 0, 2.5]}
          >
            <coneBufferGeometry args={[1, 5, 4]} Color="red" />
            <meshStandardMaterial
              // wireframe
              attach="material"
              color={"white"}
              opacity={0.5}
              transparent
            />
          </mesh>
        </group>
      );
    } else return null;
  };

  const renderGroundTruth = () => {
    if (sceneProps.groundTruth) {
      const radius = gtSensorPreset.radius / 10;
      const args = [radius, 32];
      return (
        <mesh
          rotation-y={Math.PI * 0.0}
          rotation-x={Math.PI * -0.5}
          position={[0, 1, 0]}
        >
          <circleBufferGeometry args={args} />
          <meshStandardMaterial
            // wireframe
            attach="material"
            color={"white"}
            opacity={0.5}
            transparent
          />
        </mesh>
      );
    } else {
      return null;
    }
  };

  const renderCameraSensors = () => {
    return sensorPreset?.map((sensor) => {
      const isSelected = selectedCameraData?.id === sensor.id;
      const shouldShow = sensor.isShow
      console.log('shouldShow', shouldShow)
      if (shouldShow) {
        return (
            <group
              key={sensor.id}
              // size={transformControlsSize}
              rotation={[
                isSelected
                  ? THREE.MathUtils.degToRad(cameraSensorPreset.pitch)
                  : THREE.MathUtils.degToRad(sensor.rot_x),
                isSelected
                  ? THREE.MathUtils.degToRad(cameraSensorPreset.yaw)
                  : THREE.MathUtils.degToRad(sensor.rot_y),
                isSelected
                  ? THREE.MathUtils.degToRad(cameraSensorPreset.roll)
                  : THREE.MathUtils.degToRad(sensor.rot_z),
              ]}
              position={[
                isSelected ? cameraSensorPreset.posX : sensor.pos_x,
                isSelected ? cameraSensorPreset.posY : sensor.pos_y,
                isSelected ? cameraSensorPreset.posZ : sensor.pos_z,
              ]}
            >
              <mesh scale={[0.08, 0.06, 0.08]} position={[0, 0.1, 0]} receiveShadow>
                <planeBufferGeometry args={[5, 5]} />
                <meshBasicMaterial map={texture} side={THREE.DoubleSide} />
              </mesh>
              <mesh
                rotation-x={Math.PI * 0.6}
                rotation-y={Math.PI * 0.5}
                rotation-z={Math.PI * 0.92}
                scale={[0.6, 1.5, 1.5]}
                position={[0, 0, 2.2]}
              >
                <coneBufferGeometry args={[1, 3, 6]} Color="red" />
                <meshStandardMaterial
                  // wireframe
                  attach="material"
                  color={"white"}
                  opacity={0.5}
                  transparent
                />
              </mesh>
            </group> 
        );
      }
    });
  };

  return (
    <Fragment>
      {/* 3D model show  */}
      <primitive object={modalLoader.scene} position={[0, 0, 0]} />
      {/* Wrap ImageComponent with TransformControls */}
      {/* <TransformControls size={transformControlsSize} rotation={[Math.PI * cameraSensorPreset.pitch, Math.PI * cameraSensorPreset.roll, Math.PI * cameraSensorPreset.yaw]} enabled={false} position={[cameraSensorPreset.pos_x, cameraSensorPreset.pos_y, cameraSensorPreset.pos_z]}>
        {cameraSensorSetup()}
      </TransformControls> */}
      {renderCameraSensors()}
      {/* {renderCameraSensor()} */}
      {/* <TransformControls
        size={lidarTransformControlsSize}
        rotation={[
          Math.PI * lidarSensorPreset.pitch,
          Math.PI * lidarSensorPreset.roll,
          Math.PI * lidarSensorPreset.yaw,
        ]}
        enabled={false}
        position={[
          lidarSensorPreset.pos_x,
          lidarSensorPreset.pos_y,
          lidarSensorPreset.pos_z,
        ]}
      >
      </TransformControls> */}
        {renderLidars()}
      {renderGroundTruth()}
    </Fragment>
  );
}
