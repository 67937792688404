import { IconButton } from "@mui/material";
import React from "react";
import * as Icons from "@mui/icons-material";

function IconButtonX({ id, icon, onClick, wrapper, size, disabled }) {
	const ChoosenIcon = Icons[icon];
	return wrapper ? (
		<div className={``}>
			<IconButton id={id} onClick={onClick} size={size} disabled={disabled || false}>
				<ChoosenIcon fontSize={size}/>
			</IconButton>
		</div>
	) : (
		<IconButton id={id} onClick={onClick} size={size} disabled={disabled || false}>
			<ChoosenIcon fontSize={size} />
		</IconButton>
	);
}
export default IconButtonX;