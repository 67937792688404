import { SET_MAP_VALIDATOR, UPDATE_MAP_VALIDATOR } from ".."

export const ReduxCreateMapValidatorJourney = (pid) => {
    return {
        type: SET_MAP_VALIDATOR,
        payload: { projectId: pid }
    }
}

export const ReduxUpdateMapValidatorJourney = (obj) => {
    return {
        type: UPDATE_MAP_VALIDATOR,
        payload: obj
    }
}