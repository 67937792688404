import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddIcon from "@mui/icons-material/Add";
import { ButtonX, Typos } from "components";
import { Fragment } from "react";
import { Button } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: "auto",
  bgcolor: "primary.dark",
  borderRadius: "4px",
  p: 4,
};

export default function ConfirmationModal({
  width,
  open,
  close,
  heading,
  subheading,
  hideCloseIcon,
  confirmationMessage,
  onConfirm,
}) {
  const [windowWidth, set_windowWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    set_windowWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  if (width) {
    if (windowWidth > 768) style.width = width;
    else style.width = "95%";
  }
  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{ ...style }}>
        {heading !== "" || heading !== null || heading || undefined ? (
          <Fragment>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <Typos.H5>{heading}</Typos.H5>
                <Typos.Caption>{subheading}</Typos.Caption>
              </div>
              {hideCloseIcon ? (
                ""
              ) : (
                <CloseIcon
                  onClick={close}
                  cursor="pointer"
                  sx={{
                    position: "absolute",
                    top: "2.5rem",
                    right: "2.5rem",
                    fontSize: "1.5rem",
                  }}
                />
              )}
            </div>
            <hr />
          </Fragment>
        ) : (
          <CloseIcon
            onClick={close}
            cursor="pointer"
            sx={{
              position: "absolute",
              top: "2.5rem",
              right: "2.5rem",
              fontSize: "1.5rem",
            }}
          />
        )}
        <Fragment>
          <p>{confirmationMessage}</p>
          <div style={{ textAlign: "right" }}>
            <Button
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                color: "text.main",
                fontWeight: "bold",
                marginRight: "10px",
                width: "6rem",
                textTransform: "none",
                fontSize: "inherit",
              }}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                color: "text.main",
                fontWeight: "bold",
                marginRight: "10px",
                width: "6rem",
                textTransform: "none",
                fontSize: "inherit",
              }}
              onClick={onConfirm}
            >
              Confirm
            </Button>
          </div>
        </Fragment>
      </Box>
    </Modal>
  );
}
