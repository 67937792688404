import React from "react";
import Box from "@mui/material/Box";
import { Typography, CardContent, Grid } from "@mui/material";
import ScenariosShowing from "./scenariosShowing";

export default function ScenarioVersionList({
  versionList,
  getAutoScenarioList,
  getMyScenarioList,
  getTestValues,
}) {
  console.log("versionList", versionList);
  const list = versionList.list || [];
  console.log("versionList.key", versionList.key);
  const appKey = versionList.key;
  const selectedFunction =
    versionList.key === "autoScenario"
      ? getAutoScenarioList
      : getMyScenarioList;

  if (list.length === 0) {
    return <Typography>No data found</Typography>;
  }

  const renderContent = () => {
    return (
      <Grid className="mb-3">
        {list.map((version) => (
          <Grid
            key={version.id}
            className="mb-3"
            sx={{ maxHeight: "500px", overflow: "auto" }}
          >
            <Box sx={{ padding: "0 8px" }}>
              <ScenariosShowing
                versionId={version.version_number}
                getScenarios={selectedFunction}
                appKey={appKey}
                getTestValues={getTestValues}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box>
      <CardContent>{renderContent()}</CardContent>
    </Box>
  );
}
