import React, { Fragment, useState, useEffect } from "react";
import { Box, Grid, Radio, Button } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAutoScenarioProjectsById } from "endpointServices/autoScenarioServices";
import dateConverter from "utils/dateConverter";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { wrapText, checkDagStatus } from "utils";
import AnalyticsAutoScenarioTable from "./analyticsAutoScenarioComponent/analyticsAutoScenarioTable";

export default function AnalyticsAutoScenario() {
    const param = useParams()
    let navigator = useNavigate()
  
    const [list, setList] = useState([])
    const [isFetching, setFetching] = useState(false);
    const [totalRows, setTotalRows] = useState(0)
    const [page, setPage] = useState(1)
    const pageSize = 10

  
    useEffect(() => {
      getProjectList({pageNumber: page , testsPerPage: pageSize })
      localStorage.removeItem('p_id')
      localStorage.removeItem('pralion_id')
    }, [])
  
    useEffect(() => {
          getProjectList({pageNumber: page , testsPerPage: pageSize });
      },[page ])
  
    const getProjectList = async (pagination) => {
      setFetching(true)
      const projectId = parseInt(param.id)
      const response = await getAutoScenarioProjectsById(projectId, pagination)
      console.log('response', response)
      if (response?.status === 200) {
        setFetching(false)
        const data = response?.data?.data?.applications || []
        // const listFilter = data.filter(ele => ele.no_of_scenarios !== 0) 
        setList(data)
        setTotalRows(response?.data?.data?.no_of_applications)
      } else {
        const err = response?.message || 'Failed to get request'
        toast.error(`${err}. Please try again later`)
        setList([])
        setFetching(false)
      }
    }
  
  const handlePageChange = (event, value) => {
          setPage(value);
      }
    
  
  const goto = (data) => {
    navigator(`/projects/${param.id}/applications/scen-alytics/alytics-auto-scenario/alytics-auto-scenario-map`)
    localStorage.setItem('pralion_id', data.pralion_id)
  }
  
    const columns = [
      {
        field: "name",
        headerName: (
          <span className="heading-2">Project Name</span>
        ),
        width: 550,
        renderCell: (params) => {
          return (
            <Grid>
              <Button
                className="Main-link-simple"
                onClick={() => goto(params.row)}
                sx={{ color: "white" }}
              >
                {wrapText(params.value)}
              </Button>
            </Grid>
          );
        },
      },
      {
        field: "description",
        headerName: (
          <span className="heading-2">Description</span>
        ),
        width: 360,
      },
      {
        field: "date_created",
        headerName: (
          <span className="heading-2">Created At</span>
        ),
        width: 183,
        renderCell: (params) => <span className="row-heading">{dateConverter(params.value)}</span>,
      },
      
    ];
  
    return (
      <Fragment>
        <AnalyticsAutoScenarioTable 
          table={list} 
          headers={columns} 
          fetching={isFetching}
          totalRows={totalRows}
                  pageSize={pageSize} 
                  page={page}
                  pageHandler={handlePageChange} 
          />
      </Fragment>
    )
}
