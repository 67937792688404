import { GET_TEST_VEHICLE_PROJECT, HIGH_FIDELITY_COSIM_CLIENTS, HIGH_FIDELITY_EXECUTION_JOB, HIGH_FIDELITY_MAIN, HIGH_FIDELITY_START } from "constants/endpoints"
import { httpGet, httpPost, httpPut } from "utils/httpMethods"

export const getHighFidelityLists = async (body, pagination) => {
    const {pageNumber = 1, testsPerPage = 10 } = pagination || {}
    return await httpGet({ url: `${HIGH_FIDELITY_MAIN}?project_id=${body}&page=${pageNumber}&page_size=${testsPerPage}&order=desc`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getTestVehicleProjectsByIdHighfidelity = async (body) => {
    return await httpGet({ url: `${GET_TEST_VEHICLE_PROJECT}?project_id=${body}`, payload: body })
        .then((res) => {
            return res
            
        })
        .catch((err) => {
            return err
        })
}

export const postHighFidelityProjects = async (body) => {
    return await httpPost({ url: HIGH_FIDELITY_MAIN, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const putHighFidelity = async (body) => {
    return await httpPut({ url: HIGH_FIDELITY_MAIN, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getCosimClients = async (body) => {
    return await httpGet({ url: HIGH_FIDELITY_COSIM_CLIENTS, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const postHighFidelityStart = async (body) => {
    return await httpPost({ url: `${HIGH_FIDELITY_START}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getHighFidelityExecutionJob = async (body) => {
    return await httpGet({ url: `${HIGH_FIDELITY_EXECUTION_JOB}?pralion_id=${body}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}