import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Divider,
  List,
  ListItemButton,
  MenuItem,
  TextField,
  useAutocomplete,
} from "@mui/material";
import {
  ButtonX,
  CollapseContent,
  IconButtonX,
  ProgressX,
  Typos,
} from "components";
import { useNavigate, useParams } from "react-router-dom";
import { getMapListById } from "endpointServices/mapsServices";
import STORE from "services/redux-store/store";
import { getMapGidListByPralionId } from "endpointServices/myScenarioServices";
import EnvironmentMapOdds from "./environmentSetupComponent/environmentMapOdds";
import "./edu-agent-env-styles.css";
import { AUTHENTICATED_ROUTES } from "router/constants";

export default function EnvironmentSetup() {
  const navigate = useNavigate();
  const param = useParams();

  const [selectCategory, setSelectCategory] = useState("");
  const [mapList, setMapList] = useState([]);
  const [fetchedMapOdds, setFetchedMapOdds] = useState([]);

  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMapList, setIsLoadingMapList] = useState(false);
  const [showSearchingBar, setShowSearchingBar] = useState(false);
  const [showMapsList, setShowMapsList] = useState(true);

  const { Projects, EducAgents } = STORE.getState();
  const projectId = Projects.projectId;

  useEffect(() => {
    getMapList();
  }, []);

  const updateNextButtonState = (isChecked) => {
    setIsDisabled(!isChecked);
  };

  const handleOnChange = async (e) => {
    console.log("e", e);
    const { value } = e.target;
    setSelectCategory(value);
    await getMapOddsList(value);
  };

  const getMapOddsList = async (id) => {
    setIsLoading(true);
    const map = mapList.find((item) => item.id === id);
    if (!map) {
      setIsLoading(false);
      return;
    }
    const pralionId = map.pralion_id;
    const response = await getMapGidListByPralionId(pralionId);
    console.log("getMapGIdList response", response);
    if (response?.status === 200) {
      const { data } = response?.data;
      console.log("getMapGIdList data", data);
      setFetchedMapOdds(data);
    } else {
      console.log("error");
    }
    setIsLoading(false);
  };

  const getMapList = async () => {
    setIsLoadingMapList(true);
    try {
      const response = await getMapListById(projectId);
      if (response.status === 200) {
        const { data } = response.data;
        const filteredMap = data.applications.filter(
          (application) => application?.application_version_id !== null
        );
        setMapList(filteredMap);
        setIsLoading(false);
      } else {
        console.log("error");
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoadingMapList(false);
    }
  };

  const onClickNext = () => {
    navigate(
      AUTHENTICATED_ROUTES.educSpawningStrategies
        .replace(":id", param.id)
        .replace(":aid", param.aid)
        .replace(":oddid", EducAgents.selectedMap.gid_kwh)
    );
    // navigate(`/projects/${param.id}/applications/educ-agents/traffic-configuration`);
  };

  return (
    <Fragment>
      <div className={`container-fluid`}>
        <div className={`row`}>
          <div className={`col-lg-6 d-flex align-items-center`}>
            <TextField
              label="Select Map"
              name="selectCategory"
              type="select"
              size="small"
              variant="outlined"
              value={selectCategory}
              onChange={handleOnChange}
              sx={{ maxWidth: "23rem" }}
              helperText={"The defined Map Catalogs can only be referred"}
              fullWidth
              select
              SelectProps={{
                MenuProps: {
                  className: "customDropdown",
                },
              }}
            >
              {mapList.map((item, i) => {
                return (
                  <MenuItem key={i} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
          <div className={`col-lg-6`}>
            <div className={`d-flex justify-content-end`}>
              <Button
                sx={{
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.main",
                  },
                  color: "text.main",
                  fontWeight: "bold",
                  marginRight: "10px",
                  textTransform: "none",
                  fontSize: "inherit",
                }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <ButtonX
                className={`ms-2`}
                onClick={onClickNext}
                disabled={isDisabled}
              >
                Next
              </ButtonX>
            </div>
          </div>
        </div>
      </div>
      <EnvironmentMapOdds
        loading={isLoading}
        mapIds={fetchedMapOdds}
        updateNextButtonState={updateNextButtonState}
        columns={showMapsList ? 5 : 5}
      />
    </Fragment>
  );
}
