import { GET_PROJECT_EXEC_LIST_MYSCENARIO, GET_PROJECT_LIST_MYSCENARIO, GET_PROJECT_STATUS_MYSCENARIO, START_MY_SCENARIO,GET_OBSTACLE_LIST, GET_MAP_GID_LIST, ODD_TO_GEOJSON_KHWARIZMI_URL } from "constants/endpoints"
import { httpGet, httpPost, httpPut } from "utils/httpMethods"

export const getScenarioProjectsById = async (body,pagination ) => {
     const {pageNumber = 1, testsPerPage = 10 } = pagination || {}
    return await httpGet({ url: `${GET_PROJECT_LIST_MYSCENARIO}?project_id=${body}&page=${pageNumber}&page_size=${testsPerPage}`, payload: body })
        .then((res) => {
            return res
            
        })
        .catch((err) => {
            return err?.response
        })
}

export const createScenarioProjects = async (body) => {
    return await httpPost({ url: GET_PROJECT_LIST_MYSCENARIO, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getScenarioByPralionId = async (body) => {
    return await httpGet({ url: `${GET_PROJECT_LIST_MYSCENARIO}?pralion_id=${body}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getObstacleList = async (body) => {
    return await httpGet({ url: `${GET_OBSTACLE_LIST}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const updateScenarioProjects = async (body) => {
    return await httpPut({ url: GET_PROJECT_LIST_MYSCENARIO, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}


export const startMyScenario = async (body) => {
    return await httpPost({ url: `${START_MY_SCENARIO}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getScenarioExecListByPralionId = async (body,pagination) => {
    const {pageNumber = 1, testsPerPage = 10 } = pagination || {}
    return await httpGet({ url: `${GET_PROJECT_EXEC_LIST_MYSCENARIO}?application_version_id=${body}&page=${pageNumber}&page_size=${testsPerPage}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}


export const getScenarioStatusListByPralionId = async (pralionId) => {
    return await httpGet({ url: `${GET_PROJECT_STATUS_MYSCENARIO}?pralion_id=${pralionId}` })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.responses
        })
}

export const getMapGidListByPralionId = async (pralionId) => {
    return await httpGet({ url: `${GET_MAP_GID_LIST}?pralion_id=${pralionId}` })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.responses
        })
}

export const getMapLayers = async (gid) => {
    return await httpGet({
        url: `${ODD_TO_GEOJSON_KHWARIZMI_URL}/${gid}`,
    })
}