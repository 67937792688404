import React, { useEffect, useState } from "react";
import { Button, CardContent, Grid, TextField, Box } from "@mui/material";
import { handleSpecialCharacters, specialValue } from "utils";
import { createAutoScenarioProjects } from "endpointServices/autoScenarioServices";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AutoScenarioForm({
  handleClose,
  syncList,
  summaryData,
  isEdit,
}) {
  const param = useParams();
  const [state, setState] = useState({
    name: "",
    description: "",
    testCaseId: "",
    testCaseDescription: "",
  });
  const [loading, setLoading] = useState(false);
  const [idSpecialCharacter, setIdSpecialCharacter] = useState(false);
  const [testCaseIdSpecialCharacter, setTestCaseIdSpecialCharacter] = useState(false);

  useEffect(() => {
    if (isEdit && summaryData) {
      setState({
        name: summaryData.name,
        description: summaryData.description,
        testCaseId: summaryData.meta.test_case_id,
        testCaseDescription: summaryData.meta.test_case_description,
      });
    }
  }, [isEdit, summaryData]);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    if (name === "name" || name === "testCaseId") {
      handleSpecialCharacters(name, value, setIdSpecialCharacter, setTestCaseIdSpecialCharacter);
    }
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      project_id: parseInt(param.id),
      name: state.name,
      description: state.description,
      test_case_id: state.testCaseId,
      test_case_description: state.testCaseDescription,
    };
    console.log("payload", payload);
    const response = await createAutoScenarioProjects(payload);
    if (response?.status === 200) {
      const { data } = response;
      console.log("data", data);
      toast.success(`AutoScenario Project Created`);
      handleClose();
      setLoading(false);
      syncList();
    } else {
      const errorResponse = response;
      const err = errorResponse?.message || "Failed to submit request";
      if (errorResponse?.response.request.status === 400) {
        toast.error(errorResponse.response.data.message);
      } else {
        toast.error(`${err}. Please try again later`);
      }
      console.log("error", errorResponse);
      setLoading(false);
    }
  };

  return (
    <Box>
      <CardContent>
        <form onSubmit={onSubmitLogin}>
          <Grid item xs={6}>
            <TextField
              name="name"
              required
              type="text"
              variant="outlined"
              label="Scenario ID"
              placeholder="Enter Scenario id"
              value={state.name}
              onChange={handleInputs}
              fullWidth
              disabled={isEdit}
              inputProps={{ maxLength: 40 }}
              helperText="Enter alphanumeric characters and hyphens only"
            />
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="description"
              type="text"
              variant="outlined"
              label="Scenario Description"
              placeholder="Write description"
              value={state.description}
              onChange={handleInputs}
              fullWidth
              disabled={isEdit}
              multiline
              rows={3}
            />
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="testCaseId"
              type="text"
              variant="outlined"
              label="Test Case ID"
              placeholder="Enter case id"
              value={state.testCaseId}
              onChange={handleInputs}
              disabled={isEdit}
              fullWidth
              helperText="Enter alphanumeric characters and hyphens only"
            />
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="testCaseDescription"
              type="text"
              variant="outlined"
              label="Test Case Description"
              placeholder="Write case description"
              value={state.testCaseDescription}
              onChange={handleInputs}
              fullWidth
              disabled={isEdit}
              multiline
              rows={3}
            />
          </Grid>
          <br />
          <div className={`d-flex justify-content-end w-100`}>
            {isEdit ? '' : 
            <Button
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                color: "text.main",
                textTransform: "none",
                fontSize: "13px",
              }}
              disabled={loading || isEdit || idSpecialCharacter || testCaseIdSpecialCharacter}
              type="submit"
            >
              Submit
            </Button>
          }
          </div>
        </form>
      </CardContent>
    </Box>
  );
}
