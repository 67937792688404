import { SET_SCENE_ANALYTICS, UPDATE_SCENE_ANALYTICS } from ".."

const INITIAL_STATE = {
    projectId: null,
    pralionId: null,
    journeyType: null
}

export default function ReducerSceneAnalytics(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_SCENE_ANALYTICS:
            return action.payload;
        case UPDATE_SCENE_ANALYTICS:
            return { ...state, ...action.payload }
        default:
            return state
    }
}