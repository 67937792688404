import { AUTO_SCENARIO_MAIN, GET_CODE_FOR_OBSERVER, TRAFFIC_PROFILE_LIST,POST_CODE_FOR_OBSERVER, GET_CODE_FOR_OBSERVER_METRIC, START_AUTO_SCENARIO,GET_PROJECT_EXEC_LIST_AUTO_SCENARIO, GET_CODE_FOR_OBSERVER_RULE_METRIC } from "constants/endpoints"
import { httpGet, httpPost, httpPut } from "utils/httpMethods"

export const getAutoScenarioProjectsById = async (body, pagination) => {
    const {pageNumber = 1, testsPerPage = 10 } = pagination || {}
    return await httpGet({ url: `${AUTO_SCENARIO_MAIN}?project_id=${body}&page=${pageNumber}&page_size=${testsPerPage}`, payload: body })
        .then((res) => {
            return res
            
        })
        .catch((err) => {
            return err?.response
        })
}

export const getAutoScenarioByPralionId = async (id) => {
    return await httpGet({ url: `${AUTO_SCENARIO_MAIN}?pralion_id=${id}` })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}


export const getAutoScenarioTrafficProfileList = async (body) => {
    return await httpGet({ url: `${TRAFFIC_PROFILE_LIST}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}


export const createAutoScenarioProjects = async (body) => {
    return await httpPost({ url: AUTO_SCENARIO_MAIN, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const udateAutoScenarioProjects = async (body) => {
    return await httpPut({ url: AUTO_SCENARIO_MAIN, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getCodeForObserver = async (body) => {
    return await httpGet({ url: GET_CODE_FOR_OBSERVER, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const postCodeForObserver = async (body) => {
    return await httpPost({ url: POST_CODE_FOR_OBSERVER, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getCodeForObserverMetric = async (body) => {
    return await httpGet({ url: GET_CODE_FOR_OBSERVER_METRIC, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getCodeForObserverRuleMetric = async (body) => {
    return await httpGet({ url: GET_CODE_FOR_OBSERVER_RULE_METRIC, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}


export const createObserver = async (body) => {
    return await httpPost({ url: POST_CODE_FOR_OBSERVER, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}


export const startAutoScenario = async (body) => {
    return await httpPost({ url: START_AUTO_SCENARIO, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}


export const getAutoScenarioExecListByPralionId = async (body, pagination) => {
    const {pageNumber = 1, testsPerPage = 10 } = pagination || {}
    return await httpGet({ url:`${GET_PROJECT_EXEC_LIST_AUTO_SCENARIO}?application_version_id=${body}&page=${pageNumber}&page_size=${testsPerPage}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}