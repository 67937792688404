import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Grid, Radio, Button, MenuItem, Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getScenarioExecListByPralionId, getScenarioProjectsById } from "endpointServices/myScenarioServices";
import dateConverter from "utils/dateConverter";
import ScenarioStatus from "../../../myScenarios/scenarioComponent/scenarioStatus";
import { ModalX } from "components";
import { wrapText, checkDagStatus } from "utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { pageSize } from "utils/staticsData";
import DownloadGraph from "components/downloadGraph";
import MainTable from "components/mainTable";
import VersionList from "pages/carla/carlaComponent/versionList";

export default function CarlaMyScenario () {
  const param = useParams();
    let navigator = useNavigate();
  
    const [list, setList] = useState([]);
    const [isFetching, setFetching] = useState(false);
    const [statusOpen, setStatusOpen] = useState(false);
    const [downloadStatus, setDownloadStatus] = useState(false);
    const [versionData, setVersionData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
  
    // To Open version modal
    const [versionIds, setVersionIds] = useState([]);
    const [versionModalOpen, setVersionModalOpen] = useState(false);
  
    const [statusData, setStatusData] = useState({
      name: "",
      pralionId: "",
    });
  
    const handleMoreVertClick = (event, data) => {
      const obj = data || {};
      setVersionData(obj);
      setAnchorEl(event.target);
      setStatusData({
        name: data.name,
        pralionId: data.pralion_id,
      });
    };
  
    const handleMenuItem = (action) => {
      setSelectedAction(action);
      setAnchorEl(null);
      if (action === "Status") {
        setStatusOpen(true);
      } else if (action === "ZoneGraph") {
        setDownloadStatus(true);
      }
    };
  
    const handleVersionModal = (data) => {
      setVersionIds(data);
      setVersionModalOpen(true);
    };
  
    const handleClose = () => {
      setStatusOpen(false);
    };
  
    useEffect(() => {
      getProjectList({ pageNumber: page, testsPerPage: pageSize });
      localStorage.removeItem("p_id");
      localStorage.removeItem("pralion_id");
    }, [page]);
  
    const getProjectList = async (pagination) => {
      setFetching(true);
      const projectId = parseInt(param.id);
      const response = await getScenarioProjectsById(projectId, pagination);
      console.log("response", response);
      if (response?.status === 200) {
        setFetching(false);
        const data = response?.data?.data?.applications || [];
        // const listFilter = data.filter(ele => ele.no_of_scenarios !== 0)
        setList(data);
        setTotalRows(response?.data?.data?.no_of_applications);
      } else {
        const err = response?.message || "Failed to get request";
        toast.error(`${err}. Please try again later`);
        setList([]);
        setFetching(false);
      }
    };
  
    const handlePageChange = (event, value) => {
      setPage(value);
    };
  
    const columns = useMemo(
      () => [
        {
          field: "name",
          renderHeader: () => (
            <span className="heading-2">{"Scenario ID "}</span>
          ),
          width: 550,
          renderCell: (params) => {
            const isRowDisabled = params.row?.dag_state?.dag_state;
            return (
              <Grid>
                <Button
                  className="Main-link-simple"
                  onClick={() => handleVersionModal(params.row)}
                  sx={{ color: "white" }}
                  // disabled={checkDagStatus(isRowDisabled)}
                >
                  {wrapText(params.value)}
                </Button>
              </Grid>
            );
          },
        },
        {
          field: "date_created",
          renderHeader: () => <span className="heading-2">{"Created At"}</span>,
          width: 183,
          renderCell: (params) => (
            <span className="row-heading">{dateConverter(params.value)}</span>
          ),
        },
        {
          field: "action",
          renderHeader: () => <span className="heading-2">{"Status"}</span>,
          width: 235,
          renderCell: (params) => {
            const status = params.row?.dag_state?.dag_state;
            return (
              <>
                <div className="statusRadioButton">
                  <Radio
                    color="success"
                    checked={status === "success"}
                    sx={{
                      transform: "scale(0.6)",
                      width: "1px",
                      height: "1px",
                      "& > input": {
                        backgroundColor: "#4caf50",
                        color: "#4caf50",
                        "&:checked + span": {
                          backgroundColor: "#4caf50",
                          color: "#4caf50",
                          borderRadius: "16px",
                          width: "22px",
                          height: "22px",
                        },
                      },
                    }}
                  />
  
                  <Radio
                    checked={status === "inprogress"}
                    sx={{
                      transform: "scale(0.6)",
                      width: "1px",
                      height: "1px",
                      "& > input": {
                        backgroundColor: "#ff9800",
                        color: "#ff9800",
                        "&:checked + span": {
                          backgroundColor: "#ff9800",
                          color: "#ff9800",
                          borderRadius: "16px",
                          width: "22px",
                          height: "22px",
                        },
                      },
                    }}
                  />
                  <Radio
                    checked={status === "failed"}
                    sx={{
                      transform: "scale(0.6)",
                      width: "1px",
                      height: "1px",
                      "& > input": {
                        backgroundColor: "red",
                        color: "red",
                        "&:checked + span": {
                          backgroundColor: "red",
                          color: "red",
                          borderRadius: "16px",
                          width: "22px",
                          height: "22px",
                        },
                      },
                    }}
                  />
  
                  <Radio
                    checked={
                      status === "pending" || !params.row.dag_state.dag_state
                    }
                    sx={{
                      transform: "scale(0.6)",
                      width: "1px",
                      height: "1px",
                      "& > input": {
                        backgroundColor: "gray",
                        color: "red",
                        "&:checked + span": {
                          backgroundColor: "gray",
                          color: "gray",
                          borderRadius: "16px",
                          width: "22px",
                          height: "22px",
                        },
                      },
                    }}
                  />
                </div>
                <Button
                  sx={{ color: "text.main" }}
                  onClick={(event) => handleMoreVertClick(event, params.row)}
                >
                  <MoreVertIcon />
                </Button>
              </>
            );
          },
        },
      ],
      [list, page]
    );
  
    return (
      <Fragment>
        <MainTable
          table={list}
          headers={columns}
          fetching={isFetching}
          totalRows={totalRows}
          pageSize={pageSize}
          page={page}
          pageHandler={handlePageChange}
        />
  
        <Menu
          anchorEl={anchorEl}
          open={anchorEl}
          onClose={() => setAnchorEl(null)}
          PaperProps={{
            style: {
              backgroundColor: "#ffffff",
              color: "black",
            },
          }}
        >
          <MenuItem onClick={() => handleMenuItem("Status")}>Status</MenuItem>
          <MenuItem onClick={() => handleMenuItem("ZoneGraph")}>
            Zone Graph
          </MenuItem>
        </Menu>
  
        <ModalX
          open={versionModalOpen}
          close={() => setVersionModalOpen(false)}
          heading={"Versions Available"}
          subheading={"MyScenario"}
        >
          <VersionList
            versionList={versionIds.versions}
            handleClose={handleClose}
            getMyScenarioList={getScenarioExecListByPralionId}
          />
        </ModalX>
  
        <ModalX
          open={statusOpen}
          close={() => setStatusOpen(false)}
          heading={"Details"}
          subheading={`${statusData.name}`}
        >
          <ScenarioStatus statusObject={statusData} handleClose={handleClose} />
        </ModalX>
  
        <ModalX
          open={downloadStatus}
          close={() => setDownloadStatus(false)}
          heading={"Zone Graph Details"}
          subheading={`${statusData.name}`}
        >
          <DownloadGraph
            versionList={versionData.versions}
            handleClose={handleClose}
          />
        </ModalX>
      </Fragment>
    );
}