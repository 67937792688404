import React, {useEffect, useState, useMemo} from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Grid} from "@mui/material";
import { Link } from "react-router-dom";
import { getSynthSimData } from "endpointServices/realToSimServices";
import { wrapText } from "utils";
import dateConverter from "utils/dateConverter";


export default function SynthDataTable() {
    const [list, setList] = useState([])
    const [isFetching, setFetching] = useState(false);

    const goto = (data) => {
        window.open(data.rosbag, "_blank")
    }


    useEffect(() => {
        getAllList()
    }, [])

    const getAllList = async () => {
        setFetching(true)
        const response = await getSynthSimData()
        if (response?.status === 200) {
            setFetching(false)
            console.log('response', response)
            const lists = response?.data?.data || []
            setList(lists)
        }
        else {
            setList([])
            setFetching(false)
        }
    }

    const columns = useMemo(
      () => [
        {
          field: "name",
          renderHeader: () => (
            <span className="heading-2">{"Project Name"}</span>
          ),
          width: 750,
          renderCell: (params) => (
            <Grid>
              <Button
                className="Main-link-simple"
                onClick={() => goto(params.row)}
                sx={{ color: "white" }}
              >
                {params.value}
              </Button>
            </Grid>
          ),
        },
        {
          field: "description",
          renderHeader: () => (
            <span className="heading-2">{"Description"}</span>
          ),
          width: 360,
          renderCell: (params) => (
            <span className="row-heading">Synth data description</span>
          ),
        },
        {
          field: "date_created",
          renderHeader: () => <span className="heading-2">{"Created At"}</span>,
          width: 183,
          renderCell: (params) => (
            <span className="row-heading">{dateConverter(params.value)}</span>
          ),
        },
      ],
      [list]
    );


    return (
      <div className="mt-4">
        <DataGrid
          getRowId={(row) => row.id}
          rows={list}
          columns={columns}
          loading={isFetching}
          autoHeight={true}
          className="main-table-height"
          sx={{
            border: "none",
            borderRadius: "12px",
            backgroundColor: "secondary.dark",
            "& .MuiDataGrid-cell": {
              color: "text.main",
            },
            "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
              { marginTop: "15px" },
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
            sorting: {
              sortModel: [{ field: "date_created", sort: "desc" }],
            },
          }}
        />
      </div>
    );
}
