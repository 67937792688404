import { Typography } from '@mui/material'
import { PermScanWifiRounded as NoConnectionIcon } from '@mui/icons-material';
import React, { useEffect } from 'react'

function NoConnection() {
    useEffect(() => {
        window.ononline(() => {
            window.location.reload(true);
        })
    }, [])
    return (
        <div className={`d-flex align-items-center justify-content-center`} style={{ minHeight: '80vh' }}>
            <div className={`d-flex flex-column align-items-center`}>
                <Typography variant='h5' color="gray">
                    Error 400
                </Typography>
                <Typography variant='h3' color="gray">
                    No connection
                </Typography>
                <Typography variant='caption' color="gray">
                    Please check your internet connection
                </Typography>
                <NoConnectionIcon  fontSize='large' color='disabled'/>
            </div>
        </div>
    )
}
export default NoConnection