import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import Pagination from '@mui/material/Pagination'

export default function MapsTable({table,headers, fetching, totalRows, pageSize, page, pageHandler}) {
    console.log('table', table)
    return (
        <div className="mt-4">
            <DataGrid
                getRowId={(row) => row.id}
                rows={table}
                columns={headers}
                autoHeight={true}
                hideFooter={true}
                loading={fetching}
                sx={{
                    border: "none",
                    borderRadius: "12px",
                    backgroundColor: "secondary.dark",
                    "& .MuiDataGrid-cell": {
                        color: "text.main",
                    },
                    "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": { marginTop: "15px", }
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'date_created', sort: 'desc' }],
                    },
                  }}
            />
            <div style={{backgroundColor: 'dimgrey', height: 1}}/>
            <div style={{display: 'flex', flexDirection: 'column-reverse', alignItems:'flex-end', height: '50px', justifyContent: 'center', backgroundColor: 'rgba(32, 60, 104, 0.8)', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px'}}>
                <Pagination
                    count={Math.ceil(totalRows / pageSize)}
                    page={page}
                    onChange={pageHandler}
                />
			</div>
        </div>
    )
}
