import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Grid, Radio, Button } from "@mui/material";
import { useParams, useNavigate, Link } from "react-router-dom";
import { wrapText } from "utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getScenariosByIds } from "endpointServices/testSuitServices";
import STORE from "services/redux-store/store";
import { DataGrid } from "@mui/x-data-grid";
import { pageSize } from "utils/staticsData"
import MainTable from "components/mainTable";

export default function TestSuitsScenario() {
  const param = useParams();
  const { TestSuits } = STORE.getState();

  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [totalRows, setTotalRows] = useState(0)
	const [page, setPage] = useState(1)

  useEffect(() => {
    getProjectList({pageNumber: page, testsPerPage: pageSize });
  }, [page]);

  const getProjectList = async (pagination) => {
    setFetching(true);
    const pralionId = parseInt(TestSuits.pralionId);
    const response = await getScenariosByIds(pralionId, pagination);
    console.log("responseer", response);
    if (response?.status === 200) {
      setFetching(false);
      const data = response?.data?.data || [];
      setList(data?.records || []);
      setTotalRows(data?.count)
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setList([]);
      setFetching(false);
    }
  };

  const handlePageChange = (event, value) => {
		setPage(value);
	}

  const goto = (data) => {
    console.log("data", data);
    if (data.log_url !== null) {
      window.open(data.visualizer_url, "_blank");
    } else {
      alert("Url not found");
    }
  };

  const columns = useMemo(
    () => [
      {
        field: "name",
        renderHeader: () => <span className="heading-2">{"Name "}</span>,
        width: 550,
        renderCell: (params) => {
          return (
            <Grid>
              <Button className="Main-link-simple row-heading" sx={{ color: "white" }} onClick={() => goto(params.row)}>
                {wrapText(params.value)}
              </Button>
            </Grid>
          );
        },
      },
      {
        field: "output.MinTTC",
        renderHeader: () => <span className="heading-2">{"Min TTC (s)"}</span>,
        width: 175,
        renderCell: (params) => (
          <Grid>
            {params.row.output?.MinTTC?.toFixed(3)}
          </Grid>
        ),
      },
      {
        field: "output.MinTHW",
        renderHeader: () => <span className="heading-2">{"Min THW (s) "}</span>,
        width: 175,
        renderCell: (params) => (
          <Grid>
            {params.row.output?.MinTHW?.toFixed(3)}
          </Grid>
        ),
      },
      {
        field: "output.LJavg",
        renderHeader: () => <span className="heading-2">{"LJ Avg (m/s^3)  "}</span>,
        width: 175,
        renderCell: (params) => (
          <Grid>
            {params.row.output?.LJavg?.toFixed(3)}
          </Grid>
        ),
      },
    ],
    [list]
  );

  return (
    <Fragment>
      <MainTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
				pageSize={pageSize} 
				page={page}
        pageHandler={handlePageChange}
      />
    </Fragment>
  );
}
