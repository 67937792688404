import React, { useEffect, useState } from "react";
import {
  Button,
  CardContent,
  Grid,
  MenuItem,
  TextField,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { specialValue } from "utils";
import { postScenarioExecutionList } from "endpointServices/scenarioExecutionServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function LowFidelityForm({
  handleClose,
  syncList,
  summaryData,
  isEdit,
}) {
  const param = useParams();
  const [state, setState] = useState({
    name: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEdit && summaryData) {
      setState({
        name: summaryData.name,
        description: summaryData.description,
      });
    }
  }, [isEdit, summaryData]);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      if (!specialValue.test(value)) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      project_id: parseInt(param.id),
      name: state.name,
      description: state.description,
      // "function": selectAccCategory,
      objective: state.objective,
    };
    console.log("payload", payload);
    const response = await postScenarioExecutionList(payload);
    console.log("response", response);
    if (response?.status === 200) {
      const { data } = response;
      toast.success(`LowFidelity Project Created`);
      handleClose();
      setLoading(false);
      syncList();
    } else {
      const errorResponse = response;
      const err = errorResponse?.message || "Failed to submit request";
      if (errorResponse?.response.request.status === 400) {
        toast.error(errorResponse.response.data.message);
      } else {
        toast.error(`${err}. Please try again later`);
      }
      console.log("error", errorResponse);
      setLoading(false);
    }
  };

  return (
    <Box>
      <CardContent>
        <form onSubmit={onSubmitLogin}>
          <Grid item xs={6}>
            <TextField
              name="name"
              required
              type="text"
              variant="outlined"
              label="Execution ID"
              placeholder="Enter execution id"
              inputProps={{ maxLength: 40 }}
              value={state.name}
              disabled={isEdit}
              onChange={handleInputs}
              fullWidth
              helperText="Enter alphanumeric characters and hyphens only"
            />
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="description"
              type="text"
              variant="outlined"
              label="Execution Description"
              placeholder="Write execution description"
              value={state.description}
              onChange={handleInputs}
              disabled={isEdit}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <br />
          <div className={`d-flex justify-content-end w-100`}>
            {isEdit ? '' :
              <Button
                sx={{
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.main",
                  },
                  color: "text.main",
                  textTransform: "none",
                  fontSize: "13px",
                }}
                disabled={loading || isEdit}
                type="submit"
              >
                Submit
              </Button>
            }
          </div>
        </form>
      </CardContent>
    </Box>
  );
}
