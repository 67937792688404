import { Avatar, Tooltip } from '@mui/material'
import React from 'react'

function AvatarX({ children, width, height, size, className, color, hint }) {
	let w = width;
	let h = height;

	if (size) {
		w = size
		h = size
	}
	if (hint) {
		return <Tooltip title={hint}>
			<Avatar sx={{ width: w, height: h, bgcolor: color || "InfoBackground" }} className={className}>{children}</Avatar>
		</Tooltip>
	}
	return <Avatar sx={{ width: w, height: h, bgcolor: color || "InfoBackground" }} className={className}>{children}</Avatar>;
}
export default AvatarX