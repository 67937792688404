import React, { useState } from "react";
import { DirectionsCarFilled } from "@mui/icons-material";
import { Button, Checkbox, Grid, Typography } from "@mui/material";
import { agentsParameters } from "utils";
import { ReduxUpdateEducAgentsJourney } from "services/redux-store/actions/actions-educAgents";
import { connect } from "react-redux";
import { ButtonX } from "components";
import Segment from "../../../../../assets/images/segment.PNG";
import { useNavigate, useParams } from "react-router-dom";
import { getEgoAgents } from "../../stateMaintain";

const HyperParameterData = ({ ReduxUpdateEducAgentsJourney }) => {
  const [selectedAgent, setSelectedAgent] = useState([]);
  const [showNewGrid, setShowNewGrid] = useState(false);
  const [isDisable, setIsDisabled] = useState(true);

  const navigate = useNavigate();
  const param = useParams();

  const columnTexts = [
    "2nd left adjacent lane",
    "1st left adjacent lane",
    "ego's adjacent lane",
    "1st right adjacent lane",
    "2nd right adjacent lane",
  ];

  console.log("agentsParameters", agentsParameters);

  const handleCheck = (data) => {
    const value = data?.key;
    console.log("hyperParameterData", selectedAgent);
    selectedAgent.push(value);
    setSelectedAgent(selectedAgent);
    setIsDisabled(!selectedAgent);
    ReduxUpdateEducAgentsJourney({ hyperParameterAgent: selectedAgent });
  };

  const onClickNext = () => {
    navigate(`/projects/${param.id}/applications/educ-agents/modal-input-agents`)
  };

  return (
    <>
      <Grid container justifyContent={"start"} style={{ marginBottom: "4rem" }}>
        <ButtonX
          className={`me-2`}
          variant={showNewGrid === false ? "contained" : "outlined"}
          onClick={() => setShowNewGrid(false)}
        >
          1-15 Attributes
        </ButtonX>
        <ButtonX
          onClick={() => setShowNewGrid(true)}
          variant={showNewGrid === false ? "outlined" : "contained"}
        >
          Segment
        </ButtonX>

      </Grid>
      <Grid container justifyContent={"end"}>
        <ButtonX className={`me-2`} onClick={() => navigate(-1)}>
          Back
        </ButtonX>
        <ButtonX onClick={onClickNext} disabled={isDisable}>Next</ButtonX>
      </Grid>
      {showNewGrid ? (
        <Grid container spacing={5} justifyContent="center" alignItems="center">
          <Grid item>
            <img
              src={Segment}
              alt="Segment Image"
              style={{ maxWidth: "100%", marginTop: "-2rem" }}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} style={{ marginLeft: "5px" }}>
          {agentsParameters.map((agent, index) => (
            <Grid
              item
              key={agent.id}
              xs={2.2}
              sx={{
                borderLeft:
                  index < agentsParameters.length ? "1px dashed red" : "none",
                borderRight:
                  index < agentsParameters.length ? "1px dashed red" : "none",
                paddingRight: "20px",
                position: "relative",
              }}
            >
              {index < agentsParameters.length - 10 && (
                <Typography
                  variant="body1"
                  sx={{
                    position: "absolute",
                    top: "-1%",
                    transform: "translateY(-50%)",
                    right: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {columnTexts[index]}
                </Typography>
              )}
              <Grid
                container
                sx={{
                  backgroundColor:
                    agent.key === "ego" ? "#2c5c61" : "secondary.dark",
                  borderRadius: "6px",
                  minWidth: "80px",
                  height: "7rem",
                  m: 1,
                  position: "relative",
                }}
                justifyContent="center"
                alignItems="center"
              >
                <div
                  style={{
                    backgroundColor: "#00A3B5",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "2rem",
                    borderTopLeftRadius: "6px",
                    borderBottomRightRadius: "6px",
                  }}
                >
                  {agent.id}
                </div>
                <Checkbox
                  onChange={() => handleCheck(agent)}
                  checked={agent.key === "ego" ? true : false}
                  sx={{ position: "absolute", top: 0, right: 0 }}
                  disabled={!agent.disable}
                />
                <DirectionsCarFilled style={{ fontSize: "5rem" }} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
      ;
    </>
  );
};
export default connect(null, { ReduxUpdateEducAgentsJourney })(
  HyperParameterData
);
