import { ButtonX, CollapseContent, IconButtonX, Typos } from 'components';
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { INSTRUCTIONS, mapTypes } from "utils";
import "./rate-based-map-styles.css";
import { connect } from 'react-redux';
import { DEFAULT_CIRCLE_LAYER, DEFAULT_LINE_LAYER, DEFAULT_POINT_LAYER, DEFAULT_POLYGON_LAYER } from 'constants/defaults';
import { Slider, TextField, Tooltip } from '@mui/material';
import { ReduxUpdateDSpaceJourney } from 'services/redux-store/actions/actions-dspace';
import { ODD_TO_GEOJSON } from 'constants/endpoints';
import { httpPost } from 'utils/httpMethods';
import { createRoot } from 'react-dom/client';
import { AUTHENTICATED_ROUTES } from 'router/constants';
import { ArrowRightRounded as InstructionItemIcon } from '@mui/icons-material';
import Draggable from 'react-draggable';

function RateBased({ ReduxDSpace, ReduxUpdateDSpaceJourney }) {
	const navigate = useNavigate();
	const mapContainer = useRef(null);
	const map = useRef(null);

	const [lng, setLng] = useState(7.42446); //7.42446, 51.580984
	const [lat, setLat] = useState(51.580984);
	const [zoom, setZoom] = useState(15);
	
	const [initiallng, setInitialLng] = useState(7.42446); //7.42446, 51.580984
	const [initiallat, setInitialLat] = useState(51.580984);
	const [initialzoom, setInitialZoom] = useState(15);

	const [enteringRate, setEnteringRate] = useState({
		status: false,
		callback: null
	});
	const rateTextField = useRef("");
	const savedMarkers = useRef([]);
	const [showInfoPanel, setShowInfoPanel] = useState(true);

	const loadMapLayers = async () => {
		await fetch(ReduxDSpace.geojsonMapIdAndOddPolygon)
			.then((data) => data.json())
			.then((json) => {
				console.log("json", json);
				map.current.addSource("polygons", {
					type: "geojson",
					data: json,
					generateId: true
				});
				map.current.addLayer(DEFAULT_POLYGON_LAYER("route-polygons", "polygons", "rgba(0, 163, 181, 0.7)"));
				map.current.setCenter(json["features"][0]["geometry"]["coordinates"][0][0]);
				setInitialLng(json["features"][0]["geometry"]["coordinates"][0][0][0]);
				setInitialLat(json["features"][0]["geometry"]["coordinates"][0][0][1]);
				map.current.setZoom(zoom);
				map.current.on("click", "route-polygons", onClickPolygon);
			});
	}

	useEffect(() => {
		if (!map.current) {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: mapTypes.streetView,
				center: [lng, lat],
				zoom: zoom,
			});
			map.current.on("load", () => {
				map.current.on("move", handleOnMapMove);
				loadMapLayers();
			});
		}
	}, [ReduxDSpace]);

	const handleOnMapMove = (e) => {
		setLng(map.current.getCenter().lng.toFixed(4));
		setLat(map.current.getCenter().lat.toFixed(4));
		setZoom(map.current.getZoom().toFixed(2));
	};

	const _addRateIcon = (lnglat, rate, polygon, destination = map.current) => {
		if (rateTextField.current !== "") {
			const element = (
				<div className={``}>
					{rate}
				</div>
			);
			const ref = React.createRef();
			ref.current = document.createElement("div");
			const root = createRoot(ref.current);
			root.render(element);
			const newMarker = new mapboxgl.Marker(ref.current, {
				color: "#000",
				// draggable: true,
			})
				.setLngLat(lnglat)
				.addTo(destination);
			savedMarkers.current.push({
				lnglat,
				polygon,
				rate
			})
			// _saveMarkerInfo(lnglat, polygon, "acceleration", newMarker, { acceleration });
			rateTextField.current = "";
		}
	};

	const onClickPolygon = (e) => {
		console.log("e polygon", e.features);
		const polygon = e.features[0]
		if (savedMarkers.current.some(obj => obj.polygon.id === polygon.id)) {
			return
		}
		setEnteringRate({
			status: true,
			callback: () => _addRateIcon(e.lngLat, rateTextField.current, polygon)
		});
		
		
	};

	const onClickNext = () => {
		ReduxUpdateDSpaceJourney({ choosenStrategy: "rate-based", rateBased: { savedMarkers: savedMarkers.current } });
		navigate(AUTHENTICATED_ROUTES.dSpaceTrafficConfig)
	};

	const onRateTextFieldChange = (e) => {
		rateTextField.current = e.target.value;
	}

	const onClickSaveRateButton = () => {
		enteringRate.callback()
		setEnteringRate({ status: false, callback: null })
	}

	const onClickRecenter = () => {
		map.current.setCenter([initiallng, initiallat]);
		map.current.setZoom(initialzoom);
	};

	return (
		<div className={``}>
			<div className={`container-fuild`}>
				<div className={`row`}>
					<div className={`col-12 d-flex justify-content-end`}>
						<div className={`d-flex`}>
							<ButtonX onClick={() => navigate(-1)} variant={"outlined"}>
								Back
							</ButtonX>
							<ButtonX
								className={`ms-2`}
								onClick={onClickNext}
								disabled={savedMarkers.current.length === 0}
								tooltip={savedMarkers.current.length === 0 ? "Please add Rate on a polygon to continue" : "Continue to set driving attributes"}
							>
								Next
							</ButtonX>
						</div>
					</div>
				</div>
				<div className={`map-container mt-3`}>
					<div className={`rbs-title-bar`}>
						<Typos.H6>Rate Based Spawning</Typos.H6>
					</div>
					{/* <div className="sidebar">
						Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
					</div> */}
					<div className="rbs-left-panel">
						<div className={`d-flex mb-2`}>
							<ButtonX className={"me-2"} size={"small"} onClick={onClickRecenter} variant={"outlined"} color={"inherit"}>
								Re-Center
							</ButtonX>
							<IconButtonX icon={"InfoRounded"} onClick={() => setShowInfoPanel(!showInfoPanel)} />
						</div>
						<Draggable>
							<div className="rbs-left-info-panel">
								<CollapseContent show={showInfoPanel} orientation={"vertical"}>
									<div className="px-3 py-2">
										<div className="d-flex justify-content-between align-items-center">
											<Typos.H6>Instructions</Typos.H6>
											<IconButtonX icon={"ClearRounded"} onClick={() => setShowInfoPanel(false)} />
										</div>
										<hr />
										{INSTRUCTIONS.map((item, i) => {
											return (
												<div className="row" key={i}>
													<div className="col-1">
														<InstructionItemIcon />
													</div>
													<div className="col-11">
														<Typos.Body1>{item.text}</Typos.Body1>
													</div>
												</div>
											);
										})}
									</div>
								</CollapseContent>
							</div>
						</Draggable>
					</div>
					{enteringRate.status && (
						<div className={`rbs-right-panel text-center`}>
							<Typos.Body1>Choose Rate (veh/h)</Typos.Body1>
							<hr />
							<Slider
								marks={[
									{ value: 1, label: "1" },
									{ value: 3000, label: "3000" },
								]}
								min={1}
								max={3000}
								onChange={onRateTextFieldChange}
								valueLabelDisplay="on"
							/>
							{/* <TextField label="Rate" variant="outlined" fullWidth className={`mb-3`} onChange={onRateTextFieldChange} /> */}
							<ButtonX size={"small"} fullwidth={true} onClick={onClickSaveRateButton}>
								Add
							</ButtonX>
						</div>
					)}
					<div ref={mapContainer} className="map-view" />
				</div>
			</div>
		</div>
	);
}
function mapStateToProps(state) {
	return {
		ReduxDSpace: state.DSpace
	}
}
export default connect(mapStateToProps, { ReduxUpdateDSpaceJourney })(RateBased);