import React from "react";
import Box from "@mui/material/Box";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardContent,
  Button,
} from "@mui/material";

export default function GetVersionList ({
  versionList,
  executionList,
}) {
  const list = versionList || [];
  const renderContent = () => {
    return (
      <div>
        <div className="status-table-data mb-3">
          <TableContainer>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Version Number</TableCell>
                  <TableCell>Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list &&
                  list.map((version) => (
                    <TableRow key={version.id}>
                      <TableCell>
                        <Button
                          onClick={() => {
                            executionList(version.version_number);
                          }}
                          sx={{ color: "white" }}
                        >
                          {version.version_number}
                        </Button>
                      </TableCell>
                      <TableCell>{version.date_created}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  };

  return (
    <Box>
      <CardContent>{renderContent()}</CardContent>
    </Box>
  );
};
