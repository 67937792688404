import { SET_AUTOSCENARIO, UPDATE_AUTOSCENARIO,GET_AUTOSCENARIO } from ".."

const INITIAL_STATE = {
    pralionId: null,
    projectName: null,
    mapGenObject: null,
    trafficPreset: null,
    testVehicle: null,
    mapGeometry: null,
    dynamicActorForm: null,
    observerId: null,
    oddData: null,
    trafficConfig: null,
    
}

export default function ReducerAutoScenario(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_AUTOSCENARIO:
            return action.payload;
        case UPDATE_AUTOSCENARIO:
            return { ...state, ...action.payload }
        case GET_AUTOSCENARIO:
            return action.payload
        default:
            return state;
    }
}