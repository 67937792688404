import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import execute from "assets/images/exe.png";
import { ExceptionHandlerModal } from "components/exceptionHandlerModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CloseIcon from "@mui/icons-material/Close";
import STORE from "services/redux-store/store";
import {
  getCosimClients,
  postHighFidelityStart,
  putHighFidelity,
} from "endpointServices/highFidelityServices";
import { ReduxUpdateHighFidelityJourney } from "services/redux-store/actions/actions-highFidelity";
import { connect } from "react-redux";
import {
  weatherConditionsCategory,
  weatherSeverityConditionsCategory,
} from "utils";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import ConfirmationModal from "components/confirmationModal";

const HighFidelityExecutionSetting = ({
  ReduxHighFidelity,
  ReduxUpdateHighFidelityJourney,
}) => {
  const param = useParams();
  const navigator = useNavigate();
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState("");
  const [selectClient, setSelectClient] = useState([]);
  const [selectWeatherConditions, setSelectWeatherConditions] = useState([]);
  const [selectWeatherSeverityConditions, setSelectWeatherSeverityConditions] =
    useState([]);
  const [time, setTime] = useState("");
  const [checkDataStorage, setCheckDataStorage] = useState(true);
  const [checkDesktopMachine, setCheckDesktopMachine] = useState(true);
  const { Projects, HighFidelity } = STORE.getState();
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isTimeValid, setTimeValid] = useState(false);
  const [streamDump, setStreamDump] = useState(true);
  const [planningModule, setPlanningModule] = useState(true);
  const [perceptionModule, setPerceptionModule] = useState(true);
  const [visualizaion, setVisualizaion] = useState(true);
  const [uploadStreamDump, setUploadStreamDump] = useState(false);
  const [uploadPlanningModule, setUploadPlanningModule] = useState(false);
  const [uploadPerceptionModule, setUploadPerceptionModule] = useState(false);
  const [uploadVisualizaion, setUploadVisualizaion] = useState(true);

  console.log("selectClient", selectClient);
  useEffect(() => {
    getClientsList();
  }, []);

  useEffect(() => {
    checkFields();
  }, [
    selectWeatherConditions,
    selectWeatherSeverityConditions,
    time,
    checkDesktopMachine,
    client,
  ]);

  const handleOpenModal = () => {
    setOpenConfirmationModal(true);
  };

  const closeConfirmationModal = () => setOpenConfirmationModal(false);

  const handleConfirmation = () => {
    navigator(
      `/projects/${Projects.projectId}/applications/high-fidelity/define-rule`
    );
  };

  const handleWeatherConditions = (event) => {
    const { value } = event.target;
    setSelectWeatherConditions(value);
    ReduxUpdateHighFidelityJourney({ precipitation: value });
  };

  const handleWeatherSeverityConditions = (event) => {
    const { value } = event.target;
    setSelectWeatherSeverityConditions(value);
    ReduxUpdateHighFidelityJourney({ precipitationRate: value });
  };

  const handleDateTimeChange = (field, value) => {
    const formattedTime = dayjs(value).format("HH:mm");
    setTime(formattedTime);
    ReduxUpdateHighFidelityJourney({ time: formattedTime });

    // Validate the time input
    const [hours, minutes] = formattedTime.split(":");
    if (minutes === "00") {
      setTimeValid(true);
    } else {
      setTimeValid(false);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    console.log("value", value);
    setClient(value);
    ReduxUpdateHighFidelityJourney({ desktopClientId: value });
  };

  const handleDataStorageCheckboxChange = () => {
    const value = !checkDataStorage;
    setCheckDataStorage(value);
    ReduxUpdateHighFidelityJourney({ dataStorage: value });
  };

  const handleStreamDumpCheckboxChange = () => {
    const value = !streamDump;
    setStreamDump(value);
  };

  const handlePlanningModuleCheckboxChange = () => {
    const value = !planningModule;
    setPlanningModule(value);
  };

  const handlePerceptionModuleCheckboxChange = () => {
    const value = !perceptionModule;
    setPerceptionModule(value);
  };

  const handleVisualizaionCheckboxChange = () => {
    const value = !visualizaion;
    setVisualizaion(value);
  };

  const handleUploadStreamDumpCheckboxChange = () => {
    const value = !uploadStreamDump;
    setUploadStreamDump(value);
  };

  const handleUploadPlanningModuleCheckboxChange = () => {
    const value = !uploadPlanningModule;
    setUploadPlanningModule(value);
  };

  const handleUploadPerceptionModuleCheckboxChange = () => {
    const value = !uploadPerceptionModule;
    setUploadPerceptionModule(value);
  };

  const handleUploadVisualizaionCheckboxChange = () => {
    const value = !uploadVisualizaion;
    setUploadVisualizaion(value);
  };

  const handleDesktopChange = () => {
    const value = !checkDesktopMachine;
    setCheckDesktopMachine(value);
  };

  const getClientsList = async () => {
    const response = await getCosimClients();
    console.log("response", response);
    if (response?.status === 200) {
      const list = response.data.data || [];
      setSelectClient(list);
    } else {
      console.log("error");
    }
  };

  const checkFields = () => {
    if (
      selectWeatherConditions.length &&
      selectWeatherSeverityConditions.length &&
      time &&
      !checkDesktopMachine &&
      client
    ) {
      setAllFieldsFilled(true);
    } else {
      setAllFieldsFilled(false);
    }
  };

  console.log("HighFidelity", HighFidelity);

  const onExecute = async () => {
    setLoading(true);
    const payload = {
      data_storage: ReduxHighFidelity?.dataStorage,
      test_vehicle_id: HighFidelity?.vehicleProperties?.pralion_id,
      test_suite_id: HighFidelity?.vehicleSuits?.application_version_id,
      pralion_id: HighFidelity?.pralionId,
      observer_id: HighFidelity?.observerId,
      desktop_client_id: client,
      environment: {
        time_of_day: ReduxHighFidelity?.time,
        precipitation: ReduxHighFidelity?.precipitation,
        precipitation_rate: ReduxHighFidelity?.precipitationRate,
      },
      items_to_process: {
        osi3: streamDump,
        planning_ros2_bag: uploadPlanningModule,
        perception_ros2_bag: uploadPerceptionModule,
        aai_ros1_viz: visualizaion,
      },
    };
    console.log("payload", payload);
    const response = await putHighFidelity(payload);
    if (response?.status === 200) {
      const secondPayload = {
        pralion_id: HighFidelity.pralionId,
      };
      const secondResponse = await postHighFidelityStart(secondPayload);
      if (secondResponse?.status === 200) {
        console.log("secondResponse", secondResponse);
        toast.success(`Successfuly started execution-setting generate`);
        setTimeout(() => {
          setLoading(false);
          navigator(
            `/projects/${Projects.projectId}/applications/high-fidelity`
          );
        }, 2000);
      } else {
        setLoading(false);
        const errr = secondResponse?.response?.data?.message;
        console.log("errr", errr);
        toast.error(
          `Error! Failed to start execution-setting. Please see the logs on high-fidelity project listing page`
        );
      }
    } else {
      setLoading(false);
      const errr = response?.response?.data?.message;
      console.log(errr);
      if (response?.status === 500) {
        toast.error(
          `Error! Failed to start auto-scenario. Please see the logs`
        );
      }
      toast.error(`${errr}. Please try again later`);
      // const err = response?.message || 'Failed to update. Please see the logs'
    }
  };

  return (
    <>
      <ConfirmationModal
        open={openConfirmationModal}
        heading={<span>Confirm Action</span>}
        subheading={<span>Going back will discard unsaved changes</span>}
        close={closeConfirmationModal}
        confirmationMessage={
          <span>
            Are you sure you want to go back? If you go back, your changes will
            be lost.
          </span>
        }
        onConfirm={handleConfirmation}
      />
      <ExceptionHandlerModal applicationName={"MyScenarios"} />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} justifyContent="end">
          <Grid item>
            <Button
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                color: "text.main",
                width: "200px",
                marginRight: "10px",
              }}
              onClick={handleOpenModal}
            >
              Back
            </Button>
            <Button
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                color: "text.main",
                width: "200px",
                float: "right",
              }}
              type="submit"
              disabled={loading || !allFieldsFilled || !isTimeValid}
              onClick={onExecute}
            >
              Execute
            </Button>
          </Grid>
        </Grid>
      </Box>
      <div>
        <Divider style={{ marginTop: "1rem" }} />
      </div>
      <Grid
        container
        sx={{
          marginTop: "2rem",
        }}
      >
        <span>
          <img
            src={execute}
            style={{ width: "100%", height: "50rem", marginBottom: 0 }}
            alt="icons"
          />
          <div className={`execution-setting-logs-sidebar `}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h5 className={"execution-setting-h5"}>Execution Setting</h5>
              {/* <CloseIcon
                cursor="pointer"
                sx={{
                  position: "absolute",
                  // top: "3.5rem",
                  right: "2.5rem",
                  fontSize: "1.5rem",
                }}
              /> */}
            </div>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                sx={{ marginLeft: "10px", width: "29rem", marginTop: "1rem" }}
                value={time ? dayjs(time, "HH:mm") : null}
                ampm={false}
                minutesStep={60}
                onChange={(newValue) => handleDateTimeChange("time", newValue)}
                label="Time of Day"
              />
            </LocalizationProvider>
            <FormControl
              sx={{ marginLeft: "10px", width: "14rem", marginTop: "1rem" }}
            >
              <TextField
                label="Weather Condition"
                name="weather condition"
                variant="outlined"
                value={selectWeatherConditions}
                onChange={handleWeatherConditions}
                fullWidth
                disabled={loading}
                select
                SelectProps={{
                  MenuProps: {
                    className: "customDropdown",
                  },
                }}
              >
                {weatherConditionsCategory.map((item, id) => (
                  <MenuItem key={id} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl
              sx={{ marginLeft: "10px", width: "14.3rem", marginTop: "1rem" }}
            >
              <TextField
                label="Weather Severity Condition"
                name="weather severity condition"
                variant="outlined"
                value={selectWeatherSeverityConditions}
                onChange={handleWeatherSeverityConditions}
                fullWidth
                disabled={loading}
                select
                SelectProps={{
                  MenuProps: {
                    className: "customDropdown",
                  },
                }}
              >
                {weatherSeverityConditionsCategory.map((item, id) => (
                  <MenuItem key={id} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <h6 className="execution-setting-h6">Test Execution Environment</h6>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <Grid xs={6}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Cloud"
                    disabled
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={checkDesktopMachine}
                        onChange={handleDesktopChange}
                      />
                    }
                    label="Desktop Machines"
                  />
                </FormGroup>
              </Grid>
              <Grid xs={6}>
                <FormControl
                  sx={{ width: "14rem", marginTop: "2rem" }}
                >
                  <TextField
                    labelId="demo-simple-select-label"
                    disabled={checkDesktopMachine}
                    id="demo-simple-select"
                    type="select"
                    size="small"
                    variant="outlined"
                    value={client}
                    onChange={handleChange}
                    sx={{
                      border: "1px solid #00A3B5",
                      backgroundColor: "#24262b",
                    }}
                    fullWidth
                    select
                    SelectProps={{
                      MenuProps: {
                        className: "customDropdown",
                      },
                    }}
                  >
                    {selectClient.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.client_name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              </Grid>
            </div>
            <h6 className="execution-setting-h6">
              Data Processing - RR Cosim Client
            </h6>
            <div
              style={{
                marginLeft: "10px",
              }}
            >
              <FormGroup>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      sx={{ display: "block" }}
                      control={
                        <Checkbox
                          checked={streamDump}
                          onChange={handleStreamDumpCheckboxChange}
                          disabled
                        />
                      }
                      label="OSI3 Stream Dump"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={uploadPlanningModule}
                          onChange={handleUploadPlanningModuleCheckboxChange}
                          // disabled
                        />
                      }
                      label={
                        <div style={{ marginTop: "21px" }}>
                          Bag Dump for Planning Module
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                  <FormControlLabel
                      sx={{ marginTop: "-14px" }}
                      control={
                        <Checkbox
                          checked={uploadPerceptionModule}
                          onChange={handleUploadPerceptionModuleCheckboxChange}
                          // disabled
                        />
                      }
                      label={
                        <div style={{ marginTop: "21px" }}>
                          Bag Dump for Perception Module
                        </div>
                      }
                    />
                    <FormControlLabel
                      sx={{ marginTop: "-14px" }}
                      control={
                        <Checkbox
                          checked={visualizaion}
                          onChange={handleVisualizaionCheckboxChange}
                          disabled
                        />
                      }
                      label={
                        <div style={{ marginTop: "21px" }}>
                          Bag Dump for AAI Visualizaion
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </div>
         
          </div>
        </span>
      </Grid>
    </>
  );
};

function getStateToProps(state) {
  return {
    ReduxHighFidelity: state.HighFidelity,
  };
}

export default connect(getStateToProps, { ReduxUpdateHighFidelityJourney })(
  HighFidelityExecutionSetting
);
