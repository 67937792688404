import React, { useState } from 'react'
import { Grid, Typography, Box, Slider, FormControlLabel, Checkbox, TextField, Button } from '@mui/material'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { autoScenarioMainState, autoScenarioPlaceState, getDynamicActors } from '../../stateMaintain';

export default function DynamicActorForm() {
    const [state, setState] = useState({
        perceptionRange: 0,
        amount: 200,
        seedValue: -1,
        egoDensity: 50
    })

    const [onComingTraffic, setOnComingTraffic] = useState(false)
    const [isRandomMode, setIsRandomMode] = useState(false)

    const [selectTrafficVehicleProfile, setSelectTrafficVehicleProfile] = useState([])
    const [selectedTrafficVehicleProfile, setSelectedTrafficVehicleProfile] = useState([])
    const [trafficVehicleProfile, setTrafficVehicleProfile] = useState(
        [
            {
                "base_vehicle_profile_id": 17,
                "name": "Honda-Life",
                "cda": 0.36,
                "maximum_speed": 150
            },
            {
                "base_vehicle_profile_id": 65,
                "name": "Toyota-Camry",
                "cda": 0.62,
                "maximum_speed": 250
            },
            {
                "base_vehicle_profile_id": 66,
                "name": "Toyota-Corolla-Hatchback-2019",
                "cda": 0.58,
                "maximum_speed": 200
            },
            {
                "base_vehicle_profile_id": 67,
                "name": "Toyota-Prius-2019",
                "cda": 0.58,
                "maximum_speed": 160
            },
            {
                "base_vehicle_profile_id": 4,
                "name": "Audi S5 2012",
                "cda": 0.62,
                "maximum_speed": 209
            },
        ])

    const handleSelectState = (event) => {
        const { name, value } = event.target
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }
    const handleSelectTrafficMultiple = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        setSelectTrafficVehicleProfile(value);
        const list = trafficVehicleProfile.filter(item => value.includes(item.name))
        setSelectedTrafficVehicleProfile(list)
    };

    const onSaveData = () => {
        const payload = {
            "ego": {
                "perception_range": state.perceptionRange,
                "oncoming_traffic": onComingTraffic
            },
            "traffic": {
                "amount": 200,
                "seed_value": -1,
                "random_mode": false,
                "density_around_ego": 50,
                "vehicle_profiles":  [
                    {
                        "base_vehicle_profile_id": 17,
                        "name": "Honda-Life",
                        "cda": 0.36,
                        "maximum_speed": 150
                    },
                    {
                        "base_vehicle_profile_id": 65,
                        "name": "Toyota-Camry",
                        "cda": 0.62,
                        "maximum_speed": 250
                    },
                    {
                        "base_vehicle_profile_id": 66,
                        "name": "Toyota-Corolla-Hatchback-2019",
                        "cda": 0.58,
                        "maximum_speed": 200
                    },
                    {
                        "base_vehicle_profile_id": 67,
                        "name": "Toyota-Prius-2019",
                        "cda": 0.58,
                        "maximum_speed": 160
                    },
                    {
                        "base_vehicle_profile_id": 4,
                        "name": "Audi S5 2012",
                        "cda": 0.62,
                        "maximum_speed": 209
                    },
                ]
            }
        }
        getDynamicActors(payload)
    }

    console.log('autoScenarioMainState', autoScenarioMainState)

    return (
        <Grid
            sx={{
                backgroundColor: "secondary.dark",
                paddingBottom: "12px",
                borderRadius: "8px",
            }}
        >
            <Grid className="vehicle-details-table-headings">
                <Typography
                    color="text.main"
                    sx={{ marginLeft: "1rem", marginTop: "1.5rem" }}
                >
                    Ego
                </Typography>

            </Grid>
            <Grid className="vehicle-details-table-data">
                <Box p={2} >
                    <Typography gutterBottom>Perception Range</Typography>
                    <Slider
                        size="small"
                        defaultValue={70}
                        value={state.perceptionRange}
                        onChange={handleSelectState}
                        name="perceptionRange"
                        aria-label="Small"
                        valueLabelDisplay="auto"
                    />
                    <FormControlLabel control={<Checkbox name={"onComingTraffic"} value={onComingTraffic} onChange={() => setOnComingTraffic(!onComingTraffic)} />} label="On Coming Traffic" />
                </Box>
            </Grid>

            <Grid className="vehicle-details-table-headings">
                <Typography
                    color="text.main"
                    sx={{ marginLeft: "1rem", marginTop: "0.5rem" }}
                >
                    Traffic
                </Typography>

            </Grid>
            <Grid className="vehicle-details-table-data">
                <Box p={2} >
                    <TextField
                        sx={{ marginBottom: '1em' }}
                        name="amount"
                        required
                        type="number"
                        variant="outlined"
                        label="Amount"
                        placeholder="Enter amount"
                        value={state.amount}
                        onChange={handleSelectState}
                        fullWidth
                        disabled={false}
                    />
                    <TextField
                        sx={{ marginBottom: '1em' }}
                        name="seedValue"
                        required
                        type="number"
                        variant="outlined"
                        label="Seed Value"
                        placeholder="Enter seed value"
                        value={state.seedValue}
                        onChange={handleSelectState}
                        fullWidth
                        disabled={false}
                    />
                    <TextField
                        sx={{ marginBottom: '1em' }}
                        name="egoDensity"
                        required
                        type="number"
                        variant="outlined"
                        label="Ego Density"
                        placeholder="Enter ego density"
                        value={state.egoDensity}
                        onChange={handleSelectState}
                        fullWidth
                        disabled={false}
                    />
                    <FormControlLabel
                        sx={{ marginBottom: '1em' }}
                        control={<Checkbox
                            value={isRandomMode}
                            onChange={() => setIsRandomMode(!isRandomMode)}
                        />} label="Random Mode"
                    />
                    <FormControl sx={{ minWidth: "100%" }}>
                        <Typography
                            color="text.main"
                            sx={{ marginBottom: "0.5rem" }}
                        >
                            Vehicle Profile
                        </Typography>

                        <Select
                            name="selectTrafficVehicleProfile"
                            multiple
                            native
                            value={selectTrafficVehicleProfile}
                            onChange={handleSelectTrafficMultiple}
                            sx={{
                                "& option:checked": {
                                    color: "text.main",
                                    backgroundColor: "primary.dark",
                                },
                            }}
                        >
                            {trafficVehicleProfile.map((item, id) => (
                                <option key={id} value={item.name} style={{ padding: "6px" }}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box p={2} display="flex" justifyContent="center">
                    <Button
                        sx={{
                            backgroundColor: "primary.main",
                            "&:hover": {
                                backgroundColor: "primary.main",
                            },
                            color: "text.main",
                            width: "200px",
                        }}
                        disabled={false}
                        type="submit"
                        onClick={onSaveData}
                    >
                        Save
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}
