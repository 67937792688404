import { LOGIN_ENDPOINT, PASSWORD_FORGOT_ENDPOINT, REGISTRATION_ENDPOINT, Logout } from "constants/endpoints"
import { authHttpPost, httpPost } from "utils/httpMethods"
import { GetInfoFromLocalStorage } from "utils/functions";


export const AUTH_LOGIN_SERVICE = async (body) => {
    return await authHttpPost({ url: LOGIN_ENDPOINT, payload: body })
        .then((res) => {
            console.log('res', res)
            return res
        })
        .catch((err) => {
            console.log('err', err)
            return err?.response
        })
}

export const AUTH_SIGNUP_SERVICE = async (body) => {
    return await authHttpPost({ url: REGISTRATION_ENDPOINT, payload: body })
        .then((res) => {
            console.log('res', res)
            return res
        })
        .catch((err) => {
            console.log('err', err)
            return err?.response
        })
}

export const AUTH_FORGOT_PASSWORD_SERVICE = async (body) => {
    return await authHttpPost({ url: PASSWORD_FORGOT_ENDPOINT, payload: body })
        .then((res) => {
            console.log('res', res)
            return res
        })
        .catch((err) => {
            console.log('err', err)
            return err?.response
        })
}

export const LOGOUT_SERVICE = async() => {
    const {user_id, access_token } = GetInfoFromLocalStorage('aai_user')
    return await httpPost({ url: Logout, 
      payload : {user_id: user_id, access_token: access_token}})
      .then(() => {
				localStorage.removeItem('aai_user')
                window.location.href = window.location.origin + '/auth'
			})
			.catch((err) => {
				console.error(err)
			})
}