import { Box, } from '@mui/material';
import React from 'react';
import { renderMyMapGuide, renderOddGuide, renderTestVehicleGuide } from 'utils/guidesElements';


export default function InstructionGuide({type}) {
  const renderGuides = () => {
    const appType = type
    if(appType === 'testVehicle') {
      return  renderTestVehicleGuide()
    }
    else if(appType === 'odd') {
      return renderOddGuide()
    }
    else {
      return renderMyMapGuide()
    } 
  }


  return (
    <Box className="modal-content mt-4">
      {renderGuides()}
    </Box>
  );
}
