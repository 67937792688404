import { ButtonX, CollapseContent, IconButtonX, ModalX, ReadOnlyTag, Typos } from "components";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { TRAFFIC_CONFIG_INSTRUCTIONS, mapTypes } from "utils";
import "./as-traffic-config.css";
import { httpGet } from "utils/httpMethods";
import { GET_AUTOSCENARIO_VEHICLE_LIST, GET_VEHICLE_COMPOSITION_LIST } from "constants/endpoints";
import { ReduxUpdateAutoScenarioJourney } from "services/redux-store/actions";
import { connect } from "react-redux";
import TextFieldWithEndButton from "components/text-field-with-end-button";
import { AUTHENTICATED_ROUTES } from "router/constants";
import Draggable from "react-draggable";
import { ArrowRightRounded as InstructionItemIcon } from "@mui/icons-material";
import VehicleComposition from "./vehicle-composition";
import DriverProfiles from "./driver-profiles";
import AlgorithmSettings from "./algorithm-settings";
import { Slider } from "@mui/material";
import { useCallback } from "react";
// import "animate.css";
import { getEducAgentProjectsById } from "endpointServices/educAgentServices";

function AsTrafficConfig({ ReduxAutoScenario, ReduxUpdateAutoScenarioJourney }) {
	const navigate = useNavigate();
	const param = useParams();
	const mapContainer = useRef(null);
	const map = useRef(null);

	const [lng, setLng] = useState(7.42446); //7.42446, 51.580984
	const [lat, setLat] = useState(51.580984);
	const [zoom, setZoom] = useState(15);

	const [selectedVehicle, setSelectedVehicle] = useState(null);
	const [selectedDriverProfileIndexAndType, setSelectedDriverProfileIndexAndType] = useState(null);

	// const [vehiclesList, setVehiclesList] = useState([]);

	const [loadingVehicles, setLoadingVehicles] = useState(false);
	const [openResultModal, setOpenResultModal] = useState(false);
	const [downloadLink, setDownloadLink] = useState("");
	const [downloadLinkCopied, setDownloadLinkCopied] = useState(false);
	const [showInfoPanel, setShowInfoPanel] = useState(true);

	const [vehicleCount, setVehicleCount] = useState(100);
	const [vehicleCountModalVisibility, setVehicleCountModalVisibility] = useState(false);
	const [vehicleCountRSVP, setVehicleCountRSVP] = useState(ReduxAutoScenario?.AsTrafficConfig?.vehicleCount || 100);
	const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
	
	const [loadingEducAgents, setLoadingEducAgents] = useState(false);
	const [educAgentsList, setEducAgentsList] = useState([]);

	const fetchAllEducAgents = useCallback(async () => {
		try {
			setLoadingEducAgents(true);
			const response = await getEducAgentProjectsById(param.id);
			setEducAgentsList(response.data.data.applications);
		} catch (error) {
			console.log('error', error);
		}
		finally {
			setLoadingEducAgents(false);
		}
	}, [param.id,])
	
	

	useEffect(() => {
		if (!map.current) {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: mapTypes.streetView,
				center: [lng, lat],
				zoom: zoom,
			});
			map.current.on("load", () => {
				map.current.on("move", handleOnMapMove);
			});
		}
	}, [map]);

	useEffect(() => {
		fetchAllEducAgents();
	},[fetchAllEducAgents])


	function filterValidationKeys(jsonData, keyList) {
		const valudationData = jsonData;
		for (const key in valudationData) {
			if (valudationData.hasOwnProperty(key) && !keyList.includes(key)) {
				delete valudationData[key];
			}
		}
		return jsonData;
	}

	const fetchVehicleCompositions = useCallback(async () => {
		if (ReduxAutoScenario?.AsTrafficConfig && ReduxAutoScenario?.AsTrafficConfig.vehicleCount === vehicleCountRSVP) {

		}
		else {
			setLoadingVehicles(true);
			await httpGet({ url: GET_AUTOSCENARIO_VEHICLE_LIST })
				.then(async (resp) => {
					console.log("resp", resp);
					// setVehiclesList(resp.data.data);
					const validations = Object.keys(resp.data.data);
					await httpGet({ url: GET_VEHICLE_COMPOSITION_LIST })
						.then((res) => {
							console.log("res", res);
							const response = res.data.data;
							response.valudation = filterValidationKeys(response.valudation, validations);
							// Function to calculate the total count of keys
							const getTotalKeys = (obj) => Object.keys(obj).length;

							// Function to calculate the value to be assigned to each key as an integer
							const getValuePerKey = (totalCount) => Math.floor(vehicleCountRSVP / totalCount);

							// Calculate the total count of keys
							const totalKeys = getTotalKeys(response.valudation);

							// Calculate the value to be assigned to each key as an integer
							const valuePerKey = getValuePerKey(totalKeys);

							// Calculate the residual value
							const residualValue = vehicleCountRSVP - valuePerKey * totalKeys;

							// Create a new object with the desired values
							const newObject = {};

							function distributeValuesWithOneDecimal(arr) {
								const numProfiles = arr.length;
								const equalValue = parseFloat((1 / numProfiles).toFixed(1));
								const residual = parseFloat((1 - equalValue * numProfiles).toFixed(1));
								const result = {};

								for (let i = 0; i < numProfiles; i++) {
									let value = equalValue;
									if (i === 0) {
										value += residual;
									}
									result[arr[i]] = [{ value: parseFloat(value.toFixed(1)), settings: response.default[arr[i]] }]; // Limit to one decimal place
								}

								return result;
							}
							// Assign integer values to each key
							Object.keys(response.valudation).forEach((key, index) => {
								if (index === 0) {
									// Add the residual value to the first element
									newObject[key] = {
										value: valuePerKey + residualValue,
										driverProfiles: distributeValuesWithOneDecimal(response.valudation[key]),
									};
								} else {
									newObject[key] = { value: valuePerKey, driverProfiles: distributeValuesWithOneDecimal(response.valudation[key]) };
								}
							});
							console.log("data", newObject);
							ReduxUpdateAutoScenarioJourney({ AsTrafficConfig: { trafficConfig: newObject, vehicleCount: vehicleCountRSVP } });
							// setData(newObject);
							console.log("response", response);
							// setDefaultValues(response);
							setVehicleCountModalVisibility(false);
						})
						.then((err) => {
							console.log("err", err);
							setLoadingVehicles(false);
						});
				})
				.catch((err) => {
					console.log("err", err);
					setLoadingVehicles(false);
				})
				;
		}
	}, [vehicleCountRSVP]);

	
	useEffect(() => {
		fetchVehicleCompositions();
	},[fetchVehicleCompositions])

	const handleOnMapMove = (e) => {
		setLng(map.current.getCenter().lng.toFixed(4));
		setLat(map.current.getCenter().lat.toFixed(4));
		setZoom(map.current.getZoom().toFixed(2));
	};

	const canExecute = (status) => {
		setNextButtonEnabled(status === true)
	};

	const onClickNext = async (e) => {
		navigate(
			AUTHENTICATED_ROUTES.defineObserver
				.replace(":id", param.id)
				.replace(":aid", param.aid)
				.replace(":mid", param.mid)
				.replace(":strategy", ReduxAutoScenario.AsSpawningStrategies)
		)
	};

	const downloadFile = () => {
		const fileUrl = downloadLink;

		// Create a link and simulate a click to trigger the download
		const link = document.createElement("a");
		link.href = fileUrl;
		link.download = "downloaded_file.pdf";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const copyLinkToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(downloadLink);
			setDownloadLinkCopied(true);
			setTimeout(() => {
				setDownloadLinkCopied(false);
			}, 5000);
		} catch (err) {
			console.log("err", err);
		}
	};

	const updateAlgorithmSettingsFromCallback = () => {
		setSelectedDriverProfileIndexAndType(null);
	};
	
	const updateDriverProfilesFromCallback = () => {
		setSelectedVehicle(null);
	};


	return (
		<div className={``}>
			<div className={`container-fuild`}>
				<div className={`row`}>
					<div className={`col-12 d-flex justify-content-between`}>
						<div className={`d-flex`}>
							<div className={`ms-2`}>
								{
									ReduxAutoScenario.readonly && <ReadOnlyTag />
								}
							</div>
						</div>
						<div className={`d-flex`}>
							<ButtonX onClick={() => navigate(-1)} variant={"outlined"}>
								Back
							</ButtonX>
							<ButtonX className={`ms-2`} onClick={onClickNext} disabled={!nextButtonEnabled}>
								Next
							</ButtonX>
						</div>
					</div>
				</div>
				<div className={`map-container mt-3`}>
					<div className="traffic-config-left-panel">
						<div className={`d-flex mb-2`}>
							<IconButtonX icon={"InfoRounded"} onClick={() => setShowInfoPanel(!showInfoPanel)} />
						</div>
						<Draggable>
							<div className="rbs-left-info-panel">
								<CollapseContent show={showInfoPanel} orientation={"vertical"}>
									<div className="px-3 py-2">
										<div className="d-flex justify-content-between align-items-center">
											<Typos.H6>Instructions</Typos.H6>
											<IconButtonX icon={"ClearRounded"} onClick={() => setShowInfoPanel(false)} />
										</div>
										<hr />
										{TRAFFIC_CONFIG_INSTRUCTIONS.map((item, i) => {
											return (
												<div className="row" key={i}>
													<div className="col-1">
														<InstructionItemIcon />
													</div>
													<div className="col-11">
														<Typos.Body1>{item.text}</Typos.Body1>
													</div>
												</div>
											);
										})}
									</div>
								</CollapseContent>
							</div>
						</Draggable>
					</div>
					{/* -------------------------------------------------------- VEHICLE PROFILE portion ------------------------------------------- */}
					<div className={`traffic-config-right-panel`}>
						<VehicleComposition 
							loading={loadingVehicles && loadingEducAgents} 
							selectVehicleCallback={setSelectedVehicle} 
							setVehicleCountModalCallback={() => setVehicleCountModalVisibility(true)} 
							canExecute={canExecute} 
						/>
					</div>
					{/* -------------------------------------------------------- DYNAMIC ACTORS portion ------------------------------------------- */}
					{selectedVehicle !== null && (
						<div className={`traffic-config-dynamic-actors-right-panel shadow animate__animated animate__fadeInRight`}>
							<DriverProfiles
								selectedVehicle={selectedVehicle}
								selectDriverProfileCallback={setSelectedDriverProfileIndexAndType}
								saveUpdatedDataCallback={updateDriverProfilesFromCallback}
								canExecute={canExecute}
							/>
						</div>
					)}
					{/* -------------------------------------------------------- RULES portion ------------------------------------------- */}
					{selectedDriverProfileIndexAndType !== null && (
						<div className={`traffic-config-dynamic-actors-rules-right-panel shadow animate__animated animate__fadeInRight`}>
							<AlgorithmSettings
								selectedVehicle={selectedVehicle}
								selectedDriverProfileIndexAndType={selectedDriverProfileIndexAndType}
								trainingAgents={educAgentsList}
								saveUpdatedDataCallback={updateAlgorithmSettingsFromCallback}
								canExecute={canExecute}
							/>
						</div>
					)}
					<div className="sidebar">
						Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
					</div>
					<div ref={mapContainer} className="map-view" />
				</div>
			</div>
			<ModalX open={openResultModal} close={() => setOpenResultModal(false)} heading={"Results"} subheading={"Find your generated file in the link below"}>
				<div className={`container`}>
					<div className={`row justify-content-center mt-3`}>
						<div className={`col-lg-8 col-12`}>
							<TextFieldWithEndButton
								fullwidth={true}
								buttonIcon={downloadLinkCopied ? "DoneRounded" : "ContentCopy"}
								readonly={true}
								value={downloadLink}
								onButtonClick={copyLinkToClipboard}
							/>
							<div className={`d-flex justify-content-center mt-3`}>
								<Link to={AUTHENTICATED_ROUTES.coSimulation} className={`me-2`}>
									<ButtonX>Home</ButtonX>
								</Link>
								<ButtonX onClick={downloadFile}>Download</ButtonX>
							</div>
						</div>
					</div>
				</div>
			</ModalX>
			<ModalX 
				open={vehicleCountModalVisibility} 
				close={() => {
					setVehicleCountModalVisibility(false);
					if (vehicleCount !== vehicleCountRSVP) {
						setVehicleCount(vehicleCountRSVP);
					}
				}} 
				heading={"Total Vehicles Count"} 
				subheading={"Set Total vehicle count"}>
				<div className={`container`}>
					<div className={`row justify-content-center mt-3`}>
						<div className={`col-lg-8 col-12`}>
							<Slider
								name={"vehicle"}
								size="small"
								color="primary"
								min={ReduxAutoScenario?.AsPerceptionBased?.trafficDensity || 10}
								max={500}
								disabled={loadingVehicles}
								step={1}
								marks
								valueLabelDisplay="on"
								// defaultValue={vehicleCountRSVP}
								value={vehicleCount}
								onChange={(e) => setVehicleCount(e.target.value)}
							/>
							<Typos.Caption color="red">Note: Any configurations you have made will be lost on updating vehicle count</Typos.Caption>
							<hr />
							<div className={`d-flex justify-content-end mt-3`}>
								<ButtonX disabled={loadingVehicles} onClick={() => setVehicleCountRSVP(vehicleCount)}>Update</ButtonX>
							</div>
						</div>
					</div>
				</div>
			</ModalX>
		</div>
	);
}
function mapStateToProps(state) {
	return {
		ReduxAutoScenario: state.AutoScenario,
		ReduxProjects: state.Projects
	};
}
export default connect(mapStateToProps, { ReduxUpdateAutoScenarioJourney })(AsTrafficConfig);