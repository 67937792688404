import { SET_SCENEXTRACT, UPDATE_SCENEXTRACT } from ".."

const INITIAL_STATE = {
    applicationName: null,
    pralionId: null,
    projectName: null,
    applicationVersionId: null,
}

export default function ReducerScenExtract(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_SCENEXTRACT:
            return action.payload;
        case UPDATE_SCENEXTRACT:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}