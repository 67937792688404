import React, { useState, Fragment, useEffect } from "react";
import { Grid, Button, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonX, ModalX } from "components";
import OddForm from "./oddComponent/oddForm";
import OddFormTable from "./oddComponent/oddFormTable";
import {
  ReduxCreateOddJourney,
  ReduxUpdateOddJourney,
} from "services/redux-store/actions/actions-odd";
import { connect } from "react-redux";
import { getOddProjectServicePagination } from "endpointServices/oddServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dateConverter from "utils/dateConverter";
import { pageSize } from "utils/staticsData";
import AirplayIcon from "@mui/icons-material/Airplay";

const Odd = ({ ReduxCreateOddJourney, ReduxUpdateOddJourney }) => {
  const param = useParams();
  const navigator = useNavigate();
  console.log("param", param);

  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [summaryData, setSummaryData] = useState({});
  const [edited, setEdited] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setEdited(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const goto = (data) => {
    console.log("data", data);
    ReduxUpdateOddJourney({ ...data, type: data.gid ? "Edit" : "New" });
    navigator(`/projects/${data.pralion_id}/applications/odd/odd-zones`);
  };

  useEffect(() => {
    getProjectList({ pageNumber: page, testsPerPage: pageSize });
    ReduxCreateOddJourney(param.id); // Create a new odd redux journey
  }, [page]);

  const getProjectList = async (pagination) => {
    setFetching(true);
    const projectId = parseInt(param.id);
    const response = await getOddProjectServicePagination(
      projectId,
      pagination
    );
    console.log("odd response", response);
    if (response?.status === 200) {
      setFetching(false);
      const { data } = response?.data || [];
      setList(data["applications"]);
      setTotalRows(data.no_of_applications);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setList([]);
      setFetching(false);
    }
  };

  const handleSummary = (data) => {
    console.log("data", data);
    setEdited(true);
    setOpen(true);
    setSummaryData(data);
  };

  const columns = [
    {
      field: "name",
      headerName: <span className="heading-2">ODD ID</span>,
      width: 550,
      renderCell: (params) => (
        <Grid>
          <Button
            className="Main-link-simple"
            onClick={() => goto(params.row)}
            sx={{ color: "white" }}
          >
            {params.value}
          </Button>
        </Grid>
      ),
    },
    {
      field: "date_created",
      renderHeader: () => <span className="heading-2">{"Created At"}</span>,
      width: 280,
      renderCell: (params) => (
        <span className="row-heading">{dateConverter(params.value)}</span>
      ),
    },
    {
      renderHeader: () => <span className="heading-2">{"Summary"}</span>,
      width: 360,
      renderCell: (params) => (
        <Grid
          onClick={() => handleSummary(params.row)}
          sx={{ cursor: "pointer",marginLeft:'2em' }}
        >
          <AirplayIcon />
        </Grid>
      ),
    },
  ];

  return (
    <Fragment>
      <ModalX
        open={open}
        close={handleClose}
        heading={!edited ? "Create New ODD" : "Summary"}
        subheading={!edited ? "Enter details to continue" : "ODD application details"}
      >
        <OddForm
          handleClose={handleClose}
          syncList={getProjectList}
          summaryData={summaryData}
          isEdit={edited}
        />
      </ModalX>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        mb={2}
      >
        <ButtonX onClick={handleOpen}>Create New ODD</ButtonX>
      </Box>

      <OddFormTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />
    </Fragment>
  );
};
export default connect(null, { ReduxCreateOddJourney, ReduxUpdateOddJourney })(
  Odd
);
