import { SET_EDUCAGENTS, UPDATE_EDUCAGENTS } from ".."

export const ReduxCreateEducAgentsJourney = (pId, pName, trafficObject, testVehicleObject, placeDynamicObject, hyperParameterObject, hyperParameterPerceptionObject, hyperPerceptionAgentObject, sMap, mParameter,mlocaltion, nOfSegment, vehNOfSegment, vehSelectionType, selectedT) => {
    return {
        type: SET_EDUCAGENTS,
        payload: {
            pralionId: pId,
            projectName: pName,
            trafficPreset: trafficObject,
            testVehicle: testVehicleObject,
            dynamicActorForm: placeDynamicObject,
            hyperParameterEgo: hyperParameterObject, 
            hyperParameterPerception: hyperParameterPerceptionObject,
            hyperParameterPerceptionAgent: hyperPerceptionAgentObject,
            selectedMap: sMap,
            mapParameter: mParameter,
            mapParameterLocation: mlocaltion,
            numberOfSegment: nOfSegment,
            vehicleNumberOfSegment: vehNOfSegment,
            vehicleSelectionType: vehSelectionType,
            tilt: selectedT,
        }
    }
}

export const ReduxUpdateEducAgentsJourney = (obj) => {
    return {
        type: UPDATE_EDUCAGENTS,
        payload: obj
    }
}
