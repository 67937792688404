import { SET_DSPACE, UPDATE_DSPACE } from "..";

const INITIAL_STATE = {
    projectId: null,

}

export default function ReducerDSpace(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_DSPACE:
            return action.payload;
        case UPDATE_DSPACE:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}