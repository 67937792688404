import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function ApplicationTraceableTable({ location , headers }) {
    const rows = location.state || [];
    console.log('rows', rows)
    return (
        <div className="mt-4">
          <DataGrid
            getRowId={(row) => row.id || row.log_id || row.version_number }
            rows={rows}
            columns={headers}
            className="main-table-height"
            sx={{
              border: "none",
              borderRadius: "12px",
              backgroundColor: "secondary.dark",
              "& .MuiDataGrid-cell": {
                color: "text.main",
              },
              "& .MuiDataGrid-footerContainer": { display: "none" },
            }}
          />
        </div>
      );
}
