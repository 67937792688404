import { Box, Button, Grid, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import CarlaAutoScenario from "./modules/carlaAutoScenario";
import CarlaMyScenario from "./modules/carlaMyScenario";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

export default function Carla() {
    const navigate = useNavigate();
    const param = useParams();
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const onClickNext = () => {
      navigate(
        `/projects/${param.id}/applications/carla/carla-execute`
      );
    };
  
    return (
      <Box sx={{ width: "100%" }}>
        <Grid container justifyContent={"end"} className="mb-3">
          <Button
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "text.main",
              fontWeight: "bold",
              marginRight: "10px",
            }}
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Button
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "white",
              fontWeight: "bold",
            }}
            onClick={onClickNext}
          >
            Next
          </Button>
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTab-root": {
                fontSize: "1.2rem",
              },
              backgroundColor: "#304A74",
              border: "1px solid #808080a6"
            }}
          >
            <Tab
              label="Auto Scenario"
              sx={{borderRight: "1px solid #808080a6"}}
            />
            <Tab
              label="My Scenario"
            />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <CarlaAutoScenario />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CarlaMyScenario />
        </CustomTabPanel>
      </Box>
    );
}
