import React, { Fragment, useEffect, useState, useMemo } from "react";
import { Grid, Button, Checkbox } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { getScenarioExecListByPralionId } from "endpointServices/myScenarioServices";
import STORE from "services/redux-store/store";
import { pageSize } from "utils/staticsData";
import MainTable from "components/mainTable";
import { ReduxUpdateLowFidelityJourney } from "services/redux-store/actions/actions-lowFidelity";
import { connect } from "react-redux";
import { ButtonX } from "components";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LowScenarioExecutionList = ({ ReduxUpdateLowFidelityJourney }) => {
  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [checkSelectedScenario, setCheckSelectedScenario] = useState([]);
  const [isNext, setNext] = useState(false);
  const [isDisable, setIsDisabled] = useState(true);

  const { LowFidelity } = STORE.getState();
  const navigate = useNavigate();
  const param = useParams();

  useEffect(() => {
    getProjectList({ pageNumber: page, testsPerPage: pageSize });
  }, [page]);

  const getProjectList = async (pagination) => {
    setFetching(true);
    const id = parseInt(LowFidelity?.myScenarioVersionId);
    const response = await getScenarioExecListByPralionId(id, pagination);
    console.log("response", response);
    if (response?.status === 200) {
      setFetching(false);
      const { data } = response.data;
      setList(data.records || []);
      setTotalRows(data.total_no_records);
    } else {
      setList([]);
      setFetching(false);
    }
  };

  const goto = (data) => {
    if (data.visualizer_url !== null) {
      window.open(data.visualizer_url, "_blank");
    } else {
      alert("Url not found");
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleCheckBoxChange = (item) => {
    if (item) {
      setCheckSelectedScenario((selectedItems) => {
        const updatedList = [...selectedItems];
        const index = updatedList.findIndex(
          (selectedItem) => selectedItem.id === item.id
        );
        if (index !== -1) {
          updatedList.splice(index, 1);
        } else {
          updatedList.push(item);
        }
        setIsDisabled(updatedList.length === 0);
        return updatedList;
      });
    } else {
      const allChecked = list.length === checkSelectedScenario.length;
      const updatedList = allChecked ? [] : [...list];
      setCheckSelectedScenario(updatedList);
      setIsDisabled(updatedList.length === 0);
    }
    
  };

  const onClickNext = () => {
    navigate(`/projects/${param.id}/applications/low-fidelity/auto-scenario`);
  };

  const onSaveData = async () => {
    ReduxUpdateLowFidelityJourney({
      myScenarioNumberOfScenarios: checkSelectedScenario,
    });
    toast.success(`Selected scenarios saved for my-scenario`)
    setNext(true);
  };

  const columns = [
    {
      field: "checkbox",
      headerName: <Checkbox onChange={() => handleCheckBoxChange()} />,
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={checkSelectedScenario.some(
            (item) => item.id === params.row.id
          )}
          onChange={() => handleCheckBoxChange(params.row)}
        />
      ),
    },
    {
      field: "name",
      renderHeader: () => <span className="">{"Scenarios Name "}</span>,
      width: 400,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {/* <Grid item>
            <Checkbox onChange={() => handleCheckBoxChange(params)} />
          </Grid> */}
          <Grid item>
            <Button
              className="Main-link-simple row-heading"
              onClick={() => goto(params.row)}
              sx={{ color: "white" }}
            >
              {params.value}
            </Button>
          </Grid>
        </Grid>
      ),
    },
    { field: "map_name", headerName: "Map Name", width: 249 },
    {
      field: "output.MinTTC",
      headerName: "Min TTC",
      width: 149,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {params.row.output?.MinTTC?.toFixed(3)}
        </Grid>
      ),
    },
    {
      field: "output.MinTHW",
      headerName: "Min THW",
      width: 149,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {params.row.output?.MinTHW?.toFixed(3)}
        </Grid>
      ),
    },
    {
      field: "output.LJavg",
      headerName: "L Javg",
      width: 149,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {params.row.output?.LJavg?.toFixed(3)}
        </Grid>
      ),
    },
    {
      field: "output.TTC_Pass",
      headerName: "TTC Pass",
      width: 149,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {params.row.output?.TTC_Pass === true ? (
            <p style={{ marginTop: "16px" }}>True</p>
          ) : params.row.output?.TTC_Pass === false ? (
            <p style={{ marginTop: "16px" }}>False</p>
          ) : (
            ""
          )}
        </Grid>
      ),
    },
    {
      field: "output.LJ_Pass",
      headerName: "LJ Pass",
      width: 149,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {params.row.output?.LJ_Pass === true ? (
            <p style={{ marginTop: "16px" }}>True</p>
          ) : params.row.output?.LJ_Pass === false ? (
            <p style={{ marginTop: "16px" }}>False</p>
          ) : (
            ""
          )}
        </Grid>
      ),
    },
    {
      field: "output.THW_Pass",
      headerName: "THW Pass",
      width: 149,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {params.row.output?.THW_Pass === true ? (
            <p style={{ marginTop: "16px" }}>True</p>
          ) : params.row.output?.THW_Pass === false ? (
            <p style={{ marginTop: "16px" }}>False</p>
          ) : (
            ""
          )}
        </Grid>
      ),
    },
    {
      field: "output.ScenarioPass",
      headerName: "Scenario Pass",
      width: 149,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {params.row.output?.ScenarioPass === true ? (
            <p style={{ marginTop: "16px" }}>True</p>
          ) : params.row.output?.ScenarioPass === false ? (
            <p style={{ marginTop: "16px" }}>False</p>
          ) : (
            ""
          )}
        </Grid>
      ),
    },
  ];

  return (
    <Fragment>
      <Grid item sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            color: "text.main",
            fontWeight: "bold",
            marginRight: "10px",
          }}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        {isNext ? (
          <ButtonX onClick={onClickNext}>Next</ButtonX>
        ) : (
          <ButtonX onClick={onSaveData} disabled={isDisable}>Save</ButtonX>
        )}
      </Grid>
      <MainTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />
    </Fragment>
  );
};
export default connect(null, { ReduxUpdateLowFidelityJourney })(
  LowScenarioExecutionList
);
