import { Grid, MenuItem, ListItemText, TextField, Box, Button } from "@mui/material";
import { getAutoScenarioTrafficProfileList } from "endpointServices/autoScenarioServices";
import { getTestVehicleProfileService } from "endpointServices/testVehicleService";
import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { autoScenarioPlaceState, getVehicleProfile } from "../stateMaintain";
import VehicleProfile from "./vehicleProfiles";


export default function PlaceTestVehicles() {
  const param = useParams()
  const [carObject, selectCarObject] = useState({});

  const [vehicleList, setVehicleList] = useState([]);
  const [selectCategory, setSelectCategory] = useState({});

  const [trafficPresetList, setTrafficPresetList] = useState([]);
  const [selectTraficPreset, setSelectTraficPreset] = useState('');


  React.useEffect(() => {
    getAllList()
    getTrafficProfileList()
  }, [])

  const handleSelectCategory = (e) => {
    const { value } = e.target;
    setSelectCategory(value);
  };

  const handleSelectTrafficPreset = (e) => {
    const { value } = e.target;
    setSelectTraficPreset(value);
  };
  const getAllList = async () => {
    const response = await getTestVehicleProfileService()
    if (response?.status === 200) {
      const { data } = response?.data
      const listData = data.slice(0, 10)
      setVehicleList(listData)

    }
  }

  const getTrafficProfileList = async () => {
    const response = await getAutoScenarioTrafficProfileList()
    if (response?.status === 200) {
      const { data } = response?.data
      const setKeysName = Object.keys(data)
      setTrafficPresetList(setKeysName)
    }
  }


  const onSave = () => {
    const payload = {
      test_vehicle_id: selectCategory.id,
      selected_traffic_preset: selectTraficPreset
    }
    getVehicleProfile(payload)
  }

  const trafficPreset = (
    <Box p={1} pt={2} >
      <TextField
        label="Select Traffic preset"
        name="selectTraficPreset"
        required
        type="select"
        variant="outlined"
        value={selectTraficPreset}
        onChange={handleSelectTrafficPreset}
        fullWidth
        disabled={false}
        select
        SelectProps={{
          MenuProps: {
            className: "customDropdown",
          },
        }}
      >
        {trafficPresetList.map((item, id) => {
          return (
            <MenuItem key={id} value={item}>
              <ListItemText primary={item} />
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  )

  const testVehicle = (
    <Box p={1} pt={2} >
      <TextField
        label="Select Test Vehicle"
        name="category"
        required
        type="select"
        variant="outlined"
        value={selectCategory}
        onChange={handleSelectCategory}
        fullWidth
        disabled={false}
        select
        SelectProps={{
          MenuProps: {
            className: "customDropdown",
          },
        }}
      >
        {vehicleList.map((item, id) => {
          return (
            <MenuItem key={id} value={item}>
              <ListItemText primary={item.display_name} />
            </MenuItem>
          );
        })}
      </TextField>

    </Box>
  )

  return (
    <Grid container>
      <Grid item xs={8}>
        <Grid>
          <h2>Map</h2>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Link to={`/projects/${param.id}/applications/auto-scenarios/place-dynamics-actor`}>
          <Button
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "white",
              width: "130px",
              marginBottom: '1em',
              float: 'right'
            }}

            onClick={onSave}
          >
            Next
          </Button>
        </Link>

        <VehicleProfile selectedCar={selectCategory} fields={{ trafficPreset, testVehicle }} />

        {/* <VehicleProfile selectedCar={selectCategory} /> */}
      </Grid>
    </Grid>
  )
}
