import React from 'react'
import TraceableX from 'components/traceableX'
import { TextField } from "@mui/material";


export default function ScenarioTraceable() {
    return (
        <TraceableX>
            <TextField label="Project Name" placeholder="Enter Name" />
            <TextField label="Description" placeholder="Enter Description" />
        </TraceableX>
    )
}
