import React, { Fragment, useEffect, useState } from "react";
import { Button, MenuItem, TextField } from "@mui/material";
import { ButtonX, ReadOnlyTag } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { getMapListById } from "endpointServices/mapsServices";
import STORE from "services/redux-store/store";
import { getMapGidListByPralionId, getScenarioByPralionId } from "endpointServices/myScenarioServices";
import { connect } from "react-redux";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import 'react-toastify/dist/ReactToastify.css';
import { AUTHENTICATED_ROUTES } from "router/constants";
import { ReduxUpdateMapValidatorJourney } from "services/redux-store/actions";
import MapOdds from "pages/myScenarios/modules/map/mapList/mapListComponent/mapOdds";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "xyz";

function mapStateToProps(state) {
	return {
		MapValidator: state.MapValidator
	}
}

export default connect(mapStateToProps, { ReduxUpdateMapValidatorJourney })(function ValidatorMap({
	ReduxUpdateMapValidatorJourney
}) {
	const navigate = useNavigate();
	const param = useParams();
	const [selectCategory, setSelectCategory] = useState("");
	const [mapList, setMapList] = useState([]);
	const [mapGid, setMapGid] = useState(null)
	const [isDisabled, setIsDisabled] = useState(true)
	const [selectedOdd, setSelectedOdd] = useState("");
	const { Projects, MapValidator } = STORE.getState();
	const projectId = Projects.projectId;
	const [loading, setLoading] = useState(false);
	const [ifreadonly, setIfreadonly] = useState(false);

	useEffect(() => {
		getMapList();
	}, []);

	const updateNextButtonState = (isChecked, oddId) => {
		setIsDisabled(!isChecked);
		setSelectedOdd(oddId);
	};

	const handleOnChange = async (e) => {
		const { value } = e.target;
		const isValidValue = mapList.some(item => item.id === value);
		if (isValidValue) {
			setSelectCategory(value);
			await fetchOddsAgainstMapGid(value);
		} else {
			setSelectCategory("");
		}
	};


	const fetchOddsAgainstMapGid = async (id) => {
		setLoading(true);
		const map = mapList.find(item => item.id === id);
		if (!map) {
			setLoading(false);
			return;
		}
		const pralionId = map.pralion_id;
		const response = await getMapGidListByPralionId(pralionId);
		if (response && response.status === 200) {
			const { data } = response.data;
			setMapGid(data);
		} else {
			console.log("error");
		}
		setLoading(false);
	}


	const getMapList = async () => {
		setLoading(true);
		const response = await getMapListById(projectId);
		if (response.status === 200) {
			const { data } = response.data;
			const filteredApplications = data.applications.filter(application => application?.application_version_id !== null);
			setMapList(filteredApplications);
			if (MapValidator.readonly) {
				setIfreadonly(false);
				const prevState = await getScenarioByPralionId(MapValidator.pralionId)
				if (prevState && prevState.status === 200) {
					// setSelectCategory(prevState.data.data.meta.map_app_id);
					ReduxUpdateMapValidatorJourney({
						oddData: { gid_kwh: prevState.data.data.meta.map_gid },
						prevUserScenario: prevState.data.data.user_scenario_json.scenario
					})
					const map = filteredApplications.find(item => item.id === prevState.data.data.meta.map_app_id);
					if (!map) {
						setLoading(false);
						return;
					}
					const pralionId = map.pralion_id;
					const response = await getMapGidListByPralionId(pralionId);
					if (response && response.status === 200) {
						const { data } = response.data;
						setMapGid(data);
					} else {
						console.log("error");
					}
					setLoading(false);
				}
			}
			else {
				if (MapValidator?.selectedMap) {
					// setSelectCategory(MapValidator?.selectedMap)
					// setLoading(true);
					const map = filteredApplications.find(item => item.id === MapValidator?.selectedMap);
					if (!map) {
						setLoading(false);
						return;
					}
					const pralionId = map.pralion_id;
					const response = await getMapGidListByPralionId(pralionId);
					if (response && response.status === 200) {
						const { data } = response.data;
						// setMapGid(data);
					} else {
						console.log("error");
					}
					setLoading(false);
				}
			}
		} else {
			console.log("error");
		}
		setLoading(false);
	};

	const onClickNext = () => {
		if (!selectCategory) {
			console.log("Please select a map before proceeding.");
			return;
		}

		if (!selectedOdd) {
			console.log("No odd ID available.");
			return;
		}
		ReduxUpdateMapValidatorJourney({ selectedMap: selectCategory, oddData: { gid_kwh: selectedOdd } });
		navigate(AUTHENTICATED_ROUTES.oddViewValidatorMap.replace(":id", projectId).replace(":aid", param.aid).replace(":mid", selectedOdd));
	};

	return (
		<Fragment>
			<div className={`container-fluid`}>
				<div className={`row`}>
					<div className={`col-lg-6 d-flex align-items-center`}>
						<TextField
							label="Select Map"
							name="selectCategory"
							type="select"
							size="small"
							variant="outlined"
							value={selectCategory}
							onChange={handleOnChange}
							sx={{ maxWidth: "23rem" }}
							helperText={"The defined Map Catalogs can only be referred"}
							fullWidth
							disabled={ifreadonly}
							select
							SelectProps={{
								MenuProps: {
									className: "customDropdown",
								},
							}}
						>
							{mapList.map((item, i) => {
								return (
									<MenuItem key={i} value={item.id}>
										{item.name}
									</MenuItem>
								);
							})}
						</TextField>
						<div className={`ms-2`}>
							{
								MapValidator.readonly && <ReadOnlyTag />
							}
						</div>
					</div>
					<div className={`col-lg-6`}>
						<div className={`d-flex justify-content-end`}>

							<Button
								sx={{
									backgroundColor: "primary.main",
									"&:hover": {
										backgroundColor: "primary.main",
									},
									color: "text.main",
									fontWeight: "bold",
									marginRight: "10px",
									textTransform: "none",
									fontSize: "inherit",
								}}
								onClick={() => navigate(-1)}
							>
								Back
							</Button>
							<ButtonX className={`ms-2`} onClick={onClickNext} disabled={isDisabled}>
								Next
							</ButtonX>
						</div>
					</div>
				</div>
			</div>
			<MapOdds loading={loading} mapIds={mapGid} updateNextButtonState={updateNextButtonState} />
		</ Fragment>
	);
}
)