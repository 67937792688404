import { Grid } from "@mui/material";
import React from "react";
import VehiclesList from "./replicarSimComponent/listing";

export default function ReplicarSim() {
  return (
    <Grid container>
      <VehiclesList />
    </Grid>
  );
}
