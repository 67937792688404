import * as React from "react";
import { Grid, Radio, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import ArticleIcon from "@mui/icons-material/Article";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CoSIMCarMakerTableData } from "utils";

const columns = [
  {
    field: "projectName",
    headerName: "Project Name",
    width: 249,
    renderCell: (params) => (
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <ArticleIcon fontSize="small" />
        </Grid>
        <Grid item>
          <Link
            className="Main-link-simple"
            to={`/applications/test-vehicles/cosim-car-maker/cosim-car-maker-details/${params.value}`}
          >
            {params.value}
          </Link>
        </Grid>
      </Grid>
    ),
  },
  { field: "userName", headerName: "User Name", width: 249 },
  {
    field: "projectCreationDate",
    headerName: "Project Creation Date",
    width: 249,
  },
  { field: "action", headerName: "Action", width: 249 },
  {
    field: "status",
    headerName: "Status",
    width: 245,
    renderCell: (params) => {
      return (
        <>
          <div className="statusRadioButton">
            <Radio
              sx={{
                transform: "scale(0.6)",
                width: "1px",
                height: "1px",
              }}
            />
            <Radio
              color="warning"
              sx={{ transform: "scale(0.6)", width: "1px", height: "1px" }}
            />
            <Radio
              color="success"
              sx={{ transform: "scale(0.6)", width: "1px", height: "1px" }}
            />
          </div>

          <Button
            variant="contained"
            sx={{
              backgroundColor: "secondary.dark",
              textTransform: "none",
              borderRadius: "8px",
            }}
          >
            {params.value}
          </Button>
          <Button sx={{ color: "text.main" }}>
            <MoreVertIcon />
          </Button>
        </>
      );
    },
  },
];
export default function CarMakerTable() {
  return (
    <div className="mt-4">
      <DataGrid
        rows={CoSIMCarMakerTableData}
        columns={columns}
        autoHeight={true}
        sx={{
          border: "none",
          borderRadius: "12px",
          backgroundColor: "secondary.dark",
          "& .MuiDataGrid-cell": {
            color: "text.main",
          },
          "& .MuiDataGrid-footerContainer": { display: "none" },
        }}
      />
    </div>
  );
}
