import { Grid } from '@mui/material'
import React from 'react'
import ModelInputParams from './modelInputParams'

export default function ModelInput() {
    
    return (
        <Grid container>
            <Grid item xs={12}>
                <ModelInputParams />
            </Grid>
        </Grid>
    )
}
