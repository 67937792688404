import { Backdrop, CircularProgress, CssBaseline, Fade, Skeleton, Box, LinearProgress, Typography } from "@mui/material";
import React from "react";

export default function ProgressX({ fullscreen, background, block, application, width, height, progress, linearWithLabel, linear, ...rest }) {
	if (fullscreen) {
		return (
			<Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
				<Fade in={true}>
					<div className={`position-absolute top-50 start-50 translate-middle`}>
						<CssBaseline />
						<CircularProgress />
					</div>
				</Fade>
			</Backdrop>
		);
	}
	if (background) {
		return (
			<div className={`d-flex`}>
				<Skeleton animation="pulse" variant="circular" className={`mx-1`} width={10} height={10} />
				<Skeleton animation="pulse" variant="circular" className={`mx-1`} width={10} height={10} />
				<Skeleton animation="pulse" variant="circular" className={`mx-1`} width={10} height={10} />
			</div>
		);
	}
	if (block) {
		return <Skeleton animation="wave" variant="rounded" width={width} height={height} {...rest} />;
	}
	if (application) {
		return (
			<>
				<Skeleton animation="wave" variant="rounded" width={width} height={height} sx={{marginTop: "10px", marginRight: "1em", marginLeft: '5px'}} />
				<Skeleton animation="wave" variant="rounded" width={width} height={height} sx={{marginTop: "10px"}} />
				<Skeleton animation="wave" variant="rounded" width={width} height={height} sx={{marginTop: "15px", marginRight: "1em", marginLeft: '5px'}} />
				<Skeleton animation="wave" variant="rounded" width={width} height={height} sx={{marginTop: "15px"}} />
				<Skeleton animation="wave" variant="rounded" width={width} height={height} sx={{marginTop: "15px", marginRight: "1em", marginLeft: '5px'}} />
			</>
		)
	}
	if (linear) {
		return (
			<div className={`w-100`}>
				<LinearProgress variant="determinate" value={progress} {...rest} />
			</div>
		);
	}
	if (linearWithLabel) {
		return (
			<div className={`w-100`}>
				<LinearProgressWithLabel value={progress} />
			</div>
		);
	}
	return (
		<div className={`d-flex justify-content-center`}>
			<CircularProgress />
		</div>
	);
}


function LinearProgressWithLabel(props) {
	return (
		<Box sx={{ display: "flex", alignItems: "center" }}>
			<Box sx={{ width: "100%", mr: 1 }}>
				<LinearProgress variant="determinate" {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant="body2" color="text.secondary">
					{props.value}
				</Typography>
			</Box>
		</Box>
	);
}
