import { SET_EDUCAGENTS, UPDATE_EDUCAGENTS, GET_EDUCAGENTS } from ".."

const INITIAL_STATE = {
    pralionId: null,
    projectName: null,
    trafficPreset: null,
    testVehicle: null,
    dynamicActorForm: null,
    hyperParameterEgo: null,
    hyperParameterPerception: null,
    hyperParameterPerceptionAgent: null,
    selectedMap: null,
    mapParameter: null,
    mapParameterLocation:null,
    numberOfSegment: null,
    vehicleNumberOfSegment: null,
    vehicleSelectionType: null,
    tilt: null,
}

export default function ReducerEducAgents(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_EDUCAGENTS:
            return action.payload;
        case UPDATE_EDUCAGENTS:
            return { ...state, ...action.payload }
        case GET_EDUCAGENTS:
            return action.payload
        default:
            return state;
    }
}