import { specialValue } from "./staticsData";

export const UcFirst = (string = "") => {
    if (string.length > 1 ){
        return `${string.charAt(0).toUpperCase()}${string.substring(1).toLowerCase()}`
    } else {
        return string;
    }
}

export const OUcFirst = (string = "") => {
    if (string.length > 1 ){
        return `${string.charAt(0).toUpperCase()}${string.substring(1)}`
    } else {
        return string;
    }
}

export const wrapText = (text) => {
    const maxTextLength = 50;
    return text?.length > maxTextLength ? text.substring(0, maxTextLength) + '...' : text;
};

export const miniWrapText = (text) => {
    const maxTextLength = 22;
    return text?.length > maxTextLength ? text.substring(0, maxTextLength) + '...' : text;
};

export const checkDagStatus = (status) =>{
    const dagStatus = ["pending", "failed", "inprogress", undefined, null]
    return dagStatus.includes(status) ? true : false
} 

export const PresentableText = (string = "") => {
    if (string.length > 1) {
        return UcFirst((string.split(/(?=[A-Z])/)).join(" ")).replaceAll("_", " ").replaceAll("-", " ");
    } else {
        return string;
    }

}


export const InWords = (num) => {
    const a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    num = num.toString();
    console.log('num', num);
    if (num.length > 9) return 'overflow';
    let n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    console.log('num', num);
    console.log('n', n);
    if (!n) return; 
    let str = '';
    str += (n[1] !== 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Crore ' : '';
    str += (n[2] !== 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Lac ' : '';
    str += (n[3] !== 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Thousand ' : '';
    str += (n[4] !== 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Hundred ' : '';
    str += (n[5] !== 0) ? ((str !== '') ? 'and ' : '') + (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'only ' : '';
    return str;
}

export const SumArrayOfInts = (arr) => {
    return arr.reduce((partialSum, number) => partialSum + number, 0);
}

export const ConvertLongTextToShort = (str = "", len) => {
    return {
        short: str.length > len ? `${str.substring(0, len)}...` : str,
        ishort: str.length > len ? `... ${str.substring(str.length - len, str.length)}` : str,
        actual: str
    }
}

export const SetInfoToLocalStorage = (name, userInfo) => {
    if(Object.keys(userInfo).length) {
        localStorage.setItem(name, JSON.stringify(userInfo))
    }
}

export const GetInfoFromLocalStorage = (name) => {
    const data = localStorage.getItem(name)
    return JSON.parse(data)
}

export const PageRedirectWithWindow = (path) => {
    window.location.href = window.location.origin + path
}

export const GetCurrentPagePathname = () => {
    const url = window.location.pathname
    return url
}

export const OmitKeyValuePair = (array, key) => {
	return array.map(({ [key]: _, ...rest }) => rest);
};

export const getPolygonCenter = (geojson) => {
    if (geojson.type !== 'FeatureCollection' || !Array.isArray(geojson.features)) {
      throw new Error('Invalid GeoJSON format: expected a FeatureCollection');
    }
  
    const feature = geojson.features.find(f => f.geometry && f.geometry.type === 'Polygon');
    if (!feature) {
      throw new Error('No Polygon feature found in the GeoJSON');
    }
  
    const polygonCoordinates = feature.geometry.coordinates[0];
    const numPoints = polygonCoordinates.length;
  
    // Calculate the average of all latitudes and longitudes
    let sumLat = 0;
    let sumLng = 0;
  
    for (const point of polygonCoordinates) {
      sumLat += point[1];
      sumLng += point[0];
    }
  
    const centerLat = sumLat / numPoints;
    const centerLng = sumLng / numPoints;
  
    return [centerLng, centerLat]
  }


  export let SensorSetupDetails = {}

  export const getSensorSetupDetails = (data) => {
    SensorSetupDetails = data
  }

  export const metersToDegrees = (meterValue) => {
    const degrees = (meterValue * 180) % 360;
    return degrees < 0 ? 360 + degrees : degrees;
  };
  
  export const degreesToMeters = (degreesValue) => {
    const meters = (degreesValue / 180);
    return meters;
  };

  export const handleSpecialCharacters = (name, value, idSpecialCharacter, requirementSpecialCharacter) => {
    if (!specialValue.test(value)) {
      if (name === "name") {
        idSpecialCharacter(false);
      } else {
        requirementSpecialCharacter(false);
      }
    } else {
      if (name === "name") {
        idSpecialCharacter(true);
      } else {
        requirementSpecialCharacter(true);
      }
    }
  };

 export const IsValidURL = (url) => {
    // Regular expression for URL validation
    var urlRegex = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i');
    return !!urlRegex.test(url);
}