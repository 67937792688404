import React, { Fragment, useEffect, useState } from "react";
import { Grid, Button, Checkbox } from "@mui/material";
import { pageSize } from "utils/staticsData";
import MainTable from "components/mainTable";
import { ButtonX } from "components";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { ReduxUpdateTestSuitsJourney } from "services/redux-store/actions/actions-testSuits";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import STORE from "services/redux-store/store";

const ScenariosShowing = ({
  versionId,
  ReduxUpdateTestSuitsJourney,
  getScenarios,
  appKey,
  getTestValues,
}) => {
  const { TestSuits } = STORE.getState();
  const { autoScenarioNumberOfScenarios } = TestSuits;
  const { myScenarioNumberOfScenarios } = TestSuits;
  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [checkSelectedScenario, setCheckSelectedScenario] = useState([]);
  console.log("checkSelectedScenario", checkSelectedScenario)
  const [isDisable, setIsDisabled] = useState(true);

  useEffect(() => {
    getProjectList({ pageNumber: page, testsPerPage: pageSize });
  }, [page]);

  useEffect(() => {
    getStoreValues();
  }, [TestSuits]);

  useEffect(() => {
    setIsDisabled(checkSelectedScenario.length === 0);
  }, [checkSelectedScenario]);

  const getStoreValues = () => {
    if (
      appKey === "autoScenario" &&
      autoScenarioNumberOfScenarios !== undefined &&
      autoScenarioNumberOfScenarios !== null &&
      autoScenarioNumberOfScenarios.length > 0
    ) {
      setCheckSelectedScenario(autoScenarioNumberOfScenarios);
    } else if (
      appKey === "myScenario" &&
      myScenarioNumberOfScenarios !== undefined &&
      myScenarioNumberOfScenarios !== null &&
      myScenarioNumberOfScenarios.length > 0
    ) {
      setCheckSelectedScenario(myScenarioNumberOfScenarios);
    }
  };
  

  const getProjectList = async (pagination) => {
    setFetching(true);
    const versionNumber = parseInt(versionId);
    const response = await getScenarios(versionNumber, pagination);
    console.log("response", response);
    if (response?.status === 200) {
      setFetching(false);
      const { data } = response.data;
      setList(data.records || []);
      setTotalRows(data.total_no_records);
    } else {
      setList([]);
      setFetching(false);
    }
  };

  const goto = (data) => {
    if (data.visualizer_url !== null) {
      window.open(data.visualizer_url, "_blank");
    } else {
      alert("Url not found");
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleCheckBoxChange = (item) => {
    if (item) {
      setCheckSelectedScenario((selectedItems) => {
        const updatedList = [...selectedItems];
        const index = updatedList.findIndex(
          (selectedItem) => selectedItem.id === item.id
        );
        if (index !== -1) {
          updatedList.splice(index, 1);
        } else {
          updatedList.push(item);
        }
        setIsDisabled(updatedList.length === 0);
        return updatedList;
      });
    } else {
      const allChecked = list.length === checkSelectedScenario.length;
      const updatedList = allChecked ? [] : [...list];
      setCheckSelectedScenario(updatedList);
      setIsDisabled(updatedList.length === 0);
    }
  };

  const onSaveData = async () => {
    if (appKey === "autoScenario") {
      ReduxUpdateTestSuitsJourney({
        autoScenarioNumberOfScenarios: checkSelectedScenario,
      });
      toast.success(`scenarios saved`)
    } else {
      ReduxUpdateTestSuitsJourney({
        myScenarioNumberOfScenarios: checkSelectedScenario,
      });
      toast.success(`scenarios saved`)
    }
    getTestValues()
  };

  const columns = [
    {
      field: "checkbox",
      headerName: <Checkbox sx={{ "&:hover": undefined  }} onChange={() => handleCheckBoxChange()} />,
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={checkSelectedScenario.some(
            (item) => item.id === params.row.id
          )}
          onChange={() => handleCheckBoxChange(params.row)}
        />
      ),
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "name",
      renderHeader: () => <span className="heading-2">{"Scenarios Name "}</span>,
      width: 400,
      renderCell: (params) => (
        <Grid>
            <Button
              className="Main-link-simple row-heading"
              onClick={() => goto(params.row)}
              sx={{ color: "white" }}
            >
              {params.value}
            </Button>
        </Grid>
      ),
    },
    {
      field: "output.MinTTC",
      renderHeader: () => <span className="heading-2">{"Min TTC (s) "}</span>,
      width: 175,
      renderCell: (params) => (
        <Grid>
          {params.row.output?.MinTTC?.toFixed(3)}
        </Grid>
      ),
    },
    {
      field: "output.MinTHW",
      renderHeader: () => <span className="heading-2">{"Min THW (s) "}</span>,
      width: 175,
      renderCell: (params) => (
        <Grid>
          {params.row.output?.MinTHW?.toFixed(3)}
        </Grid>
      ),
    },
    {
      field: "output.LJavg",
      renderHeader: () => <span className="heading-2">{"LJ Avg (m/s^3) "}</span>,
      width: 175,
      renderCell: (params) => (
        <Grid>
          {params.row.output?.LJavg?.toFixed(3)}
        </Grid>
      ),
    },
  ];

  return (
    <Fragment>
      <Grid item sx={{ display: "flex", justifyContent: "end" }}>
        <ButtonX onClick={onSaveData} disabled={isDisable}>
          Save
        </ButtonX>
      </Grid>
      <MainTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />
    </Fragment>
  );
};
export default connect(null, { ReduxUpdateTestSuitsJourney })(ScenariosShowing);
