import { SET_ODD, UPDATE_ODD } from "..";

const INITIAL_STATE = {
    pralionId: null,
    oddPayload : null,
    projectName: null,
}

export default function ReducerOdd(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_ODD:
            return action.payload;
        case UPDATE_ODD:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}