import React, { Fragment, useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import "./oddViewValidatorMap.css";
import { LinkButtonX, ButtonX, SwitchButtonX, CollapseContent, Typos, IconButtonX, SquareImage } from "components";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { useLocation, useNavigate } from "react-router-dom";
import Draggable from "react-draggable";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Tab,
	Tabs,
} from "@mui/material";
import {
	ExpandMoreRounded,
} from "@mui/icons-material";
import { httpGet, httpPost } from "utils/httpMethods";
import { ProgressX } from "components";
import { ODD_TO_GEOJSON, ODD_TO_GEOJSON_V2, VALIDATE_ODR_GID } from "constants/endpoints";
import { GetInfoFromLocalStorage, PresentableText, mapTypes } from "utils";
import { connect } from "react-redux";
import { DEFAULT_POLYGON_LAYER } from "constants/defaults";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "xyz";

const laneColors = {
	selection: "#00FF00",
	option: "#EFCB00",
};

export const LAYER2 = {
	id: "state-fills",
	type: "fill",
	source: "states",
	layout: {},
	filter: ["==", "$type", "Polygon"],
	paint: {
		// "fill-color": "#0080ff", // blue color fill
		// "fill-opacity": 0.2,
		// "fill-color": "rgba(0, 163, 181, 0.2)",
		"fill-outline-color": "rgba(0, 188, 212, 1)",
		"fill-color": [
			"case",
			["boolean", ["feature-state", "click"], false],
			"#64bdbb", // if selected true, paint in blue
			"#ff0000", // else paint in red
		],
	},
};

function mapStateToProps(state) {
	return {
		MapValidator: state.MapValidator
	}
}

export default connect(mapStateToProps)(function OddViewValidatorMap({ MapValidator }) {
	const mapContainer = useRef(null);
	const map = useRef(null);
	const [isLoading, setIsLoading] = useState(false)
	const navigate = useNavigate();
	const location = useLocation();
  const jsonRef = useRef(null);
	const [lng, setLng] = useState(13.405252);
	const [lat, setLat] = useState(52.517644);
	const [zoom, setZoom] = useState(8);
	const [loading, setLoading] = useState(false);
	const [loadingMapValidationResults, setLoadingMapValidationResults] = useState(false);
	const [mapValidationResults, setMapValidationResults] = useState(null);
	const [currentMapType, setCurrentMapType] = useState(mapTypes.streetView);
	const [url, setUrl] = useState(null);
	const [carObject, selectCarObject] = useState({});
  const eRef = useRef(null);
	const LayersRef = useRef(null);
  const dataRef = useRef(null);
	const [value, setValue] = React.useState(0);
	const [geojsonFilesURLs, setGeojsonFilesURLs] = useState(null);
	const [clickNextStage, setClickNextStage] = useState(0);
	const [currentViewingError, setCurrentViewError] = useState(null);

	const handleTabChange = (event, newValue) => {
		console.log('Tab changed to', newValue);
		setValue(newValue)
	}

	const TabPanel = ({ value, index, children }) => {
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`tabpanel-${index}`}
				aria-labelledby={`tab-${index}`}
			>
				{value === index && (
					<div>
						{children}
					</div>
				)}
			</div>
		);
	};



	const generateNewLayer = (layerId, type) => {
		return {
			id: "route" + layerId,
			type: "fill",
			source: layerId,
			paint: {
				"fill-opacity": 0.7,
				"fill-color": ["get", "fill"], //type.fill, //"rgba(0, 163, 181, 0.2)",
				"fill-outline-color": type.stroke, //"rgba(0, 188, 212, 1)",
			},
		};
	};

	useEffect(() => {
		console.log("location", location);


		if (!map.current) {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: mapTypes.streetView,
				center: [lng, lat],
				zoom: zoom,
			});
	map.current.on("load", () => {
		fetchMapLayers();
});
	map.current.on("move", handleOnMapMove);
		return () => {
		};
		}
	}, []);

	const fetchMapLayers = async () => {
		setLoading(true);
		try {
			await httpGet({ url: `${ODD_TO_GEOJSON_V2}/${MapValidator.oddData.gid_kwh}` })
				.then((resp) => {
					console.log("resp", resp);
					LayersRef.current = resp;
					setUrl(resp.data.data.lane_polygons);
					setGeojsonFilesURLs(resp.data.data);
					handleSourceLoad({ target: { checked: true, id: Object.keys(resp.data.data).findIndex(item => item == "lane_polygons").toString() } }, "lane_polygons", resp.data.data.lane_polygons)
				})
				.catch((err) => {
					console.log('err', err);
				}).finally(() => {
					setLoading(false);
				});
		} catch (err) {
			console.log("err", err);
			setLoading(false);
		}
	};

	const handleSourceLoad = (e, type, data = null) => {
		eRef.current = e;
    dataRef.current = data;
		if (e.target.checked) {
			fetch(data || geojsonFilesURLs[type])
				.then((resp) => resp.json())
				.then((json) => {
					map.current.addSource(e.target.id, { type: "geojson", data: json, generateId: true });
					const layerProps = json["features"][0]["properties"];
					map.current.addLayer(generateNewLayer(e.target.id, layerProps));
					if (type === "lane_polygons") {
						map.current.setCenter(json["features"][0]["geometry"]["coordinates"][0][0]);
						map.current.setZoom(14);
					}
				});
		} else {
			try {
				map.current.removeLayer(`route${e.target.id}`);
				map.current.removeSource(e.target.id);
			} catch (err) {
				console.log("err", err);
			}
		}
	};

	const handleOnMapMove = (e) => {
		setLng(map.current.getCenter().lng.toFixed(4));
		setLat(map.current.getCenter().lat.toFixed(4));
		setZoom(map.current.getZoom().toFixed(2));
	};

const onClickChangeMapType = (type) => {
    let mapStyle;
    switch (type) {
        case mapTypes.streetView:
            mapStyle = "mapbox://styles/intakhabkhan/clikoeo8o00co01qpdpe9euh9";
            break;
        case mapTypes.satelliteView:
            mapStyle = "mapbox://styles/mapbox/satellite-v9";
            break;
        default:
            console.error("Invalid map type:", type);
            return;
    }

    if (type !== currentMapType) {
        setIsLoading(true);
        map.current.setStyle(mapStyle);
        map.current.once('style.load', () => {
            setIsLoading(false);

            if (type === mapTypes.satelliteView) {
                setUrl(LayersRef.current.data.data.lane_polygons);
                setGeojsonFilesURLs(LayersRef.current.data.data);
                handleSourceLoad(
                    { target: { checked: true, id: Object.keys(LayersRef.current.data.data).findIndex(item => item === "lane_polygons").toString() } },
                    "lane_polygons",
                    LayersRef.current.data.data.lane_polygons
                );
            }

            if (type === mapTypes.streetView) {
                setUrl(LayersRef.current.data.data.lane_polygons);
                setGeojsonFilesURLs(LayersRef.current.data.data);
                handleSourceLoad(
                    { target: { checked: true, id: Object.keys(LayersRef.current.data.data).findIndex(item => item === "lane_polygons").toString() } },
                    "lane_polygons",
                    LayersRef.current.data.data.lane_polygons
                );
            }

            if (type === mapTypes.streetView || type === mapTypes.satelliteView) {
                if (jsonRef.current) {
                    fetch(dataRef.current || geojsonFilesURLs[type])
                        .then((resp) => resp.json())
                        .then((json) => {
                            const sourceId = eRef.current.target.id;
                            if (!map.current.getSource(sourceId)) {
                                map.current.addSource(sourceId, { type: "geojson", data: json, generateId: true });
                            }           
                            if (type === "lane_polygons") {
                                map.current.setCenter(json["features"][0]["geometry"]["coordinates"][0][0]);
                                map.current.setZoom(14);
                            }
                            addValidationResultsToMap(jsonRef.current);
                        });
                }
                console.log("Data loading finished.");
            }
        });
        setCurrentMapType(type);
    } else {
        console.log("Already on the selected map type:", type);
    }
};

	const onClickNext = () => {
		const projectId = GetInfoFromLocalStorage('p_id')
		navigate(`/projects/${projectId}/applications/map-validator`)
	};

	const onClickBack = () => {
		switch (clickNextStage) {
			case 0:
				navigate(-1)
				break;
			case 1:
				setClickNextStage(0);
				break;
			default:
				break;
		}
		// navigate();
	};

const removeValidationResultsFromMap = () => {
    if (map.current.getSource("validation-results-source")) {
        map.current.removeLayer("validation-results-layer");
        map.current.removeSource("validation-results-source");
    }
};

const addValidationResultsToMap = (geojson) => {
    // Check if source exists with the same ID
    if (map.current.getSource("validation-results-source")) {
        map.current.removeLayer("validation-results-layer");
        map.current.removeSource("validation-results-source");
    }

    // Add new source and layer
    map.current.addSource("validation-results-source", { type: "geojson", data: geojson, generateId: true });
    map.current.addLayer({
        id: 'validation-results-layer',
        type: 'circle',
        source: 'validation-results-source',
        paint: {
           
            'circle-color': 'red', // Adjust circle color as needed
        },
    });
    map.current.setCenter(geojson["features"][0]["geometry"]["coordinates"][0][0]);
    map.current.setZoom(14);
    map.current.on("click", `validation-results-layer`, (clickEvent) => {
        console.log('clientEvent', clickEvent);
        console.log('features', clickEvent.features);
        setCurrentViewError(clickEvent.features[0]);
    });
};

	const onClickStartValidation = async () => {
    setLoadingMapValidationResults(true);
    try {
       
    } catch (err) {
        console.log(err);
    }
    try {
        const res = await httpGet({ url: `${VALIDATE_ODR_GID}/${MapValidator.oddData.gid_kwh}` });
        console.log('res', res);
        const validationResults = {};
        await fetch(res.data.data.summary_log)
            .then(resp => resp.text())
            .then(text => {
                validationResults.summary_log = text;
            });
        await fetch(res.data.data.error_log)
            .then(resp => resp.text())
            .then(text => {
                validationResults.error_log = text;
            });
        await fetch(res.data.data.geo_json)
            .then(resp => resp.json())
            .then(json => {
                console.log('json', json);
                validationResults.geo_json = json;
								   jsonRef.current = json;
									 console.log('json', json);
                addValidationResultsToMap(json);
            });
        setMapValidationResults({ ...validationResults });
    } catch (error) {
        console.log('error', error);
    } finally {
        setLoadingMapValidationResults(false);
    }
};

	// console.log('mapValidationResults', mapValidationResults)

	return (
		<Fragment>
			{loadingMapValidationResults && <ProgressX fullscreen={true} />}
			<div className={`container-fluid`}>
				<div className={`row`}>
					<div className={`col-12 d-flex justify-content-between`}>
						<div className={`d-flex`}></div>
						<div className={`d-flex`}>
							<ButtonX className={`me-2`} onClick={onClickBack} disabled={false}>
								Back
							</ButtonX>
							{!(loading || !mapValidationResults) && <ButtonX onClick={onClickNext}>Finish</ButtonX>
							}
						</div>
					</div>
				</div>
				<div className={`map-container mt-3`}>
					<div className={`map-available-geojsons-sidebar px-3 py-3 d-none`}>
						<Accordion sx={{ backgroundColor: "rgba(35, 55, 75, 0.6)" }}>
							<AccordionSummary expandIcon={<ExpandMoreRounded />}>
								<Typos.Body1>Available Map Layers</Typos.Body1>
							</AccordionSummary>
							<AccordionDetails>
								{loading ? (
									<ProgressX />
								) : (
									Object.keys(geojsonFilesURLs || {}).map((item, i) => {
										return (
											<div key={i} className={`mt-1`}>
												<SwitchButtonX id={`${i}`} defaultChecked={item === "lane_polygons"} onChange={(e) => handleSourceLoad(e, item)} label={PresentableText(item)}></SwitchButtonX>
											</div>
										);
									})
								)}
							</AccordionDetails>
						</Accordion>
					</div>
					{
						currentViewingError &&
						<div className={`map-available-geojsons-sidebar px-3 py-3`}>
							<div className="d-flex justify-content-between align-items-center">
								<Typos.H6>
									{currentViewingError.properties['error-type']}
								</Typos.H6>
								<IconButtonX
									icon={"ClearRounded"}
									onClick={() => setCurrentViewError(null)}
								/>
							</div>
							<hr />
							<Typos.Body1>
								{currentViewingError.properties['error-description']}
							</Typos.Body1>

						</div>
					}
					<div className={`map-validator-logs-sidebar`}>
						<div className={`row`}>
							<div className={`col-12 d-flex justify-content-center`}>

								<div className={`d-flex`}>
									<Tabs value={value} onChange={handleTabChange} sx={{ borderRadius: "6px" }} aria-label="lab API tabs example"
										TabIndicatorProps={{
											style: {
												backgroundColor: 'primary.main',
											},
										}}
									>
										<Tab label="Summary"

											sx={{
												backgroundColor: value === 0 ? 'primary.main' : 'primary.dark',
												minWidth: "8rem"
											}}
										/>
										<Tab label="Logs"
											sx={{
												backgroundColor: value === 1 ? 'primary.main' : 'primary.dark',
												minWidth: "8rem"
											}}
										/>
									</Tabs>
								</div>
								{/* <IconButtonX
									icon="CloudDownload"
									onClick={() => console.log('download', )}
								/> */}
							</div>
						</div>
						<hr />
						<div className="text-center">

							<Typos.H6>
								OpenDRIVE Map Validation {value === 0 ? "Summary" : "Logs"} Report
							</Typos.H6>
						</div>
						<hr />
						{
							mapValidationResults === null &&
							<div className="w-100 d-flex justify-content-center">
								{loading ? <div><ProgressX background={true} /></div> :
									<ButtonX className={`me-2`} onClick={onClickStartValidation}>Start Validation</ButtonX>
								}
							</div>
						}
						<div className="right-logs-tab-panel">
							<TabPanel value={value} index={0}>
								{
									mapValidationResults &&
									<pre>
										{mapValidationResults.summary_log}
									</pre>
								}
							</TabPanel>
							<TabPanel value={value} index={1}>
								{
									mapValidationResults &&
									<pre>
										{mapValidationResults.error_log}
									</pre>
								}
							</TabPanel>
						</div>
					</div>
					<div className={`map-types-bar`}>
						<IconButtonX wrapper size={"small"} icon={"StreetviewRounded"} onClick={() => onClickChangeMapType(mapTypes.streetView)} />
						<IconButtonX wrapper size={"small"} icon={"SatelliteRounded"} onClick={() => onClickChangeMapType(mapTypes.satelliteView)} />
					</div>

					<div ref={mapContainer} className="map-view" />
				</div>
			</div>
		</Fragment>
	);
}
)