import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { EducAgentsTableData } from "utils";

export default function EducAgentsTable({table, headers, fetching}) {
  return (
    <div className="mt-4">
      <DataGrid
        getRowId={(row) => row.id}
        rows={table}
        columns={headers}
        autoHeight={true}
        loading={fetching}
        className="main-table-height"
        sx={{
          border: "none",
          borderRadius: "12px",
          backgroundColor: "secondary.dark",
          "& .MuiDataGrid-cell": {
            color: "text.main",
          },
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": { marginTop: "15px", }
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
          sorting: {
            sortModel: [{ field: 'date_created', sort: 'desc' }],
          },
        }}
      />
    </div>
  )
}
