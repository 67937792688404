import {
  Button,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  Grid,
  Select,
  MenuItem,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import AuthWrapper from "components/authWrapper";
import {
  ThemesOptions,
  LocationOptions,
  LanguageOptions,
  TimeZoneOptions,
  UnitedSystemOptions,
  PageRedirectWithWindow,
} from "utils";
import { AUTH_SIGNUP_SERVICE } from "endpointServices/authServices";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Registration() {
  const [state, setState] = useState({
    email: "",
    firstName: "",
    secondName: "",
    departmentName: "",
    role: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, set_error] = useState("");

  const [selectTheme, setSelectTheme] = useState("");
  const [selectLocation, setSelectLocation] = useState("");
  const [selectLanguage, setSelectLanguage] = useState("");
  const [selectTimeZone, setSelectTimeZone] = useState("");
  const [selectUnitedSystem, setSelectUnitedSystem] = useState("");

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectTheme = (e) => {
    const { value } = e.target;
    setSelectTheme(value);
  };

  const handleSelectLocation = (e) => {
    const { value } = e.target;
    setSelectLocation(value);
  };

  const handleSelectLanguage = (e) => {
    const { value } = e.target;
    setSelectLanguage(value);
  };

  const handleSelectTimeZone = (e) => {
    const { value } = e.target;
    setSelectTimeZone(value);
  };

  const handleSelectUnitedSystem = (e) => {
    const { value } = e.target;
    setSelectUnitedSystem(value);
  };

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      email: state.email,
      first_name: state.firstName,
      last_name: state.secondName,
      // departmentName: state.departmentName,
      // role: state.role,
      // theme: selectTheme,
      // location: selectLocation,
      // language: selectLanguage,
      // timeZone: selectTimeZone,
      // unitedSystem: selectUnitedSystem,
      password: state.password,
    };
    console.log("payload", payload);
    const response = await AUTH_SIGNUP_SERVICE(payload)
    if (response.status === 200) {
      console.log('response', response)
      toast("User successfuly registered")
      setTimeout(() => {
        PageRedirectWithWindow('/auth')
      }, 2000)
      setLoading(false);

    }
    else {
      const { message } = response.data 
      toast(message || 'Failed to registered')
      setLoading(false);
    }
  };

  return (
    <AuthWrapper>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        theme="dark"
        type="info"
        hideProgressBar={true}
      />
      <CardHeader
        title="Setup your account"
        subheader="Let's start a new journey together, sign up now."
        titleTypographyProps={{ variant: "h5", color: "text.main" }}
        subheaderTypographyProps={{ variant: "caption", color: "text.main" }}
        sx={{ color: "text.main", padding: "5px" }}
      />
      <CardContent>
        <form onSubmit={onSubmitLogin}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="email"
                required
                type="email"
                variant="outlined"
                label="Email Address"
                placeholder="Enter you email"
                value={state.email}
                onChange={handleInputs}
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="firstName"
                required
                type="text"
                variant="outlined"
                label="First Name"
                placeholder="Enter you first name"
                value={state.firstName}
                onChange={handleInputs}
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="secondName"
                required
                type="text"
                variant="outlined"
                label="Second Name"
                placeholder="Enter you second name"
                value={state.secondName}
                onChange={handleInputs}
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="departmentName"
                // required
                type="text"
                variant="outlined"
                label="Department Name"
                placeholder="Enter you department name"
                value={state.departmentName}
                onChange={handleInputs}
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="role"
                type="text"
                variant="outlined"
                label="Role"
                placeholder="Enter you role"
                value={state.role}
                onChange={handleInputs}
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Language"
                name="language"
                type="select"
                variant="outlined"
                value={selectLanguage.label}
                onChange={handleSelectLanguage}
                fullWidth
                disabled={loading}
                select
                SelectProps={{
                  MenuProps: {
                    className: "select-dropdown",
                  },
                }}
              >
                {LanguageOptions.map((item, id) => {
                  return (
                    <MenuItem key={id} value={item.value}>
                      <ListItemText
                        primaryTypographyProps={{ align: "left" }}
                        primary={item.label}
                      />
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="TimeZone"
                name="timezone"
                type="select"
                variant="outlined"
                value={selectTimeZone.label}
                onChange={handleSelectTimeZone}
                fullWidth
                disabled={loading}
                select
                SelectProps={{
                  MenuProps: {
                    className: "select-dropdown",
                  },
                }}
              >
                {LanguageOptions.map((item, id) => {
                  return (
                    <MenuItem key={id} value={item.value}>
                      <ListItemText
                        primaryTypographyProps={{ align: "left" }}
                        primary={item.label}
                      />
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                label="United System"
                name="unitedsystem"
                type="select"
                variant="outlined"
                value={selectUnitedSystem.label}
                onChange={handleSelectUnitedSystem}
                fullWidth
                disabled={loading}
                select
                SelectProps={{
                  MenuProps: {
                    className: "select-dropdown",
                  },
                }}
              >
                {UnitedSystemOptions.map((item, id) => {
                  return (
                    <MenuItem key={id} value={item.value}>
                      <ListItemText
                        primaryTypographyProps={{ align: "left" }}
                        primary={item.label}
                      />
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid> */}
            <Grid item xs={12}>
              <TextField
                name="password"
                required
                type="password"
                variant="outlined"
                label="Create Password"
                placeholder="Enter you password"
                value={state.password}
                onChange={handleInputs}
                fullWidth
                disabled={loading}
              />
            </Grid>
          </Grid>

          <div className={`d-flex justify-content-center w-100 mt-3 mb-3`}>
            <Button
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                width: "431px",
              }}
              disabled={loading}
              type="submit"
            >
              Finish
            </Button>
          </div>

          <Typography variant="caption" color="text.main">
            By Registering in you agrees to our&nbsp;
            <Typography
              variant="caption"
              color="text.main"
              sx={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              Terms and Conditions
            </Typography>
          </Typography>
        </form>
      </CardContent>
    </AuthWrapper>
  );
}
