import {
  Button,
  IconButton,
  ListItemIcon,
  MenuItem,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Select,
  OutlinedInput,
  InputLabel,
  FormControl,
  CircularProgress
} from "@mui/material";
import React, { useEffect, useRef, useState, } from "react";
import { ODD_TAXONOMIES } from "constants/endpoints";
import { useSelector } from "react-redux";
import ButtonX from "components/buttonX";
import { httpGet } from "utils/httpMethods";
import STORE from "services/redux-store/store";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  postOddRequirement,
  putOddProjectService,
  putOddRequirement,
} from "endpointServices/oddServices";
import { ReadOnlyTag } from "components";
import { ArrowRight } from "@mui/icons-material";

const OddDefinition = () => {
  const { Odd } = STORE.getState();
  const navigate = useNavigate();
  const location = useLocation();
  const oddAttributesData = location.state?.oddAttributesData;
  console.log("oddAttributesData in the odd definition:", oddAttributesData);
  const { Projects } = STORE.getState();
  const projectId = Projects.projectId;
  const savedPayload = useSelector((state) => state.Odd.oddPayload);
  const [qualifier, setQualifier] = useState("");
  const [showDropdowns, setShowDropdowns] = useState(false);
  const [attributeNames, setAttributeNames] = useState([]);
  const [selectedAttributeName, setSelectedAttributeName] = useState("");
  const [selectedAttributeValue, setSelectedAttributeValue] = useState([]);
  const [endNumericValue, setEndNumericValue] = useState([]);
  const [enteredNumericValue, setEnteredNumericValue] = useState("");
  const [statements, setStatements] = useState([]);
  console.log("statements ---", statements)
  const responseDataRef = useRef(null);
  const responseDataRefState = useRef(null);
  const [selectCategory, setSelectCategory] = useState("");
  const [selectedNestedKeys, setSelectedNestedKeys] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElConditional, setAnchorElConditional] = useState(null);
  const [combinedSelectedValues, setCombinedSelectedValues] = useState("");
  const [qualifierState, setQualifierState] = useState("");
  const [showDropdownsState, setShowDropdownsState] = useState(false);
  const [selectedAttributeNameState, setSelectedAttributeNameState] =
    useState("");
  const [selectedNestedKeysState, setSelectedNestedKeysState] = useState([]);
  const [selectedAttributeValueState, setSelectedAttributeValueState] =
    useState("");
  const [selectedAttributeNamesState, setSelectedAttributeNamesState] =
    useState([]);
  const [anchorElState, setAnchorElState] = useState(null);
  const [anchorElStateConditional, setAnchorElStateConditional] = useState(null);
  const [showDiv, setShowDiv] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [enteredValue, setEnteredValue] = useState("");
  const [enteredValueNest, setEnteredValueNest] = useState("");
  const [selectedMainMenu, setSelectedMainMenu] = useState(null);
  const [selectedMainMenuConditional, setSelectedMainMenuConditional] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [selectedSubMenuConditional, setSelectedSubMenuConditional] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
  const [subMenuAnchorElConditional, setSubMenuAnchorElConditional] = useState(null);
  const [subSubMenuAnchorEl, setSubSubMenuAnchorEl] = useState(null);
  const [subSubSubMenuAnchorEl, setSubSubSubMenuAnchorEl] = useState(null);
  const [selectedSubSubMenu, setSelectedSubSubMenu] = useState(null);
  const [selectedSubSubSubMenu, setSelectedSubSubSubMenu] = useState(null);
  const [selectedSubSubMenuObject, setSelectedSubSubMenuObject] = useState({});
  console.log("selectedSubSubMenuObject ---", selectedSubSubMenuObject)
  const [subSubMenuAnchorElConditional, setSubSubMenuAnchorElConditional] = useState(null);
  const [selectedSubSubMenuConditional, setSelectedSubSubMenuConditional] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState([])
  console.log("selectedAttribute ---", selectedAttribute)
  const [conditionalSelectedSubSubMenuObject, setConditionalSelectedSubSubMenuObject] = useState({});
  const [conditionalSelectedAttribute, setConditionalSelectedAttribute] = useState([])
  const [selectedConditionalAttributeValue, setSelectedConditionalAttributeValue] = useState([]);
  const [conditionalEndNumericValue, setConditionalEndNumericValue] = useState([]);
  const [selectedSubSubSubSubMenuConditional, setSelectedSubSubSubSubMenuConditional] = useState(null);
  const [subSubSubMenuAnchorElConditional, setSubSubSubMenuAnchorElConditional] = useState(null);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [isAvtive, setIsActive] = useState(false)
  const [isAvtiveConditional, setIsActiveConditional] = useState(false)
  const [fieldError, setFieldError] = useState(false)
  const [endFieldError, setEndFieldError] = useState(false)
  const [singleNumericField, setSingleNumericField] = useState(false)
  const [conditionalStartFieldError, setConditionalStartFieldError] = useState(false)
  const [conditionalEndFieldError, setConditionalEndFieldError] = useState(false)
  const [ConditionalSingleNumericField, setConditionalSingleNumericField] = useState(false)

  useEffect(() => {
    if (oddAttributesData) {
      const newStatements = oddAttributesData.statements.map((statement) => ({
        qualifier_1: statement?.qualifier_1,
        qualifier_2: statement?.qualifier_2,
        influencing_attribute: {
          type: statement?.influencing_attribute?.type,
          name: statement?.influencing_attribute?.name,
          value: statement?.influencing_attribute?.value,
        },
        influenced_attribute: {
          type: statement?.influenced_attribute?.type,
          name: statement?.influenced_attribute?.name,
          value: statement?.influenced_attribute?.value,
        },
      }));

      setStatements([...statements, ...newStatements]);
      console.log("Values from oddAttributesData:", oddAttributesData);
    }
  }, []);

  const handleSelectOpen = () => {
    setIsSelectOpen(true);
  };

  const handleSelectClose = () => {
    setIsSelectOpen(false);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        backgroundColor: "rgba(32, 60, 104, 0.9)",
      },
    },
  };

  const handleInputChangeEnter = (event) => {
    const { value } = event.target;
    if (
      responseDataRef.current[0]?.taxonomy[selectedAttributeName]?.[
        selectedNestedKeys[0]
      ]?.[selectedNestedKeys[1]]?.attribute_type === "NumericRange"
    ) {
      setSelectedAttributeValue(value);
      setEnteredValue(value);
    } else {
      handleAttributeValueChange(event);
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      console.log("First Numeric Value", enteredValue);
      setSelectedAttributeValue(enteredValue);
    }
  };

  const handleEnterKeyPressNest = (event) => {
    if (event.key === "Enter") {
      console.log("Second Numeric Value", enteredValueNest);
      setSelectedAttributeValueState(enteredValueNest);
    }
  };

  const handleOnChange = (event) => {
    const selectedOption = event.target.value;
    console.log("Selected option:", selectedOption);
    setSelectCategory(selectedOption);
  };

  const renderNestedOptions = (obj) => {
    return Object.keys(obj).map((key, index) => (
      <MenuItem key={index} value={key}>
        {key}
      </MenuItem>
    ));
  };

  useEffect(() => {
    const combinedValue = `${selectedAttributeName} . ${
      selectedNestedKeys[0] || ""
    } . ${selectedNestedKeys[1] || ""} . ${selectedNestedKeys[2] || ""}`;
    setCombinedSelectedValues(combinedValue);
  }, [selectedAttributeName, selectedNestedKeys]);

  const open = Boolean(anchorEl, anchorElState);
   const openConditional = Boolean(anchorElConditional, anchorElStateConditional);

  const handleInputChangeState = (event, type, index) => {
    const value = event.target.value;

    if (type === "attributeName") {
      setSelectedAttributeNameState(value);
      setSelectedNestedKeysState([]);
      setAnchorElState(event.currentTarget);
      console.log("Selected attribute name in conditional case:", value);

      if (!value) return;
      const nestedKeys = responseDataRefState.current[0]?.taxonomy?.[value];

      if (nestedKeys && Array.isArray(nestedKeys)) {
        setSelectedNestedKeysState((prevSelectedKeys) => [nestedKeys[0]]);
        console.log("Selected nested keys:", nestedKeys.join(", "));
      } else {
        console.warn(
          "Nested keys for the selected attribute is not an array:",
          nestedKeys
        );
      }
    } else if (type === "nestedKey") {
      setSelectedNestedKeysState((prevSelectedKeys) => {
        const updatedKeysConditional = [...prevSelectedKeys];
        updatedKeysConditional[index] = value;
        console.log(
          "Selected nested keys in conditional case:",
          updatedKeysConditional.join(", ")
        );
        return updatedKeysConditional;
      });
    }
  };

  const renderNestedOptionsState = (obj) => {
    return Object.keys(obj).map((key, index) => (
      <MenuItem key={index} value={key}>
        {key}
      </MenuItem>
    ));
  };

  const handleAttributeValueChangeState = (event) => {
    const value = event.target.value;
    setSelectedAttributeValueState(value);
    console.log("Selected attribute value in conditional case:", value);
  };

  const handleAddStatement = () => {
    const combinedSelectedValues =
      selectedNestedKeys.length > 0
        ? `${selectedAttributeName}.${selectedNestedKeys.join(".")}`
        : "";
    const name = `${combinedSelectedValues}`;

    const conditionalSelectedValues =
      selectedNestedKeysState.length > 0
        ? `${selectedAttributeNameState}.${selectedNestedKeysState.join(".")}`
        : "";
    const conditionalName = `${conditionalSelectedValues}`;

    let selectedAttributeType = "";
    let selectedAttributeTypeConditional = "";

    if (selectedNestedKeys.length > 1) {
      const selectedAttribute =
        responseDataRef.current[0].taxonomy[selectedSubSubMenuObject][
          selectedNestedKeys[0]
        ][selectedNestedKeys[1]];
      selectedAttributeType = selectedAttribute.attribute_type;
    }

    if (selectedNestedKeysState.length > 1) {
      const selectedAttributeConditional =
        responseDataRef.current[0]?.taxonomy[selectedAttributeNameState]?.[
          selectedNestedKeysState[0]
        ]?.[selectedNestedKeysState[1]];
      if (selectedAttributeConditional) {
        selectedAttributeTypeConditional =
          selectedAttributeConditional.attribute_type;
      }
    }

    let valueArray = [];
    if (selectedSubSubMenuObject.attribute_type === "SingleValueNumeric") {
      valueArray.push(selectedAttributeValue);
    }
    if (selectedSubSubMenuObject.attribute_type === "NumericRange") {
      valueArray.push(selectedAttributeValue, endNumericValue);
    }
    if (selectedSubSubMenuObject.attribute_type === "TextList") {
      valueArray.push(selectedAttributeValue);
    }
    if (selectedSubSubMenuObject.attribute_type === "Boolean") {
      valueArray.push(selectedAttributeValue);
    }

    let valueArrayConditional = [];
    if (
      conditionalSelectedSubSubMenuObject.attribute_type ===
      "SingleValueNumeric"
    ) {
      valueArrayConditional.push(selectedConditionalAttributeValue);
    }
    if (conditionalSelectedSubSubMenuObject.attribute_type === "NumericRange") {
      valueArrayConditional.push(
        selectedConditionalAttributeValue,
        conditionalEndNumericValue
      );
    }
    if (conditionalSelectedSubSubMenuObject.attribute_type === "TextList") {
      valueArrayConditional.push(selectedConditionalAttributeValue);
    }
    if (conditionalSelectedSubSubMenuObject.attribute_type === "Boolean") {
      valueArrayConditional.push(selectedConditionalAttributeValue);
    }

    const selectAttribute = selectedAttribute
      .map(
        (attr) =>
          `${attr.selectedMainMenu}.${
            attr.selectedSubMenu ? attr.selectedSubMenu + "." : ""
          }${attr.subMenuItem ? attr.subMenuItem + "." : ""}${
            attr.selectedSubSubMenu ? attr.selectedSubSubMenu + "." : ""
          }${attr.subSubMenuItem ? attr.subSubMenuItem + "." : ""}${
            attr.selectedSubSubSubMenu ? attr.selectedSubSubSubMenu + "." : ""
          }${attr.subSubSubKey}`
      )
      .join(".");
    console.log("selectAttribute", selectAttribute);

    const selectAttributeConditional = conditionalSelectedAttribute
      .map(
        (attr) =>
          `${attr.selectedMainMenuConditional}.${
            attr.selectedSubMenuConditional
              ? attr.selectedSubMenuConditional + "."
              : ""
          }${attr.subMenuItem ? attr.subMenuItem + "." : ""}${
            attr.selectedSubSubMenuConditional
              ? attr.selectedSubSubMenuConditional + "."
              : ""
          }${attr.subSubMenuItem ? attr.subSubMenuItem + "." : ""}${
            attr.selectedSubSubSubSubMenuConditional
              ? attr.selectedSubSubSubSubMenuConditional + "."
              : ""
          }${attr.subSubSubKey}`
      )
      .join(".");
    console.log("selectAttributeConditional", selectAttributeConditional);

    // Check if the entered values are valid for NumericRange type
    if (selectedSubSubMenuObject.attribute_type === "NumericRange" || selectedSubSubMenuObject.attribute_type === "SingleValueNumeric") {
      const startValue = parseFloat(selectedAttributeValue);
      const endValue = parseFloat(endNumericValue);
      const singleNumericValue = parseFloat(selectedAttributeValue);
      if (startValue < 0 || endValue < 0 || singleNumericValue < 0) {
        // Show notification for negative values
        toast.error("Negative values are not allowed");
        return;
      }
    }

    // Check if the entered values are valid for NumericRange type
    if (conditionalSelectedSubSubMenuObject.attribute_type === "NumericRange" || conditionalSelectedSubSubMenuObject.attribute_type === "SingleValueNumeric") {
      const startValue = parseFloat(selectedConditionalAttributeValue);
      const endValue = parseFloat(conditionalEndNumericValue);
      const singleNumericValue = parseFloat(selectedConditionalAttributeValue);
      if (startValue < 0 || endValue < 0 || singleNumericValue < 0) {
        // Show notification for negative values
        toast.error("Negative values are not allowed");
        return;
      }
    }

    const newStatement = {
      qualifier_1: qualifier || null,
      qualifier_2: qualifierState || null,
      influencing_attribute:
        qualifier === "conditional"
          ? {
              type: conditionalSelectedSubSubMenuObject.attribute_type,
              name:
                qualifier === "conditional" ? selectAttributeConditional : null,
              value: valueArrayConditional || null,
            }
          : null,
      influenced_attribute:
        {
          type: selectedSubSubMenuObject.attribute_type,
          name: selectAttribute,
          value: valueArray,
        } || null,
    };

    setSelectedAttributeValueState("");
    setStatements([...statements, newStatement]);
    setSelectedNestedKeys([]);
    setSelectedAttributeNameState("");
    setSelectedAttributeName("");
    setQualifier("");
    setQualifierState("");
    setSelectedNestedKeysState("");
    setEnteredValue("");
    setEnteredValueNest("");
    setSelectedAttributeValue([]);
    setEndNumericValue([]);
    setConditionalEndNumericValue([]);
    setSelectedConditionalAttributeValue([]);
    setShowDiv("");
    setSelectedMainMenu(null);
    setSelectedMainMenuConditional(null);
    setShowDropdowns(false);
    setShowDropdownsState(false);
    setIsActive(false);
    setIsActiveConditional(false);

    console.log("New statement added:", newStatement);
  };

  const handleSubmit = async () => {
    setLoading(true)
    const payload = {
      odd_zone: savedPayload,
      odd_attributes: {
        taxonomy_name: "ISO-34503",
        taxonomy_version: "1.0",
        language_mode: selectCategory,
        statements: statements,
      },
    };
    console.log("payload", payload);

    if (Odd.type === "Edit" && Odd.gid) {
      try {
        const resp = await putOddRequirement(Odd.gid, payload);
        console.log("put response in the requirement", resp);
        if (resp.status === 200) {
          toast.success("ODD updated successfully");
          setTimeout(() => {
            setLoading(false)
            setStatements([]);
            navigate(`/projects/${projectId}/applications/odd`);
          }, 2000);
        } else {
          setLoading(false)
          console.log("findMultiPointRoute update error", resp);
        }
      } catch (error) {
        setLoading(false)
        console.error("Error updating ODD:", error);
      }
    } else {
      try {
        const resp = await postOddRequirement(payload);
        console.log("post response in the requirement", resp);
        if (resp.status === 200) {
          const response = await putOddProjectService({
            pralion_id: Odd.pralion_id,
            gid: resp.data.odd_requirement_id,
          });
          if (response?.status === 200) {
            console.log("response", response);

            toast.success("ODD created successfully");
            setTimeout(() => {
              navigate(`/projects/${projectId}/applications/odd`);
            }, 2000);
          } else {
            const err = response?.message || "Failed to get request";
            toast.error(`${err}. Please try again later`);
          }
        } else {
          console.log("findMultiPointRoute error", resp);
        }
      } catch (error) {
        console.error("Error creating ODD:", error);
      }
    }
  };

  const handleQualifierChangeState = async (event) => {
    const value = event.target.value;
    setQualifierState(value);
    console.log("Qualifier changed in case of conditional:", value);

    if (value === "include" || value === "exclude" || value === "conditional") {
      try {
        const res = await httpGet({ url: `${ODD_TAXONOMIES}` });
        if (res && res.data) {
          setSelectedAttributeNamesState(Object.keys(res.data));
          console.log("API Response:", res.data);
          responseDataRefState.current = res.data;
          console.log("Saved API Response:", responseDataRefState.current);
        } else {
          console.error("Error fetching data: Empty response");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    if (value === "include" || value === "exclude") {
      setShowDropdownsState(true);
    } else {
      setShowDropdownsState(false);
    }

    if (!responseDataRefState.current) {
      responseDataRefState.current = {};
    }
  };

  const handleQualifierChange = async (event) => {
    const value = event.target.value;
    setQualifier(value);
    console.log("Qualifier changed:", value);

    setSelectedNestedKeysState([null, null]);
    setSelectedAttributeNameState(null);

    if (value === "include" || value === "exclude" || value === "conditional") {
      try {
        const res = await httpGet({ url: `${ODD_TAXONOMIES}` });
        if (res && res.data) {
          setAttributeNames(Object.keys(res.data));
          console.log("API Response:", res.data);
          responseDataRef.current = res.data;
          console.log("Saved API Response:", responseDataRef.current);
        } else {
          console.error("Error fetching data: Empty response");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    if (value === "include" || value === "exclude" || value === "conditional") {
      setShowDropdowns(true);
    } else {
      setShowDropdowns(false);
    }

    setShowDiv(value === "conditional");

    if (!responseDataRef.current) {
      responseDataRef.current = {};
    }
  };

  const handleInputChange = (event, type, index) => {
    const value = event.target.value;
    if (type === "attributeName") {
      setSelectedAttributeName(value);
      setSelectedNestedKeys([]);
      setAnchorEl(event.currentTarget);
      console.log("Selected attribute name:", value);
      if (!value) return;
      const nestedKeys = responseDataRef.current[0]?.taxonomy?.[value];
      if (nestedKeys && Array.isArray(nestedKeys)) {
        setSelectedNestedKeys((prevSelectedKeys) => [nestedKeys[0]]);
        console.log("Selected nested keys:", nestedKeys.join(", "));
      } else {
        console.warn(
          "Nested keys for the selected attribute is not an array:",
          nestedKeys
        );
      }
    } else if (type === "nestedKey") {
      setSelectedNestedKeys((prevSelectedKeys) => {
        const updatedKeys = [...prevSelectedKeys];
        updatedKeys[index] = value;
        console.log("Selected nested keys:", updatedKeys.join(", "));
        return updatedKeys;
      });
    }
  };

  const handleAttributeValueChange = (event) => {
    const value = event.target.value;
    if (value < 0) { 
      // Set fieldError flag to true when a negative value is entered 
      setFieldError(true); 
      setSingleNumericField(true)
    } else { 
      // Reset fieldError flag if value is not negative 
      setFieldError(false); 
      setSingleNumericField(false)
    }
    setSelectedAttributeValue(value);
    console.log("Selected attribute value:", value);
  };

  const handleEndNumericAttributeValueChange = (event) => {
    const value = event.target.value;
    if (value < 0) { 
      // Set fieldError flag to true when a negative value is entered 
      setEndFieldError(true); 
    } else { 
      // Reset fieldError flag if value is not negative 
      setEndFieldError(false); 
    }
    setEndNumericValue(value);
  };

  const handleConditionalAttributeValueChange = (event) => {
    const value = event.target.value;
    if (value < 0) { 
      // Set fieldError flag to true when a negative value is entered 
      setConditionalStartFieldError(true); 
      setConditionalSingleNumericField(true)
    } else { 
      // Reset fieldError flag if value is not negative 
      setConditionalStartFieldError(false); 
      setConditionalSingleNumericField(false)
    }
    setSelectedConditionalAttributeValue(value);
    console.log("conditiona Selected attribute value:", value);
  };

  const handleConditionalEndNumericAttributeValueChange = (event) => {
    const value = event.target.value;
    if (value < 0) { 
      // Set fieldError flag to true when a negative value is entered 
      setConditionalEndFieldError(true); 
    } else { 
      // Reset fieldError flag if value is not negative 
      setConditionalEndFieldError(false); 
    }
    setConditionalEndNumericValue(value);
  };

  const handleGoBack = () => {
    window.history.back();
  };

  const isConditionalQualifierFieldsFilled = () => {
    return (
      // selectedAttributeName &&
      // selectedNestedKeys.length > 1 &&
      // selectedAttributeValue !== "" &&
      // selectedAttributeValueState !== "" &&
      selectedConditionalAttributeValue.length > 0 ||
      (typeof selectedConditionalAttributeValue === "boolean" || selectedConditionalAttributeValue.length > 0) &&
      qualifierState !== ""
    );
  };

  const isAddStatementButtonEnabled = () => {
    if (qualifier === "conditional") {
      return isConditionalQualifierFieldsFilled();
    } else {
      return (
        // selectedAttributeName &&
        // selectedNestedKeys.length > 1 &&
        selectedAttributeValue.length > 0 ||
        (typeof selectedAttributeValue === "boolean" || selectedAttributeValue.length > 0) &&
        qualifier !== ""
      );
    }
  };

  const handleMainMenuHover = (event, menuItem) => {
    setSelectedMainMenu(menuItem);
    setAnchorEl(event.currentTarget);
    setSelectedSubMenu(null);
    setSubMenuAnchorEl(null); // Close sub-menu when main menu is hovered
    setSelectedSubSubMenu(null);
    setSubSubMenuAnchorEl(null); // Close sub sub-menu when main menu is hovered
  };

  const handleMainMenuHoverConditional = (event, menuItem) => {
    setSelectedMainMenuConditional(menuItem);
    setAnchorElConditional(event.currentTarget);
    setSelectedSubMenuConditional(null);
    setSubMenuAnchorElConditional(null); // Close sub-menu when main menu is hovered
    setSelectedSubSubMenuConditional(null);
    setSubSubMenuAnchorElConditional(null); // Close sub sub-menu when main menu is hovered
  };

  const handleSubMenuHover = (event, subMenuItem, subObject) => {
    setIsActive(true)
    setSelectedSubMenu(subMenuItem);
    setSubMenuAnchorEl(event.currentTarget);
    setSelectedSubSubMenuObject(subObject);
    setSelectedSubSubMenu(null);
    setSubSubMenuAnchorEl(null); // Close sub sub-menu when sub-menu is hovered
    setSelectedSubSubSubMenu(null)
    setSubSubSubMenuAnchorEl(null)
    setSelectedAttribute([{ selectedMainMenu, subMenuItem }]);
  };

const handleSubMenuHoverConditional = (event, subMenuItem, conditionalSubObject) => {
    setIsActiveConditional(true)
    setSelectedSubMenuConditional(subMenuItem);
    setSubMenuAnchorElConditional(event.currentTarget);
    setConditionalSelectedSubSubMenuObject(conditionalSubObject)
    setSelectedSubSubMenuConditional(null);
    setSubSubMenuAnchorElConditional(null); // Close sub sub-menu when sub-menu is hovered
    setSelectedSubSubSubSubMenuConditional(null)
    setSubSubSubMenuAnchorElConditional(null)
    setConditionalSelectedAttribute([{selectedMainMenuConditional, subMenuItem}])
  };

  const handleSubSubMenuHover = (event, subSubMenuItem, subSubObject) => {
    setSelectedSubSubMenu(subSubMenuItem);
    setSubSubMenuAnchorEl(event.currentTarget);
    setSelectedSubSubMenuObject(subSubObject);
    setSelectedAttribute([
      { selectedMainMenu, selectedSubMenu, subSubMenuItem },
    ]);
  };

  const handleSubSubMenuClick = (event, subSubSubKey, subSubSubObject) => {
    setSelectedSubSubSubMenu(subSubSubKey);
    setSubSubSubMenuAnchorEl(event.currentTarget);
    setSelectedSubSubMenuObject(subSubSubObject);
    setSelectedAttribute([
      { selectedMainMenu, selectedSubMenu, selectedSubSubMenu, subSubSubKey },
    ]);
  }

  const handleSubSubSubMenuClick = (subSubSubKey, subSubSubObject) => {
    setSelectedSubSubMenuObject(subSubSubObject);
    setSelectedAttribute([
      { selectedMainMenu, selectedSubMenu, selectedSubSubMenu, selectedSubSubSubMenu, subSubSubKey },
    ]);
  }
  
 const handleSubSubMenuHoverConditional = (event, subSubMenuItem, conditionalSubSubObject) => {
    setSelectedSubSubMenuConditional(subSubMenuItem);
    setSubSubMenuAnchorElConditional(event.currentTarget);
    setConditionalSelectedSubSubMenuObject(conditionalSubSubObject)
    setConditionalSelectedAttribute([{selectedMainMenuConditional, selectedSubMenuConditional, subSubMenuItem},])
  };

  const handleSubSubMenuClickConditional = (event, subSubSubKey, conditionalSubSubSubObject) => {
    setSelectedSubSubSubSubMenuConditional(subSubSubKey)
    setSubSubSubMenuAnchorElConditional(event.currentTarget)
    setConditionalSelectedSubSubMenuObject(conditionalSubSubSubObject)
    setConditionalSelectedAttribute([{selectedMainMenuConditional, selectedSubMenuConditional, selectedSubSubMenuConditional, subSubSubKey}])
  };

  const handleSubSubSubMenuClickConditional = (subSubSubKey, conditionalSubSubSubObject) => {
    setConditionalSelectedSubSubMenuObject(conditionalSubSubSubObject)
    setConditionalSelectedAttribute([{selectedMainMenuConditional, selectedSubMenuConditional, selectedSubSubMenuConditional, selectedSubSubSubSubMenuConditional, subSubSubKey}])
  };

  const handleCloseMainMenu = () => {
    setAnchorEl(null);
    // setSelectedMainMenu(null);
  };

   const handleCloseMainMenuConditional = () => {
    setAnchorElConditional(null);
    // setSelectedMainMenuConditional(null);
  };

  const handleCloseSubMenu = () => {
    setSubMenuAnchorEl(null);
    setSelectedSubMenu(null);
  };

   const handleCloseSubMenuConditional = () => {
    setSubMenuAnchorElConditional(null);
    setSelectedSubMenuConditional(null);
  };

  const handleCloseSubSubMenu = () => {
    setSubSubMenuAnchorEl(null);
    setSelectedSubSubMenu(null);
  };

  const handleCloseSubSubSubMenu = () => {
    setSubSubSubMenuAnchorEl(null);
    setSelectedSubSubSubMenu(null);
  };

   const handleCloseSubSubMenuConditional = () => {
    setSubSubMenuAnchorElConditional(null);
    setSelectedSubSubMenuConditional(null);
  };

  const handleCloseSubSubSubMenuConditional = () => {
    setSubSubSubMenuAnchorElConditional(null);
    setSelectedSubSubSubSubMenuConditional(null);
  };

  const subMenuOpen = Boolean(subMenuAnchorEl);
  const subMenuOpenConditional = Boolean(subMenuAnchorElConditional);
  const subSubMenuOpen = Boolean(subSubMenuAnchorEl);
  const subSubSubMenuOpen = Boolean(subSubSubMenuAnchorEl);
  const subSubMenuOpenConditional = Boolean(subSubMenuAnchorElConditional);
  const subSubSubMenuOpenConditional = Boolean(subSubSubMenuAnchorElConditional);

  return (
    <>
      <section className="odd-definition">
        <div className={`row`}>
          <div className={`col-12 d-flex justify-content-between`}>
            <div className={``}>
              {Odd?.type === "Edit" && Odd.gid ? (
                <ReadOnlyTag />
              ) : (
                <IconButton sx={{ ml: 0 }}></IconButton>
              )}
            </div>
            <div className={`d-flex`}>
              <ButtonX
                className={`m-1`}
                style={{ marginRight: "10px !important" }}
                onClick={handleGoBack}
              >
                Back
              </ButtonX>
              <ButtonX
                className={`m-1`}
                onClick={handleSubmit}
                disabled={Odd.type === "Edit" || loading}
              >
                Submit{" "}
                {loading ? (
                  <CircularProgress
                    size={20}
                    style={{ marginLeft: "8px" }}
                    color="inherit"
                  />
                ) : (
                  ""
                )}
              </ButtonX>
            </div>
          </div>
        </div>
        <div
          className="mt-4"
          style={{
            backgroundColor: "rgba(32, 60, 104, 0.8)",
            padding: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <TextField
            label="Language Mode"
            name="selectCategory"
            size="small"
            variant="outlined"
            // value={selectCategory}
            value={
              Odd?.type === "Edit" && Odd.gid
                ? oddAttributesData?.language_mode
                : selectCategory
            }
            onChange={handleOnChange}
            sx={{ width: "180px", marginRight: "10px" }}
            fullWidth
            select
            disabled={Odd?.type === "Edit" && Odd.gid}
            SelectProps={{
              MenuProps: {
                className: "customDropdown",
              },
            }}
          >
            <MenuItem value="Permissive">Permissive</MenuItem>
            <MenuItem value="Restrictive">Restrictive</MenuItem>
            <MenuItem value="Default">Default</MenuItem>
          </TextField>

          {!(Odd?.type === "Edit" && Odd.gid) && (
            <TextField
              label="Qualifier"
              name="selectCategory"
              size="small"
              variant="outlined"
              sx={{ width: "180px" }}
              select
              value={qualifier}
              onChange={handleQualifierChange}
              disabled={selectCategory === ""}
              SelectProps={{
                MenuProps: {
                  className: "customDropdown",
                },
              }}
            >
              <MenuItem value="include">Include</MenuItem>
              <MenuItem value="exclude">Exclude</MenuItem>
              <MenuItem value="conditional">Conditional</MenuItem>
            </TextField>
          )}

          {showDropdowns && (
            <>
              <TextField
                label="Select Attribute Name"
                name="selectAttributeName"
                size="small"
                variant="outlined"
                select
                sx={{ marginLeft: "10px", width: "180px" }}
                SelectProps={{
                  MenuProps: {
                    className: "customDropdown",
                  },
                }}
                InputLabelProps={{
                  shrink: !!selectedMainMenu,
                }}
                value={selectedMainMenu}
                onChange={(event) => setSelectedMainMenu(event.target.value)}
              >
                {Object.keys(responseDataRef.current?.[0]?.taxonomy || {}).map(
                  (key, index) => {
                    const formattedKey = key
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase());
                    return (
                      <MenuItem
                        key={index}
                        value={key}
                        onMouseDown={(event) => handleMainMenuHover(event, key)}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {formattedKey}
                        <ListItemIcon sx={{ justifyContent: "end" }}>
                          <ArrowRight />
                        </ListItemIcon>
                      </MenuItem>
                    );
                  }
                )}
              </TextField>

              <Popover
                id="main-menu-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseMainMenu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {selectedMainMenu &&
                  Object.keys(
                    responseDataRef.current?.[0]?.taxonomy[selectedMainMenu] ||
                      {}
                  ).map((subKey, subIndex) => {
                    const formattedSubKey = subKey
                      .replace(/_/g, " ")
                      .replace(/\b\w/g, (char) => char.toUpperCase());
                    const selectedObject =
                      responseDataRef.current?.[0]?.taxonomy[
                        selectedMainMenu
                      ]?.[subKey] || {};
                    return (
                      <MenuItem
                        key={subIndex}
                        value={subKey}
                        onClick={(event) =>
                          handleSubMenuHover(event, subKey, selectedObject)
                        }
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          backgroundColor: "rgba(32, 60, 104, 0.9)",
                        }}
                      >
                        {formattedSubKey}
                        {selectedObject.attribute_type === undefined && (
                          <ListItemIcon sx={{ justifyContent: "end" }}>
                            <ArrowRight />
                          </ListItemIcon>
                        )}
                      </MenuItem>
                    );
                  })}
              </Popover>

              {!selectedSubSubMenuObject.attribute_type && (
                <Popover
                  id="sub-menu-popover"
                  open={subMenuOpen}
                  anchorEl={subMenuAnchorEl}
                  onClose={handleCloseSubMenu}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {selectedSubMenu &&
                    Object.keys(
                      responseDataRef.current?.[0]?.taxonomy[selectedMainMenu][
                        selectedSubMenu
                      ] || {}
                    ).map((subSubKey, subSubIndex) => {
                      const formattedSubSubKey = subSubKey
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase());
                      const selectedObject =
                        responseDataRef.current?.[0]?.taxonomy[
                          selectedMainMenu
                        ][selectedSubMenu]?.[subSubKey] || {};
                      return (
                        <MenuItem
                          key={subSubIndex}
                          value={subSubKey}
                          onClick={(event) =>
                            handleSubSubMenuHover(
                              event,
                              subSubKey,
                              selectedObject
                            )
                          }
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: "rgba(32, 60, 104, 0.9)",
                          }}
                        >
                          {formattedSubSubKey}
                          {selectedObject.attribute_type === undefined && (
                            <ListItemIcon sx={{ justifyContent: "end" }}>
                              <ArrowRight />
                            </ListItemIcon>
                          )}
                        </MenuItem>
                      );
                    })}
                </Popover>
              )}

              {!selectedSubSubMenuObject.attribute_type && (
                <Popover
                  id="sub-sub-menu-popover"
                  open={subSubMenuOpen}
                  anchorEl={subSubMenuAnchorEl}
                  onClose={handleCloseSubSubMenu}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {selectedSubSubMenu &&
                    Object.keys(
                      responseDataRef.current?.[0]?.taxonomy[
                        selectedMainMenu
                      ]?.[selectedSubMenu]?.[selectedSubSubMenu] || {}
                    ).map((subSubSubKey, subSubSubIndex) => {
                      const formattedSubSubSubKey = subSubSubKey
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase());
                      const selectedObject =
                        responseDataRef.current?.[0]?.taxonomy[
                          selectedMainMenu
                        ]?.[selectedSubMenu]?.[selectedSubSubMenu]?.[
                          subSubSubKey
                        ] || {};
                      return (
                        <MenuItem
                          key={subSubSubIndex}
                          value={subSubSubKey}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: "rgba(32, 60, 104, 0.9)",
                          }}
                          onClick={(event) =>
                            handleSubSubMenuClick(
                              event,
                              subSubSubKey,
                              selectedObject
                            )
                          }
                        >
                          {formattedSubSubSubKey}
                          {selectedObject.attribute_type === undefined && (
                            <ListItemIcon sx={{ justifyContent: "end" }}>
                              <ArrowRight />
                            </ListItemIcon>
                          )}
                        </MenuItem>
                      );
                    })}
                </Popover>
              )}

              {!selectedSubSubMenuObject.attribute_type && (
                <Popover
                  id="sub-sub-menu-popover"
                  open={subSubSubMenuOpen}
                  anchorEl={subSubSubMenuAnchorEl}
                  onClose={handleCloseSubSubSubMenu}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {selectedSubSubSubMenu &&
                    Object.keys(
                      responseDataRef.current?.[0]?.taxonomy[
                        selectedMainMenu
                      ]?.[selectedSubMenu]?.[selectedSubSubMenu]?.[
                        selectedSubSubSubMenu
                      ] || {}
                    ).map((subSubSubKey, subSubSubIndex) => {
                      const formattedSubSubSubKey = subSubSubKey
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase());
                      const selectedObject =
                        responseDataRef.current?.[0]?.taxonomy[
                          selectedMainMenu
                        ]?.[selectedSubMenu]?.[selectedSubSubMenu]?.[
                          selectedSubSubSubMenu
                        ]?.[subSubSubKey] || {};
                      return (
                        <MenuItem
                          key={subSubSubIndex}
                          value={subSubSubKey}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            backgroundColor: "rgba(32, 60, 104, 0.9)",
                          }}
                          onClick={() =>
                            handleSubSubSubMenuClick(
                              subSubSubKey,
                              selectedObject
                            )
                          }
                        >
                          {formattedSubSubSubKey}
                        </MenuItem>
                      );
                    })}
                </Popover>
              )}

              {isAvtive && selectedSubSubMenuObject.attribute_type === "TextList" && (
                <FormControl sx={{ marginLeft: "10px", width: 300 }}>
                  <InputLabel
                    id="demo-multiple-name-label"
                    sx={{
                      marginTop:
                        isSelectOpen || selectedAttributeValue.length > 0
                          ? "0px"
                          : "-7px",
                    }}
                  >
                    Select Value
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-name-label"
                    id="demo-multiple-name"
                    multiple
                    value={selectedAttributeValue}
                    onChange={(event) => handleAttributeValueChange(event)}
                    onOpen={handleSelectOpen}
                    onClose={handleSelectClose}
                    size="small"
                    input={<OutlinedInput label="Select Value" />}
                    MenuProps={MenuProps}
                  >
                    {selectedSubSubMenuObject.possible_values.map(
                      (value, index) => {
                        return (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              )}
              {isAvtive && selectedSubSubMenuObject.attribute_type === "Boolean" && (
                <TextField
                  select
                  label="Select Value"
                  value={selectedAttributeValue}
                  onChange={handleAttributeValueChange}
                  size="small"
                  variant="outlined"
                  fullWidth
                  sx={{
                    marginLeft: "10px",
                    width: "180px",
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: "customDropdown",
                    },
                  }}
                >
                  <MenuItem value={true}>True</MenuItem>
                  <MenuItem value={false}>False</MenuItem>
                </TextField>
              )}
              {isAvtive && selectedSubSubMenuObject.attribute_type === "SingleValueNumeric" && (
                <TextField
                  type="number"
                  label="Enter Numeric Value"
                  value={selectedAttributeValue}
                  onChange={handleAttributeValueChange}
                  size="small"
                  variant="outlined"
                  sx={{
                    marginLeft: "10px",
                    width: "180px",
                    backgroundColor: "inherit",
                    color: "inherit",
                    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': { '-webkit-appearance': 'none', margin: 0, },
                  }}
                  error={singleNumericField}
                />
              )}
              {isAvtive && selectedSubSubMenuObject.attribute_type === "NumericRange" && (
                <>
                  <TextField
                    type="number"
                    label="Start"
                    value={selectedAttributeValue}
                    onChange={handleAttributeValueChange}
                    size="small"
                    variant="outlined"
                    sx={{
                      marginLeft: "10px",
                      width: "180px",
                      backgroundColor: "inherit",
                      color: "inherit",
                      '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': { '-webkit-appearance': 'none', margin: 0, },
                    }}
                    error={fieldError}
                  />
                  <TextField
                    type="number"
                    label="End"
                    value={endNumericValue}
                    onChange={handleEndNumericAttributeValueChange}
                    size="small"
                    variant="outlined"
                    sx={{
                      marginLeft: "10px",
                      width: "180px",
                      backgroundColor: "inherit",
                      color: "inherit",
                      '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': { '-webkit-appearance': 'none', margin: 0, },
                    }}
                    error={endFieldError}
                  />
                </>
              )}

              {selectedAttributeName && (
                <>
                  <TextField
                    className="customDropdown"
                    label={`Select Type`}
                    name="selectNestedKey1"
                    size="small"
                    variant="outlined"
                    sx={{
                      marginLeft: "10px",
                      width: "180px",
                      backgroundColor: "inherit",
                      color: "inherit",
                    }}
                    select
                    value={selectedNestedKeys[0] || ""}
                    SelectProps={{
                      MenuProps: {
                        className: "customDropdown",
                      },
                    }}
                    onChange={(event) =>
                      handleInputChange(event, "nestedKey", 0)
                    }
                  >
                    {renderNestedOptions(
                      responseDataRef.current[0].taxonomy[selectedAttributeName]
                    )}
                  </TextField>

                  {selectedNestedKeys[0] && (
                    <TextField
                      label={`Select Sub Type`}
                      name="selectNestedKey2"
                      size="small"
                      variant="outlined"
                      sx={{
                        marginLeft: "10px",
                        width: "180px",
                        backgroundColor: "inherit",
                        color: "inherit",
                      }}
                      select
                      value={selectedNestedKeys[1] || ""}
                      SelectProps={{
                        MenuProps: {
                          className: "customDropdown",
                        },
                      }}
                      onChange={(event) =>
                        handleInputChange(event, "nestedKey", 1)
                      }
                    >
                      {renderNestedOptions(
                        responseDataRef.current[0].taxonomy[
                          selectedAttributeName
                        ][selectedNestedKeys[0]]
                      )}
                    </TextField>
                  )}

                  {selectedNestedKeys[1] && (
                    <TextField
                      label={
                        responseDataRef.current[0]?.taxonomy[
                          selectedAttributeName
                        ]?.[selectedNestedKeys[0]]?.[selectedNestedKeys[1]]
                          ?.attribute_type === "NumericRange"
                          ? "Enter Numeric Value"
                          : "Select Attribute Value"
                      }
                      name="selectAttributeValue"
                      size="small"
                      variant="outlined"
                      sx={{ marginLeft: "10px", width: "180px" }}
                      select={
                        responseDataRef.current[0]?.taxonomy[
                          selectedAttributeName
                        ]?.[selectedNestedKeys[0]]?.[selectedNestedKeys[1]]
                          ?.attribute_type !== "NumericRange"
                      }
                      value={
                        responseDataRef.current[0]?.taxonomy[
                          selectedAttributeName
                        ]?.[selectedNestedKeys[0]]?.[selectedNestedKeys[1]]
                          ?.attribute_type === "NumericRange"
                          ? enteredValue
                          : selectedAttributeValue || ""
                      }
                      SelectProps={
                        responseDataRef.current[0]?.taxonomy[
                          selectedAttributeName
                        ]?.[selectedNestedKeys[0]]?.[selectedNestedKeys[1]]
                          ?.attribute_type !== "NumericRange"
                          ? {
                              MenuProps: {
                                className: "customDropdown",
                              },
                            }
                          : null
                      }
                      onChange={
                        responseDataRef.current[0]?.taxonomy[
                          selectedAttributeName
                        ]?.[selectedNestedKeys[0]]?.[selectedNestedKeys[1]]
                          ?.attribute_type !== "NumericRange"
                          ? handleAttributeValueChange
                          : handleInputChangeEnter
                      }
                      type={
                        responseDataRef.current[0]?.taxonomy[
                          selectedAttributeName
                        ]?.[selectedNestedKeys[0]]?.[selectedNestedKeys[1]]
                          ?.attribute_type === "NumericRange"
                          ? "number"
                          : undefined
                      }
                    >
                      {responseDataRef.current[0]?.taxonomy[
                        selectedAttributeName
                      ]?.[selectedNestedKeys[0]]?.[selectedNestedKeys[1]]
                        ?.attribute_type === "TextList" ? (
                        (
                          responseDataRef.current[0]?.taxonomy[
                            selectedAttributeName
                          ]?.[selectedNestedKeys[0]]?.[selectedNestedKeys[1]]
                            ?.possible_values || []
                        ).map((value, index) => (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        ))
                      ) : responseDataRef.current[0]?.taxonomy[
                          selectedAttributeName
                        ]?.[selectedNestedKeys[0]]?.[selectedNestedKeys[1]]
                          ?.attribute_type === "Boolean" ? (
                        <>
                          <MenuItem value={true}>True</MenuItem>
                          <MenuItem value={false}>False</MenuItem>
                        </>
                      ) : (
                        // Otherwise, render default options
                        <MenuItem value="default">Default Value</MenuItem>
                      )}
                    </TextField>
                  )}
                </>
              )}
            </>
          )}

          <div style={{ marginLeft: "auto" }}>
            <ButtonX
              style={{
                backgroundColor: "#00A3B5",
                color: "white",
                border: "none",
                padding: "6px 50px",
                margin: "10px",
              }}
              onClick={handleAddStatement}
              disabled={!isAddStatementButtonEnabled()}
            >
              Add Statement
            </ButtonX>
          </div>
        </div>
        <></>
        {showDiv && (
          <div
            className="mt-4"
            style={{
              backgroundColor: "rgba(32, 60, 104, 0.8)",
              padding: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <TextField
              label="Qualifier 2"
              name="selectCategory"
              size="small"
              variant="outlined"
              sx={{ width: "180px" }}
              select
              value={qualifierState}
              onChange={handleQualifierChangeState}
              SelectProps={{
                MenuProps: {
                  className: "customDropdown",
                },
              }}
            >
              <MenuItem value="include">Include</MenuItem>
              <MenuItem value="exclude">Exclude</MenuItem>
            </TextField>

            {showDropdownsState && (
              <>
                <>
                  <TextField
                    label="Select Attribute Name"
                    name="selectAttributeName"
                    size="small"
                    variant="outlined"
                    select
                    sx={{ marginLeft: "10px", width: "180px" }}
                    SelectProps={{
                      MenuProps: {
                        className: "customDropdown",
                      },
                    }}
                    InputLabelProps={{
                      shrink: !!selectedMainMenuConditional,
                    }}
                    value={selectedMainMenuConditional}
                    onChange={(event) =>
                      setSelectedMainMenuConditional(event.target.value)
                    }
                  >
                    {Object.keys(
                      responseDataRef.current?.[0]?.taxonomy || {}
                    ).map((key, index) => {
                      const formattedKeyConditional = key
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (char) => char.toUpperCase());
                      return (
                        <MenuItem
                          key={index}
                          value={key}
                          onMouseDown={(event) =>
                            handleMainMenuHoverConditional(event, key)
                          }
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {formattedKeyConditional}
                          <ListItemIcon sx={{ justifyContent: "end" }}>
                            <ArrowRight />
                          </ListItemIcon>
                        </MenuItem>
                      );
                    })}
                  </TextField>

                  <Popover
                    id="main-menu-popover-conditional"
                    open={openConditional}
                    anchorEl={anchorElConditional}
                    onClose={handleCloseMainMenuConditional}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {selectedMainMenuConditional &&
                      Object.keys(
                        responseDataRef.current?.[0]?.taxonomy[
                          selectedMainMenuConditional
                        ] || {}
                      ).map((subKeyConditional, subIndexConditional) => {
                        const formattedSubKeyConditional = subKeyConditional
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (char) => char.toUpperCase());
                        const selectedObjectConditional =
                          responseDataRef.current?.[0]?.taxonomy[
                            selectedMainMenuConditional
                          ]?.[subKeyConditional] || {};
                        return (
                          <MenuItem
                            key={subIndexConditional}
                            value={subKeyConditional}
                            onClick={(event) =>
                              handleSubMenuHoverConditional(
                                event,
                                subKeyConditional,
                                selectedObjectConditional
                              )
                            }
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              backgroundColor: "rgba(32, 60, 104, 0.9)",
                            }}
                          >
                            {formattedSubKeyConditional}
                            {selectedObjectConditional.attribute_type ===
                              undefined && (
                              <ListItemIcon sx={{ justifyContent: "end" }}>
                                <ArrowRight />
                              </ListItemIcon>
                            )}
                          </MenuItem>
                        );
                      })}
                  </Popover>

                  {!conditionalSelectedSubSubMenuObject.attribute_type && (
                    <Popover
                      id="sub-menu-popover-conditional"
                      open={subMenuOpenConditional}
                      anchorEl={subMenuAnchorElConditional}
                      onClose={handleCloseSubMenuConditional}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      {selectedSubMenuConditional &&
                        Object.keys(
                          responseDataRef.current?.[0]?.taxonomy[
                            selectedMainMenuConditional
                          ][selectedSubMenuConditional] || {}
                        ).map(
                          (subSubKeyConditional, subSubIndexConditional) => {
                            const formattedSubSubKeyConditional =
                              subSubKeyConditional
                                .replace(/_/g, " ")
                                .replace(/\b\w/g, (char) => char.toUpperCase());
                            const selectedObjectConditional =
                              responseDataRef.current?.[0]?.taxonomy[
                                selectedMainMenuConditional
                              ][selectedSubMenuConditional]?.[
                                subSubKeyConditional
                              ] || {};
                            return (
                              <MenuItem
                                key={subSubIndexConditional}
                                value={subSubKeyConditional}
                                onClick={(event) =>
                                  handleSubSubMenuHoverConditional(
                                    event,
                                    subSubKeyConditional,
                                    selectedObjectConditional
                                  )
                                }
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  backgroundColor: "rgba(32, 60, 104, 0.9)",
                                }}
                              >
                                {formattedSubSubKeyConditional}
                                {selectedObjectConditional.attribute_type ===
                                  undefined && (
                                  <ListItemIcon sx={{ justifyContent: "end" }}>
                                    <ArrowRight />
                                  </ListItemIcon>
                                )}
                              </MenuItem>
                            );
                          }
                        )}
                    </Popover>
                  )}

                  {!conditionalSelectedSubSubMenuObject.attribute_type && (
                    <Popover
                      id="sub-sub-menu-popover-conditional"
                      open={subSubMenuOpenConditional}
                      anchorEl={subSubMenuAnchorElConditional}
                      onClose={handleCloseSubSubMenuConditional}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      {selectedSubSubMenuConditional &&
                        Object.keys(
                          responseDataRef.current?.[0]?.taxonomy[
                            selectedMainMenuConditional
                          ][selectedSubMenuConditional]?.[
                            selectedSubSubMenuConditional
                          ] || {}
                        ).map(
                          (
                            subSubSubKeyConditional,
                            subSubSubIndexConditional
                          ) => {
                            const formattedSubSubSubKeyConditional =
                              subSubSubKeyConditional
                                .replace(/_/g, " ")
                                .replace(/\b\w/g, (char) => char.toUpperCase());
                            const selectedObjectConditional =
                              responseDataRef.current?.[0]?.taxonomy[
                                selectedMainMenuConditional
                              ][selectedSubMenuConditional]?.[
                                selectedSubSubMenuConditional
                              ]?.[subSubSubKeyConditional] || {};
                            return (
                              <MenuItem
                                key={subSubSubIndexConditional}
                                value={subSubSubKeyConditional}
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  backgroundColor: "rgba(32, 60, 104, 0.9)",
                                }}
                                onClick={(event) =>
                                  handleSubSubMenuClickConditional(
                                    event,
                                    subSubSubKeyConditional,
                                    selectedObjectConditional
                                  )
                                }
                              >
                                {formattedSubSubSubKeyConditional}
                                {selectedObjectConditional.attribute_type ===
                                  undefined && (
                                  <ListItemIcon sx={{ justifyContent: "end" }}>
                                    <ArrowRight />
                                  </ListItemIcon>
                                )}
                              </MenuItem>
                            );
                          }
                        )}
                    </Popover>
                  )}

                  {!conditionalSelectedSubSubMenuObject.attribute_type && (
                    <Popover
                      id="sub-sub-menu-popover"
                      open={subSubSubMenuOpenConditional}
                      anchorEl={subSubSubMenuAnchorElConditional}
                      onClose={handleCloseSubSubSubMenuConditional}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      {selectedSubSubSubSubMenuConditional &&
                        Object.keys(
                          responseDataRef.current?.[0]?.taxonomy[
                            selectedMainMenuConditional
                          ]?.[selectedSubMenuConditional]?.[
                            selectedSubSubMenuConditional
                          ]?.[selectedSubSubSubSubMenuConditional] || {}
                        ).map((subSubSubKey, subSubSubIndex) => {
                          const formattedSubSubSubKey = subSubSubKey
                            .replace(/_/g, " ")
                            .replace(/\b\w/g, (char) => char.toUpperCase());
                          const selectedObject =
                            responseDataRef.current?.[0]?.taxonomy[
                              selectedMainMenuConditional
                            ]?.[selectedSubMenuConditional]?.[
                              selectedSubSubMenuConditional
                            ]?.[selectedSubSubSubSubMenuConditional]?.[
                              subSubSubKey
                            ] || {};
                          return (
                            <MenuItem
                              key={subSubSubIndex}
                              value={subSubSubKey}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                backgroundColor: "rgba(32, 60, 104, 0.9)",
                              }}
                              onClick={() =>
                                handleSubSubSubMenuClickConditional(
                                  subSubSubKey,
                                  selectedObject
                                )
                              }
                            >
                              {formattedSubSubSubKey}
                            </MenuItem>
                          );
                        })}
                    </Popover>
                  )}

                  {isAvtiveConditional && conditionalSelectedSubSubMenuObject.attribute_type === "TextList" && (
                    <FormControl sx={{ marginLeft: "10px", width: 300 }}>
                      <InputLabel
                        id="demo-multiple-name-label"
                        sx={{
                          marginTop:
                            isSelectOpen ||
                            selectedConditionalAttributeValue.length > 0
                              ? "0px"
                              : "-7px",
                        }}
                      >
                        Select Value
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={selectedConditionalAttributeValue}
                        onChange={handleConditionalAttributeValueChange}
                        onOpen={handleSelectOpen}
                        onClose={handleSelectClose}
                        size="small"
                        input={<OutlinedInput label="Select Value" />}
                        MenuProps={MenuProps}
                      >
                        {conditionalSelectedSubSubMenuObject.possible_values.map(
                          (value, index) => {
                            return (
                              <MenuItem key={index} value={value}>
                                {value}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </FormControl>
                  )}
                  {isAvtiveConditional && conditionalSelectedSubSubMenuObject.attribute_type === "Boolean" && (
                    <TextField
                      select
                      label="Select Value"
                      value={selectedConditionalAttributeValue}
                      onChange={handleConditionalAttributeValueChange}
                      size="small"
                      variant="outlined"
                      fullWidth
                      sx={{
                        marginLeft: "10px",
                        width: "180px",
                      }}
                      SelectProps={{
                        MenuProps: {
                          className: "customDropdown",
                        },
                      }}
                    >
                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </TextField>
                  )}
                  {isAvtiveConditional && conditionalSelectedSubSubMenuObject.attribute_type === "SingleValueNumeric" && (
                    <TextField
                      type="number"
                      label="Enter Numeric Value"
                      value={selectedConditionalAttributeValue}
                      onChange={handleConditionalAttributeValueChange}
                      size="small"
                      variant="outlined"
                      sx={{
                        marginLeft: "10px",
                        width: "180px",
                        backgroundColor: "inherit",
                        color: "inherit",
                        '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': { '-webkit-appearance': 'none', margin: 0, },
                      }}
                      error={ConditionalSingleNumericField}
                    />
                  )}
                  {isAvtiveConditional && conditionalSelectedSubSubMenuObject.attribute_type === "NumericRange" && (
                    <>
                      <TextField
                        type="number"
                        label="Start"
                        value={selectedConditionalAttributeValue}
                        onChange={handleConditionalAttributeValueChange}
                        size="small"
                        variant="outlined"
                        sx={{
                          marginLeft: "10px",
                          width: "180px",
                          backgroundColor: "inherit",
                          color: "inherit",
                          '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': { '-webkit-appearance': 'none', margin: 0, },
                        }}
                        error={conditionalStartFieldError}
                      />
                      <TextField
                        type="number"
                        label="End"
                        value={conditionalEndNumericValue}
                        onChange={handleConditionalEndNumericAttributeValueChange}
                        size="small"
                        variant="outlined"
                        sx={{
                          marginLeft: "10px",
                          width: "180px",
                          backgroundColor: "inherit",
                          color: "inherit",
                          '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button': { '-webkit-appearance': 'none', margin: 0, },
                        }}
                        error={conditionalEndFieldError}
                      />
                    </>
                  )}

                  {selectedAttributeNameState && (
                    <>
                      <TextField
                        className="customDropdown"
                        label={`Select Type`}
                        name="selectNestedKey1"
                        size="small"
                        variant="outlined"
                        sx={{
                          marginLeft: "10px",
                          width: "180px",
                          backgroundColor: "inherit",
                          color: "inherit",
                        }}
                        select
                        value={selectedNestedKeysState[0] || ""}
                        SelectProps={{
                          MenuProps: {
                            className: "customDropdown",
                          },
                        }}
                        onChange={(event) =>
                          handleInputChangeState(event, "nestedKey", 0)
                        }
                      >
                        {renderNestedOptionsState(
                          responseDataRefState.current[0].taxonomy[
                            selectedAttributeNameState
                          ]
                        )}
                      </TextField>

                      {selectedNestedKeysState[0] && (
                        <TextField
                          label={`Select Sub Type`}
                          name="selectNestedKey2"
                          size="small"
                          variant="outlined"
                          sx={{
                            marginLeft: "10px",
                            width: "180px",
                            backgroundColor: "inherit",
                            color: "inherit",
                          }}
                          select
                          value={selectedNestedKeysState[1] || ""}
                          SelectProps={{
                            MenuProps: {
                              className: "customDropdown",
                            },
                          }}
                          onChange={(event) =>
                            handleInputChangeState(event, "nestedKey", 1)
                          }
                        >
                          {renderNestedOptionsState(
                            responseDataRefState.current[0].taxonomy[
                              selectedAttributeNameState
                            ][selectedNestedKeysState[0]]
                          )}
                        </TextField>
                      )}

                      {selectedNestedKeysState[1] && (
                        <TextField
                          label={
                            responseDataRefState.current[0]?.taxonomy[
                              selectedAttributeNameState
                            ]?.[selectedNestedKeysState[0]]?.[
                              selectedNestedKeysState[1]
                            ]?.attribute_type === "NumericRange"
                              ? "Enter Numeric Value"
                              : "Select Attribute Value"
                          }
                          name="selectAttributeValue"
                          size="small"
                          variant="outlined"
                          sx={{ marginLeft: "10px", width: "180px" }}
                          select={
                            responseDataRefState.current[0]?.taxonomy[
                              selectedAttributeNameState
                            ]?.[selectedNestedKeysState[0]]?.[
                              selectedNestedKeysState[1]
                            ]?.attribute_type !== "NumericRange"
                          }
                          value={selectedAttributeValueState || ""}
                          onChange={(event) => {
                            const newValue = event.target.value;
                            setSelectedAttributeValueState(newValue);
                          }}
                          SelectProps={
                            responseDataRefState.current[0]?.taxonomy[
                              selectedAttributeNameState
                            ]?.[selectedNestedKeysState[0]]?.[
                              selectedNestedKeysState[1]
                            ]?.attribute_type === "TextList"
                              ? {
                                  MenuProps: {
                                    className: "customDropdown",
                                  },
                                }
                              : null
                          }
                        >
                          {responseDataRefState.current[0]?.taxonomy[
                            selectedAttributeNameState
                          ]?.[selectedNestedKeysState[0]]?.[
                            selectedNestedKeysState[1]
                          ]?.attribute_type === "TextList" &&
                            (
                              responseDataRefState.current[0]?.taxonomy[
                                selectedAttributeNameState
                              ]?.[selectedNestedKeysState[0]]?.[
                                selectedNestedKeysState[1]
                              ]?.possible_values || []
                            ).map((value, index) => (
                              <MenuItem key={index} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          {responseDataRefState.current[0]?.taxonomy[
                            selectedAttributeNameState
                          ]?.[selectedNestedKeysState[0]]?.[
                            selectedNestedKeysState[1]
                          ]?.attribute_type === "Boolean" && (
                            <>
                              <MenuItem value={true}>True</MenuItem>
                              <MenuItem value={false}>False</MenuItem>
                            </>
                          )}
                          {!responseDataRefState.current[0]?.taxonomy[
                            selectedAttributeNameState
                          ]?.[selectedNestedKeysState[0]]?.[
                            selectedNestedKeysState[1]
                          ]?.attribute_type && (
                            <MenuItem value="">Default Value</MenuItem>
                          )}
                        </TextField>
                      )}
                    </>
                  )}
                </>
              </>
            )}
          </div>
        )}
      </section>
      <section>
        <TableContainer
          sx={{
            borderCollapse: "collapse",
            width: "100%",
            backgroundColor: "rgba(32, 60, 104, 0.8)",
            marginTop: "20px",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Qualifier 1</TableCell>
                <TableCell>Influenced Attribute</TableCell>
                <TableCell>Influenced Value</TableCell>
                <TableCell>Qualifier 2</TableCell>
                <TableCell>Influencing Attribute</TableCell>
                <TableCell>Influencing Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statements.map((statement, index) => (
                <TableRow
                  key={index}
                  sx={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}
                >
                  <TableCell component="th" scope="row">
                    {statement.qualifier_1}
                  </TableCell>
                  <TableCell>
                    {statement.influenced_attribute.name || "null.."}
                  </TableCell>
                  <TableCell>
                    {statement.influenced_attribute.value.join(", ") || "null.."}
                  </TableCell>
                  <TableCell>{statement.qualifier_2 || "null.."}</TableCell>
                  <TableCell>
                    {statement?.influencing_attribute?.name || "null.."}
                  </TableCell>
                  <TableCell>
                    {Array.isArray(statement?.influencing_attribute?.value)
                      ? statement?.influencing_attribute?.value.join(", ")
                      : "null.."}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    </>
  );
};

export default OddDefinition;
