import React, { useEffect, useState } from "react";
import TESTIMAGE from "assets/images/exe.png";
import { CardX, ImageButton, ProgressX } from "components";
import { useNavigate } from "react-router-dom";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { connect } from "react-redux";
import { ReduxUpdateDSpaceJourney } from "services/redux-store/actions/actions-dspace";
import { GET_SHOW_ODDS_MAPS, ODD_TO_GEOJSON } from "constants/endpoints";
import { httpPost } from "utils/httpMethods";

function DSpaceSpawningStrategies({ ReduxDSpace, ReduxUpdateDSpaceJourney }) {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);

	const Strategies = [
		{
			name: "Perception Based Spawning",
			image: TESTIMAGE,
			onClick: () => navigate(AUTHENTICATED_ROUTES.perceptionBased),
			active: true,
		},
		{
			name: "Rate Based Spawning",
			image: TESTIMAGE,
			onClick: () => navigate(AUTHENTICATED_ROUTES.rateBased),
			active: true,
		},
		{
			name: "Spawn Everywhere",
			image: TESTIMAGE,
			onClick: () => {
				ReduxUpdateDSpaceJourney({ choosenStrategy: "everywhere" })
				navigate(AUTHENTICATED_ROUTES.dSpaceTrafficConfig)
			},
			active: true,
		},
	];

	const fetchMapLayers = async () => {
		if (ReduxDSpace.userMap) {
			setLoading(false);
		} else {
			setLoading(true);
			await httpPost({
				url: ODD_TO_GEOJSON,
				payload: {
					odd_polygon: {
						type: "FeatureCollection",
						features: [ReduxDSpace.selectedOdd],
					},
					map_id: ReduxDSpace.mapId,
					gid: ReduxDSpace.selectedOdd.properties.id,
				},
			})
				.then((res) => {
					ReduxUpdateDSpaceJourney({ geojsonMapIdAndOddPolygon: res.data.data.lane_polygons });
				})
				.catch((err) => {
					console.log("err", err);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	useEffect(() => {
		fetchMapLayers();
	}, []);

	return (
		<CardX nodefaultclasses={true} title={"Spawning Strategies"} className={`mt-3 bg-transparent`}>
			<div className={`container`}>
				<div className={`row mb-3`}>
					{Strategies.map((strategy, index) => {
						return (
							<div key={index} className={`col-lg-4 col-md-12 col-sm-12 text-center py-3`}>
								{loading ? (
									<ProgressX block={true} height={"28vh"} width={"100%"} />
								) : (
									<ImageButton
										src={ReduxDSpace.userMap ? TESTIMAGE : `${GET_SHOW_ODDS_MAPS}/${ReduxDSpace.selectedOdd.properties.id}`}
										title={strategy.name}
										height={"28vh"}
										width={"100%"}
										active={strategy.active}
										onClick={strategy.onClick}
									/>
								)}
							</div>
						);
					})}
				</div>
			</div>
		</CardX>
	);
}
function mapStateToProps(state) {
	return {
		ReduxDSpace: state.DSpace,
	};
}
export default connect(mapStateToProps, { ReduxUpdateDSpaceJourney })(DSpaceSpawningStrategies);
