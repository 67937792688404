import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Box, Grid, Radio, Button } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { getAutoScenarioProjectsById } from "endpointServices/autoScenarioServices";
import AutoScenarioStatus from "../../../autoScenarios/autoScenariosComponent/autoScenarioStatus";
import dateConverter from "utils/dateConverter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { wrapText, checkDagStatus } from "utils";
import { ButtonX, ModalX } from "components";
import { pageSize } from "utils/staticsData";
import MainTable from "components/mainTable";
import { ReduxUpdateLowFidelityJourney } from "services/redux-store/actions/actions-lowFidelity";
import { connect } from "react-redux";
import GetVersionList from "components/getVersionList";

const LowFidelityAutoScenario = ({ ReduxUpdateLowFidelityJourney }) => {
  const param = useParams();
  let navigator = useNavigate();

  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [statusOpen, setStatusOpen] = useState(false);
  const [statusData, setStatusData] = useState({
    name: "",
    pralionId: "",
  });

  // To Open version modal
  const [versionIds, setVersionIds] = useState([]);
  const [versionModalOpen, setVersionModalOpen] = useState(false);

  const handleStatusOpenModal = (data) => {
    setStatusData({
      name: data.name,
      pralionId: data.pralion_id,
    });
    setStatusOpen(true);
  };

  const handleClose = () => setStatusOpen(false);

  useEffect(() => {
    getProjectList({ pageNumber: page, testsPerPage: pageSize });
    localStorage.removeItem("p_id");
    localStorage.removeItem("pralion_id");
  }, [page]);

  const getProjectList = async (pagination) => {
    setFetching(true);
    const projectId = parseInt(param.id);
    const response = await getAutoScenarioProjectsById(projectId, pagination);
    console.log("response", response);
    if (response?.status === 200) {
      setFetching(false);
      const data = response?.data?.data?.applications || [];
      // const listFilter = data.filter(ele => ele.no_of_scenarios !== 0)
      setList(data);
      setTotalRows(response?.data?.data?.no_of_applications);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setList([]);
      setFetching(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleVersionModal = (data) => {
    setVersionIds(data);
    setVersionModalOpen(true);
    ReduxUpdateLowFidelityJourney({ autoScenarioPralionId: data.pralion_id });
  };

  const executionList = (data) => {
    ReduxUpdateLowFidelityJourney({ autoScenarioVersionId: data });
    navigator({
      pathname: `/projects/${param.id}/applications/low-fidelity/auto-scenario/auto-scenario-list`,
    });
  };

  const onClickNext = () => {
    navigator({
      pathname: `/projects/${param.id}/applications/low-fidelity/observer`,
    });
  };

  const columns = useMemo(
    () => [
      {
        field: "name",
        renderHeader: () => (
          <span className="heading-2">{"Project Name "}</span>
        ),
        width: 550,
        renderCell: (params) => {
          const isRowDisabled = params.row?.dag_state?.dag_state;
          return (
            <Grid>
              <Button
                className="Main-link-simple"
                onClick={() => handleVersionModal(params.row)}
                sx={{ color: "white" }}
                // disabled={checkDagStatus(isRowDisabled)}
              >
                {wrapText(params.value)}
              </Button>
            </Grid>
          );
        },
      },
      {
        field: "description",
        renderHeader: () => <span className="heading-2">{"Description"}</span>,
        width: 360,
      },
      {
        field: "date_created",
        renderHeader: () => <span className="heading-2">{"Created At"}</span>,
        width: 183,
        renderCell: (params) => (
          <span className="row-heading">{dateConverter(params.value)}</span>
        ),
      },
      {
        field: "action",
        renderHeader: () => <span className="heading-2">{"Status"}</span>,
        width: 235,
        renderCell: (params) => {
          const status = params.row?.dag_state?.dag_state;
          return (
            <>
              <div className="statusRadioButton">
                <Radio
                  color="success"
                  checked={status === "success"}
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "#4caf50",
                      color: "#4caf50",
                      "&:checked + span": {
                        backgroundColor: "#4caf50",
                        color: "#4caf50",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />

                <Radio
                  checked={status === "inprogress"}
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "#ff9800",
                      color: "#ff9800",
                      "&:checked + span": {
                        backgroundColor: "#ff9800",
                        color: "#ff9800",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
                <Radio
                  checked={status === "failed"}
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "red",
                      color: "red",
                      "&:checked + span": {
                        backgroundColor: "red",
                        color: "red",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />

                <Radio
                  checked={
                    status === "pending" || !params.row.dag_state.dag_state
                  }
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "gray",
                      color: "red",
                      "&:checked + span": {
                        backgroundColor: "gray",
                        color: "gray",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
              </div>
              <Button
                sx={{ color: "text.main" }}
                onClick={() => handleStatusOpenModal(params.row)}
              >
                <MoreVertIcon />
              </Button>
            </>
          );
        },
      },
    ],
    [list, page]
  );

  const onClickBack = () => {
    navigator({
      pathname: `/projects/${param.id}/applications/low-fidelity/my-scenario`,
    });
  }

  return (
    <Fragment>
      <Grid item sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            color: "text.main",
            fontWeight: "bold",
            marginRight: "10px",
          }}
          onClick={onClickBack}
        >
          Back
        </Button>
        <ButtonX className={`ms-2`} onClick={onClickNext}>
          Next
        </ButtonX>
      </Grid>
      <MainTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />

      <ModalX
        open={versionModalOpen}
        close={() => setVersionModalOpen(false)}
        heading={"Versions Available"}
        subheading={"AutoScenario"}
      >
        <GetVersionList
          executionList={executionList}
          versionList={versionIds.versions}
          handleClose={handleClose}
        />
      </ModalX>

      <ModalX
        open={statusOpen}
        close={() => setStatusOpen(false)}
        heading={"State Management"}
        subheading={`${statusData.name}`}
      >
        <AutoScenarioStatus
          statusObject={statusData}
          handleClose={handleClose}
        />
      </ModalX>
    </Fragment>
  );
};
export default connect(null, { ReduxUpdateLowFidelityJourney })(
  LowFidelityAutoScenario
);
