import React, { useState, useEffect } from "react";
import { Box, Grid, TextField, Button, Slider } from "@mui/material";
import { Typos } from "components";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addCameraSensor } from "../../../../../../endpointServices/testVehicleService";
import STORE from "services/redux-store/store";

export default function CameraSensorForm({ onClose, reloader, cameraSensorList }) {
  console.log("cameraSensorList", cameraSensorList)
  const [state, setState] = useState({
    name: "",
    image_width: "1920",
    image_height: "1080",
    field_of_view: 64,
    iso: 100,
    fps: 30,
    vignette_intensity: 0.1,
    exposure_compensation: 0,
    chromatic_aberration_intensity: 0.1,
    sensor_size_x: 25,
    sensor_size_y: 25,
    focal_length: 20,
    aperture: 0,
    lens_distortion_intensity: 1,
    gate_fit: 0,
  });

  const navigate = useNavigate();
  const { MyVehicle } = STORE.getState();
  const { sensorSetupId } = MyVehicle

  const stateHandler = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    console.log("onSubmit clicked");

    // Check if the name already exists
    const existingSensor = cameraSensorList.find(
      (sensor) => sensor.name === state.name
    );
    if (existingSensor) {
      toast.error(
        `Sensor with the name '${existingSensor.name}' already exists.`
      );
      return;
    }

    const payload = {
      sensor_setup_id: sensorSetupId,
      name: state.name,
      image_width: parseInt(state.image_width),
      image_height: parseInt(state.image_height),
      field_of_view: state.field_of_view,
      iso: state.iso,
      fps: state.fps,
      vignette_intensity: state.vignette_intensity,
      exposure_compensation: state.exposure_compensation,
      chromatic_aberration_intensity: state.chromatic_aberration_intensity,
      sensor_size_x: state.sensor_size_x,
      sensor_size_y: state.sensor_size_y,
      focal_length: state.focal_length,
      aperture: state.aperture,
      lens_distortion_intensity: state.lens_distortion_intensity,
      gate_fit: state.gate_fit,
    };
    const response = await addCameraSensor(payload);
    if (response?.status === 200) {
      onClose();
      console.log("response", response);
      toast.success(`Sensor presets added successfully`);
      reloader(true);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      console.log("errorresponse", response);
    }
  };

  return (
    <Box className="rbs-left-cameraSensor-form" sx={{ overflowX: "auto" }}>
      <form onSubmit={onSubmit}>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} sx={{ marginRight: "1rem" }}>
            <TextField
              name="name"
              type="text"
              variant="outlined"
              label="Name"
              fullWidth
              value={state.name}
              onChange={stateHandler}
              required
            />
          </Grid>
          <Grid item xs={12} sx={{ marginRight: "1rem", mt: 1 }}>
            <TextField
              name="image_width"
              type="text"
              variant="outlined"
              label="Image Width (pixels)"
              fullWidth
              value={state.image_width}
              onChange={stateHandler}
              required
            />
          </Grid>
          <Grid item xs={12} sx={{ marginRight: "1rem", mt: 1 }}>
            <TextField
              name="image_height"
              type="text"
              variant="outlined"
              label="Image Height (pixels)"
              fullWidth
              value={state.image_height}
              onChange={stateHandler}
              required
            />
          </Grid>
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"FoV"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({state.field_of_view} Deg)
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.field_of_view}
              onChange={(e, value) =>
                stateHandler({ target: { name: "field_of_view", value } })
              }
              min={0}
              max={180}
              step={1}
            />
          </Grid>
          {/* <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"ISO"}{" "}
              <Typos.Caption className={`ms-1`}>({state.iso})</Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.iso}
              onChange={(e, value) =>
                stateHandler({ target: { name: "iso", value } })
              }
              min={0}
              max={100}
              step={1}
              disabled={true}
            />
          </Grid> */}
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"FPS"}{" "}
              <Typos.Caption className={`ms-1`}>({state.fps} s^-1)</Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.fps}
              onChange={(e, value) =>
                stateHandler({ target: { name: "fps", value } })
              }
              min={30}
              max={60}
              step={5}
              disabled={false}
            />
          </Grid>
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Vignette Intensity"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({state.vignette_intensity} lux)
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.vignette_intensity}
              onChange={(e, value) =>
                stateHandler({ target: { name: "vignette_intensity", value } })
              }
              min={0}
              max={1}
              step={0.1}
            />
          </Grid>
          {/* <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Exposure Compensation"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({state.exposure_compensation})
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.exposure_compensation}
              onChange={(e, value) =>
                stateHandler({
                  target: { name: "exposure_compensation", value },
                })
              }
              min={0}
              max={100}
              step={1}
              disabled={true}
            />
          </Grid> */}
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"CAI"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({state.chromatic_aberration_intensity} lux)
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.chromatic_aberration_intensity}
              onChange={(e, value) =>
                stateHandler({
                  target: { name: "chromatic_aberration_intensity", value },
                })
              }
              min={0}
              max={1}
              step={0.1}
            />
          </Grid>
          {/* <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Gate Fit"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({state.gate_fit})
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.gate_fit}
              onChange={(e, value) =>
                stateHandler({ target: { name: "gate_fit", value } })
              }
              min={0}
              max={100}
              step={1}
              disabled={true}
            />
          </Grid> */}
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Sensor Size X"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({state.sensor_size_x} mm)
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.sensor_size_x}
              onChange={(e, value) =>
                stateHandler({ target: { name: "sensor_size_x", value } })
              }
              min={1}
              max={25}
              step={1}
            />
          </Grid>
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Sensor Size Y"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({state.sensor_size_y} mm)
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.sensor_size_y}
              onChange={(e, value) =>
                stateHandler({ target: { name: "sensor_size_y", value } })
              }
              min={1}
              max={25}
              step={1}
            />
          </Grid>
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Focal Length"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({state.focal_length} mm)
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.focal_length}
              onChange={(e, value) =>
                stateHandler({ target: { name: "focal_length", value } })
              }
              min={1}
              max={20}
              step={1}
            />
          </Grid>
          {/* <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Aperture"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({state.aperture})
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.aperture}
              onChange={(e, value) =>
                stateHandler({ target: { name: "aperture", value } })
              }
              min={0}
              max={100}
              step={1}
              disabled={true}
            />
          </Grid> */}
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"LDI"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({state.lens_distortion_intensity} lux)
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.lens_distortion_intensity}
              onChange={(e, value) =>
                stateHandler({
                  target: { name: "lens_distortion_intensity", value },
                })
              }
              min={-1}
              max={1}
              step={0.5}
            />
          </Grid>
        </Grid>

        <div
          style={{ marginTop: "2rem", marginBottom: "2rem", marginLeft: "70%" }}
        >
          <Button
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "white",
              fontSize: "13px",
            }}
            type="submit"
          >
            Save
          </Button>
        </div>
      </form>
    </Box>
  );
}
