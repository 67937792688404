import axios from 'axios'

const getUser = localStorage.getItem('aai_user')
const user = JSON.parse(getUser)

export const getAuthHeader = () => {
    return { headers: { Authorization: `Bearer ${user.access_token}` } }
}




export const authHttpPost = async ({ url, payload = null }) => {
    try {
        const response = await axios.post(url,payload)
        return response
    } catch (e) {
        console.log(e)
        return e.response
      
    }
}

export const httpGet = async ({ url, params = null }) => {
    if (!localStorage.hasOwnProperty('aai_user')) {
        window.location.href = window.location.origin + '/auth'
    }
    try {
        const response = await axios.get(url, {
            headers: { 
                userId: user?.user_id, 
                email:user?.email,
                Authorization: getAuthHeader().headers.Authorization 
            },
            params: params,
        })
        return response;
    } catch (e) {
        console.log(e)
        // return e
        if (e.response.status === 401) {
            localStorage.removeItem('aai_user')
            window.location.href = window.location.origin + '/auth'
        } else {
            throw e
        }
    }
}

export const httpPost = async ({ url, payload = null, contentType = 'application/json' }) => {
    if (!localStorage.hasOwnProperty('aai_user')) {
        window.location.href = window.location.origin + '/auth'
    }
    try {
        const response = await axios.post(url, payload, {
            headers: {
                'content-type': contentType,
                userId: user?.user_id,
                email:user?.email,
                Authorization: getAuthHeader().headers.Authorization,
            },
        })
        return response
    } catch (e) {
        console.log(e)
        return e
    }
}

export const httpPostFormdata = async ({ url, payload = null, contentType = 'application/json' }) => {
    if (!localStorage.hasOwnProperty('aai_user')) {
        window.location.href = window.location.origin + '/auth'
    }
    try {
        const response = await axios.post(url, payload, {
            headers: {
                userId: user?.user_id,
                email:user?.email,
                Authorization: getAuthHeader().headers.Authorization,
            },
        })
        return response
    } catch (e) {
        console.log(e)
        return e
    }
}

export const httpPut = async ({ url, payload }) => {
    if (!localStorage.hasOwnProperty('aai_user')) {
        window.location.href = window.location.origin + '/auth'
    }
    try {
        const response = await axios.put(url, payload, {
            headers: {
                'content-type': 'application/json',
                userId: user?.user_id,
                email:user?.email,
                Authorization: getAuthHeader().headers.Authorization,
            },
        })
        return response
    } catch (e) {
        console.log(e)
        return e
    }
}

export const httpPatch = async ({ url, payload }) => {
    if (!localStorage.hasOwnProperty('aai_user')) {
        window.location.href = window.location.origin + '/auth'
    }
    try {
        const response = await axios.patch(url, payload, {
            headers: {
                'content-type': 'application/json',
                userId: user?.user_id,
                email:user?.email,
                Authorization: getAuthHeader().headers.Authorization,
            },
        })
        return response
    } catch (e) {
        console.log(e)
        return e
    }
}

export const httpDelete = async ({ url, params = null }) => {
    if (!localStorage.hasOwnProperty('aai_user')) {
        window.location.href = window.location.origin + '/auth'
    }
    try {
        const response = await axios.delete(url, {
            headers: {
                'content-type': 'application/json',
                userId: user?.user_id,
                email:user?.email,
                Authorization: getAuthHeader().headers.Authorization,
            },
        })
        return response
    } catch (e) {
        if (e.response.status === 401)
            window.location.href = window.location.origin + '/auth'
        else throw e
    }
}
