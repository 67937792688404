import { VisibilityRounded } from '@mui/icons-material'
import { Chip } from '@mui/material'
import React from 'react'

function ReadOnlyTag() {
    return (
        <Chip
            icon={<VisibilityRounded />}
            label="Read Only Mode"
            variant="outlined"
            sx={{ background: '#00a2b9' }}
        />
    )
}
export default ReadOnlyTag