import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid, Radio, Button } from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";
import { GetInfoFromLocalStorage } from "utils";
import AutoExecutionListTable from "./autoExecutionListComponent/autoExecutionListTable";
import { getAutoScenarioExecListByPralionId } from "endpointServices/autoScenarioServices";
import { pageSize } from "utils/staticsData";
import STORE from "services/redux-store/store";

export default function AutoExecutionList() {
  // const pralionId = GetInfoFromLocalStorage('pralion_id')
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);

  const { ScenExtract } = STORE.getState();

  useEffect(() => {
    getProjectList({ pageNumber: page, testsPerPage: pageSize });
  }, [page]);

  const getProjectList = async (pagination) => {
    setFetching(true);
    const projectId = parseInt(ScenExtract?.applicationVersionId);
    const response = await getAutoScenarioExecListByPralionId(
      projectId,
      pagination
    );
    console.log("response", response);
    if (response?.status === 200) {
      setFetching(false);
      const { data } = response.data;
      setList(data.records || []);
      setTotalRows(data.total_no_records);
    } else {
      setList([]);
      setFetching(false);
    }
  };

  const goto = (data) => {
    console.log("data", data);
    if (data.visualizer_url !== null) {
      window.open(data.visualizer_url, "_blank");
    } else {
      alert("Url not found");
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const columns = [
    {
      field: "name",
      renderHeader: () => <span className="heading-2">{"Concrete Scenarios "}</span>,
      width: 400,
      renderCell: (params) => (
        <Grid>
          <Button
            className="Main-link-simple row-heading"
            onClick={() => goto(params.row)}
            sx={{ color: "white" }}
          >
            {params.value}
          </Button>
        </Grid>
      ),
    },
    {
      field: "output.MinTTC",
      renderHeader: () => <span className="heading-2">{"Min TTC (s) "}</span>,
      width: 175,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center" sx={{marginLeft: "2px"}}>
          {params.row.output?.MinTTC?.toFixed(3)}
        </Grid>
      ),
    },
    {
      field: "output.MinTHW",
      renderHeader: () => <span className="heading-2">{"Min THW (s) "}</span>,
      width: 175,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center" sx={{marginLeft: "2px"}}>
          {params.row.output?.MinTHW?.toFixed(3)}
        </Grid>
      ),
    },
    {
      field: "output.LJavg",
      renderHeader: () => <span className="heading-2">{"LJ Avg (m/s^3) "}</span>,
      width: 175,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center" sx={{marginLeft: "2px"}}>
          {params.row.output?.LJavg?.toFixed(3)}
        </Grid>
      ),
    },
  ];

  return (
    <Fragment>
      <Grid container justifyContent={"end"} className="mb-3">
        <Button
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            color: "text.main",
            marginRight: "10px",
            textTransform: "none",
            fontSize: "inherit",
          }}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Button
          onClick={getProjectList}
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            color: "white",
            textTransform: "none",
            fontSize: "inherit",
          }}
          
        >
          Refresh List
        </Button>
      </Grid>

      <AutoExecutionListTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />
    </Fragment>
  );
}
