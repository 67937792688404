import React, { Fragment, useEffect, useState } from "react";
import DownloadTable from "./downloadComponent/downloadTable";
import TraceIcon from "../../assets/icons/trace.svg";
import { Button, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { DownloadTableData } from "utils";
import { getDownloadableBuilds } from "endpointServices/simulationServices";

export default function Download() {
  const params = useParams();
  const navigator = useNavigate();
  const [downloadList, setDownloadList] = useState({});

  useEffect(() => {
    getDownloadList();
  }, []);

  const getDownloadList = async () => {
    const response = await getDownloadableBuilds();
    console.log("response", response);
    if (response?.status === 200) {
      const data = response?.data;
      console.log('data', data)
      setDownloadList(data);
    } else {
      const err = response?.message || "Failed to get request";
    }
  };

  const goto = (id) => {
    navigator(`/downloads/download-template/${id}`, {
      state: { downloadList },
    });
  };

  const columns = [
    {
      field: "name",
      headerName: <span className="heading-2">Applications</span>,
      width: 465,
      renderCell: (params) => {
        const isDisabled = params.row.disabled === true;
        const disabledStyle = isDisabled
          ? { opacity: 0.5, pointerEvents: "none" }
          : {};
        return (
          <Grid container spacing={1} alignItems="center">
            {/* <Grid item>
                        <img src={TraceIcon} style={{ width: "1rem", cursor: "pointer" }} />
                    </Grid> */}
            <Grid item>
              <Button
                className="Main-link-simple row-heading"
                style={disabledStyle}
                onClick={() => goto(params.id)}
                sx={{ color: "white" }}
              >
                {params.value}
              </Button>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Fragment>
      <DownloadTable headers={columns} />
    </Fragment>
  );
}
