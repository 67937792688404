

export let autoScenarioMainState = {
  placeVehicle:{},
  placeDynamic: {},
  placeActor: {},
  myVehicle:{},
  trafficPreset:'',
  executeTime:null
  
}
export const getVehicleProfile = (data) => {
  autoScenarioMainState.placeVehicle = data
}

export const getDynamicActors = (data) => {
  autoScenarioMainState.placeDynamic = data
}

export const getStaticActors = (data) => {
  autoScenarioMainState.placeActor = data
}

export const getMyVehicle = (data) => {
  autoScenarioMainState.myVehicle = data
}

export const getTrafficPreset = (data) => {
  autoScenarioMainState.trafficPreset = data
}

export const setExecuteTime = (data) => {
  autoScenarioMainState.executeTime = data
}

