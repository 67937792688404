import { Autocomplete, Button, Grid, Slider, TextField } from "@mui/material";
import { getAutoScenarioByPralionId, startAutoScenario, udateAutoScenarioProjects } from "endpointServices/autoScenarioServices";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import execute from "assets/images/exe.png";
import CircularProgress from "@mui/material/CircularProgress";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { static_traffic_vehicle_profile, static_traffic_driver_profiles, static_traffic_composition } from "utils/staticJson/traffics";
import { ExceptionHandlerModal } from "components/exceptionHandlerModal";
import STORE from "services/redux-store/store";
import { ReadOnlyTag, Typos } from "components";
import { GET_AUTOSCENARIO_VEHICLE_LIST, GET_VEHICLE_LIST } from "constants/endpoints";
import { httpGet } from "utils/httpMethods";
import { getAllTestVehicleProjects } from "endpointServices/testVehicleService";
import { UcFirst } from "utils";
import { ReduxUpdateAutoScenarioJourney } from "services/redux-store/actions";
import { connect } from "react-redux";
import { AUTHENTICATED_ROUTES } from "router/constants";

function spawnLaneInfo(inputData) {
	let outputData = { spawn_lanes_info: [] };
	inputData.forEach((item) => {
		let laneString = item.polygon.properties.road_id + ":0:" + item.polygon.properties.lane_id;
		let weightValue = item.rate;
		outputData.spawn_lanes_info.push({ lane: laneString, weight: parseInt(weightValue) });
	});
	console.log('outputData', outputData)
	return outputData;
}

function flattenVehicleProfileData(inputData) {
	let result = [];
	for (let key in inputData) {
		if (inputData.hasOwnProperty(key)) {
			result.push(...inputData[key]);
		}
	}
	return result;
}

export default connect(null, { ReduxUpdateAutoScenarioJourney })(function ExecuteSetting({ ReduxUpdateAutoScenarioJourney }) {
	const { AutoScenario: ReduxAutoScenario, Projects } = STORE.getState();

	const navigator = useNavigate();

	const [loading, setLoading] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [errorMsg, setErrMsg] = useState("");
	const [executionTime, setExecutionTime] = useState(60);
    const [testVehicleList, setTestVehicleList] = useState([]);
    const [selectedTestVehicle, setSelectedTestVehicle] = useState(null);
    
    useEffect(() => {
		if (ReduxAutoScenario?.ExecuteSetting?.executionTime) {
			setExecutionTime(ReduxAutoScenario?.ExecuteSetting?.executionTime)
		}
		if (ReduxAutoScenario?.ExecuteSetting?.selectedTestVehicle) {
			setSelectedTestVehicle(ReduxAutoScenario?.ExecuteSetting?.selectedTestVehicle)
		}
	},[])

    const getAllTestVehicles = async () => {
		const response = await getAllTestVehicleProjects(Projects.projectId)
		if (response?.status === 200) {
			const data = response?.data?.data?.test_vehicles || []
            console.log('data', data);
			const list = data.filter(row => row.application_version_id !== null).map((row) => {
				const returnValue = {
					name: row['name'],
					value: row['pralion_id'], 
				}
				return returnValue
			})
		  setTestVehicleList(list)
		} else {
			const err = response?.message || 'Failed to sumbit request'
      		toast.error(`${err}. Please try again later`)
			setTestVehicleList([])
		}
	};

    useEffect(() => {
        getAllTestVehicles()
    }, [])

    console.log('selectedVehicleId', selectedTestVehicle);
	const onExecute = async () => {
		setLoading(true);
		let mappedSpawnStrategy = "";
		let params = {};
		if (ReduxAutoScenario.AsSpawningStrategies === "everywhere") {
			mappedSpawnStrategy = "everywhere";
			params = { "ego.sensor_range": 200 };
		} else if (ReduxAutoScenario.AsSpawningStrategies === "as-perception-based") {
			mappedSpawnStrategy = ReduxAutoScenario.AsPerceptionBased.onCommingTraffic ? "around_ego_vision" : "around_ego";
			params = { "ego.sensor_range": ReduxAutoScenario.AsPerceptionBased.perceptionRange };
		} else if (ReduxAutoScenario.AsSpawningStrategies === "as-rate-based") {
			mappedSpawnStrategy = "rate_based";
			params = { ...spawnLaneInfo(ReduxAutoScenario.AsRateBased.savedMarkers), "ego.sensor_range": 200 };
		}
        const vehiclesList = await httpGet({ url: GET_AUTOSCENARIO_VEHICLE_LIST });
		const payload = {
			pralion_id: ReduxAutoScenario.pralionId,
			name: ReduxAutoScenario.projectName,
			map_gid: ReduxAutoScenario.AutoMapList.oddData.gid_kwh,
			map_generation_id: ReduxAutoScenario.AutoMapList.selectedMap,
			observer_id: ReduxAutoScenario.DefineObserver.observerId,
			time_of_day: "16:00",
			execution_time: executionTime,
			generate_log: true,
            ego: "ego",
			test_vehicle_id: selectedTestVehicle.value,
			traffic: {
				spawn_strategy: {
					traffic_amount_hint: ReduxAutoScenario.AsTrafficConfig.vehicleCount,
					traffic_density: ReduxAutoScenario.AsSpawningStrategies === "as-perception-based" ? (ReduxAutoScenario?.AsPerceptionBased?.trafficDensity || 10) : 10,
					spawn_strategy: mappedSpawnStrategy,
					params,
				},
				vehicle_profiles: flattenVehicleProfileData(vehiclesList.data.data),
				composition: ReduxAutoScenario.AsTrafficConfig.trafficConfig,
			},
		};
		console.log("payload", payload);
		const response = await udateAutoScenarioProjects(payload);
		if (response?.status === 200) {
			// const { pralion_id } = response?.data.data
			const body = {
				pralion_id: ReduxAutoScenario.pralionId,
			};
			console.log("body", body);
			const secondResponse = await startAutoScenario(body);
			if (secondResponse?.status === 200) {
				console.log("secondResponse", secondResponse);
				toast.success(`Successfuly started auto-scenarios generation`);
				setTimeout(() => {
					setLoading(false);
					navigator(AUTHENTICATED_ROUTES.autoScenarios.replace(":id", Projects.projectId));
				}, 4000);
			} else {
				setLoading(false);
				const errr = secondResponse?.response?.data?.message;
				console.log("errr", errr);
				setErrMsg(errr);
				toast.error(`Error! Failed to start auto-scenario. Please see the logs`);
				setOpenModal(true);
				// const err = response?.message || 'Failed to start my scenario. Please see the logs'
			}
		} else {
			setLoading(false);
			const errr = response?.response?.data?.message;
			console.log(errr);
			if (response?.status === 500) {
				setOpenModal(true);
				toast.error(`Error! Failed to start auto-scenario. Please see the logs`);
				setErrMsg(errr);
			}
			toast.error(`${errr}. Please try again later`);
			// const err = response?.message || 'Failed to update. Please see the logs'
		}
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const handleVehicleSelection = (e, val) => {
		setSelectedTestVehicle(val)
		ReduxUpdateAutoScenarioJourney({ ExecuteSetting: {
			...ReduxAutoScenario.ExecuteSetting,
			selectedTestVehicle: val,
		}})
	}

	const handleExecutionTimeChange = (e, value) => {
		setExecutionTime(value);
		ReduxUpdateAutoScenarioJourney({ ExecuteSetting: {
			...ReduxAutoScenario.ExecuteSetting,
			executionTime: value,
		}})
	}

	return (
		<>
			<ExceptionHandlerModal open={openModal} close={handleCloseModal} applicationName={"AutoScenarios"} errorMessage={errorMsg} />
			<div className={`container-fluid`}>
				<div className={`row`}>
					<div className={`col-12 d-flex justify-content-end align-items-center`}>
						<Link>
							<Button
								sx={{
									backgroundColor: "primary.main",
									"&:hover": {
										backgroundColor: "primary.main",
									},
									color: "white",
									width: "130px",
									marginRight: "10px",
								}}
								onClick={() => navigator(-1)}
							>
								Back
							</Button>
						</Link>
						{
							ReduxAutoScenario.readonly ? <ReadOnlyTag /> :
								<Button
									sx={{
										backgroundColor: "primary.main",
										"&:hover": {
											backgroundColor: "primary.main",
										},
										color: "white",
										width: "130px",
									}}
									disabled={loading || selectedTestVehicle === null}
									onClick={onExecute}
								>
									Generate
								</Button>
						}
					</div>
				</div>
                <hr />
				<div className={`row align-items-center`}>
					<div className={`col-lg-6 col-12`}>
						<div className={``}>
							<Typos.Body1>{`Execution Time: (in seconds)`}</Typos.Body1>
						</div>
						<Slider
							size="medium"
							valueLabelDisplay="auto"
							disabled={ReduxAutoScenario.readonly}
							min={60}
							max={600}
							value={executionTime}
							onChange={handleExecutionTimeChange}
							marks={[
								{
									value: 60,
									label: "60 sec",
								},
								{
									value: 120,
									label: "120",
								},
								{
									value: 180,
									label: "180",
								},
								{
									value: 440,
									label: "440",
								},
								{
									value: 600,
									label: "600",
								},
							]}
							step={10}
						/>
					</div>
					<div className={`col-lg-6 col-12`}>
						<Autocomplete
							disablePortal
							id="odd-search-combo-box"
							size="small"
							disabled={ReduxAutoScenario.readonly}
							filterOptions={(options) => options.filter(option => option.application_version_id !== null)}
							getOptionLabel={(option) => UcFirst(option.name.replaceAll("_", " "))}
							isOptionEqualToValue={(option, value) => value.value.id === option.id}
							options={testVehicleList}
							onChange={handleVehicleSelection}
							value={selectedTestVehicle}
							PaperComponent={"div"}
							renderInput={(params) => <TextField {...params} label="Test Vehicles" />}
						/>
					</div>
				</div>
			</div>

			<Grid
				container
				sx={{
					backgroundColor: "secondary.dark",
					padding: "4rem",
					borderRadius: "4px",
					marginTop: "1rem",
					height: "30rem",
					justifyContent: "center",
				}}
			>
				{/* <Grid item xs={6}> */}
				<span>
					{loading ? <CircularProgress sx={{ position: "absolute", top: "50%", left: "50%" }} /> : ""}
					<div style={{ position: "absolute", top: "65%" ,left: '35%' }}>
						<p className={`text-center`}>To begin, select the test vehicle and set the execution time. And then press the "Generate" button and monitor</p>
						<p style={{ textAlign: "center" }}>the results in the corresponding application...</p>
					</div>
					
					<img src={execute} style={{ width: "100%", marginBottom: 0 }} alt="icons" />
				</span>

				{/* </Grid> */}
			</Grid>
		</>
	);
}
)