import { SET_LOW_FIDELITY, UPDATE_LOW_FIDELITY } from "..";

const INITIAL_STATE = {
    pralionId: null,
    projectName: null,
    vehicleProperties: null,
    vehicleSuits: null,
    lowFidelityObserverId: null,
}

export default function ReducerLowFidelity(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_LOW_FIDELITY:
            return action.payload;
        case UPDATE_LOW_FIDELITY:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}