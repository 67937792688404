import { SCENARIO_EXECUTION_ENDPOINT } from "constants/endpoints"
import { httpGet, httpPost,httpPatch, httpPut } from "utils/httpMethods"


export const getScenarioExecutionList = async (body,pagination) => {
    const {pageNumber = 1, testsPerPage = 10 } = pagination || {}
    return await httpGet({ url: `${SCENARIO_EXECUTION_ENDPOINT}?project_id=${body}&page=${pageNumber}&page_size=${testsPerPage}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}


export const postScenarioExecutionList = async (body) => {
    return await httpPost({ url: `${SCENARIO_EXECUTION_ENDPOINT}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const putScenarioExecutionList = async (body) => {
    return await httpPut({ url: `${SCENARIO_EXECUTION_ENDPOINT}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}