import React from "react";
import { Button, Tooltip } from "@mui/material";


export default function ButtonX({ id, children, className, onClick, color, fullwidth, size, variant, disabled, tooltip, tooltipplacement }) {

	if (tooltip) {
		return (
			<Tooltip placement={tooltipplacement || "bottom"} title={tooltip}>
				<span>
				<Button
					id={id}
					onClick={onClick}
					disabled={disabled}
					className={` ${className}`}
					color={color || "primary"}
					size={size || "medium"}
					sx={{
						"&:hover": {
							backgroundColor: "primary.main",
						},
						textTransform: "none",
					}}
					variant={variant || "contained"}
					fullWidth={fullwidth || false}
				>
					{children}
				</Button>
				</span>
			</Tooltip>
		);
	}
	return (
		<Button
			id={id}
			onClick={onClick}
			disabled={disabled}
			className={`heading-3 ${className}`}
			color={color || "primary"}
			size={size || "medium"}
			sx={{
				"&:hover": {
					backgroundColor: "primary.main",
					color: "white"
				},
				textTransform: "none",
			}}
			variant={variant || "contained"}
			fullWidth={fullwidth || false}
		>
			{children}
		</Button>
	);
}
