import { Box, Button, Grid, Tab, Tabs, Typography, Chip } from "@mui/material";
import React, { Fragment, useState } from "react";
import TestSuitsAutoScenarioList from "./testSuitsComponent/testSuitsAutoScenarioList";
import TestSitsMyScenarioList from "./testSuitsComponent/testSitsMyScenarioList";
import { useParams, useNavigate } from "react-router-dom";
import STORE from "services/redux-store/store";
import { useEffect } from "react";
import TestSuitsScenario from "./testSuitsComponent/testSuitsScenario";
import ConfirmationModal from "components/confirmationModal";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DownloadIcon from "@mui/icons-material/Download";
import { getStatusListByPralionId, GetTestSuiteExport, startTestSuiteExport } from "endpointServices/testSuitServices";
import { ButtonX } from "components";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function TestSuits() {
  const navigate = useNavigate();
  const param = useParams();
  const [value, setValue] = React.useState(0);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [storedValues, setStoredValues] = React.useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isExported, setIsExported] = useState(true);
  const [statusActive, setStatusActive] = useState(false);
  const [statusCheck, setStatusCheck] = useState('');
  const [tabs, setTabs] = useState([
    {
      name: "Auto Scenario",
      component: (
        <TestSuitsAutoScenarioList getTestValues={() => handleStoredValues()} />
      ),
    },
    {
      name: "My Scenario",
      component: (
        <TestSitsMyScenarioList getTestValues={() => handleStoredValues()} />
      ),
    },
    {
      name: "Scenario",
      component: <TestSuitsScenario />,
    },
  ]);

  const { TestSuits, Projects } = STORE.getState();
  const { autoScenarioNumberOfScenarios } = TestSuits;
  const { myScenarioNumberOfScenarios } = TestSuits;
  const { applicationVersionId } = TestSuits;
  const { projectId } = Projects;

  console.log('applicationVersionId', applicationVersionId)
  const filteredTabs = applicationVersionId
    ? tabs.filter((tab) => tab.name === "Scenario")
    : tabs.filter((tab) => tab.name !== "Scenario");

  useEffect(() => {
    if (
      autoScenarioNumberOfScenarios?.length > 0 ||
      myScenarioNumberOfScenarios?.length > 0
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [autoScenarioNumberOfScenarios, myScenarioNumberOfScenarios]);

  useEffect(() => {
    getStatusList()
    console.log('single status check ....', )
  },[isExported])

  
  useEffect(() => {
    console.log('statusCheck', statusCheck)
    console.log('statusActive', statusActive)
    let intervalStatus;
    if(statusActive) {
      if (statusCheck !== 'success') {
        intervalStatus = setInterval(() => {
          getStatusList()
          console.log('111', 111)
        }, 3000)
      }
     
    }
    return () => clearInterval(intervalStatus);
  }, [statusActive, statusCheck])

  const handleExport = async () => {
    let formData = new FormData();
    formData.append('pralion_id', TestSuits.pralionId);
    formData.append('project_id', projectId);
    const response = await startTestSuiteExport(formData);
    if (response?.status === 200) {
      console.log("response", response);
      setStatusActive(true)
    } else {
      setStatusActive(false)
      console.log("Failed to fetch status data");
    }
  };

  const getStatusList = async () => {
    const pralionId = TestSuits.pralionId;
    const response = await getStatusListByPralionId(pralionId);
    if (response?.status === 200) {
      console.log("API response status:", response);
      const resp = response?.data?.data
      setStatusCheck(resp?.dag_state)
      const btnActive = resp?.dag_state === 'success' ? true : false
      setIsExported(btnActive)
     
    } else {
      console.error("Failed to fetch status data");
    }
  };

  const handleOpenModal = () => {
    if (applicationVersionId) {
      handleConfirmation();
    } else {
      setOpenConfirmationModal(true);
    }
  };

  const closeConfirmationModal = () => setOpenConfirmationModal(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleStoredValues = (values) => {
    setStoredValues(values);
  };

  const onClickNext = () => {
    navigate(
      `/projects/${param.id}/applications/test-suits/test-suits-execute`
    );
  };

  const handleConfirmation = () => {
    navigate(`/projects/${projectId}/applications`);
  };

  const downloadSuite = async() => {
    const pralionId = TestSuits.pralionId;
    const response = await GetTestSuiteExport(pralionId);
    if (response?.status === 200) {
      const resp = response.data.data
      console.log("API response status:", response);
      const link = document.createElement('a');
      link.href = resp.ts_export_pkg;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } else {
      console.error("Failed to fetch status data");
    }

  }

  return (
    <Box sx={{ width: "100%" }}>
      <ConfirmationModal
        open={openConfirmationModal}
        heading={<span>Confirm Action</span>}
        subheading={<span>Going back will discard unsaved changes</span>}
        close={closeConfirmationModal}
        confirmationMessage={
          <span>
            Are you sure you want to go back? If you go back, your changes will
            be lost.
          </span>
        }
        onConfirm={handleConfirmation}
      />
      <Grid container justifyContent={"space-between"} className="mb-3">
        <Grid item>
          {applicationVersionId !== null ?
          <ButtonX
            size={"medium"}
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "text.main",
              fontWeight: "bold",
              textTransform: "none",
            }}
            disabled={isExported || statusActive}
            onClick={handleExport}
          >
            Export Suite
          </ButtonX>
          :''
          }
          {statusCheck !== 'success' && statusActive  ? (
            <Chip
              size={"large"}
              label="Inprogress"
              sx={{
                borderRadius: "4",
                marginLeft: "1em",
                color: "text.main",
              }}
            />
          ) : statusCheck === 'success' ? (
            <Button
              sx={{
                  backgroundColor: "#607d8b00",
                "&:hover": {
                  backgroundColor: "#607d8b00",
                },
                  color: "#ffffff",
                fontWeight: "bold",
                marginRight: "10px",
                textTransform: "none",
                // marginLeft: "1em",
              }}
              // variant="outlined"
              size="large"
              // disabled={isExported}
              onClick={downloadSuite}
            >
              <DownloadIcon />
            </Button>
          ) : ''}
        </Grid>
        <Grid item>
          <Button
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "text.main",
              fontWeight: "bold",
              marginRight: "10px",
              textTransform: "none",
            }}
            variant={"contained"}
            size="large"
            onClick={handleOpenModal}
          >
            Back
          </Button>
          <Button
            size="large"
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "white",
              fontWeight: "bold",
              textTransform: "none",
            }}
            variant={"contained"}
            onClick={onClickNext}
            disabled={isDisabled}
          >
            Next
          </Button>
        </Grid>
      </Grid>
      <Fragment>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTab-root": {
                fontSize: "1.2rem",
              },
              backgroundColor: "#304A74",
              border: "1px solid #808080a6",
            }}
          >
            {filteredTabs.map((tab, index) => (
              <Tab key={index} label={tab.name} />
            ))}
          </Tabs>
        </Box>
        {filteredTabs.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={index}>
            {tab.component}
          </CustomTabPanel>
        ))}
      </Fragment>
    </Box>
  );
}
