import React, { useEffect, useState } from "react";
import { Button, CardContent, Grid, TextField, Box } from "@mui/material";
import { handleSpecialCharacters, specialValue } from "utils";
import { createProjectService } from "endpointServices/projectServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ProjectForm({
  handleClose,
  syncList,
  summaryData,
  isEdit,
}) {
  console.log("summaryData", summaryData);
  console.log("isEdit", isEdit);
  const [state, setState] = useState({
    name: "",
    description: "",
    requirementId: "",
    requirementdescription: "",
    acceptancecriteria: "",
  });
  const [loading, setLoading] = useState(false);
  const [idSpecialCharacter, setIdSpecialCharacter] = useState(false);
  const [requirementSpecialCharacter, setRequirementSpecialCharacter] = useState(false);

  useEffect(() => {
    if (isEdit && summaryData) {
      setState({
        name: summaryData.name,
        description: summaryData.description,
        requirementId: summaryData.requirement_id,
        requirementdescription: summaryData.requirement_description,
        acceptancecriteria: summaryData.acceptance_criteria,
      });
    }
  }, [isEdit, summaryData]);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    if (name === "name" || name === "requirementId") {
      handleSpecialCharacters(name, value, setIdSpecialCharacter, setRequirementSpecialCharacter);
    }
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      name: state.name,
      description: state.description,
      requirement_id: state.requirementId,
      requirement_description: state.requirementdescription,
      acceptance_criteria: state.acceptancecriteria,
    };
    console.log("response:", payload);
    const response = await createProjectService(payload);
    if (response?.status === 200) {
      const { data } = response?.data;
      toast.success(`Project created with id:${data.project_id}`);
      handleClose();
      syncList();
      setLoading(false);
    } else {
      const errorResponse = response;
      const err = errorResponse?.message || "Failed to submit request";
      if (errorResponse?.response.request.status === 400) {
        toast.error(errorResponse.response.data.message);
      } else {
        toast.error(`${err}. Please try again later`);
      }
      console.log("error", errorResponse);
      setLoading(false);
    }
  };

  return (
    <Box>
      <CardContent>
        <form onSubmit={onSubmitLogin}>
          <Grid item xs={6}>
            <TextField
              name="name"
              required
              type="text"
              variant="outlined"
              label="Project ID"
              placeholder="Enter project id"
              inputProps={{ maxLength: 40 }}
              value={state.name}
              onChange={handleInputs}
              disabled={isEdit}
              fullWidth
              helperText="Enter alphanumeric characters and hyphens only"
            />
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="description"
              type="text"
              variant="outlined"
              label="Project Description"
              placeholder="Write project description"
              value={state.description}
              onChange={handleInputs}
              fullWidth
              multiline
              rows={3}
              disabled={isEdit}
            />
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="requirementId"
              type="text"
              variant="outlined"
              label="Requirement ID"
              placeholder="Enter requirement id"
              inputProps={{ maxLength: 40 }}
              value={state.requirementId}
              onChange={handleInputs}
              disabled={isEdit}
              helperText="Enter alphanumeric characters and hyphens only"
              fullWidth
            />
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="requirementdescription"
              type="text"
              variant="outlined"
              label="Requirement Description"
              placeholder="Write requirement description"
              value={state.requirementdescription}
              onChange={handleInputs}
              fullWidth
              multiline
              rows={3}
              disabled={isEdit}
            />
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="acceptancecriteria"
              type="text"
              variant="outlined"
              label="Acceptance Criteria"
              placeholder="Enter acceptance criteria"
              // inputProps={{ maxLength: 40 }}
              value={state.acceptancecriteria}
              onChange={handleInputs}
              fullWidth
              multiline
              rows={3}
              disabled={isEdit}
            />
          </Grid>
          <br />

          <div className={`d-flex justify-content-end`}>
            {isEdit ? '' :
             <Button
             sx={{
               backgroundColor: "primary.main",
               "&:hover": {
                 backgroundColor: "primary.main",
               },
               color: "text.main",
               textTransform: "none",
               fontSize: "13px",
             }}
             disabled={loading || isEdit || idSpecialCharacter || requirementSpecialCharacter}
             type="submit"
           >
             Submit
           </Button>
            }
          </div>
        </form>
      </CardContent>
    </Box>
  );
}
