import {
  Grid,
  Icon,
  Typography,
  Button,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import carLoad from "assets/images/carLoad.png";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  getTestVehicleProfileService,
  getTestVehicleProjectsById,
} from "endpointServices/testVehicleService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { vehicleList } from "utils";
import { ButtonX } from "components";
import { ReduxUpdateLowFidelityJourney } from "services/redux-store/actions/actions-lowFidelity";
import { connect } from "react-redux";
import STORE from "services/redux-store/store";
import { getTestVehicleProjectsByIdLowfidelity } from "endpointServices/lowFidelityServices";

const LowFidelityVehicleSelect = ({ ReduxUpdateLowFidelityJourney }) => {
  const { LowFidelity, Projects } = STORE.getState();
  const { vehicleProperties } = LowFidelity;
  const { projectId } = Projects;
  const navigate = useNavigate();
  const param = useParams();

  const [selectTitle, setSelectTitle] = React.useState("");
  const [isFetching, setFetching] = React.useState(false);
  const [checked, setChecked] = React.useState([]);
  const [data, setData] = useState([]);
  const [isVehicleSelected, setVehicleSelected] = useState(false);

  React.useEffect(() => {
    getAllList();
  }, []);

  const selectCar = (id, title, data) => {
    setSelectTitle(title);
    ReduxUpdateLowFidelityJourney({ vehicleProperties: data });
    vehicleSelected(true);
  };

  const vehicleSelected = () => {
    setVehicleSelected(true);
  };

  React.useEffect(() => {
    if (vehicleProperties) {
      setSelectTitle(vehicleProperties.name);
      setVehicleSelected(true);
    } else {
      setVehicleSelected(false);
    }
  }, [vehicleProperties]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.length = 0; // Clear the array before pushing the new value
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const getAllList = async () => {
    setFetching(true);
    const projectId = param?.id;
    const response = await getTestVehicleProjectsByIdLowfidelity(projectId);
    if (response?.status === 200) {
      setFetching(false);
      const { data } = response?.data;
      const filteredData = data.test_vehicles.filter(vehicle => vehicle.versions.length > 0);
      console.log("filteredData", filteredData)
      console.log("data", data);
      setData(filteredData);
      // setTotalRows(data.no_of_test_vehicles)
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setData([]);
      setFetching(false);
    }
  };

  const onClickNext = () => {
    navigate(`/projects/${param.id}/applications/low-fidelity/test-suits`);
  };

  const onClickBack = () => {
    navigate(`/projects/${projectId}/applications/low-fidelity`);
  };

  return (
    <>
      <Grid item sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            color: "text.main",
            fontWeight: "bold",
            marginRight: "10px",
            textTransform: "none",
            fontSize: "inherit",
          }}
          onClick={onClickBack}
        >
          Back
        </Button>
        <ButtonX
          className={`ms-2`}
          onClick={onClickNext}
          disabled={!isVehicleSelected}
        >
          Next
        </ButtonX>
      </Grid>
      <div>
        <Divider style={{ marginTop: "1rem" }} />
      </div>

      <Grid
        container
        xs={12}
        sm={12}
        sx={{
          borderColor: "primary.main",
          maxHeight: "50rem",
          backgroundColor: "secondary.dark",
          borderRadius: "6px",
          marginTop: "2rem",
          // m: 1,
        }}
      >
        <Grid container direction="column">
          <Typography
            variant="h6"
            sx={{ marginTop: "1rem", marginLeft: "1rem" }}
          >
            Select Vehicle
          </Typography>
          <Typography
            variant="caption"
            sx={{ marginLeft: "1rem", marginBottom: "1rem" }}
          >
            The defined TestVehicle can only be referred
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            height: "20rem",
            overflowY: "auto",
            overflowX: "hidden",
            border: "2px solid",
            borderColor: "primary.main",
            backgroundColor: "secondary.dark",
            borderRadius: "6px",
            marginLeft: "1rem",
            marginRight: "1rem",
          }}
        >
          <Grid
            container
            sx={{
              marginLeft: "1rem",
            }}
          >
            {isFetching ? (
              <div style={{ position: "absolute", top: "39%", left: "50%" }}>
                <CircularProgress size={40} />
              </div>
            ) : (
              data.map((vehicle, index) => (
                <Grid
                  item
                  xs={3}
                  sm={2}
                  key={index}
                  sx={{
                    minWidth: 230,
                    border: "2px solid",
                    borderColor: "primary.main",
                    backgroundColor: "secondary.dark",
                    textAlign: "center",
                    borderRadius: "6px",
                    m: 2,
                  }}
                >
                  <Button
                    onClick={() => selectCar(vehicle.id, vehicle.name, vehicle)}
                  >
                    <Grid>
                      <Icon
                        sx={{ fontSize: "5rem", width: "2em", height: "1.5em" }}
                      >
                        <img
                          src={`/images/vehicles/${
                            vehicle.side_view ||
                            (vehicle.prefab_name
                              ? vehicle.prefab_name + ".png"
                              : "")
                          }`}
                          style={{ width: "150px", marginBottom: 0 }}
                          onError={(e) => {
                            e.target.src = carLoad;
                          }}
                        />
                      </Icon>
                      <Typography
                        sx={{
                          color: "text.main",
                          textTransform: "none",
                        }}
                      >
                        {vehicle.name}
                      </Typography>
                      {/* <Typography
                        sx={{
                          color: "text.primary",
                          textTransform: "none",
                        }}
                      >
                        {vehicle.prefab_name}
                      </Typography> */}
                    </Grid>
                  </Button>
                  {selectTitle === vehicle.name ? (
                    <Link to={``} state={{ vehicleData: vehicle.model_path }}>
                      <Button
                        variant="contained"
                        sx={{
                          width: "100%",
                          textTransform: "none",
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          "&:hover": {
                            backgroundColor: "primary.main",
                          },
                        }}
                        onClick={vehicleSelected}
                      >
                        Vehicle Selected
                      </Button>
                    </Link>
                  ) : (
                    ""
                  )}
                </Grid>
              ))
            )}
          </Grid>
        </Grid>

        <Typography variant="h6" sx={{ margin: "1rem", marginTop: "2rem" }}>
          Select SUTs
        </Typography>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            maxHeight: "15rem",
            overflowY: "auto",
            overflowX: "hidden",
            border: "2px solid",
            borderColor: "primary.main",
            backgroundColor: "secondary.dark",
            borderRadius: "6px",
            marginLeft: "1rem",
            marginRight: "1rem",
            marginBottom: "2rem",
          }}
        >
          <List sx={{ width: "100%", maxWidth: 360 }}>
            {[0, 1, 2].map((value) => {
              const labelId = `checkbox-list-label-${value}`;

              return (
                <ListItem key={value} disablePadding>
                  <ListItemButton
                    role={undefined}
                    onClick={handleToggle(value)}
                    dense
                    disabled
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={checked[0] === value}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      id={labelId}
                      primary={`ADS-ECU 1.${value + 1}`}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Grid>
      </Grid>
    </>
  );
};
export default connect(null, { ReduxUpdateLowFidelityJourney })(
  LowFidelityVehicleSelect
);
