import { Fragment } from "react"
import { Box, Container, Typography } from '@mui/material';

const commonSpanStyles = {
    fontSize: "18px",
    fontWeight: "bold",
    marginRight: "10px",
    marginLeft: "10px",
  };

export const renderTestVehicleGuide = () => {
    return (
        <Fragment>
            <section className="main-content mb-1">
                <Typography variant='body1' gutterBottom>
                    Welcome to the My Vehicle Sensor Setup screen! This guide will walk you through the steps of creating and updating sensor setups for your vehicle, including cameras, LiDAR, and ground truth sensors.
                </Typography>
            </section>
            <section className="sub-headings">
                <Container>
                    <Typography variant='body1' gutterBottom>
                        1.
                        <span style={commonSpanStyles}>CREATE SENSOR SETUP.</span>
                        To create a new sensor setup, simply click on the add icon in the select sensor setup section. Then, choose the desired sensor from the list.
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        2.
                        <span style={commonSpanStyles}>CREATE CAMERA.</span>
                        To add a new camera, start by selecting a Sensor Setup. Then, you can either choose a pre-existing sensor preset from the list or create a new one from scratch by clicking the '+' icon next to the sensor presets name.
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        3.
                        <span style={commonSpanStyles}>UPDATE CAMERA.</span>
                        After adding a new camera, select the sensor setup again to view the newly added camera in the list. Click on the edit icon to update the camera preset coordinates. Once you've made the necessary updates, click the save button to apply the changes. Additionally, you can toggle the sensor icon to hide or show the camera sensor 3D object.
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        4.
                        <span style={commonSpanStyles}>LIDAR.</span>
                        Enable the LiDAR by checking the checkbox, then click on the edit icon to update the coordinates of the LiDAR sensor. Once the coordinates are updated, save the changes by clicking the save button at the bottom of the form. You can visualize the sensor position by toggling the button next to the edit button.
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        5.
                        <span style={commonSpanStyles}>GROUND TRUTH.</span>
                        Similarly, enable the ground truth sensor by checking the checkbox, then click on the edit icon to update the radius of the ground truth sensor. Once you've adjusted the radius, save the changes by clicking the save button at the bottom of the form. You can visualize the sensor radius by toggling the button next to the edit icon.
                    </Typography>
                    <Typography sx={{ marginTop: '1em' }} variant='body1' gutterBottom>
                        Follow these steps to efficiently set up and manage sensors for your vehicle's operations.
                    </Typography>
                </Container>
            </section>
        </Fragment>
    )
}

export const renderOddGuide = () => {
    return (
        <Fragment>
            <section className="main-content mb-1">
                <Typography variant='body1' gutterBottom>
                This screen/application allows you to define the Operational Design Domain Zone for the overall journey you will perform on the Replicar Platform. Here's how you can add various elements to your ODD Zone:
                </Typography>
            </section>
            <section className="sub-headings">
                <Container>
                    <Typography variant='body1' gutterBottom>
                        1.
                        <span style={commonSpanStyles}>Regions.</span>
                        To add a region to your ODD, select the polygon tool located in the top left corner of the screen. Then, draw the region of interest on the map. You can use the search function in the top right corner to locate specific areas. After creating the polygon, you can customize its name or keep the predefined name assigned to it. Ensure that you use distinct names for different regions to avoid confusion.
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        2.
                        <span style={commonSpanStyles}>Routes.</span>
                        Adding routes is straightforward. Simply search for locations and add stops (up to 5 stops maximum) by clicking the "add stop" button. Once you've added all stops, click on "find route" to calculate and add the route to your ODD definition. You can add multiple routes as needed.
                    </Typography>
                    
                    <Typography sx={{ marginTop: '1em' }} variant='body1' gutterBottom>
                    Once you're satisfied with the changes made to your ODD, press the "submit" button to confirm the journey.
                    </Typography>
                </Container>
            </section>
        </Fragment>
    )
}

export const renderMyMapGuide = () => {
    return (
        <Fragment>
            <section className="main-content mb-1">
                <Typography variant='body1' gutterBottom>
                    Welcome to the MyMaps Application! This guide will help you navigate through the features and functionalities of the application:
                </Typography>
            </section>
            <section className="sub-headings">
                <Container>
                    <Typography variant='body1' gutterBottom>
                        1.
                        <span style={commonSpanStyles}>Highlighted Region Maps.</span>
                        To add a region to your ODD, select the polygon tool located in the top left corner of the screen. Then, draw the region of interest on the map. You can use the search function in the top right corner to locate specific areas. After creating the polygon, you can customize its name or keep the predefined name assigned to it. Ensure that you use distinct names for different regions to avoid confusion.
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        2.
                        <span style={commonSpanStyles}>Zooming In.</span>
                        Adding routes is straightforward. Simply search for locations and add stops (up to 5 stops maximum) by clicking the "add stop" button. Once you've added all stops, click on "find route" to calculate and add the route to your ODD definition. You can add multiple routes as needed.
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        3.
                        <span style={commonSpanStyles}>Search Maps Feature.</span>
                        If you want to search for maps based on attributes, region names, or ODD definitions, utilize the Search Maps feature located in the top right corner of the screen. Select the attribute and its value on which you would like to filter the maps. This feature will help you navigate to all the maps that fulfill your search criteria. You can inspect and add them to your project or map catalog as per your needs.
                    </Typography>
                    <Typography variant='body1' gutterBottom>
                        4.
                        <span style={commonSpanStyles}>Submitting Your Selection.</span>
                        Once you are satisfied with your map catalog, press the submit button to save your selection.
                    </Typography>
                    <Typography sx={{ marginTop: '1em' }} variant='body1' gutterBottom>
                        Explore the maps, build your catalog, and make the most out of the MyMaps Application for your mapping needs!
                    </Typography>
                </Container>
            </section>
        </Fragment>
    )
}