import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { Autocomplete, Step, StepLabel, Stepper, TextField, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import "./maps.css";
import ButtonX from "components/buttonX";
import { AccordionX, CollapseContent, IconButtonX, LinkButtonX, ProgressX, Typos, UxSpace } from "components";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { httpGet, httpPost } from "utils/httpMethods";
import { BBOX_TO_ODD, FETCH_ODD_KEYWORDS, FETCH_ROUTE, GET_ALL_AVAILABILITY_ZONES, GET_DSPACE_USER_MAPS, GET_USER_MAPS } from "constants/endpoints";
import { useLocation, useNavigate, useParams } from "react-router";
import { UcFirst, mapTypes, SetInfoToLocalStorage } from "utils";
import { DEFAULT_LINE_LAYER, DEFAULT_POINT_LAYER, DEFAULT_POLYGON_LAYER } from "constants/defaults";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { ReduxUpdateDSpaceJourney } from "services/redux-store/actions/actions-dspace";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "xyz";

const lineStringLayer = {
	type: "line",
	source: "route-between-2-points",
	id: "route-between-2-points1",
	paint: {
		"line-color": "yellow",
		"line-width": 6,
		"line-opacity": 0.4,
	},
};

function DSpaceMap({ ReduxUpdateDSpaceJourney }) {
	const mapContainer = useRef(null);
	const map = useRef(null);
	const geocoder = useRef(null);
	const loadExistingFileInput = useRef(null);

	const navigate = useNavigate();

	const [lng, setLng] = useState(7.76286); // 7.76286, 50.40618
	const [lat, setLat] = useState(50.40618);
	const [zoom, setZoom] = useState(8);
	const [searchedResultClick, setSearchedResultClick] = useState(false);
	const [searchedLocations, setSearchedLocations] = useState([]);
	const [selectedFiles, setSelectedFiles] = useState(null);
	const [showUserMapsList, setShowUserMapsList] = useState(true);
	const [showODDListControls, setShowODDListControls] = useState(false);
	const [fetchedRoute, setFetchedRoute] = useState(null);
	const [fetchedODDs, setFetchedODDs] = useState(null);
	const [fetchedODDPolygons, setFetchedODDPolygons] = useState(null);
	const [focusedODDIndex, setfocusedODDIndex] = useState(null);

	const [overlayDrawers, setOverlayDrawers] = useState([]);
	const [loadingRoute, setLoadingRoute] = useState(false);
	const [loadingAzones, setLoadingAzones] = useState(false);
	const [searchingRouteOption, setSearchingRouteOption] = useState(false);
	const [searchingODDOption, setSearchingODDOption] = useState(false);
	const [availableODDsList, setAvailableODDsList] = useState([]);
	const [availableUserMaps, setAvailableUserMaps] = useState([]);

	useEffect(() => {
		localStorage.removeItem("pralion_id");
		if (!map.current) {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: mapTypes.streetViewWithOdds,
				center: [lng, lat],
				zoom: zoom,
			});
			map.current.on("load", () => {
				console.log("loading");
				// map.current.addSource("route", { type: "geojson", data: SOURCE });
				// map.current.addLayer(LAYER);
				// showAvailabilityZone();
				getCurrentLocation();
			});
			geocoder.current = new MapboxGeocoder({
				accessToken: mapboxgl.accessToken,
				mapboxgl: mapboxgl,
				render: (item) => {
					return `<div class="search-option-item">
						<div class="search-option-item-title">${item.text}</div>
						<div class="search-option-item-desc">${item.place_name}</div>
					</div>`;
				},
			});

			// map.current.addControl(geocoder);
			document.getElementById("geocoder").prepend(geocoder.current.onAdd(map.current));
		}
		map.current.on("move", handleOnMapMove);
		// map.current.on("click", "route", handleOnClickPolygon);
		geocoder.current.on("result", handleGeocoderResult);

		return () => {
			geocoder.current.off("result", handleGeocoderResult);
			// map.current.off("click", "route", handleOnClickPolygon);
		};
	}, [searchedLocations]);

	useEffect(() => {
		fetchODDs();
		fetchUserMaps();
	}, []);

	const getCurrentLocation = () => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				const latitude = position.coords.latitude;
				const longitude = position.coords.longitude;

				// Set the map viewport with the current location
				setLat(latitude);
				setLng(longitude);

				map.current.flyTo({
					center: [longitude, latitude], // Specify the destination coordinates
					zoom: 15, // Specify the zoom level of the destination
					speed: 0.8, // Control the speed of the flyTo animation
					curve: 1.42, // Control the curve of the flight path
					easing: (t) => t, // Customize the easing function
				});
				// Create a map instance
				// const map = new mapboxgl.Map({
				//   container: 'mapContainer',
				//   style: 'mapbox://styles/mapbox/streets-v11',
				//   center: [longitude, latitude],
				//   zoom: 10,
				// });

				// // Add a marker to the map at the current location
				// new mapboxgl.Marker().setLngLat([longitude, latitude]).addTo(map);
			},
			(error) => {
				console.error("Error getting current location:", error);
			}
		);
	};
	const handleGeocoderResult = (e) => {
		console.log("e.result", e.result);
		if (searchedLocations.length < 2) {
			// setSearchedResultClick(true);
			// const newMarker = new mapboxgl.Marker().setLngLat(e.result.center);
			// setSearchedLocations([...searchedLocations, { location: e.result, marker: newMarker }]);
			// newMarker.addTo(map.current);
		} else {
		}
	};

	const onClickChangeMapType = (type) => {
		map.current.setStyle(type);
	};

	const handleOnMapMove = (e) => {
		setLng(map.current.getCenter().lng.toFixed(4));
		setLat(map.current.getCenter().lat.toFixed(4));
		setZoom(map.current.getZoom().toFixed(2));
	};

	const showAvailabilityZone = async () => {
		setLoadingAzones(true);
		await httpGet({ url: GET_ALL_AVAILABILITY_ZONES })
			.then(async (res) => {
				console.log("res", res);
				const geojson = JSON.parse(res.data.data);
				console.log("geojson", geojson);
				await map.current.addSource("azones", { type: "geojson", data: geojson });
				await map.current.addLayer(DEFAULT_POLYGON_LAYER("polygons", "azones"));
				await map.current.addLayer(DEFAULT_POINT_LAYER("points", "azones"));
				await map.current.addLayer(DEFAULT_LINE_LAYER("lines", "azones"));
				setLoadingAzones(false);
			})
			.catch((err) => {
				toast.error(`${err}. Please try again later`);
				setLoadingAzones(false);
			});
	};

	const onClickClearAllLocations = (e) => {
		try {
			for (let i = 0; i < searchedLocations.length; ++i) {
				searchedLocations[i].marker.remove();
			}
			geocoder.current.clear();
			setSearchedLocations([]);
			setSearchedResultClick(false);
			setSearchingRouteOption(false);
			setFetchedODDPolygons(null);
			setShowODDListControls(false);
			map.current.removeLayer("route-between-2-points1");
			map.current.removeSource("route-between-2-points");
		} catch (err) {
			toast.error(`${err}. Please try again later`);
			console.log("err", err);
		}
	};

	const onClickLoadExistingFileInput = (e) => {
		loadExistingFileInput.current.click();
	};

	const onLoadExistingFile = (e) => {
		// console.log("e loading map", e);
		// setSelectedFiles(e.target.files);
		// map.current.addSource("route", { type: "geojson", data: SOURCE });
		// map.current.addLayer(LAYER);
		// if (showUserMapsList) {
		// 	console.log("showUserMapsList", showUserMapsList);
		// 	setShowUserMapsList(false);
		// }
	};

	const onClickCloseOverlayDrawer = (index) => {
		console.log("index", index);
		const temp = [...overlayDrawers];
		temp.splice(index, 1);
		console.log("temp", temp);
		setOverlayDrawers([...temp]);
	};

	const onRouteButtonClick = async () => {
		if (searchedLocations.length < 2) {
			geocoder.current.clear();
			setSearchingRouteOption(true);
		} else {
			// call the find route API
			try {
				setLoadingRoute(true);
				const resp = await httpPost({
					url: FETCH_ROUTE,
					payload: {
						project_id: parseInt(localStorage.getItem("p_id")),
						start_lat: searchedLocations[0].location.center[1],
						start_long: searchedLocations[0].location.center[0],
						end_lat: searchedLocations[1].location.center[1],
						end_long: searchedLocations[1].location.center[0],
					},
				});
				console.log("resp map", resp);
				setFetchedRoute(resp.data.data);
				await map.current.addSource("route-between-2-points", { type: "geojson", data: resp.data.data.geo_json });
				await map.current.addLayer(lineStringLayer);
				setLoadingRoute(false);
			} catch (err) {
				toast.error(`${err}. Please try again later`);
				console.log("err", err);
				setLoadingRoute(false);
			}
		}
	};

	// const onClickNext = (e) => {

	// 	// call the routes api to get line string geo json -> /route-to-odr
	// 	// call another api after this, "odd on route" using an id or url returns searched_odd_on_route.geojson
	// 	// after going into odds list, display all the feature odds in the map/odds route, it will have
	// 	// image: {SERVER URL}/odd-image/<properties.<id>> and title: <odd_keyword>(replace with display_name)
	// 	/**
	// 	 * select one of the listed odd routes. Fetch the respective payload using properties.<id> from the geojson file
	// 	 * Make it a geojson and load it to the next screen on the map as geojson source.
	// 	 */
	// 	/**
	// 	 * call an api using the same geojson payload created earlier which will return a
	// 	 * list of urls of multiple map geojsons. Which will be loaded
	// 	 * on toggle button
	// 	 */
	// };

	const onClickNext = () => {
		if (searchingRouteOption) {
			ReduxUpdateDSpaceJourney({
				searchType: "route",
				mapId: fetchedRoute.map_id,
				geojson: fetchedRoute.geo_json,
				pralionId: fetchedRoute.pralion_id,
				newMap: {
					searchingRoute: true,
					searchingOdd: false,
					searchedLocations: searchedLocations,
				},
				userMap: null,
			});
		} else if (searchingODDOption) {
			ReduxUpdateDSpaceJourney({
				searchType: "odd",
				geojson: fetchedODDs, // a geojson url
				mapId: null,
				pralionId: null,
				newMap: {
					searchingRoute: false,
					searchingOdd: true,
					searchedLocations: searchedLocations,
				},
				userMap: null,
			});
		}
		navigate(
			{
				pathname: AUTHENTICATED_ROUTES.dSpaceOdds,
			},
			{
				state: {
					mapId: searchingRouteOption ? fetchedRoute.map_id : null,
					oddUrl: searchingODDOption ? fetchedODDs : null,
				},
			}
		);
		// SetInfoToLocalStorage('pralion_id',param.id)
	};

	const fetchODDs = async () => {
		try {
			await httpGet({ url: FETCH_ODD_KEYWORDS })
				.then((res) => {
					console.log("res.data", res.data);
					const jsonResponse = JSON.parse(res.data.data);
					console.log(
						"jsonResponse",
						jsonResponse.ODD_keywords.filter((item) => item.platform_support === true)
					);
					setAvailableODDsList(jsonResponse.ODD_keywords.filter((item) => item.platform_support === true));
				})
				.catch((err) => {
					console.log("err", err);
				});
		} catch (err) {
			console.log("err", err);
		}
	};

	const fetchUserMaps = async () => {
		try {
			await httpGet({ url: GET_DSPACE_USER_MAPS }).then((resp) => {
				console.log("resp.data", resp.data);
				setAvailableUserMaps(resp.data.data);
			});
		} catch (err) {
			console.log("err", err);
		}
	};

	const onClickToSearchODD = async (e, val) => {
		console.log("e,val", e, val);
		if (val) {
			setSearchingODDOption(true);
			setfocusedODDIndex(null);
			try {
				map.current.removeLayer("odds-polygon-id");
				map.current.removeLayer("odds-line-id");
				map.current.removeLayer("odds-point-id");
				map.current.removeSource("odds");
			} catch (err) {
				console.log("err", err);
			}
			// get the current map bounds
			var bounds = map.current.getBounds();

			// get the southwest and northeast corners
			var sw = bounds.getSouthWest();
			var ne = bounds.getNorthEast();

			// create a geojson object representing the bounding box
			// create a geojson object representing the bounding box
			var geojson = {
				type: "FeatureCollection",
				features: [
					{
						type: "Feature",
						properties: {},
						geometry: {
							type: "Polygon",
							coordinates: [
								[
									[sw.lng, sw.lat], // southwest corner
									[ne.lng, sw.lat], // southeast corner
									[ne.lng, ne.lat], // northeast corner
									[sw.lng, ne.lat], // northwest corner
									[sw.lng, sw.lat], // back to southwest corner
								],
							],
						},
					},
				],
			};

			console.log(geojson);
			await httpPost({
				url: BBOX_TO_ODD,
				payload: {
					map_id: 1122,
					odd_polygon: geojson,
					odd_keyword: val.name,
				},
			})
				.then((resp) => {
					console.log("resp", resp);
					// const ODD = "https://temp-rayan.s3.eu-central-1.amazonaws.com/searched_odd_in_bbox_4ce55eb7-f5f9-4f16-b2e9-956ddc1b6e14.geojson"
					fetch(resp.data.data.geo_json)
						.then((response) => response.json())
						.then((json) => {
							const data = json.features[0];
							console.log("json", json);

							// const cords = data.geometry.coordinates[0][0];
							// console.log("cords", cords);
							// setLng(cords[0]);
							// setLat(cords[1]);
							// map.current.setCenter(cords);

							map.current.addSource("odds", { type: "geojson", data: json });
							map.current.addLayer(DEFAULT_POLYGON_LAYER("odds-polygon-id", "odds", "#FF0000"));
							map.current.addLayer(DEFAULT_LINE_LAYER("odds-line-id", "odds", "#FF0000"));
							map.current.addLayer(DEFAULT_POINT_LAYER("odds-point-id", "odds", "#FF0000"));
							setFetchedODDs(resp.data.data.geo_json);
							setFetchedODDPolygons(json);
						});
				})
				.catch((err) => {
					console.log("err", err);
				});
		} else {
			setSearchingODDOption(false);
			setFetchedODDPolygons(null);
			setfocusedODDIndex(null);
			setShowODDListControls(false);
			try {
				map.current.removeLayer("odds-polygon-id");
				map.current.removeLayer("odds-line-id");
				map.current.removeLayer("odds-point-id");
				map.current.removeSource("odds");
			} catch (err) {
				console.log("err", err);
			}
		}
	};

	const flyToMapLocation = (lngLat) => {
		map.current.flyTo({
			center: lngLat, // Specify the destination coordinates
			zoom: 12, // Specify the zoom level of the destination
			speed: 0.8, // Control the speed of the flyTo animation
			curve: 1.42, // Control the curve of the flight path
			easing: (t) => t, // Customize the easing function
		});
	};

	const findOutTypeOfFeature = (featureType, index) => {
		if (["LineString"].includes(featureType)) {
			const cords = fetchedODDPolygons.features[index].geometry.coordinates[0];
			flyToMapLocation([cords[0], cords[1]]);
		} else if (["MultiLineString"].includes(featureType)) {
			const cords = fetchedODDPolygons.features[index].geometry.coordinates[0][0];
			flyToMapLocation([cords[0], cords[1]]);
		} else {
			const cords = fetchedODDPolygons.features[index].geometry.coordinates[0][0];
			flyToMapLocation([cords[0], cords[1]]);
		}
	};

	const onClickFocusedODDIndexChangeButton = (e) => {
		console.log("e", e);
		let index;
		switch (e.target.value) {
			case "Next":
				index = focusedODDIndex === null ? 0 : focusedODDIndex + 1;
				findOutTypeOfFeature(fetchedODDPolygons.features[index].geometry.type, index);
				setfocusedODDIndex(index);
				break;
			case "Previous":
				index = focusedODDIndex - 1;
				findOutTypeOfFeature(fetchedODDPolygons.features[index].geometry.type, index);
				setfocusedODDIndex(index);
				break;
			case "Recenter":
				findOutTypeOfFeature(fetchedODDPolygons.features[focusedODDIndex || 0].geometry.type, 0);
				break;
			default:
				break;
		}
	};

	const onClickUserMap = (userMap) => {
		ReduxUpdateDSpaceJourney({
			userMap: true,
			mapId: userMap.id,
			geojsonMapIdAndOddPolygon: userMap.lane_polygons,
			mapName: userMap.name
		});
	};

	return (
		<>
			<div className={`container-fluid`}>
				{overlayDrawers}
				<div className={`row`}>
					<div className={`col-12 d-flex justify-content-end`}>
						<div className={`d-flex align-items-center`}>
							<ButtonX onClick={() => navigate(-1)}>Back</ButtonX>
							<ButtonX className={`ms-2`} disabled={fetchedRoute === null} onClick={onClickNext}>
								Next
							</ButtonX>
							<div className={``}>{loadingAzones && <ProgressX background={true} />}</div>
						</div>
					</div>
				</div>
				<div className={`row mt-3`}>
					<div className={`col-lg-12`}>
						<div className={`map-container`}>
							<CollapseContent orientation={"horizontal"} show={!searchedResultClick}>
								<div className={`map-sidebar`}>
									<AccordionX
										expanded={showUserMapsList}
										default_open={true}
										onClick={() => setShowUserMapsList(!showUserMapsList)}
										title={
											<Typos.H6 color={"white"} className={`fw-bold`}>
												User Maps for dSpace
											</Typos.H6>
										}
									>
										{/* <CardX className={`w-100`}> */}
										{availableUserMaps
											.filter((nitem) => nitem.name !== null)
											.map((item, i) => (
												<div className={`mt-2`} key={i}>
													{item.lane_polygons === null ? (
														<Tooltip title="Currently unavailable!" placement="bottom">
															<div>
																<Typos.Body1 color="grey">{item.name}</Typos.Body1>
															</div>
														</Tooltip>
													) : (
														<Link
															className={`fw-bold text-white`}
															to={AUTHENTICATED_ROUTES.dSpaceSpawningStrategies}
															onClick={() => onClickUserMap(item)}
															// state={{
															// 	isGeojsonAvailable: true,
															// 	oddPolygon: null,
															// 	mapId: item.id,
															// 	lane_polygons: item.lane_polygons,
															// }}
														>
															{item.name}
														</Link>
													)}
												</div>
											))}
										{/* </CardX> */}
									</AccordionX>
								</div>
							</CollapseContent>
							{fetchedODDs !== null && showODDListControls ? (
								<div className={`map-sidebar`}>
									<CollapseContent orientation={"vertical"} show={fetchedODDs !== null}>
										<AccordionX
											// expanded={showUserMapsList}
											default_open={true}
											// onClick={() => setShowUserMapsList(!showUserMapsList)}
											title={
												<Typos.Body1 color={"white"} className={`fw-bold`}>
													Available ODDs ({fetchedODDPolygons ? fetchedODDPolygons.features?.length : 0})
												</Typos.Body1>
											}
										>
											<Typos.H6>
												Currently viewing: {focusedODDIndex !== null ? focusedODDIndex + 1 : "None"}/
												{fetchedODDPolygons ? fetchedODDPolygons.features?.length : 0}
											</Typos.H6>
											<ToggleButtonGroup fullWidth onChange={onClickFocusedODDIndexChangeButton}>
												<ToggleButton value="Previous" disabled={focusedODDIndex === null || focusedODDIndex === 0}>
													Previous
												</ToggleButton>
												<ToggleButton value="Recenter">Recenter</ToggleButton>
												<ToggleButton value="Next" disabled={focusedODDIndex && focusedODDIndex + 1 === fetchedODDPolygons.features?.length}>
													Next
												</ToggleButton>
											</ToggleButtonGroup>
										</AccordionX>
									</CollapseContent>
								</div>
							) : (
								""
							)}

							<div className={`map-search-field-on-search`}>
								<CollapseContent show={searchedResultClick}>
									<div className={`p-2`}>
										{searchingRouteOption ? (
											<Stepper orientation="vertical">
												{searchedLocations.map((item, i) => (
													<Step key={i} className={`border rounded px-2`}>
														<StepLabel>{item.location.place_name}</StepLabel>
													</Step>
												))}
											</Stepper>
										) : (
											<Autocomplete
												disablePortal
												id="odd-search-combo-box"
												size="small"
												getOptionLabel={(option) => UcFirst(option.name.replaceAll("_", " "))}
												options={availableODDsList}
												onChange={(event, newValue) => {
													onClickToSearchODD(event, newValue);
												}}
												PaperComponent={"div"}
												renderInput={(params) => <TextField {...params} label="Awailable ODDs" />}
											/>
										)}
										<UxSpace />
										<div className={`d-flex justify-content-around w-100`}>
											{loadingRoute ? (
												<ProgressX />
											) : (
												<IconButtonX
													disabled={searchingODDOption}
													icon={searchedLocations.length === 2 ? "RouteRounded" : "AltRouteRounded"}
													size="large"
													onClick={onRouteButtonClick}
												/>
											)}

											<IconButtonX
												disabled={searchingRouteOption}
												icon={"BusinessRounded"}
												size="large"
												onClick={() => setShowODDListControls(!showODDListControls)}
											/>
										</div>
										{searchedResultClick && (
											<div className={`mt-2 mb-1 px-3`}>
												<ButtonX
													fullwidth={true}
													className={`mb-2`}
													disabled={fetchedRoute === null && fetchedODDs === null}
													size={"small"}
													onClick={onClickNext}
												>
													Next
												</ButtonX>
												<ButtonX fullwidth={true} color="error" size={"small"} onClick={onClickClearAllLocations}>
													Clear
												</ButtonX>
											</div>
										)}
									</div>
								</CollapseContent>
							</div>

							<div className={`map-search-field`}>
								<div id="geocoder"></div>
							</div>
							{/* <div className="map-location-info">
							Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
						</div> */}
							{/* <div className={`map-types-bar`}>
								<IconButtonX wrapper size={"small"} icon={"StreetviewRounded"} onClick={() => onClickChangeMapType(mapTypes.streetView)} />
								<IconButtonX wrapper size={"small"} icon={"SatelliteRounded"} onClick={() => onClickChangeMapType(mapTypes.satelliteView)} />
								<IconButtonX wrapper size={"small"} icon={"FlareRounded"} onClick={() => onClickChangeMapType(mapTypes.lightView)} />
							<IconButtonX wrapper size={"small"} icon={"NightlightRounded"} onClick={() => onClickChangeMapType(mapTypes.darkView)} />
							</div> */}
							<div ref={mapContainer} className="map-view" />
						</div>
					</div>
				</div>
				<UxSpace />
			</div>
		</>
	);
}
export default connect(null, { ReduxUpdateDSpaceJourney })(DSpaceMap);
