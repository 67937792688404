import { ODD_APP_ENDPOINT, ODD_MULTI_POINT_ENDPOINT, ODD_REQUIREMENT_ENDPOINT } from "constants/endpoints"
import { httpGet, httpPost, httpPut } from "utils/httpMethods"

export const getOddProjectAService = async (body) => {
    return await httpGet({ url:`${ODD_APP_ENDPOINT}?project_id=${body}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getOddProjectServicePagination = async (body, pagination) => {
    const {pageNumber = 1, testsPerPage = 10 } = pagination || {}
    return await httpGet({ url:`${ODD_APP_ENDPOINT}?project_id=${body}&page=${pageNumber}&page_size=${testsPerPage}&order=desc`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const postOddProjectService = async (body) => {
    return await httpPost({ url: ODD_APP_ENDPOINT, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const putOddProjectService = async (body) => {
    return await httpPut({ url:`${ODD_APP_ENDPOINT}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const postOddMultiPointRequirement = async (body) => {
    return await httpPost({ url:`${ODD_MULTI_POINT_ENDPOINT}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const postOddRequirement = async (body) => {
    return await httpPost({ url:`${ODD_REQUIREMENT_ENDPOINT}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}



export const getOddRequirement = async (id) => {
    return await httpGet({ url:`${ODD_REQUIREMENT_ENDPOINT}${id}` })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const putOddRequirement = async (id, payload) => {
    return await httpPut({ url:`${ODD_REQUIREMENT_ENDPOINT}${id}`, payload })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

// export const oddRequirementDelete = async (body) => {
//     return await httpPut({ url:`${ODD_REQUIREMENT_ENDPOINT}?odd_id=${body}`, payload: body })
//         .then((res) => {
//             return res
//         })
//         .catch((err) => {
//             return err?.response
//         })
// }