import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModalX from "../../../components/modalx";

export default function MapStatusModal({ width, open, close, statusObject }) {
  console.log("statusObject ---", statusObject);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  console.log("selectedTask", selectedTask);

  const handleVisibilityClick = (task) => {
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setSelectedTask(null);
    setIsModalOpen(false);
  };

  return (
    <div className="">
      {isModalOpen && (
        <ModalX
          open={isModalOpen}
          close={handleModalClose}
          heading={`${selectedTask?.task_id}`}
          subheading={"Logs Details"}
        >
          {selectedTask && (
            <pre className="logs-detail" style={{ overflow: "auto" }}>
              {selectedTask.logs}
            </pre>
          )}
        </ModalX>
      )}
      <div className="status-table-data p-3 mb-3">
        <h6>
          <b>State:</b>
          {statusObject?.dag_state === "success" ? (
            <Chip label="Success" color="success" className="chip-text-color" />
          ) : statusObject?.dag_state === "failed" ? (
            <Chip label="Failed" className="chip-failed-danger" />
          ) : statusObject?.dag_state === "running" ? (
            <Chip label="Running" color="primary" className="chip-text-color" />
          ) : (
            <Chip
              label="Queued"
              color="secondary"
              className="chip-text-color"
            />
          )}
        </h6>
        <h6>
          <b>Duration:</b> {Number(statusObject?.duration).toFixed(2)} sec
        </h6>
      </div>

      <div className="status-table-data mb-3">
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>Task Id</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Log</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statusObject &&
                statusObject.task_state.map((task) => (
                  <TableRow key={task.task_id}>
                    <TableCell>{task.task_id}</TableCell>
                    <TableCell>{task.duration}</TableCell>
                    <TableCell>
                      {task.state === "success" ? (
                        <Chip
                          label="Success"
                          color="success"
                          className="chip-text-color"
                        />
                      ) : task.state === "failed" ||
                        task.state === "upstream_failed" ? (
                        <Chip label="Failed" className="chip-failed-danger" />
                      ) : task.state === "running" ? (
                        <Chip
                          label="Running"
                          color="primary"
                          className="chip-text-color"
                        />
                      ) : task.state === "queued" ? (
                        <Chip
                          label="Queued"
                          color="secondary"
                          className="chip-text-color"
                        />
                      ) : (
                        <Chip
                          label="Wait"
                          color="secondary"
                          className="chip-text-color"
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <VisibilityIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleVisibilityClick(task)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
