import { ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { AlertX, IconButtonX, ProgressX, UxSpace } from "components";
import { GET_SHOW_ODDS_MAPS } from "constants/endpoints";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ReduxUpdateEducAgentsJourney } from "services/redux-store/actions/actions-educAgents";
import { connect } from "react-redux";

const EnvironmentMapOdds = ({ loading, mapIds, updateNextButtonState, ReduxUpdateEducAgentsJourney, columns }) => {
	const [selectedOddId, setSelectedOddId] = useState(null);

	const onClickItemCheckbox = (e) => {
		const updatedSelectOddId = e.gid_kwh === selectedOddId ? null : e.gid_kwh;
		setSelectedOddId(updatedSelectOddId);
		const isChecked = updatedSelectOddId !== null;
		updateNextButtonState(isChecked);
		ReduxUpdateEducAgentsJourney({ selectedMap: e });
	};

	const cols = columns || 3;

	const renderODDList = () => {
		if (loading) {
			return [...Array(cols * 3).keys()].map((x, i) => <ProgressX key={i} block={true} height={230} width={"100%"} />);
		}
		return mapIds.map((item, i) => (
			<ImageListItem key={i}>
				<ImageListItemBar
					sx={{
						background: "linear-gradient(to bottom, rgba(35, 55, 75, 0.7) 0%, " + "rgba(35, 55, 75, 0.3) 70%, rgba(35, 55, 75, 0) 100%)",
						borderRadius: "10px",
					}}
					position="top"
					actionPosition="left"
					actionIcon={
						<IconButtonX
							sx={{ color: "white" }}
							icon={item.gid_kwh === selectedOddId ? "CheckBoxRounded" : "CheckBoxOutlineBlankRounded"}
							onClick={() => onClickItemCheckbox(item)}
						/>
					}
				/>
				<img
					src={`${GET_SHOW_ODDS_MAPS}/${item.gid_kwh}`}
					alt={item.gid_kwh}
					loading="lazy"
					className={`img-fluid`}
					style={{
						borderRadius: "10px",
						borderColor: "fff",
						maxHeight: 260,
					}}
				/>
				<ImageListItemBar
					sx={{
						background: "linear-gradient(to bottom, rgba(35, 55, 75, 0.7) 100%, " + "rgba(35, 55, 75, 0.3) 70%, rgba(35, 55, 75, 0) 0%)",
						borderEndEndRadius: "10px",
					}}
					position="bottom"
					actionPosition="right"
					title={item.name}
				/>
			</ImageListItem>
		));
	};

	return (
		<>
			<div className={`container-fuild`} style={{marginLeft:'1.5em'}}>
				<div className={`row mt-4`}>
					{mapIds && mapIds.length === 0 && !loading ? (
						<AlertX text="Select a Map to load ODDs." badge={"info"} style={{ width: "23rem", backgroundColor: "#2F4870" }} />
					) : (
						<ImageList sx={{ width: "100%", maxHeight: "72vh", padding: "12px" }} gap={10} cols={cols} rowHeight={260}>
							{renderODDList()}
							{mapIds && mapIds.length > 15 && <UxSpace />}
						</ImageList>
					)}
				</div>
			</div>
		</>
	);
};
export default connect(null, { ReduxUpdateEducAgentsJourney })(EnvironmentMapOdds);
