import { GET_PROJECT, UPDATE_PROJECT } from ".."

export const ReduxAddProject = (pid) => {
    return {
        type: GET_PROJECT,
        payload: { projectId: pid }
    }
}

export const ReduxUpdateProject = (obj) => {
    return {
        type: UPDATE_PROJECT,
        payload: obj
    }
}