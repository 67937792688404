import { Divider, Skeleton, Slider } from "@mui/material";
import { IconButtonX, ProgressX, Typos } from "components";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ReduxUpdateDSpaceTrafficConfigurations } from "services/redux-store/actions";

function mapStateToProps(state) {
	return {
		DSpaceTrafficConfigs: state.DSpaceTrafficConfigs
	}
}

function VehicleComposition({ loading, selectVehicleCallback, DSpaceTrafficConfigs, ReduxUpdateDSpaceTrafficConfigurations }) {


	const handleSliderChange = (e) => {
		const tempDSpaceTrafficConfigs = _.cloneDeep(DSpaceTrafficConfigs);
		tempDSpaceTrafficConfigs[e.target.name].value = e.target.value;
		ReduxUpdateDSpaceTrafficConfigurations(tempDSpaceTrafficConfigs);
	}

	const calculateCurrentCompositionSum = () => {
		if (DSpaceTrafficConfigs) {
			// Extract the values of the first-level properties.
			const vehiclesCounts = Object.values(DSpaceTrafficConfigs).map((item) => item.value || 0);

			// Calculate the sum using reduce.
			const sum = vehiclesCounts.reduce((acc, currentValue) => acc + currentValue, 0);

			// Check if the sum is exactly 100.
			return { is100: sum === 100, sum };
		} else {
			return { is100: false, sum: 0 };
		}
	}

	const valuesAudit = calculateCurrentCompositionSum();

	return (
		<div className={``}>
			<Typos.H6>Vehicle Composition</Typos.H6>
			<Typos.Caption className={`d-flex align-items-center`}>
				Complete sum of{" "}
				<Typos.Caption color={valuesAudit.is100 ? "lightgreen" : "red"} className={`mx-1`}>
					({valuesAudit.sum}/100)
				</Typos.Caption>{" "}
				to proceed
			</Typos.Caption>
			<ProgressX linear={true} progress={valuesAudit.sum} color={valuesAudit.is100 ? "primary" : "error"} />
			<Divider />
			<div className={``}>{loading && [...Array(10).keys()].map((item, i) => <Skeleton animation="wave" key={i} width={"100%"} height={36} />)}</div>
			<div className={`mt-3`}>
			<Typos.Caption color="grey">Values ranges from 0 to 100</Typos.Caption>
				{DSpaceTrafficConfigs !== null &&
					!loading &&
					Object.keys(DSpaceTrafficConfigs).map((vehicle, i) => (
						<div className={``} key={i}>
							<div className={`d-flex justify-content-between align-items-center`}>
								<Typos.Body1 className={`d-flex align-items-end`}>
									{vehicle} <Typos.Caption className={`ms-1`}>({DSpaceTrafficConfigs[vehicle].value})</Typos.Caption>
								</Typos.Body1>
								<IconButtonX disabled={!valuesAudit.is100} icon={"EditRounded"} size={"small"} onClick={() => selectVehicleCallback(vehicle)} />
							</div>
							<Slider
								name={vehicle}
								size="small"
								color="primary"
								min={0}
								max={100}
								step={1}
								value={DSpaceTrafficConfigs[vehicle].value}
								onChange={handleSliderChange}
							/>
						</div>
					))}
			</div>
		</div>
	);
}
export default connect(mapStateToProps, { ReduxUpdateDSpaceTrafficConfigurations }) (VehicleComposition);
