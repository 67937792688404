export const ROUTES_CORE = {
  projects: `projects`,
  appplications: "applications",

  // Download
  download: "downloads",
  downloadTemplate: "download-template",

  // Simulations
  simulations: "simulations",

  //MyScenarios
  myScenarios: "my-scenarios",
  map: "map",
  odds: "odds",
  selectedOdds: "selected-odds",
  interactiveMap: "interactive-map",
  logicalScenerioParams: "logical-scenerio-params",
  scenarioTraceable: "traceable",
  defineScenarioObserver: "define-observer",
  defineScenarioRule: "define-rule",
  scenarioExecution: "scenario-executation",
  mapList: "map-list",


  // AutoScenarios
  autoScenarios: "auto-scenarios",
  asMap: "as-map",
  asOdds: "as-odds",
  asSelectedOdds: "as-selected-odd",
  asInteractiveMap: "as-interactive-map",
  scenarioMap: "map",
  placeTestVehicles: "place-test-vehicles",
  placeDynamicsActor: "place-dynamics-actor",
  placeStaticActor: "place-static-actor",
  routesOdds: "routes-odds",
  defineObserver: "define-observer",
  defineRule: "define-rule",
  execute: "execute",
  executeSetting: "execute-setting",
  autoMapList: "auto-map-list",
  asSpawningStrategies: "as-spawning-strategies",
  asPerceptionBased: "as-perception-based",
  asRateBased: "as-rate-based",
  asTrafficConfig: "as-traffic-config",

  
  //TestVehicles
  testVehicles: "test-vehicles",
  replicarSim: "replica-sim",
  coSimulation: "co-simulation",
  simulationDetails: "co-simulation-details",

  dSpaceConfig: "d-space-config",
  dSpaceMap: "d-space-map",
  dSpaceOdds: "d-space-odds",
  dSpaceSpawningStrategies: "d-space-spawning-strategies",
  perceptionBased: "pbs",
  rateBased: "rbs",
  everywhere: "every-where",
  dSpaceTrafficConfig: "d-space-traffic-config",

  coSimDspace: "cosim-dspace",
  coSimDSpaceDetails: "cosim-dspace-details",
  coSimCarMaker: "cosim-car-maker",
  carMakerDetails: "cosim-car-maker-details",
  others: "others",


  // Maps
  maps: "maps",
  mainMap: "map",
  routeBasedMap: "route-based-map",
  areaBasedMap: "area-based-map",
  oddBasedMap: "odd-based-map",
  oddViewMap: "odd-view-map",
  oddBasedSelectedMap: "odd-based-selected-map",

  // ScenAnalytics
  scenAnalytics: "scen-alytics",
  scenarioUserDefined: "scenario-user-defined",
  scenIntelligentParam: "scen-intelligent-param",
  traffic: "traffic",
  mapAnalytics: "map",
  analyticsMyScenario: "alytics-my-scenario",
  myAnalyticsList: "alytics-scenario-map",
  analyticsAutoScenario: "alytics-auto-scenario",
  AutoAnalyticsList: "alytics-auto-scenario-map",
  TraceabilityMatrix: "traceability-matrix",

  // SynthData
  synthData: "synth-data",
  environments: "environments",

  // Real2Sim
  realSim: "real-sim",
  realSimEnvironments: "real-sim-environments",
  
  // SenVironment
  senVironment: "sen-vironment",
  senEnvironments: "sen-environment",

  // ScenExtract
  scenExtract: "scen-extract",
  overView: "over-view",
  scenarioExecutionList: "extract-my-scenario",
  autoExecutionList: "extract-auto-scenario",

  // EducAgents
  educAgents: "educ-agents",
  educSpawningStrategies: "educ-agent-spawning-strategies",
  educPerceptionBased: "educ-agent-perception-based",
  educRateBased: "educ-agent-rate-based",
  educTrafficConfig: "educ-agent-traffic-config",

  educMap: "educ-map",
  educRouteOdds: "educ-route-odds",
  educRouteSelectedOdds: "educ-route-odds-selected",
  trafficConfiguration: "traffic-configuration",
  educDynamicActor: "educ-dynamic-actor",
  educStaticActor: "educ-static-actor",
  agents: "agents",
  educDefineRewards: "educ-define-rewards",
  educTestCases: "educ-define-test",
  hyperParameter: "modal-input-ego",
  educExecution: "educ-execution",
  modelInput: "modal-input-agents",
  educHyperParameter: "hyper-parameter",
  environmentSetup: "environment-setup",
  attributeSelection: "attribute-selection",

  // Map Validator
  mapValidator: "map-validator",
  oddValidatorBasedMap: "odd-based-validator-map",
  oddValidatorBasedSelectedMap: 'odd-based-selected-validator-map',
  oddViewValidatorMap: "odd-view-validator-map",

  // High Fidelity
  highFidelity: "high-fidelity",
  vehicleSelection: "vehicle-selection",
  highFidelityTestSuits: "testsuits",
  highFidelityDefineObserver: "define-observer",
  highFidelityDefineRules: "define-rule",
  highFidelityExecutionSetting: "execution-setting",
  highFidelityExecute: "execution-scenarios",

  // Low Fidelity
  lowFidelity: "low-fidelity",
  lowFidelitySaveScenario: "scenarios",
  lowFidelityTestSuits: "test-suits",
  lowFidelityVehicleSelect: "vehicle-select",
  lowFidelityMyScenario: "my-scenario",
  lowFidelityAutoScenario: "auto-scenario",
  lowScenarioExecutionList: "scenario-execution-list",
  lowAutoScenarioExecutionList: "auto-scenario-list",
  lowFidelityScenarios: "execute",
  lowFidelityObserver: "observer",
  lowFidelityRule: "rule",

  // Tracebility
  tracebility: "tracebility",

  // ApplicationTraceable
  applicationTraceable: "traceable",

  // Odds
  Odd: "odd",
  oddZone: "odd-zones",
  oddDefinition: "odd-definition",

  // Test Suits
  testSuits: "test-suits",
  testSuitsExecute: "test-suits-execute",

  // Carla
  carla: "carla",
  carlaExecute: "carla-execute",

};

export const AUTHENTICATED_ROUTES = {
  dashboard: "/",

  //Projects
  projects: `/${ROUTES_CORE.projects}`,
  applications: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}`,

  // Download
  download: `/${ROUTES_CORE.download}`,
  downloadTemplate: `/${ROUTES_CORE.download}/${ROUTES_CORE.downloadTemplate}/:id`,

  // Simulations
  simulations: `/${ROUTES_CORE.simulations}`,

  //MyScenarios
  myScenarios: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.myScenarios}`,
  map: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.myScenarios}/${ROUTES_CORE.map}`,
  odds: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.myScenarios}/${ROUTES_CORE.map}/${ROUTES_CORE.odds}`,
  selectedOdds: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.myScenarios}/${ROUTES_CORE.map}/${ROUTES_CORE.odds}/${ROUTES_CORE.selectedOdds}`,
  // ! pralion id as aid
  mapList: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.myScenarios}/:aid/${ROUTES_CORE.mapList}`,
  interactiveMap: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.myScenarios}/:aid/${ROUTES_CORE.mapList}/:mid/${ROUTES_CORE.interactiveMap}`,
  logicalScenerioParams: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.myScenarios}/:aid/${ROUTES_CORE.mapList}/:mid/${ROUTES_CORE.interactiveMap}/${ROUTES_CORE.logicalScenerioParams}`,
  defineScenarioObserver: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.myScenarios}/:aid/${ROUTES_CORE.mapList}/:mid/${ROUTES_CORE.interactiveMap}/${ROUTES_CORE.logicalScenerioParams}/${ROUTES_CORE.defineScenarioObserver}`,
  defineScenarioRule: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.myScenarios}/:aid/${ROUTES_CORE.mapList}/:mid/${ROUTES_CORE.interactiveMap}/${ROUTES_CORE.logicalScenerioParams}/${ROUTES_CORE.defineScenarioObserver}/${ROUTES_CORE.defineScenarioRule}`,
  scenarioTraceable: `/${ROUTES_CORE.appplications}/${ROUTES_CORE.myScenarios}/${ROUTES_CORE.scenarioTraceable}`,
  
  scenarioExecution: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.myScenarios}/:aid/${ROUTES_CORE.mapList}/:mid/${ROUTES_CORE.interactiveMap}/${ROUTES_CORE.logicalScenerioParams}/${ROUTES_CORE.defineScenarioObserver}/${ROUTES_CORE.scenarioExecution}`,

  // AutoScenarios
  autoScenarios: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}`,

  asMap: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/${ROUTES_CORE.asMap}`,
  asOdds: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/${ROUTES_CORE.asMap}/${ROUTES_CORE.asOdds}`,
  asSelectedOdds: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/${ROUTES_CORE.asMap}/${ROUTES_CORE.asOdds}/${ROUTES_CORE.asSelectedOdds}`,
  asInteractiveMap: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/${ROUTES_CORE.asMap}/${ROUTES_CORE.asInteractiveMap}`,
  scenarioMap: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/${ROUTES_CORE.scenarioMap}`,
  placeTestVehicles: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/${ROUTES_CORE.placeTestVehicles}`,
  placeDynamicsActor: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/${ROUTES_CORE.placeDynamicsActor}`,
  placeStaticActor: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/${ROUTES_CORE.placeStaticActor}`,

  routesOdds: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/${ROUTES_CORE.routesOdds}`,
  autoMapList: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/:aid/${ROUTES_CORE.autoMapList}`,
  asSpawningStrategies: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/:aid/${ROUTES_CORE.autoMapList}/:mid/${ROUTES_CORE.asSpawningStrategies}`,
  asPerceptionBased: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/:aid/${ROUTES_CORE.autoMapList}/:mid/${ROUTES_CORE.asSpawningStrategies}/${ROUTES_CORE.asPerceptionBased}`,
  asRateBased: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/:aid/${ROUTES_CORE.autoMapList}/:mid/${ROUTES_CORE.asSpawningStrategies}/${ROUTES_CORE.asRateBased}`,
  asTrafficConfig: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/:aid/${ROUTES_CORE.autoMapList}/:mid/${ROUTES_CORE.asSpawningStrategies}/:strategy/${ROUTES_CORE.asTrafficConfig}`,
  defineObserver: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/:aid/${ROUTES_CORE.autoMapList}/:mid/${ROUTES_CORE.asSpawningStrategies}/:strategy/${ROUTES_CORE.asTrafficConfig}/${ROUTES_CORE.defineObserver}`,
  defineRule: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/:aid/${ROUTES_CORE.autoMapList}/:mid/${ROUTES_CORE.asSpawningStrategies}/:strategy/${ROUTES_CORE.asTrafficConfig}/${ROUTES_CORE.defineRule}`,
  execute: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/:aid/${ROUTES_CORE.autoMapList}/:mid/${ROUTES_CORE.asSpawningStrategies}/:strategy/${ROUTES_CORE.asTrafficConfig}/${ROUTES_CORE.execute}`,
  executeSetting: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.autoScenarios}/:aid/${ROUTES_CORE.autoMapList}/:mid/${ROUTES_CORE.asSpawningStrategies}/:strategy/${ROUTES_CORE.asTrafficConfig}/${ROUTES_CORE.executeSetting}`,

  //TestVehicle
  testVehicles: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}`,

  replicarSim: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.replicarSim}`,
  replicarSimVehicleDetails: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.replicarSim}/:name/:id`,

  coSimulation: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.coSimulation}`,
  simulationDetails: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.coSimulation}/${ROUTES_CORE.simulationDetails}/:id`,

  dSpaceConfig: `/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.coSimulation}/${ROUTES_CORE.simulationDetails}/${ROUTES_CORE.dSpaceConfig}/:id`,
  dSpaceMap: `/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.coSimulation}/${ROUTES_CORE.simulationDetails}/${ROUTES_CORE.dSpaceMap}`,
  dSpaceOdds: `/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.coSimulation}/${ROUTES_CORE.simulationDetails}/${ROUTES_CORE.dSpaceOdds}`,
  dSpaceSpawningStrategies: `/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.coSimulation}/${ROUTES_CORE.simulationDetails}/${ROUTES_CORE.dSpaceSpawningStrategies}`,
  perceptionBased: `/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.coSimulation}/${ROUTES_CORE.simulationDetails}/${ROUTES_CORE.dSpaceSpawningStrategies}/${ROUTES_CORE.perceptionBased}`,
  rateBased: `/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.coSimulation}/${ROUTES_CORE.simulationDetails}/${ROUTES_CORE.dSpaceSpawningStrategies}/${ROUTES_CORE.rateBased}`,
  everywhere: `/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.coSimulation}/${ROUTES_CORE.simulationDetails}/${ROUTES_CORE.dSpaceSpawningStrategies}/${ROUTES_CORE.everywhere}`,
  dSpaceTrafficConfig: `/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.coSimulation}/${ROUTES_CORE.simulationDetails}/${ROUTES_CORE.dSpaceSpawningStrategies}/${ROUTES_CORE.dSpaceTrafficConfig}`,

  coSimDspace: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.coSimDspace}`,
  coSimDSpaceDetails: `/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.coSimDspace}/${ROUTES_CORE.coSimDSpaceDetails}/:id`,
  
  coSimCarMaker: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.coSimCarMaker}`,
  carMakerDetails: `/${ROUTES_CORE.appplications}/${ROUTES_CORE.testVehicles}/${ROUTES_CORE.coSimCarMaker}/${ROUTES_CORE.carMakerDetails}/:id`,
  
  // Maps
  maps: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.maps}`,
  mainMap: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.maps}/${ROUTES_CORE.mainMap}`,
  routeBasedMap: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.maps}/${ROUTES_CORE.routeBasedMap}`,
  areaBasedMap: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.maps}/${ROUTES_CORE.areaBasedMap}`,
  oddBasedMap: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.maps}/${ROUTES_CORE.oddBasedMap}`,
  oddBasedSelectedMap: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.maps}/${ROUTES_CORE.oddBasedMap}/${ROUTES_CORE.oddBasedSelectedMap}`,
  oddViewMap: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.maps}/${ROUTES_CORE.map}/${ROUTES_CORE.oddViewMap}`,

  // ScenAnalytics
  scenAnalytics: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.scenAnalytics}`,
  scenarioUserDefined: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.scenAnalytics}/${ROUTES_CORE.scenarioUserDefined}`,
  scenIntelligentParam: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.scenAnalytics}/${ROUTES_CORE.scenIntelligentParam}`,
  traffic: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.scenAnalytics}/${ROUTES_CORE.traffic}`,
  mapAnalytics: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.scenAnalytics}/${ROUTES_CORE.mapAnalytics}`,
  analyticsMyScenario: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.scenAnalytics}/${ROUTES_CORE.analyticsMyScenario}`,
  myAnalyticsList: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.scenAnalytics}/${ROUTES_CORE.analyticsMyScenario}/${ROUTES_CORE.myAnalyticsList}`,
  analyticsAutoScenario: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.scenAnalytics}/${ROUTES_CORE.analyticsAutoScenario}`,
  AutoAnalyticsList: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.scenAnalytics}/${ROUTES_CORE.analyticsAutoScenario}/${ROUTES_CORE.AutoAnalyticsList}`,
  TraceabilityMatrix: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.scenAnalytics}/${ROUTES_CORE.TraceabilityMatrix}`,

  // SynthData
  synthData: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.synthData}`,
  environments: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.synthData}/${ROUTES_CORE.environments}`,
  
  // Real2Sim
  realSim: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.realSim}`,
  realSimEnvironments: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.realSim}/${ROUTES_CORE.realSimEnvironments}`,
  
  // senVironment
  senVironment: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.senVironment}`,
  senEnvironments: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.senVironment}/${ROUTES_CORE.senEnvironments}`,

  // ScenExtract
  scenExtract: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.scenExtract}`,
  overView: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.scenExtract}/${ROUTES_CORE.overView}`,
  scenarioExecutionList: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.scenExtract}/${ROUTES_CORE.scenarioExecutionList}`,
  autoExecutionList: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.scenExtract}/${ROUTES_CORE.autoExecutionList}`,

  // EducAgents
  educAgents: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}`,
  environmentSetup: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/:aid/${ROUTES_CORE.environmentSetup}`,
  educSpawningStrategies: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/:aid/${ROUTES_CORE.environmentSetup}/:oddid/${ROUTES_CORE.educSpawningStrategies}`,
  educPerceptionBased: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/:aid/${ROUTES_CORE.environmentSetup}/:oddid/${ROUTES_CORE.educSpawningStrategies}/${ROUTES_CORE.educPerceptionBased}`,
  educRateBased: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/:aid/${ROUTES_CORE.environmentSetup}/:oddid/${ROUTES_CORE.educSpawningStrategies}/${ROUTES_CORE.educRateBased}`,
  educTrafficConfig: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/:aid/${ROUTES_CORE.environmentSetup}/:oddid/${ROUTES_CORE.educSpawningStrategies}/:strategy/${ROUTES_CORE.educTrafficConfig}`,
  modelInput: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/:aid/${ROUTES_CORE.environmentSetup}/:oddid/${ROUTES_CORE.educSpawningStrategies}/:strategy/${ROUTES_CORE.educTrafficConfig}/${ROUTES_CORE.modelInput}`,
  attributeSelection: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/:aid/${ROUTES_CORE.environmentSetup}/:oddid/${ROUTES_CORE.educSpawningStrategies}/:strategy/${ROUTES_CORE.educTrafficConfig}/${ROUTES_CORE.modelInput}/${ROUTES_CORE.attributeSelection}`,
  educDefineRewards: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/:aid/${ROUTES_CORE.environmentSetup}/:oddid/${ROUTES_CORE.educSpawningStrategies}/:strategy/${ROUTES_CORE.educTrafficConfig}/${ROUTES_CORE.modelInput}/${ROUTES_CORE.attributeSelection}/${ROUTES_CORE.educDefineRewards}`,
  educTestCases: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/:aid/${ROUTES_CORE.environmentSetup}/:oddid/${ROUTES_CORE.educSpawningStrategies}/:strategy/${ROUTES_CORE.educTrafficConfig}/${ROUTES_CORE.modelInput}/${ROUTES_CORE.attributeSelection}/${ROUTES_CORE.educDefineRewards}/${ROUTES_CORE.educTestCases}`,
  educHyperParameter: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/:aid/${ROUTES_CORE.environmentSetup}/:oddid/${ROUTES_CORE.educSpawningStrategies}/:strategy/${ROUTES_CORE.educTrafficConfig}/${ROUTES_CORE.modelInput}/${ROUTES_CORE.attributeSelection}/${ROUTES_CORE.educDefineRewards}/${ROUTES_CORE.educTestCases}/${ROUTES_CORE.educHyperParameter}`,
  educExecution: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/:aid/${ROUTES_CORE.environmentSetup}/:oddid/${ROUTES_CORE.educSpawningStrategies}/:strategy/${ROUTES_CORE.educTrafficConfig}/${ROUTES_CORE.modelInput}/${ROUTES_CORE.attributeSelection}/${ROUTES_CORE.educDefineRewards}/${ROUTES_CORE.educTestCases}/${ROUTES_CORE.educHyperParameter}/${ROUTES_CORE.educExecution}`,
  

  educMap: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/${ROUTES_CORE.educMap}`,
  educRouteOdds: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/${ROUTES_CORE.educRouteOdds}`,
  educRouteSelectedOdds: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/${ROUTES_CORE.educRouteSelectedOdds}`,
  trafficConfiguration: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/${ROUTES_CORE.trafficConfiguration}`,
  educDynamicActor: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/${ROUTES_CORE.educDynamicActor}`,
  educStaticActor: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/${ROUTES_CORE.educStaticActor}`,
  hyperParameter: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.educAgents}/${ROUTES_CORE.hyperParameter}`,

  // Map Validator
  mapValidator: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.mapValidator}`,
  oddValidatorBasedMap: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.mapValidator}/${ROUTES_CORE.oddValidatorBasedMap}`,
  oddValidatorBasedSelectedMap: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.mapValidator}/${ROUTES_CORE.oddValidatorBasedMap}/${ROUTES_CORE.oddValidatorBasedSelectedMap}`,
  oddViewValidatorMap: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.mapValidator}/${ROUTES_CORE.oddViewValidatorMap}`,

  // High Fidelity
  highFidelity: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.highFidelity}`,
  vehicleSelection: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.highFidelity}/${ROUTES_CORE.vehicleSelection}`,
  highFidelityTestSuits: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.highFidelity}/${ROUTES_CORE.highFidelityTestSuits}`,
  highFidelityDefineObserver: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.highFidelity}/${ROUTES_CORE.highFidelityDefineObserver}`,
  highFidelityDefineRules: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.highFidelity}/${ROUTES_CORE.highFidelityDefineRules}`,
  highFidelityExecutionSetting: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.highFidelity}/${ROUTES_CORE.highFidelityExecutionSetting}`,
  highFidelityExecute: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.highFidelity}/${ROUTES_CORE.highFidelityExecute}`,

  // Low Fidelity
  lowFidelity: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.lowFidelity}`,
  lowFidelityVehicleSelect: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.lowFidelity}/${ROUTES_CORE.lowFidelityVehicleSelect}`,
  lowFidelityTestSuits: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.lowFidelity}/${ROUTES_CORE.lowFidelityTestSuits}`,
  lowFidelitySaveScenario: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.lowFidelity}/${ROUTES_CORE.lowFidelitySaveScenario}`,
  lowFidelityMyScenario: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.lowFidelity}/${ROUTES_CORE.lowFidelityMyScenario}`,
  lowFidelityAutoScenario: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.lowFidelity}/${ROUTES_CORE.lowFidelityAutoScenario}`,
  lowScenarioExecutionList: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.lowFidelity}/${ROUTES_CORE.lowFidelityMyScenario}/${ROUTES_CORE.lowScenarioExecutionList}`,
  lowAutoScenarioExecutionList: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.lowFidelity}/${ROUTES_CORE.lowFidelityAutoScenario}/${ROUTES_CORE.lowAutoScenarioExecutionList}`,
  lowFidelityScenarios: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.lowFidelity}/${ROUTES_CORE.lowFidelityScenarios}`,
  lowFidelityObserver: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.lowFidelity}/${ROUTES_CORE.lowFidelityObserver}`,
  lowFidelityRule: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.lowFidelity}/${ROUTES_CORE.lowFidelityRule}`,

  // Tracebility
  tracebility: `${ROUTES_CORE.projects}/:name/:id/${ROUTES_CORE.tracebility}`,

  // ApplicationTraceable
  applicationTraceable: `${ROUTES_CORE.projects}/:id/${ROUTES_CORE.applicationTraceable}/:name/:id`,

  // odds
  Odd: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.Odd}`,
  oddZone: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.Odd}/${ROUTES_CORE.oddZone}`,
  oddDefinition: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.Odd}/${ROUTES_CORE.oddDefinition}`,

  // Test Suits
  testSuits: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.testSuits}/:title`,
  testSuitsExecute: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.testSuits}/${ROUTES_CORE.testSuitsExecute}`,

  // Carla
  carla: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.carla}`,
  carlaExecute: `/${ROUTES_CORE.projects}/:id/${ROUTES_CORE.appplications}/${ROUTES_CORE.carla}/${ROUTES_CORE.carlaExecute}`,

};

export const UNAUTHENTICATED_ROUTES = {
  auth: `/auth`,
  registration: "/registration",
  forgetPassword: "/forget-password",
  resetPassword: "/reset-password",
};
