import { SET_MAP, UPDATE_MAP } from "..";

const INITIAL_STATE = {
    pralionId: null,
    projectName: null,
    oddId: null,
    oddPralionId:null
}

export default function ReducerMaps(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_MAP:
            return action.payload;
        case UPDATE_MAP:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}