import { Grid } from '@mui/material'
import React from 'react'
import HyperParameterData from './hyperParameterComponent/hyperParameterData'
import HyperParameterTable from './hyperParameterComponent/hyperParameterTable'

export default function HyperParameter() {
    
    return (
        <Grid container>
            <Grid item xs={8}>
                <HyperParameterData />
            </Grid>
            <Grid item xs={4}>
                <HyperParameterTable />
            </Grid>
        </Grid>
    )
}
