import React from 'react'
import { Grid, Icon, Typography } from '@mui/material'
import MainVehicle from "../../../../../assets/images/mainVehicle.png";
import { DYNAMIC_EXECUTE_ICONS, STATIC_EXECUTE_ICONS } from 'components/container/leftSideBarList';

export default function ExecuteDetailForm() {
    return (
        <Grid>
            <Grid>
                <Typography>
                    Test Vehicle Logical Parameters
                </Typography>
                <Grid className="execute-details-form">
                    <img src={MainVehicle} alt={'icon'} style={{ width: "11rem", marginLeft: "1rem" }} />
                </Grid>
            </Grid>
            <Grid>
                <Typography className='mt-3'>
                    Dynamic Actors Logical Parameters
                </Typography>
                <Grid className="execute-details-form">
                    {DYNAMIC_EXECUTE_ICONS.map((icon) => (
                        <Icon>{icon.icons}</Icon>
                    ))}
                </Grid>
            </Grid>
            <Grid>
                <Typography className='mt-3'>
                    Static Actors Logical Parameters
                </Typography>
                <Grid className="execute-details-form">
                    {STATIC_EXECUTE_ICONS.map((icon) => (
                        <Icon>{icon.icons}</Icon>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )
}
