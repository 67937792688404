import { Box, Button, Grid } from '@mui/material';
import React from 'react'
import { Link, useParams } from 'react-router-dom';
import StaticActorForm from './staticActorForm';

export default function EducStaticActor() {
  const param = useParams()
 
  return (
    <Grid container>
    <Grid item xs={8}>
      <Grid>
        <h2>Map</h2>
      </Grid>
    </Grid>
    <Grid item xs={4}>
    <Box sx={{marginBottom:'3em'}}>
        <Link to={`/projects/${param.id}/applications/educ-agents/hyper-parameter-ego`}>
        <Button
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            color: "white",
            width: "130px",
            marginBottom: '1em',
            float: 'right'
          }}
        >
          Next
        </Button>
      </Link>
      </Box>
      <StaticActorForm  />
    </Grid>
  </Grid>
  )
}
