import { ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { AlertX, IconButtonX, LinkButtonX, ProgressX, UxSpace } from "components";
import ButtonX from "components/buttonX";
import { FETCH_ODDS, GET_SHOW_ODDS_MAPS } from "constants/endpoints";
import { SetInfoToLocalStorage } from "utils";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { httpPost } from "utils/httpMethods";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReduxUpdateAutoScenarioJourney } from "services/redux-store/actions/actions-autoscenario";
import { connect } from "react-redux";
import STORE from "services/redux-store/store";

// This page receives the id or URL from the map screen
// call another api after this, "odd on route" using an id or url returns searched_odd_on_route.geojson
const AsOdds = ({ ReduxUpdateAutoScenarioJourney }) => {
	const param = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const { AutoScenario } = STORE.getState();

	const [oddsList, setOddsList] = useState([]);
	const [selectedOddId, setSelectedOddId] = useState("");
	const [oddGeojsonURL, setOddGeojsonURL] = useState(null);
	const [loading, setLoading] = useState(false);
	const [isNext, setNext] = useState(true);

	useEffect(() => {
		console.log("location", location);
		getStoreValues();
		if (location.state) {
			if (location.state.mapId) {
				// route odd loading portion
				fetchOdds(location.state.mapId);
			} else {
				// polygons received loading portion
				loadPolygonsList(location.state.oddUrl);
			}
		}
	}, []);

	const getStoreValues = () => {
		if (AutoScenario.mapGeometry !== undefined && AutoScenario.mapGeometry !== null) {
			setSelectedOddId(AutoScenario.mapGeometry.id);
			setNext(false);
		}
	};

	const fetchOdds = async (mapId) => {
		setLoading(true);
		await httpPost({ url: FETCH_ODDS, payload: { map_id: mapId } })
			.then((resp) => {
				console.log("resp odds", resp);
				setOddGeojsonURL(resp.data.data.geo_json);
				fetch(resp.data.data.geo_json)
					.then((response) => response.json())
					.then((json) => {
						setOddsList(json.features);
						console.log("data", json);
						setLoading(false);
					});
			})
			.catch((err) => {
				toast.error(`${err}. Please try again later`);
				setLoading(false);
			});
	};

	const loadPolygonsList = (geojson) => {
		fetch(geojson)
			.then((resp) => resp.json())
			.then((json) => {
				setOddsList(json.features);
				setOddGeojsonURL(geojson);
			});
	};

	const onClickItemCheckbox = (e) => {
		setSelectedOddId(e.id);
		setNext(false);
		console.log("e", e);
		ReduxUpdateAutoScenarioJourney({ mapGeometry: e });
	};

	const onClickNext = () => {
		SetInfoToLocalStorage("pralion_id", param.id);
		navigate(
			{
				pathname: `/projects/${param.id}/applications/auto-scenarios/as-map/as-odds/as-selected-odd`,
			},
			{ state: { oddId: selectedOddId, geojson: oddGeojsonURL, mapId: location.state.mapId } }
		);
	};

	return (
		<>
			<div className={`container-fuild`}>
				<div className={`row`}>
					<div className={`col-12 d-flex justify-content-between`}>
						<div className={`d-flex`}></div>
						<div className={`d-flex`}>
							<ButtonX className={`me-2`} onClick={() => navigate(-1)} variant={"outlined"} disabled={false}>
								Back
							</ButtonX>
							<ButtonX onClick={onClickNext} disabled={isNext}>
								Next
							</ButtonX>
						</div>
					</div>
				</div>
				<div className={`row mt-3`}>
					{
						<ImageList sx={{ width: "100%", maxHeight: "78vh" }} gap={10} cols={5} rowHeight={260}>
							{loading ? (
								[...Array(15).keys()].map(() => (
									<div>
										<ProgressX block={true} height={230} width={"100%"} />
									</div>
								))
							) : oddsList && oddsList.length === 0 ? (
								<AlertX text="No ODDs found" badge={"info"} />
							) : (
								oddsList.map((item) => (
									<ImageListItem key={item.id}>
										<ImageListItemBar
											sx={{
												background: "linear-gradient(to bottom, rgba(35, 55, 75, 0.7) 0%, " + "rgba(35, 55, 75, 0.3) 70%, rgba(35, 55, 75, 0) 100%)",
												borderRadius: "10px",
											}}
											position="top"
											actionPosition="left"
											actionIcon={
												<IconButtonX
													sx={{ color: "white" }}
													icon={item.id === selectedOddId ? "CheckBoxRounded" : "CheckBoxOutlineBlankRounded"}
													onClick={() => onClickItemCheckbox(item)}
												/>
											}
										/>
										<img
											src={`${GET_SHOW_ODDS_MAPS}/${item.properties.id}`}
											alt={item.properties.odd_keyword}
											loading="lazy"
											className={`img-fluid`}
											style={{ borderRadius: "10px", borderColor: "fff", maxHeight: 260 }}
										/>
										<ImageListItemBar
											sx={{
												background: "linear-gradient(to bottom, rgba(35, 55, 75, 0.7) 100%, " + "rgba(35, 55, 75, 0.3) 70%, rgba(35, 55, 75, 0) 0%)",
												borderEndEndRadius: "10px",
											}}
											position="bottom"
											actionPosition="right"
											title={item.properties.odd_keyword}
										/>
									</ImageListItem>
								))
							)}
						</ImageList>
					}
					{oddsList && oddsList.length > 15 && <UxSpace />}
				</div>
			</div>
		</>
	);
};
export default connect(null, { ReduxUpdateAutoScenarioJourney })(AsOdds);
