import React from 'react'
import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { autoScenarioExecuteTable } from "utils";

export default function ExecuteDeailTable() {
    return (
        <Grid>
            <Grid>
                <Typography color="text.main">
                    Test Vehicle Logical Parameters
                </Typography>
            </Grid>
            <Grid className="execute-details-table-data">
                <TableContainer>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Parameter</TableCell>
                                <TableCell>Fra</TableCell>
                                <TableCell>Ta</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {autoScenarioExecuteTable.map((data) => (
                                <TableRow key={data.id}>
                                    <TableCell>{data.parameter}</TableCell>
                                    <TableCell>{data.fra}</TableCell>
                                    <TableCell>{data.ta}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}
