import React from "react";
import Box from "@mui/material/Box";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardContent,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

export default function DownloadGraph({ versionList }) {
  const list = versionList || [];
  const renderContent = () => {
    return (
      <div>
        <div className="status-table-data mb-3">
          <TableContainer>
            <Table size="medium">
              <TableHead>
                <TableRow>
                  <TableCell>Zone Graph</TableCell>
                  <TableCell>View</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list &&
                  list.map((version) => (
                    <TableRow key={version.id}>
                      <TableCell>Zone graph url</TableCell>
                      <TableCell>
                        <a href={version.zone_graph_url} target="_blank">
                          <DownloadIcon
                            style={{ cursor: "pointer", color: "white" }}
                          />
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  };

  return (
    <Box>
      <CardContent>{renderContent()}</CardContent>
    </Box>
  );
}
