import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Box, Grid, Radio, Button } from "@mui/material";
import ButtonX from "components/buttonX";
import ScenarioTable from "./scenarioComponent/scenarioTable";
import { ModalX, ProgressX, Typos } from "components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getScenarioProjectsById } from "endpointServices/myScenarioServices";
import { SetInfoToLocalStorage, wrapText } from "utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dateConverter from "utils/dateConverter";
import ScenarioStatus from "./scenarioComponent/scenarioStatus";
import { ReduxCreateMyScenarioJourney } from "services/redux-store/actions/actions-myscenario";
import { connect } from "react-redux";
import underConstruction from "../../assets/images/underConstruction.png";
import { pageSize } from "utils/staticsData";
import { getAllTestVehicleProjects } from "endpointServices/testVehicleService";
import STORE from "services/redux-store/store";
import { AUTHENTICATED_ROUTES } from "router/constants";
import ScenarioForm from "./scenarioComponent/scenarioForm";
import AirplayIcon from "@mui/icons-material/Airplay";
import { getMapProjectsById } from "endpointServices/mapsServices";

const MyScenarios = ({ ReduxCreateMyScenarioJourney }) => {
  const param = useParams();
  const { Projects } = STORE.getState();
  let navigator = useNavigate();
  const [MapsExistFlag, setMapsExistFlag] = useState(false);
   const [loadingMaps, setLoadingMaps] = useState(false);
  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [underConstructionModal, setUnderConstructionModal] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [loadingVehicles, setLoadingVehicles] = useState(false);
  const [vehiclesExistFlag, setVehiclesExistFlag] = useState(false);
  const [summaryData, setSummaryData] = useState({});
  const [edited, setEdited] = useState(false);

  const [statusData, setStatusData] = useState({
    name: "",
    pralionId: "",
  });

  const handleOpen = () => {
    setOpen(true);
    setEdited(false);
  };

  const handleClose = () => {
    setOpen(false);
    setStatusOpen(false);
  };

  const handleStatusOpenModal = (data) => {
    setStatusData({
      name: data.name,
      pralionId: data.pralion_id,
    });
    setStatusOpen(true);
  };

  useEffect(() => {
    getProjectList({ pageNumber: page, testsPerPage: pageSize });
    getMapList({ pageNumber: page, testsPerPage: pageSize });
    fetchVehicles();
    localStorage.removeItem("p_id");
  }, [page]);

const fetchVehicles = async () => {
  setLoadingVehicles(true);

  try {
    const response = await getAllTestVehicleProjects(parseInt(param.id));

    if (response?.status === 200) {
      if (response?.data?.data?.test_vehicles.length > 0) {
        const hasVehicleId = response.data.data.test_vehicles.some(
          (vehicle) => vehicle.application_version_id !== null
        );

        if (hasVehicleId) {
          setVehiclesExistFlag(true);
        } else {
          setVehiclesExistFlag(false);
        }
      } else {
        setVehiclesExistFlag(false);
      }
    } else {
      setVehiclesExistFlag(false);
    }
  } catch (err) {
    setVehiclesExistFlag(false);
  } finally {
    setLoadingVehicles(false);
  }
};

const getMapList = async (pagination) => {
  setLoadingMaps(true);

  const projectId = parseInt(param.id);
  const response = await getMapProjectsById(projectId, pagination);

  if (response?.status === 200) {
    if (response?.data?.data?.applications.length > 0) {

      const hasVersionId = response.data.data.applications.some(
        (application) => application.application_version_id !== null
      );

      if (hasVersionId) {
        setMapsExistFlag(true); 
      } else {
        setMapsExistFlag(false); 
      }
    } else {
      setMapsExistFlag(false);
    }
  } else {
    setMapsExistFlag(false);
  }
  setLoadingMaps(false);
};

  const getProjectList = async (pagination) => {
    setFetching(true);
    const projectId = parseInt(param.id);
    const response = await getScenarioProjectsById(projectId, pagination);
    if (response?.status === 200) {
      setFetching(false);
      const { data } = response?.data;
      setList(data.applications);
      setTotalRows(data.no_of_applications);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setList([]);
      setFetching(false);
    }
  };

  const goto = (data) => {
    console.log('data', data)
    SetInfoToLocalStorage('p_id', param.id)
    if (data.application_version_id) {
      ReduxCreateMyScenarioJourney(data.pralion_id, data.name, true)
    }
    else {
      ReduxCreateMyScenarioJourney(data.pralion_id, data.name)
    }
    navigator(AUTHENTICATED_ROUTES.mapList.replace(":id", Projects.projectId).replace(":aid", data.pralion_id))
  }

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSummary = (data) => {
    console.log("data", data);
    setEdited(true);
    setOpen(true);
    setSummaryData(data);
  };

  const columns = useMemo(
    () => [
      {
        field: "name",
        renderHeader: () => <span className="heading-2">{"Scenario ID "}</span>,
        width: 550,
        renderCell: (params) => (
          <Grid>
            <Button
              className="Main-link-simple row-heading"
              onClick={() => goto(params.row)}
              sx={{ color: "white" }}
            >
              {wrapText(params.value)}
            </Button>
          </Grid>
        ),
      },
      {
        field: "date_created",
        renderHeader: () => <span className="heading-2">{"Created At"}</span>,
        width: 280,
        renderCell: (params) => (
          <span className="row-heading">{dateConverter(params.value)}</span>
        ),
      },
      {
        field: "action",
        renderHeader: () => <span className="heading-2">{"Status"}</span>,
        width: 235,
        renderCell: (params) => {
          const status = params.row.dag_state.dag_state;
          return (
            <>
              <div className="scenarioStatusRadioButton">
                <Radio
                  checked={
                    status === "pending" || !params.row.dag_state.dag_state
                  }
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "gray",
                      color: "red",
                      "&:checked + span": {
                        backgroundColor: "gray",
                        color: "gray",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
                <Radio
                  checked={
                    status === "queued" 
                  }
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "#ffff00",
                      color: "#ffff00",
                      "&:checked + span": {
                        backgroundColor: "#ffff00",
                        color: "#ffff00",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
                <Radio
                  checked={status === "running"}
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "#ff9800",
                      color: "#ff9800",
                      "&:checked + span": {
                        backgroundColor: "#ff9800",
                        color: "#ff9800",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
                <Radio
                  color="success"
                  checked={status === "success"}
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "#4caf50",
                      color: "#4caf50",
                      "&:checked + span": {
                        backgroundColor: "#4caf50",
                        color: "#4caf50",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
                <Radio
                  checked={status === "failed"}
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "red",
                      color: "red",
                      "&:checked + span": {
                        backgroundColor: "red",
                        color: "red",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
              </div>

              {/* <Button
              variant="contained"
              sx={{
                backgroundColor: "secondary.dark",
                textTransform: "none",
                borderRadius: "8px",
              }}
            >
              Stop
            </Button> */}
              <Button
                sx={{ color: "text.main" }}
                onClick={() => handleStatusOpenModal(params.row)}
              >
                <MoreVertIcon />
              </Button>
            </>
          );
        },
      },
      {
        renderHeader: () => <span className="heading-2">{"Summary"}</span>,
        width: 180,
        renderCell: (params) => (
          <Grid
            onClick={() => handleSummary(params.row)}
            sx={{ cursor: "pointer",marginLeft:'2em' }}
          >
            <AirplayIcon />
          </Grid>
        ),
      },
    ],
    [list, page]
  );

  return (
    <Fragment>
       <ModalX
  open={open}
  close={handleClose}
  heading={!edited ? "Create New MyScenario" : `${summaryData.name} Summary`}
  subheading={
    !edited
      ? "Enter MyScenario details to continue"
      : "MyScenario application details"
  }
>

  {loadingMaps || loadingVehicles ? (
    <>
      <ProgressX />
    </>
  ) : MapsExistFlag && vehiclesExistFlag ? (
    <>
      <ScenarioForm
        syncList={getProjectList}
        handleClose={handleClose}
        summaryData={summaryData}
        isEdit={edited}
      />
    </>
  ) : !MapsExistFlag ? (
    <>
      <Typos.Body1>
        No Maps found to create a scenario. Please go and create a{" "}
        <Link to={`/projects/${param.id}/applications/maps`}>
          Map
        </Link>
        .
      </Typos.Body1>
    </>
  ) : (
    <>
      <Typos.Body1>
        No vehicles found to create a scenario. Please go and create a{" "}
        <Link to={`/projects/${param.id}/applications/test-vehicles`}>
          test vehicle
        </Link>
        .
      </Typos.Body1>
    </>
  )}
</ModalX>

      <ModalX
        open={underConstructionModal}
        close={() => setUnderConstructionModal(false)}
        heading={"MyScenario"}
        subheading={"Service Under Maintenance "}
      >
        <Box display="flex" flexDirection="row" alignItems="center" p={2}>
          <Box flex="1" p={2}>
            <div>
              <p>
                We apologize for any inconvenience, this application is
                currently under maintenance. Only limited functionality is
                available where you can perform the application journey but
                would not be able to execute or generate scenarios.
              </p>
            </div>
          </Box>
          <Box
            flex="1"
            p={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={underConstruction}
              alt="Under Construction"
              style={{ maxWidth: "80%", maxHeight: "100%" }}
            />
          </Box>
        </Box>
      </ModalX>

      <ModalX
        open={statusOpen}
        close={() => setStatusOpen(false)}
        heading={"State Management"}
        subheading={`${statusData.name}`}
      >
        <ScenarioStatus statusObject={statusData} handleClose={handleClose} />
      </ModalX>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        mb={2}
      >
        <ButtonX onClick={handleOpen}>New MyScenario</ButtonX>
      </Box>
      <ScenarioTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />
    </Fragment>
  );
};

export default connect(null, { ReduxCreateMyScenarioJourney })(MyScenarios);
