import { SET_DSPACE, UPDATE_DSPACE } from ".."

export const ReduxCreateDSpaceJourney = (pid) => {
    return {
        type: SET_DSPACE,
        payload: { projectId: pid }
    }
}

export const ReduxUpdateDSpaceJourney = (obj) => {
    return {
        type: UPDATE_DSPACE,
        payload: obj
    }
}