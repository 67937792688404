import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { Button, Divider, Grid, Typography, Box, Alert } from "@mui/material";
import CodeEditor from "components/codeEditor";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getRlHyperParametersInterfaces,
  postRlHyperParametersInterfaces,
} from "endpointServices/educAgentServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AUTHENTICATED_ROUTES } from "router/constants";

export default function EducHyperParameter() {
  const navigator = useNavigate();
  const param = useParams();
  const [codeDoc, setCodeDoc] = useState("");
  const [codeEditor, setCodeEditor] = useState("");

  const [open, setOpen] = useState(false);
  const [collapseId, setCollapseId] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [executed, setExecuted] = useState(false);
  const [execution, setExecution] = useState(true);
  const [isNextButtonDisabled, setNextButtonDisabled] = useState(false);

  useEffect(() => {
    getCodeSample();
    // getCodeObserverMetric()
  }, []);

  const handleClick = (id) => {
    setCollapseId(id);
    setOpen(!open);
  };

  const getCodeSample = async () => {
    const response = await getRlHyperParametersInterfaces();
    console.log("response", response);
    if (response?.status === 200) {
      const sample = response?.data?.data?.output;
      setCodeDoc(sample);
      setCodeEditor(sample);
      setExecution(false)
      // getCodeObserverMetric(code)
    } else {
      setExecution(false)
      const err = response?.message || "Request failed";
      toast.error(`${err}. Please try again later`);
    }
  };

  const handleCodeInput = React.useCallback((value) => {
    setCodeEditor(value);
  }, []);

  const onSubmitaCodeEditor = async (e) => {
    setExecuted(true)
    e.preventDefault();
    const payload = {
      pralion_id: parseInt(param.aid),
      user_base_config: JSON.parse(codeEditor),
    };
    console.log("Payload:", payload);
    const response = await postRlHyperParametersInterfaces(payload);
    console.log("response submit", response);
    if (response?.status === 200) {
      const resp = response?.data?.data?.output;
      setErrorCode(resp);
      setExecuted(false)
      // toast.success("Executed successfully");
      console.log("response", response);
      setNextButtonDisabled(response?.data?.data?.success === true);
    } else {
      setExecuted(false)
      const err = response?.message || "Failed to sumbit request";
      toast.error(`${err}. Please try again later`);
      setNextButtonDisabled(false);
    }
  };

  return (
    <Fragment>
      <Grid item sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            color: "white",
            width: "130px",
            marginRight: "10px",
          }}
          onClick={() => navigator(-1)}
        >
          Back
        </Button>

        <Link
          to={AUTHENTICATED_ROUTES.educExecution
            .replace(":id", param.id)
            .replace(":aid", param.aid)
            .replace(":oddid", param.oddid)
            .replace(":strategy", param.strategy)}
        >
          <Button
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "white",
              width: "130px",
            }}
            disabled={!isNextButtonDisabled}
          >
            Next
          </Button>
        </Link>
      </Grid>

      <Divider
        className="mb-3 mt-3"
        sx={{ borderBottom: "1px solid #D9D9D9" }}
      />

      <Grid container sx={{ backgroundColor: "black" }}>
        <Grid item xs={12}>
          <Grid container direction="column">
            <Grid
              item
              sx={{
                height: "60vh",
                width: "100%",
                borderBottom: "1px solid",
                borderColor: "primary.main",
                overflowY: "auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "80%" }}>
                <Typography
                  variant="h6"
                  sx={{
                    backgroundColor: "secondary.dark",
                    color: "white",
                    padding: "12px",
                    textAlign: "center",
                    width: "85px",
                    lineHeight: 1,
                  }}
                >
                  Editor
                </Typography>
                <CodeEditor
                  onChange={handleCodeInput}
                  value={codeEditor}
                  className="code-editor"
                  isEdit={true}
                />
              </div>
              <div>
                <Button
                  onClick={onSubmitaCodeEditor}
                  sx={{
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                    color: "white",
                    marginTop: "10px",
                    marginRight: "1rem",
                    width: "130px",
                  }}
                  disabled={executed || execution}
                >
                  Execute
                </Button>
              </div>
            </Grid>
            <Grid item sx={{ height: "30vh", overflowY: "auto" }}>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "secondary.dark",
                  color: "white",
                  padding: "12px",
                  textAlign: "center",
                  width: "85px",
                  lineHeight: 1,
                }}
              >
                Result
              </Typography>
              <Box p={2}>
                <Typography variant="body2" gutterBottom>
                  {errorCode === "" ? (
                    <Alert severity="info" icon={false}>
                      Please try to execute the code
                    </Alert>
                  ) : (
                    <Alert
                      severity={isNextButtonDisabled ? "success" : "error"}
                      icon={false}
                    >
                      {errorCode || `API response empty`}
                    </Alert>
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
