export const USER_AUTH = "user-auth-state";
export const SET_AUTO_SCENARIO = "set-auto-scenario";
export const UPDATE_AUTO_SCENARIO = "update-auto-scenario";
export const DELETE_AUTO_SCEHNARIO = "delete-auto-scenario";

// D Space
export const SET_DSPACE = "set-d-space";
export const UPDATE_DSPACE = "update-d-space";
export const SET_DSPACE_TRAFFIC_CONFIG = "set-d-space-traffic-config";
export const UPDATE_DSPACE_TRAFFIC_CONFIG = "update-d-space-traffic-config";

// Projects
export const GET_PROJECT = "parent-project";
export const UPDATE_PROJECT = "u-parent-project";
export const SET_VEHICLE = "set_vehicle"
export const UPDATE_VEHICLE = "update_vehicle"
export const GET_VEHICLE = "get_vehicle"

// EducAgents
export const SET_EDUCAGENTS = "set-educ-agents"
export const UPDATE_EDUCAGENTS = "update-educ-agents"
export const GET_EDUCAGENTS = "get-educ-agents"

// AutoScenario
export const SET_AUTOSCENARIO = "set_autoscenario"
export const UPDATE_AUTOSCENARIO = "update_autoscenario"
export const GET_AUTOSCENARIO = "get_autoscenario"

// MyScenario
export const SET_MYSCENARIO = 'set_myscenario'
export const UPDATE_MYSCENARIO = 'update_myscenario'


// MapValidator
export const SET_MAP_VALIDATOR = "set map validator";
export const UPDATE_MAP_VALIDATOR = "update map validator"

// Scene Analytics
export const SET_SCENE_ANALYTICS = "set scene analytics";
export const UPDATE_SCENE_ANALYTICS = "update scene analytics"

// ScenExtract
export const SET_SCENEXTRACT = "set_scenextract";
export const UPDATE_SCENEXTRACT = "update_scenextract";

// LowFidelity
export const SET_LOW_FIDELITY = "set_low_fidelity";
export const UPDATE_LOW_FIDELITY = "update_low_didelity";

// Maps
export const SET_MAP = "set_map";
export const UPDATE_MAP = "update_map";

// Odd
export const SET_ODD = "set_odd";
export const UPDATE_ODD = "update_odd";

// TestSuits
export const SET_TEST_SUITS = "set_test_suits";
export const UPDATE_TEST_SUITS = "update_test_suits";

// Carla
export const SET_CARLA = "set_carla";

// HighFidelity
export const HIGH_FIDELITY_SET_VEHICLE = "high_fidelity_set_vehicle";
export const HIGH_FIDELITY_UPDATE_VEHICLE = "high_fidelity_update_vehicle";