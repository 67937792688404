import { AccordionActions, Button, ButtonBase, Divider, Fab, Slider } from '@mui/material';
import { CardX, CollapseContent, IconButtonX, ProgressX, Typos } from 'components'
import React, { useState, Fragment } from 'react';
import AddIcon from '@mui/icons-material/Add';
import styled from '@emotion/styled';
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import _, { sum } from 'lodash';
import { connect } from 'react-redux';
import { ReduxUpdateDSpaceTrafficConfigurations } from 'services/redux-store/actions';


function mapStateToProps(state) {
	return {
		DSpaceTrafficConfigs: state.DSpaceTrafficConfigs
	}
}

function DriverProfiles({ selectedVehicle, selectDriverProfileCallback, saveUpdatedDataCallback, DSpaceTrafficConfigs, ReduxUpdateDSpaceTrafficConfigurations }) {
	const [expanded, setExpanded] = React.useState("panel-0");

	const handleDriverProfilesPanelChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const onClickCloseDriverProfiles = () => {
		saveUpdatedDataCallback()
	}

	const onClickAddDriverProfile = (e, type) => {
		console.log('e', e, type);
		const tempDSpaceTrafficConfigs = _.cloneDeep(DSpaceTrafficConfigs);
		const newDriverProfile = _.cloneDeep(DSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][type][DSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][type].length - 1 || 0]);
		tempDSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][type] = [...tempDSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][type], newDriverProfile];
		ReduxUpdateDSpaceTrafficConfigurations(tempDSpaceTrafficConfigs);
	}

	const onEditDriverProfile = (e, type, index) => {
		if (selectedVehicle !== null) {
			const tempDSpaceTrafficConfigs = _.cloneDeep(DSpaceTrafficConfigs);
			tempDSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][type][index].value = e.target.value;
			ReduxUpdateDSpaceTrafficConfigurations(tempDSpaceTrafficConfigs);
		}
	}

	const onClickDeleteDriverProfile = (type, index, i = 1) => {
		if (selectedVehicle !== null) {
			const tempDSpaceTrafficConfigs = _.cloneDeep(DSpaceTrafficConfigs);
			tempDSpaceTrafficConfigs[selectedVehicle]['driverProfiles'][type].splice(index, i);
			ReduxUpdateDSpaceTrafficConfigurations(tempDSpaceTrafficConfigs);
		}
	}


	const calculateDriverProfileSums = () => {
		const sums = [];
		const driverProfiles = DSpaceTrafficConfigs[selectedVehicle].driverProfiles;
		const totalSum = Math.round(Object.values(driverProfiles).reduce((acc, subArray) => {
			subArray.forEach((item) => {
				acc += item.value;
			});
            sums.push(subArray.map(nitem => nitem.value));
			return acc;
		}, 0) * 100) / 100;
		return {is1: totalSum === 1, sums, total: totalSum};
	}

	const valuesAudit = calculateDriverProfileSums();

	return (
		<div className={``}>
			<div className={`d-flex justify-content-between align-items-center`}>
				<Typos.H6>Driver Profiles</Typos.H6>
				<IconButtonX
					disabled={!valuesAudit.is1}
					icon={"ClearRounded"}
					onClick={onClickCloseDriverProfiles}
				/>
			</div>
			<Typos.Caption
				// color={isValidValues ? "green" : "red"}
				className={`d-flex align-items-center`}
			>
				Complete sum of 1
				<Typos.Caption color={valuesAudit.is1 ? "lightgreen" : "red"} className={`mx-1`}>
					({valuesAudit.total.toFixed(1)}/1)
				</Typos.Caption>{" "}
				to proceed
			</Typos.Caption>
            <ProgressX linear={true} progress={(valuesAudit.total * 100).toFixed(0)} color={valuesAudit.is1 ? "primary" : "error"} />
			<Divider />
			<div className={`mt-3`} style={{ height: "52vh", overflowX: "hidden", overflowY: "auto" }}>
				{DSpaceTrafficConfigs !== null &&
					Object.keys(DSpaceTrafficConfigs[selectedVehicle]["driverProfiles"]).map((profile, i) => (
						<Accordion key={i} expanded={expanded === `panel-${i}`} onChange={handleDriverProfilesPanelChange(`panel-${i}`)}>
							<AccordionSummary id={`panel${i}d-header`}>
								<div className={`d-flex justify-content-between align-items-center w-100`}>
									<Typos.Body1 className="fw-bold" >
										{profile} ({valuesAudit.sums[i].length})
									</Typos.Body1>
									<Typos.Caption color={valuesAudit.is1 ? "lightgreen" : "red"}>{sum(valuesAudit.sums[i]).toFixed(1)}</Typos.Caption>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								{DSpaceTrafficConfigs[selectedVehicle]["driverProfiles"][profile].map((agent, j) => (
									<div key={`${i}-${j}`} className="px-3">
										<div className="d-flex justify-content-between align-items-center w-100">
											<Typos.Caption>
												#{j + 1} ({agent.value})
											</Typos.Caption>
											<div className="d-flex">
												<IconButtonX
													disabled={!valuesAudit.is1}
													icon={"Settings"}
													size={"small"}
													onClick={() => selectDriverProfileCallback({ index: j, type: profile })}
												/>

												<IconButtonX
													disabled={DSpaceTrafficConfigs[selectedVehicle]["driverProfiles"][profile].length === 1}
													icon={"DeleteRounded"}
													size={"small"}
													onClick={() => onClickDeleteDriverProfile(profile, j)}
												/>
											</div>
										</div>
										<div>
											<Slider
												name={`${profile}-${j}`}
												size="small"
												color="primary"
												min={0.0}
												max={1.0}
												step={0.1}
												valueLabelDisplay="auto"
												marks
												value={agent.value}
												onChange={(e) => onEditDriverProfile(e, profile, j)}
											/>
										</div>
									</div>
								))}
								<Button variant="text" fullWidth size="small" onClick={(e) => onClickAddDriverProfile(e, profile)}>
									<AddIcon /> Add {profile} Profile
								</Button>
							</AccordionDetails>
						</Accordion>
					))}
			</div>
		</div>
	);
}

export default connect(mapStateToProps, { ReduxUpdateDSpaceTrafficConfigurations })(DriverProfiles);


const Accordion = styled((props) => <MuiAccordion disableGutters elevation={1} {...props} />)(({ theme }) => ({
	border: `1px solid ${theme.palette.divider}`,
	backgroundColor: "transparent",
	// "&:not(:last-child)": {
	// 	borderBottom: 0,
	// },
	"&:before": {
		display: "none",
	},
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />)(({ theme }) => ({
	backgroundColor: "transparent",//"dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
	flexDirection: "row-reverse",
	"& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
		transform: "rotate(90deg)",
	},
	"& .MuiAccordionSummary-content": {
		marginLeft: theme.spacing(1),
	},
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(0),
	borderTop: "1px solid rgba(0, 0, 0, .125)",
}));