import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Button,
  MenuItem,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { getCameraSensor } from "../../../../../../endpointServices/testVehicleService";
import CameraSensorForm from "./CameraSensorForm";
import STORE from "services/redux-store/store";
import Slider from "@mui/material/Slider";
import { Typos } from "components";
import AddCameraSensorModal from "components/addCameraSensorModal";

export default function SensorSetupForm({ sensorSetup, onClose, onSave, disabledSubmit, handleAddCameraIcon, setSensorSelected }) {
  console.log("sensorSetup", sensorSetup)
  const [reload, setReload] = useState(false);
  const [cameraSensorOpener, setCameraSensorOpener] = useState(false);
  const [state, setState] = useState({
    name: sensorSetup?.name,
    pos_x: 0,
    pos_y: 0,
    pos_z: 0,
    rot_x: 0,
    rot_y: 0,
    rot_z: 0,
  });
  const [sensorPreset, setSensorPreset] = useState({
    sensor_presets: sensorSetup?.sensor_presets[0]?.name,
    sensor_id: sensorSetup?.sensor_presets[0]?.sensor_id,
    pos_x: 0,
    pos_y: 0,
    pos_z: 0,
    rot_x: 0,
    rot_y: 0,
    rot_z: 0,
  });
  const [cameraSensorList, setCameraSensorList] = useState([]);
  const [disable, setDisable] = useState(true);
  const { MyVehicle } = STORE.getState();
  
  const closeCameraSensorModal = () => setCameraSensorOpener(false);

  useEffect(() => {
    fetchCameraSensors();
  }, [reload]);

  const fetchCameraSensors = async () => {
    const { data } = await getCameraSensor(MyVehicle.sensorSetupId);
    setCameraSensorList(data.data);
  };

  const stateHandler = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const sensorPresetHandler = (e) => {
    const { name, value } = e.target;
    if (name === "sensor_presets") {
      setDisable(false)
      setSensorPreset((prev) => ({
        ...prev,
        sensor_presets: value.name,
        sensor_id: value.id,
        pos_x: 0,
        pos_y: 0,
        pos_z: 0,
        rot_x: 0,
        rot_y: 0,
        rot_z: 0,
      }));
    } else
      setSensorPreset((prev) => ({
        ...prev,
        [name]: value,
      }));
  };

  const reloadHandler = () => {
    setReload(true);
  };

  const onSubmit = async () => {
    const payload = {
      name: state.name,
      is_default_setup: false,
      unique_id: MyVehicle.vehicleProperties.unique_id,
      origin_pos_x: state.pos_x,
      origin_pos_y: state.pos_y,
      origin_pos_z: state.pos_z,
      origin_rot_x: state.rot_x,
      origin_rot_y: state.rot_y,
      origin_rot_z: state.rot_z,
      sensor_presets: [
        {
          name: sensorPreset.sensor_presets,
          pos_x: sensorPreset.pos_x,
          pos_y: sensorPreset.pos_y,
          pos_z: sensorPreset.pos_z,
          rot_x: sensorPreset.rot_x,
          rot_y: sensorPreset.rot_y,
          rot_z: sensorPreset.rot_z,
          sensor_id: sensorPreset.sensor_id,
          sensor_type: "camera",
        },
      ],
    };
    onSave(payload);
    disabledSubmit(true)
    handleAddCameraIcon({})
    setSensorSelected([])
    onClose();
  };

  return (
    <>
      <Box className="rbs-left-cameraSensor-form" sx={{ overflowX: "auto" }}>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12} sx={{ marginRight: "1rem" }}>
            <TextField
              name="name"
              type="text"
              variant="outlined"
              label="Name"
              fullWidth
              value={state.name}
              onChange={stateHandler}
              disabled
            />
          </Grid>
          {/* <Grid item xs={11} sx={{ mt: 1 }}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Position X"}{" "}
              <Typos.Caption className={`ms-1`}>({state.pos_x})</Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.pos_x}
              onChange={(e, value) =>
                stateHandler({ target: { name: "pos_x", value } })
              }
              min={0}
              max={100}
              step={1}
            />
          </Grid>
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Position Y"}{" "}
              <Typos.Caption className={`ms-1`}>({state.pos_y})</Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.pos_y}
              onChange={(e, value) =>
                stateHandler({ target: { name: "pos_y", value } })
              }
              min={0}
              max={100}
              step={1}
            />
          </Grid>
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Position Z"}{" "}
              <Typos.Caption className={`ms-1`}>({state.pos_z})</Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.pos_z}
              onChange={(e, value) =>
                stateHandler({ target: { name: "pos_z", value } })
              }
              min={0}
              max={100}
              step={1}
            />
          </Grid>
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Rotation X"}{" "}
              <Typos.Caption className={`ms-1`}>({state.rot_x})</Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.rot_x}
              onChange={(e, value) =>
                stateHandler({ target: { name: "rot_x", value } })
              }
              min={0}
              max={100}
              step={1}
            />
          </Grid>
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Rotation Y"}{" "}
              <Typos.Caption className={`ms-1`}>({state.rot_y})</Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.rot_y}
              onChange={(e, value) =>
                stateHandler({ target: { name: "rot_y", value } })
              }
              min={0}
              max={100}
              step={1}
            />
          </Grid>
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Rotation Z"}{" "}
              <Typos.Caption className={`ms-1`}>({state.rot_z})</Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={state.rot_z}
              onChange={(e, value) =>
                stateHandler({ target: { name: "rot_z", value } })
              }
              min={0}
              max={100}
              step={1}
            />
          </Grid> */}
          <Grid item xs={12} sx={{ marginRight: "1rem" }}>
            {console.log("sensor_presets", sensorPreset)}
            <Box
              className="mb-1"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              Sensor Presets Name
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "secondary.dark",
                borderRadius: "4px",
                p: 1,
                border: "1px solid #ccc",
                height: "6vh",
                transition: "border-color 0.3s ease",
                "&:focus-within": {
                  borderColor: "primary.main",
                },
              }}
            >
              <TextField
                select
                name="sensor_presets"
                variant="outlined"
                // label="Sensor Presets Name"
                fullWidth
                value={sensorPreset.name}
                onChange={sensorPresetHandler}
                SelectProps={{
                  MenuProps: {
                    className: "customDropdown",
                  },
                }}
                sx={{
                  color: "white",
                  flex: 1,
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                {cameraSensorList.map((option) => (
                  <MenuItem key={option.id} value={option}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <IconButton onClick={() => setCameraSensorOpener(true)}>
                <AddIcon className="D-icon-color" />
              </IconButton>
            </Box>
          </Grid>
          {/* <Grid item xs={11} sx={{ mt: 1 }}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Position X"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({sensorPreset.pos_x})
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={sensorPreset.pos_x}
              onChange={(e, value) =>
                sensorPresetHandler({ target: { name: "pos_x", value } })
              }
              min={0}
              max={2}
              step={0.1}
            />
          </Grid> */}
          {/* <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Position Y"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({sensorPreset.pos_y})
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={sensorPreset.pos_y}
              onChange={(e, value) =>
                sensorPresetHandler({ target: { name: "pos_y", value } })
              }
              min={0}
              max={4}
              step={0.1}
            />
          </Grid>
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Position Z"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({sensorPreset.pos_z})
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={sensorPreset.pos_z}
              onChange={(e, value) =>
                sensorPresetHandler({ target: { name: "pos_z", value } })
              }
              min={0}
              max={5}
              step={0.1}
            />
          </Grid>
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Roll"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({sensorPreset.rot_z})
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={sensorPreset.rot_z}
              onChange={(e, value) =>
                sensorPresetHandler({ target: { name: "rot_z", value } })
              }
              min={0}
              max={2}
              step={0.1}
            />
          </Grid>
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Pitch"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({sensorPreset.rot_x})
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={sensorPreset.rot_x}
              onChange={(e, value) =>
                sensorPresetHandler({ target: { name: "rot_x", value } })
              }
              min={0}
              max={2}
              step={0.1}
            />
          </Grid>
          <Grid item xs={11}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"YAW"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({sensorPreset.rot_y})
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={sensorPreset.rot_y}
              onChange={(e, value) =>
                sensorPresetHandler({ target: { name: "rot_y", value } })
              }
              min={0}
              max={2}
              step={0.1}
            />
          </Grid> */}
        </Grid>

        <div
          style={{ marginTop: "2rem", marginBottom: "2rem", marginLeft: "72%" }}
        >
          <Button
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "white",
              fontSize: "13px",
            }}
            onClick={onSubmit}
            disabled={disable}
          >
            Save
          </Button>
        </div>
      </Box>

      <AddCameraSensorModal
        open={cameraSensorOpener}
        close={closeCameraSensorModal}
        // width={"20%"}
        heading={"Camera Sensors"}
        subheading={"Add Camera Sensors"}
      >
        <Box sx={{ ml: "15px" }}>
        {/* <div className={`traffic-config-dynamic-actors-right-panel shadow`}> */}
          <CameraSensorForm
            onClose={closeCameraSensorModal}
            reloader={reloadHandler}
            cameraSensorList={cameraSensorList}
          />
          {/* </div> */}
        </Box>
      </AddCameraSensorModal>
    </>
  );
}
