import React, { useState, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Checkbox,
} from "@mui/material";
import styled from "@mui/system/styled";

// Define styled components
const StyledTableContainer = styled(TableContainer)`
  background-color: rgba(32, 60, 104, 0.92);
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
  color: ${({ theme }) => theme.palette.text.main};
  font-size: 13px;
`;

export default function RenderingTypeForm({
  handleClose,
  sensorObject,
  renderingType,
}) {
  const [cameraPresetState, setCameraPresetState] = useState([]);
  console.log('sensorObject', sensorObject)

  useEffect(() => {
    if (sensorObject?.rendering_type) {
      setCameraPresetState(sensorObject.rendering_type);
    } else {
      const types = [
        {
          camera_name: sensorObject.name,
          raw: true,
          segmented: false,
          depth: false,
          normals: false,
          save_images: false,
          key: sensorObject.id,
        },
      ];
      setCameraPresetState(types);
    }
  }, []);

  const handleCheckboxChangeFactory = (event, index) => {
    const { name, checked } = event.target;
    const newCheckboxState = [...cameraPresetState];
    newCheckboxState[index][name] = checked;
    setCameraPresetState(newCheckboxState);
  };

  const cameraPresetTableColumns = [
    {
      title: "Name",
      key: "camera_name",
    },
    ...cameraPresetState.map((row, index) => ({
      title: row.camera_name,
      key: `checkbox-${index}`,
    })),
  ];

  const features = ["Raw", "Segmented", "Depth", "Normals", "Save Images"];

  const handleSave = () => {
    console.log('cameraPresetState', cameraPresetState)
    renderingType(cameraPresetState);
    handleClose();
  };

  return (
    <>
      <Box sx={{ height: "28rem", overflowX: "auto", marginTop: "1rem" }}>
        <div
          style={{
            float: "right",
            marginRight: 15,
            // marginTop: "2rem",
            marginBottom: "1rem",
          }}
        >
          <StyledButton onClick={handleSave}>Save</StyledButton>
        </div>
        <StyledTableContainer component={Paper}>
          <Table sx={{ minWidth: 200 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {cameraPresetTableColumns.map((column) => (
                  <TableCell key={column.key}>{column.title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {features.map((feature, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {feature}
                  </TableCell>
                  {cameraPresetState.map((row, rowIndex) => (
                    <TableCell align="right" key={`cell-${index}-${rowIndex}`}>
                      <Checkbox
                        name={feature.toLowerCase().replace(" ", "_")}
                        checked={row[feature.toLowerCase().replace(" ", "_")]}
                        onChange={(e) =>
                          handleCheckboxChangeFactory(e, rowIndex)
                        }
                      />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
    </>
  );
}
