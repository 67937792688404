import React, { Fragment, useEffect, useState, useMemo } from "react";
import { Box, Grid, Radio, Button } from "@mui/material";
import ButtonX from "components/buttonX";
import { ModalX, ProgressX, Typos } from "components";
import AutoScenarioTable from "./autoScenariosComponent/autoScenarioTable";
import { Link, useNavigate, useParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  createAutoScenarioProjects,
  getAutoScenarioProjectsById,
} from "endpointServices/autoScenarioServices";
import { SetInfoToLocalStorage, wrapText } from "utils";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dateConverter from "utils/dateConverter";
import { ReduxCreateAutoScenarioJourney } from "services/redux-store/actions/actions-autoscenario";
import { connect } from "react-redux";
import underConstruction from "../../assets/images/underConstruction.png";
import AutoScenarioStatus from "./autoScenariosComponent/autoScenarioStatus";
import { pageSize } from "utils/staticsData";
import ApplicationForm from "components/applicationForm";
import { getAllTestVehicleProjects } from "endpointServices/testVehicleService";
import AutoScenarioForm from "./autoScenariosComponent/autoScenarioForm";
import AirplayIcon from "@mui/icons-material/Airplay";
import { getMapProjectsById } from "endpointServices/mapsServices";

const AutoScenarios = ({ ReduxCreateAutoScenarioJourney }) => {
  const param = useParams();
  let navigator = useNavigate();

  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [underConstructionModal, setUnderConstructionModal] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [statusOpen, setStatusOpen] = useState(false);
  const [loadingVehicles, setLoadingVehicles] = useState(false);
   const [loadingMaps, setLoadingMaps] = useState(false);
  const [vehiclesExistFlag, setVehiclesExistFlag] = useState(false);
  const [MapsExistFlag, setMapsExistFlag] = useState(false);
  const [summaryData, setSummaryData] = useState({});
  const [edited, setEdited] = useState(false);
  const [statusData, setStatusData] = useState({
    name: "",
    pralionId: "",
  });

  const handleStatusOpenModal = (data) => {
    setStatusData({
      name: data.name,
      pralionId: data.pralion_id,
    });
    setStatusOpen(true);
  };

  useEffect(() => {
    getProjectList({ pageNumber: page, testsPerPage: pageSize });
    getMapList({ pageNumber: page, testsPerPage: pageSize });
    fetchVehicles();
    localStorage.removeItem("p_id");
    localStorage.removeItem("app_id");
    localStorage.removeItem("map_id");
    localStorage.removeItem("pralion_id");
  }, [page]);

  const handleOpen = () => {
    setOpen(true);
    setEdited(false);
  };

  const handleClose = () => setOpen(false);

  const getProjectList = async (pagination) => {
    setFetching(true);
    const projectId = parseInt(param.id);
    const response = await getAutoScenarioProjectsById(projectId, pagination);
    if (response?.status === 200) {
      setFetching(false);
      const { data } = response?.data;
      setList(data.applications);
      setTotalRows(data.no_of_applications);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setList([]);
      setFetching(false);
    }
  };

  const goto = (data) => {
    SetInfoToLocalStorage("p_id", param.id);
    localStorage.setItem("app_id", data.id);
    ReduxCreateAutoScenarioJourney(data.pralion_id, data.name, data.application_version_id ? true : false);
    navigator(AUTHENTICATED_ROUTES.autoMapList.replace(":id", param.id).replace(":aid", data.pralion_id));
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

const fetchVehicles = async () => {
  setLoadingVehicles(true);

  try {
    const response = await getAllTestVehicleProjects(parseInt(param.id));

    if (response?.status === 200) {
      if (response?.data?.data?.test_vehicles.length > 0) {
        const hasVehicleId = response.data.data.test_vehicles.some(
          (vehicle) => vehicle.application_version_id !== null
        );

        if (hasVehicleId) {
          setVehiclesExistFlag(true);
        } else {
          setVehiclesExistFlag(false);
        }
      } else {
        setVehiclesExistFlag(false);
      }
    } else {
      setVehiclesExistFlag(false);
    }
  } catch (err) {
    console.log("Error fetching vehicles:", err);
    setVehiclesExistFlag(false);
  } finally {
    setLoadingVehicles(false);
  }
};

const getMapList = async (pagination) => {
  setLoadingMaps(true);

  const projectId = parseInt(param.id);
  const response = await getMapProjectsById(projectId, pagination);

  if (response?.status === 200) {
    if (response?.data?.data?.applications.length > 0) {

      const hasVersionId = response.data.data.applications.some(
        (application) => application.application_version_id !== null
      );

      if (hasVersionId) {
        setMapsExistFlag(true); 
      } else {
        setMapsExistFlag(false); 
      }
    } else {
      setMapsExistFlag(false);
    }
  } else {
    setMapsExistFlag(false);
  }
  setLoadingMaps(false);
};

  const handleSummary = (data) => {
    setEdited(true);
    setOpen(true);
    setSummaryData(data);
  };

  const columns = useMemo(
    () => [
      {
        field: "name",
        renderHeader: () => <span className="heading-2">{"Scenario ID "}</span>,
        width: 550,
        renderCell: (params) => (
          <Grid>
            <Button
              className="Main-link-simple row-heading"
              onClick={() => goto(params.row)}
              sx={{ color: "white" }}
            >
              {wrapText(params.value)}
            </Button>
          </Grid>
        ),
      },
      {
        field: "date_created",
        renderHeader: () => <span className="heading-2">{"Created At"}</span>,
        width: 280,
        renderCell: (params) => (
          <span className="row-heading">{dateConverter(params.value)}</span>
        ),
      },
      {
        field: "action",
        renderHeader: () => <span className="heading-2">{"Status"}</span>,
        width: 235,
        renderCell: (params) => {
          const status = params.row.dag_state.dag_state;
          return (
            <>
              <div className="scenarioStatusRadioButton">
                <Radio
                  checked={
                    status === "pending" || !params.row.dag_state.dag_state
                  }
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "gray",
                      color: "red",
                      "&:checked + span": {
                        backgroundColor: "gray",
                        color: "gray",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
                <Radio
                  checked={
                    status === "queued" || !params.row.dag_state.dag_state
                  }
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "#ffff00",
                      color: "#ffff00",
                      "&:checked + span": {
                        backgroundColor: "#ffff00",
                        color: "#ffff00",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
                <Radio
                  checked={status === "running"}
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "#ff9800",
                      color: "#ff9800",
                      "&:checked + span": {
                        backgroundColor: "#ff9800",
                        color: "#ff9800",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
                <Radio
                  color="success"
                  checked={status === "success"}
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "#4caf50",
                      color: "#4caf50",
                      "&:checked + span": {
                        backgroundColor: "#4caf50",
                        color: "#4caf50",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
                <Radio
                  checked={status === "failed"}
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "red",
                      color: "red",
                      "&:checked + span": {
                        backgroundColor: "red",
                        color: "red",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
              </div>

              {/* <Button
              variant="contained"
              sx={{
                backgroundColor: "secondary.dark",
                textTransform: "none",
                borderRadius: "8px",
              }}
              disabled
            >
              Stop
            </Button> */}
              <Button
                sx={{ color: "text.main" }}
                onClick={() => handleStatusOpenModal(params.row)}
              >
                <MoreVertIcon />
              </Button>
            </>
          );
        },
      },
      {
        renderHeader: () => <span className="heading-2">{"Summary"}</span>,
        width: 180,
        renderCell: (params) => (
          <Grid
            onClick={() => handleSummary(params.row)}
            sx={{ cursor: "pointer",marginLeft:'2em' }}
          >
            <AirplayIcon />
          </Grid>
        ),
      },
    ],
    [list, page]
  );

  return (
    <Fragment>
 <ModalX
  open={open}
  close={handleClose}
  heading={!edited ? "Create New AutoScenario" : `${summaryData.name} Summary`}
  subheading={
    !edited
      ? "Enter AutoScenario details to continue"
      : "AutoScenario application details"
  }
>
  {loadingMaps || loadingVehicles ? (
    <>
      <ProgressX />
    </>
  ) : MapsExistFlag && vehiclesExistFlag ? (
    <>
      <AutoScenarioForm
        syncList={getProjectList}
        handleClose={handleClose}
        summaryData={summaryData}
        isEdit={edited}
      />
    </>
  ) : !MapsExistFlag ? (
    <>
      <Typos.Body1>
        No Maps found to create a scenario. Please go and create a{" "}
        <Link to={`/projects/${param.id}/applications/maps`}>
          Map
        </Link>
        .
      </Typos.Body1>
    </>
  ) : (
    <>
      <Typos.Body1>
        No vehicles found to create a scenario. Please go and create a{" "}
        <Link to={`/projects/${param.id}/applications/test-vehicles`}>
          test vehicle
        </Link>
        .
      </Typos.Body1>
    </>
  )}
</ModalX>

      <ModalX
        open={underConstructionModal}
        close={() => setUnderConstructionModal(false)}
        heading={"AutoScenario"}
        subheading={"Service Under Maintenance "}
      >
        <Box display="flex" flexDirection="row" alignItems="center" p={2}>
          <Box flex="1" p={2}>
            <div>
              <p>
                We apologize for any inconvenience, this application is
                currently under maintenance. Only limited functionality is
                available where you can perform the application journey but
                would not be able to execute or generate scenarios.
              </p>
            </div>
          </Box>
          <Box
            flex="1"
            p={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={underConstruction}
              alt="Under Construction"
              style={{ maxWidth: "80%", maxHeight: "100%" }}
            />
          </Box>
        </Box>
      </ModalX>

      <ModalX
        open={statusOpen}
        close={() => setStatusOpen(false)}
        heading={"State Management"}
        subheading={`${statusData.name}`}
      >
        <AutoScenarioStatus
          statusObject={statusData}
          handleClose={handleClose}
        />
      </ModalX>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        mb={2}
      >
        <ButtonX onClick={handleOpen}>New AutoScenario</ButtonX>
      </Box>
      <AutoScenarioTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />
    </Fragment>
  );
};

export default connect(null, { ReduxCreateAutoScenarioJourney })(AutoScenarios);
