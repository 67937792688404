import React, { useState } from "react";
import { Button, CardContent, Grid, TextField, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { specialValue } from "utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTestSuits } from "endpointServices/testSuitServices";

export default function TestSuitsForm({ handleClose, syncList }) {
  const param = useParams();
  const [state, setState] = useState({
    name: "",
    description: "",
    criteria: "",
  });
  const [loading, setLoading] = useState(false);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      if (!specialValue.test(value)) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      project_id: parseInt(param.id),
      name: state.name,
      description: state.description,
      criteria: state.criteria,
    };
    console.log("payload", payload);
    const response = await createTestSuits(payload);
    console.log("Test Suite response", response);
    if (response?.status === 200) {
      const { data } = response;
      console.log("data", data);
      toast.success(`Test Suite Created`);
      handleClose();
      setLoading(false);
      syncList();
    } else {
      const errorResponse = response;
      const err = errorResponse?.message || "Failed to submit request";
      if (errorResponse?.response.request.status === 400) {
        toast.error(errorResponse.response.data.message);
      } else {
        toast.error(`${err}. Please try again later`);
      }
      console.log("error", errorResponse);
      setLoading(false);
    }
  };

  return (
    <Box>
      <CardContent>
        <form onSubmit={onSubmitLogin}>
          <Grid item xs={6}>
            <TextField
              name="name"
              required
              type="text"
              variant="outlined"
              label="Test Suite ID"
              placeholder="Enter suite id"
              inputProps={{ maxLength: 16 }}
              value={state.name}
              onChange={handleInputs}
              fullWidth
              helperText="Enter alphanumeric characters and hyphens only & max length 16 characters"
            />
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="description"
              type="text"
              variant="outlined"
              label="Test Suite Description"
              placeholder="Write suite description"
              value={state.description}
              onChange={handleInputs}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="criteria"
              type="text"
              variant="outlined"
              label="Pass/Fail Criteria"
              placeholder="Enter criteria"
              // inputProps={{ maxLength: 40 }}
              value={state.criteria}
              onChange={handleInputs}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          <br />
          <div className={`d-flex justify-content-end w-100`}>
            <Button
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                color: "text.main",
                textTransform: "none",
                fontSize: "13px",
              }}
              disabled={loading}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </CardContent>
    </Box>
  );
}
