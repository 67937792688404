import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  TextField,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { GetInfoFromLocalStorage, mapAttributeDropdown, rilMapList } from "utils";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonX } from "components";
import { getEducAgentByPralionId } from "endpointServices/educAgentServices";
import { ReduxUpdateEducAgentsJourney } from "services/redux-store/actions/actions-educAgents";
import { connect } from "react-redux";
import STORE from "services/redux-store/store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MapParameter = ({ ReduxUpdateEducAgentsJourney, getMapValues, getLaneValues, mapDescription, isLoading }) => {
  const param = useParams();
  const navigate = useNavigate();
  const { EducAgents } = STORE.getState();

  const mapId = GetInfoFromLocalStorage("map_id");

  const [isDisable, setIsDisabled] = useState(true);

  const [project, setProject] = useState({});
  const [checked, setChecked] = useState(false);
  const [mapParameterList, setMapParameterList] = useState([]);
  const [mapEgoMenu, setMapEgoMenu] = useState("");



  const handleMapEgoMenuChange = (e) => {
    const { value } = e.target;
    console.log('value', value)
    setMapEgoMenu(value);
    getLaneValues(value)
    ReduxUpdateEducAgentsJourney({
      mapParameterLocation: value === 0 ? [] : value,
    });
  };

  useEffect(() => {
    getStoreValues();
  }, [EducAgents.mapParameter]);

  useEffect(() => {
    if (EducAgents.mapParameterLocation?.length === 0) {
      setMapEgoMenu(0);
    } else {
      setMapEgoMenu(EducAgents.mapParameterLocation);
    }
  }, [EducAgents.mapParameterLocation]);

  useEffect(() => {
    setIsDisabled(mapParameterList.length === 0);
  }, [mapParameterList]);

  const getStoreValues = () => {
    if (
      EducAgents.mapParameter?.features !== undefined &&
      EducAgents.mapParameter?.features !== null &&
      EducAgents.mapParameter?.features.length > 0
    ) {
      setMapParameterList(EducAgents.mapParameter?.features);
    }
  };

  const handleCheckBoxChange = (item) => {
    const updatedChecked = [...mapParameterList];
    const itemIndex = mapParameterList.findIndex((obj) => obj.json_representation === item.json_representation);
    if (itemIndex !== -1) {
      updatedChecked.splice(itemIndex, 1);
    } else {
      updatedChecked.push({
        id: item.id,
        json_representation: item.json_representation,
      });
    }
    setMapParameterList(updatedChecked);
    ReduxUpdateEducAgentsJourney({
      mapParameter: {
        features: updatedChecked,
      },
    });
    getMapValues(updatedChecked);
  };

  useEffect(() => {
    getProject();
  }, [checked]);

  const getProject = async () => {
    const pralionId = parseInt(param.id);
    const response = await getEducAgentByPralionId(pralionId);
    console.log("response", response);
    if (response?.status === 200) {
      const { data } = response.data;
      setProject(data);
    } else {
      setProject([]);
    }
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            sx={{
              backgroundColor: "secondary.dark",
              paddingBottom: "12px",
              borderRadius: "8px",
            }}
          >
            <Grid container justifyContent={"space-between"} p={1}>
              <Grid item sx={{ width: "25rem" }}>
                <TextField
                  label="Select lanes to consider around ego"
                  name="selectCategory"
                  type="select"
                  size="small"
                  variant="outlined"
                  value={mapEgoMenu}
                  onChange={handleMapEgoMenuChange}
                  fullWidth
                  select
                  SelectProps={{
                    MenuProps: {
                      className: "customDropdown",
                    },
                  }}
                >
                  {mapAttributeDropdown.map((item,idx) => {
                    return (
                      <MenuItem key={idx} value={item.value}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid item>
                {/* <ButtonX onClick={onClickNext} disabled={isDisable}>
                  Save
                </ButtonX> */}
              </Grid>
            </Grid>

            <Grid className="vehicle-details-table-data" sx={{ height: "23.2rem" }}>
            {isLoading ? 
                <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                  <CircularProgress size={40} />
                </div>
               : 
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Selection</TableCell>
                      <TableCell>Feature</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {mapDescription.map((item) => {
                      return (
                        <TableRow key={item.feature}>
                          <TableCell>
                            <Checkbox
                              checked={mapParameterList.some(
                                (value) => value.json_representation === item.json_representation
                              ) && mapEgoMenu !== 0
                            }
                              onChange={() => handleCheckBoxChange(item)}
                              disabled={mapEgoMenu === 0}
                            />
                          </TableCell>
                          <TableCell>{item.feature}</TableCell>
                          <TableCell>{item.type}</TableCell>
                          <TableCell>{item.description}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}></Grid>
      </Grid>
    </Fragment>
  );
};
export default connect(null, { ReduxUpdateEducAgentsJourney })(MapParameter);
