import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import DownloadIcon from '@mui/icons-material/Download';
import { CO_SIM_CLIENT_BUILDS, VERSATILE_BUILDS } from 'constants/endpoints';

export const specialValue = /[!@#$%^&*()`_+\={};':"\\|,.<>?/~\s]+/;

// Theme
export const ThemesOptions = [
  { label: "Dark", value: "dark" },
  { label: "Light", value: "light" },
];

// Location
export const LocationOptions = [
  { label: "Germany", value: "germany" },
  { label: "Islamabad", value: "islamabad" },
];

// Language
export const LanguageOptions = [
  { label: "US", value: "english" },
  { label: "German", value: "german" },
  { label: "Japanese", value: "japanese" },
  { label: "Korean", value: "korean" },
];

// TimeZone
export const TimeZoneOptions = [
  { label: "Germany", value: "germany" },
  { label: "Pakistan", value: "pakistan" },
];

// United System
export const UnitedSystemOptions = [
  { label: "System One", value: "system one" },
  { label: "System Two", value: "system two" },
];

// CoSIM-DSpace & CoSIM-CarMaker Form
// Select Category
export const Category = [
  {
    label: "Category 1",
    value: "Category 1",
  },
];

// Select AutoScenarios Category
export const AutoScenariosCategory = [
  {
    label: "Category 1",
    value: "Category 1",
  },
];

// Select Maps Category
export const MapsCategory = [
  {
    label: "Category 1",
    value: "Category 1",
  },
];

// Select ScenAnalytics Category
export const ScenAnalyticsCategory = [
  {
    label: "Category 1",
    value: "Category 1",
  },
];

// Select ScenAnalyticsACC Category
export const ScenAnalyticsACCCategory = [
  {
    label: "ACC",
    value: "ACC",
  },
  {
    label: "LKA",
    value: "LKA",
  },
  {
    label: "AEB",
    value: "AEB",
  },
];

// Select Projects Category
export const ProjectsCategory = [
  {
    label: "Category 1",
    value: "Category 1",
  },
];

// Select Projects Category
export const ProjectsACCCategory = [
  {
    label: "ACC",
    value: "ACC",
  },
  {
    label: "AEB",
    value: "AEB",
  },
  {
    label: "BSM / BLIS",
    value: "BSM / BLIS",
  },
  {
    label: "LDW / LDWS",
    value: "LDW / LDWS",
  },
  {
    label: "LKA / LKAS",
    value: "LKA / LKAS",
  },
  {
    label: "FCW / FCWS",
    value: "FCW / FCWS",
  },
  {
    label: "TSR",
    value: "TSR",
  },
  {
    label: "ISA",
    value: "ISA",
  },
  {
    label: "PA / PASS",
    value: "PA / PASS",
  },
  {
    label: "RVM",
    value: "RVM",
  },
  {
    label: "NV",
    value: "NV",
  },
];

// Select weather Conditions Category
export const weatherConditionsCategory = [
  {
    label: "Clear",
    value: "Clear",
  },
  {
    label: "Rain",
    value: "Rain",
  },
  {
    label: "Snow",
    value: "Snow",
  },
  {
    label: "Hail",
    value: "Hail",
  },
];

// Select Weather Severity Conditions Category
export const weatherSeverityConditionsCategory = [
  {
    label: "Pleasant",
    value: "Pleasant",
  },
  {
    label: "Mild",
    value: "Mild",
  },
  {
    label: "Moderate",
    value: "Moderate",
  },
  {
    label: "Harsh",
    value: "Harsh",
  },
];

// Select ODD Definition Category
export const OddDefinitionCategory = [
  {
    label: "Permisive",
    value: "Permisive",
  },
  {
    label: "Restrictive",
    value: "Restrictive",
  },
  {
    label: "Default",
    value: "Default",
  },
];

// CoSIM-DSpace Details Start
// Select Version
export const Version = [
  {
    label: "Carmaker Version 10.2.1",
    value: "Carmaker Version 10.2.1",
  },
];

// Select Path
export const Path = [
  {
    label: "Project Path 1",
    value: "Project Path 1",
  },
];

// Select Test Run
export const TestRun = [
  {
    label: "Test Run 1",
    value: "Test Run 1",
  },
];

// Select Test Modal
export const Modal = [
  {
    label: "Car Modal 1",
    value: "Car Modal 1",
  },
];
// CoSIM-DSpace Details End

// CoSIM-CarMaker Details Start
// Select Version
export const SelectVersion = [
  {
    label: "Carmaker Version 10.2.1",
    value: "Carmaker Version 10.2.1",
  },
];

// Select Path
export const SelectPath = [
  {
    label: "Project Path 1",
    value: "Project Path 1",
  },
];

// Select Test Run
export const SelectTestRun = [
  {
    label: "Test Run 1",
    value: "Test Run 1",
  },
];

// Select Test Modal
export const SelectModal = [
  {
    label: "Car Modal 1",
    value: "Car Modal 1",
  },
];
// CoSIM-CarMaker Details End

export const TableData = [
  {
    id: 1,
    projectName: "AAI Replica 01",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Intelligent Paramet",
    status: "Start",
  },
  {
    id: 2,
    projectName: "AAI Replica 02",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Synthetic Data Generation",
    status: "Pause",
  },
  {
    id: 3,
    projectName: "AAI Replica 03",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Intelligent Paramet",
    status: "Stop",
  },
];

export const CoSIMDSpaceTableData = [
  {
    id: 1,
    projectName: "AAI CoSIM DSpace 01",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Intelligent Paramet",
    status: "Start",
  },
  {
    id: 2,
    projectName: "AAI CoSIM DSpace 02",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Synthetic Data Generation",
    status: "Pause",
  },
  {
    id: 3,
    projectName: "AAI CoSIM DSpace 03",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Intelligent Paramet",
    status: "Stop",
  },
];

export const LowFidelityTableData = [
  {
    id: 1,
    name: "AAI LowFidelity 01",
    description: "LowFidelity data discription",
    date_created: "April 6, 2021",
  },
];

export const DownloadTableData = [
  {
    id: 1,
    name: "Versatile",
    last_updated_version: "Replicar 001",
    date: "12-2-2023",
    disabled: false,
  },
  {
    id: 5,
    name: "Visual Templates",
    last_updated_version: "Replicar 001",
    date: "12-2-2023",
    disabled: false,
  },
  {
    id: 2,
    name: "dSPACE Co-SIM",
    last_updated_version: "Replicar 001",
    date: "12-2-2023",
    disabled: false,
  },
  {
    id: 6,
    name: "Carla Co-SIM",
    last_updated_version: "Replicar 001",
    date: "12-2-2023",
    disabled: false,
  },
  {
    id: 3,
    name: "Carmaker Co-SIM",
    last_updated_version: "Replicar 001",
    date: "12-2-2023",
    disabled: true,
  },
  {
    id: 4,
    name: "TruckMaker C0-SIM",
    last_updated_version: "Replicar 001",
    date: "12-2-2023",
    disabled: true,
  },
 
];
export const SimulationTableData = [
  {
    id: 1,
    name: "Simulation",
    last_updated_version: "Replicar 001",
    date: "12-2-2023",
    disabled: false,
  },
 
];

export const OddZoneTableData = [
  {
    id: 1,
    name: "Odd Zone",
    description: "Odd Zone description",
    mode: "Restrict",
    date_created: "12-2-2023",
  },
 
];

export const VersatileDownloadTableData = [
  {
    id: 1,
    name: <a style={{ textDecoration: "none", color: "inherit" }}
      href={VERSATILE_BUILDS.windows}
    >
      
     <DownloadIcon /> Windows
    </a>,
    last_updated_version: VERSATILE_BUILDS.version,
    date: "12-2-2023",
  },
  {
    id: 2,
    name: <a style={{ textDecoration: "none", color: "inherit" }}
      href={VERSATILE_BUILDS.linux}
    >
      <DownloadIcon /> Linux
    </a>,
    last_updated_version: VERSATILE_BUILDS.version,
    date: "12-2-2023",
  },
];

export const VisualizerTempDownloadTableData = [
  {
    id: 1,
    name: <a style={{ textDecoration: "none", color: "inherit" }}
      href="https://temp-replicar-assets.s3.eu-central-1.amazonaws.com/downloads/v_5_3_2/visualizer_layouts.zip"
    >
     <DownloadIcon /> Windows/Linux
    </a>,
    last_updated_version: "v1.0",
    date: "12-2-2023",
  },
  
];

export const DspaceTempDownloadTableData = [
  {
    id: 1,
    name: <a style={{ textDecoration: "none", color: "inherit" }}
      href="https://temp-replicar-assets.s3.eu-central-1.amazonaws.com/downloads/v_5_0_2/traffic.fmu"
    >
     <DownloadIcon />AAI Intelligent Traffic - Linux FMU
    </a>,
    // name:"AAI Intelligent Traffic",
    last_updated_version: "v7.1.6",
    date: "12-2-2023",
  },
  {
    id: 2,
    name: <a style={{ textDecoration: "none", color: "inherit" }}
      href="https://temp-replicar-assets.s3.eu-central-1.amazonaws.com/downloads/v_5_0_2/RR_Worker_v5.0.2.zip"
    >
     <DownloadIcon />RR Worker - Linux
    </a>,
    // name:"AAI Intelligent Traffic",
    last_updated_version: "v.5.0.2",
    date: "12-2-2023",
  },
  {
    id: 3,
    name: <a style={{ textDecoration: "none", color: "inherit" }}
      href="https://temp-replicar-assets.s3.eu-central-1.amazonaws.com/downloads/v_5_0_2/dSPACE-cosim-client_v.0.1.zip"
    >
     <DownloadIcon />dSPACE Co-Sim Client - Windows
    </a>,
    // name:"AAI Intelligent Traffic",
    last_updated_version: "v0.1",
    date: "12-2-2023",
  },
  
];

/**
 * SIMULATION_CLIENT_BUILD List
 */
export const SIMULATION_CLIENT_BUILD = [
	{
		title: 'Windows',
		icons: <DownloadIcon />,
		// url: '',
		isDisabled: true,
	},
	{
		title: 'Linux',
		icons: <DownloadIcon />,
		url: CO_SIM_CLIENT_BUILDS.linux,
		isDisabled: false,
	},
]



export const ScenarioExecutionListTableData = [
  {
    id: 1,
    name: "ScenarioExecutionList 01",
    description: "David Dears",
    date_created: "April 6, 2021",
    status: "Start",
  },
  {
    id: 2,
    name: "ScenarioExecutionList 02",
    description: "David Dears",
    date_created: "April 6, 2021",
    status: "Pause",
  },
];

export const AutoExecutionListTableData = [
  {
    id: 1,
    name: "AutoExecutionList 01",
    description: "David Dears",
    date_created: "April 6, 2021",
  },
  {
    id: 2,
    name: "AutoExecutionList 02",
    description: "David Dears",
    date_created: "April 6, 2021",
  },
];

export const SynthDataTableData = [
  {
    id: 1,
    name: "AAI SynthData 01",
    description: "This is SynthData Description",
    date_created: "April 6, 2021",
  },
];

export const RealSimTableData = [
  {
    id: 1,
    name: "AAI RealSim 01",
    description: "This is RealSim Description",
    date_created: "April 6, 2021",
  },
];

export const SenVironmentTableData = [
  {
    id: 1,
    name: "AAI SenVironment 01",
    description: "This is SenVironment Description",
    date_created: "April 6, 2021",
  },
];

export const ScenExtractTableData = [
  {
    id: 1,
    name: "AAI ScenExtract 01",
    description: "This is ScenExtract Description",
    date_created: "April 6, 2021",
  },
];

export const EducAgentsTableData = [
  {
    id: 1,
    name: "AAI EducAgents 01",
    description: "This is EducAgents Description",
    date_created: "April 6, 2021",
  },
];

export const CoSIMCarMakerTableData = [
  {
    id: 1,
    projectName: "AAI CoSIM Car Maker 01",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Intelligent Paramet",
    status: "Start",
  },
  {
    id: 2,
    projectName: "AAI CoSIM Car Maker 02",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Synthetic Data Generation",
    status: "Pause",
  },
  {
    id: 3,
    projectName: "AAI CoSIM Car Maker 03",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Intelligent Paramet",
    status: "Stop",
  },
];

export const CoSimulationTableData = [
  {
    id: 1,
    projectName: "IPG CarMaker",
    userName: "Project-1",
    projectCreationDate: "April 6, 2021",
    disabled: true,
  },
  {
    id: 2,
    projectName: "dSPACE ModelDESK",
    userName: "Project-2",
    projectCreationDate: "April 6, 2021",
    disabled: false,
  },
  {
    id: 3,
    projectName: "IPG TruckMaker",
    userName: "Project-3",
    projectCreationDate: "April 6, 2021",
    disabled: true,
  },
];

export const CoSimulationDetailsTableData = [
  {
    id: 1,
    projectName: "IPC CarMaker",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Intelligent Paramet",
    status: "Start",
  },
  {
    id: 2,
    projectName: "dSPACE Aurolon",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Synthetic Data Generation",
    status: "Pause",
  },
  {
    id: 3,
    projectName: "IPC TruckMaker",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Intelligent Paramet",
    status: "Stop",
  },
];

export const ScenarioTableData = [
  {
    id: 1,
    projectName: "User Defined Scenario 01",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Intelligent Paramet",
    status: "Start",
  },
  {
    id: 2,
    projectName: "User Defined Scenario 02",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Synthetic Data Generation",
    status: "Pause",
  },
  {
    id: 3,
    projectName: "User Defined Scenario 03",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Intelligent Paramet",
    status: "Stop",
  },
];

export const VehicleTableData = [
  {
    id: 1,
    projectName: "Vehicle 001",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Intelligent Paramet",
    status: "Start",
  },
  {
    id: 2,
    projectName: "Vehicle 002",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Synthetic Data Generation",
    status: "Pause",
  },
  {
    id: 3,
    projectName: "Vehicle 003",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Intelligent Paramet",
    status: "Stop",
  },
];

export const AutoScenarioTableData = [
  {
    id: 1,
    projectName: "AAI Auto Scenario 01",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Intelligent Paramet",
    status: "Start",
  },
  {
    id: 2,
    projectName: "AAI Auto Scenario 02",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Synthetic Data Generation",
    status: "Pause",
  },
  {
    id: 3,
    projectName: "AAI Auto Scenario 03",
    userName: "David Dears",
    projectCreationDate: "April 6, 2021",
    action: "Intelligent Paramet",
    status: "Stop",
  },
];

export const agents = [
  { id: 0, key: 'r1', icon: <DirectionsCarIcon style={{fontSize: "5rem", }} /> },
  { id: 1, key: 'r2', icon: <DirectionsCarIcon style={{fontSize: "5rem", }} />  },
  { id: 2, key: 'r3', icon: <DirectionsCarIcon style={{fontSize: "5rem", }} />  },
  { id: 3, key: 'r4', icon: <DirectionsCarIcon style={{fontSize: "5rem", }} />  },
  { id: 4, key: 'l1', icon: <DirectionsCarIcon style={{fontSize: "5rem", }} />  },
  { id: 5, key: 'l2', icon: <DirectionsCarIcon style={{fontSize: "5rem", }} />  },
  { id: 6, key: 'l3', icon: <DirectionsCarIcon style={{fontSize: "5rem", }} />  },
  { id: 7, key: 'l4', icon: <DirectionsCarIcon style={{fontSize: "5rem", }} />  },
  { id: 8, key: 'b1', icon: <DirectionsCarIcon style={{fontSize: "5rem", }} />  },
  { id: 9, key: 'b2', icon: <DirectionsCarIcon style={{fontSize: "5rem", }} />  },
  { id: 10, key: 'b3', icon:<DirectionsCarIcon style={{fontSize: "5rem", }} />  },
  { id: 11, key: 'b4', icon:<DirectionsCarIcon style={{fontSize: "5rem", }} />  },
  { id: 12, key: 'c1', icon:<DirectionsCarIcon style={{fontSize: "5rem", }} />  },
  { id: 13, key: 'c2', icon:<DirectionsCarIcon style={{fontSize: "5rem", }} />  },
  { id: 14, key: 'c3', icon:<DirectionsCarIcon style={{fontSize: "5rem", }} />  },

]

export const agentsParameters = [
  { id: 0, key: "l2-front", disable: false },
  { id: 1, key: "l1-front", disable: false },
  { id: 2, key: "c-front", disable: false },
  { id: 3, key: "r1-front", disable: false },
  { id: 4, key: "r2-front", disable: false },
  { id: 5, key: "l2-beside", disable: false },
  { id: 6, key: "l1-beside", disable: false },
  { id: 7, key: "ego", disable: true },
  { id: 8, key: "r1-beside", disable: false },
  { id: 9, key: "r2-beside", disable: false },
  { id: 10, key: "l2-back", disable: false },
  { id: 11, key: "l1-back", disable: false },
  { id: 12, key: "c-back", disable: false },
  { id: 13, key: "r1-back", disable: false },
  { id: 14, key: "r2-back", disable: false }
]

export const hyperParameterTable = [
  { feature: "Make", type: 1200, units: "kilogram" },
  { feature: "Length", type: 4.5, units: "kilogram" },
  { feature: "Width", type: 2, units: "kilogram" },
  { feature: "Height", type: 1.5, units: "kilogram" },
  { feature: "Position X", type: 0, units: "kilogram" },
  { feature: "Position Y", type: 0, units: "kilogram" },
  { feature: "Position Z", type: 20, units: "kilogram" },
  { feature: "Rotation", type: 0, units: "kilogram" },
  { feature: "Rotation Pitch", type: 0, units: "kilogram" },
  { feature: "Rotation Roll", type: 10, units: "kilogram" },
  { feature: "Top Speed", type: 0, units: "kilogram" },
  { feature: "Accelerat", type: 1200, units: "kilogram" },
  { feature: "Type", type: 0, units: "kilogram" },
  { feature: "Model", type: 10, units: "kilogram" },
  { feature: "CDA", type: 2, units: "kilogram" },
];

export const replicaSimRDetailsTable = [
  { parameter: "Prefab Name", defaultValue: 'Test_Vehicle_2023', units: "--" },
  { parameter: "Display Name", defaultValue: 'Test Vehicle', units: "--" },
  { parameter: "Model", defaultValue: 'Vehicle', units: "--" },
  { parameter: "Make", defaultValue: 1200, units: "kilogram" },
  { parameter: "Length", defaultValue: 4.5, units: "kilogram" },
  { parameter: "Width", defaultValue: 2, units: "kilogram" },
  { parameter: "Height", defaultValue: 1.5, units: "kilogram" },
  // { parameter: "Position X", defaultValue: 0, units: "kilogram" },
  // { parameter: "Position Y", defaultValue: 0, units: "kilogram" },
  // { parameter: "Position Z", defaultValue: 20, units: "kilogram" },
  // { parameter: "Rotation", defaultValue: 0, units: "kilogram" },
  // { parameter: "Rotation Pitch", defaultValue: 0, units: "kilogram" },
  // { parameter: "Rotation Roll", defaultValue: 10, units: "kilogram" },
  // { parameter: "Top Speed", defaultValue: 0, units: "kilogram" },
  // { parameter: "Accelerat", defaultValue: 1200, units: "kilogram" },
  // { parameter: "Type", defaultValue: 0, units: "kilogram" },
  // { parameter: "Model", defaultValue: 10, units: "kilogram" },
  // { parameter: "CDA", defaultValue: 2, units: "kilogram" },
];

export const coSimDSpaceDetailsTable = [
  { parameter: "Mass", defaultValue: 1200, units: "kilogram" },
  { parameter: "Length", defaultValue: 4.5, units: "kilogram" },
  { parameter: "Width", defaultValue: 2, units: "kilogram" },
  { parameter: "Height", defaultValue: 1.5, units: "kilogram" },
  { parameter: "Position X", defaultValue: 0, units: "kilogram" },
  { parameter: "Position Y", defaultValue: 0, units: "kilogram" },
  { parameter: "Position Z", defaultValue: 20, units: "kilogram" },
  { parameter: "Rotation", defaultValue: 0, units: "kilogram" },
  { parameter: "Rotation Pitch", defaultValue: 0, units: "kilogram" },
  { parameter: "Rotation Roll", defaultValue: 10, units: "kilogram" },
  { parameter: "Speed", defaultValue: 0, units: "kilogram" },
  { parameter: "Accelerat", defaultValue: 1200, units: "kilogram" },
  { parameter: "Mass", defaultValue: 0, units: "kilogram" },
  { parameter: "Mass", defaultValue: 10, units: "kilogram" },
  { parameter: "Mass", defaultValue: 2, units: "kilogram" },
];

export const coSimCarMakerDetailsTable = [
  { parameter: "Mass", defaultValue: 1200, units: "kilogram" },
  { parameter: "Length", defaultValue: 4.5, units: "kilogram" },
  { parameter: "Width", defaultValue: 2, units: "kilogram" },
  { parameter: "Height", defaultValue: 1.5, units: "kilogram" },
  { parameter: "Position X", defaultValue: 0, units: "kilogram" },
  { parameter: "Position Y", defaultValue: 0, units: "kilogram" },
  { parameter: "Position Z", defaultValue: 20, units: "kilogram" },
  { parameter: "Rotation", defaultValue: 0, units: "kilogram" },
  { parameter: "Rotation Pitch", defaultValue: 0, units: "kilogram" },
  { parameter: "Rotation Roll", defaultValue: 10, units: "kilogram" },
  { parameter: "Speed", defaultValue: 0, units: "kilogram" },
  { parameter: "Accelerat", defaultValue: 1200, units: "kilogram" },
  { parameter: "Mass", defaultValue: 0, units: "kilogram" },
  { parameter: "Mass", defaultValue: 10, units: "kilogram" },
  { parameter: "Mass", defaultValue: 2, units: "kilogram" },
];

export const autoScenarioExecuteTable = [
  { parameter: "Speed", fra: 0, ta: "20" },
  { parameter: "Step Size", fra: 0, ta: "0" },
  { parameter: "Speed", fra: 10, ta: "meters/second" },
  { parameter: "Step Size", fra: 0, ta: "20" },
  { parameter: "Speed", fra: 20, ta: "0" },
  { parameter: "Step Size", fra: 0, ta: "meters/second" },
  { parameter: "Speed", fra: 30, ta: "20" },
  { parameter: "-", fra: "-", ta: "-" },
  { parameter: "-", fra: "-", ta: "-" },
  { parameter: "-", fra: "-", ta: "-" },
  { parameter: "-", fra: "-", ta: "-" },
  { parameter: "-", fra: "-", ta: "-" },
  { parameter: "-", fra: "-", ta: "-" },
  { parameter: "-", fra: "-", ta: "-" },
];

export const codePresets = [
  {
    name: 'Test_Case_1',
    code:'from ObserverLib.util import *\n\n    def helper():\n…_output_file():\n        raise NotImplementedError'
  },
  {
    name: 'Test_Case_2',
    code:"from ObserverLib.util import *\n\n    def helper():\n        raise NotImplementedError\n\n    def process_frame(OSI_frame_data):\n        time_to_collision()\n        is_ego_in_shoulder_lane()\n\n    def write_output_file():\n        raise NotImplementedError"
  },
  {
    name: 'Test_Case_3',
    code:"myfunction(x, y):\n\n return x + y \n\n else:\n\n  print('Hello!')\n\n  if mark >= 50\n\n  print('You passed!')\n\n  if arriving:\n\n  print('Hi!')\n\n  esle:\n\n  print('Bye!')\n\n if flag:\n\n print('Flag is set!')"
  }
]


export const mapTypes = {
    streetViewWithOdds: "mapbox://styles/intakhabkhan/clluzi0hf00d001nzffizfrjx",
    streetView: "mapbox://styles/intakhabkhan/clikoeo8o00co01qpdpe9euh9",
    satelliteView: "mapbox://styles/mapbox/satellite-v9",
    lightView: "mapbox://styles/mapbox/light-v11",
    darkView: "mapbox://styles/mapbox/dark-v11",
}

export const rilEgoList = 
    [
        {
            "id": 1,
            "feature": "Speed",
            "description": "Speed of ego Vehicle",
            "type": "double",
            "json_representation": "speed",
            "valid_values": "[0, + infinity]",
            "units": "meters per second",
            "isTrue":true
        },
        {
            "id": 2,
            "feature": "Light Mask",
            "description": "Light mask value indicating on lights",
            "type": "int",
            "json_representation": "light_mask",
            "valid_values": "8 bit Mask [LightMask]",
            "units": "unitless",
            "isTrue":true
        },
        {
            "id": 3,
            "feature": "Yaw",
            "description": "Yaw of ego vehicle",
            "type": "double",
            "json_representation": "yaw",
            "valid_values": "[-infinity, + infinity]",
            "units": "radians",
            "isTrue":true
        },
        {
            "id": 4,
            "feature": "Lane Center Distance",
            "description": "ego’s distance from lane center",
            "type": "double",
            "json_representation": "lane_center_distance",
            "valid_values": "[-infinity, + infinity]",
            "units": "meters",
            "isTrue":true
        },
        {
            "id": 5,
            "feature": "Vehicle Type",
            "description": "Type of ego vehicle",
            "type": "int",
            "json_representation": "vehicle_type",
            "valid_values": "Enum Class [RoadUserType]",
            "units": "unitless",
            "isTrue":false
        },
        {
            "id": 6,
            "feature": "Acceleration",
            "description": "Acceleration of ego",
            "type": "double",
            "json_representation": "acceleration",
            "valid_values": "[-infinity, infinity]",
            "units": "meters / (second * second)",
            "isTrue":false
        },
        {
            "id": 7,
            "feature": "Lane Type",
            "description": "Lane type of ego ",
            "type": "int",
            "json_representation": "lane_type",
            "valid_values": "Enum Class [LaneType]",
            "units": "unitless",
            "isTrue":false
        },
        {
            "id": 8,
            "feature": "Target Speed",
            "description": "Target speed of ego",
            "type": "double",
            "json_representation": "target_speed",
            "valid_values": "[0, + infinity]",
            "units": "meters / second",
            "isTrue":false
        },
        {
            "id": 9,
            "feature": "Lane Idx",
            "description": "Lane idx starting from slowing lane",
            "type": "int",
            "json_representation": "lane_idx",
            "valid_values": "[0, + infinity]",
            "units": "unitless",
            "isTrue":false
        }
    ]


    export const rilMapList = 
    [
        {
            "id": 1,
            "feature": "Lane Type",
            "description": "Type of Lane for Ego",
            "type": "double",
            "json_representation": "lane_type",
            "valid_values": "[0, + infinity]",
            "units": "unitless",
            "isTrue":true
        },
        {
            "id": 2,
            "feature": "Speed Limit",
            "description": "Speed Limit",
            "type": "int",
            "json_representation": "speed_limit",
            "valid_values": "8 bit Mask [LightMask]",
            "units": "meters / second",
            "isTrue":true
        },
        {
            "id": 3,
            "feature": "Lane End Distance",
            "description": "Distance to lane end",
            "type": "double",
            "json_representation": "lane_end_distance",
            "valid_values": "[-infinity, + infinity]",
            "units": "meters / second",
            "isTrue":true
        },
        {
            "id": 4,
            "feature": "Lane Valid",
            "description": "Lane exists or not",
            "type": "double",
            "json_representation": "lane_center_distance",
            "valid_values": "[-infinity, + infinity]",
            "units": "meters / second",
            "isTrue":true
        },
        {
            "id": 5,
            "feature": "Lane Rule",
            "description": "Rule allows to stay in lane",
            "type": "int",
            "json_representation": "lane_rule_abidance",
            "valid_values": "Enum Class [RoadUserType]",
            "units": "unitless",
            "isTrue":false
        },
        {
            "id": 6,
            "feature": "Lane Idx",
            "description": "Lane idx starting from slowing lane",
            "type": "double",
            "json_representation": "lane_idx",
            "valid_values": "[-infinity, infinity]",
            "units": "unitless",
            "isTrue":false
        }
    ]

  export const riLPerceptionList = 
    [
        {
            "id": 1,
            "feature": "Speed",
            "description": "Speed of selected Vehicle",
            "type": "double",
            "json_representation": "speed",
            "valid_values": "[0, + infinity]",
            "units": "meters / second",
            "isTrue": true,
        },
        {
            "id": 2,
            "feature": "Light Mask",
            "description": "Light mask value indicating on lights",
            "type": "int",
            "json_representation": "light_mask",
            "valid_values": "8 bit Mask [LightMask]",
            "units": "unitless",
            "isTrue": true,
        },
        {
            "id": 3,
            "feature": "Relative Distance",
            "description": "Bumper to bumper distance to ego",
            "type": "double",
            "json_representation": "relative_distance",
            "valid_values": "[0, + infinity]",
            "units": "meters",
            "isTrue": true,
        },
        {
            "id": 4,
            "feature": "Relative Speed",
            "description": "Relative speed to ego",
            "type": "double",
            "json_representation": "relative_speed",
            "valid_values": "[-infinity, + infinity]",
            "units": "meters / second",
            "isTrue": true,
        },
        {
            "id": 5,
            "feature": "Yaw",
            "description": "Yaw of selected vehicle",
            "type": "double",
            "json_representation": "yaw",
            "valid_values": "[-infinity, + infinity]",
            "units": "radians",
            "isTrue": false,
        },
        {
            "id": 6,
            "feature": "Lane Center Distance",
            "description": "Distance from lane center",
            "type": "double",
            "json_representation": "lane_center_distance",
            "valid_values": "[-infinity, + infinity]",
            "units": "meters",
            "isTrue": false,
        },
        {
            "id": 7,
            "feature": "Vehicle Type",
            "description": "Type of vehicle",
            "type": "int",
            "json_representation": "vehicle_type",
            "valid_values": "Enum Class [RoadUserType]",
            "units": "unitless",
            "isTrue": false,
        },
        {
            "id": 8,
            "feature": "Lane Type",
            "description": "Lane type of vehicle",
            "type": "int",
            "json_representation": "lane_type",
            "valid_values": "Enum Class [LaneType]",
            "units": "unitless",
            "isTrue": false,
        },
        {
            "id": 9,
            "feature": "Lane Idx",
            "description": "Lane idx starting from slowing lane",
            "type": "int",
            "json_representation": "lane_idx",
            "valid_values": "[0, + infinity]",
            "units": "unitless",
            "isTrue": false,
        }
    ]

export const mapAttributeDropdown = [
  {label:"No Map Attribute", value:0},
  {label:"Ego Lane Attributes", value:['c']},
  {label:"Adjacent Lane Attrinutes (Upto One)", value:['l1','r1','c']},
  {label:"Adjacent Lane Attrinutes (Upto Two)", value:['l2','r2','l1','r1','c']},
 ];
 

export const pageSize = 10

export const vehicleList = [
  {id: 1},
  {id: 2},
  {id: 3},
  {id: 4},
  {id: 5},
  {id: 6},
  {id: 7},
  {id: 8},
  {id: 9},
  {id: 10},
  {id: 55},
  {id: 73},
  {id: 26},
  {id: 28},
]

export const cameraSensorDumpTypes = [
{
		id: 12,
		name: 'None',
	},
	{
		id: 13,
		name: 'RawImage',
	},
	{
		id: 14,
		name: 'OSI',
	},
	{
		id: 15,
		name: 'ROS',
	},
]

export const LIDAR_DEFAULT_OBJECT = [{
	type: 'Lidar',
	monitor: false,
	name: 'Default Lidar',
	pos_x: 0,
	pos_y: 1.24,
	pos_z: 0.95,
	roll: 0,
	pitch: 0,
	yaw: 0,
	sensor_raw_data: {
		fov_x: 360,
		fov_y: -30,
		maxFovY: 10,
		channels: 16,
		accuracy: 0.02,
		sample_frequency: 4000,
		horizontal_frequency: 10,
		radius: 1,
	}
}]

export const GT_DEFAULT_OBJECT = [{
	type: 'GroundTruth',
	monitor: false,
	name: 'GtSensor',
	pos_x: 0,
	pos_y: 1.29,
	pos_z: 0.6,
	roll: 0,
	pitch: 0,
	yaw: 0,
	sensor_raw_data: {
		radius: 120,
	},
}]

export const Static_Properties_Cam_Lidar_GT = {
  "camera":  {
        "name": "Audi_A8_01",
        "selected_camera_presets": [
            947
        ],
        "cameraSensorFields": {
            "name": "AAI Zed2 Camera",
            "dumpType": 12,
            "monitor": false,
            "key": 947,
            "pos_x": 0,
            "pos_y": 0,
            "pos_z": 0,
            "roll": 0,
            "pitch": 0,
            "yaw": 0
        },
        "rendering_type": [
            {
                "camera_name": "AAI Zed2 Camera",
                "raw": true,
                "segmented": false,
                "depth": false,
                "normals": false,
                "save_images": false,
                "key": 947
            }
        ],
        "monitor": false,
        "dump_type_id": 12,
        "sensor_raw_data": {
            "fov_x": 360
        }
    },
   "lidar":[
    {
        "type": "Lidar",
        "monitor": false,
        "name": "Default Lidar",
        "pos_x": 0,
        "pos_y": 1.24,
        "pos_z": 0.95,
        "roll": 0,
        "pitch": 0,
        "yaw": 0,
        "sensor_raw_data": {
            "fov_x": 360,
            "fov_y": -30,
            "maxFovY": 10,
            "channels": 16,
            "accuracy": 0.02,
            "sample_frequency": 4000,
            "horizontal_frequency": 10,
            "radius": 1
        }
    }
],
    "ground_truth":[
      {
          "type": "GroundTruth",
          "monitor": false,
          "name": "GtSensor",
          "pos_x": 0,
          "pos_y": 1.29,
          "pos_z": 0.6,
          "roll": 0,
          "pitch": 0,
          "yaw": 0,
          "sensor_raw_data": {
              "radius": 120
          }
      }
  ]
}

export const hfDownloadFiles = [
  { id: 1, name: "ROS visualization bag", type: "MCAP", path: 'ros_visualization_bag' },
  { id: 2, name: "ROS Perception bag", type: "MCAP", path: 'ros2_perception_bag' },
  { id: 3, name: "ROS Planning bag", type: "MCAP", path: 'ros2_planning_bag' },
  { id: 4, name: "CoSim Client Logs", type: "Zip", path: 'log' },
  { id: 6, name: "MultiSensor OSI", type: "OSI3 SensorDataSeries", path: 'generated_osi_url' },
  { id: 5, name: "Map", type: "XODR", path: 'map_url' },
];

export const lfDownloadFiles = [
  { id: 1, name: "ROS visualization bag", type: "MCAP", path: 'ros_visualization_bag' },
  { id: 2, name: "ROS Perception bag", type: "MCAP", path: 'ros2_perception_bag' },
  { id: 3, name: "ROS Planning bag", type: "MCAP", path: 'ros2_planning_bag' },
  { id: 4, name: "CoSim Client Logs", type: "Zip", path: 'log' },
  { id: 6, name: "MultiSensor OSI", type: "OSI3 SensorDataSeries", path: 'generated_osi_url' },
  { id: 5, name: "Map", type: "XODR", path: 'map_url' },
];