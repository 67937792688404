import { SET_CARLA } from "..";

const INITIAL_STATE = {
    scenario: null,
}

export default function ReducerCarla(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_CARLA:
            return action.payload;
        default:
            return state;
    }
}