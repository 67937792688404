import { Button, CardContent, CardHeader, TextField, } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import AuthWrapper from "components/authWrapper";
import { AUTH_LOGIN_SERVICE } from "endpointServices/authServices";
import { SetInfoToLocalStorage } from "utils";
import CryptoJS from "crypto-js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ModalX } from "components";
import { ReduxSetUser } from "services/redux-store/actions";
import { connect } from "react-redux";
import { AUTHENTICATED_ROUTES } from "router/constants";
import LicenseeText from "components/licenseeText";

export default connect(null, { ReduxSetUser })(function Auth({ ReduxSetUser }) {
	const [state, setState] = useState({
		email: "",
		password: "",
	});

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [openModal, setOpenModal] = useState(false);

	const handleInputs = (e) => {
		const { name, value } = e.target;
		setState((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const hashPassword = (password) => {
		const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_KEY);
		const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV);
		const encryptedPassword = CryptoJS.AES.encrypt(password, key, { iv: iv });
		return encryptedPassword.toString();
	};

	const onSubmitLogin = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const payload = {
				username: state.email,
				password: hashPassword(state.password),
			};
			console.log("payload", payload);
			const response = await AUTH_LOGIN_SERVICE(payload);
			if (response?.status === 200) {
				const { data } = response?.data;
				console.log("data", data);
				ReduxSetUser(data);
				toast.success("Successfully logged in");
				SetInfoToLocalStorage("aai_user", data);
	
				setTimeout(() => {
					handleOpenModal();
					// PageRedirectWithWindow('/')
				}, 2000);
			} else {
				const { data } = response;
				console.log("data", data);
				toast.error("Authentication failed");
				console.log("err response", data);
				setLoading(false);
			}
		} catch (error) {
			console.log('error', error);
			toast.error("Network Error. Please check your connection.");
		} finally {
			setLoading(false); 
		}
	};

	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const redirectToDashboard = () => {
		window.location.href = '/projects'
	}


	return (
		<>
			<ModalX
				open={openModal}
				heading={<span style={{ color: "white" }}>End User License Agreement</span>}
				subheading={<span style={{ color: "white" }}> (“EULA”)</span>}
				hideCloseIcon={"close"}
>
				<div className="container-fluid ">
					<LicenseeText />
					<div className={`d-flex justify-content-end mt-4`}>
						<Link className="link">
							<Button
								sx={{
									backgroundColor: "primary.main",
									"&:hover": {
										backgroundColor: "primary.main",
									},
									color: "text.main",
									textTransform: "none",
									fontSize: "13px",
									width: "160px",
								}}
								className="heading-3"
								disabled={false}
								type="submit"
								onClick={redirectToDashboard}
							>
								Accept Policy
							</Button>
						</Link>
					</div>
				</div>
			</ModalX>

			<AuthWrapper>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="light"
				/>
				<CardHeader
					title="Login"
					subheader="Let's start a new journey together, login now."
					titleTypographyProps={{ variant: "h5", color: "text.main" }}
					subheaderTypographyProps={{ variant: "subtitle1", color: "text.main" }}
					sx={{ color: "text.main", padding: "5px" }}
				/>
				<CardContent>
					<form onSubmit={onSubmitLogin}>
						<div>
							<TextField
								name="email"
								required
								type="email"
								variant="outlined"
								label="Email"
								placeholder="Enter your email"
								value={state.email}
								onChange={handleInputs}
								fullWidth
								disabled={loading}
								InputLabelProps={{
									shrink: true,
								}}
								sx={{
									background: "#266798",
									width: "100%",
									"& .MuiInputBase-root": {
										// height: 80
									},
								}}
							/>
						</div>
						<div className="mt-3">
							<TextField
								name="password"
								required
								type="password"
								variant="outlined"
								label="Password"
								placeholder="Enter your password"
								value={state.password}
								onChange={handleInputs}
								fullWidth
								disabled={loading}
							InputLabelProps={{
									shrink: true, 
								}}
								sx={{
									background: "#266798",
									width: "100%",
									"& .MuiInputBase-root": {
										// height: 80
									},
								}}
							/>
						</div>
						{/* <div className="mt-3 d-flex justify-content-end">
              <Typography
                sx={{ color: "text.disabled" }}
                variant="caption"
                display="block"
                gutterBottom
              >
                Forgot your password?{" "}
                <b>
                  {" "}
                  <Link to="/forget-password" className="link auth-text-disabled" >
                    Click here
                  </Link>
                </b>
              </Typography>
            </div> */}

						<div className={`d-flex justify-content-center w-100 mt-3 mb-3`}>
							<Button
								sx={{
									backgroundColor: "primary.main",
									color: "white",
									marginLeft: "auto",
									// width: "431px",
								}}
								disabled={loading}
								type="submit"
							>
								Login
							</Button>
						</div>
						{/* <div className="mt-3 d-flex justify-content-end">
              <Typography
                sx={{ color: "text.disabled", marginLeft: 'auto' }}
                variant="caption"
                display="block"
                gutterBottom
              >
                Don’t have an account?{" "}
                <b>
                  {" "}
                  <Link to="/registration" className="link auth-text-disabled">
                    Create Account
                  </Link>
                </b>
              </Typography>
            </div> */}
					</form>
				</CardContent>
			</AuthWrapper>
		</>
	);
});
