import { ButtonX, CollapseContent, IconButtonX, ModalX, ProgressX, Typos } from "components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { TRAFFIC_CONFIG_INSTRUCTIONS, mapTypes } from "utils";
import "./d-space-traffic-config.css";
import { useSelector } from 'react-redux';
import { httpGet, httpPost } from "utils/httpMethods";
import { GET_DSPACE_TEST_PROJECT, GET_VEHICLE_COMPOSITION_LIST, GET_VEHICLE_LIST } from "constants/endpoints";
import { ReduxUpdateDSpaceJourney, ReduxCreateDSpaceTrafficConfigurations } from "services/redux-store/actions";
import { connect } from "react-redux";
import TextFieldWithEndButton from "components/text-field-with-end-button";
import { AUTHENTICATED_ROUTES } from "router/constants";
import Draggable from "react-draggable";
import { ArrowRightRounded as InstructionItemIcon } from "@mui/icons-material";
import VehicleComposition from "./vehicle-composition";
import DriverProfiles from "./driver-profiles";
import { getEducAgentProjectsById } from "endpointServices/educAgentServices";
import AlgorithmSettings from "./algorithm-settings";

function extractNames(inputData) {
	let result = {};
	for (let key in inputData) {
		if (inputData.hasOwnProperty(key)) {
			result[key] = inputData[key].map((vehicle) => vehicle.name);
		}
	}
	return result;
}

function DSpaceTrafficConfig({ ReduxDSpace, ReduxProjects, ReduxCreateDSpaceTrafficConfigurations, ReduxDSpaceTrafficConfigs  }) {
	const navigate = useNavigate();
	const mapContainer = useRef(null);
	const map = useRef(null);
  const param = useParams();
	const [lng, setLng] = useState(7.42446); //7.42446, 51.580984
	const [lat, setLat] = useState(51.580984);
	const [zoom, setZoom] = useState(15);
	const [defaultValues, setDefaultValues] = useState(null);

	const [data, setData] = useState(null);
	const [selectedVehicle, setSelectedVehicle] = useState(null);
	const [selectedDriverProfileIndexAndType, setSelectedDriverProfileIndexAndType] = useState(null);

	const [newValidationValues, setNewValidationValues] = useState(null);
	const [newDynamicActorValidationValues, setNewDynamicActorValidationValues] = useState(null);
	const [newRulesDynamicActorVehicleCompositionValidationValues, setNewRulesDynamicActorVehicleCompositionValidationValues] = useState(null);

	const [vehiclesList, setVehiclesList] = useState([]);
	const [DSpaceAgentsList, setDSpaceAgentsList] = useState([]);
	const [loadingEducAgents, setLoadingEducAgents] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loadingVehicles, setLoadingVehicles] = useState(false);
	const [openResultModal, setOpenResultModal] = useState(false);
	const [downloadLink, setDownloadLink] = useState("");
	const [downloadLinkCopied, setDownloadLinkCopied] = useState(false);
	const [showInfoPanel, setShowInfoPanel] = useState(true);
	const [educAgentsList, setEducAgentsList] = useState([]);
	const projectId = useSelector(state => state.Projects.projectId);
	

	useEffect(() => {
		if (!map.current) {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: mapTypes.streetView,
				center: [lng, lat],
				zoom: zoom,
			});
			map.current.on("load", () => {
				map.current.on("move", handleOnMapMove);
				fetchVehicleCompositions();
			});
		}
	}, []);

	const fetchAllEducAgents = useCallback(async () => {
		try {
			const projectUpdateID = parseInt(projectId);
			const response = await getEducAgentProjectsById(projectUpdateID);
	    setEducAgentsList(response.data.data.applications);

		} catch (error) {
			console.log('error', error);
		}
		finally {
			setLoadingEducAgents(false);
		}
	}, [projectId])

  useEffect(() => {
		fetchAllEducAgents();
	},[fetchAllEducAgents])

	function filterValidationKeys(jsonData, keyList) {
		const valudationData = jsonData;
		for (const key in valudationData) {
			if (valudationData.hasOwnProperty(key) && !keyList.includes(key)) {
				delete valudationData[key];
			}
		}
		return jsonData;
	}

	const fetchVehicleCompositions = async () => {
		setLoadingVehicles(true);
		await httpGet({ url: GET_VEHICLE_LIST })
			.then(async (resp) => {
				console.log("resp", resp);
				setVehiclesList(resp.data.data);
				const validations = Object.keys(resp.data.data);
				await httpGet({ url: GET_VEHICLE_COMPOSITION_LIST })
					.then((res) => {
						console.log("res", res);
						const response = res.data.data;
						response.valudation = filterValidationKeys(response.valudation, validations);
						// Function to calculate the total count of keys
						const getTotalKeys = (obj) => Object.keys(obj).length;

						// Function to calculate the value to be assigned to each key as an integer
						const getValuePerKey = (totalCount) => Math.floor(100 / totalCount);

						// Calculate the total count of keys
						const totalKeys = getTotalKeys(response.valudation);

						// Calculate the value to be assigned to each key as an integer
						const valuePerKey = getValuePerKey(totalKeys);

						// Calculate the residual value
						const residualValue = 100 - valuePerKey * totalKeys;

						// Create a new object with the desired values
						const newObject = {};

						function distributeValuesWithOneDecimal(arr) {
							const numProfiles = arr.length;
							const equalValue = parseFloat((1 / numProfiles).toFixed(1));
							const residual = parseFloat((1 - equalValue * numProfiles).toFixed(1));
							const result = {};

							for (let i = 0; i < numProfiles; i++) {
								let value = equalValue;
								if (i === 0) {
									value += residual;
								}
								result[arr[i]] = [{ value: parseFloat(value.toFixed(1)), settings: response.default[arr[i]] }]; // Limit to one decimal place
							}

							return result;
						}
						// Assign integer values to each key
						Object.keys(response.valudation).forEach((key, index) => {
							if (index === 0) {
								// Add the residual value to the first element
								newObject[key] = {
									value: valuePerKey + residualValue,
									driverProfiles: distributeValuesWithOneDecimal(response.valudation[key]),
								};
							} else {
								newObject[key] = { value: valuePerKey, driverProfiles: distributeValuesWithOneDecimal(response.valudation[key]) };
							}
						});
						console.log("data", newObject);
						ReduxCreateDSpaceTrafficConfigurations(newObject);
						setData(newObject);
						console.log("response", response);
						setDefaultValues(response);

						// const validationCount = Object.keys(response.valudation).length;
						// const _newValidationValues = { ...response.valudation };
						// const _newDynamicActorValidationValues = { ...response.valudation };
						// const _newRulesDynamicActorVehicleCompositionValidationValues = { ...response.valudation };
						// const distribution = distributeTotal(100, validationCount);
						// Object.keys(response.valudation).forEach((key, i) => {
						// 	_newValidationValues[key] = distribution[i];
						// 	const isEven = _newDynamicActorValidationValues[key].length % 2 === 0;
						// 	_newDynamicActorValidationValues[key] = Object.fromEntries(
						// 		/**
						// 		 * Check if the profiles are even count
						// 		 * Check if the profile is not single
						// 		 * Check if the profile is the last element
						// 		 * if yes then add a 0.1 to the last profile
						// 		 */
						// 		response.valudation[key].map((value, index) =>
						// 			!isEven && _newDynamicActorValidationValues[key].length !== 1 && index === _newDynamicActorValidationValues[key].length - 1
						// 				? [value, (1 / _newDynamicActorValidationValues[key].length + 0.1).toFixed(1)]
						// 				: [value, (1 / _newDynamicActorValidationValues[key].length).toFixed(1)]
						// 		)
						// 	);
						// 	_newRulesDynamicActorVehicleCompositionValidationValues[key] = Object.fromEntries(
						// 		response.valudation[key].map((value) => [value, response.default[value]])
						// 	);
						// });
						// setNewValidationValues(_newValidationValues);
						// setNewDynamicActorValidationValues(_newDynamicActorValidationValues);
						// setNewRulesDynamicActorVehicleCompositionValidationValues(_newRulesDynamicActorVehicleCompositionValidationValues);
						// setLoadingVehicles(false);
					})
					.then((err) => {
						console.log("err", err);
						setLoadingVehicles(false);
					});
			})
			.catch((err) => {
				console.log("err", err);
				setLoadingVehicles(false);
			});
	};

	console.log("data", data);

	const handleOnMapMove = (e) => {
		setLng(map.current.getCenter().lng.toFixed(4));
		setLat(map.current.getCenter().lat.toFixed(4));
		setZoom(map.current.getZoom().toFixed(2));
	};

	const canExecute = () => {
		// if (ReduxDSpace.userMap) {
		// 	if (vehicleCompositionAttributesValidations.code === 0) {
		// 		if (validateAllDynamicActorsSum(newDynamicActorValidationValues)) {
		// 			if (validateAggressivenessSum(newRulesDynamicActorVehicleCompositionValidationValues)) {
		// 				return true;
		// 			}
		// 			return false;
		// 		}
		// 		return false;
		// 	}
		// 	return false;
		// }
		// return false;
		return true;
	};

	function flattenVehicleProfileData(inputData) {
		let result = [];
		for (let key in inputData) {
			if (inputData.hasOwnProperty(key)) {
				result.push(...inputData[key]);
			}
		}
		return result;
	}

	const onClickNext = async (e) => {
		// setLoading(true);
		// const result = createDriverProfileData(newRulesDynamicActorVehicleCompositionValidationValues, newDynamicActorValidationValues);
		// const vehicleNames = extractNames(vehiclesList);
		// console.log("vehicleNames", vehicleNames);
		// const trafficComposition = flattenVehicleProfileData(
		// 	Object.keys(vehicleNames).map((name) => distributeAmount(result.transformedComposition[name], vehicleNames[name], newValidationValues[name]))
		// );

		let mappedSpawnStrategy = "";
		let params = {};
		if (ReduxDSpace.choosenStrategy === "everywhere") {
			mappedSpawnStrategy = "everywhere";
			params = { "ego.sensor_range": 200 };
		} else if (ReduxDSpace.choosenStrategy === "perception-based") {
			mappedSpawnStrategy = ReduxDSpace.perceptionBased.onCommingTraffic ? "around_ego_vision" : "around_ego";
			params = { "ego.sensor_range": ReduxDSpace.perceptionBased.perceptionRange };
		} else if (ReduxDSpace.choosenStrategy === "rate-based") {
			mappedSpawnStrategy = "rate_based";
			params = { ...spawnLaneInfo(ReduxDSpace.rateBased.savedMarkers), "ego.sensor_range": 200 };
		}
		// console.log("trafficComposition", trafficComposition);
		const payload = {
			project_id: ReduxProjects.projectId, //ReduxDSpace.projectId,
			name: ReduxDSpace.projectName,
			description: ReduxDSpace.projectDescription,
			project_path: ReduxDSpace.projectPath,
			scenario_name: ReduxDSpace.projectScenario,
			map_id: ReduxDSpace.mapId,
			traffic: {
				spawn_strategy: {
					traffic_amount_hint: 100,
					traffic_density: ReduxDSpace?.perceptionBased?.trafficDensity || 10,
					spawn_strategy: mappedSpawnStrategy,
					params,
				},
				vehicle_profiles: flattenVehicleProfileData(vehiclesList),
				composition: ReduxDSpaceTrafficConfigs
				// driver_profiles: result.transformedDriverProfiles,
				// traffic_composition: trafficComposition,
			},
		};
		console.log("payload", payload);
		await httpPost({ url: GET_DSPACE_TEST_PROJECT, payload })
			.then((res) => {
				console.log("res", res);
				setDownloadLink(res.data.data.libtraffic_asset);
				setOpenResultModal(true);
			})
			.catch((err) => {
				console.log("err", err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const downloadFile = () => {
		const fileUrl = downloadLink;

		// Create a link and simulate a click to trigger the download
		const link = document.createElement("a");
		link.href = fileUrl;
		link.download = "downloaded_file.pdf";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const copyLinkToClipboard = async () => {
		try {
			await navigator.clipboard.writeText(downloadLink);
			setDownloadLinkCopied(true);
			setTimeout(() => {
				setDownloadLinkCopied(false);
			}, 5000);
		} catch (err) {
			console.log("err", err);
		}
	};

	const updateAlgorithmSettingsFromCallback = () => {
		setSelectedDriverProfileIndexAndType(null);
	};

	const updateDriverProfilesFromCallback = () => {
		setSelectedVehicle(null);
	};

	return (
		<div className={``}>
			{loading && <ProgressX fullscreen={true} />}
			<div className={`container-fuild`}>
				<div className={`row`}>
					<div className={`col-12 d-flex justify-content-between`}>
						<div className={`d-flex`}></div>
						<div className={`d-flex`}>
							<ButtonX onClick={() => navigate(-1)} variant={"outlined"}>
								Back
							</ButtonX>
							<ButtonX className={`ms-2`} onClick={onClickNext} disabled={!canExecute()}>
								Execute
							</ButtonX>
						</div>
					</div>
				</div>
				<div className={`map-container mt-3`}>
					<div className="traffic-config-left-panel">
						<div className={`d-flex mb-2`}>
							<IconButtonX icon={"InfoRounded"} onClick={() => setShowInfoPanel(!showInfoPanel)} />
						</div>
						<Draggable>
							<div className="rbs-left-info-panel">
								<CollapseContent show={showInfoPanel} orientation={"vertical"}>
									<div className="px-3 py-2">
										<div className="d-flex justify-content-between align-items-center">
											<Typos.H6>Instructions</Typos.H6>
											<IconButtonX icon={"ClearRounded"} onClick={() => setShowInfoPanel(false)} />
										</div>
										<hr />
										{TRAFFIC_CONFIG_INSTRUCTIONS.map((item, i) => {
											return (
												<div className="row" key={i}>
													<div className="col-1">
														<InstructionItemIcon />
													</div>
													<div className="col-11">
														<Typos.Body1>{item.text}</Typos.Body1>
													</div>
												</div>
											);
										})}
									</div>
								</CollapseContent>
							</div>
						</Draggable>
					</div>
					{/* -------------------------------------------------------- VEHICLE PROFILE portion ------------------------------------------- */}
					<div className={`traffic-config-right-panel`}>
						<VehicleComposition loading={loadingVehicles} selectVehicleCallback={setSelectedVehicle} />
					</div>
					{/* -------------------------------------------------------- DYNAMIC ACTORS portion ------------------------------------------- */}
					{selectedVehicle !== null && (
						<div className={`traffic-config-dynamic-actors-right-panel shadow`}>
							<DriverProfiles
								data={data}
								selectedVehicle={selectedVehicle}
								selectDriverProfileCallback={setSelectedDriverProfileIndexAndType}
								saveUpdatedDataCallback={updateDriverProfilesFromCallback}
							/>
						</div>
					)}
					{/* -------------------------------------------------------- RULES portion ------------------------------------------- */}
					{selectedDriverProfileIndexAndType !== null && (
						<div className={`traffic-config-dynamic-actors-rules-right-panel shadow`}>
							<AlgorithmSettings
								data={data}
								trainingAgents={educAgentsList}
								selectedVehicle={selectedVehicle}
								selectedDriverProfileIndexAndType={selectedDriverProfileIndexAndType}
								saveUpdatedDataCallback={updateAlgorithmSettingsFromCallback}
							/>
						</div>
					)}
					<div className="sidebar">
						Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
					</div>
					<div ref={mapContainer} className="map-view" />
				</div>
			</div>
			<ModalX open={openResultModal} close={() => setOpenResultModal(false)} heading={"Results"} subheading={"Find your generated file in the link below"}>
				<div className={`container`}>
					<div className={`row justify-content-center mt-3`}>
						<div className={`col-lg-8 col-12`}>
							<TextFieldWithEndButton
								fullwidth={true}
								buttonIcon={downloadLinkCopied ? "DoneRounded" : "ContentCopy"}
								readonly={true}
								value={downloadLink}
								onButtonClick={copyLinkToClipboard}
							/>
							<div className={`d-flex justify-content-center mt-3`}>
								<Link to={AUTHENTICATED_ROUTES.coSimulation} className={`me-2`}>
									<ButtonX>Home</ButtonX>
								</Link>
								<ButtonX onClick={downloadFile}>Download</ButtonX>
							</div>
						</div>
					</div>
				</div>
			</ModalX>
		</div>
	);
}
function mapStateToProps(state) {
	return {
		ReduxDSpace: state.DSpace,
		ReduxProjects: state.Projects,
		ReduxDSpaceTrafficConfigs: state.DSpaceTrafficConfigs
	};
}
export default connect(mapStateToProps, { ReduxUpdateDSpaceJourney, ReduxCreateDSpaceTrafficConfigurations })(DSpaceTrafficConfig);

// !Depreciated
// function createDriverProfileData(driverProfile, profileComposition) {
// 	// Initialize the transformed driver profiles object
// 	let transformedDriverProfiles = {
// 		AI: [],
// 		RL: [],
// 		Stochastic: [],
// 	};

// 	// Initialize the transformed composition object
// 	let transformedComposition = {};

// 	let index = {
// 		AI: 0,
// 		RL: 0,
// 		Stochastic: 0,
// 	};

// 	for (let vehicleType in driverProfile) {
// 		if (driverProfile.hasOwnProperty(vehicleType)) {
// 			for (let profileType in driverProfile[vehicleType]) {
// 				if (driverProfile[vehicleType].hasOwnProperty(profileType)) {
// 					let profile = driverProfile[vehicleType][profileType];

// 					// Assign p values, name, target_speed, rule_abiding
// 					let transformedProfile = {
// 						aggressiveness: {
// 							p1: profile.aggressiveness.p1,
// 							p2: profile.aggressiveness.p2,
// 							p3: profile.aggressiveness.p3,
// 							p4: profile.aggressiveness.p4,
// 						},
// 						name: `${profileType}-Driver-${index[profileType]++}`,
// 						target_speed: profile.target_speed,
// 						algorithm: profile.algorithm,
// 						rule_abiding: profile.rule_abiding === -1 ? 0 : profile.rule_abiding === 1 ? -1 : profile.rule_abiding,
// 					};
// 					transformedDriverProfiles[profileType].push(transformedProfile);

// 					// Add to composition
// 					if (!transformedComposition[vehicleType]) {
// 						transformedComposition[vehicleType] = { driver_profile_probs: {} };
// 					}
// 					transformedComposition[vehicleType].driver_profile_probs[transformedProfile.name] = parseFloat(profileComposition[vehicleType][profileType]);
// 				}
// 			}
// 		}
// 	}

// 	return {
// 		transformedDriverProfiles,
// 		transformedComposition,
// 	};
// }

// !Depreciated
// function distributeAmount(driverProfileProbs, names, amount) {
// 	let outputData = { traffic_composition: [] };

// 	// If number of names is more than amount
// 	if (names.length > amount) {
// 		for (let i = 0; i < amount; i++) {
// 			let newItem = {
// 				driver_profile_probs: driverProfileProbs.driver_profile_probs,
// 				name: names[i],
// 				amount: 1,
// 			};
// 			outputData.traffic_composition.push(newItem);
// 		}
// 	} else {
// 		// Calculate how much each name gets and the remainder
// 		let perNameAmount = Math.floor(amount / names.length);
// 		let remainder = amount % names.length;

// 		// For each name, create a new object with the driver profile probs, name, and amount
// 		names.forEach((name, index) => {
// 			let nameAmount = perNameAmount;

// 			// Add the remainder to the first name
// 			if (index === 0 && remainder > 0) {
// 				nameAmount += remainder;
// 			}

// 			let newItem = {
// 				driver_profile_probs: driverProfileProbs.driver_profile_probs,
// 				name: name,
// 				amount: nameAmount,
// 			};

// 			// Add the new item to the output data
// 			outputData.traffic_composition.push(newItem);
// 		});
// 	}
// 	return outputData.traffic_composition;
// }
function spawnLaneInfo(inputData) {
	let outputData = { spawn_lanes_info: [] };
	inputData.forEach((item) => {
		let laneString = item.polygon.properties.road_id + ":0:" + item.polygon.properties.lane_id;
		let weightValue = item.rate;
		outputData.spawn_lanes_info.push({ lane: laneString, weight: weightValue });
	});
	return outputData;
}
