import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { replicaSimRDetailsTable } from "utils";

export default function VehicleDetailsTable({carData}) {
  console.log('carData', carData);

  const carDataArray = Object.entries(carData);

  const matchingKey = (parameter) => {
    const matchingData = carDataArray.find(([key, value]) =>
      key.toLowerCase().includes(parameter.toLowerCase().replace(/\s/g, "_"))
    );
    return matchingData ? matchingData[1] : "";
  }

  return (
    <Grid
      sx={{
        backgroundColor: "secondary.dark",
        paddingBottom: "12px",
        borderRadius: "8px",
      }}
    >
      <Grid className="vehicle-details-camera-table-headings">
        <Typography
          color="text.main"
          sx={{ marginLeft: "1rem" }}
        >
          Vehicle Details
        </Typography>
        <Typography
          color="text.main"
          sx={{ marginRight: "1rem", opacity: 0.5, pointerEvents: "none" }}
        >
          Add Parameter
          <AddCircleOutlineIcon style={{ fontSize: "large" }} />
        </Typography>
      </Grid>
      <Grid className="vehicle-details-camera-table-data">
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>Parameter</TableCell>
                <TableCell>Default Value</TableCell>
                {/* <TableCell>Actions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
            {replicaSimRDetailsTable.map((item) => {
                const value = matchingKey(item.parameter);

                return (
                  <TableRow key={item.parameter}>
                    <TableCell>{item.parameter}</TableCell>
                    <TableCell>{value}</TableCell>
                    {/* <TableCell>
                      <Grid style={{ display: "flex", opacity: 0.5, pointerEvents: "none" }}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}
