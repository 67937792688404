import {
  Grid,
  Icon,
  Typography,
  Button,
  CircularProgress,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import carLoad from "assets/images/carLoad.png";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getTestVehicleProfileService } from "endpointServices/testVehicleService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReduxUpdateMyVehicleJourney } from "services/redux-store/actions/actions-vehicles";
import { connect } from "react-redux";
import { vehicleList } from "utils";
import { Tabs, Tab, Box } from "@mui/material";
import { ButtonX, ProgressX } from "components";
import STORE from "services/redux-store/store";
import ConfirmationModal from "components/confirmationModal";
import VisibilityIcon from "@mui/icons-material/Visibility";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const tabStyle = {
  color: "#fff",
  cursor: "pointer",
  backgroundColor: "#0a294d",
  width: "10%",
  padding: "10px 12px",
  margin: "6px",
  border: "none",
  borderRadius: "7px",
  display: "flex",
  textTransform: "none",
  justifyContent: "center",
  "&.Mui-selected": {
    backgroundColor: "#00A3B5",
    color: "#FFFFF",
  },
};

const VehiclesList = ({ ReduxUpdateMyVehicleJourney }) => {
  const { MyVehicle, Projects } = STORE.getState();
  const { vehicleProfileId, readOnly } = MyVehicle;
  const { projectId } = Projects;
  console.log("vehicleProfileId", vehicleProfileId)
  const param = useParams();
  const navigate = useNavigate();
  const [selectTitle, setSelectTitle] = useState("");
  const [isFetching, setFetching] = React.useState(false);
  const [vehicleFetching, setVehicleFetching] = React.useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isDisabledNext, setIsDisabledNext] = useState(true);
  const [value, setValue] = React.useState(0);
  const [car, setCar] = useState([]);
  const [bus, setBus] = useState([]);
  const [truck, setTruck] = useState([]);
  const [motorCycle, setMotorcycle] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const tabArray = [
    { label: "Car", id: 0 },
    { label: "Bus", id: 1 },
    { label: "Truck", id: 2 },
    { label: "Motorcycle", id: 3 },
  ];

  React.useEffect(() => {
    if (vehicleProfileId) {
      [car, bus, truck, motorCycle].forEach((vehicles, index) => {
        console.log("index", index)
        const selectedVehicle = vehicles.find(
          (vehicle) => vehicle.id === vehicleProfileId
          );
          console.log("selectedVehicle", selectedVehicle);
          if (selectedVehicle) {
            setSelectTitle(selectedVehicle?.display_name);
            ReduxUpdateMyVehicleJourney({
              vehicleProfileId: selectedVehicle?.id,
            vehicleDisplayName: selectedVehicle?.display_name,
            vehicleProperties: selectedVehicle,
          });
          updateNextButtonState(true);
          setIsDisabled(true);
          setValue(index);
        }
      });
    } else {
      setIsDisabled(false);
    }
  }, [car, bus, truck, motorCycle]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpenModal = () => {
    if(readOnly) {
      handleConfirmation();
    } else {
      setOpenConfirmationModal(true);
    }
	};

  const closeConfirmationModal = () => setOpenConfirmationModal(false);

  const selectCar = (id, title, data) => {
    setSelectTitle(title);
    ReduxUpdateMyVehicleJourney({
      vehicleProfileId: id,
      vehicleDisplayName: title,
      vehicleProperties: data,
    });
    updateNextButtonState(true);
  };

  React.useEffect(() => {
    localStorage.removeItem('isLidar')
    localStorage.removeItem('isGT')
    getAllList();
  }, []);

  const updateNextButtonState = (isChecked) => {
    setIsDisabledNext(!isChecked);
  };

  const handleNext = () => {
    if (selectTitle) {
      const selectedVehicle =
        car.find((vehicle) => vehicle.display_name === selectTitle) ||
        bus.find((vehicle) => vehicle.display_name === selectTitle) ||
        truck.find((vehicle) => vehicle.display_name === selectTitle) ||
        motorCycle.find((vehicle) => vehicle.display_name === selectTitle)

      if (selectedVehicle) {
        navigate(
          `/projects/${param.id}/applications/test-vehicles/replica-sim/${selectedVehicle.prefab_name}/${selectedVehicle.id}`
        );
      }
    }
  };

  const handleConfirmation = () => {
    navigate(`/projects/${projectId}/applications/test-vehicles`);
  };

  const getAllList = async () => {
    setFetching(true);
    setVehicleFetching(true);
    const response = await getTestVehicleProfileService();
    if (response?.status === 200) {
      setFetching(false);
      setVehicleFetching(false);
      const { data } = response?.data;
      console.log("data", data);
      const vehicleListArray = [
        'Audi_A8_2011', 'Audi_Q7_Etron_2017', 'Audi_S5_2012', 'Audi_TTs_2008', 'BMW_M5_Touring_2017', 
        'BMW_X6_2009', 'BMW_330i_2008', 'Honda_E_2020', 'Jeep_Wrangler_2010_a', 'Maserati_GT_2009', 'Mazda_MX-5', 
        'Mercedes_ML500_2009', 'Setra_S515HD_2016', 'Scania_rSeries_2016_6x4_short', 'Suzuki_GSXR_2014', 'VW_Golf_2020', 'Mercedes_sClass_W222_2014', 'mercedesAtego_2014_CabinS',
      ];
      const carList = data?.Car.filter((item) =>
        vehicleListArray.includes(item.prefab_name)
      );
      const busList = data?.Bus.filter((item) =>
        vehicleListArray.includes(item.prefab_name)
      );
      const truckList = data?.Truck.filter((item) =>
        vehicleListArray.includes(item.prefab_name)
      );
      const motorList = data?.Motorcycle.filter((item) =>
        vehicleListArray.includes(item.prefab_name)
      );
      setCar(carList);
      setBus(busList);
      setTruck(truckList);
      setMotorcycle(motorList)
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setFetching(false);
      setVehicleFetching(false);
    }
  };

  return (
    <>
      <ConfirmationModal
        open={openConfirmationModal}
        heading={<span>Confirm Action</span>}
        subheading={<span>Going back will discard unsaved changes</span>}
        close={closeConfirmationModal}
        confirmationMessage={
          <span>
            Are you sure you want to go back? If you go back, your changes will
            be lost.
          </span>
        }
        onConfirm={handleConfirmation}
      />
      <Grid container sx={{ display: "flex", justifyContent: readOnly ? "space-between" : "end" }}>
        {readOnly && (
          <Grid item>
            <Chip
              icon={<VisibilityIcon />}
              label="Read Only Mode"
              variant="outlined"
              sx={{background:'#00a2b9'}}
            />
          </Grid>
        )}
        <Grid item>
          <Button
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "text.main",
              fontWeight: "bold",
              marginRight: "10px",
              textTransform: "none",
              fontSize: "inherit",
            }}
            onClick={handleOpenModal}
          >
            Back
          </Button>
          <ButtonX
            className={`ms-2`}
            onClick={handleNext}
            disabled={isDisabledNext}
          >
            Next
          </ButtonX>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "secondary.dark",
          mt: 2,
          border: "2px solid",
          borderColor: "primary.main",
          borderRadius: "12px",
          p: 2,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTab-root": {
              fontSize: "1.2rem",
            },
            "& .MuiTabs-indicator": {
              height: "0px",
            },
            backgroundColor: "secondary.dark",
            borderRadius: "12px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "space-between",
          }}
        >
          {tabArray.map((tab, index) => (
            <Tab key={tab.id} label={tab.label} sx={tabStyle} disabled={readOnly && index !== value} />
          ))}
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <Grid
            container
            xs={12}
            sm={12}
            sx={{
              height: "35rem",
            }}
          >
            <Grid
              container
              xs={12}
              sm={12}
              sx={{
                maxHeight: "33rem",
                overflowY: "auto",
                overflowX: "hidden",
                marginTop: "10px",
              }}
            >
              {vehicleFetching ? (
                <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                  <CircularProgress size={40} />
                </div>
              ) : (
                car.map((vehicle, index) => (
                  <Grid
                    item
                    xs={3}
                    sm={2}
                    key={index}
                    sx={{
                      minWidth: 295,
                      height: "14.7rem",
                      border: "2px solid",
                      borderColor: "primary.main",
                      backgroundColor: "secondary.dark",
                      textAlign: "center",
                      borderRadius: "12px",
                      m: 1,
                    }}
                  >
                    <Button
                      onClick={() =>
                        selectCar(vehicle.id, vehicle.display_name, vehicle)
                      }
                      disabled={readOnly ? isDisabled : ""}
                    >
                      <Grid>
                        <Icon
                          sx={{
                            fontSize: "5rem",
                            width: "2em",
                            height: "1.5em",
                          }}
                        >
                          <img
                            src={`/images/vehicles/${
                              vehicle.side_view ||
                              (vehicle.prefab_name
                                ? vehicle.prefab_name + ".png"
                                : "")
                            }`}
                            style={{ width: "150px", marginBottom: 0 }}
                            onError={(e) => {
                              e.target.src = carLoad;
                            }}
                          />
                        </Icon>
                        <Typography
                          sx={{
                            color: "text.main",
                            textTransform: "none",
                          }}
                        >
                          {vehicle.display_name}
                        </Typography>
                        <Typography
                          sx={{
                            color: "text.primary",
                            textTransform: "none",
                          }}
                        >
                          {vehicle.prefab_name}
                        </Typography>
                      </Grid>
                    </Button>
                    {selectTitle === vehicle.display_name ? (
                      <Link to={``} state={{ vehicleData: vehicle.model_path }}>
                        <Button
                          variant="contained"
                          sx={{
                            width: "100%",
                            textTransform: "none",
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomLeftRadius: "12px",
                            borderBottomRightRadius: "12px",
                            marginTop: "8px",
                            "&:hover": {
                              backgroundColor: "primary.main",
                            },
                          }}
                          onClick={updateNextButtonState}
                        >
                          Vehicle Selected
                        </Button>
                      </Link>
                    ) : (
                      ""
                    )}
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Grid
            container
            xs={12}
            sm={12}
            sx={{
              height: "35rem",
            }}
          >
            <Grid
              container
              xs={12}
              sm={12}
              sx={{
                maxHeight: "33rem",
                overflowY: "auto",
                overflowX: "hidden",
                marginTop: "10px",
              }}
            >
              {vehicleFetching ? (
                <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                  <CircularProgress size={40} />
                </div>
              ) : (
                bus.map((vehicle, index) => (
                  <Grid
                    item
                    xs={3}
                    sm={2}
                    key={index}
                    sx={{
                      minWidth: 295,
                      height: "14.7rem",
                      border: "2px solid",
                      borderColor: "primary.main",
                      backgroundColor: "secondary.dark",
                      textAlign: "center",
                      borderRadius: "12px",
                      m: 1,
                    }}
                  >
                    <Button
                      onClick={() =>
                        selectCar(vehicle.id, vehicle.display_name, vehicle)
                      }
                      disabled={readOnly ? isDisabled : ""}
                    >
                      <Grid>
                        <Icon
                          sx={{
                            fontSize: "5rem",
                            width: "2em",
                            height: "1.5em",
                          }}
                        >
                          <img
                            src={`/images/vehicles/${
                              vehicle.side_view ||
                              (vehicle.prefab_name
                                ? vehicle.prefab_name + ".png"
                                : "")
                            }`}
                            style={{ width: "150px", marginBottom: 0 }}
                            onError={(e) => {
                              e.target.src = carLoad;
                            }}
                          />
                        </Icon>
                        <Typography
                          sx={{
                            color: "text.main",
                            textTransform: "none",
                          }}
                        >
                          {vehicle.display_name}
                        </Typography>
                        <Typography
                          sx={{
                            color: "text.primary",
                            textTransform: "none",
                          }}
                        >
                          {vehicle.prefab_name}
                        </Typography>
                      </Grid>
                    </Button>
                    {selectTitle === vehicle.display_name ? (
                      <Link to={``} state={{ vehicleData: vehicle.model_path }}>
                        <Button
                          variant="contained"
                          sx={{
                            width: "100%",
                            textTransform: "none",
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomLeftRadius: "12px",
                            borderBottomRightRadius: "12px",
                            marginTop: "8px",
                            "&:hover": {
                              backgroundColor: "primary.main",
                            },
                          }}
                          onClick={updateNextButtonState}
                        >
                          Vehicle Selected
                        </Button>
                      </Link>
                    ) : (
                      ""
                    )}
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Grid
            container
            xs={12}
            sm={12}
            sx={{
              height: "35rem",
            }}
          >
            <Grid
              container
              xs={12}
              sm={12}
              sx={{
                maxHeight: "33rem",
                overflowY: "auto",
                overflowX: "hidden",
                marginTop: "10px",
              }}
            >
              {vehicleFetching ? (
                <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                  <CircularProgress size={40} />
                </div>
              ) : (
                truck.map((vehicle, index) => (
                  <Grid
                    item
                    xs={3}
                    sm={2}
                    key={index}
                    sx={{
                      minWidth: 295,
                      height: "14.7rem",
                      border: "2px solid",
                      borderColor: "primary.main",
                      backgroundColor: "secondary.dark",
                      textAlign: "center",
                      borderRadius: "12px",
                      m: 1,
                    }}
                  >
                    <Button
                      onClick={() =>
                        selectCar(vehicle.id, vehicle.display_name, vehicle)
                      }
                      disabled={readOnly ? isDisabled : ""}
                    >
                      <Grid>
                        <Icon
                          sx={{
                            fontSize: "5rem",
                            width: "2em",
                            height: "1.5em",
                          }}
                        >
                          <img
                            src={`/images/vehicles/${
                              vehicle.side_view ||
                              (vehicle.prefab_name
                                ? vehicle.prefab_name + ".png"
                                : "")
                            }`}
                            style={{ width: "150px", marginBottom: 0 }}
                            onError={(e) => {
                              e.target.src = carLoad;
                            }}
                          />
                        </Icon>
                        <Typography
                          sx={{
                            color: "text.main",
                            textTransform: "none",
                          }}
                        >
                          {vehicle.display_name}
                        </Typography>
                        <Typography
                          sx={{
                            color: "text.primary",
                            textTransform: "none",
                          }}
                        >
                          {vehicle.prefab_name}
                        </Typography>
                      </Grid>
                    </Button>
                    {selectTitle === vehicle.display_name ? (
                      <Link to={``} state={{ vehicleData: vehicle.model_path }}>
                        <Button
                          variant="contained"
                          sx={{
                            width: "100%",
                            textTransform: "none",
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomLeftRadius: "12px",
                            borderBottomRightRadius: "12px",
                            marginTop: "8px",
                            "&:hover": {
                              backgroundColor: "primary.main",
                            },
                          }}
                          onClick={updateNextButtonState}
                        >
                          Vehicle Selected
                        </Button>
                      </Link>
                    ) : (
                      ""
                    )}
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Grid
            container
            xs={12}
            sm={12}
            sx={{
              height: "35rem",
            }}
          >
            <Grid
              container
              xs={12}
              sm={12}
              sx={{
                maxHeight: "33rem",
                overflowY: "auto",
                overflowX: "hidden",
                marginTop: "10px",
              }}
            >
              {vehicleFetching ? (
                <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                  <CircularProgress size={40} />
                </div>
              ) : (
                motorCycle.map((vehicle, index) => (
                  <Grid
                    item
                    xs={3}
                    sm={2}
                    key={index}
                    sx={{
                      minWidth: 295,
                      height: "14.7rem",
                      border: "2px solid",
                      borderColor: "primary.main",
                      backgroundColor: "secondary.dark",
                      textAlign: "center",
                      borderRadius: "12px",
                      m: 1,
                    }}
                  >
                    <Button
                      onClick={() =>
                        selectCar(vehicle.id, vehicle.display_name, vehicle)
                      }
                      disabled={readOnly ? isDisabled : ""}
                    >
                      <Grid>
                        <Icon
                          sx={{
                            fontSize: "5rem",
                            width: "2em",
                            height: "1.5em",
                          }}
                        >
                          <img
                            src={`/images/vehicles/${
                              vehicle.side_view ||
                              (vehicle.prefab_name
                                ? vehicle.prefab_name + ".png"
                                : "")
                            }`}
                            style={{ width: "150px", marginBottom: 0 }}
                            onError={(e) => {
                              e.target.src = carLoad;
                            }}
                          />
                        </Icon>
                        <Typography
                          sx={{
                            color: "text.main",
                            textTransform: "none",
                          }}
                        >
                          {vehicle.display_name}
                        </Typography>
                        <Typography
                          sx={{
                            color: "text.primary",
                            textTransform: "none",
                          }}
                        >
                          {vehicle.prefab_name}
                        </Typography>
                      </Grid>
                    </Button>
                    {selectTitle === vehicle.display_name ? (
                      <Link to={``} state={{ vehicleData: vehicle.model_path }}>
                        <Button
                          variant="contained"
                          sx={{
                            width: "100%",
                            textTransform: "none",
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomLeftRadius: "12px",
                            borderBottomRightRadius: "12px",
                            marginTop: "8px",
                            "&:hover": {
                              backgroundColor: "primary.main",
                            },
                          }}
                          onClick={updateNextButtonState}
                        >
                          Vehicle Selected
                        </Button>
                      </Link>
                    ) : (
                      ""
                    )}
                  </Grid>
                ))
              )}
            </Grid>
          </Grid>
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default connect(null, { ReduxUpdateMyVehicleJourney })(VehiclesList);
