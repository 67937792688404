import { SET_MAP_VALIDATOR, UPDATE_MAP_VALIDATOR } from "..";

const INITIAL_STATE = {
    projectId: null,

}

export default function ReducerMapValidator(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_MAP_VALIDATOR:
            return action.payload;
        case UPDATE_MAP_VALIDATOR:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}