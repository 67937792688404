import React from "react";
import { OrbitControls, Environment } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import envFile from "../assets/images/venice_sunset_1k.hdr";

export default function ThreeCanvas({ children }) {
  // Canvas Camera Position
  const camCanvasPos = [4, 5, 0];

  // Direction Light Values
  const directionLight = [3.3, 1.0, 4.4];

  return (
    <Canvas
      camera={{ position: camCanvasPos }}
      zoom
      style={{
        marginTop: "1em",
        padding: "0.5em",
        height: "510px",
        borderRadius: "2%",
      }}
    >
      {/* Grid Planner Component */}
      <gridHelper args={[20, 20, `white`, `gray`]} />

      {/* Gizmo Component*/}
      <axesHelper args={[2]} />

      {/* Environment Component */}
      <Environment files={envFile} background blur={0.1} />

      {/* Light Component */}
      <directionalLight position={directionLight} />

      {/* Object Controller Component */}
      <OrbitControls target={[0, 1, 0]} />

      {/* Render children */}
      {children}
    </Canvas>
  );
}
