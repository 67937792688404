import { ArrowDropDownRounded, ExpandMoreRounded, RemoveRedEyeRounded, VisibilityOffRounded } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import React from 'react'

function AccordionX({ title, children, customicon, expanded, default_open, onClick }) {
    return (
		<Accordion
			onClick={onClick}
            expanded={expanded}
			defaultExpanded={default_open}
			
			sx={{ backgroundColor: "rgba(35, 55, 75, 0.6)", borderRadius: "12px" }}
		>
			<AccordionSummary expandIcon={expanded? <VisibilityOffRounded />:<RemoveRedEyeRounded />}>{title}</AccordionSummary>
			<AccordionDetails>{children}</AccordionDetails>
		</Accordion>
	);
}
export default AccordionX