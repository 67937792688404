import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Box, Grid, Radio, Button } from "@mui/material";
import ButtonX from "components/buttonX";
import { ModalX } from "components";
import { useParams, useNavigate } from "react-router-dom";
import MapsForm from "./mapsComponent/mapsForm";
import MapsTable from "./mapsComponent/mapsTable";
import { getMapProjectsById } from "endpointServices/mapsServices";
import { SetInfoToLocalStorage, wrapText } from "utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dateConverter from "utils/dateConverter";
import { pageSize } from "utils/staticsData";
import { ReduxCreateMapsJourney } from "services/redux-store/actions/actions-maps";
import { connect } from "react-redux";
import AirplayIcon from "@mui/icons-material/Airplay";

const Maps = ({ ReduxCreateMapsJourney }) => {
  const param = useParams();
  let navigator = useNavigate();

  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [geoId, setGeoId] = useState("");
  const [oddProjectId, setOddProjectId] = useState(null);
  const [summaryData, setSummaryData] = useState({});
  const [edited, setEdited] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setEdited(false);
  };

  const handleClose = () => setOpen(false);
  const getGeoId = (id) => setGeoId(id);
  const getOddProjectId = (id) => setOddProjectId(id);

  useEffect(() => {
    getProjectList({ pageNumber: page, testsPerPage: pageSize });
    localStorage.removeItem("p_id");
  }, [page]);

  const getProjectList = async (pagination) => {
    setFetching(true);
    const projectId = parseInt(param.id);
    const response = await getMapProjectsById(projectId, pagination);
    console.log("map response", response);
    if (response?.status === 200) {
      setFetching(false);
      const { data } = response?.data;
      setList(data.applications);
      setTotalRows(data.no_of_applications);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setList([]);
      setFetching(false);
    }
  };

  const goto = (data) => {
    console.log("maps data", data);
    console.log("maps versionId", data.application_version_id);
    SetInfoToLocalStorage("p_id", param.id);
    ReduxCreateMapsJourney(
      data.pralion_id,
      data.name,
      data.objective,
      data.application_version_id,
      data.application_version_id ? true : false
    );
    navigator(`/projects/${data.pralion_id}/applications/maps/map`);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSummary = (data) => {
    console.log("data", data);
    setEdited(true);
    setOpen(true);
    setSummaryData(data);
  };

  const columns = useMemo(
    () => [
      {
        field: "name",
        renderHeader: () => (
          <span className="heading-2">{"Map Catalog ID "}</span>
        ),
        width: 550,
        renderCell: (params) => (
          <Grid>
            <Button
              className="Main-link-simple row-heading"
              onClick={() => goto(params.row)}
              sx={{ color: "white" }}
            >
              {wrapText(params.value)}
            </Button>
          </Grid>
        ),
      },
      {
        field: "date_created",
        renderHeader: () => <span className="heading-2">{"Created At"}</span>,
        width: 280,
        renderCell: (params) => (
          <span className="row-heading">{dateConverter(params.value)}</span>
        ),
      },
      {
        renderHeader: () => <span className="heading-2">{"Summary"}</span>,
        width: 180,
        renderCell: (params) => (
          <Grid
            onClick={() => handleSummary(params.row)}
            sx={{ cursor: "pointer",marginLeft:'2em' }}
          >
            <AirplayIcon />
          </Grid>
        ),
      },
      // {
      //     field: "action",
      //     renderHeader: () => (
      //     <span className="heading-2">
      //      {'Action'}
      //      </span>
      //      ),
      //     width: 235,
      //     renderCell: (params) => {
      //       return (
      //         <>
      //           <div className="statusRadioButton">
      //           <Radio
      //               color="success"
      //               checked={true}
      //               sx={{
      //                 transform: "scale(0.6)", width: "1px", height: "1px",
      //                 "& > input": {
      //                   backgroundColor: "#4caf50",
      //                   color: "#4caf50",
      //                   "&:checked + span": {
      //                     backgroundColor: "#4caf50",
      //                     color: "#4caf50",
      //                     borderRadius: "16px",
      //                     width: "22px",
      //                     height: "22px",
      //                   },
      //                 },
      //               }}
      //             />

      //             <Radio
      //               sx={{
      //                 transform: "scale(0.6)", width: "1px", height: "1px",
      //                 "& > input": {
      //                   backgroundColor: "#ff9800",
      //                   color: "#ff9800",
      //                   "&:checked + span": {
      //                     backgroundColor: "#ff9800",
      //                     color: "#ff9800",
      //                     borderRadius: "16px",
      //                     width: "22px",
      //                     height: "22px",
      //                   },
      //                 },
      //               }}
      //             />
      //             <Radio
      //               sx={{
      //                 transform: "scale(0.6)",
      //                 width: "1px",
      //                 height: "1px",
      //                 "& > input": {
      //                   backgroundColor: "red",
      //                   color: "red",
      //                   "&:checked + span": {
      //                     backgroundColor: "red",
      //                     color: "red",
      //                     borderRadius: "16px",
      //                     width: "22px",
      //                     height: "22px",
      //                   },
      //                 },
      //               }}
      //             />
      //           </div>

      //           <Button
      //             variant="contained"
      //             sx={{
      //               backgroundColor: "secondary.dark",
      //               textTransform: "none",
      //               borderRadius: "8px",
      //             }}
      //             disabled
      //           >
      //             Stop
      //           </Button>
      //           <Button sx={{ color: "text.main" }} disabled >
      //             <MoreVertIcon />
      //           </Button>
      //         </>
      //       );
      //     },
      //   },
    ],
    [list, page]
  );

  return (
    <Fragment>
      <ModalX
        open={open}
        close={handleClose}
        heading={!edited ? "Create New MyMap" : `${summaryData.name} Summary`}
        subheading={
          !edited
            ? "Enter MyMap details to continue"
            : "MyMap application details"
        }
      >
        <MapsForm
          syncList={getProjectList}
          handleClose={handleClose}
          getOddId={getGeoId}
          getProjectId={getOddProjectId}
          summaryData={summaryData}
          isEdit={edited}
        />
      </ModalX>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        mb={2}
      >
        <ButtonX onClick={handleOpen}>Create New MyMap</ButtonX>
      </Box>
      <MapsTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />
    </Fragment>
  );
};
export default connect(null, { ReduxCreateMapsJourney })(Maps);
