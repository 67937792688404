import React from "react";
import Box from "@mui/material/Box";
import {
  Typography,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ScenariosShowing from "./scenariosShowing";

export default function VersionList({
  versionList,
  getAutoScenarioList,
  getMyScenarioList
}) {
  const list = versionList || [];
  const [expanded, setExpanded] = React.useState({});

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  if(list.length === 0) {
    return <Typography>No data found</Typography>
  }

  const renderContent = () => {
    return (
      <div>
        <div className="mb-3">
          {list.map((version) => (
            <Accordion
              key={version.id}
              expanded={expanded[`panel${version.id}`]}
              onChange={handleChange(`panel${version.id}`)}
              sx={{ backgroundColor: '#102c57' }} 
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${version.id}bh-content`}
                id={`panel${version.id}bh-header`}
              >
                <Typography sx={{ width: "33%", flexShrink: 0 }}>
                  Version Id: {version.version_number}
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  Created At: {version.date_created}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ maxHeight: '500px', overflow: 'auto' }}>
                <Typography>
                  <ScenariosShowing versionId={version.version_number} getScenarios={getAutoScenarioList || getMyScenarioList}/>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    );
  };

  return (
    <Box>
      <CardContent>{renderContent()}</CardContent>
    </Box>
  );
}
