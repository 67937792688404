import React, { useState } from "react";
import {
  Button,
  CardHeader,
  CardContent,
  Grid,
  MenuItem,
  TextField,
  Box,
  ListItemText,
} from "@mui/material";
import { Category } from "utils";

export default function CarMakerForm() {
  const [state, setState] = useState({
    name: "Name",
  });
  const [loading, setLoading] = useState(false);
  const [error, set_error] = useState("");

  const [selectCategory, setSelectCategory] = useState("");

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectCategory = (e) => {
    const { value } = e.target;
    setSelectCategory(value);
  };

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      name: state.name,
      category: selectCategory,
    };
    console.log("payload", payload);
    try {
      // await signinByEmailPassword(email.value, password.value);
      setLoading(false);
    } catch (e) {
      set_error(e.code);
      setLoading(false);
    }
  };

  return (
    <Box>
      <CardHeader
        title="Create a New Project"
        subheader="Enter details to continue"
        titleTypographyProps={{ variant: "h5", color: "text.main" }}
        subheaderTypographyProps={{
          variant: "caption",
          color: "text.main",
        }}
        sx={{
          color: "text.main",
          padding: "15px",
        }}
      />

      <CardContent>
        <form onSubmit={onSubmitLogin}>
          <Grid item xs={6}>
            <TextField
              name="name"
              required
              type="text"
              variant="outlined"
              label="Name"
              placeholder="Enter you name"
              value={state.name}
              onChange={handleInputs}
              fullWidth
              disabled={false}
            />
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              label="Category"
              name="category"
              required
              type="select"
              variant="outlined"
              value={selectCategory.label}
              onChange={handleSelectCategory}
              fullWidth
              disabled={false}
              select
            >
              {Category.map((item, id) => {
                return (
                  <MenuItem key={id} value={item.value}>
                    <ListItemText
                      primaryTypographyProps={{ align: "left" }}
                      primary={item.label}
                    />
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="objectibe"
              required
              type="text"
              variant="outlined"
              label="Objective"
              placeholder="Enter you objective"
              defaultValue={"objective"}
              fullWidth
              disabled={false}
              inputProps={{ sx: { height: "60px" } }}
            />
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="task"
              required
              type="text"
              variant="outlined"
              label="Key Tasks"
              placeholder="Enter you task"
              defaultValue={"task"}
              fullWidth
              disabled={false}
              inputProps={{ sx: { height: "60px" } }}
            />
          </Grid>

          <div className={`d-flex justify-content-center w-100 mt-3 mb-3`}>
            <Button
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                color: "text.main",
                width: "931px",
              }}
              disabled={loading}
              type="submit"
            >
              Create Account
            </Button>
          </div>
        </form>
      </CardContent>
    </Box>
  );
}
