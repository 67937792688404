import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { VersatileDownloadTableData } from "utils";

export default function DownloadTemplateTable({headers, tableRow}) {
    return (
        <div className="mt-4">
            <DataGrid
                rows={tableRow}
                columns={headers}
                className="main-table-height"
                sx={{
                    border: "none",
                    borderRadius: "12px",
                    backgroundColor: "secondary.dark",
                    "& .MuiDataGrid-cell": {
                        color: "text.main",
                    },
                    "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": { marginTop: "15px", }
                }}
                initialState={{
                    pagination: {
                      paginationModel: { pageSize: 10, page: 0 },
                    },
                  }}
            />
        </div>
    );
}
