import React, { Fragment, useState, useEffect } from "react";
import { Box, Grid, Radio, Button } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getAutoScenarioProjectsById } from "endpointServices/autoScenarioServices";
import dateConverter from "utils/dateConverter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { wrapText, checkDagStatus } from "utils";
import TraceabilityMatrixTable from "./traceabilityMatrixComponent/traceabilityMatrixTable";
import AirplayIcon from "@mui/icons-material/Airplay";
import { ButtonX, ModalX } from "components";
import { getLowFidelityTrace } from "endpointServices/scenAlyticsServices";
import STORE from "services/redux-store/store";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import ExecutedTable from "./traceabilityMatrixComponent/executedTable";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DescriptionField from "components/descriptionField";

const hardcodedData = [
  {
    id: 1,
    "Requirement ID": "REQ001",
    "Requirement Description": "Description for REQ001",
    "Test Suit ID": "TS001",
    "Test Suit Description": "Description for TS001",
    "Test Suit Execution ID": "TSE001",
    "Execution Description": "Description for TSE001",
    "Pass Fail Criteria": "Criteria for TSE001",
    Status: "Pass",
  },
];

export default function TraceabilityMatrix() {
  const { Projects } = STORE.getState();
  const { projectId } = Projects;
  const param = useParams();
  let navigator = useNavigate();

  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [open, setOpen] = useState(false);
  const [openDescriptionModal, setOpenDescriptionModal] = useState(false);
  const [headerName, setHeaderName] = useState("");
  const [description, setDescription] = useState("");
  const [summaryData, setSummaryData] = useState({});
  const [page, setPage] = useState(1);
  const pageSize = 10;
  console.log("list", list);

  useEffect(() => {
    getTraceailityMatrix({ pageNumber: page, testsPerPage: pageSize });
    localStorage.removeItem("p_id");
    localStorage.removeItem("pralion_id");
  }, []);

  useEffect(() => {
    getTraceailityMatrix({ pageNumber: page, testsPerPage: pageSize });
  }, [page]);

  const getTraceailityMatrix = async (pagination) => {
    setFetching(true);
    const response = await getLowFidelityTrace(projectId, pagination);
    console.log("response", response);
    if (response?.status === 200) {
      setFetching(false);
      const data = response?.data?.data || [];
      // const listFilter = data.filter(ele => ele.no_of_scenarios !== 0)
      setList(data);
      setTotalRows(response?.data?.data?.no_of_applications);
    } else {
      const err = response?.data?.message || "Failed to get request";
      console.log('err', err)
      // toast.error(`${err}. Please try again later`);
      setList([]);
      setFetching(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const goto = (data) => {
    // navigator(
    //   `/projects/${param.id}/applications/scen-alytics/alytics-auto-scenario/alytics-auto-scenario-map`
    // );
    // localStorage.setItem("pralion_id", data.pralion_id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSummary = (data) => {
    console.log("data", data);
    setOpen(true);
    setSummaryData(data);
  };

  const handleDescriptionClose = () => {
    setOpenDescriptionModal(false);
  };

  const handleOpenModal = (headerName, description) => {
    setOpenDescriptionModal(true);
    setHeaderName(headerName);
    setDescription(description);
  };

  const columns = [
    {
      field: "project.name",
      headerName: <span className="heading-2">Project ID</span>,
      width: 180,
      renderCell: (params) => {
        return (
          <Grid>
            <Button
              className="Main-link-simple"
              onClick={() => goto(params.row)}
              sx={{ color: "white" }}
            >
              {wrapText(params.row.project.name)}
            </Button>
          </Grid>
        );
      },
    },
    {
      field: "project.requirement_description",
      headerName: <span className="heading-2">Requirement Description</span>,
      width: 360,
      renderCell: (params) => {
        const description = params.row.project.requirement_description || "N/A";
        const headerName = "Requirement Description";
        if (description.length > 26) {
          return (
            <Fragment>
              <span
                className="row-heading"
                onClick={() => handleOpenModal(headerName, description)}
                style={{ cursor: "pointer" }}
              >
                {description.substring(0, 26)}
                <MoreHorizIcon />
              </span>
            </Fragment>
          );
        } else {
          return <span className="row-heading">{description}</span>;
        }
      },
    },
    {
      field: "test_suite.name",
      headerName: <span className="heading-2">Test Suites ID</span>,
      width: 180,
      renderCell: (params) => (
        <span className="row-heading">{params.row.test_suite.name}</span>
      ),
    },
    {
      field: "test_suite.description",
      headerName: <span className="heading-2">Test Suites Description</span>,
      width: 250,
      renderCell: (params) => {
        const description = params.row.test_suite.description || "N/A";
        const headerName = "Test Suites Description";
        if (description.length > 26) {
          return (
            <Fragment>
              <span
                className="row-heading"
                onClick={() => handleOpenModal(headerName, description)}
                style={{ cursor: "pointer" }}
              >
                {description.substring(0, 26)}
                <MoreHorizIcon sx={{marginTop:'0.2em', marginLeft:'0.1em'}} />
              </span>
            </Fragment>
          );
        } else {
          return <span className="row-heading">{description}</span>;
        }
      },
    },
    {
      field: "application.name",
      headerName: <span className="heading-2">Test Suites Execution ID</span>,
      width: 270,
      renderCell: (params) => (
        <span className="row-heading">{params.row.application.name}</span>
      ),
    },
    {
      field: "application.description",
      headerName: <span className="heading-2">Execution Description</span>,
      width: 270,
      renderCell: (params) => {
        const description = params.row.application.description || "N/A";
        const headerName = "Execution Description";
        if (description.length > 26) {
          return (
            <Fragment>
              <span
                className="row-heading"
                onClick={() => handleOpenModal(headerName, description)}
                style={{ cursor: "pointer" }}
              >
                {description.substring(0, 26)}
                <MoreHorizIcon sx={{marginTop:'0.2em', marginLeft:'0.1em'}} />
              </span>
            </Fragment>
          );
        } else {
          return <span className="row-heading">{description}</span>;
        }
      },
    },
    {
      field: "test_suite.meta.criteria",
      headerName: <span className="heading-2">Pass Fail Criteria</span>,
      width: 250,
      renderCell: (params) => {
        const description = params.row.test_suite.meta.criteria || "N/A";
        const headerName = "Pass Fail Criteria";
        if (description.length > 26) {
          return (
            <Fragment>
              <span
                className="row-heading"
                onClick={() => handleOpenModal(headerName, description)}
                style={{ cursor: "pointer" }}
              >
                {description.substring(0, 26)}
                <MoreHorizIcon sx={{marginTop:'0.2em', marginLeft:'0.1em'}} />
              </span>
            </Fragment>
          );
        } else {
          return <span className="row-heading">{description}</span>;
        }
      },
    },
    {
      field: "status",
      renderHeader: () => <span className="heading-2">{"Status"}</span>,
      width: 155,
      renderCell: (params) => (
        <Grid container alignItems="center" sx={{ marginLeft: "1em" }}>
          {params.row.status === true ? (
            <p style={{ marginTop: "16px" }}>
              <DoneIcon sx={{ color: "green" }} />
            </p>
          ) : params.row.status === false ? (
            <p style={{ marginTop: "16px" }}>
              <CloseIcon sx={{ color: "red" }} />
            </p>
          ) : (
            ""
          )}
        </Grid>
      ),
    },
    {
      renderHeader: () => (
        <span className="heading-2">{"Executed Scenarios"}</span>
      ),
      width: 200,
      renderCell: (params) => (
        <Grid
          sx={{ cursor: "pointer", marginLeft: "3em" }}
          onClick={() => handleSummary(params.row)}
        >
          <AirplayIcon />
        </Grid>
      ),
    },
  ];

  return (
    <Fragment>
      <ModalX
        open={openDescriptionModal}
        close={handleDescriptionClose}
        heading={headerName}
        subheading={"TraceAbility matrix details"}
      >
        <DescriptionField
          handleClose={handleDescriptionClose}
          headerName={headerName}
          description={description}
        />
      </ModalX>
      <ModalX
        open={open}
        close={handleClose}
        heading={"Executed Scenarios"}
        subheading={"TraceAbility matrix details"}
      >
        <ExecutedTable handleClose={handleClose} summaryData={summaryData} />
      </ModalX>
      <Grid item sx={{ display: "flex", justifyContent: "end" }}>
        <ButtonX onClick={() => navigator(-1)}>Back</ButtonX>
      </Grid>
      <TraceabilityMatrixTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />
    </Fragment>
  );
}
