import {  CLIENTS_BUILD_DOWNLOAD, COSIM_CLIENT } from "constants/endpoints"
import { httpGet, httpDelete } from "utils/httpMethods"

export const getCosimClientService = async (body) => {
    return await httpGet({ url: COSIM_CLIENT, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getCosimClientRegister = async (body) => {
    console.log('body', body)
    return await httpDelete({ url: `${COSIM_CLIENT}?mac_address=${body}`})
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getDownloadableBuilds = async (body) => {
    return await httpGet({ url: `${CLIENTS_BUILD_DOWNLOAD}`})
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}