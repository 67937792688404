import React, { Fragment, useEffect, useState } from 'react'
import { Box, Button, Grid, Radio } from '@mui/material';
import { ButtonX, ModalX } from 'components';
import StatusModal from 'components/statusModal';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SimulationDetailsForm from './simulationDetailsComponent/simulationDetailsForm';
import SimulationDetailsTable from './simulationDetailsComponent/simulationDetailsTable';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router";
import { getDspaceTestList } from 'endpointServices/testVehicleService';
import DownloadIcon from '@mui/icons-material/Download';
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    ReduxProjects: state.Projects
  }
}

export default connect(mapStateToProps) (function SimulationDetails({ReduxProjects}) {
  const navigate = useNavigate()
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const [list, setList] = useState([]);

  const [isFetching, setFetching] = useState(false);

  useEffect(() => {
    getAllList()
  }, [])

  const getAllList = async () => {
    setFetching(true)
    const projectId = localStorage.getItem('p_id')
    const response = await getDspaceTestList(ReduxProjects.projectId)
    if (response?.status === 200) {
      setFetching(false)
      const { data } = response?.data
      console.log('data', data)
      setList(data)
    } else {
      const err = response?.message || 'Failed to get request'
      // toast.error(`${err}. Please try again later`)
      setList([])
      setFetching(false)
    }
  }



  const gotoConfigPage = (data, type) => {
    if (type === 'edit') {
      navigate(`/applications/test-vehicles/co-simulation/co-simulation-details/d-space-config/${data.id}`)
    }
    else {
      navigate(`/applications/test-vehicles/co-simulation/co-simulation-details/d-space-config/0`)
    }
  }

  const downloadAssets = (data) => {
    const downloadFile = data.libtraffic_asset
    window.location = downloadFile
  }

  const columns = [
    {
      field: "name",
      headerName: "Project Name",
      width: 400,
      renderCell: (params) => (
        <Grid>
            {/* <Button className="Main-link-simple row-heading"
              onClick={() => gotoConfigPage(params.row, 'edit')}
            >
              {params.value}
            </Button> */}
            {params.value}
        </Grid>
      ),
    },
    { field: "scenario_name", headerName: "Scenario Name", width: 300 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <Grid container >
          <Grid item>
            <Button className="Main-link-simple row-heading" disabled={params.row.libtraffic_asset === null ? true :false} 
              onClick={() => downloadAssets(params.row)}  >
              <DownloadIcon /> 
            </Button>
          </Grid>
        </Grid>
      ),
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 245,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //       <div className="statusRadioButton">
    //       <Radio
    //           color="success"
    //           checked={true}
    //           sx={{
    //             transform: "scale(0.6)", width: "1px", height: "1px",
    //             "& > input": {
    //               backgroundColor: "#4caf50",
    //               color: "#4caf50",
    //               "&:checked + span": {
    //                 backgroundColor: "#4caf50",
    //                 color: "#4caf50",
    //                 borderRadius: "16px",
    //                 width: "22px",
    //                 height: "22px",
    //               },
    //             },
    //           }}
    //         />
           
    //         <Radio
    //           sx={{
    //             transform: "scale(0.6)", width: "1px", height: "1px",
    //             "& > input": {
    //               backgroundColor: "#ff9800",
    //               color: "#ff9800",
    //               "&:checked + span": {
    //                 backgroundColor: "#ff9800",
    //                 color: "#ff9800",
    //                 borderRadius: "16px",
    //                 width: "22px",
    //                 height: "22px",
    //               },
    //             },
    //           }}
    //         />
    //         <Radio
    //           sx={{
    //             transform: "scale(0.6)",
    //             width: "1px",
    //             height: "1px",
    //             "& > input": {
    //               backgroundColor: "red",
    //               color: "red",
    //               "&:checked + span": {
    //                 backgroundColor: "red",
    //                 color: "red",
    //                 borderRadius: "16px",
    //                 width: "22px",
    //                 height: "22px",
    //               },
    //             },
    //           }}
    //         />
    //       </div>

    //       <Button
    //         variant="contained"
    //         sx={{
    //           backgroundColor: "secondary.dark",
    //           textTransform: "none",
    //           borderRadius: "8px",
    //         }}
    //         disabled
    //       >
    //         Stop
    //       </Button>
    //       <Button sx={{ color: "text.main" }} disabled >
    //         <MoreVertIcon />
    //       </Button>
    //     </>
    //     );
    //   },
    // },
  ];

  return (
    <Fragment>
      {/* <ModalX open={open} close={handleClose} heading={"Create New Project"} subheading={"Enter Project details to continue"}>
        <SimulationDetailsForm handleClose={handleClose} />
      </ModalX> */}

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        mb={2}
      >
        <ButtonX onClick={gotoConfigPage}>New dSPACE Test</ButtonX>
      </Box>
      <SimulationDetailsTable headers={columns} table={list} fetching={isFetching}  />
    </Fragment>
  )
}
)