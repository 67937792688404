import { Button, Grid } from '@mui/material'
import React from 'react'
import { Link, useParams } from "react-router-dom";
import ExecuteDeailTable from './executeDetailsComponent/executeDeailTable';
import ExecuteDetailForm from './executeDetailsComponent/executeDetailForm';

export default function Execute() {
    const param = useParams()

    return (
        <>
            <Grid container justifyContent={"end"}>
                <Button
                    sx={{
                        backgroundColor: "primary.main",
                        "&:hover": {
                            backgroundColor: "primary.main",
                        },
                        color: "white",
                        width: "130px",
                        marginRight: "10px",
                    }}
                >
                    Back
                </Button>
                <Link to={`/projects/${param.id}/applications/auto-scenarios/execute/execute-setting`}>
                    <Button
                        sx={{
                            backgroundColor: "primary.main",
                            "&:hover": {
                                backgroundColor: "primary.main",
                            },
                            color: "white",
                            width: "130px",
                        }}

                    >
                        Execute
                    </Button>
                </Link>
            </Grid>

            <Grid container sx={{
                backgroundColor: "secondary.dark",
                padding: "12px",
                borderRadius: "4px",
                marginTop: "1rem",
                justifyContent: "space-between"
            }}>
                <Grid item xs={8}>
                    <Grid>
                        <ExecuteDetailForm />
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <ExecuteDeailTable />
                </Grid>
            </Grid>
        </>
    )
}
