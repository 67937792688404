import { SET_DSPACE_TRAFFIC_CONFIG, UPDATE_DSPACE_TRAFFIC_CONFIG } from "..";

const INITIAL_STATE = null

export default function ReducerDSpaceTrafficConfigurations(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_DSPACE_TRAFFIC_CONFIG:
            return action.payload;
        case UPDATE_DSPACE_TRAFFIC_CONFIG:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}