import { ButtonX, CheckboxX, ProgressX, ReadOnlyTag, Typos } from 'components';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { mapTypes } from "utils";
import "./perception-based-map-styles.css";
import { connect } from 'react-redux';
import { DEFAULT_POLYGON_LAYER } from 'constants/defaults';
import { Slider } from '@mui/material';
import { AUTHENTICATED_ROUTES } from 'router/constants';
import { ReduxUpdateEducAgentsJourney } from 'services/redux-store/actions';
import * as turf from '@turf/turf';

function EducPerceptionBased({ ReduxUpdateEducAgentsJourney, ReduxEducAgents }) {
	console.log('halooooo', )
	const navigate = useNavigate();
	const params = useParams();
	const mapContainer = useRef(null);
	const map = useRef(null);

	const [loading, setLoading] = useState(false)
	const [lng, setLng] = useState(7.42446); //7.42446, 51.580984
	const [lat, setLat] = useState(51.580984);
	const [zoom, setZoom] = useState(15);

	const [initiallng, setInitialLng] = useState(7.42446); //7.42446, 51.580984
	const [initiallat, setInitialLat] = useState(51.580984);
	const [initialzoom, setInitialZoom] = useState(15);
	
	const [radius, setRadius] = useState(100)
	const [circleCoords, setCircleCoords] = useState({ lat: 0, lng: 0});
	const [trafficDensity, setTrafficDensity] = useState(10);
	const [onCommingTraffic, setOnCommingTraffic] = useState(false);


	const loadMapLayers = async () => {
		setLoading(true);
		await fetch(ReduxEducAgents.geojsonMapIdAndOddPolygon)
			.then((data) => data.json())
			.then((json) => {
				console.log("json", json);
				map.current.addSource("polygons", {
					type: "geojson",
					data: json,
				});
				setLoading(false);
				map.current.addLayer(DEFAULT_POLYGON_LAYER("route-polygons", "polygons", "rgba(0, 163, 181,0.7)"));
				map.current.setCenter(json["features"][0]["geometry"]["coordinates"][0][0]);
				map.current.setZoom(zoom);
				setInitialLng(json["features"][0]["geometry"]["coordinates"][0][0][0]);
				setInitialLat(json["features"][0]["geometry"]["coordinates"][0][0][1]);
				// map.current.on("click", "route-polygons", onClickPolygon)
				// const center = getPolygonCenter(json)
				setCircleCoords({
					lng: json["features"][0]["geometry"]["coordinates"][0][0][0],
					lat: json["features"][0]["geometry"]["coordinates"][0][0][1],
				});
				map.current.addSource("area-source", {
					type: "geojson",
					data: generateCircle(json["features"][0]["geometry"]["coordinates"][0][0], radius / 1000)
				});
				map.current.addLayer(DEFAULT_POLYGON_LAYER("selected-area", "area-source", "rgba(0, 163, 181, 0.5)"));
				if (ReduxEducAgents.EducPerceptionBased) {
					setLoading(false);
					setRadius(ReduxEducAgents.EducPerceptionBased.perceptionRange);
					setTrafficDensity(ReduxEducAgents.EducPerceptionBased.trafficDensity);
					setOnCommingTraffic(ReduxEducAgents.EducPerceptionBased.onCommingTraffic);

					map.current.removeLayer("selected-area");
					map.current.removeSource("area-source");

					map.current.addSource("area-source", {
						type: "geojson",
						data: generateCircle([json["features"][0]["geometry"]["coordinates"][0][0][0], json["features"][0]["geometry"]["coordinates"][0][0][1]], ReduxEducAgents.EducPerceptionBased.perceptionRange / 1000)
					});
					map.current.addLayer(DEFAULT_POLYGON_LAYER("selected-area", "area-source", "rgba(0, 163, 181, 0.5)"))
				}
			});
	};

	useEffect(() => {
		if (!map.current) {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: mapTypes.streetView,
				center: [lng, lat],
				zoom: zoom,
			});
			map.current.on("load", () => {
				map.current.on("move", handleOnMapMove);
				loadMapLayers();
			});
		}
	}, [ReduxEducAgents]);

	const handleOnMapMove = (e) => {
		setLng(map.current.getCenter().lng.toFixed(4));
		setLat(map.current.getCenter().lat.toFixed(4));
		setZoom(map.current.getZoom().toFixed(2));
	};

	const onClickNext = () => {
		ReduxUpdateEducAgentsJourney({ EducPerceptionBased: { onCommingTraffic, trafficDensity, perceptionRange: radius } });
		navigate(
			AUTHENTICATED_ROUTES.educTrafficConfig
				.replace(":id", params.id)
				.replace(":aid", params.aid)
				.replace(":oddid", params.oddid)
				.replace(":strategy", "educ-perception-based")
		);
	};

	const handleOnCircleRadiusChange = (e, val) => {
		setRadius(val)
		map.current.removeLayer("selected-area");
		map.current.removeSource("area-source");

		map.current.addSource("area-source", {
			type: "geojson",
			data: generateCircle([circleCoords.lng, circleCoords.lat], val / 1000)
		  });
		map.current.addLayer(DEFAULT_POLYGON_LAYER("selected-area", "area-source", "rgba(0, 163, 181, 0.5)"))
	}

	function generateCircle(center, radius) {
		const circlePolygon = turf.circle(turf.point(center), radius, { steps: 64, units: 'kilometers', properties: {} });
		return {
		  type: "Polygon",
		  coordinates: circlePolygon.geometry.coordinates
		};
	  }

	  const onClickRecenter = () => {
		map.current.setCenter([initiallng, initiallat]);
		map.current.setZoom(initialzoom);
	};
	return (
		<div className={``}>
			<div className={`container-fuild`}>
				<div className={`row`}>
					<div className={`col-lg-12 d-flex justify-content-between align-items-center`}>
						<div className={``}>
							{
								ReduxEducAgents.readonly && <ReadOnlyTag />
							}							
						</div>
						<div className={`d-flex`}>
							<ButtonX onClick={() => navigate(-1)} variant={"outlined"}>
								Back
							</ButtonX>
							<ButtonX className={`ms-2`} onClick={onClickNext}>
								Next
							</ButtonX>
						</div>
					</div>
				</div>
				<div className={`map-container mt-3`}>
					<div className={`pbs-title-bar`}>
						<Typos.H6>Perception Based Spawning</Typos.H6>
					</div>

					<div className={`pbs-left-panel`}>
						<ButtonX className={"me-2"} size={"small"} onClick={onClickRecenter} variant={"outlined"} color={"inherit"}>
							Re-Center
						</ButtonX>
					</div>

					<div className={`pbs-right-panel`}>
						
						<div className={``}>
							<Typos.Body1>Perception Range (meters)</Typos.Body1>
							<Slider
								onChange={handleOnCircleRadiusChange}
								value={radius}
								valueLabelDisplay="auto"
								step={1}
								disabled={ReduxEducAgents.readonly}
								min={5}
								max={1000}
								marks={[
									{ value: 5, label: "5" },
									{ value: 200, label: "200" },
									{ value: 350, label: "350" },
									{ value: 1000, label: "1000" },
								]}
							/>
						</div>
						<hr />
						<div className={``}>
							<Typos.Body1>Traffic Density (vehicles in radius)</Typos.Body1>
							<Slider
								onChange={(e, val) => setTrafficDensity(val)}
								value={trafficDensity}
								valueLabelDisplay="auto"
								step={1}
								min={10}
								disabled={ReduxEducAgents.readonly}
								max={100}
								marks={[
									{ value: 10, label: "10" },
									{ value: 100, label: "100" },
								]}
							/>
						</div>
						<hr />
						<CheckboxX disabled={ReduxEducAgents.readonly} label={"On Comming Traffic"} labelplacement={"end"} checked={onCommingTraffic} onChange={(e) => setOnCommingTraffic(e.target.checked)} />
					
					</div>
					<div className="sidebar">
						Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
					</div>
					<div ref={mapContainer} className="map-view" />
					{loading && <ProgressX fullscreen={true}/> }
				</div>
			</div>
		</div>
	);
}
function mapStateToProps(state) {
	return {
		ReduxEducAgents: state.EducAgents,
	}
}
export default connect(mapStateToProps, { ReduxUpdateEducAgentsJourney })(EducPerceptionBased);