import React, { Fragment } from "react";
import SimulationTable from "./simulationComponent/simulationTable";
export default function CoSimulation() {
  
    return (
      <Fragment>
        <SimulationTable />
      </Fragment>
    );
}
