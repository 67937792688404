import React, { Fragment, useEffect, useState } from "react";
import { Button, Grid, MenuItem, TextField } from "@mui/material";
import { ButtonX, ModalX, ReadOnlyTag, Typos } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { getMapListById } from "endpointServices/mapsServices";
import STORE from "services/redux-store/store";
import { getMapGidListByPralionId } from "endpointServices/myScenarioServices";
import AutoMapOdds from "./autoMapListComponent/autoMapOdds";
import { ReduxUpdateAutoScenarioJourney } from "services/redux-store/actions";
import { connect } from "react-redux";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { getAutoScenarioByPralionId } from "endpointServices/autoScenarioServices";
import { getAllTestVehicleProjects } from "endpointServices/testVehicleService";

function mapStateToProps(state) {
  return {
    ReduxAutoScenario: state.AutoScenario
  }
}
export default connect(mapStateToProps, { ReduxUpdateAutoScenarioJourney })(function AutoMapList({ ReduxAutoScenario, ReduxUpdateAutoScenarioJourney }) {
  const navigate = useNavigate();
  const param = useParams();
  const [selectCategory, setSelectCategory] = useState("");
  const [mapList, setMapList] = useState([]);
  const [mapGid, setMapGid] = useState(null)
  const [isDisabled, setIsDisabled] = useState(true)

  const { Projects, AutoScenario } = STORE.getState();
  const projectId = Projects.projectId;
  const [loading, setLoading] = useState(false);
  const [selectedOdd, setSelectedOdd] = useState("");
  const [editWarningPopupVisibility, setEditWarningPopupVisibility] = useState(false);
  const [ifreadonly, setIfreadonly] = useState(false);

  useEffect(() => {
    getMapList();
  }, []);

  const updateNextButtonState = (isChecked, oddId) => {
    setIsDisabled(!isChecked);
    setSelectedOdd(oddId);
  };

  const handleOnChange = async (e) => {
    const { value } = e.target;
    setSelectCategory(value);
    await fetchOddsAgainstMapGid(value)
  };

  const fetchOddsAgainstMapGid = async (id) => {
    setLoading(true);
    const map = mapList.find(item => item.id === id);
    if (!map) {
      setLoading(false);
      return;
    }
    const pralionId = map.pralion_id;
    const response = await getMapGidListByPralionId(pralionId);
    if (response && response.status === 200) {
      const { data } = response.data;
      setMapGid(data);
    } else {
      console.log("error");
    }
    setLoading(false);
  }

  const getMapList = async () => {
    setLoading(true);
    const response = await getMapListById(projectId);
    if (response.status === 200) {
      const { data } = response.data;
      const filteredApplications = data.applications.filter(application => application?.application_version_id !== null);
      setMapList(filteredApplications);
      if (AutoScenario.readonly) {
        setIfreadonly(true);
        const prevState = await getAutoScenarioByPralionId(param.aid)
        if (prevState && prevState.status === 200) {
          console.log('prevState', prevState);
          setSelectCategory(prevState.data.data.meta?.map_app_id || null);
          await updateReduxDataJourneyForReadOnly(prevState.data.data);
          const map = filteredApplications.find(item => item.id === prevState.data.data.meta.map_app_id);
          if (!map) {
            setLoading(false);
            return;
          }
          const pralionId = map.pralion_id;
          const response = await getMapGidListByPralionId(pralionId);
          console.log("getMapGIdList response", response)
          if (response && response.status === 200) {
            const { data } = response.data;
            console.log("getMapGIdList data", data)
            setMapGid(data);
          } else {
            console.log("error");
          }
          setLoading(false);
        }
      }
      else {
        if (AutoScenario?.AutoMapList?.selectedMap) {
          setSelectCategory(AutoScenario?.AutoMapList?.selectedMap)
          setLoading(true);
          const map = filteredApplications.find(item => item.id === AutoScenario?.AutoMapList?.selectedMap);
          if (!map) {
            setLoading(false);
            return;
          }
          const pralionId = map.pralion_id;
          const response = await getMapGidListByPralionId(pralionId);
          console.log("getMapGIdList response", response)
          if (response && response.status === 200) {
            const { data } = response.data;
            console.log("getMapGIdList data", data)
            setMapGid(data);
          } else {
            console.log("error");
          }
          setLoading(false);
        }
      }
    } else {
      console.log("error");
    }
    setLoading(false);
  };

  const updateReduxDataJourneyForReadOnly = async (prevState) => {
    // if (!prevState?.meta?.map_app_id || !prevState.settings){
    //   console.log('update', prevState?.meta?.map_app_id, prevState.settings)
    //   return;
    // }
    const response = await getAllTestVehicleProjects(param.id)
    if (response && response.status === 200) {
      const vehicles = response.data.data.test_vehicles;
      console.log('vehilces', vehicles)
      const selectedVehicle = vehicles.find(v => v.application_version_id === prevState.test_vehicle_id)
      if (selectedVehicle){
        const dataPayload = {
          AutoMapList: {
            selectedMap: prevState.meta.map_app_id,
            oddData: { gid_kwh: prevState.meta.map_gid}
          },
          AsPerceptionBased: undefined,
          AsRateBased: undefined,
          AsTrafficConfig: {
            trafficConfig: prevState.settings.composition, 
            vehicleCount: prevState.settings.spawn_strategy.traffic_amount_hint
          },
          ExecuteSetting: {
            executionTime: prevState.execution_time,
            selectedTestVehicle: { name: selectedVehicle.name, value: selectedVehicle.pralion_id }
          }
        }
        if (prevState.settings.spawn_strategy.spawn_strategy === "everywhere") {
          dataPayload.AsSpawningStrategies = "everywhere";
          // params = { "ego.sensor_range": 200 };
        } else if (["around_ego_vision", "around_ego"].includes(prevState.settings.spawn_strategy.spawn_strategy)) {
          dataPayload.AsSpawningStrategies = "as-perception-based";
          dataPayload.AsPerceptionBased = { 
            perceptionRange: prevState.settings.spawn_strategy.params["ego.sensor_range"], 
            trafficDensity: prevState.settings.spawn_strategy.traffic_density, 
            onCommingTraffic: prevState.settings.spawn_strategy.spawn_strategy === "around_ego_vision"
          }
          // params = { "ego.sensor_range": ReduxAutoScenario.AsPerceptionBased.perceptionRange };
        } else if (prevState.settings.spawn_strategy.spawn_strategy === "rate_based") {
          dataPayload.AsSpawningStrategies = "as-rate-based";
          dataPayload.AsRateBased = {
            savedMarkers: [],
            prevSavedMarkers: prevState.settings.spawn_strategy.params.spawn_lanes_info
          }
          // params = { ...spawnLaneInfo(ReduxAutoScenario.AsRateBased.savedMarkers), "ego.sensor_range": 200 };
        }
        ReduxUpdateAutoScenarioJourney({...dataPayload})
      }
    }
  }

  const onClickNext = () => {
    if (!AutoScenario.AutoMapList) {
      ReduxUpdateAutoScenarioJourney({
        AutoMapList: {
          selectedMap: selectCategory,
          oddData: { gid_kwh: selectedOdd }
        }
      });
      navigate(AUTHENTICATED_ROUTES.asSpawningStrategies.replace(":id", param.id).replace(":aid", param.aid).replace(":mid", selectedOdd));
    }
    else {
      if ((AutoScenario.AutoMapList?.selectedMap !== selectCategory) || (AutoScenario.AutoMapList?.oddData.gid_kwh !== selectedOdd)) {
        setEditWarningPopupVisibility(true);
        return;
      }
      else {
        ReduxUpdateAutoScenarioJourney({
          AutoMapList: {
            selectedMap: selectCategory,
            oddData: { gid_kwh: selectedOdd }
          }
        });
        navigate(AUTHENTICATED_ROUTES.asSpawningStrategies.replace(":id", param.id).replace(":aid", param.aid).replace(":mid", selectedOdd));
      }
    }
  }

  const onContinueWithEditClick = () => {
    ReduxUpdateAutoScenarioJourney({
      AutoMapList: {
        selectedMap: selectCategory,
        oddData: { gid_kwh: selectedOdd },
      },
      AsPerceptionBased: undefined,
      AsRateBased: undefined,
      AsTrafficConfig: undefined,
      DefineObserver: undefined
    })
    navigate(AUTHENTICATED_ROUTES.asSpawningStrategies.replace(":id", param.id).replace(":aid", param.aid).replace(":mid", selectedOdd));
  }

  const onCancelEditsClick = () => {
    setSelectCategory(AutoScenario.AutoMapList.selectedMap);
    setSelectedOdd(AutoScenario.AutoMapList.oddData.gid_kwh);
    fetchOddsAgainstMapGid(AutoScenario.AutoMapList.selectedMap);
    setEditWarningPopupVisibility(false);
  }

  return (
    <Fragment>
      <div className={`container-fluid`}>
        <div className={`row`}>
          <div className={`col-lg-6 d-flex align-items-center`}>
            <TextField
              label="Select Map"
              name="selectCategory"
              type="select"
              size="small"
              variant="outlined"
              value={selectCategory}
              onChange={handleOnChange}
              sx={{ maxWidth: "23rem" }}
              helperText={"The defined Map Catalogs can only be referred"}
              fullWidth
              disabled={ifreadonly}
              select
              SelectProps={{
                MenuProps: {
                  className: "customDropdown",
                },
              }}
            >
              {mapList.map((item, i) => {
                return (
                  <MenuItem key={i} value={item.id}>
                    {item.name}
                  </MenuItem>
                );
              })}
            </TextField>
            <div className={`ms-2`}>
              {
                AutoScenario.readonly && <ReadOnlyTag />
              }
            </div>
          </div>
          <div className={`col-lg-6`}>
            <div className={`d-flex justify-content-end`}>

              <Button
                sx={{
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.main",
                  },
                  color: "text.main",
                  fontWeight: "bold",
                  marginRight: "10px",
                  textTransform: "none",
                  fontSize: "inherit",
                }}
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <ButtonX className={`ms-2`} onClick={onClickNext} disabled={isDisabled}>
                Next
              </ButtonX>
            </div>
          </div>
        </div>
      </div>
      <AutoMapOdds loading={loading} mapIds={mapGid} updateNextButtonState={updateNextButtonState} />
      <ModalX heading={"Warning!"} subheading={"Your Progress will be lost."} open={editWarningPopupVisibility} close={() => setEditWarningPopupVisibility(false)} >
        <Typos.Body1>If you edit your selection, you will loose any saved progress made after this screen.</Typos.Body1>
        <hr />
        <div className={`d-flex`}>
          <ButtonX className={"me-2"} onClick={onCancelEditsClick}>
            Cancel
          </ButtonX>
          <ButtonX variant={"outlined"} onClick={onContinueWithEditClick}>
            Continue
          </ButtonX>
        </div>
      </ModalX>
    </ Fragment>
  );
})
