import { Button, Grid, Chip } from "@mui/material";
import { ButtonX } from "components";
import { updateTestVehicleProjectService } from "endpointServices/testVehicleService";
import React, { Fragment, useState, } from "react";
import { useNavigate, useParams } from "react-router-dom";
import VehicleDetailsDForm from "./vehicledetailsComponent/vehicleDetailsDForm"
import VehicleDetailsForm from "./vehicledetailsComponent/vehicleDetailsForm";
import VehicleDetailsTable from "./vehicledetailsComponent/vehicleDetailsTable";
import { toast } from 'react-toastify';
import { ReduxUpdateMyVehicleJourney } from "services/redux-store/actions/actions-vehicles";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import STORE from "services/redux-store/store";
import { getSensorSetupDetails, SensorSetupDetails, Static_Properties_Cam_Lidar_GT } from "utils";
import ConfirmationModal from "components/confirmationModal";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ReplicarSimVehicleDetails = () =>  {
  const {MyVehicle} = STORE.getState()
  const { pralionId, readOnly } = MyVehicle;
  const {Projects} = STORE.getState()

  const navigate = useNavigate();
  const param = useParams();

  const [vehicleDetailsData, setVehicleDetailsData] = useState(null);
  const [cameraSensorPayload, setCameraSensorPayload] = useState(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const handleOpenModal = () => {
    if(readOnly) {
      handleConfirmation();
    } else {
      setOpenConfirmationModal(true);
    }
	};

  const closeConfirmationModal = () => setOpenConfirmationModal(false);

  const handleConfirmation = () => {
    navigate(`/projects/${pralionId}/applications/test-vehicles/replica-sim`);
  };
  
  const handleVehicleDetailsData = (data) => {
    setVehicleDetailsData(data);
  };

  const handleAllPresets = (data, updatePreset) => {
    console.log('updatePreset', updatePreset)

    console.log("data", data);
    const lastUpdatedPreset =  updatePreset
    const updatedCameraPresets = data?.[0]?.sensor_presets || []
    // setCameraPresets(updatedCameraPresets);

    // fetching cameras key
    const selectedCameraPresets = updatedCameraPresets.map(preset => preset?.id);

    // fetching camera sensor fields
    const cameraSensorFields = updatedCameraPresets.map(preset => {
      const { id, rot_x, rot_y, rot_z, sensor_id, sensor_type_id, isShow, ...presetWithoutIds } = preset;
      return {
        "key": preset.id, 
        "dumpType": 12,
        "monitor": false,
        ...presetWithoutIds,
        "pitch": preset.rot_x,
        "yaw": preset.rot_y,
        "roll": preset.rot_z
      };
    });

    // fetching rendering types
    const renderingType = updatedCameraPresets.map(preset => {
      const { id, name, pos_x, pos_y, pos_z, rot_x, rot_y, rot_z, sensor_id, sensor_type_id, isShow, ...presetWithoutIds } = preset;
      return {
        "camera_name": preset.name,
        "depth": false,
        "key": preset.id, 
        "normals": false,
        "raw": true,
        "save_images": false,
        "segmented": false,
        ...presetWithoutIds
      };
    });

    const payload = {
      "cameraSensorFields": lastUpdatedPreset === undefined ? cameraSensorFields[0]:lastUpdatedPreset.cameraSensorFields,
      "dump_type_id": 12,
      "monitor": false,
      "name": data[0]?.name,
      "rendering_type": renderingType,
      "selected_camera_presets": selectedCameraPresets,
      "sensor_raw_data": {fov_x: 360}
    };
    console.log('payload', payload)
    setCameraSensorPayload(payload)
  };

  console.log('vehicleDetailsData', vehicleDetailsData)

  const handleDisabledSubmit = (data) => {
    setIsDisabled(data);
  };


  const handleSubmit = async () => {
    const isLidarTrue = localStorage.getItem('isLidar')
    const isLidarGT = localStorage.getItem('isGT')
    // condition for vehicle details null 
    const staticProperties = Static_Properties_Cam_Lidar_GT

    console.log('cameraSensorPayload', cameraSensorPayload)
    
    const payload = {
      name: MyVehicle.projectName,
      pralion_id: MyVehicle.pralionId,
      controller_id: 1,
      vehicle_profile_id: MyVehicle.vehicleProfileId,
      enable_lidar: true,
      graphical_rendering: true,
      sensors: {
        camera: cameraSensorPayload === null ? null : cameraSensorPayload,
        lidar:  vehicleDetailsData === null ? staticProperties.lidar : vehicleDetailsData.sensors.lidar === undefined ? staticProperties.lidar : vehicleDetailsData?.sensors.lidar,
        ground_truth:vehicleDetailsData === null ? staticProperties.ground_truth : vehicleDetailsData.sensors.lidar === undefined ? staticProperties.lidar  : vehicleDetailsData?.sensors.ground_truth,
      },
    };

    if(payload.sensors.camera === null || !Object.values(payload.sensors.camera) || payload.sensors.camera.name === undefined) {
      delete payload.sensors.camera 
    }

    if(JSON.parse(isLidarTrue) === true || JSON.parse(isLidarTrue) === null || JSON.parse(isLidarTrue) === undefined ) {
      delete payload.sensors.lidar 
    }

    if(JSON.parse(isLidarGT) === true || JSON.parse(isLidarGT) === null || JSON.parse(isLidarGT) === undefined) {
      delete payload.sensors.ground_truth 
    }

  
    const {sensors} = payload
    console.log("sensors.camera.cameraSensorFields", sensors.camera.cameraSensorFields)
    if (
      sensors.camera.cameraSensorFields === undefined &&
      sensors.ground_truth === undefined &&
      sensors.lidar === undefined
    ) {
      toast.warning(`Sensor setup cannot submitted empty!`);
    } else if (sensors?.camera?.cameraSensorFields?.key === undefined) {
      toast.warning(`Please ensure that at least one camera is selected before submit!`);
    } else {
      const response = await updateTestVehicleProjectService(payload);
      console.log("response", response);
      if (response?.status === 200) {
        console.log("response", response);
        toast.success(`Test vehicle successfully updated`);
        setTimeout(() => {
          navigate(
            `/projects/${Projects.projectId}/applications/test-vehicles`
          );
        }, 2000);
      } else {
        const err = response?.message || "Failed to get request";
        toast.error(`${err}. Please try again later`);
        console.log("errorresponse", response);
      }
    }

    console.log("payload", payload)

    
  };

  return (
    <Fragment>
      <ConfirmationModal
        open={openConfirmationModal}
        heading={<span>Confirm Action</span>}
        subheading={<span>Going back will discard unsaved changes</span>}
        close={closeConfirmationModal}
        confirmationMessage={
          <span>
            Are you sure you want to go back? If you go back, your changes will
            be lost.
          </span>
        }
        onConfirm={handleConfirmation}
      />
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: readOnly ? "space-between" : "end",
        }}
      >
        {readOnly && (
          <Grid item>
            <Chip
              icon={<VisibilityIcon />}
              label="Read Only Mode"
              variant="outlined"
              sx={{background:'#00a2b9'}}
            />
          </Grid>
        )}
        <Grid item>
          <Button
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "text.main",
              fontWeight: "bold",
              marginRight: "10px",
              width: "6rem",
              textTransform: "none",
              fontSize: "inherit",
            }}
            onClick={handleOpenModal}
          >
            Back
          </Button>
          <ButtonX onClick={handleSubmit} disabled={isDisabled}>
            Submit
          </ButtonX>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Grid>
            <VehicleDetailsDForm
              projectId={Projects.projectId}
              handleCameraPresets={handleAllPresets}
              disabledSubmit={handleDisabledSubmit}
              currentVehicle={MyVehicle}
              onSubmitHandler={handleVehicleDetailsData}
            />
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}
export default connect(null, { ReduxUpdateMyVehicleJourney })(ReplicarSimVehicleDetails);