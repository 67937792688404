import { Box, Grid } from "@mui/material";
import { ModalX } from "components";
import ButtonX from "components/buttonX";
import React, { Fragment } from "react";
import CoSimForm from "../coSimComponent/coSimForm";
import CoSimDetailsForm from "./coSimDSpaceDetailsComponent/coSimDetailsForm";
import CoSimDetailsTable from "./coSimDSpaceDetailsComponent/coSimDetailsTable";

export default function CoSimDSpaceDetails() {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Fragment>
      <ModalX open={open} close={handleClose}>
        <CoSimForm handleClose={handleClose} />
      </ModalX>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        mb={2}
      >
        <ButtonX onClick={handleOpen}>New Project</ButtonX>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <CoSimDetailsForm />
        </Grid>
        <Grid item xs={4}>
          <CoSimDetailsTable />
        </Grid>
      </Grid>
    </Fragment>
  );
}
