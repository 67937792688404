import { combineReducers } from "redux";
import ReducerUser from "./reducer-user";
import ReducerDSpace from "./reducer-dspace";
import ReducerProjects from "./reducer-projects";
import ReducerVehicles from "./reducer-vehicle";
import ReducerAutoScenario from "./reducer-autoscenario";
import ReducerMyScenario from "./reducer-myscenario"
import ReducerEducAgents from "./reducer-educAgents";
import ReducerMapValidator from "./reducer-map-validator";
import ReducerSceneAnalytics from "./reducer-scene-analytics";
import ReducerScenExtract from "./reducer-scenextract";
import ReducerDSpaceTrafficConfigurations from "./reducer-dspace-traffic-configurations";
import ReducerLowFidelity from "./reducer-lowFidelity";
import ReducerMaps from "./reducer.maps";
import ReducerOdd from "./reducer-odd";
import ReducerTestSuits from "./reducer-testSuits";
import ReducerCarla from "./reducer-carla";
import ReducerHighFidelity from "./reducer-highFidelity";


const ROOT_REDUCER = combineReducers({
    User: ReducerUser,
    DSpace: ReducerDSpace,
    Projects: ReducerProjects,
    MyVehicle: ReducerVehicles,
    AutoScenario: ReducerAutoScenario,
    MyScenario: ReducerMyScenario,
    EducAgents: ReducerEducAgents,
    MapValidator: ReducerMapValidator,
    SceneAnalytics: ReducerSceneAnalytics,
    ScenExtract: ReducerScenExtract,
    DSpaceTrafficConfigs: ReducerDSpaceTrafficConfigurations,
    LowFidelity: ReducerLowFidelity,
    Maps: ReducerMaps,
    Odd: ReducerOdd,
    TestSuits: ReducerTestSuits,
    Carla: ReducerCarla,
    HighFidelity: ReducerHighFidelity,
});
export default ROOT_REDUCER;