import React, { Fragment, useEffect, useState } from 'react'
import { Grid, Typography, Box, Slider, FormControlLabel, Checkbox, TextField, Button } from '@mui/material'
import { autoScenarioMainState, getDynamicActors } from '../../stateMaintain';
import { Typos } from 'components';
import { ReduxUpdateAutoScenarioJourney } from "services/redux-store/actions/actions-autoscenario";
import { connect } from "react-redux"
import STORE from "services/redux-store/store"


const DynamicActorForm = ({ setIsSave, ReduxUpdateAutoScenarioJourney }) => {
	const {AutoScenario} = STORE.getState()


    const [onComingTraffic, setOnComingTraffic] = useState(false)
    const [isRandomMode, setIsRandomMode] = useState(false)

    const [selectTrafficVehicleProfile, setSelectTrafficVehicleProfile] = useState([])
    const [selectedTrafficVehicleProfile, setSelectedTrafficVehicleProfile] = useState([])
	const [state, setState] = useState({
      perceptionRange: 0,
      amount: 200,
      seedValue: 1,
      egoDensity: 50,
      executionTime: 60,
    });
    const [trafficVehicleProfile, setTrafficVehicleProfile] = useState(
        [
            {
                "base_vehicle_profile_id": 17,
                "name": "Honda-Life",
                "cda": 0.36,
                "maximum_speed": 150
            },
            {
                "base_vehicle_profile_id": 65,
                "name": "Toyota-Camry",
                "cda": 0.62,
                "maximum_speed": 250
            },
            {
                "base_vehicle_profile_id": 66,
                "name": "Toyota-Corolla-Hatchback-2019",
                "cda": 0.58,
                "maximum_speed": 200
            },
            {
                "base_vehicle_profile_id": 67,
                "name": "Toyota-Prius-2019",
                "cda": 0.58,
                "maximum_speed": 160
            },
            {
                "base_vehicle_profile_id": 4,
                "name": "Audi S5 2012",
                "cda": 0.62,
                "maximum_speed": 209
            },
        ])

	useEffect(() => {
		setIsSave(true)
		getStoreValues()
	},[])	

	const getStoreValues = () => {
		if(AutoScenario.dynamicActorForm !== undefined && AutoScenario.dynamicActorForm !== null ) {
			const {traffic} = AutoScenario.dynamicActorForm
			const {ego} = AutoScenario.dynamicActorForm
			getDynamicActors(AutoScenario.dynamicActorForm);
			setState({
				amount: traffic.amount, egoDensity: traffic.density_around_ego, seedValue: traffic.seed_value,
			executionTime:AutoScenario.dynamicActorForm.executionTime*60, perceptionRange: ego.perception_range  })
			setIsRandomMode(traffic.random_mode)
			setOnComingTraffic(ego.oncoming_traffic)
			setIsSave(false);
		}
		
	}

	const convertToMeters = (value, valueLabelDisplay) => {
		if(valueLabelDisplay === 'auto') {
			return value + 'M'
		}
		return value + ' meters';
	  };

	  const convertToSec = (value, valueLabelDisplay) => {
		  console.log("convertToSec", value)
		if(valueLabelDisplay === 'auto') {
			return value + 'sec'
		}
		return value + ' sec';
	};

    const handleSelectState = (event) => {
        const { name, value } = event.target
        setState(prev => ({
            ...prev,
            [name]: value
        }))
    }

    const onSaveData = () => {
		const executionTimeConvert = state.executionTime/60
        const payload = {
            "ego": {
                "perception_range": state.perceptionRange,
                "oncoming_traffic": onComingTraffic
            },
            "traffic": {
                "amount": state.amount,
                "seed_value": state.seedValue,
                "random_mode": isRandomMode,
                "density_around_ego": state.egoDensity,
                "vehicle_profiles": selectedTrafficVehicleProfile
            },
			"executionTime":executionTimeConvert
        }
   
		if (state.perceptionRange > 0 || isRandomMode) {
			setIsSave(false);
		} else {
			setIsSave(true);
		}

		ReduxUpdateAutoScenarioJourney({dynamicActorForm: payload})
    }


    return (
		<Fragment>
			<Grid>
				<Typography color="text.main" sx={{ marginLeft: "1rem", marginTop: "1.5rem" }}>
					Ego
				</Typography>
			</Grid>
			<Grid>
				<Box p={2}>
					<Typography gutterBottom>Perception Range: {convertToMeters(state.perceptionRange)}</Typography>
					<Slider
						size="small"
						defaultValue={70}
						value={state.perceptionRange}
						onChange={handleSelectState}
						name="perceptionRange"
						aria-label="Small"
						valueLabelDisplay="auto"
						valueLabelFormat={(value) => convertToMeters(value, 'auto')}
						min={1} 
						max={100}
						step={1}
					/>
				</Box>
				<Box p={2}>
					<Typography gutterBottom>Execution Time: {convertToSec(state.executionTime)}</Typography>
					<Slider
						size="small"
						value={state.executionTime}
						onChange={handleSelectState}
						name="executionTime"
						aria-label="Small"
						valueLabelDisplay="auto"
						valueLabelFormat={(value) => convertToSec(value, 'auto')}
						min={60} 
						max={300}
						step={60}
						marks
					/>
					<FormControlLabel
						control={<Checkbox name={"onComingTraffic"} checked={onComingTraffic} onChange={() => setOnComingTraffic(!onComingTraffic)} />}
						label="On Coming Traffic"
					/>
				</Box>
			</Grid>

			
			<div className={`d-flex justify-content-between ps-3`}>
				<Typos.Body1>Traffic</Typos.Body1>
				<FormControlLabel control={<Checkbox size='small' checked={isRandomMode} onChange={() => setIsRandomMode(!isRandomMode)} />} label="Random Mode" />
			</div>
			<Grid>
				<Box p={2}>
					<TextField
						sx={{ marginBottom: "1em" }}
						name="amount"
						size="small"
						type="number"
						variant="outlined"
						label="Amount"
						placeholder="Enter amount"
						value={state.amount}
						onChange={handleSelectState}
						fullWidth
						disabled={false}
						InputProps={{ inputProps: { min: 0, max: 100 } }}
					/>
					<TextField
						sx={{ marginBottom: "1em" }}
						name="seedValue"
						size="small"
						type="number"
						variant="outlined"
						label="Seed Value"
						placeholder="Enter seed value"
						value={state.seedValue}
						onChange={handleSelectState}
						fullWidth
						disabled={false}
						InputProps={{ inputProps: { min: 1, max: 10 } }}
					/>
					<TextField
						sx={{ marginBottom: "1em" }}
						name="egoDensity"
						size="small"
						type="number"
						variant="outlined"
						label="Ego Density"
						placeholder="Enter ego density"
						value={state.egoDensity}
						onChange={handleSelectState}
						fullWidth
						disabled={false}
						InputProps={{ inputProps: { min: 1, max: 10 } }}
					/>
					{/* <TextField
                        name="executionTime"
                        type="number"
                        variant="outlined"
                        label="Execution Time"
                        placeholder="Enter time"
                        value={state.executionTime}
                        onChange={handleSelectState}
                        fullWidth
                        disabled={false}
						InputProps={{ inputProps: { min: 1, max: 10 } }}
                    /> */}

					{/* <FormControl sx={{ minWidth: "100%" }}>
						<Typography color="text.main" sx={{ marginBottom: "0.5rem" }}>
							Vehicle Profile
						</Typography>

						<Select
							name="selectTrafficVehicleProfile"
							multiple
							native
							value={selectTrafficVehicleProfile}
							onChange={handleSelectTrafficMultiple}
							sx={{
								"& option:checked": {
									color: "text.main",
									backgroundColor: "primary.dark",
								},
							}}
						>
							{trafficVehicleProfile.map((item, id) => (
								<option key={id} value={item.name} style={{ padding: "6px" }}>
									{item.name}
								</option>
							))}
						</Select>
					</FormControl> */}
				</Box>
				
				<Box p={2} display="flex" justifyContent="center">
					<Button
						sx={{
							backgroundColor: "primary.main",
							"&:hover": {
								backgroundColor: "primary.main",
							},
							color: "text.main",
							width: "200px",
						}}
						disabled={false}
						type="submit"
						onClick={onSaveData}
					>
						Save
					</Button>
				</Box>
			</Grid>
		</Fragment>
	);
}

export default connect(null, { ReduxUpdateAutoScenarioJourney })(DynamicActorForm);