import { ArticleRounded } from "@mui/icons-material";
import { Grid, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { IconButtonX, LinkButtonX } from "components";
import ButtonX from "components/buttonX";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { AUTHENTICATED_ROUTES } from "router/constants";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { mapTypes } from "utils";
import { DEFAULT_LINE_LAYER, DEFAULT_POINT_LAYER, DEFAULT_POLYGON_LAYER } from "constants/defaults";
import { connect } from "react-redux";
import { ReduxUpdateMapValidatorJourney } from "services/redux-store/actions";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "xyz"

function mapStateToProps(state) {
	return {
		MapValidator: state.MapValidator
	}
}

export default connect(mapStateToProps)(function OddValidatorBasedSelectedMap({ MapValidator }) {
    const mapContainer = useRef(null);
	const map = useRef(null);

    const navigate = useNavigate();
	const location = useLocation();
	const param = useParams();

	const [oddData, setOddData] = useState(null);
	const [lng, setLng] = useState(7.42446);//7.42446, 51.580984
	const [lat, setLat] = useState(51.580984);
	const [zoom, setZoom] = useState(15);

	useEffect(() => {
		/**
		 * Read the searched_odd_on_route.geojson file or response
		 */
		if (!map.current) {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: mapTypes.streetView,
				center: [lng, lat],
				zoom: zoom,
			});
			map.current.on("load", () => {
				map.current.on("move", handleOnMapMove);
				// map.current.on("mousemove", "route", handleOnMouseMove);
				// map.current.on("click", "state-fills", handleOnClickPolygon);
				fetch(MapValidator.odd_geojson)
					.then((response) => response.json())
					.then((json) => {
						const data = json.features;
						console.log('json', json)
						const selectedOdd = data.filter((item) => item.id === MapValidator.oddId)[0];
						console.log('selectedOdd', selectedOdd)
						setOddData(selectedOdd);
						if (["LineString"].includes(selectedOdd.geometry.type)) {
							const cords = selectedOdd.geometry.coordinates[0];
							console.log("ls cords", cords);
							setLng(cords[0]);
							setLat(cords[1]);
							map.current.setCenter(cords);
						} else if (["MultiLineString"].includes(selectedOdd.geometry.type)) {
							const cords = selectedOdd.geometry.coordinates[0][0];
							console.log("mls cords", cords);
							setLng(cords[0]);
							setLat(cords[1]);
							map.current.setCenter(cords);
						} else {
							const cords = selectedOdd.geometry.coordinates[0][0];
							console.log("poly cords", cords);
							setLng(cords[0]);
							setLat(cords[1]);
							map.current.setCenter(cords);
						}

						map.current.addSource("route", {
							type: "geojson",
							data: {
								type: "FeatureCollection",
								features: [selectedOdd],
							},
						});
						map.current.addLayer(DEFAULT_POLYGON_LAYER("route-polygon", "route"));
						map.current.addLayer(DEFAULT_LINE_LAYER("route-line", "route"));
						map.current.addLayer(DEFAULT_POINT_LAYER("route-point", "route"));
					});
			}); 
		}
		
		
	}, [])

	const handleOnMapMove = (e) => {
		setLng(map.current.getCenter().lng.toFixed(4));
		setLat(map.current.getCenter().lat.toFixed(4));
		setZoom(map.current.getZoom().toFixed(2));
	};

	const onClickNext = () => {
		navigate(`/projects/${param.id}/applications/map-validator/odd-view-validator-map`, {
			// state: {
			// 	isGeojsonAvailable: false,
			// 	geojson: {
			// 		type: "FeatureCollection",
			// 		features: [oddData],
			// 	},
			// 	mapId: location.state.mapId,
			// 	gId: oddData.properties.id
			// },
		});
	}

	return (
		<div className={`container-fuild`}>
            <div className={`row`}>
				<div className={`col-12 d-flex justify-content-between`}>
					<div className={`d-flex`}>
					</div>
					<div className={`d-flex`}>
						<ButtonX onClick={() => navigate(-1)} disabled={false}>Back</ButtonX>
						<ButtonX className={`ms-2`} onClick={onClickNext}>
							Next
						</ButtonX>
					</div>
				</div>
			</div>
			<div className={`map-container mt-3`}>
				{/* <div className="sidebar">
					Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
				</div> */}
				<div ref={mapContainer} className="map-view" />
			</div>
		</div>
	);
}
)