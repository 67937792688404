import { AlertX, ButtonWithIcon, ButtonX, ImageButton, ModalX, ProgressX, ReadOnlyTag, Typos } from "components";
import React, { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import "./logicalScenerioParams.css";
import TEST_VEHICLE from "assets/images/mainVehicle.png";
import { CloudDownloadRounded, CloudUploadRounded, HorizontalRuleRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded } from "@mui/icons-material";
import { Autocomplete, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Slider, TextField } from "@mui/material";
import { getScenarioData } from "../../mainState";
import { GetInfoFromLocalStorage, PresentableText } from "utils";
import { getAllTestVehicleProjects } from "endpointServices/testVehicleService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReduxUpdateMyScenarioJourney } from "services/redux-store/actions/actions-myscenario";
import { connect } from "react-redux";
import PEDESTRIAN from "assets/images/walking-person.gif";
import STORE from "services/redux-store/store";
import _ from "lodash";
import { DEFAULT_PEDESTRIAN_SPEED, DEFAULT_VEHICLE_SPEED } from "constants/defaults";
import { httpGet } from "utils/httpMethods";
import { GET_VEHICLE_ATTACHEMENT } from "constants/endpoints";
import { AUTHENTICATED_ROUTES } from "router/constants";
import ConfirmationModal from "components/confirmationModal";

const LogicalScenerioParams = ({ ReduxUpdateMyScenarioJourney }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const param = useParams();
	// const { actors, ego, url } = location.state;
	const { MyScenario: MyScenarioReduxData } = STORE.getState();
	const jsonLookupTable = location.state.polygonsLookupTable;

	const [speed, setSpeed] = useState({
		min: 90,
		max: 100,
		step: 5,
	});

	const [scenarioData, setScenarioData] = useState(null);
	const [selectedObjectVehicle, setSelectedObjectVehicle] = useState(null);
	const [selectedObject, setSelectedObject] = useState(null);
	const [selectedObjectIndex, setSelectedObjectIndex] = useState(null);
	const [vehicleAttachementsForActors, setVehicleAttachementsForActors] = useState(null);
	const [loadingActorAttachments, setLoadingActorAttachments] = useState(false);
	

	const [vehicleList, setVehicleList] = useState([]);
	const [minDistance, setMinDistance] = useState(5);
	const [maxDistance, setMaxDistance] = useState(30);
	
	

	const [anchorDeveloperOptions, setAnchorDeveloperOptions] = useState(false);
	const openDeveloperOptions = Boolean(anchorDeveloperOptions);
	const [customScenarioModalVisibility, setCustomScenarioModalVisibility] = useState(false);
	const [customGeojsonFile, setCustomGeojsonFile] = useState(null);
	const [invalidJsonError, setInvalidJsonError] = useState({ show: false, message: "" });
	const [plausibilityRadius, setPlausibilityRadius] = useState(5);
	const [readonlyMode, setReadonlyMode] = useState(false);
	const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
	
	

	const ego = MyScenarioReduxData.routeConfiguration.ego;
	const actors = MyScenarioReduxData.routeConfiguration.actors;
	const pedestrians = MyScenarioReduxData.routeConfiguration.pedestrians; //edit this to trai;
	const staticObjects = MyScenarioReduxData.routeConfiguration.staticObjects;
	const url = MyScenarioReduxData.routeConfiguration.url;

	useEffect(() => {
		if (MyScenarioReduxData.readonly) {
			setReadonlyMode(true);
			setScenarioData({...MyScenarioReduxData.prevUserScenario, pedestrianData: MyScenarioReduxData.prevUserScenario.Pedestrians })
			setPlausibilityRadius(MyScenarioReduxData.prevUserScenario.plausibility?.radius)
		}
		else {
			loadReceviedData(url);
		}
	}, [MyScenarioReduxData.readonly]);

	const getAllList = async () => {
		const projectId = GetInfoFromLocalStorage("p_id");
		const response = await getAllTestVehicleProjects(parseInt(projectId));
		if (response?.status === 200) {
			const data = response?.data?.data?.test_vehicles || [];
			setVehicleList(data);
		} else {
			const err = response?.message || "Failed to get request";
			toast.error(`${err}. Please try again later`);
			setVehicleList([]);
		}
	};

	useEffect(() => {
		getAllList();
	}, []);

	const loadReceviedData = (geojsonUrl) => {
		try {
			const vut = {};
			fetch(geojsonUrl)
				.then((resp) => resp.json())
				.then((json) => {
					// ---------------------------------------------------- VUT ------------------------------------------------------
					vut.spawn_point = {
						position: [ego?.initialPosition.lat, ego?.initialPosition.lng],
						lane_id: ego?.route[0].properties.lane_id,
						road_id: ego?.route[0].properties.road_id,
					};
					vut.end_point = {
						position: [ego?.route[ego?.route?.length - 1].location.lat, ego?.route[ego?.route?.length - 1].location.lng],
						lane_id: ego?.route[ego?.route?.length - 1].properties.lane_id,
						road_id: ego?.route[ego?.route?.length - 1].properties.road_id,
					};
					const checkLaneChangeByEgo = ego?.markers.filter((item) => item.type === "lane-change");
					const laneChange = {};
					laneChange.activate = checkLaneChangeByEgo?.length > 0 ? true : false;
					if (laneChange.activate) {
						const polygon = json.features[jsonLookupTable[checkLaneChangeByEgo[0].polygon]];
						laneChange.position = [checkLaneChangeByEgo[0].lnglat.lat, checkLaneChangeByEgo[0].lnglat.lng];
						laneChange.lane_id = polygon.properties.lane_id;
						laneChange.road_id = polygon.properties.road_id;
						laneChange.direction = checkLaneChangeByEgo[0].additional.laneChangeDirection === "l2r" ? "right" : "left";
					}
					vut.lane_change = laneChange;
					const checkAccelerationByEgo = ego?.markers.filter((item) => item.type === "acceleration");
					const acceleration = {};
					acceleration.activate = checkAccelerationByEgo?.length > 0 ? true : false;
					if (acceleration.activate) {
						const polygon = json.features[jsonLookupTable[checkAccelerationByEgo[0].polygon]];
						acceleration.position = [checkAccelerationByEgo[0].lnglat.lat, checkAccelerationByEgo[0].lnglat.lng];
						acceleration.lane_id = polygon.properties.lane_id;
						acceleration.road_id = polygon.properties.road_id;
						acceleration.desired = checkAccelerationByEgo[0].additional.acceleration;
					}
					vut.acceleration = acceleration;
					vut.speed = _.cloneDeep(DEFAULT_VEHICLE_SPEED);
					vut.lane_route = ego?.route.map((item) => [item.properties.road_id, item.properties.lane_id]);
					vut.vehicle_profile = MyScenarioReduxData.testVehicle;

					// ----------------------------------------------------------- TRAFFIC PARTICIPANTS -----------------------------------------------
					const traffic_Participants = [];
					actors.forEach((actor) => {
						const traffic_Participant = {};
						traffic_Participant.spawn_point = {
							position: [actor?.initialPosition.lat, actor?.initialPosition.lng],
							lane_id: actor?.route[0].properties.lane_id,
							road_id: actor?.route[0].properties.road_id,
						};
						traffic_Participant.end_point = {
							position: [actor?.route[actor?.route?.length - 1].location.lat, actor?.route[actor?.route?.length - 1].location.lng],
							lane_id: actor?.route[actor?.route?.length - 1].properties.lane_id,
							road_id: actor?.route[actor?.route?.length - 1].properties.road_id,
						};
						const checkLaneChangeByActor = actor?.markers.filter((item) => item.type === "lane-change");
						const laneChange = {};
						laneChange.activate = checkLaneChangeByActor?.length > 0 ? true : false;
						if (laneChange.activate) {
							const polygon = json.features[jsonLookupTable[checkLaneChangeByActor[0].polygon]];
							laneChange.position = [checkLaneChangeByActor[0].lnglat.lat, checkLaneChangeByActor[0].lnglat.lng];
							laneChange.lane_id = polygon.properties.lane_id;
							laneChange.road_id = polygon.properties.road_id;
							laneChange.direction = checkLaneChangeByActor[0].additional.laneChangeDirection === "l2r" ? "right" : "left";
						}
						traffic_Participant.lane_change = laneChange;
						const checkAccelerationByActor = actor?.markers.filter((item) => item.type === "acceleration");
						const acceleration = {};
						acceleration.activate = checkAccelerationByActor?.length > 0 ? true : false;
						if (acceleration.activate) {
							const polygon = json.features[jsonLookupTable[checkAccelerationByActor[0].polygon]];
							acceleration.position = [checkAccelerationByActor[0].lnglat.lat, checkAccelerationByActor[0].lnglat.lng];
							acceleration.lane_id = polygon.properties.lane_id;
							acceleration.road_id = polygon.properties.road_id;
							acceleration.desired = checkAccelerationByActor[0].additional.acceleration;
						}
						traffic_Participant.acceleration = acceleration;
						traffic_Participant.speed = _.cloneDeep(DEFAULT_VEHICLE_SPEED);
						traffic_Participant.lane_route = actor?.route.map((item) => [item.properties.road_id, item.properties.lane_id]);
						traffic_Participant.vehicle_profile = actor?.vehicle.value;
						traffic_Participants.push({ ...traffic_Participant });
					});
					// ----------------------------------------------------------- PEDESTRIANS -----------------------------------------------

					const pedestrianData = [];
					pedestrians.forEach((element) => {
						pedestrianData.push({
							...element,
							// spawn_point: { ...element.spawn_point, position: element.spawn_point.position.reverse() },
							// end_point: { ...element.end_point, position: element.end_point.position.reverse() },
							speed: _.cloneDeep(DEFAULT_PEDESTRIAN_SPEED),
						});
					});

					setScenarioData({
						vut,
						traffic_Participants,
						pedestrianData,
					});
					getScenarioData({
						vut,
						traffic_Participants,
					});

					if (MyScenarioReduxData?.routeConfiguration?.plausibility) { //if there is plausibility that means user has come back from a screen after this one
						setPlausibilityRadius(MyScenarioReduxData?.routeConfiguration?.plausibility?.radius);
						console.log('MyScenarioReduxData?.routeConfiguration', MyScenarioReduxData?.routeConfiguration)
						setScenarioData({
							vut: MyScenarioReduxData?.routeConfiguration?.vut,
							traffic_Participants: MyScenarioReduxData?.routeConfiguration?.trafficParticipants,
							pedestrianData: MyScenarioReduxData?.routeConfiguration?.pedestrians,
							plausibility: {
								radius: MyScenarioReduxData?.routeConfiguration?.plausibility,
								override_api: true
							}
						})
					}
				});
		} catch (err) {
			console.log("err", err);
		}
	};

	const onChangeTexField = (e, newValue, activePin) => {
		const scenario = _.cloneDeep(scenarioData);
	  
		if (!Array.isArray(newValue)) {
		  return;
		}
	  
		// Ensure newValue[0] <= newValue[1] to maintain a valid range
		const [min, max] = newValue.sort((a, b) => a - b);
		// console.log('min, max', min, minDistance, max, maxDistance);
	  
		const distance = max - min;
		// Validate against minDistance and maxDistance (assuming maxDistance is defined elsewhere)
		if (distance < minDistance || distance > maxDistance) {
		  console.warn('Invalid range: Selected range exceeds allowed limits.');
		  return;
		}
	  
		if (max - min < minDistance) {
		  console.warn('Invalid range: Minimum distance between pins cannot be less than minDistance.');
		  return;
		}
	  
		setSpeed({ ...speed, min, max });
	  
		if (selectedObject === "vut") {
		  scenario[selectedObject].speed.range[0] = min;
		  scenario[selectedObject].speed.range[1] = max;
		} else if (selectedObject === "actor") {
		  scenario["traffic_Participants"][selectedObjectIndex].speed.range[0] = min;
		  scenario["traffic_Participants"][selectedObjectIndex].speed.range[1] = max;
		} else if (selectedObject === "pedestrian") {
		  scenario["pedestrianData"][selectedObjectIndex].speed.range[0] = min;
		  scenario["pedestrianData"][selectedObjectIndex].speed.range[1] = max;
		}
	  
		setScenarioData(scenario);
	  };

	const onChangeStepSizeSlider = (e) => {
		const scenario = _.cloneDeep(scenarioData);
		if (selectedObject === "vut") {
			scenario[selectedObject].speed.step_size = e.target.value;
			setSpeed({ ...speed, step: e.target.value });
		} else if (selectedObject === "actor") {
			scenario["traffic_Participants"][selectedObjectIndex].speed.step_size = e.target.value;
			setSpeed({ ...speed, step: e.target.value });
		} else if (selectedObject === "pedestrian") {
			scenario["pedestrianData"][selectedObjectIndex].speed.step_size = e.target.value;
			setSpeed({ ...speed, step: e.target.value });
		}
		setScenarioData(scenario);
	};

	// console.log("scenarioData", scenarioData);
	const onClickNext = () => {
		// console.log("ego", ego);
		// console.log("actors", actors);
		// console.log("pedestrianData", pedestrianData);
		ReduxUpdateMyScenarioJourney({
			routeConfiguration: {
				...MyScenarioReduxData.routeConfiguration,
				vut: scenarioData.vut,
				trafficParticipants: scenarioData.traffic_Participants,
				pedestrians: scenarioData.pedestrianData,
				plausibility: {
					radius: plausibilityRadius,
					override_api: true
				}
			},
		});
		getScenarioData({
			vut: scenarioData.vut,
			traffic_Participants: scenarioData.traffic_Participants,
		});
		navigate(AUTHENTICATED_ROUTES.defineScenarioObserver
			.replace(":id", param.id)
			.replace(":aid", param.aid)
			.replace(":mid", param.mid)
		);
	};

	const downloadJsonAsFile = () => {
		const jsonData = {
			scenario: {
				vut: scenarioData.vut,
				traffic_Participants: scenarioData.traffic_Participants,
				Pedestrians: scenarioData.pedestrianData,
				plausibility: {
					radius: plausibilityRadius,
					override_api: true
				}
			},
		};
		// console.log('jsonData', jsonData);
		setAnchorDeveloperOptions(null);
		const jsonString = JSON.stringify(jsonData, null, 2);

		const fileBlob = new Blob([jsonString], { type: "application/json" });

		// Create a temporary anchor element
		const downloadLink = document.createElement("a");
		downloadLink.href = URL.createObjectURL(fileBlob);

		// Set the file name
		downloadLink.download = "data.json";

		// Append the anchor element to the DOM
		document.body.appendChild(downloadLink);

		// Simulate a click event to trigger the download
		downloadLink.click();

		// Clean up by removing the anchor element
		document.body.removeChild(downloadLink);
	};

	function getUniqueRecordsByAttachmentType(data) {
		return Object.values(
			data.reduce((uniqueRecords, item) => {
				const attachmentType = item.attachment_type;
				uniqueRecords[attachmentType] = uniqueRecords[attachmentType] || item;
				return uniqueRecords;
			}, {})
		);
	}

	function convertVehicleAttachementDeatils(attachment_type, attachment_detail) {
		// Extract values from the input object
		const { attachment_value, attachment_name, center_x, center_y, center_z, size_x, size_y, size_z, weight } = attachment_detail;

		// Construct the attachmentDetails object
		const attachmentDetails = {
			[attachment_value]: {
				name: attachment_name,
				center: {
					x: center_x,
					y: center_y,
					z: center_z,
				},
				size: {
					x: size_x,
					y: size_y,
					z: size_z,
				},
				weight: parseFloat(weight), // Ensure weight is a floating-point number
			},
		};

		// Construct the final output object
		const output = {
			name: attachment_type,
			colorBit: {
				0: "00000000000000000000",
				2000: "00000010000000000000",
			},
			attachmentDetails: attachmentDetails,
		};

		return output;
	}

	const onClickObject = async (type, index = null) => {
		setSelectedObject(type);
		setSelectedObjectIndex(index);
		if (type === "vut") {
			// ego car
			setSpeed({
				min: scenarioData.vut.speed.range[0],
				max: scenarioData.vut.speed.range[1],
				step: scenarioData.vut.speed.step_size,
			});
			setSelectedObjectVehicle({ 
				...scenarioData.vut.vehicle_profile, 
				stepSizeMin: 5, 
				stepSizeMax: 30, 
				stepSizeAllowed: 5 
			});
			setMinDistance(5);
			setMaxDistance(30);
		} else if (type === "actor") {
			setLoadingActorAttachments(true);
			// console.log("actor scenarioData", scenarioData["traffic_Participants"][index]);
			const vehicleAttachements = await httpGet({
				url: `${GET_VEHICLE_ATTACHEMENT}?vehicle_profile_id=${scenarioData["traffic_Participants"][index].vehicle_profile.id}`,
			});
			// console.log("vehicleAttachements", vehicleAttachements);
			const uniqueRecords = getUniqueRecordsByAttachmentType(vehicleAttachements.data.data);
			// console.log("uniqueRecords", uniqueRecords);
			setVehicleAttachementsForActors(uniqueRecords);
			setSpeed({
				min: scenarioData["traffic_Participants"][index].speed.range[0],
				max: scenarioData["traffic_Participants"][index].speed.range[1],
				step: scenarioData["traffic_Participants"][index].speed.step_size,
			});
			setSelectedObjectVehicle({
				...scenarioData["traffic_Participants"][index].vehicle_profile, 
				stepSizeMin: 5, 
				stepSizeMax: 30, 
				stepSizeAllowed: 5
			})
			setMinDistance(5);
			setMaxDistance(30);
			setLoadingActorAttachments(false);
		} else if (type === "pedestrian") {
			setSpeed({
				min: scenarioData["pedestrianData"][index].speed.range[0],
				max: scenarioData["pedestrianData"][index].speed.range[1],
				step: scenarioData["pedestrianData"][index].speed.step_size,
			});
			setSelectedObjectVehicle({
				top_speed: 20,
				stepSizeMin: 1, 
				stepSizeMax: 5,
				stepSizeAllowed: 1,
			})
			setMinDistance(1);
			setMaxDistance(5);
		}
		// console.log("scenarioData", scenarioData);
		// setSpeed({ min: 50, max: 100, step: 5 });
	};

	const showInvalidJsonError = (error) => {
		setInvalidJsonError({ show: true, message: error });
		setTimeout(() => {
			setInvalidJsonError({ show: false, message: "" });
		}, 5000);
	};

	const onClickLoadCustomScenarioFile = async () => {
		if (!customGeojsonFile) {
			showInvalidJsonError("No JSON File selected!");
			return;
		}

		try {
			const response = await fetch(URL.createObjectURL(customGeojsonFile));
			const content = await response.text();
			const json = JSON.parse(content);
			setScenarioData(json.scenario);
			setCustomScenarioModalVisibility(false);
			setSelectedObject(null);
			setSelectedObjectIndex(null);
		} catch (error) {
			showInvalidJsonError(error.toString());
		}
	};

	const onChangeVehicleAttachementForActors = (e, id, val) => {
		// console.log("e, val", e, id, val);
		// const id = e.target.id.replace(/-option-\d+/, '')
		const scenario = { ...scenarioData };
		if (val !== null) {
			// console.log("convertVehicleAttachementDeatils", convertVehicleAttachementDeatils(e.target.id.replace(/-option-\d+/, ""), val));
			if (scenario["traffic_Participants"][selectedObjectIndex]["vehicle_profile"]["vehicle_attachments"]) {
				// console.log("attachement key exists");
				const index = scenario["traffic_Participants"][selectedObjectIndex]["vehicle_profile"]["vehicle_attachments"].findIndex((item) => item.name === id);
				if (index !== -1) { // [type] (id) was already selected
					// replace the element
					scenario["traffic_Participants"][selectedObjectIndex]["vehicle_profile"]["vehicle_attachments"][index] = convertVehicleAttachementDeatils(id, val);
				} else {
					// console.log("new element to add in existing attachements");
					// attachement does not exist so adding one
					scenario["traffic_Participants"][selectedObjectIndex]["vehicle_profile"]["vehicle_attachments"].push(convertVehicleAttachementDeatils(id, val));
				}
			}
			if (!scenario["traffic_Participants"][selectedObjectIndex]["vehicle_profile"]["vehicle_attachments"]) {
				// if not exists, add a new element
				scenario["traffic_Participants"][selectedObjectIndex]["vehicle_profile"] = {
					...scenario["traffic_Participants"][selectedObjectIndex]["vehicle_profile"],
					vehicle_attachments: [convertVehicleAttachementDeatils(id, val)],
				};
			}
			// console.log("scenario", scenario);
			setScenarioData(scenario);
		} else {
			// delete the attachement
			// console.log("id", id);
			const index = scenario["traffic_Participants"][selectedObjectIndex]["vehicle_profile"]["vehicle_attachments"].findIndex((item) => item.name === id);
			if (index !== -1) {
				const tempAttachements = scenario["traffic_Participants"][selectedObjectIndex]["vehicle_profile"]["vehicle_attachments"];
				tempAttachements.splice(index, 1);
				scenario["traffic_Participants"][selectedObjectIndex]["vehicle_profile"]["vehicle_attachments"] = tempAttachements;
				// console.log("scenario", scenario);
				setScenarioData(scenario);
			} else {
				console.log("error, nothing to delete");
			}
		}
	};

	const getVehicleAttachementDropDownSelectedValue = (fetchedAttachements, type) => {
		// console.log('selectedObjectIndex', selectedObjectIndex);
		if (selectedObjectIndex === null) return null;
		const attachements = scenarioData.traffic_Participants[selectedObjectIndex]["vehicle_profile"]["vehicle_attachments"];
		// console.log('attachements', attachements)
		if (!attachements) {
			return null;
		}
		else {
			// Find the object in the second array based on attachment_type
			const attachmentObject = attachements.find(obj => obj.name === type);
			// console.log('attachmentObject', attachmentObject)

			if (attachmentObject) {
				// Extract the attachment_value from the attachmentDetails object
				const attachmentValue = Object.keys(attachmentObject.attachmentDetails)[0];
				const fetchedAttachementsObject = fetchedAttachements.find(obj => obj.attachment_value === attachmentValue)
				if (fetchedAttachementsObject){
					return fetchedAttachementsObject;
				}
				// console.log('attachmentValue', attachmentValue, fetchedAttachements, fetchedAttachementsObject);
			}

			return null; // Return null if the attachment type is not found in the second array
		}
	}
	
	const onClickBack = () => {
		if (MyScenarioReduxData.readonly) {
			navigate(-1)
		}
		else {
			if (openConfirmationModal) {
				navigate(-1);
			}
			else {
				setOpenConfirmationModal(true);
			}
		}
	}
	// console.log('selectedObjectVehicle', selectedObjectVehicle)
	// https://temp-replicar-assets.s3.eu-central-1.amazonaws.com/lane_polygons_c51dc180-5b3d-43bf-be54-82fce4b8b53c.geojson
	return (
		<>
			<ConfirmationModal 
				open={openConfirmationModal}
				heading={<span>Confirm Action</span>}
				subheading={<span>Going back will discard unsaved changes</span>}
				close={() => setOpenConfirmationModal(false)}
				confirmationMessage={
					<span>
						Are you sure you want to go back? If you go back, your changes on this screen might
						be lost.
					</span>
				}
				onConfirm={onClickBack}
			/>
			<div className={`container-fluid`}>
				<div className={`row`}>
					<div className={`col-12 d-flex justify-content-between align-items-center`}>
						<div className={`d-flex`}>
							{/* <div>
								<ButtonWithIcon
									variant={"outlined"}
									size={"small"}
									icon={openDeveloperOptions ? <KeyboardArrowUpRounded /> : <KeyboardArrowDownRounded />}
									onClick={(e) => setAnchorDeveloperOptions(e.currentTarget)}
								>
									Developer Options
								</ButtonWithIcon>
								<Menu anchorEl={anchorDeveloperOptions} open={openDeveloperOptions} onClose={() => setAnchorDeveloperOptions(null)}>
									<MenuItem onClick={downloadJsonAsFile}>
										<ListItemIcon>
											<CloudDownloadRounded />
										</ListItemIcon>
										<ListItemText>Download scenario in JSON file</ListItemText>
									</MenuItem>
									<Divider />
									<MenuItem
										disabled={readonlyMode}
										onClick={() => {
											setCustomScenarioModalVisibility(true);
											setAnchorDeveloperOptions(null);
										}}
									>
										<ListItemIcon>
											<CloudUploadRounded />
										</ListItemIcon>
										<ListItemText>Load a custom scenario</ListItemText>
									</MenuItem>
								</Menu>
							</div> */}
							<div className={`ms-2`}>
								{
									readonlyMode && <ReadOnlyTag />
								}

							</div>
						</div>
						<div className={`d-flex`}>
							<ButtonX onClick={onClickBack} variant={"outlined"} >
								Back
							</ButtonX>
							<ButtonX className={`ms-2`} onClick={onClickNext}>
								Next
							</ButtonX>
						</div>
					</div>
				</div>
				<hr />
				<div className={`row`}>
					<div className={`col-lg-7 col-md-12 col-sm-12`}>
						<div className={`logical-scenerio-background p-2`}>
							<Typos.H6>Test Vehicle Logical Parameters</Typos.H6>
							<div className={`logical-scenerio-portion border border-primary`}>
								<ImageButton onClick={(e) => onClickObject(`vut`)} src={TEST_VEHICLE} title={"EGO"} size={170} active={selectedObject === "vut"} />
							</div>
							<Typos.H6 className={`mt-3`}>Dynamic Actors Logical Parameters</Typos.H6>
							<div className={`row logical-scenerio-portion border border-primary`}>
								{scenarioData?.traffic_Participants?.map((item, i) => {
									return (
										<div className={`col-lg-4 col-md-6 col-sm-12`} key={i}>
											<ImageButton
												onClick={(e) => onClickObject("actor", i)}
												src={TEST_VEHICLE}
												title={`Actor ${i + 1}`}
												size={110}
												active={selectedObject === "actor" && selectedObjectIndex === i}
											/>
										</div>
									);
								})}
							</div>
							<Typos.H6 className={`mt-3`}>Pedestrian Logical Parameters</Typos.H6>
							<div className={`row logical-scenerio-portion border border-primary`}>
								{scenarioData?.pedestrianData?.map((item, i) => {
									return (
										<div className={`col-lg-3 col-md-4 col-sm-4`} key={i}>
											<ImageButton
												onClick={(e) => onClickObject("pedestrian", i)}
												src={PEDESTRIAN}
												title={`P${i + 1}`}
												height={100}
												width={100}
												active={selectedObject === "pedestrian" && selectedObjectIndex === i}
											/>
										</div>
									);
								})}
							</div>
						</div>
						<button className={process.env.NODE_ENV === "production" ? `d-none` : ""} onClick={() => console.log("scenario data", scenarioData)}>
							Scenraio Data
						</button>
					</div>

					<div className={`col-lg-5 col-md-12 col-sm-12`}>
						<div className="logical-scenerio-background py-2 px-3">
							<Typos.H6>Logical Parameters & Decision Space</Typos.H6>
							<hr />
							<div className={``}>
								<div className={`d-flex justify-content-between`}>
								<Typos.Body1 className="fw-bold">Plausibility Radius</Typos.Body1>
								<Typos.Body1 className="fw-bold">{plausibilityRadius}</Typos.Body1>
								</div>
								<Slider 
									min={1}
									max={20}
									step={0.5}
									disabled={readonlyMode}
									value={plausibilityRadius}
									onChange={(e, val) => setPlausibilityRadius(val)}
									marks
									valueLabelDisplay={"auto"}
								/>
							</div>
							<hr />
							{selectedObject !== null ? (
								<Fragment>
									<div className={`row`}>
										<div className={`col-lg-12`}>
											<div className={`d-flex w-100 align-items-center`}>
												<Typos.Body1 className={`fw-bold me-1`}>Speed</Typos.Body1>
												{
													loadingActorAttachments ? <ProgressX block={true} width={"100%"} /> :
														<Typos.Caption color="gray">
															({speed.min}
															<HorizontalRuleRounded className="mx-1" />
															{speed.max})
														</Typos.Caption>
												}
											</div>
										</div>
									</div>
									<div className={``}>
										{
											loadingActorAttachments ? <ProgressX block={true} width={"100%"} height={43} /> : (
											selectedObjectVehicle &&
											<Slider
												value={[speed.min, speed.max]}
												onChange={(e, newValue, activePin) => onChangeTexField(e, newValue, activePin)}
												valueLabelDisplay={speed.min < Math.ceil((selectedObjectVehicle.top_speed || 300) / 3) ? "auto" : "on"}
												disableSwap
												disabled={readonlyMode}
												max={selectedObjectVehicle.top_speed}
												min={1}
												step={selectedObjectVehicle.stepSizeAllowed}
												marks={[
													{
														value: 1,
														label: 1,
													},
													{
														value: Math.ceil(selectedObjectVehicle.top_speed / 2),
														label: "Speed Range (Km/Hr)",
													},
													{
														value: selectedObjectVehicle.top_speed,
														label: `${selectedObjectVehicle.top_speed}`,
													},
												]}
											/>)
										}
									</div>
									<hr />
									<div className={`row mt-2 align-items-center`}>
										<div className={`col-lg-6 col-md-4 col-sm-12 d-flex`}>
											<HorizontalRuleRounded className="me-2" />
											<Typos.Body1>Step Size</Typos.Body1>
										</div>
										<div className="col-lg-6 col-md-8 col-sm-12">
											{
												loadingActorAttachments ? <ProgressX block={true} width={"100%"} height={33} /> : (
												selectedObjectVehicle &&
												<Slider
													type="range"
													id="stepSizeTextField"
													valueLabelDisplay="auto"
													marks
													disabled={readonlyMode}
													value={speed.step}
													onChange={(e) => onChangeStepSizeSlider({ target: { id: "stepSizeTextField", value: e.target.value } })}
													max={selectedObjectVehicle.stepSizeMax}
													min={selectedObjectVehicle.stepSizeMin}
													step={selectedObjectVehicle.stepSizeAllowed}
												/>)
											}
										</div>
									</div>
									<hr />
									{
										loadingActorAttachments ? 
											<div className={``}>
												<div className={`mt-1`}><ProgressX block={true} width={"100%"} height={38} /></div>
												<div className={`mt-1`}><ProgressX block={true} width={"100%"} height={38} /></div>
												<div className={`mt-1`}><ProgressX block={true} width={"100%"} height={38} /></div>
												<div className={`mt-1`}><ProgressX block={true} width={"100%"} height={38} /></div>
											</div>
										: (
											selectedObject === "actor" &&
											vehicleAttachementsForActors &&
											vehicleAttachementsForActors.map((item, i) => {
												return (
													<div key={i} className={`row mt-2`}>
														<div className="col-lg-12 col-md-12 col-sm-12">
															<Autocomplete
																disablePortal
																id={item.attachment_type}
																size="small"
																disabled={readonlyMode}
																getOptionLabel={(option) => PresentableText(option.attachment_name)}
																options={item.attachment_details}
																onChange={(e, newval) => onChangeVehicleAttachementForActors(e, item.attachment_type, newval)}
																value={getVehicleAttachementDropDownSelectedValue(item.attachment_details, item.attachment_type)}
																PaperComponent={"div"}
																renderInput={(params) => <TextField {...params} label={PresentableText(item.attachment_type)} />}
															/>
														</div>
													</div>
												);
											}))}
								</Fragment>
							) : (
								<AlertX text={"Select any Vehicle or Pedestrian to edit its parameters."} />
							)}
						</div>
					</div>
				</div>

				{/* <PedestrianParameter setPerdistranData={getPedestrianData}  /> */}

				<ModalX
					open={customScenarioModalVisibility}
					close={() => setCustomScenarioModalVisibility(false)}
					heading={"Custom Scenario"}
					subheading={<Typos.Caption color="error">Note that you if you load a custom scenario you will loose the existing made scenario</Typos.Caption>}
				>
					{/* <TextField 
					label="GEOJSON file URL"
					helperText="Add your GEOJSON file URL here on which the scenario will be made"
					fullWidth
					type="url"
					value={customGeojsonFileURL}
					onChange={(e) => setCustomGeojsonFileURL(e.target.value)}
				/> */}
					<div className="mt-3">
						<Typos.Body1>JSON file to upload</Typos.Body1>
						<TextField
							className="mt-1"
							helperText="Choose your JSON scenario file"
							type="file"
							hiddenLabel
							variant="filled"
							onChange={(e) => setCustomGeojsonFile(e.target.files[0])}
						/>
					</div>
					<hr />
					{invalidJsonError.show && <Typos.Caption color="error">{invalidJsonError.message}</Typos.Caption>}
					<div className="d-flex justify-content-end">
						<ButtonX onClick={() => setCustomScenarioModalVisibility(false)} variant={"outlined"}>
							Cancel
						</ButtonX>
						<ButtonX className={`ms-2`} onClick={onClickLoadCustomScenarioFile}>
							Load
						</ButtonX>
					</div>
				</ModalX>
			</div>
		</>
	);
};
export default connect(null, { ReduxUpdateMyScenarioJourney })(LogicalScenerioParams);
