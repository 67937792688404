import {
  Button,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import AuthWrapper from "components/authWrapper";

export default function ResetPassword() {
  const [state, setState] = useState({
    password: "admin",
    confirmPassword: "admin",
  });
  const [loading, setLoading] = useState(false);
  const [error, set_error] = useState("");

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    // const { email, password } = e.target.elements;
    const payload = {
      password: state.password,
      confirmPassword: state.confirmPassword,
    };
    console.log("payload", payload);
    try {
      // await signinByEmailPassword(email.value, password.value);
      setLoading(false);
    } catch (e) {
      set_error(e.code);
      setLoading(false);
    }
  };

  return (
    <AuthWrapper>
      <CardHeader
        title="Create a new password"
        subheader="Something unique that only you can remember"
        titleTypographyProps={{ variant: "h5", color: "text.main" }}
        subheaderTypographyProps={{ variant: "caption", color: "text.main" }}
        sx={{ color: "text.main", padding: "5px", marginTop: "3em" }}
      />
      <CardContent>
        <form onSubmit={onSubmitLogin}>
          <div>
            <TextField
              name="password"
              required
              type="password"
              variant="outlined"
              label="Password"
              placeholder="Password"
              value={state.password}
              onChange={handleInputs}
              fullWidth
              disabled={loading}
            />
          </div>
          <div className="mt-3">
            <TextField
              name="confirmPassword"
              required
              type="password"
              variant="outlined"
              label="Password"
              placeholder="Reset Password"
              value={state.confirmPassword}
              onChange={handleInputs}
              fullWidth
              disabled={loading}
            />
          </div>
          <div className={`d-flex justify-content-center w-100 mt-3 mb-3`}>
            <Button
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                width: "431px",
              }}
              disabled={loading}
              type="submit"
            >
              Create Account
            </Button>
          </div>
        </form>
      </CardContent>
    </AuthWrapper>
  );
}
