import React from "react";
import { CardContent, Grid, TextField } from "@mui/material";

const style = {
  bgcolor: "secondary.dark",
};

export default function TraceableX({ children }) {
  return (
    <CardContent>
      <form>
        <Grid>
          {React.Children.map(children, (child) => (
            <Grid>
              <TextField
                sx={{ ...style, marginBottom: "1rem" }}
                label={child.props.label}
                placeholder={child.props.placeholder}
                variant="outlined"
                fullWidth
                multiline={child.props.label === "Description"}
                rows={child.props.label === "Description" ? 18 : undefined}
              />
            </Grid>
          ))}
        </Grid>
      </form>
    </CardContent>
  );
}
