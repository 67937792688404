import {
  Button,
  CardContent,
  CardHeader,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import AuthWrapper from "components/authWrapper";
import { AUTH_FORGOT_PASSWORD_SERVICE } from "endpointServices/authServices";
import { ToastContainer, toast } from 'react-toastify';

export default function ForgetPassword() {
  const [state, setState] = useState({
    email: "",
    // digitOne: "1",
    // digitTwo: "2",
    // digitThree: "3",
    // digitFour: "4",
  });
  const [loading, setLoading] = useState(false);
  const [error, set_error] = useState("");
  // const [isVerification, setIsVerification] = useState(false);

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      username: state.email,
    };
    console.log('payload', payload)
    const response = await AUTH_FORGOT_PASSWORD_SERVICE(payload)
    if (response.status === 200) {
      console.log('response', response)
      // setTimeout(() => {
      //   PageRedirectWithWindow('/auth')
      // }, 2000)
      setLoading(false);
    }
    else {
      const {message} = response.data
      toast(message || 'Network Error')
      console.log('error', response)
      setLoading(false);
    }
  
  };

  const emailForm = () => {
    return (
      <Fragment>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          theme="dark"
          type="info"
          hideProgressBar={true}
        />
        <CardHeader
          title=""
          subheader="Enter the e-mail address associated with your account and we will send you a code to reset your password"
          titleTypographyProps={{ variant: "h5", color: "text.main" }}
          subheaderTypographyProps={{
            variant: "caption",
            color: "text.main",
          }}
          sx={{ color: "text.main", padding: "5px", marginTop: "3em" }}
        />
        <CardContent>
          <form onSubmit={onSubmitLogin}>
            <div>
              <TextField
                name="email"
                required
                type="email"
                variant="outlined"
                label="Email"
                placeholder="Enter you email"
                value={state.email}
                onChange={handleInputs}
                fullWidth
                disabled={loading}
              />
            </div>

            <div className={`d-flex justify-content-center w-100 mt-3 mb-3`}>
              <Button
                sx={{
                  backgroundColor: "primary.main",
                  color: "white",
                  // width: "431px",
                  marginLeft:'auto'
                }}
                disabled={loading}
                type="submit"
              >
                Submit Email
              </Button>
            </div>
            <div className="mt-3 d-flex justify-content-end">
            <Typography
              sx={{ color: "text.main" }}
              variant="caption"
              display="block"
              gutterBottom
            >
              Don’t have an account?{" "}
              <b>
                {" "}
                <Link to="/registration" className="link">
                  Sign up
                </Link>
              </b>
            </Typography>
            </div>
          </form>
        </CardContent>
      </Fragment>
    );
  };

  // const codeVerificationForm = () => {
  //   return (
  //     <Fragment>
  //       <CardHeader
  //         title="Check your email for the 4 digit code"
  //         subheader=""
  //         titleTypographyProps={{ variant: "h5", color: "text.main" }}
  //         subheaderTypographyProps={{
  //           variant: "caption",
  //           color: "text.main",
  //         }}
  //         sx={{ color: "text.main", padding: "5px", marginTop: "3em" }}
  //       />
  //       <Typography
  //         sx={{ color: "text.main" }}
  //         variant="caption"
  //         display="block"
  //         gutterBottom
  //       >
  //         code.abr@gmail.com
  //         <b>
  //           {" "}
  //           <Link to="/registration" className="link">
  //             Change
  //           </Link>
  //         </b>
  //       </Typography>
  //       <CardContent>
  //         <form onSubmit={onSubmitLogin}>
  //           <div className="justify-content-center">
  //             <Grid container spacing={2}>
  //               <Grid item xs={2}></Grid>
  //               <Grid item xs={2}>
  //                 <TextField
  //                   name="digitOne"
  //                   required
  //                   type="text"
  //                   variant="filled"
  //                   label=""
  //                   placeholder=""
  //                   value={state.digitOne}
  //                   onChange={handleInputs}
  //                   fullWidth
  //                   disabled={loading}
  //                 />
  //               </Grid>
  //               <Grid item xs={2}>
  //                 <TextField
  //                   name="digitTwo"
  //                   required
  //                   type="text"
  //                   variant="filled"
  //                   label=""
  //                   placeholder=""
  //                   value={state.digitTwo}
  //                   onChange={handleInputs}
  //                   fullWidth
  //                   disabled={loading}
  //                 />
  //               </Grid>
  //               <Grid item xs={2}>
  //                 <TextField
  //                   name="digitThree"
  //                   required
  //                   type="text"
  //                   variant="filled"
  //                   label=""
  //                   placeholder=""
  //                   value={state.digitThree}
  //                   onChange={handleInputs}
  //                   fullWidth
  //                   disabled={loading}
  //                 />
  //               </Grid>
  //               <Grid item xs={2}>
  //                 <TextField
  //                   name="digitFour"
  //                   required
  //                   type="text"
  //                   variant="filled"
  //                   label=""
  //                   placeholder=""
  //                   value={state.digitFour}
  //                   onChange={handleInputs}
  //                   fullWidth
  //                   disabled={loading}
  //                 />
  //               </Grid>
  //               <Grid item xs={2}></Grid>
  //             </Grid>
  //           </div>

  //           <div className={`d-flex justify-content-center w-100 mt-3 mb-3`}>
  //             <Button
  //               sx={{
  //                 backgroundColor: "primary.main",
  //                 color: "white",
  //                 width: "431px",
  //               }}
  //               disabled={loading}
  //               type="submit"
  //             >
  //               Submit Code
  //             </Button>
  //           </div>
  //           <Typography
  //             sx={{ color: "text.main" }}
  //             variant="caption"
  //             display="block"
  //             gutterBottom
  //           >
  //             Didn’t Received the Code?{" "}
  //             <b>
  //               {" "}
  //               <Link to="/registration" className="link">
  //                 Send Again
  //               </Link>
  //             </b>
  //           </Typography>
  //         </form>
  //       </CardContent>
  //     </Fragment>
  //   );
  // };

  return (
    <AuthWrapper>
      {emailForm()}
    </AuthWrapper>
  );
}
