import { Box, Container, Typography } from '@mui/material';
import React from 'react';

const commonSpanStyles = {
  fontSize: "18px",
  fontWeight: "bold",
  marginRight: "10px",
  marginLeft: "17px",
};
export default function LicenseeText() {
  return (
    <Box className="modal-content mt-4">
      <section className="main-content">
        <Typography variant='body1' gutterBottom>
          THIS EULA COMPRISES A LEGAL AGREEMENT BETWEEN AUTOMOTIVE ARTIFICIAL
          INTELLIGENCE (AAI) GMBH (“AAI”) AND YOU INDIVIDUALLY, OR IF YOU ARE AUTHORIZED
          TO ACQUIRE AAI’S PRODUCTS, SOFTWARE OR ACCESS AND/OR USE OUR SERVICES
          (COLLECTIVELY, “SOFTWARE”) ON BEHALF OF YOUR COMPANY OR ANOTHER ORGANIZATION,
          THE ENTITY YOU REPRESENT, INCLUDING ITS ASSIGNEES, AFFILIATES, AGENTS,
          SUCCESSORS AND LEGAL REPRESENTATIVES (IN BOTH INSTANCES, “LICENSEE”). IF
          LICENSEE DOES NOT AGREE TO ALL OF THE TERMS OF THIS EULA, LICENSEE MUST NOT
          INSTALL OR USE THE SOFTWARE. BY INSTALLING OR USING THE SOFTWARE, LICENSEE
          AGREES TO COMPLY WITH AND BE BOUND BY THE TERMS OF THIS EULA.
        </Typography>
      </section>
      <section className="sub-headings">
        <Container>
          <Typography variant='body1' gutterBottom>
            1.
            <span style={commonSpanStyles}>DEFINITION.</span>
          </Typography>
          <Typography variant='body1' gutterBottom>
            2.
            <span style={commonSpanStyles}>LICENSOR.</span>
            AAI means Automotive Artificial Intelligence (AAI) GmbH, Franklinstr. 26B,
            10587, Berlin, Germany. AAI will retain all ownership of the SOFTWARE,
            including all updates, enhancements, modifications, and other changes AAI
            makes or adds to the SOFTWARE, and all intellectual property rights in it.
            The LICENSEE acknowledges such ownership and intellectual property rights and
            will not take any action to jeopardize, limit or interfere in any manner with
            AAI’s or its suppliers’/Licensors’ ownership of or rights with respect to the
            SOFTWARE.
          </Typography>
          <Typography variant='body1' gutterBottom>
            3.
            <span style={commonSpanStyles}>LICENSE GRANT.</span>
            Subject to clause 4 below, AAI hereby grants to LICENSEE a limited,
            non-exclusive, non-transferrable, revocable, non-sublicensable license to use
            the SOFTWARE. The LICENSEE may install and use the SOFTWARE for LICENSEE's
            business and commercial use. AAI reserves for itself all other rights and
            interest not explicitly granted under this End User License Agreement
            (“EULA”).
          </Typography>
          <Typography variant='body1' gutterBottom>
            4.
            <span style={commonSpanStyles}>EVALUATION USE.</span>
            If LICENSEE receives an evaluation license or development release of the
            SOFTWARE, LICENSEE may use the SOFTWARE only for determining the usability,
            desirability, functionality, and compatibility of the Software with
            Licensee’s applications and operations environment (the “EVALUATION PURPOSE”)
            and only during the applicable evaluation period. Any business or commercial
            use of the SOFTWARE is strictly prohibited.
          </Typography>
          <Typography variant='body1' gutterBottom>
            5.
            <span style={commonSpanStyles}>RESTRICTED USES.</span>
            LICENSEE may not (i) distribute, license, loan, or sell the SOFTWARE or other
            content contained or displayed in it, (ii) modify, alter, or create any
            derivative works of the SOFTWARE, (iii) reverse engineer, decompile, decode,
            decrypt, disassemble, or derive any source code from the SOFTWARE, (iv)
            remove, alter, or obscure any copyright, trademark, or other proprietary
            rights notice on or in the SOFTWARE, (v) publish any results of benchmark
            tests run on the SOFTWARE to a third party without AAI’s prior written
            consent, (vi) create a competitive product or service using the Software, or
            (vii) launch an automated program or script for the purpose of scraping,
            indexing, surveying, or otherwise data mining any portion of the Software.
          </Typography>
          <Typography variant='body1' gutterBottom>
            6.
            <span style={commonSpanStyles}>PERMITTED USERS.</span>
            LICENSEE agrees and shall ensure that only the agreed maximum number of
            permitted users shall use the SOFTWARE. LICENSEE may increase the number of
            permitted users from time to time by purchasing the requisite number of
            additional user accounts from AAI or any authorized reseller.
          </Typography>
          <Typography variant='body1' gutterBottom>
            7.
            <span style={commonSpanStyles}>THIRD PARTY SOFTWARE.</span>
            The Software may include source code and/or other files, libraries or
            components provided by third party vendors and/or open source projects
            ("Third Party Product"). Use of such Third Party Products is subject to
            license restrictions imposed by the copyright owner of such Third Party
            Product ("Third Party Vendor"). Licensee is solely responsible for ensuring
            full compliance with the license terms for such Third Party Products.
            Licensee should refer to the on-line documentation (if any) provided with
            Third Party Product for any license restrictions imposed by the Third Party
            Vendor. In any event, any license restrictions imposed by the Third Party
            Vendor are in addition to, not in lieu of, the terms and conditions of this
            EULA.
          </Typography>
          <Typography variant='body1' gutterBottom>
            8.
            <span style={commonSpanStyles}>UPDATES AND MAINTENANCE SERVICES.</span>
            Unless otherwise defined in the offer to which this EULA is an attachment,
            the maintenance period starts at the date AAI delivers the SOFTWARE to the
            LICENSEE (in case of subsequent deliveries the first delivery counts) and
            will last for the applicable maintenance period as stated in the offer. AAI
            will (i) provide LICENSEE with all updates, enhancement, modifications, and
            other changes AAI makes or adds to the SOFTWARE and which AAI in general
            offers to other licensees of the SOFTWARE, (ii) AAI will provide LICENSEE, at
            AAI's sole expense, with bug fixes and code corrections to correct SOFTWARE
            malfunctions and defects in order to bring the SOFTWARE into substantial
            conformity with its operating specifications, (iii) AAI may, but will not be
            required to, provide these maintenance services if LICENSEE has modified the
            SOFTWARE or is in default. After the initial maintenance period, LICENSEE may
            elect to renew or extend AAI's support services as stated in this Clause 7
            for such period and on such terms to be agreed in writing.
          </Typography>
          <Typography variant='body1' gutterBottom>
            9.
            <span style={commonSpanStyles}>LICENSE FEES.</span>
            LICENSEE shall pay AAI the license fee listed in the offer document to which
            this EULA is an attachment, in including all Taxes applicable to payments
            between the parties.
          </Typography>
          <Typography variant='body1' gutterBottom>
            10.
            <span style={commonSpanStyles}>TERMINATION.</span>
            AAI may terminate this agreement with immediate effect if LICENSEE breaches
            any part of this EULA, without obligation to re-pay already paid license
            fees, whether in full or in part. Upon termination, LICENSEE shall destroy
            all copies of the SOFTWARE.
          </Typography>

          <Typography variant='body1' gutterBottom>
            11.
            <span style={commonSpanStyles}>NO WARRANTY.</span>
            The SOFTWARE, including all Third Party Products, is provided strictly "as
            is," with all faults, defects, bugs, and errors. Unless otherwise listed in
            this EULA, (i) AAI does not make any warranty regarding the SOFTWARE and
            Third Party Products, which includes that (ii) AAI disclaims to the fullest
            extent authorized by Law any and all other warranties, whether express or
            implied, including any implied warranties of merchantability or fitness for a
            particular purpose.
          </Typography>
          <Typography variant='body1' gutterBottom>
            12.
            <span style={commonSpanStyles}>LIMITATION ON LIABILITY.</span>
            To the fullest extent authorized by Law, in no event will AAI be liable for
            any indirect, special, incidental or damages arising out of the use of or
            inability of the SOFTWARE, including, without limitation, damages for loss of
            goodwill, work stoppage, computer failure or malfunction, or any and all
            other commercial damages or losses, even if advised of the possibility
            thereof, and regardless of the legal or equitable theory (contract, tort or
            otherwise) upon which the claim is based. In addition, to the fullest extent
            allowed by Law, in no event will AAI’s total aggregate liability arising out
            of or in connection with these Terms whether in contract, tort (including
            negligence, product liability, or other theory), warranty, or otherwise,
            exceed the amount of greater of the license fee paid by Licensee, if any, in
            the preceding twelve (12) months.
          </Typography>
          <Typography variant='body1' gutterBottom>
            13.
            <span style={commonSpanStyles}>COLLECT AND USE OF DATA.</span>
            AAI may collect and use technical information gathered as part of its
            maintenance, support, and training services, only for the purpose of
            improving its products and services. AAI will not disclose this any of this
            information in a form that personally identifies the LICENSEE or LICENSEE's
            clients.
          </Typography>
          <Typography variant='body1' gutterBottom>
            14.
            <span style={commonSpanStyles}>GOVERNING LAW.</span>
            This agreement shall be governed, construed, and enforced in accordance with
            the laws of the Federal Republic of Germany. Any dispute arising between the
            parties shall be resolved exclusively by the courts in Munich/Germany.
          </Typography>
          <Typography variant='body1' gutterBottom>
            15.
            <span style={commonSpanStyles}>ACCEPTANCE.</span>
            By using and/or installing the SOFTWARE, LICENSEE accepts all terms and
            conditions of this EULA. If LICENSEE does not agree with the terms and
            conditions of this EULA, LICENSEE may not use the SOFTWARE.
          </Typography>
        </Container>
      </section>
    </Box>
  );
};

