import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { mapTypes } from 'utils';

export default function ScenAnalytics() {

  const mapContainer = useRef(null);
	const map = useRef(null);

  const [lng, setLng] = useState(7.42446); //7.42446, 51.580984
	const [lat, setLat] = useState(51.580984);
	const [zoom, setZoom] = useState(6);

  useEffect(() => {
		if (!map.current) {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: mapTypes.streetViewWithOdds,
				center: [lng, lat],
				zoom: zoom,
			});
			map.current.on("load", () => {
  			map.current.on("move", handleOnMapMove);
				// map.current.on("mousemove", "route", handleOnMouseMove);
				// map.current.on("click", "state-fills", handleOnClickPolygon);
			});
		}
	}, []);

  const handleOnMapMove = (e) => {
		setLng(map.current.getCenter().lng.toFixed(4));
		setLat(map.current.getCenter().lat.toFixed(4));
		setZoom(map.current.getZoom().toFixed(2));
	};

  return (
    <div className={``}>
			<div className={`map-container mt-3`}>
				<div className="sidebar">
					Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
				</div>
				<div ref={mapContainer} className="map-view" />
			</div>
		</div>
  )
}
{
	/* This is a URL that was redirecting
			https://public.tableau.com/views/AAIScenAnalytics/MainDashboard?:language=en-US&:display_count=n&:origin=viz_share_link 
			*/
}