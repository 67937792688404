import React, { useState } from "react";
import {
  CardContent,
  Grid,
  ListItemText,
  MenuItem,
  TextField,
} from "@mui/material";
import { SelectVersion, SelectPath, SelectTestRun, SelectModal } from "utils";
import { Button } from "@mui/base";

export default function CarMakerDetailsForm() {
  const [state, setState] = useState({
    projectName: "Car Maker Project Name: Toyota Corolla",
  });
  const [loading, setLoading] = useState(false);
  const [error, set_error] = useState("");

  const [selectVersion, setSelectVersion] = useState("");
  const [selectPath, setSelectPath] = useState("");
  const [selectTestRun, setSelectTestRun] = useState("");
  const [selectModal, setSelectModal] = useState("");

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectVersion = (e) => {
    const { value } = e.target;
    setSelectVersion(value);
  };

  const handleSelectPath = (e) => {
    const { value } = e.target;
    setSelectPath(value);
  };

  const handleSelectTestRun = (e) => {
    const { value } = e.target;
    setSelectTestRun(value);
  };

  const handleSelectModal = (e) => {
    const { value } = e.target;
    setSelectModal(value);
  };

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      projectName: state.projectName,
      version: selectVersion,
      path: selectPath,
      testRun: selectTestRun,
      modal: selectModal,
    };
    console.log("payload", payload);
    try {
      // await signinByEmailPassword(email.value, password.value);
      setLoading(false);
    } catch (e) {
      set_error(e.code);
      setLoading(false);
    }
  };

  return (
    <CardContent>
      <form onSubmit={onSubmitLogin}>
        <Grid item xs={10}>
          <TextField
            name="projectName"
            type="text"
            variant="outlined"
            placeholder="Enter car details"
            value={state.projectName}
            onChange={handleInputs}
            fullWidth
            disabled={loading}
            sx={{
              backgroundColor: "secondary.dark",
            }}
          />
        </Grid>
        <br />
        <Grid item xs={10}>
          <TextField
            label="Select Carmaker Version"
            name="category"
            type="select"
            variant="outlined"
            value={selectVersion.label}
            onChange={handleSelectVersion}
            fullWidth
            disabled={loading}
            sx={{
              backgroundColor: "secondary.dark",
            }}
            select
          >
            {SelectVersion.map((item, id) => {
              return (
                <MenuItem key={id} value={item.value}>
                  <ListItemText primary={item.label} />
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <br />
        <Grid item xs={10}>
          <TextField
            label="Project Path"
            name="category"
            type="select"
            variant="outlined"
            value={selectPath.label}
            onChange={handleSelectPath}
            fullWidth
            disabled={loading}
            sx={{
              backgroundColor: "secondary.dark",
            }}
            select
          >
            {SelectPath.map((item, id) => {
              return (
                <MenuItem key={id} value={item.value}>
                  <ListItemText primary={item.label} />
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <br />
        <Grid item xs={10}>
          <TextField
            label="Select Test Run"
            name="category"
            type="select"
            variant="outlined"
            value={selectTestRun.label}
            onChange={handleSelectTestRun}
            fullWidth
            disabled={loading}
            sx={{
              backgroundColor: "secondary.dark",
            }}
            select
          >
            {SelectTestRun.map((item, id) => {
              return (
                <MenuItem key={id} value={item.value}>
                  <ListItemText primary={item.label} />
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        <br />
        <Grid item xs={10}>
          <TextField
            label="Select Carmaker modal"
            name="category"
            type="select"
            variant="outlined"
            value={selectModal.label}
            onChange={handleSelectModal}
            fullWidth
            disabled={loading}
            sx={{
              backgroundColor: "secondary.dark",
            }}
            select
          >
            {SelectModal.map((item, id) => {
              return (
                <MenuItem key={id} value={item.value}>
                  <ListItemText primary={item.label} />
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        {/* <div className={`d-flex justify-content-center w-100 mt-3 mb-3`}>
          <Button
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              width: "431px",
            }}
            disabled={loading}
            type="submit"
          >
            Submit
          </Button>
        </div> */}
      </form>
    </CardContent>
  );
}
