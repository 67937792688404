import React, { Fragment } from 'react'
import DSpaceConfigForm from './dSpaceConfigComponent/dSpaceConfigForm'

export default function DSpaceConfig() {
  return (
		<Fragment>
			<DSpaceConfigForm />
		</Fragment>
  );
}
