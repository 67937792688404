import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Grid,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Alert,
  Collapse,
  Tabs,
  Tab,
} from "@mui/material";
import CodeEditor from "components/codeEditor";
import { Link, useNavigate, useParams } from "react-router-dom";
import CodeIcon from "@mui/icons-material/Code";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FunctionsIcon from "@mui/icons-material/Functions";
import {
  getCodeForObserver,
  getCodeForObserverRuleMetric,
  postCodeForObserver,
} from "endpointServices/autoScenarioServices";
import { GetInfoFromLocalStorage } from "utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import STORE from "services/redux-store/store";

const documentationUrl = "/replicaDocument/html/index.shtml";

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default function LowFidelityRule() {
  const { Projects } = STORE.getState();
  const { projectId } = Projects;
  const navigator = useNavigate();
  const param = useParams();
  const [codeSample, setCodeSample] = useState({
    codeEditor: "",
  });

  const [metric, setMetric] = useState([]);
  const [open, setOpen] = useState(false);
  const [collapseId, setCollapseId] = useState("");
  const [codeMessage, setCodeMessage] = useState("");
  const [isNextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getCodeSample();
    getCodeObserverMetric();
  }, []);

  const handleClick = (id) => {
    setCollapseId(id);
    setOpen(!open);
  };

  const onClickNext = () => {
    navigator(`/projects/${param.id}/applications/low-fidelity/execute`);
  };

  const getCodeSample = async () => {
    const response = await getCodeForObserver();
    if (response?.status === 200) {
      const sample = response?.data.data;
      const code = sample[0]?.observer_criteria;
      const decodeData = atob(code);
      setCodeSample({
        codeEditor: decodeData,
      });
    } else {
      const err = response?.message || "Request failed";
      toast.error(`${err}. Please try again later`);
      setCodeSample({
        codeEditor: "",
      });
    }
  };

  const getCodeObserverMetric = async () => {
    const response = await getCodeForObserverRuleMetric();
    if (response?.status === 200) {
      const sample = response?.data.data;
      setMetric(sample);
    } else {
      const err = response?.message || "Request failed";
      toast.error(`${err}. Please try again later`);
    }
  };

  const handleCodeInput = React.useCallback((value) => {
    setCodeSample((prevState) => ({
      ...prevState,
      codeEditor: value,
    }));
  }, []);

  const presetListRender = () => {
    return metric.map((row, idx) => {
      return (
        <>
          <ListItemButton
            key={idx}
            sx={{ borderBottom: "1px solid #5c616642" }}
          >
            <ListItemIcon>
              {idx}
              <CodeIcon sx={{ marginLeft: ".5em" }} />
            </ListItemIcon>
            <ListItemText primary={row.name} onClick={() => handleClick(idx)} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse
            in={collapseId == idx ? open : false}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <FunctionsIcon sx={{ marginLeft: ".5em" }} />
                </ListItemIcon>
                <ListItemText primary={row.input} secondary={row.description} />
              </ListItemButton>
            </List>
          </Collapse>
        </>
      );
    });
  };

  const onSubmitaCodeEditor = async (e) => {
    e.preventDefault();
    const observerId = GetInfoFromLocalStorage("observer_id");
    const parseId = JSON.stringify(observerId);
    const payload = {
      observer_id: parseInt(parseId),
      criteria_evaluator: btoa(codeSample.codeEditor),
    };
    const response = await postCodeForObserver(payload);
    console.log("response submit", response);
    if (response?.status === 200) {
      const { message } = response?.data.data;
      setCodeMessage(message);
      console.log("message", message);
      toast.success("Rule executed successfully");
      setNextButtonDisabled(false);
    } else {
      const err = response?.message || "Failed to sumbit request";
      toast.error(`${err}. Please try again later`);
      setNextButtonDisabled(true);
    }
  };

  const onClickBack = () => {
    navigator(`/projects/${projectId}/applications/low-fidelity/observer`);
  };

  return (
    <Fragment>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item display="flex">
          <Typography variant="caption" sx={{ fontSize: 14 }}>
            Define or Select Rule from List
          </Typography>
          <Typography
            sx={{ fontSize: 10, marginLeft: "2px", marginTop: "5px" }}
          >
            - an ruler is a computational entity or algorithm that monitors the
            behavior of a Highly Automated Driving (HAD) system
          </Typography>
        </Grid>
        <Grid item>
          <Button
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "white",
              width: "130px",
              marginRight: "10px",
              textTransform: "none",
              fontSize: "inherit",
            }}
            onClick={onClickBack}
          >
            Back
          </Button>
          <Button
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "white",
              width: "130px",
              textTransform: "none",
              fontSize: "inherit",
            }}
            disabled={isNextButtonDisabled}
            onClick={onClickNext}
          >
            Next
          </Button>
        </Grid>
      </Grid>

      <Divider
        className="mb-3 mt-3"
        sx={{ borderBottom: "1px solid #D9D9D9" }}
      />

      <Grid container sx={{ backgroundColor: "black" }}>
        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid
              item
              sx={{
                height: "60vh",
                width: "100%",
                borderBottom: "1px solid",
                borderColor: "primary.main",
                overflowY: "auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "80%" }}>
                <Typography
                  variant="h6"
                  sx={{
                    backgroundColor: "secondary.dark",
                    color: "white",
                    padding: "12px",
                    textAlign: "center",
                    width: "85px",
                    lineHeight: 1,
                  }}
                >
                  Editor
                </Typography>
                <CodeEditor
                  onChange={handleCodeInput}
                  value={codeSample.codeEditor}
                  isEdit={false}
                  className="code-editor"
                />
              </div>
              <div>
                <Button
                  onClick={onSubmitaCodeEditor}
                  sx={{
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                    color: "white",
                    marginTop: "10px",
                    marginRight: "1rem",
                    width: "130px",
                  }}
                >
                  Execute
                </Button>
              </div>
            </Grid>
            <Grid item sx={{ height: "30vh", overflowY: "auto" }}>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "secondary.dark",
                  color: "white",
                  padding: "12px",
                  textAlign: "center",
                  width: "85px",
                  lineHeight: 1,
                }}
              >
                Result
              </Typography>
              <Box p={2}>
                <Typography variant="body2" gutterBottom>
                  <Alert severity="success">
                    Code will be checked at the time of execution
                  </Alert>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            borderLeft: "1px solid",
            borderColor: "primary.main",
            height: "90vh",
            overflowY: "auto",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="List" />
              {/* <Tab label="Documentation" /> */}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <List
              sx={{ width: "100%", marginTop: ".5em" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader=""
            >
              {presetListRender()}
            </List>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div style={{ height: "48rem" }}>
              <iframe
                src={documentationUrl}
                title="Documentation"
                width="100%"
                height="100%"
              />
            </div>
          </CustomTabPanel>
        </Grid>
      </Grid>
    </Fragment>
  );
}
