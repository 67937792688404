import React, { useEffect, useState } from "react";
import TESTIMAGE from "assets/images/exe.png";
import { ButtonX, CardX, ImageButton, ProgressX, ReadOnlyTag } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { connect } from "react-redux";
import { ReduxUpdateAutoScenarioJourney } from "services/redux-store/actions";
import { GET_SHOW_ODDS_MAPS, ODD_TO_GEOJSON_KHWARIZMI_URL } from "constants/endpoints";
import { httpGet } from "utils/httpMethods";

function AsSpawningStrategies({ ReduxAutoScenario, ReduxUpdateAutoScenarioJourney }) {
	const navigate = useNavigate();
	const params = useParams();
	const [loading, setLoading] = useState(false);
	

	const Strategies = [
		{
			name: "Perception Based Spawning",
			key: "as-perception-based",
			image: TESTIMAGE,
			onClick: () => {
				ReduxUpdateAutoScenarioJourney({ AsSpawningStrategies: "as-perception-based" })
				navigate(AUTHENTICATED_ROUTES.asPerceptionBased
					.replace(":id", params.id)
					.replace(":aid", params.aid)
					.replace(":mid", params.mid))
			},
			active: true,
		},
		{
			name: "Rate Based Spawning",
			key: "as-rate-based",
			image: TESTIMAGE,
			onClick: () => {
				ReduxUpdateAutoScenarioJourney({ AsSpawningStrategies: "as-rate-based" })
				navigate(AUTHENTICATED_ROUTES.asRateBased
					.replace(":id", params.id)
					.replace(":aid", params.aid)
					.replace(":mid", params.mid))
			},
			active: true,
		},
		{
			name: "Spawn Everywhere",
			key: "everywhere",
			image: TESTIMAGE,
			onClick: () => {
				ReduxUpdateAutoScenarioJourney({ AsSpawningStrategies: "everywhere" });
				navigate(AUTHENTICATED_ROUTES.asTrafficConfig
					.replace(":id", params.id)
					.replace(":aid", params.aid)
					.replace(":mid", params.mid)
					.replace(":strategy", "every-where"));
			},
			active: true,
		},
	];

	// TODO: Get the geojson url from some gid or anything
	const fetchMapLayers = async () => {
		setLoading(true);
		await httpGet({
			url: `${ODD_TO_GEOJSON_KHWARIZMI_URL}/${ReduxAutoScenario.AutoMapList.oddData.gid_kwh}`,
		}).then(async (resp) => {
			ReduxUpdateAutoScenarioJourney({ geojsonMapIdAndOddPolygon: resp.data.data.lane_polygons });
		}).catch((err) => {
			console.log("err", err);
		})
		.finally(() => {
			setLoading(false);
		});
	};

	useEffect(() => {
		fetchMapLayers();
	}, []);

	return (
		<CardX nodefaultclasses={true} title={"Spawning Strategies"} className={`mt-3 bg-transparent`} headeractions={
			<div className={`d-flex`}>
				<ButtonX onClick={() => navigate(-1)} variant={"outlined"}>
					Back
				</ButtonX>
				<div className={`ms-2`}>
					{
						ReduxAutoScenario.readonly && <ReadOnlyTag />
					}
				</div>
			</div>
		}>
			<div className={`container`}>
				<div className={`row mt-3`}>
					{Strategies.map((strategy, index) => {
						return (
							<div key={index} className={`col-lg-4 col-md-12 col-sm-12 text-center py-3`}>
								{loading ? (
									<ProgressX block={true} height={"28vh"} width={"100%"} />
								) : (
									<ImageButton
										src={`${GET_SHOW_ODDS_MAPS}/${ReduxAutoScenario.AutoMapList.oddData.gid_kwh}`}
										disabled={ReduxAutoScenario.readonly && ReduxAutoScenario.AsSpawningStrategies !== strategy.key}
										title={strategy.name}
										height={"28vh"}
										width={"100%"}
										active={ReduxAutoScenario.readonly ? ReduxAutoScenario.AsSpawningStrategies === strategy.key: strategy.active}
										onClick={strategy.onClick}
									/>
								)}
							</div>
						);
					})}
				</div>
			</div>
		</CardX>
	);
}
function mapStateToProps(state) {
	return {
		ReduxAutoScenario: state.AutoScenario,
	};
}
export default connect(mapStateToProps, { ReduxUpdateAutoScenarioJourney })(AsSpawningStrategies);
