import React, { useEffect, useState } from "react";
import { Box, Grid, Button, CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  getEducAgentByPralionId,
  postRlStartTraining,
} from "endpointServices/educAgentServices";
import execute from "assets/images/exe.png";
import { GetInfoFromLocalStorage } from "utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import STORE from "services/redux-store/store";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { TENSOR_BOARD } from "constants/endpoints";

export default function EducExecution() {
  const param = useParams();
  const navigator = useNavigate();
  const { Projects } = STORE.getState();

  const observId = GetInfoFromLocalStorage("observer_id");
  const mapId = GetInfoFromLocalStorage("map_id");

  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState({});
  const [isDisable, setDisable] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const [showText, setShowText] = useState(false);
  const [executed, setExecuted] = useState(false);

  // useEffect(() => {
  //   getProject();
  // }, [param]);

  // const getProject = async () => {
  //   const pralionId = parseInt(param.aid);
  //   const response = await getEducAgentByPralionId(pralionId);
  //   console.log("response", response);
  //   if (response?.status === 200) {
  //     setDisable(false);
  //     const data = response?.data?.training_session_id || null;
  //     setSessionId(data);
  //   } else {
  //     setSessionId(null);
  //   }
  // };

  // const runTensorBoardApi = () => {
  //   if (sessionId === null) {
  //     toast.info(`Url not available`);
  //   } else {
  //     window.open(`${TENSOR_BOARD}/${sessionId}`, "_blank");
  //   }
  // };

  const onSaveData = async () => {
    const map_id = parseInt(mapId);
    const observer_idd = parseInt(observId);

    // const allState = autoScenarioMainState
    // const previousProject = project
    // const payload = {
    //   pralion_id: previousProject.id,
    //   name: previousProject.name,
    //   map_generation_id: map_id,
    //   observer_id: observer_idd,
    //   time_of_day: "16:00",
    //   execution_time: parseInt(allState.placeDynamic.executionTime),
    //   generate_log: false,
    //   selected_traffic_preset: allState.placeVehicle?.selected_traffic_preset,
    //   test_vehicle_id: allState.placeVehicle?.test_vehicle_id,
    //   ego: allState.placeDynamic?.ego,
    //   traffic: {
    //     amount: allState.placeDynamic.traffic?.amount,
    //     seed_value: parseInt(allState.placeDynamic.traffic?.seed_value),
    //     random_mode: allState.placeDynamic.traffic?.random_mode,
    //     density_around_ego: allState.placeDynamic.traffic?.density_around_ego,
    //     vehicle_profiles: static_traffic_vehicle_profile,
    //     driver_profiles: static_traffic_driver_profiles,
    //     traffic_composition: static_traffic_composition,
    //   },
    //   "ril_configs": {
    //     "selected_agents": allState.agents,
    //     "ril_ego_features": [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
    //     "ril_perception_features": [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
    //     // "ril_map_features": [{id}, {id}, {id}]
    //   },
    //   "is_educ_agent": true,
    // }
    // const payload  = educAgentPayload
    const payload = {
      pralion_id: param.aid,
    };
    console.log("payload", payload);
    setShowText(false);
    setLoading(true);
    const response = await postRlStartTraining(payload);
    if (response?.status === 200) {
      setExecuted(true)
      // setLoading(false);
      const { data } = response;
      console.log("data", data);
      toast.success(data.message);
      setTimeout(() => {
        setLoading(false);
        navigator(`/projects/${Projects.projectId}/applications/educ-agents`)
        // getProject()
      }, 2000);
      // toast.success(`Successfuly started educAgents execution`);
      // const secondBody = {
      //   pralion_id: payload.pralion_id,
      // }
      // setTimeout(() => {
      //   runTensorBoardApi()
      // },2000)
      // const secondResponse = await startAutoScenario(secondBody)
      // if (secondResponse?.status === 200) {
      //     console.log('secondResponse', secondResponse)
      //     setDisable(false)
      // } else {
      //     setLoading(false);
      //     const errr = response?.response?.data?.message
      //     console.log('errr', errr)
      //     toast.error(`${errr}. Please try again later`)
      //     // const err = response?.message || 'Failed to start my scenario. Please see the logs'
      // }
    } else {
      console.log("error", response);
      setExecuted(false)
      const err =
        response?.message || "Failed to start my scenario. Please see the logs";
      toast.error(`${err}. Please try again later`);
      setLoading(false);
    }
  };
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} justifyContent="end">
          <Grid item>
            {
              executed ?
                <Button
                  sx={{
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                    color: "text.main",
                    width: "200px",
                    marginRight: "10px",
                  }}
                  onClick={() => navigator(`/projects/${Projects.projectId}/applications/educ-agents`)}
                >
                  Goto Projects
                </Button> :
                <Button
                  sx={{
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                    color: "text.main",
                    width: "200px",
                    marginRight: "10px",
                  }}
                  onClick={() => navigator(-1)}
                >
                  Back
                </Button>
            }

            <Button
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                color: "text.main",
                width: "200px",
                float: "right",
              }}
              disabled={loading || executed}
              type="submit"
              onClick={onSaveData}
            >
              Train Agent{" "}
              {loading ? (
                <CircularProgress
                  size={20}
                  style={{ marginLeft: "8px" }}
                  color="inherit"
                />
              ) : (
                ""
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        sx={{
          backgroundColor: "secondary.dark",
          padding: "4rem",
          borderRadius: "4px",
          marginTop: "1rem",
          height: "30rem",
          justifyContent: "center",
        }}
      >
        {/* <Grid item xs={6}> */}
        <span>
          {/* {loading ? <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%' }} /> : ''} */}

          {showText ? (
            <div style={{ position: "absolute", top: "50%", left: "32%" }}>
              {/* <h6 style={{ textAlign: 'center' }}>Executing Start</h6> */}
              {/* <p>To begin, press the "Execute" button and monitor</p> */}
              <p>
                <b>
                  An order has been issued to the support@automtove-ai .com to
                  start the training of your agent based on your inputs.
                </b>
              </p>
              <p style={{ textAlign: "center" }}>
                <b> Support will come back to you for details </b>
              </p>
            </div>
          ) : (
            <div style={{ position: "absolute", top: "50%", left: "45%" }}>
              {/* <h6 style={{ textAlign: 'center' }}>Executing Start</h6> */}
              <p>
                <b>To begin, press the "Train Agent" button and monitor</b>
              </p>
              <p style={{ textAlign: "center" }}>
                <b>the results in the corresponding application</b>
              </p>
              {/* <Button
                sx={{
                  backgroundColor: "primary.main",
                  "&:hover": {
                    backgroundColor: "primary.main",
                  },
                  color: "text.main",
                  width: "200px",
                  left: "30%",
                }}
                disabled={isDisable}
                type="submit"
                onClick={runTensorBoardApi}
              >
                Tensor Board
              </Button> */}
            </div>
          )}

          <img
            src={execute}
            style={{ width: "100%", marginBottom: 0 }}
            alt="icons"
          />
        </span>

        {/* </Grid> */}
      </Grid>
    </>
  );
}
