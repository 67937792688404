import {  GET_DSPACE_TEST_PROJECT, GET_TEST_VEHICLE_PROFILE, GET_TEST_VEHICLE_PROJECT, TEST_VEHICLE_PROJECT, Camera_Sensor_Setups, Add_Camera_Sensor } from "constants/endpoints"
import { httpGet, httpPatch, httpPost, httpPut } from "utils/httpMethods"

export const getTestVehicleProjectsById = async (body, pagination) => {
    const {pageNumber = 1, testsPerPage = 10 } = pagination || {}
    return await httpGet({ url: `${GET_TEST_VEHICLE_PROJECT}?project_id=${body}&page=${pageNumber}&page_size=${testsPerPage}`, payload: body })
        .then((res) => {
            return res
            
        })
        .catch((err) => {
            return err
        })
}

export const getAllTestVehicleProjects = async (body) => {
    return await httpGet({ url: `${GET_TEST_VEHICLE_PROJECT}?project_id=${body}&page=1`, payload: body })
        .then((res) => {
            return res
            
        })
        .catch((err) => {
            return err
        })
}

export const getTestVehicleDetails = async (pralionId) => {
    return await httpGet({ url: `${GET_TEST_VEHICLE_PROJECT}?pralion_id=${pralionId}` })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err
        })
}

export const createTestVehicleProjectService = async (body) => {
    return await httpPost({ url: GET_TEST_VEHICLE_PROJECT, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err
        })
}

export const getTestVehicleProfileService = async (body) => {
    return await httpGet({ url: GET_TEST_VEHICLE_PROFILE, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err
        })
}

export const updateTestVehicleProjectService = async (body) => {
    return await httpPut({ url: TEST_VEHICLE_PROJECT, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err
        })
}

export const  getDspaceTestList = async (body) => {
    return await httpGet({ url:`${GET_DSPACE_TEST_PROJECT}?project_id=${body}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err
        })
}

export const  addSensorSetups = async(body) => {
    return await httpPost({ url: Camera_Sensor_Setups, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err
        })
}

export const  editSensorSetups = async(body) => {
    return await httpPut({ url: Camera_Sensor_Setups, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err
        })
}

export const  editSensorPreset = async(body) => {
    return await httpPatch({ url: Camera_Sensor_Setups, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err
        })
}

export const  getSensorSetups = async(id) => {
    return await httpGet({ url: `${Camera_Sensor_Setups}?project_id=${id}` })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err
        })
}

export const  addCameraSensor = async(body) => {
    return await httpPost({ url: Add_Camera_Sensor, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err
        })
}

export const  getCameraSensor = async(body) => {
    return await httpGet({ url: `${Add_Camera_Sensor}?sensor_setup_id=${body}`})
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err
        })
}