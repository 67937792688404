export const DEFAULT_POLYGON_LAYER = (layerId, source, color = "rgba(0, 163, 181, 0.4)", pickColorFromProps = false) => ({
	id: layerId,
	type: "fill",
	source: source,
	layout: {},
	filter: ["==", "$type", "Polygon"],
	paint: {
		"fill-color": pickColorFromProps ? ["get", "fill"] : color,
		"fill-outline-color": "#FF7D00",
	},
});

export const DEFAULT_POLYGON_LAYER_WITH_DYNAMIC_COLORS = (layerId, source, color = "rgba(0, 163, 181, 0.1)") => ({
	id: layerId,
	type: "fill",
	source: source,
	layout: {},
	filter: ["==", "$type", "Polygon"],
	paint: {
		'fill-color': [
		  'case',
		  ['==', ['get', 'id'], 0], // Conditional color change for selected polygon
		  '#FF0000', // Red for selected polygon
		  color, // Default color for other polygons
		],
		'fill-opacity': 0.5,
	  },
});


export const DEFAULT_POINT_LAYER = (layerId, source, size = 1, color = "") => ({
	id: layerId,
	type: "symbol",
	source: source,
	layout: {
		"icon-image": "marker",
		"icon-size": size,
		"icon-allow-overlap": true,
	},
});

export const DEFAULT_LINE_LAYER = (layerId, source, color = "#FF7D00", typeFor = "LineString") => ({
	id: layerId,
	type: "line",
	filter: ["==", "$type", typeFor],
	source: source,
	paint: {
		"line-color": color,
		"line-width": 5,
	},
});

export const DEFAULT_CIRCLE_LAYER = (layerId, source, radius = 20, color = "rgba(0, 163, 181, 0.5)") => ({
	id: layerId,
	type: "circle",
	source: source,
	paint: {
		"circle-radius": radius,
		"circle-color": color, //"rgba(255, 0, 0, 0.5)", // Set your preferred circle color
	},
});

export const DEFAULT_MULTI_LINE_LAYER = (layerId, source, color = "#ff0000") => ({
	id: layerId,
	type: "line",
	source: source,
	filter: ["==", "$type", "MultiLineString"],
	paint: {
		"line-color": color,
		"line-width": 5,
	},
});

export const DEFAULT_PEDESTRIAN_SPEED = { range: [2, 7], step_size: 1 };
export const DEFAULT_VEHICLE_SPEED = { range: [50, 70], step_size: 5 };
