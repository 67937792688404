import * as React from "react";
import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";


const ImageButtonBase = styled(ButtonBase)(({ theme, height, active }) => ({
	position: "relative",
	height,
	borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
		width: "100% !important", // Overrides inline-style
		height,
	},
	"&:hover, &.Mui-focusVisible": {
		zIndex: 1,
		"& .MuiImageBackdrop-root": {
			opacity: 0.15,
		},
		"& .MuiImageMarked-root": {
			opacity: 0,
		},
		"& .MuiTypography-root": {
			border: "4px solid currentColor",
		},
	},
}));

const ImageSrc = styled("span")({
	position: "absolute",
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundSize: "cover",
	backgroundPosition: "center ",
	objectFit: "cover",
	borderRadius: "10px"
});

const Image = styled("span")(({ theme }) => ({
	position: "absolute",
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	color: theme.palette.common.white,
	borderRadius: "10px"
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
	position: "absolute",
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundColor: "rgba(35, 55, 75)",
	opacity: 0.4,
	transition: theme.transitions.create("opacity"),
    borderRadius: "10px"
}));

const ImageMarked = styled("span")(({ theme }) => ({
	height: 3,
	width: 18,
	backgroundColor: theme.palette.common.white,
	position: "absolute",
	bottom: -2,
	left: "calc(50% - 9px)",
	transition: theme.transitions.create("opacity"),
}));

export default function ImageButton({ src, alt, title, size, active, onClick, height, width, disabled }) {

	return (
		<ImageButtonBase
			focusRipple
			disabled={disabled}
			key={title || alt}
			height={size || height}
			style={{
				width: size * 1.4 || width,
			}}
            onClick={onClick}
		>
			<ImageSrc style={{ backgroundImage: `url(${src})` }} />
			<ImageBackdrop className="MuiImageBackdrop-root" />
			<Image>
				<Typography
					component="span"
					variant="subtitle1"
					color={disabled ? "gray" : "inherit"}
					sx={{
						position: "relative",
						p: 4,
						pt: 2,
						pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
					}}
				>
					{title || alt}
					{active === true ? <ImageMarked className="MuiImageMarked-root" /> : ""}
				</Typography>
			</Image>
		</ImageButtonBase>
	);
}
