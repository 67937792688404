import { SET_MYSCENARIO, UPDATE_MYSCENARIO } from ".."

export const ReduxCreateMyScenarioJourney = (pId, pName, readonly = false) => {
    return {
        type: SET_MYSCENARIO,
        payload: {
            pralionId: pId,
            projectName: pName,
            readonly,
        }
    }
}

export const ReduxUpdateMyScenarioJourney = (obj) => {
    return {
        type: UPDATE_MYSCENARIO,
        payload: obj
    }
}