import { SET_ODD, UPDATE_ODD } from ".."

export const ReduxCreateOddJourney = (pid,ODDPayload) => {
    return {
        type: SET_ODD,
        payload: { 
            projectId: pid,
            oddPayload:ODDPayload
        }
    }
}

export const ReduxUpdateOddJourney = (obj) => {
    return {
        type: UPDATE_ODD,
        payload: obj
    }
}