import React, { useEffect, useState } from "react";
import {Modal, Box, Typography,Alert,Button, Divider } from "@mui/material"
import { styled } from '@mui/material/styles';
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import copy from "copy-to-clipboard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: 'auto',
  bgcolor: "primary.dark",
  borderRadius: "4px",
  p: 4,
};

const StyledContent = styled(Box)(({ theme }) => ({
  marginTop: 14,
  border: '1px solid white',
  paddingTop: '3rem',
  paddingBottom: '3rem'

}));

const HeaderBox = styled(Box)(({ theme }) => ({
  marginTop: -24,
  display: 'flex',
  alignItems: 'center'

}));

const AlertBox = styled(Alert)(({ theme }) => ({
 backgroundColor: 'transparent',
 color: 'white',
 textAlign: 'justify',
 paddingLeft: '3rem',
 paddingRight: '3rem'

}));

const CaptionText = styled('Typography')(({theme})=>({
  paddingLeft: '5rem',
  fontStyle: 'italic',
  color: 'aqua'
}))

const StyledDiv = styled('div')(({ theme }) => ({
 display: 'flex',
 justifyContent: 'flex-end',
 paddingRight: '2rem',
 marginTop: '2rem'
}));

export const ExceptionHandlerModal = ({ open, close, applicationName, errorMessage }) => {

  const copyToClipboard = () => {
        copy(errorMessage);
    }
return(
   <Modal
      open={open}
      onClose={close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
        <Box sx={style}>
          <HeaderBox>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {applicationName}
          </Typography>
           <CloseIcon
            onClick={close}
            cursor="pointer"
            sx={{
              position: "absolute",
              marginTop: '2px',
              right: "2.5rem",
              fontSize: "1.85rem",
              }}
            />
          </HeaderBox>
          <StyledContent>
          <AlertBox severity="error">
          <Typography>
          {errorMessage}
         </Typography>
          </AlertBox>
          <CaptionText>For further assistance, email this log to our tech support: support@automotive-ai.com</CaptionText>
          <StyledDiv >
            <Button variant="contained" startIcon={<ContentCopyIcon />} onClick={copyToClipboard}>Copy Error</Button>
        </StyledDiv>
          </StyledContent>
          
        </Box>
    </Modal>
)
}