import React, { useState, useEffect, useMemo } from "react";
import { Box, Grid, Button } from "@mui/material";
import ButtonX from "components/buttonX";
import ProjectForm from "./projectComponent/projectForm";
import ProjectsTable from "./projectComponent/projectsTable";
import { Link, useNavigate } from "react-router-dom";
import { ModalX } from "components";
import { getAllProjectService } from "endpointServices/projectServices";
import { SetInfoToLocalStorage, wrapText } from "utils";
import TraceIcon from "../../assets/icons/trace.svg";
import dateConverter from "utils/dateConverter";
import { ReduxAddProject } from "services/redux-store/actions";
import { connect } from "react-redux";
import { pageSize } from "utils/staticsData";
import AirplayIcon from "@mui/icons-material/Airplay";

export default connect(null, { ReduxAddProject })(function Projects({
  ReduxAddProject,
}) {
  let navigator = useNavigate();

  const [open, setOpen] = useState(false);
  const [list, setList] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [isFetching, setFetching] = useState(false);
  const [summaryData, setSummaryData] = useState({});
  const [edited, setEdited] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    setEdited(false);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    getAllList({ pageNumber: page, testsPerPage: pageSize });
    localStorage.removeItem("title");
    localStorage.removeItem("pralion_id");
    localStorage.removeItem("map_id");
    localStorage.removeItem("p_id");
    localStorage.removeItem("observer_id");
  }, [page]);

  const getAllList = async (pagination) => {
    setFetching(true);
    const response = await getAllProjectService(null, pagination);
    console.log("response", response)
    if (response?.status === 200) {
      setFetching(false);
      const { data } = response?.data;
      setTotalRows(data.no_of_projects);
      setList(data.projects);
    } else {
      setList([]);
      setFetching(false);
    }
  };

  const goto = (data) => {
    localStorage.setItem("p_id", data.id);
    SetInfoToLocalStorage("title", data.value);
    ReduxAddProject(data.id);
    navigator(`/projects/${data.id}/applications`);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSummary = (data) => {
    console.log("data", data);
    setEdited(true);
    setOpen(true);
    setSummaryData(data);
  };

  const columns = useMemo(
    () => [
      {
        field: "name",
        renderHeader: () => <span className="heading-2">{"Project ID "}</span>,
        width: 550,
        renderCell: (params) => {
          return (
            <Grid container spacing={1} alignItems="center">
              <Grid item style={{ display: "flex", alignItems: "center" }}>
                <Link to={`/projects/${params.value}/${params.id}/tracebility`}>
                  <img
                    src={TraceIcon}
                    style={{ width: "1rem", cursor: "pointer" }}
                  />
                </Link>
                <Button
                  className="Main-link-simple row-heading"
                  onClick={() => goto(params)}
                  sx={{ color: "white" }}
                >
                  {wrapText(params.value)}
                </Button>
              </Grid>
            </Grid>
          );
        },
      },
      {
        field: "date_created",
        renderHeader: () => <span className="heading-2">{"Created At"}</span>,
        width: 280,
        renderCell: (params) => (
          <span className="row-heading">{dateConverter(params.value)}</span>
        ),
      },
      {
        renderHeader: () => <span className="heading-2">{"Summary"}</span>,
        width: 360,
        renderCell: (params) => (
          <Grid onClick={() => handleSummary(params.row)} sx={{cursor: "pointer", marginLeft:'2em'}}>
            <AirplayIcon />
          </Grid>
        ),
      },
    ],
    [list, page]
  );

  return (
    <>
      <ModalX
        open={open}
        close={handleClose}
        heading={!edited ? "Create New Project" : `${summaryData?.name} Summary`}
        subheading={!edited ? "Enter Project details to continue" : "Project application details"}
      >
        <ProjectForm
          syncList={getAllList}
          handleClose={handleClose}
          summaryData={summaryData}
          isEdit={edited}
        />
      </ModalX>

      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
        mb={2}
      >
        <ButtonX onClick={handleOpen}>Create New Project</ButtonX>
      </Box>

      <ProjectsTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />
    </>
  );
});
