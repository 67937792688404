export let allScenarioState = {
    user_scenario:{},
    mapData:{},
    vehicleData:{}
}

export const getScenarioData = (data) => {
    allScenarioState.user_scenario = data 
}

export const setMapData = (data) => {
    allScenarioState.map_id = data 
}

export const setVehicleData = (data) => {
    allScenarioState.vehicleData = data 
}