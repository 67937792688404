import { ButtonX, Typos } from 'components';
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { mapTypes } from "utils";
import "./everywhere-map-styles.css";

function Everywhere({}) {
    const navigate = useNavigate();
    const mapContainer = useRef(null);
	const map = useRef(null);

    const [lng, setLng] = useState(7.42446); //7.42446, 51.580984
	const [lat, setLat] = useState(51.580984);
	const [zoom, setZoom] = useState(15);

    useEffect(() => {
		if (!map.current) {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: mapTypes.streetView,
				center: [lng, lat],
				zoom: zoom,
			});
			map.current.on("load", () => {
				map.current.on("move", handleOnMapMove);
				
			}); 
		}
		
		
	}, [])

    const handleOnMapMove = (e) => {
		setLng(map.current.getCenter().lng.toFixed(4));
		setLat(map.current.getCenter().lat.toFixed(4));
		setZoom(map.current.getZoom().toFixed(2));
	};

    const onClickNext = () => {

    }

    return (
        <div className={``}>
            <div className={`container-fuild`}>
            <div className={`row`}>
				<div className={`col-12 d-flex justify-content-between`}>
					<div className={`d-flex`}>
					</div>
					<div className={`d-flex`}>
						<ButtonX onClick={() => navigate(-1)} variant={"outlined"}>Back</ButtonX>
						<ButtonX className={`ms-2`} onClick={onClickNext}>
							Next
						</ButtonX>
					</div>
				</div>
			</div>
			<div className={`map-container mt-3`}>
                <div className={`pbs-title-bar`}>
                    <Typos.H6>Spawn Everywhere</Typos.H6>
                </div>
				<div className="sidebar">
					Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
				</div>
				<div ref={mapContainer} className="map-view" />
			</div>
		</div>
        </div>
    )
}
export default Everywhere;