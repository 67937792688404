import { Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ButtonX } from "components";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { ReduxUpdateDSpaceJourney } from "services/redux-store/actions/actions-dspace";

export default connect(null, { ReduxUpdateDSpaceJourney })(function DSpaceConfigForm({ ReduxUpdateDSpaceJourney }) {
	const navigate = useNavigate();
	const [textFields, setTextFields] = useState({
		name: "",
		description: "",
		projectPath: "",
		scenarioName: "",
	});

	const onClickNext = () => {
		ReduxUpdateDSpaceJourney({
			projectName: textFields.name,
			projectDescription: textFields.description,
			projectPath: textFields.projectPath,
			projectScenario: textFields.scenarioName,
		});
		navigate(AUTHENTICATED_ROUTES.dSpaceMap);
	};

	const onTextFieldChange = (e) => {
		const { name, value } = e.target;
		const validPathRegex = /^[\w\s\\/.:-]+$/;
		if (name === "projectPath" && value !== "" && !validPathRegex.test(value) && e.key !== "Backspace") {
			return; 
		}
		setTextFields({
			...textFields,
			[name]: value,
		});
	};

	return (
		<>
			<div className={`d-flex justify-content-end`}>
				<ButtonX size="large" className={`me-2`} onClick={() => navigate(-1)}>
					Back
				</ButtonX>
				<ButtonX size="large" onClick={onClickNext} disabled={Object.keys(textFields).filter((item) => textFields['name'] === "" || textFields['scenarioName'] === "").length > 0}>
					Next
				</ButtonX>
			</div>

			<Grid
				sx={{
					backgroundColor: "secondary.dark",
					maxWidth: "50rem",
					paddingBottom: "12px",
					borderRadius: "8px",
				}}
			>
				<Box>
					<Typography
						sx={{
							padding: "2rem",
						}}
					>
						dSPACE Properties
					</Typography>
				</Box>

				<Box
					component="form"
					sx={{
						"& .MuiTextField-root": { m: 4, width: "35ch" },
					}}
				>
					<div>
						<TextField name="name" label="Project Name" value={textFields.name} inputProps={{ maxLength: 40 }} onChange={onTextFieldChange} />
						<TextField name="scenarioName" label="Scenario Name" value={textFields.scenarioName} inputProps={{ maxLength: 30 }} onChange={onTextFieldChange} />

						{/* <TextField name="description" label="Description" value={textFields.description} onChange={onTextFieldChange} /> */}
					</div>

					<div>
						{/* <TextField name="projectPath" label="Project Path" value={textFields.projectPath} onChange={onTextFieldChange} /> */}
					</div>
				</Box>
			</Grid>
		</>
	);
});
