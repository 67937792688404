import { SET_MAP, UPDATE_MAP } from ".."

export const ReduxCreateMapsJourney = (pid, pname, geoId, oddPid, readonly = false) => {
    return {
        type: SET_MAP,
        payload: {
            pralionId: pid,
            projectName: pname,
            oddId: geoId,
            oddPralionId: oddPid,
            readonly
        }
    }
}

export const ReduxUpdateMapsJourney = (obj) => {
    return {
        type: UPDATE_MAP,
        payload: obj
    }
}