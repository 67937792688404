import React, { Fragment, useEffect, useState } from "react";
import { Grid, Button, Checkbox } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { pageSize } from "utils/staticsData";
import MainTable from "components/mainTable";
import { ButtonX } from "components";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { ReduxCreateCarlaJourney } from "services/redux-store/actions/actions-carla";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ScenariosShowing = ({
  versionId,
  ReduxCreateCarlaJourney,
  getScenarios,
}) => {
  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [checkSelectedScenario, setCheckSelectedScenario] = useState([]);
  const [isDisable, setIsDisabled] = useState(true);
  const [isNext, setNext] = useState(false);

  const navigate = useNavigate();
  const param = useParams();

  useEffect(() => {
    getProjectList({ pageNumber: page, testsPerPage: pageSize });
  }, [page]);

  const getProjectList = async (pagination) => {
    setFetching(true);
    const versionNumber = parseInt(versionId);
    const response = await getScenarios(versionNumber, pagination);
    if (response?.status === 200) {
      setFetching(false);
      const { data } = response.data;
      setList(data.records || []);
      setTotalRows(data.total_no_records);
    } else {
      setList([]);
      setFetching(false);
    }
  };

  const goto = (data) => {
    if (data.visualizer_url !== null) {
      window.open(data.visualizer_url, "_blank");
    } else {
      alert("Url not found");
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleCheckBoxChange = (event, item) => {
    setCheckSelectedScenario((prevSelect) => {
      if (prevSelect === item) {
        setIsDisabled(true); 
        return null;
      } else {
        setIsDisabled(false); 
        return item;
      }
    });
  };

  const onSaveData = async () => {
    ReduxCreateCarlaJourney(checkSelectedScenario)
    toast.success("Scenario saved")
    setNext(true);
  };

  const columns = [
    {
      // field: "checkbox",
      // headerName: <Checkbox onChange={() => handleCheckBoxChange()} />,
      width: 100,
      renderCell: (params) => (
        <Checkbox
          checked={checkSelectedScenario === params.row}
          onChange={(event) => handleCheckBoxChange(event, params.row)}
        />
      ),
    },
    {
      field: "name",
      renderHeader: () => <span className="">{"Scenarios Name "}</span>,
      width: 400,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Button
              className="Main-link-simple row-heading"
              onClick={() => goto(params.row)}
              sx={{ color: "white" }}
            >
              {params.value}
            </Button>
          </Grid>
        </Grid>
      ),
    },
    { field: "map_name", headerName: "Map Name", width: 150 },
    {
      field: "output.TTC_Pass",
      headerName: "TTC Pass",
      width: 115,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {params.row.output?.TTC_Pass === true ? (
            <p style={{ marginTop: "16px" }}>
              <DoneIcon sx={{ color: "green" }} />
            </p>
          ) : params.row.output?.TTC_Pass === false ? (
            <p style={{ marginTop: "16px" }}>
              <CloseIcon sx={{ color: "red" }} />
            </p>
          ) : (
            ""
          )}
        </Grid>
      ),
    },
  ];

  return (
    <Fragment>
      <Grid item sx={{ display: "flex", justifyContent: "end" }}>
        <ButtonX onClick={onSaveData} disabled={isDisable}>
          Save
        </ButtonX>
      </Grid>
      <MainTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />
    </Fragment>
  );
}
export default connect(null, { ReduxCreateCarlaJourney })(ScenariosShowing);