import React from "react";
import { Button } from "@mui/material";


export default function ButtonWithIcon({ id, children, className, onClick, color, fullwidth, size, variant, icon, disabled }) {
	return (
		<Button
			id={id}
			onClick={onClick}
			className={className}
            disabled={disabled || false}
			color={color || "primary"}
			size={size || "medium"}
			sx={{
				"&:hover": {
					backgroundColor: "primary.main",
				},
			}}
            endIcon={icon}
			variant={variant || "contained"}
			fullWidth={fullwidth || false}
		>
			{children}
		</Button>
	);
}
