import { SET_VEHICLE, UPDATE_VEHICLE } from "..";

const INITIAL_STATE = {
    pralionId: null,
    projectName: null,
    readOnly: null,
    vehicleProfileId: null,
    vehicleDisplayName: null,
    vehicleProperties: null,
    rendering_Type: []
}

export default function ReducerVehicles(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_VEHICLE:
            return action.payload;
        case UPDATE_VEHICLE:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}