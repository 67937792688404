import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { rilEgoList } from "utils";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonX } from "components";
import { getEgoAgents } from "../../../stateMaintain";
import { ReduxUpdateEducAgentsJourney } from "services/redux-store/actions/actions-educAgents";
import { connect } from "react-redux";
import STORE from "services/redux-store/store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EgoParameter = ({
  ReduxUpdateEducAgentsJourney,
  getMapValues,
  egoDescription,
  isLoading,
}) => {
  const param = useParams();
  const navigate = useNavigate();
  const { EducAgents } = STORE.getState();

  const [egoParameterList, setEgoParameterList] = useState([]);
  const [isDisable, setIsDisabled] = useState(true);

  useEffect(() => {
    getStoreValues();
  }, [EducAgents.hyperParameterEgo]);

  useEffect(() => {
    setIsDisabled(egoParameterList.length === 0);
  }, [egoParameterList]);

  const getStoreValues = () => {
    if (
      EducAgents.hyperParameterEgo !== undefined &&
      EducAgents.hyperParameterEgo !== null &&
      EducAgents.hyperParameterEgo.length > 0
    ) {
      console.log("Ego Parameter List", EducAgents.hyperParameterEgo);
      setEgoParameterList(EducAgents.hyperParameterEgo);
    }
  };

  const handleCheckBoxChange = (item) => {
    const updatedChecked = [...egoParameterList];
    console.log("updatedChecked", updatedChecked);
    const itemIndex = egoParameterList.findIndex((obj) => obj.json_representation === item.json_representation);
    if (itemIndex !== -1) {
      updatedChecked.splice(itemIndex, 1);
    } else {
      updatedChecked.push({
        id: item.id,
        json_representation: item.json_representation,
      });
    }
    setEgoParameterList(updatedChecked);
    ReduxUpdateEducAgentsJourney({ hyperParameterEgo: updatedChecked });
    getEgoAgents(updatedChecked);
    getMapValues(updatedChecked);
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            sx={{
              backgroundColor: "secondary.dark",
              paddingBottom: "12px",
              borderRadius: "8px",
            }}
          >
            <Grid container justifyContent={"end"} p={1}>
              {/* <ButtonX  onClick={onClickNext} disabled={isDisable}>
                Save
              </ButtonX> */}
            </Grid>
            <Grid
              className="vehicle-details-table-data"
              sx={{ height: "33.5rem" }}
            >
              {isLoading ? 
                <div style={{ position: "absolute", top: "50%", left: "50%" }}>
                  <CircularProgress size={40} />
                </div>
               : 
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Selection</TableCell>
                        <TableCell>Feature</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Description</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {egoDescription.map((item) => {
                        return (
                          <TableRow key={item.feature}>
                            <TableCell>
                              <Checkbox
                                checked={egoParameterList.some(
                                  (value) => value.json_representation === item.json_representation
                                )}
                                onChange={() => handleCheckBoxChange(item)}
                              />
                            </TableCell>
                            <TableCell>{item.feature}</TableCell>
                            <TableCell>{item.type}</TableCell>
                            <TableCell>{item.description}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};
export default connect(null, { ReduxUpdateEducAgentsJourney })(EgoParameter);
