import { USER_AUTH } from "..";

const INITIAL_STATE = null

export default function ReducerUser(state = INITIAL_STATE, action) {
    switch (action.type) {
        case USER_AUTH:
            return action.payload;
        default:
            return state;
    }
}