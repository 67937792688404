import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography, CardHeader, Box, } from '@mui/material';
import { useParams } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import { getProjectTraceService } from "endpointServices/projectServices";
import { ModalX } from 'components'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const style = {
  bgcolor: "secondary.dark",
  padding: "7px",
  borderRadius: "8px"
};

export default function Tracebility() {
  const { name, id } = useParams();
  const [list, setList] = useState([])
  const [open, setOpen] = useState(false);
  const [actionList, setActionList] = useState([]);
  const [isFetching, setFetching] = useState(false);



  useEffect(() => {
    getProjectList()
  }, [])

  const getProjectList = async () => {
    setFetching(true);
    const projectId = parseInt(id)
    const response = await getProjectTraceService(projectId)
    console.log('response', response)
    if (response?.status === 200) {
      setFetching(false);
      const { data } = response.data
      setList(data)
    } else {
      setList([])
      setFetching(false);
    }
  }
  const handleClose = () => setOpen(false);
  const openActions = (data) => {
    console.log('data', data)
    setActionList(data)
    setOpen(true)
  }

  const columns = [
    {
      field: "id",
      headerName: (
        <span className="heading-2">Id</span>
      ),
      width: 355,
      renderCell: (params) => (
        <span className="row-heading">{params.value}</span>
      ),
    },
    {
      field: "actions",
      headerName: (
        <span className="heading-2">Action</span>
      ),
      width: 355,
      renderCell: (params) => (
        <Button onClick={() => openActions(params.value)}>
          Action List
        </Button>
      ),
    },
    {
      field: "request_uuid",
      headerName: (
        <span className="heading-2">Request UUID</span>
      ),
      width: 330,
      renderCell: (params) => (
        <span className="row-heading">{params.value}</span>
      ),
    },
    {
      field: "response_uuid",
      headerName: (
        <span className="heading-2">Response UUID</span>
      ),
      width: 330,
      renderCell: (params) => (
        <span className="row-heading">{params.value}</span>
      ),
    },
    {
      field: "processing_time",
      headerName: (
        <span className="heading-2">Process TIme</span>
      ),
      width: 330,
      renderCell: (params) => (
        <span className="row-heading">{params.value}</span>
      ),
    },
    {
      field: "request_at",
      headerName: (
        <span className="heading-2">Request At</span>
      ),
      width: 310,
      renderCell: (params) => (
        <span className="row-heading">{params.value}</span>
      ),
    },
  ];

  const renderActionList = () => {
    if (actionList.length) {
      return actionList && actionList.map((row, idx) => {
        return (
          <ListItem key={idx}>
            <ListItemButton>
              <span>{idx}</span>
              <ListItemText primary={row} sx={{ marginLeft: '2em' }} />
            </ListItemButton>
          </ListItem>
        )
      })
    }
    else {
      return (
        <ListItem >
          <ListItemButton>
            <ListItemText primary="No Found" />
          </ListItemButton>
        </ListItem>
      )
    }

  }
  return (
    <>
      <ModalX open={open} close={handleClose} heading={name} subheading={'Traceability'}>
        <div className='container-fluid'>
          <Box className='modal-content mt-4'>
            <CardHeader
              title="Action List"
            />
            <List >
              {renderActionList()}
            </List>
          </Box>
        </div>
      </ModalX>
      <Grid sx={style}>
        <Typography variant="h6">Project Name: {id} - {name}</Typography>
      </Grid>
      <DataGrid
        getRowId={(row) => row.id}
        rows={list}
        columns={columns}
        autoHeight={true}
        loading={isFetching}
        className="main-table-height"
        sx={{
          marginTop: "1rem",
          border: "none",
          borderRadius: "12px",
          backgroundColor: "secondary.dark",
          "& .MuiDataGrid-cell": {
            color: "text.main",
          },
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": { marginTop: "15px", }

        }}

        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
        }}
      />
    </>
  );
}
