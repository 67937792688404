import { SET_TEST_SUITS, UPDATE_TEST_SUITS } from "..";

const INITIAL_STATE = {
    pralionId: null,
    projectName: null,
    applicationVersionId: null,
    autoScenarioProjectName: null,
    autoScenarioPralionId: null,
    autoScenarioNumberOfScenarios: null,
    myScenarioPralionId: null,
    myScenarioProjectName: null,
    myScenarioNumberOfScenarios: null,
}

export default function ReducerTestSuits(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_TEST_SUITS:
            return action.payload;
        case UPDATE_TEST_SUITS:
            return { ...state, ...action.payload }
        default:
            return state;
    }
}