import React, { Fragment, useEffect, useState } from 'react'
import { DATA_GENERATION, LEFTSIDE_APPLICATIONS, NEW_CATEGORY, ODD_LIST, SCENARIO_APPLICATION, SCENARIO_EXECUTION, SCEN_ANALYTICS, TEST_SUITS, TEST_VEHICLE, TOOLS_APPLICATION } from 'components/container/leftSideBarList'
import { Grid, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { GetCurrentPagePathname } from 'utils'
import { ModalX } from 'components'
import TestSuitsApp from 'pages/applications/applicationsComponent/testSuits'

export default function Applications() {
	const projectPath = GetCurrentPagePathname()
	const [isOpen, setOpen] = useState(false)
	const [state, setState] = useState({})

	useEffect(() => {
		localStorage.removeItem('map_id')
		localStorage.removeItem('observer_id')
		localStorage.removeItem('pralion_id')
	},[])

	const handleOpen = (data) => {
		setOpen(true);
		setState(data)
	}

  const handleFullPageRedirect = (application) => {
    window.location.pathname = application.url;
  };
  

	const handleClose = () => setOpen(false);

	return (
    <Fragment>
      <ModalX
        open={isOpen}
        close={handleClose}
        heading={state?.title}
        subheading={"Application"}
      >
        {state?.title === "NCAPScenarios" ? (
          <p>
            The application is currently not part of your license, please
            contact Sales for activation. <b>sales@automotive-ai.com</b>{" "}
          </p>
        ) : (
          <p>
            Please contact sales for the new Applications.{" "}
            <b>sales@automotive-ai.com</b>
          </p>
        )}
      </ModalX>
      <Grid className="container-fluid application-container d-flex gap-3">
        <Grid className="d-flex flex-column gap-3">
          <Grid className="odd-application">
            <Typography variant="h6" className="scenario-generation-heading">
              ODD
            </Typography>
            <Grid className="mt-2 mb-4 d-flex flex-column align-items-center">
              {ODD_LIST.map((application, index) => (
                // <ButtonBase>
                <Grid
                  item
                  xs={3}
                  sm={2}
                  linkcomponent={Link}
                  to={`${projectPath}/${application.url}`}
                  className={`d-flex flex-column justify-content-center application-box-size`}
                  key={index}
                  sx={{
                    border: "2px solid",
                    borderColor:
                      application.url !== "none"
                        ? "primary.main"
                        : "primary.main", // #e9ecef
                    backgroundColor:
                      application.url !== "none"
                        ? "secondary.dark"
                        : "secondary.dark", // rgb(255 255 255 / 44%)
                    textAlign: "center",
                    borderRadius: "10px",
                    m: 1,
                  }}
                >
                  {application.url === "none" ? (
                    <Link
                      className="Main-link-simple"
                      aria-disabled={true}
                      onClick={() => handleOpen(application)}
                    >
                      <div>{application.icons}</div>
                      <Typography
                        sx={{
                          color: "text.main",
                        }}
                      >
                        {application.title}
                      </Typography>
                    </Link>
                  ) : (
                    <Link
                      className="Main-link-simple"
                      aria-disabled={true}
                      to={`${projectPath}/${application.url}`}
                    >
                      <div>{application.icons}</div>
                      <Typography
                        sx={{
                          color: "text.main",
                          marginTop: "10px",
                        }}
                      >
                        {application.title}
                      </Typography>
                    </Link>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid className="test-vehicle-application">
            <Typography variant="h6" className="scenario-generation-heading">
              Test Vehicle
            </Typography>
            <Grid className="mt-2 mb-4 d-flex flex-column align-items-center">
              {TEST_VEHICLE.map((application, index) => (
                // <ButtonBase>
                <Grid
                  item
                  xs={3}
                  sm={2}
                  linkcomponent={Link}
                  to={`${projectPath}/${application.url}`}
                  className={`d-flex flex-column justify-content-center application-box-size`}
                  key={index}
                  sx={{
                    border: "2px solid",
                    borderColor:
                      application.url !== "none"
                        ? "primary.main"
                        : "primary.main", // #e9ecef
                    backgroundColor:
                      application.url !== "none"
                        ? "secondary.dark"
                        : "secondary.dark", // rgb(255 255 255 / 44%)
                    textAlign: "center",
                    borderRadius: "10px",
                    m: 1,
                  }}
                >
                  {application.url === "none" ? (
                    <Link
                      className="Main-link-simple"
                      aria-disabled={true}
                      onClick={() => handleOpen(application)}
                    >
                      <div>{application.icons}</div>
                      <Typography
                        sx={{
                          color: "text.main",
                        }}
                      >
                        {application.title}
                      </Typography>
                    </Link>
                  ) : (
                    <Link
                      className="Main-link-simple"
                      aria-disabled={true}
                      to={`${projectPath}/${application.url}`}
                    >
                      <div>{application.icons}</div>
                      <Typography
                        sx={{
                          color: "text.main",
                          marginTop: "8px",
                        }}
                      >
                        {application.title}
                      </Typography>
                    </Link>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid className="scenario-generation-application">
          <Typography variant="h6" className="scenario-generation-heading">
            Scenario Generation
          </Typography>
          <Grid container className="mt-2 mb-4 justify-content-center">
            {SCENARIO_APPLICATION.map((application, index) => (
              // <ButtonBase>
              <>
                <Grid
                  item
                  // xs={4}
                  sm={5}
                  linkcomponent={Link}
                  to={`${projectPath}/${application.url}`}
                  className={`d-flex flex-column justify-content-center application-box-size`}
                  key={index}
                  sx={{
                    border: "2px solid",
                    borderColor:
                      application.url !== "none"
                        ? "primary.main"
                        : "primary.main", // #e9ecef
                    backgroundColor:
                      application.url !== "none"
                        ? "secondary.dark"
                        : "secondary.dark", // rgb(255 255 255 / 44%)
                    textAlign: "center",
                    borderRadius: "10px",
                    m: 1,
                  }}
                >
                  {application.url === "none" ? (
                    <Link
                      className="Main-link-simple"
                      aria-disabled={true}
                      onClick={() => handleOpen(application)}
                    >
                      <div>{application.icons}</div>
                      <Typography
                        sx={{
                          color: "text.main",
                        }}
                      >
                        {application.title}
                      </Typography>
                    </Link>
                  ) : (
                    <Link
                      className="Main-link-simple"
                      aria-disabled={true}
                      to={`${projectPath}/${application.url}`}
                    >
                      <div>{application.icons}</div>
                      <Typography
                        sx={{
                          color: "text.main",
                        }}
                      >
                        {application.title}
                      </Typography>
                    </Link>
                  )}
                </Grid>
                {application.border ? <hr style={{ width: "20em" }} /> : ""}
              </>
            ))}
          </Grid>
        </Grid>
        <Grid className="test-suits-application">
          <Typography variant="h6" className="scenario-generation-heading">
            TestSuites
          </Typography>
          <TestSuitsApp />
        </Grid>
        <Grid className="scenario-run-application">
          <Typography variant="h6" className="scenario-generation-heading">
            ScenRun
          </Typography>
          <Grid container className="mt-2 mb-4 d-flex justify-content-center">
            {SCENARIO_EXECUTION.map((application, index) => (
              // <ButtonBase>
              <>
                <Grid
                  item
                  // xs={3}
                  sm={5}
                  linkcomponent={Link}
                  to={`${projectPath}/${application.url}`}
                  className={`d-flex flex-column justify-content-center application-box-size`}
                  key={index}
                  sx={{
                    border: "2px solid",
                    borderColor:
                      application.url !== "none"
                        ? "primary.main"
                        : "primary.main", // #e9ecef
                    backgroundColor: application.isDisabled
                      ? "#c0cdd9ad"
                      : "secondary.dark", // rgb(255 255 255 / 44%)
                    textAlign: "center",
                    borderRadius: "10px",
                    m: 1,
                  }}
                >
                  {application.url === "none" ? (
                    <Link
                      className="Main-link-simple"
                      aria-disabled={true}
                      onClick={() => handleOpen(application)}
                    >
                      <div>{application.icons}</div>
                      <Typography
                        sx={{
                          color: "text.main",
                        }}
                      >
                        {application.title}
                      </Typography>
                    </Link>
                  ) : (
                    <Link
                      className="Main-link-simple"
                      aria-disabled={true}
                      to={`${projectPath}/${application.url}`}
                    >
                      <div>{application.icons}</div>
                      <Typography
                        sx={{
                          color: "text.main",
                        }}
                      >
                        {application.title}
                      </Typography>
                    </Link>
                  )}
                </Grid>
                {application.border ? <hr style={{ width: "20em" }} /> : ""}
              </>
            ))}
          </Grid>
        </Grid>
        <Grid className="d-flex flex-column gap-3">
          <Grid className="scen-analytics-application">
            <Typography variant="h6" className="scenario-generation-heading">
              ScenAlytics
            </Typography>
            <Grid className="mt-2 mb-4 d-flex flex-column align-items-center">
              {SCEN_ANALYTICS.map((application, index) => (
                // <ButtonBase>
                <Grid
                  item
                  xs={3}
                  sm={2}
                  linkcomponent={Link}
                  to={`${projectPath}/${application.url}`}
                  className={`d-flex flex-column justify-content-center application-box-size`}
                  key={index}
                  sx={{
                    border: "2px solid",
                    borderColor:
                      application.url !== "none"
                        ? "primary.main"
                        : "primary.main", // #e9ecef
                    backgroundColor:
                      application.url !== "none"
                        ? "secondary.dark"
                        : "secondary.dark", // rgb(255 255 255 / 44%)
                    textAlign: "center",
                    borderRadius: "10px",
                    m: 1,
                  }}
                >
                  {application.url === "none" ? (
                    <Link
                      className="Main-link-simple"
                      aria-disabled={true}
                      onClick={() => handleOpen(application)}
                    >
                      <div>{application.icons}</div>
                      <Typography
                        sx={{
                          color: "text.main",
                        }}
                      >
                        {application.title}
                      </Typography>
                    </Link>
                  ) : (
                    <Link
                      className="Main-link-simple"
                      aria-disabled={true}
                      to={`${projectPath}/${application.url}`}
                    >
                      <div>{application.icons}</div>
                      <Typography
                        sx={{
                          color: "text.main",
                        }}
                      >
                        {application.title}
                      </Typography>
                    </Link>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid className="data-generation-application">
            <Typography variant="h6" className="scenario-generation-heading">
              Data Generation
            </Typography>
            <Grid className="mt-2 mb-4 d-flex flex-column align-items-center">
              {DATA_GENERATION.map((application, index) => (
                // <ButtonBase>
                <Grid
                  item
                  xs={3}
                  sm={2}
                  linkcomponent={Link}
                  to={`${projectPath}/${application.url}`}
                  className={`d-flex flex-column justify-content-center application-box-size`}
                  key={index}
                  sx={{
                    border: "2px solid",
                    borderColor:
                      application.url !== "none"
                        ? "primary.main"
                        : "primary.main", // #e9ecef
                    backgroundColor: application.isDisabled
                      ? "#c0cdd9ad"
                      : "secondary.dark", // rgb(255 255 255 / 44%)
                    textAlign: "center",
                    borderRadius: "10px",
                    m: 1,
                  }}
                >
                  {application.url === "none" ? (
                    <Link
                      className="Main-link-simple"
                      aria-disabled={true}
                      onClick={() => handleOpen(application)}
                    >
                      <div>{application.icons}</div>
                      <Typography
                        sx={{
                          color: "text.main",
                        }}
                      >
                        {application.title}
                      </Typography>
                    </Link>
                  ) : (
                    <Link
                      className="Main-link-simple"
                      aria-disabled={true}
                      to={`${projectPath}/${application.url}`}
                    >
                      <div>{application.icons}</div>
                      <Typography
                        sx={{
                          color: "text.main",
                        }}
                      >
                        {application.title}
                      </Typography>
                    </Link>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="tools-section application-container d-flex">
        <Grid>
          <Typography
            variant="h6"
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              padding: "12px",
              textAlign: "center",
              width: "140px",
              height: "3rem",
              lineHeight: 1,
              borderTopLeftRadius: "6px",
            }}
          >
            Tools
          </Typography>
        </Grid>
        <Grid className="d-flex flex-row mt-5 mb-2">
          {TOOLS_APPLICATION.map((application, index) => (
            // <ButtonBase>
            <Grid
              item
              xs={3}
              sm={2}
              linkcomponent={Link}
              to={`${projectPath}/${application.url}`}
              className={`d-flex flex-column justify-content-center application-box-size`}
              key={index}
              style={{ marginRight: "4rem" }}
              sx={{
                border: "2px solid",
                borderColor:
                  application.url !== "none" ? "primary.main" : "primary.main", // #e9ecef
                backgroundColor:
                  application.url !== "none"
                    ? "secondary.dark"
                    : "secondary.dark", // rgb(255 255 255 / 44%)
                textAlign: "center",
                borderRadius: "10px",
                m: 1,
              }}
            >
              {application.url === "none" ? (
                <Link
                  className="Main-link-simple"
                  aria-disabled={true}
                  onClick={() => handleOpen(application)}
                >
                  <div>{application.icons}</div>
                  <Typography
                    sx={{
                      color: "text.main",
                    }}
                  >
                    {application.title}
                  </Typography>
                </Link>
              ) : application.title === "Versatile" ? (
                <Link
                  className="Main-link-simple"
                  aria-disabled={true}
                  onClick={() => handleFullPageRedirect(application)}
                >
                  <div>{application.icons}</div>
                  <Typography
                    sx={{
                      color: "text.main",
                    }}
                  >
                    {application.title}
                  </Typography>
                </Link>
              ) : (
                <Link
                  className="Main-link-simple"
                  aria-disabled={true}
                  to={`${projectPath}/${application.url}`}
                >
                  <div>{application.icons}</div>
                  <Typography
                    sx={{
                      color: "text.main",
                    }}
                  >
                    {application.title}
                  </Typography>
                </Link>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Fragment>
  );
}
