import React from 'react';
import Typography from '@mui/material/Typography';

export const H1 = (props) => (
  <Typography variant="h1" {...props}>
    {props?.children || '<h1>'}
  </Typography>
);

export const H2 = (props) => (
  <Typography variant="h2" {...props}>
    {props?.children || '<h2>'}
  </Typography>
);

export const H3 = (props) => (
  <Typography variant="h3" {...props}>
    {props?.children || '<h3>'}
  </Typography>
);

export const H4 = (props) => (
  <Typography variant="h4" {...props}>
    {props?.children || '<h4>'}
  </Typography>
);

export const H5 = (props) => (
  <Typography variant="h5" {...props}>
    {props?.children || '<h5>'}
  </Typography>
);

export const H6 = (props) => (
  <Typography variant="h6" {...props}>
    {props?.children || '<h6>'}
  </Typography>
);

export const Subtitle1 = (props) => (
  <Typography variant="subtitle1" {...props}>
    {props?.children || '<subtitle1>'}
  </Typography>
);

export const Subtitle2 = (props) => (
  <Typography variant="subtitle2" {...props}>
    {props?.children || '<subtitle2>'}
  </Typography>
);

export const Body1 = (props) => (
  <Typography variant="body1" {...props}>
    {props?.children || '<body1>'}
  </Typography>
);

export const Body2 = (props) => (
  <Typography variant="body2" {...props}>
    {props?.children || '<body2>'}
  </Typography>
);

export const ButtonText = (props) => (
  <Typography variant="button" display="block" {...props}>
    {props?.children || '<button text>'}
  </Typography>
);

export const Caption = (props) => (
  <Typography variant="caption" display="block" {...props}>
    {props?.children || '<caption text>'}
  </Typography>
);

export const Overline = (props) => (
  <Typography variant="overline" display="block" {...props}>
    {props?.children || '<overline>'}
  </Typography>
);

export const TitleHeading = ({ children }) => (
  <Caption color={'secondary'} className={`fw-bold`}>
    {children}
  </Caption>
);

export const TitleHeading2 = ({ children }) => (
  <Body1 color={'secondary'} className={`fw-bold`}>
    {children}
  </Body1>
);