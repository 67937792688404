import { ArticleRounded } from "@mui/icons-material";
import { Grid, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { AlertX, IconButtonX, LinkButtonX, ProgressX } from "components";
import ButtonX from "components/buttonX";
import { FETCH_ODDS, GET_ODD_IMAGES, GET_SHOW_ODDS_MAPS } from "constants/endpoints";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { httpPost } from "utils/httpMethods";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { ReduxUpdateDSpaceJourney } from "services/redux-store/actions/actions-dspace";

// This page receives the id or URL from the map screen
// call another api after this, "odd on route" using an id or url returns searched_odd_on_route.geojson
function DSpaceOdds({ ReduxUpdateDSpaceJourney, ReduxDSpace }) {
	const navigate = useNavigate();
	const location = useLocation();

	const [oddsList, setOddsList] = useState([]);
	const [selectedOddId, setSelectedOddId] = useState(null);
	const [oddGeojsonURL, setOddGeojsonURL] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		console.log("ReduxDSpace", ReduxDSpace);
		if (ReduxDSpace.searchType === "route") {
			// route odd loading portion
			fetchOdds(ReduxDSpace.mapId);
		} else if (ReduxDSpace.searchType === "odd") {
			// polygons received loading portion
			loadPolygonsList(ReduxDSpace.geojson);
		}
	}, [ReduxDSpace]);

	const fetchOdds = async (mapId) => {
		setLoading(true);
		await httpPost({ url: FETCH_ODDS, payload: { map_id: mapId } })
			.then((resp) => {
				console.log("resp odds", resp);
				setOddGeojsonURL(resp.data.data.geo_json);
				fetch(resp.data.data.geo_json)
					.then((response) => response.json())
					.then((json) => {
						setOddsList(json.features);
						console.log("data", json);
						setLoading(false);
					});
			})
			.catch((err) => {
				toast.error(`${err}. Please try again later`);
				setLoading(false);
			});
	};

	const loadPolygonsList = (geojson) => {
		fetch(geojson)
			.then((resp) => resp.json())
			.then((json) => {
				setOddsList(json.features);
				setOddGeojsonURL(geojson);
			});
	};

	const onClickItemCheckbox = (index) => {
		setSelectedOddId(index === selectedOddId ? null : index);
	};

	const onClickNext = () => {
		ReduxUpdateDSpaceJourney({ 
			geojsonAgainstMapId: oddGeojsonURL,
			selectedOdd: oddsList[selectedOddId] 
		});
		navigate(AUTHENTICATED_ROUTES.dSpaceSpawningStrategies);
	};

	return (
		<>
			<div className={`container-fuild`}>
				<div className={`row`}>
					<div className={`col-12 d-flex justify-content-between`}>
						<div className={`d-flex`}></div>
						<div className={`d-flex`}>
							<ButtonX className={`me-2`} onClick={() => navigate(-1)} variant={"outlined"}>
								Back
							</ButtonX>
							<ButtonX onClick={onClickNext} disabled={selectedOddId === null}>
								Next
							</ButtonX>
						</div>
					</div>
				</div>
				<div className={`row mt-3`}>
					{loading ? (
						<ProgressX />
					) : oddsList && oddsList.length === 0 ? (
						<AlertX text="No ODDs found" badge={"info"} />
					) : (
						<ImageList sx={{ width: "100%", maxHeight: "100vh" }} gap={10} cols={5} rowHeight={250}>
							{oddsList.map((item, i) => (
								<ImageListItem key={item.id} className={`m-1`}>
									<ImageListItemBar
										sx={{
											background: "linear-gradient(to bottom, rgba(35, 55, 75, 0.7) 0%, " + "rgba(35, 55, 75, 0.3) 70%, rgba(35, 55, 75, 0) 100%)",
											borderRadius: "10px",
										}}
										position="top"
										actionPosition="left"
										actionIcon={
											<IconButtonX
												sx={{ color: "white" }}
												icon={i === selectedOddId ? "CheckBoxRounded" : "CheckBoxOutlineBlankRounded"}
												onClick={() => onClickItemCheckbox(i)}
											/>
										}
									/>
									<img
										src={`${GET_SHOW_ODDS_MAPS}/${item.properties.id}`}
										alt={item.properties.odd_keyword}
										loading="lazy"
										style={{ borderRadius: "10px", borderColor: "fff" }}
									/>
									<ImageListItemBar
										sx={{
											background: "linear-gradient(to bottom, rgba(35, 55, 75, 0.7) 100%, " + "rgba(35, 55, 75, 0.3) 70%, rgba(35, 55, 75, 0) 0%)",
											borderEndEndRadius: "10px",
										}}
										position="bottom"
										actionPosition="right"
										title={item.properties.odd_keyword}
									/>
								</ImageListItem>
							))}
						</ImageList>
					)}
				</div>
			</div>
		</>
	);
}
function mapStateToProps(state) {
	return {
		ReduxDSpace: state.DSpace
	}
}
export default connect(mapStateToProps, { ReduxUpdateDSpaceJourney })(DSpaceOdds);
