import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { GetCurrentPagePathname } from "utils";
import { useParams, useNavigate } from "react-router-dom";
import { Fragment } from "react";
import {
  getScenariosByIds,
  getTestSuits,
} from "endpointServices/testSuitServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import testSuits from "assets/icons/testSuits.png";
import { ButtonX } from "components";
import { ReduxUpdateHighFidelityJourney } from "services/redux-store/actions/actions-highFidelity";
import { connect } from "react-redux";
import STORE from "services/redux-store/store";

const HighFidelityTestSuits = ({ ReduxUpdateHighFidelityJourney }) => {
  const { HighFidelity, Projects } = STORE.getState();
  const { vehicleSuits } = HighFidelity;
  const { projectId } = Projects;
  const param = useParams();
  const navigate = useNavigate();
  const projectPath = GetCurrentPagePathname();
  const [isFetching, setFetching] = useState(false);
  const [fetchCounts, setFetchCount] = useState(false);
  const [selectSuite, setSelectSuite] = useState("");
  const [suits, setSuits] = useState([]);
  const [isSuitsSelected, setSuitsSelected] = useState(false);

  useEffect(() => {
    getTestSuitsList();
  }, []);

  const selectSuits = (data) => {
    console.log("data", data);
    setSelectSuite(data);
    ReduxUpdateHighFidelityJourney({ vehicleSuits: data });
    suitsSelected(true);
  };

  const suitsSelected = () => {
    setSuitsSelected(true);
  };

  useEffect(() => {
    if (vehicleSuits) {
      setSelectSuite(vehicleSuits);
      setSuitsSelected(true);
    } else {
      setSuitsSelected(false);
    }
  }, [vehicleSuits]);

  const getTestSuitsList = async () => {
    setFetching(true);
    const projectId = parseInt(param.id);
    const response = await getTestSuits(projectId);
    if (response?.status === 200) {
      setFetching(false);
      const { data } = response?.data;
      const newApplications = data.applications || [];
      const filterSuits = newApplications.filter(
        (item) => item.application_version_id !== null
      );
      setSuits(filterSuits);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setFetching(false);
    }
  };

  const testSuiteScenariosCount = async () => {
    console.log("selectSuite", selectSuite);
    setFetchCount(true);
    const response = await getScenariosByIds(selectSuite.pralion_id);
    if (response?.status === 200) {
      const counts = response?.data?.data?.count || 0;
      if (counts <= 10) {
        console.log("counts", counts);
        toast.info(`${selectSuite.name} suit have ${counts} scenarios`);
        setTimeout(() => {
        setFetchCount(false);
          navigate(
            `/projects/${param.id}/applications/high-fidelity/define-observer`
          );
        }, 2000);
      } else {
        toast.info(
          `${selectSuite.name} suit have ${counts} scenarios. Greater then 10 scenarios cannot be selected`
        );
        setFetchCount(false);
      }
      console.log("responseer", response);
    }
  };

  const onClickNext = () => {
    navigate(
      `/projects/${param.id}/applications/high-fidelity/define-observer`
    );
  };

  const onClickBack = () => {
    navigate(
      `/projects/${projectId}/applications/high-fidelity/vehicle-selection`
    );
  };

  return (
    <Fragment>
      <Grid item sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            color: "text.main",
            fontWeight: "bold",
            marginRight: "10px",
            textTransform: "none",
            fontSize: "inherit",
          }}
          onClick={onClickBack}
        >
          Back
        </Button>
        <ButtonX className={`ms-2`} onClick={testSuiteScenariosCount} disabled={!isSuitsSelected || fetchCounts}>
          Next {fetchCounts ?<CircularProgress size={20} style={{ marginLeft: "8px" }} color="inherit" />: '' }
        </ButtonX>
      </Grid>
      <div>
        <Divider style={{ marginTop: "1rem" }} />
      </div>

      <Grid
        container
        xs={12}
        sm={12}
        sx={{
          maxHeight: "42rem",
          borderColor: "secondary.dark",
          backgroundColor: "secondary.dark",
          borderRadius: "6px",
          marginTop: "2rem",
        }}
      >
        <Grid container direction="column">
          <Typography
            variant="h6"
            sx={{ marginTop: "1rem", marginLeft: "1rem" }}
          >
            Select Test Suites
          </Typography>
          <Typography
            variant="caption"
            sx={{ marginLeft: "1rem", marginBottom: "1rem" }}
          >
            The defined TestSuites can only be referred
          </Typography>
        </Grid>
        <Grid
          container
          xs={12}
          sm={12}
          sx={{
            height: "32rem",
            overflowY: "auto",
            overflowX: "hidden",
            border: "2px solid",
            borderColor: "primary.main",
            backgroundColor: "secondary.dark",
            borderRadius: "6px",
            marginLeft: "1rem",
            marginRight: "1rem",
            marginBottom: "1rem",
          }}
        >
          {isFetching ? (
            <div style={{ position: "absolute", top: "40%", left: "50%" }}>
              <CircularProgress size={40} />
            </div>
          ) : (
            suits.map((application, index) => (
              <>
                <Grid
                  item
                  sm={1.5}
                  linkcomponent={Link}
                  to={`${projectPath}/${application.url}`}
                  className={`d-flex flex-column justify-content-center high-fidelity-application-box-size`}
                  key={index}
                  sx={{
                    height: "12rem",
                    border: "2px solid",
                    borderColor:
                      application.url !== "none"
                        ? "primary.main"
                        : "primary.main",
                    backgroundColor:
                      application.url !== "none"
                        ? "secondary.dark"
                        : "secondary.dark",
                    textAlign: "center",
                    borderRadius: "10px",
                    m: 2,
                    marginBottom: "4rem",
                  }}
                >
                  <Button
                    onClick={() => selectSuits(application, application.name)}
                  >
                    <Link
                      className="Main-link-simple"
                      aria-disabled={true}
                      // to={`${projectPath}/${encodeURIComponent(
                      //   application.title === undefined
                      //     ? application.name
                      //     : application.title
                      // )}`}
                    >
                      <div style={{ marginTop: "2.3rem" }}>
                        {application.icons === undefined ? (
                          <img src={testSuits} alt="icons" />
                        ) : (
                          application.icons
                        )}
                      </div>
                      <Typography
                        sx={{
                          color: "text.main",
                        }}
                      >
                        {application.title === undefined
                          ? application.name
                          : application.title}
                      </Typography>
                    </Link>
                  </Button>
                  {selectSuite.name === application.name ? (
                    <Link
                    // to={`/projects/${param.id}/applications/test-vehicles/replica-sim`}
                    // state={{ vehicleData: vehicle.model_path }}
                    >
                      <Button
                        variant="contained"
                        sx={{
                          width: "100%",
                          textTransform: "none",
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                          // marginTop: "2.3rem",
                          "&:hover": {
                            backgroundColor: "primary.main",
                          },
                        }}
                        onClick={suitsSelected}
                      >
                        Suite Selected
                      </Button>
                    </Link>
                  ) : (
                    ""
                  )}
                </Grid>
              </>
            ))
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default connect(null, { ReduxUpdateHighFidelityJourney })(
  HighFidelityTestSuits
);
