import React, { Fragment } from 'react'
import RealSimTable from './realSimComponent/realSimTable'

export default function RealSim() {
    return (
        <Fragment>
            <RealSimTable />
        </Fragment>
    )
}
