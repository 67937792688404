import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { RealSimTableData } from "utils";
import { Button, Grid, Radio } from "@mui/material";
import { useParams, useNavigate, Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { SetInfoToLocalStorage } from "utils";
import { getRealSimData } from "endpointServices/realToSimServices";
import dateConverter from "utils/dateConverter";


export default function RealSimTable() {
  const param = useParams()
  let navigator = useNavigate()
  const [list, setList] = React.useState([])
  const [isFetching, setFetching] = React.useState(false);

  const goto = (data) => {
    window.open(data.rosbag, "_blank")
  }


  React.useEffect(() => {
    getAllList()
  }, [])

  const getAllList = async () => {
    setFetching(true)
    const response = await getRealSimData()
    if (response?.status === 200) {
      setFetching(false)
      console.log('response', response)
      const lists = response.data.data || []
      setList(lists)
    } else {
      setList([])
      setFetching(false)
    }
  }

  const columns = [
    {
      field: "id",
      headerName: <span className="heading-2">Id</span>,
      width: 310,
      renderCell: (params) => (
        <Grid>
          <Button
            className="Main-link-simple"
            onClick={() => goto(params.row)}
            sx={{ color: "white" }}
          >
            {params.value}
          </Button>
        </Grid>
      ),
    },
    {
      field: "description",
      renderHeader: () => <span className="heading-2">{"Description"}</span>,
      width: 360,
      renderCell: (params) => (
        <span className="row-heading">Real to sim data description</span>
      ),
    },
    {
      field: "date_created",
      renderHeader: () => <span className="heading-2">{"Created At"}</span>,
      width: 183,
      renderCell: (params) => (
        <span className="row-heading">{dateConverter(params.value)}</span>
      ),
    },
  ];

  return (
    <div className="mt-4">
      <DataGrid
        getRowId={(row) => row.id}
        rows={list}
        columns={columns}
        loading={isFetching}
        autoHeight={true}
        className="main-table-height"
        sx={{
          border: "none",
          borderRadius: "12px",
          backgroundColor: "secondary.dark",
          "& .MuiDataGrid-cell": {
            color: "text.main",
          },
          "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows":
              { marginTop: "15px" },
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          },
          sorting: {
            sortModel: [{ field: 'date_created', sort: 'desc' }],
          },
        }}
      />
    </div>
  );
}
