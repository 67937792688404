import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Slider, Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { CheckboxX, IconButtonX, Typos } from "components";
import _ from "lodash";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { ReduxUpdateEducAgentsJourney } from "services/redux-store/actions";

function mapStateToProps(state) { 
	return {
		DSpaceTrafficConfigs: state.DSpaceTrafficConfigs,
		ReduxEducAgents: state.EducAgents
	}
}

function AlgorithmSettings({ selectedVehicle, selectedDriverProfileIndexAndType, saveUpdatedDataCallback, ReduxEducAgents, ReduxUpdateEducAgentsJourney, canExecute }) {


	const onEditAgrressivness = (e) => {
		const tempAsTrafficConfigs = _.cloneDeep({ ...ReduxEducAgents?.AsTrafficConfig?.trafficConfig });
		const profileToEdit = _.cloneDeep(ReduxEducAgents?.AsTrafficConfig?.trafficConfig[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type])
		profileToEdit[selectedDriverProfileIndexAndType.index]['settings']['aggressiveness'][e.target.name] = e.target.value;
		tempAsTrafficConfigs[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type] = profileToEdit;
		ReduxUpdateEducAgentsJourney({ AsTrafficConfig: { ...ReduxEducAgents.AsTrafficConfig, trafficConfig: tempAsTrafficConfigs } });
	};

	const onClickCloseAlgorithmSettings = () => {
		saveUpdatedDataCallback()
	}

	const onEditTargetSpeed = (value) => {
		console.log('value', value, selectedVehicle);
		const tempAsTrafficConfigs = _.cloneDeep({ ...ReduxEducAgents?.AsTrafficConfig?.trafficConfig });
		const profileToEdit = _.cloneDeep(ReduxEducAgents?.AsTrafficConfig?.trafficConfig[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type])
		profileToEdit[selectedDriverProfileIndexAndType.index]['settings']['target_speed'] = value;
		tempAsTrafficConfigs[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type] = profileToEdit;
		ReduxUpdateEducAgentsJourney({ AsTrafficConfig: { ...ReduxEducAgents.AsTrafficConfig, trafficConfig: tempAsTrafficConfigs } });
	}

	const onEditTargetSpeedFromButtons = (incOrDec) => { // true for increment
		const tempAsTrafficConfigs = _.cloneDeep({ ...ReduxEducAgents?.AsTrafficConfig?.trafficConfig });
		const profileToEdit = _.cloneDeep(ReduxEducAgents?.AsTrafficConfig?.trafficConfig[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type])
		profileToEdit[selectedDriverProfileIndexAndType.index]['settings']['target_speed'] = incOrDec ? profileToEdit[selectedDriverProfileIndexAndType.index]['settings']['target_speed'] + 1 : profileToEdit[selectedDriverProfileIndexAndType.index]['settings']['target_speed'] - 1;
		tempAsTrafficConfigs[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type] = profileToEdit;
		if (profileToEdit[selectedDriverProfileIndexAndType.index]['settings']['target_speed'] >= 10 && profileToEdit[selectedDriverProfileIndexAndType.index]['settings']['target_speed'] <= 200) {
			ReduxUpdateEducAgentsJourney({ AsTrafficConfig: { ...ReduxEducAgents.AsTrafficConfig, trafficConfig: tempAsTrafficConfigs } });
		}
	}

	const onEditRuleAbiding = (value) => {
		const tempAsTrafficConfigs = _.cloneDeep({ ...ReduxEducAgents?.AsTrafficConfig?.trafficConfig });
		const profileToEdit = _.cloneDeep(ReduxEducAgents?.AsTrafficConfig?.trafficConfig[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type])
		profileToEdit[selectedDriverProfileIndexAndType.index]['settings']['rule_abiding'] = value;
		tempAsTrafficConfigs[selectedVehicle]['driverProfiles'][selectedDriverProfileIndexAndType.type] = profileToEdit;
		ReduxUpdateEducAgentsJourney({ AsTrafficConfig: { ...ReduxEducAgents.AsTrafficConfig, trafficConfig: tempAsTrafficConfigs } });
	}

    const calculateDriverProfileSums = () => {
		const sums = [];
		const aggressiveness = ReduxEducAgents?.AsTrafficConfig?.trafficConfig[selectedVehicle].driverProfiles[selectedDriverProfileIndexAndType.type][selectedDriverProfileIndexAndType.index]['settings']['aggressiveness'];
        const totalSum = Math.round(Object.values(aggressiveness).reduce((acc, item) => acc + item, 0) * 100) / 100;
		return {is1: totalSum === 1, sums, total: totalSum};
	}

    const aggressivenessValuesAudit = calculateDriverProfileSums();

	canExecute(aggressivenessValuesAudit.is1);


	return (
		<div className={``}>
			<div className={`d-flex justify-content-between align-items-center`}>
				<Typos.H6>Algorithm Settings</Typos.H6>
				<IconButtonX disabled={!aggressivenessValuesAudit.is1} icon={"ClearRounded"} onClick={onClickCloseAlgorithmSettings} />
			</div>
			<hr />
			<div className={`d-flex justify-content-between`}>
				<Typos.Body1 className={`fw-bold`}>Target Speed ({ReduxEducAgents?.AsTrafficConfig?.trafficConfig[selectedVehicle]["driverProfiles"][selectedDriverProfileIndexAndType.type][selectedDriverProfileIndexAndType.index]["settings"]["target_speed"]} km/h)</Typos.Body1>
				<ToggleButtonGroup disabled={ReduxEducAgents.readonly} size="small">
					<ToggleButton onClick={() => onEditTargetSpeedFromButtons(false)}><ArrowBackIos sx={{ width: "10px", height: "10px" }} fontSize="small" /></ToggleButton>
					<ToggleButton onClick={() => onEditTargetSpeedFromButtons(true)}><ArrowForwardIos sx={{ width: "10px", height: "10px" }} fontSize="small" /> </ToggleButton>
				</ToggleButtonGroup>
			</div>
			<Slider
				name="target_speed"
				color="primary"
				value={
					ReduxEducAgents?.AsTrafficConfig?.trafficConfig[selectedVehicle]["driverProfiles"][selectedDriverProfileIndexAndType.type][selectedDriverProfileIndexAndType.index]["settings"][
						"target_speed"
					]
				}
				onChange={(e) => onEditTargetSpeed(e.target.value)}
				disabled={ReduxEducAgents.readonly}
				min={10}
				max={200}
				valueLabelDisplay="auto"
				marks={[
					{ value: 10, label: 10 },
					{ value: 60, label: 60 },
					{ value: 100, label: 100 },
					{ value: 200, label: 200 },
				]}
			/>
			<hr />
			<Typos.Body1 className={`fw-bold`}>Aggressiveness</Typos.Body1>
			<Typos.Caption>Values ranges from 0 to 1</Typos.Caption>
			<Typos.Caption className={`d-flex align-items-center`}>
				Complete sum of{" "}
				<Typos.Caption color={aggressivenessValuesAudit.is1 ? "lightgreen" : "red"} className={`mx-1`}>
					({aggressivenessValuesAudit.total}/1)
				</Typos.Caption>{" "}
				to proceed
			</Typos.Caption>
			{ReduxEducAgents?.AsTrafficConfig?.trafficConfig !== null &&
				Object.keys(
					ReduxEducAgents?.AsTrafficConfig?.trafficConfig[selectedVehicle]["driverProfiles"][selectedDriverProfileIndexAndType.type][selectedDriverProfileIndexAndType.index]["settings"][
						"aggressiveness"
					]
				).map((attr, i) => {
					return (
						<Fragment key={i}>
							<Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
								<Typos.Body1 className={`fw-bold d-flex align-items-end`}>
									{attr}
									<Typos.Caption className={`ms-2`}>(%)</Typos.Caption>
								</Typos.Body1>
								<Slider
									name={attr}
									size="small"
									valueLabelDisplay="auto"
									min={0}
									max={1}
									step={0.1}
									disabled={ReduxEducAgents.readonly}
									marks
									value={
										ReduxEducAgents?.AsTrafficConfig?.trafficConfig[selectedVehicle]["driverProfiles"][selectedDriverProfileIndexAndType.type][selectedDriverProfileIndexAndType.index][
											"settings"
										]["aggressiveness"][attr]
									}
									onChange={onEditAgrressivness}
								/>
							</Stack>
						</Fragment>
					);
				})}
			<hr />
			<CheckboxX
				name="rule_abiding"
				label={"Rule Abiding"}
				labelplacement={"end"}
				disabled={ReduxEducAgents.readonly}
				checked={
					ReduxEducAgents?.AsTrafficConfig?.trafficConfig[selectedVehicle]["driverProfiles"][selectedDriverProfileIndexAndType.type][selectedDriverProfileIndexAndType.index]["settings"][
						"rule_abiding"
					] === 1
				}
				onChange={(e) => onEditRuleAbiding(e.target.checked === true ? 1 : -1)}
			/>
		</div>
	);
}
export default connect(mapStateToProps, { ReduxUpdateEducAgentsJourney }) (AlgorithmSettings);
