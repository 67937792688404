import { GET_TRACEABILITY } from "constants/endpoints"
import { httpGet, httpPost, httpPut } from "utils/httpMethods"

export const getLowFidelityTrace = async (body, pagination) => {
    const {pageNumber = 1, testsPerPage = 10 } = pagination || {}
    return await httpGet({ url: `${GET_TRACEABILITY}?project_id=${body}&page=${pageNumber}&page_size=${testsPerPage}`, payload: body })
        .then((res) => {
            return res
            
        })
        .catch((err) => {
            return err?.response
        })
}