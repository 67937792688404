import React, { Fragment,useState,useEffect  } from "react";
import { Button, Divider, Grid, Typography,List,ListItemButton,ListItemIcon,ListItemText, Box, Alert,Collapse, Tabs, Tab } from "@mui/material";
import CodeEditor from "components/codeEditor";
import { Link, useNavigate, useParams } from "react-router-dom";
import CodeIcon from '@mui/icons-material/Code';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FunctionsIcon from '@mui/icons-material/Functions';
import { getCodeForObserver,getCodeForObserverMetric,postCodeForObserver } from "endpointServices/autoScenarioServices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ReduxUpdateMyScenarioJourney} from "services/redux-store/actions/actions-myscenario"
import { connect } from "react-redux"
import { AUTHENTICATED_ROUTES } from "router/constants";
import STORE from "services/redux-store/store";
import { ReadOnlyTag } from "components";

const documentationUrl = '/replicaDocument/html/index.shtml';


const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const DefineSecnarioObserver = ({ReduxUpdateMyScenarioJourney}) =>{
  const navigator = useNavigate();
  const param = useParams()
  const { MyScenario, Projects } = STORE.getState();

  const [codeSample, setCodeSample] = useState({
    codeEditor: '',
  });

  const [metric, setMetric] = useState([])
  const [open, setOpen] = useState(false);
  const [collapseId, setCollapseId] = useState('');

  const [previousObject, setPreviousObject] = useState({})
  const [isNextButtonDisabled, setNextButtonDisabled] = useState(true);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  console.log('previousObject', previousObject)
  useEffect(() => {
    getCodeSample()
    getCodeObserverMetric()
    localStorage.removeItem('observer_id')
  },[])



  const handleClick = (id) => {
    setCollapseId(id)
    setOpen(!open);
  };

  const getCodeSample = async() => {
    const response = await getCodeForObserver()
    if (response?.status === 200) {
      const sample = response?.data.data
      const code = sample[0]?.observer
      const decodeData = atob(code)
      console.log('decodeData', decodeData)
      setCodeSample({
        codeEditor:decodeData
      })
    } else {
      const err = response?.message || 'Request failed'
      toast.error(`${err}. Please try again later`)
      setCodeSample({
        codeEditor: '',
      });
    }
  } 

  const getCodeObserverMetric = async() => {
    const response = await getCodeForObserverMetric()
    if (response?.status === 200) {
      const sample = response?.data.data
      setMetric(sample)
    } else {
      const err = response?.message || 'Request failed'
      toast.error(`${err}. Please try again later`)
    }
  } 


  const handleCodeInput = React.useCallback((value) => {
    setCodeSample((prevState) => ({
      ...prevState,
      codeEditor: value,
    }));
  }, []);

  const presetListRender = () => {
    return metric.map((row, idx) => {
      return (
        <>
          <ListItemButton key={idx} sx={{ borderBottom: '1px solid #5c616642' }} >
            <ListItemIcon>
              {idx}
              <CodeIcon sx={{ marginLeft: '.5em' }} />
            </ListItemIcon>
            <ListItemText primary={row.name} onClick={() => handleClick(idx)} />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={collapseId == idx ? open : false} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemIcon>
                  <FunctionsIcon sx={{ marginLeft: '.5em' }} />
                </ListItemIcon>
                <ListItemText primary={row.input} secondary={row.description} />
              </ListItemButton>
            </List>
          </Collapse>
        </>
      )
    })
  }

  const onSubmitaCodeEditor = async (e) => {
    e.preventDefault();
    const payload = {
      "code": btoa(codeSample.codeEditor),
    }
    console.log("Payload:", payload);
    const response = await postCodeForObserver(payload)
    if (response?.status === 200) {
      const { observer_id } = response?.data.data
      console.log('observer_id', observer_id)
      ReduxUpdateMyScenarioJourney({observerId: observer_id})
      localStorage.setItem('observer_id',observer_id)
      toast.success("Observer executed successfully")
      setNextButtonDisabled(false);
    } else {
      const err = response?.message || 'Failed to sumbit request'
      toast.error(`${err}. Please try again later`)
      setNextButtonDisabled(true)
    }
  };

  const onClickBack = () => {
		if (MyScenario.readonly && MyScenario.usingFile === true) {
      navigator(AUTHENTICATED_ROUTES
				.interactiveMap
				.replace(":id", Projects.projectId)
				.replace(":aid", MyScenario.pralionId)
				.replace(":mid", MyScenario.oddData.gid_kwh)
      )
		}
		else {
			navigator(-1)
		}
	}

  return (
    <Fragment>
      <div className={`container-fluid`}>
        <div className={`row`}>
          <div className={`col-lg-8`}>
            <div className={``}>
              <Typography variant="body" >
                Define or Select Observers from List
              </Typography>
            </div>
            <div>
              <Typography variant="caption" color="gray">
                - An observer is a computational entity or algorithm that monitors the
                behavior of a Highly Automated Driving (HAD) system
              </Typography>
            </div>
          </div>
          <div className={`col-lg-4`}>
            <div className={`d-flex justify-content-end`}>
              <Button
                variant="outlined"
                sx={{
                  // backgroundColor: "primary.main",
                  // "&:hover": {
                  //   backgroundColor: "primary.main",
                  // },
                  // color: "white",
                  width: "130px",
                  marginRight: "10px"
                }}
                onClick={onClickBack}

              >
                Back
              </Button>
              {
                !MyScenario.readonly &&
                <Link to={AUTHENTICATED_ROUTES.scenarioExecution
                  .replace(":id", param.id)
                  .replace(":aid", param.aid)
                  .replace(":mid", param.mid)}>
                  <Button
                    sx={{
                      backgroundColor: "primary.main",
                      "&:hover": {
                        backgroundColor: "primary.main",
                      },
                      color: "white",
                      width: "130px",
                    }}
                    disabled={isNextButtonDisabled}
                  >
                    Next
                  </Button>
                </Link>
              }
            </div>
          </div>
        </div>
      </div>
      
      <Divider
        className="mb-3 mt-3"
        sx={{ borderBottom: "1px solid #D9D9D9" }}
      />

      <Grid container sx={{ backgroundColor: "black" }}>
        <Grid item xs={6}>
          <Grid container direction="column">
            <Grid
              item
              sx={{
                height: "60vh",
                width: "100%",
                borderBottom: "1px solid",
                borderColor: "primary.main",
                overflowY: "auto",
                // display: "flex",
                // justifyContent: "space-between",
              }}
            >
              <div className={`d-flex justify-content-between align-items-center`}>
                <Typography
                  variant="h6"
                  sx={{
                    backgroundColor: "secondary.dark",
                    color: "white",
                    padding: "12px",
                    textAlign: "center",
                    width: "85px",
                    lineHeight: 1,
                  }}
                >
                  Editor
                </Typography>
                <div>
                  {
                    MyScenario.readonly ? <ReadOnlyTag /> :
                      <Button onClick={onSubmitaCodeEditor}
                        sx={{
                          backgroundColor: "primary.main",
                          "&:hover": {
                            backgroundColor: "primary.main",
                          },
                          color: "white",
                          marginTop: "10px",
                          marginRight: "1rem",
                          width: "130px"
                        }}>
                        Execute
                      </Button>
                  }
                </div>
              </div>
              <div style={{ width: "100%" }}>

                <CodeEditor
                  onChange={handleCodeInput}
                  value={codeSample.codeEditor}
                  className="code-editor"
                  isEdit={false}
                />
              </div>

            </Grid>
            <Grid item sx={{ height: "30vh", overflowY: "auto" }}>
            <Typography
                variant="h6"
                sx={{
                    backgroundColor: "secondary.dark",
                    color: "white",
                    padding: "12px",
                    textAlign: "center",
                    width: "85px",
                    lineHeight: 1,
                }}
            >
                Result
            </Typography>
            <Box p={2}>
                <Typography variant="body2" gutterBottom>
                  <Alert severity="success">
                    Code will be checked at the time of execution
                  </Alert>
                </Typography>
              </Box>
              
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            borderLeft: "1px solid",
            borderColor: "primary.main",
            height: "90vh",
            overflowY: "auto",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="List" />
              {/* <Tab label="Documentation" /> */}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <List
              sx={{ width: "100%", marginTop: ".5em" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader=""
            >
              {presetListRender()}
            </List>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div style={{height: "48rem"}}>
            <iframe
              src={documentationUrl}
              title="Documentation"
              width="100%"
              height="100%"
            />
            </div>
            
          </CustomTabPanel>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default connect(null,{ReduxUpdateMyScenarioJourney})(DefineSecnarioObserver)