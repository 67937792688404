import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from '@mui/material/Pagination'

export default function AnalyticsMyScenarioTable({ table, headers, fetching, totalRows, pageSize, page, pageHandler }) {
    return (
        <div className="mt-4">
          <DataGrid
            getRowId={(row) => row.id}
            rows={table}
            columns={headers}
            loading={fetching}
            className="secondary-table-height"
            autoHeight={true}
            hideFooter={true}
            sx={{
              border: "none",
              borderRadius: "12px",
              backgroundColor: "secondary.dark",
              "& .MuiDataGrid-cell": {
                color: "text.main",
              },
              "& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows": { marginTop: "15px", }
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'date_created', sort: 'desc' }],
              },
            }}
          />
          <div style={{backgroundColor: 'dimgrey', height: 1}}/>
          <div style={{display: 'flex', flexDirection: 'column-reverse', alignItems:'flex-end', height: '50px', justifyContent: 'center', backgroundColor: 'rgba(32, 60, 104, 0.8)', borderBottomLeftRadius: '15px', borderBottomRightRadius: '15px'}}>
          <Pagination
          count={Math.ceil(totalRows / pageSize)}
          page={page}
          onChange={pageHandler}
          />  
			  </div>
        </div>
      );
}
