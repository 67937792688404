import React from 'react'
import { HomeRounded as HomeIcon } from '@mui/icons-material'
import { AUTHENTICATED_ROUTES } from 'router/constants'
import MainVehicle from '../../assets/images/mainVehicle.png'
import MyVehicle from '../../assets/icons/MyVehicle.svg'
import MyScenario from '../../assets/icons/MyScenario.svg'
import AutoScenario from '../../assets/icons/AutoScenario.svg'
import Maps from '../../assets/icons/Maps.svg'
import ScenAnalytics from '../../assets/icons/ScenAnalytics.svg'
import Synthdata from '../../assets/icons/Synthdata.svg'
import ScenExtract from '../../assets/icons/ScenExtract.svg'
import NCAPScenarios from '../../assets/icons/NCAPScenarios.svg'
import EducAgents from '../../assets/icons/EducAgents.svg'
import Real2Sim from '../../assets/icons/Real2Sim.svg'
import SenVironment from '../../assets/icons/SenVironment.svg'
import AddNew from '../../assets/icons/AddNew.svg'
import MapValidator from '../../assets/icons/MapValidator.svg'
import carlaIcon from '../../assets/icons/carla.png'
import esminiIcon from '../../assets/icons/esmini.png'
import odd from '../../assets/icons/odd.png'
import vector from '../../assets/icons/Vector.png'
import testSuits from '../../assets/icons/testSuits.png'
import highFidelity from '../../assets/icons/High Fidelity.svg'
import lowFidelity from '../../assets/icons/Low Fidelity.svg'
import dSpace from '../../assets/icons/dSpace.png'
import versatile from '../../assets/icons/versatile.png'
import parking from '../../assets/icons/parking.png'
import DownloadIcon from "@mui/icons-material/Download";

// const openUrl = () => {
//   window.open("https://public.tableau.com/app/profile/.sana.khan/viz/shared/TSC8QBBH5", "_blank");
// };


/**
 * TEST VEHICLE List
 */
export const TEST_VEHICLE = [
	{
		title: 'MyVehicle',
		icons: [<img src={vector} alt='icons' />],
		url: `test-vehicles`,
	},
	{
		title: 'Add Test Vehicle',
		icons: <img src={AddNew} alt='icons' />,
		url: 'none',
	},
]


/**
 * ODD List
 */
export const ODD_LIST = [
	{
		title: 'MyODD',
		icons: [<img src={odd} alt='icons' />],
		url: `odd`,
	},
]


/**
 * SCENARIO APPLICATION List
 */
export const SCENARIO_APPLICATION = [
	{
		title: 'MyScenarios',
		icons: [<img src={MyScenario} alt='icons' style={{ height: "5rem" }} />],
		url: `my-scenarios`,
	},
	{
		title: 'AutoScenarios',
		icons: [<img src={AutoScenario} alt='icons' style={{ height: "5rem" }} />],
		url: 'auto-scenarios',
		
	},
	{
		title: 'Real2Sim',
		icons: <img src={Real2Sim} alt='icons' />,
		url: 'real-sim',
		
	},
	{
		title: 'Add Scenario',
		icons: <img src={AddNew} alt='icons' />,
		url: 'none',
		border:true
	},
	{
		title: 'ScenExtract',
		icons: <img src={ScenExtract} alt='icons' />,
		url: 'scen-extract',
	},
	
]


/**
 * TEST_SUITS List
 */
export const TEST_SUITS = [
	{
		title: 'Add Test Suite',
		icons: <img src={AddNew} alt='icons' />,
		url: 'none',
		border: false

	},
	// {
	// 	title: 'NCAP',
	// 	icons: <img src={NCAPScenarios} alt='icons' />,
	// 	url: 'none',
	// },
	// {
	// 	title: 'ALKS',
	// 	icons: [<img src={testSuits} alt='icons' />],
	// 	url: 'none',
		
	// },
	// {
	// 	title: 'Parking',
	// 	icons: [<img src={parking} alt='icons' style={{ marginBottom: "25px" }} />],
	// 	url: 'none',
	// },
	// {
	// 	title: 'Highway Cut-Ins',
	// 	icons: <img src={testSuits} alt='icons' />,
	// 	url: 'none',
	// },
	// {
	// 	title: 'City Cut-Ins',
	// 	icons: <img src={testSuits} alt='icons' />,
	// 	url: 'none',
	// 	border: true
	// },
	
	
]

const dspace = window.location.pathname
console.log('dspace', dspace)

/**
 * SCENARIO EXECUTION List
 */
export const SCENARIO_EXECUTION = [
	{
		title: 'Low Fidelity',
		icons: [<img src={lowFidelity} alt='icons' style={{ marginBottom: "25px" }} />],
		url: 'low-fidelity',
	},
	{
		title: 'High Fidelity',
		icons: [<img src={highFidelity} alt='icons' style={{ marginBottom: "25px" }} />],
		url: 'high-fidelity',
		isDisabled: false,
		border:true
	},
	{
		title: 'dSpace',
		icons: [<img src={dSpace} alt='icons' style={{ height: "2.3rem", marginTop: "1rem" }} />],
		url: `test-vehicles/co-simulation/co-simulation-details/:id`,
	},
	{
		title: 'Carla',
		icons: [<img src={carlaIcon} alt='icons' style={{ height: "5rem" }} />],
		url: `carla`,
	},
	{
		title: 'Esmini',
		icons: [<img src={esminiIcon} alt='icons' style={{ height: "4.3rem", marginTop:'9px' }} />],
		url: `none`,
		isDisabled: true,
	},
	{
		title: 'Add Simulation',
		icons: <img src={AddNew} alt='icons' />,
		url: 'none',
	},
]

export const NEW_CATEGORY = [
	{
		title: 'Add new Category',
		icons: [<img src={AddNew} alt='icons' />],
		url: 'none',
	},
]


/**
 * SCEN ANALYTICS List
 */
export const SCEN_ANALYTICS = [
	{
		title: 'ScenAlytics',
		icons: <img src={ScenAnalytics} alt='icons' />,
		url: 'scen-alytics',
	},
]


/**
 * DATA GENERATION List
 */
export const DATA_GENERATION = [
	{
		title: 'SynthData',
		icons: <img src={Synthdata} alt='icons' />,
		url: 'none',
		isDisabled: true,
	},
	{
		title: 'SenVironment',
		icons: <img src={SenVironment} alt='icons' style={{ height: "5rem" }} />,
		url: 'sen-vironment',
	},
]


/**
 * TOOLS_APPLICATION List
 */
export const TOOLS_APPLICATION = [
	{
		title: 'MapChecker',
		icons: <img src={MapValidator} alt='icons' />,
		url: 'map-validator',
	},
	{
		title: 'MyMaps',
		icons: <img src={Maps} alt='icons' />,
		url: 'maps',
	},
	{
		title: 'EducAgents',
		icons: <img src={EducAgents} alt='icons' />,
		url: 'educ-agents',
	},
	{
		title: 'Versatile',
		icons: [<img src={versatile} alt='icons' style={{ marginBottom: "12px" }} />],
		url: 'downloads/download-template/1',
	},
]

/**
 * Applications List
 */
export const LEFTSIDE_APPLICATIONS = [
	{
		title: 'dSPACE',
		icons: [<img src={dSpace} alt='icons' />],
		url: 'co-simulation-details',
	},
	{
		title: 'MyVehicle',
		icons: [<img src={MyVehicle} alt='icons' style={{ height: "5rem" }} />],
		url: `test-vehicles`,
	},
	{
		title: 'MyScenarios',
		icons: [<img src={MyScenario} alt='icons' style={{ height: "5rem" }} />],
		url: `my-scenarios`,
	},
	{
		title: 'AutoScenarios',
		icons: [<img src={AutoScenario} alt='icons' style={{ height: "5rem" }} />],
		url: 'auto-scenarios',
	},
	{
		title: 'ScenAlytics',
		icons: <img src={ScenAnalytics} alt='icons' />,
		url: 'scen-alytics',
	},
	{
		title: 'SynthData',
		icons: <img src={Synthdata} alt='icons' />,
		url: 'synth-data',
	},
	{
		title: 'ScenExtract',
		icons: <img src={ScenExtract} alt='icons' />,
		url: 'scen-extract',
	},
	{
		title: 'NCAPScenarios',
		icons: <img src={NCAPScenarios} alt='icons' />,
		url: 'none',
	},
	{
		title: 'EducAgents',
		icons: <img src={EducAgents} alt='icons' />,
		url: 'educ-agents',
	},
	{
		title: 'Real2Sim',
		icons: <img src={Real2Sim} alt='icons' />,
		url: 'real-sim',
	},
	{
		title: 'SenVironment',
		icons: <img src={SenVironment} alt='icons' />,
		url: 'sen-vironment',
	},
	{
		title: 'MapChecker',
		icons: <img src={MapValidator} alt='icons' />,
		url: 'map-validator',
	},
	{
		title: 'MyMaps',
		icons: <img src={Maps} alt='icons' />,
		url: 'maps',
	},
	{
		title: 'Add New Application',
		icons: <img src={AddNew} alt='icons' />,
		url: 'none',
	},
	{
		title: 'Low Fidelity',
		icons: [<img src={AutoScenario} alt='icons' style={{ height: "5rem" }} />],
		url: 'low-fidelity',
	},
	{
		title: 'High Fidelity',
		icons: [<img src={AutoScenario} alt='icons' style={{ height: "5rem" }} />],
		url: 'high-fidelity',
	},
	{
		title: 'MyODD',
		icons: [<img src={odd} alt='icons' />],
		url: 'odd',
	},
	{
		title: 'Simulations',
		icons: [<img src={``} alt='icons' />],
		url: 'simulations',
	},
]


/**
 * Sidebar Elements For Projects
 */
export const LEFTSIDEBAR_ELEMENTS = [
	{
		title: 'Projects',
		nested: false,
		icon: <HomeIcon fontSize='small' />,
		url: AUTHENTICATED_ROUTES.projects,
		active: true,
		onclick: true,
	},
	{
		title: 'Downloads',
		nested: false,
		icon: <HomeIcon fontSize='small' />,
		url: AUTHENTICATED_ROUTES.download,
		active: true,
		onclick: true,
	},
	{
		title: 'Simulations',
		nested: false,
		icon: <HomeIcon fontSize='small' />,
		url: AUTHENTICATED_ROUTES.simulations,
		active: true,
		onclick: true,
	},
]

/**
 * Sidebar Elements For My-Scenario
 */
export const LEFTSIDEBAR_SCENARIO_ELEMENTS = [
	{
		title: 'MAP',
		nested: false,
		icon: <HomeIcon fontSize='small' />,
		url: AUTHENTICATED_ROUTES.map,
		active: true,
	},
	{
		title: 'Show Routes & ODDs ',
		nested: false,
		icon: <HomeIcon fontSize='small' />,
		// url: AUTHENTICATED_ROUTES.routesOdds,
		active: true,
	},
]

/**
 * Sidebar Elements For Test Vehicle
 */
export const LEFTSIDEBAR_TEST_VEHICLE_ELEMENTS = [
	{
		title: 'ReplicaR SIM',
		nested: false,
		icon: <HomeIcon fontSize='small' />,
		url: AUTHENTICATED_ROUTES.replicarSim,
		active: true,
	},
	{
		title: 'Co-SIM - dSPACE',
		nested: false,
		icon: <HomeIcon fontSize='small' />,
		url: AUTHENTICATED_ROUTES.coSimDspace,
		active: true,
	},
	{
		title: 'Co-SIM - CarMaker',
		nested: false,
		icon: <HomeIcon fontSize='small' />,
		url: AUTHENTICATED_ROUTES.coSimCarMaker,
		active: true,
	},
	{
		title: 'Others',
		nested: false,
		icon: <HomeIcon fontSize='small' />,
		// url: AUTHENTICATED_ROUTES.others,
		active: true,
	},
]

/**
 * Sidebar Elements For Auto Scenarios
 */
export const LEFTSIDEBAR_AUTO_SCENARIO_ELEMENTS = [
	{
		title: 'Place Test Vehicles',
		nested: false,
		icon: <HomeIcon fontSize='small' />,
		url: AUTHENTICATED_ROUTES.placeTestVehicles,
		active: true,
	},
	{
		title: 'Show Routes & ODDs ',
		nested: false,
		icon: <HomeIcon fontSize='small' />,
		url: AUTHENTICATED_ROUTES.routesOdds,
		active: true,
	},
	{
		title: 'Define Observer(s)',
		nested: false,
		icon: <HomeIcon fontSize='small' />,
		url: AUTHENTICATED_ROUTES.defineObserver,
		active: true,
	},
]

export const DOWNLOAD_MENULIST = [
	{
		title: "VERSATILE",
		nested: false,
		url: `/downloads/download-template/1`,
		active: true,
		onclick: true,
	},
	{
		title: "VISUAL TEMPLATES",
		nested: false,
		url: `/downloads/download-template/5`,
		active: true,
		onclick: true,
	},
	{
		title: "DSPACE CO-SIM",
		nested: false,
		url: `/downloads/download-template/2`,
		active: true,
		onclick: true,
	},
];


/**
 * Sidebar Elements For Map
 */
export const LEFTSIDEBAR_MAPS_ELEMENTS = []

/**
 * AutoScenario Execute Icons
 */
export const DYNAMIC_EXECUTE_ICONS = [
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '8rem', marginLeft: '4rem', marginTop: '2rem' }}
				alt='icons'
			/>
		),
	},
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '8rem', marginLeft: '2rem', marginTop: '2rem' }}
				alt='icons'
			/>
		),
	},
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '8rem', marginLeft: '2rem', marginTop: '2rem' }}
				alt='icons'
			/>
		),
	},
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '8rem', marginLeft: '2rem', marginTop: '2rem' }}
				alt='icons'
			/>
		),
	},
]

/**
 * AutoScenario Execute Icons
 */
export const STATIC_EXECUTE_ICONS = [
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '5rem', marginLeft: '1rem', marginTop: '0.5rem' }}
				alt='icons'
			/>
		),
	},
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '5rem', marginLeft: '1rem', marginTop: '0.5rem' }}
				alt='icons'
			/>
		),
	},
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '5rem', marginLeft: '1rem', marginTop: '0.5rem' }}
				alt='icons'
			/>
		),
	},
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '5rem', marginLeft: '1rem', marginTop: '0.5rem' }}
				alt='icons'
			/>
		),
	},
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '5rem', marginLeft: '1rem', marginTop: '0.5rem' }}
				alt='icons'
			/>
		),
	},
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '5rem', marginLeft: '1rem', marginTop: '0.5rem' }}
				alt='icons'
			/>
		),
	},
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '5rem', marginLeft: '1rem', marginTop: '0.5rem' }}
				alt='icons'
			/>
		),
	},
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '5rem', marginLeft: '1rem', marginTop: '0.5rem' }}
				alt='icons'
			/>
		),
	},
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '5rem', marginLeft: '1rem', marginTop: '0.5rem' }}
				alt='icons'
			/>
		),
	},
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '5rem', marginLeft: '1rem', marginTop: '0.5rem' }}
				alt='icons'
			/>
		),
	},
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '5rem', marginLeft: '1rem', marginTop: '0.5rem' }}
				alt='icons'
			/>
		),
	},
	{
		icons: (
			<img
				src={MainVehicle}
				style={{ width: '5rem', marginLeft: '1rem', marginTop: '0.5rem' }}
				alt='icons'
			/>
		),
	},
]
