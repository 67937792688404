import { SET_SCENE_ANALYTICS, UPDATE_SCENE_ANALYTICS } from ".."

export const ReduxCreateSceneAnalyticsJourney = (pid) => {
    return {
        type: SET_SCENE_ANALYTICS,
        payload: { projectId: pid }
    }
}

export const ReduxUpdateSceneAnalyticsJourney = (obj) => {
    return {
        type: UPDATE_SCENE_ANALYTICS,
        payload: obj
    }
}