import { Avatar } from '@mui/material'
import React from 'react'

function SquareImage({ children, size, src, alt }) {
	return (
		<Avatar sx={{ width: size, height: size }} imgProps={{ sx:{ objectFit: "contain"} }} src={src} alt={alt} variant="rounded">
			{children}
		</Avatar>
	);
}
export default SquareImage;