import React, { Fragment, useState, useEffect } from "react";
import { Box, Grid, Radio, Button, MenuItem, Menu } from "@mui/material";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getScenarioProjectsById } from "endpointServices/myScenarioServices";
import dateConverter from "utils/dateConverter";
import { wrapText, checkDagStatus } from "utils";
import AnalyticsMyScenarioTable from "./analyticsMyScenarioComponent/analyticsMyScenarioTable";
import { connect } from "react-redux";
import { ReduxCreateSceneAnalyticsJourney, ReduxUpdateSceneAnalyticsJourney } from "services/redux-store/actions";
import MoreVertIcon from "@mui/icons-material/MoreVert";


export default connect(null, {
	ReduxCreateSceneAnalyticsJourney,
	ReduxUpdateSceneAnalyticsJourney,
})(function AnalyticsMyScenario({ ReduxCreateSceneAnalyticsJourney, ReduxUpdateSceneAnalyticsJourney }) {
	const param = useParams();
	let navigator = useNavigate();

	const [list, setList] = useState([]);
	const [statusData, setStatusData] = useState({
		name: "",
		pralionId: "",
	});
	const [versionData, setVersionData] = useState([]);
	const [isFetching, setFetching] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [anchorEl, setAnchorEl] = useState(null);
	const [page, setPage] = useState(1);
	const pageSize = 10;

	useEffect(() => {
		getProjectList({ pageNumber: page, testsPerPage: pageSize });
		localStorage.removeItem("p_id");
		localStorage.removeItem("pralion_id");
		ReduxCreateSceneAnalyticsJourney(param.id);
	}, []);

	useEffect(() => {
		getProjectList({ pageNumber: page, testsPerPage: pageSize });
	}, [page]);

	const getProjectList = async (pagination) => {
		setFetching(true);
		const projectId = parseInt(param.id);
		const response = await getScenarioProjectsById(projectId, pagination);
		if (response?.status === 200) {
			setFetching(false);
			const data = response?.data?.data?.applications || [];
			// const listFilter = data.filter(ele => ele.no_of_scenarios !== 0)
			setList(data);
			setTotalRows(response?.data?.data?.no_of_applications);
		} else {
			setList([]);
			setFetching(false);
		}
	};

	const handleMoreVertClick = (event, data) => {
		const obj = data || {};
		setVersionData(obj);
		setAnchorEl(event.target);
		setStatusData({
			name: data.name,
			pralionId: data.pralion_id,
		});
	};

	const handlePageChange = (event, value) => {
		setPage(value);
	};

	const goto = (data) => {
		/**
		 * Update journey in redux here
		 */
		ReduxUpdateSceneAnalyticsJourney({ pralionId: data.pralion_id, journeyType: 'my-scenario' });
		localStorage.setItem("pralion_id", data.pralion_id);
		navigator(`/projects/${param.id}/applications/scen-alytics/alytics-my-scenario/alytics-scenario-map`);
	};

	const columns = [
		{
			field: "name",
			headerName: <span className="heading-2">Scenario ID</span>,
			width: 550,
			renderCell: (params) => {
				return (
					<Grid>
						<Button
							className="Main-link-simple"
							onClick={() => goto(params.row)}
							sx={{ color: "white" }}
							disabled={params.row.dag_state.dag_state !== "success"}
						>
							{wrapText(params.value)}
						</Button>
					</Grid>
				);
			},
		},
		{
			field: "date_created",
			headerName: "Created At",
			headerName: <span className="heading-2">Created At</span>,
			width: 183,
			renderCell: (params) => (
				<span className="row-heading">{dateConverter(params.value)}</span>
			),
		},
		{
			field: "status",
			headerName: "Status",
			headerName: <span className="heading-2">Status</span>,
			width: 235,
			renderCell: (params) => {
				const status = params.row?.dag_state?.dag_state;
				return (
					<>
						<div className="statusRadioButton">
							<Radio
								color="success"
								checked={status === "success"}
								sx={{
									transform: "scale(0.6)",
									width: "1px",
									height: "1px",
									"& > input": {
										backgroundColor: "#4caf50",
										color: "#4caf50",
										"&:checked + span": {
											backgroundColor: "#4caf50",
											color: "#4caf50",
											borderRadius: "16px",
											width: "22px",
											height: "22px",
										},
									},
								}}
							/>

							<Radio
								checked={status === "running"}
								sx={{
									transform: "scale(0.6)",
									width: "1px",
									height: "1px",
									"& > input": {
										backgroundColor: "#ff9800",
										color: "#ff9800",
										"&:checked + span": {
											backgroundColor: "#ff9800",
											color: "#ff9800",
											borderRadius: "16px",
											width: "22px",
											height: "22px",
										},
									},
								}}
							/>
							<Radio
								checked={status === "failed"}
								sx={{
									transform: "scale(0.6)",
									width: "1px",
									height: "1px",
									"& > input": {
										backgroundColor: "red",
										color: "red",
										"&:checked + span": {
											backgroundColor: "red",
											color: "red",
											borderRadius: "16px",
											width: "22px",
											height: "22px",
										},
									},
								}}
							/>

							<Radio
								checked={
									status === "pending" || !params.row.dag_state.dag_state
								}
								sx={{
									transform: "scale(0.6)",
									width: "1px",
									height: "1px",
									"& > input": {
										backgroundColor: "gray",
										color: "red",
										"&:checked + span": {
											backgroundColor: "gray",
											color: "gray",
											borderRadius: "16px",
											width: "22px",
											height: "22px",
										},
									},
								}}
							/>
						</div>
						<Button
							sx={{ color: "text.main" }}
							onClick={(event) => handleMoreVertClick(event, params.row)}
						>
							<MoreVertIcon />
						</Button>
					</>
				);
			},
		},
	];

	return (
		<Fragment>
			<AnalyticsMyScenarioTable table={list} headers={columns} fetching={isFetching} totalRows={totalRows} pageSize={pageSize} page={page} pageHandler={handlePageChange} />
		</Fragment>
	);
});