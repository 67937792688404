import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { Box, Step, StepLabel, Stepper, CircularProgress, ButtonBase, IconButton,Tooltip } from "@mui/material";
import "./maps.css";
import ButtonX from "components/buttonX";
import { AlertX, ButtonWithIcon, CollapseContent, IconButtonX, ModalX, ProgressX, ReadOnlyTag, Typos, UxSpace } from "components";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { useNavigate } from "react-router";
import { mapTypes } from "utils";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReduxUpdateOddJourney } from "services/redux-store/actions/actions-odd";
import { connect } from "react-redux";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { DirectionsRounded, RouteRounded } from "@mui/icons-material";
import AvatarX from "components/avatarX";
import { getOddRequirement, postOddMultiPointRequirement, postOddRequirement, putOddProjectService, putOddRequirement } from "endpointServices/oddServices";
import STORE from "services/redux-store/store";
import { DEFAULT_POLYGON_LAYER } from "constants/defaults";
import InfoIcon from "@mui/icons-material/Info";
import InstructionGuide from "components/instructionGuide";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "xyz";

const OddZone = ({
	ReduxUpdateOddJourney
}) => {
	const mapContainer = useRef(null);
	const map = useRef(null);
	const geocoder = useRef(null);
	const respRef = useRef(null);
	const draw = useRef(null);
	const loadExistingFileInput = useRef(null);
	const newPolygonsGeoJsonFeatures = useRef([]);
	const oddAttributesRef = useRef(null);

	const [selectedPolygon, setSelectedPolygon] = useState(null);
	const [currentMapType, setCurrentMapType] = useState(mapTypes.streetViewWithOdds);
	const navigate = useNavigate();
	const { Odd } = STORE.getState()
	const { Projects } = STORE.getState()

	const [lng, setLng] = useState(7.76286); // 7.76286, 50.40618
	const [lat, setLat] = useState(50.40618);
	const [zoom, setZoom] = useState(8);
	const [searchedResultClick, setSearchedResultClick] = useState(false);
	const [searchedLocations, setSearchedLocations] = useState([]);
	const [showDrawnRegions, setShowDrawnRegions] = useState(true);
	const [showDrawnRoutes, setShowDrawnRoutes] = useState(true);

	const [loadingRoute, setLoadingRoute] = useState(false);
	const [newDrawnPolygonsArray, setNewDrawnPolygonsArray] = useState([]);
	const [newDrawnRoutesArray, setNewDrawnRoutesArray] = useState([]);
	const [newDrawnRoutesResults, setNewDrawnRoutesResults] = useState([]);
	const [isRouteLoad, setRouteLoad] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [openInfoModal, setOpenInfoModal] = useState(false);


	useEffect(() => {
		localStorage.removeItem('pralion_id')
		if (!map.current) {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: mapTypes.streetViewWithOdds,
				center: [lng, lat],
				zoom: zoom,
			});
			map.current.on("load", () => {
				getCurrentLocation();
			});
		}
		map.current.on("move", handleOnMapMove);
		return () => {
			// map.current.off("click", "route", handleOnClickPolygon);
		};
	}, []);

	useEffect(() => {
		if (!geocoder.current) {
			geocoder.current = new MapboxGeocoder({
				accessToken: mapboxgl.accessToken,
				mapboxgl: mapboxgl,
				render: (item) => {
					return `<div class="search-option-item">
						<div class="search-option-item-title">${item.text}</div>
						<div class="search-option-item-desc">${item.place_name}</div>
					</div>`;
				},
			});
			document.getElementById("geocoder").prepend(geocoder.current.onAdd(map.current));
		}
		geocoder.current.on("result", handleGeocoderResult);

		return () => {
			geocoder.current.off("result", handleGeocoderResult);
		};
	}, [searchedLocations])

	useEffect(() => {
		console.log("ue4 draw tool");
		if (!draw.current) {
			draw.current = new MapboxDraw({
				displayControlsDefault: false,
				controls: {
					polygon: Odd?.type === 'Edit' && Odd.gid ? false : true,
					trash: Odd?.type === 'Edit' && Odd.gid ? false : true,
				}
			});
			// Map#addControl takes an optional second argument to set the position of the control.
			// If no position is specified the control defaults to `top-right`. See the docs
			// for more details: https://docs.mapbox.com/mapbox-gl-js/api/#map#addcontrol
			if (Odd?.type === 'Edit' && Odd.gid) {
				console.log('edit controls',)
			}
			else {
				map.current.addControl(draw.current, "top-left");
				console.log('non edit controls',)
				map.current.on("draw.create", handleDrawCreate);
				map.current.on("draw.delete", handleDrawDelete);
				map.current.on("draw.update", handleDrawUpdate);
				map.current.on("draw.selectionchange", handleDrawSelect);
			}
			fetchExistingOddRequirement();
		}

		return () => {

		}
	}, [])

	const handleDrawCreate = (e) => {
		// console.log('create', e)
		const feature = e.features[0];
		feature.properties["name"] = feature.id;
		feature.properties["ui_generated_id"] = feature.id;
		feature.id = undefined;
		delete feature.id;
		feature.properties["type"] = "Region";
		const temp = [...newPolygonsGeoJsonFeatures.current];
		temp.push({ ...feature });
		newPolygonsGeoJsonFeatures.current = [...temp];
		setNewDrawnPolygonsArray([...temp]);
		setShowDrawnRegions(true);
	}

	const handleDrawDelete = (e) => {
		const temp = newPolygonsGeoJsonFeatures.current.filter((feature) => feature.properties.ui_generated_id !== e.features[0].id)
		newPolygonsGeoJsonFeatures.current = [...temp];
		setNewDrawnPolygonsArray([...temp]);
	}

	const handleDrawUpdate = (e) => {
		// console.log('update', e)
		const temp = newPolygonsGeoJsonFeatures.current.map((feature) => {
			if (feature.properties.ui_generated_id === e.features[0].id) {
				const updatedPolygon = e.features[0];
				updatedPolygon.properties = feature.properties;
				return updatedPolygon;
			} else {
				return feature;
			}
		})
		newPolygonsGeoJsonFeatures.current = [...temp];
		setNewDrawnPolygonsArray([...temp]);
	}

	const handleDrawSelect = (e) => {
		// console.log('select', e)
		if (e && e.features.length > 0) setSelectedPolygon(e.features[0].id)
		else setSelectedPolygon(null);
	}

	// console.log('newPolygonsGeoJsonFeatures.current', newPolygonsGeoJsonFeatures.current);

	const fetchExistingOddRequirement = async () => {
		// if (Odd.gid) {
			// setIsLoading(true);
		  setIsLoading(true);
			const oddReq = await getOddRequirement(Odd.gid)
			if (oddReq.status === 200) {
				console.log('overall fetched data', oddReq)
				fetch(oddReq.data.odd_requirement_url)
				
					.then((response) => response.json())
					.then(async json => {
						console.log('fetched json in read only  mode', json)
						const oddAttributes = json.odd_attributes;
						oddAttributesRef.current = oddAttributes
						console.log('odd_attributes:', oddAttributes);
						// Loop over json features and fetch those which are regions. 
						const regions = json.odd_zone.features.filter(feat => feat.properties.type === "Region");
						// Make a new geojson with them in it
						// Add it to draw tool
						// draw.current.add({
						// 	type: "FeatureCollection",
						// 	features: [
						// 		...regions
						// 	]
						// })
						map.current.addSource(`polygons-src`, {
							type: "geojson", data: {
								type: "FeatureCollection",
								features: [
									...regions
								]
							}
						})
						map.current.addLayer(DEFAULT_POLYGON_LAYER("poygons", "polygons-src"))
						// Add it to react arrays
						newPolygonsGeoJsonFeatures.current = [...regions];
						setNewDrawnPolygonsArray([...regions]);
						setShowDrawnRegions(true);


						const routes = json.odd_zone.features.filter(feat => feat.properties.type === "Route");
						setNewDrawnRoutesResults([...routes]);
						for (let i = 0; i < routes.length; ++i) {
							await map.current.addSource(`route-${i}-src`, {
								type: "geojson", data: {
									type: "FeatureCollection",
									features: [routes[i]]
								}
							});
							await map.current.addLayer({
								type: "line",
								source: `route-${i}-src`,
								id: `route-${i}-layer`,
								paint: {
									"line-color": "yellow",
									"line-width": 6,
									"line-opacity": 0.4,
								},
							});
						}


					})
					.finally(() => {
						setIsLoading(false);
					})
			} else {
				setIsLoading(false);
				console.log("Normal Mode",)
				const oddPayload = Odd.oddPayload;
				console.log("Odd Payload" , oddPayload )
				if (oddPayload && oddPayload.features) {
					const regions = oddPayload.features.filter(feat => feat.properties.type === "Region");
					map.current.addSource(`polygons-src`, {
						type: "geojson", data: {
							type: "FeatureCollection",
							features: [
								...regions
							]
						}
					})
					map.current.addLayer(DEFAULT_POLYGON_LAYER("poygons", "polygons-src"))
					newPolygonsGeoJsonFeatures.current = [...regions];
					setNewDrawnPolygonsArray([...regions]);
					setShowDrawnRegions(true);
					const routes = oddPayload.features.filter(feat => feat.properties.type === "Route");
					setNewDrawnRoutesResults([
						...newDrawnRoutesResults,
						...routes
					])
					for (let i = 0; i < routes.length; ++i) {
						await map.current.addSource(`route-${i}-src`, {
							type: "geojson", data: {
								type: "FeatureCollection",
								features: [routes[i]]
							}
						});
						await map.current.addLayer({
							type: "line",
							source: `route-${i}-src`,
							id: `route-${i}-layer`,
							paint: {
								"line-color": "yellow",
								"line-width": 6,
								"line-opacity": 0.4,
							},
						});
					}
				}
				
			}
		// }
	}

	const getCurrentLocation = () => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				const latitude = position.coords.latitude;
				const longitude = position.coords.longitude;

				// Set the map viewport with the current location
				setLat(latitude);
				setLng(longitude);

				map.current.flyTo({
					center: [longitude, latitude], // Specify the destination coordinates
					zoom: 15, // Specify the zoom level of the destination
					speed: 0.8, // Control the speed of the flyTo animation
					curve: 1.42, // Control the curve of the flight path
					easing: (t) => t, // Customize the easing function
				});
			},
			(error) => {
				console.error('Error getting current location:', error);
			}
		);
	};

	const handleGeocoderResult = (e) => {
		if ((searchedLocations.length < 5) && !(Odd?.type === 'Edit' && Odd.gid)) {
			setSearchedResultClick(true);
			const newMarker = new mapboxgl.Marker().setLngLat(e.result.center);
			setSearchedLocations([...searchedLocations, { location: e.result, marker: newMarker }]);
			newMarker.addTo(map.current);
		}
	};

	const onClickChangeMapType = (type) => {
		let mapStyle;
		switch (type) {
			case mapTypes.streetView:
				mapStyle = "mapbox://styles/intakhabkhan/clikoeo8o00co01qpdpe9euh9";
				break;
			case mapTypes.satelliteView:
				mapStyle = "mapbox://styles/mapbox/satellite-v9";
				break;
			default:
				console.error("Invalid map type:", type);
				return;
		}

		if (type !== currentMapType) {
			setIsLoading(true);
			// Set map style
			map.current.setStyle(mapStyle);

			// Listen for style load event before adding source and layers
			map.current.once('style.load', () => {
				setIsLoading(false);
				// Add logging for street view or satellite view
				if (type === mapTypes.streetView || type === mapTypes.satelliteView) {
					fetchExistingOddRequirement()
						.then(() => {
							if (respRef.current) {
								drawRouteAndLayers(respRef.current, false);
							}
							console.log("Data loading finished.");
						})
						.catch(error => {
							console.error("Error fetching data:", error);
						});
				}
			});

			setCurrentMapType(type);
		} else {
			console.log("Already on the selected map type:", type);
		}
		getCurrentLocation();
	};

	const handleOnMapMove = (e) => {
		setLng(map.current.getCenter().lng.toFixed(4));
		setLat(map.current.getCenter().lat.toFixed(4));
		setZoom(map.current.getZoom().toFixed(2));
	};

	const onClickClearAllLocations = (e) => {
		try {
			// Clear the array of routes results saved
			for (let i = 0; i < searchedLocations.length; ++i) {
				searchedLocations[i].marker.remove();
			}
			geocoder.current.clear();
			setSearchedLocations([]);
			setSearchedResultClick(false);
			//! Depreciated ( there routes clearing will only clear the markers and reset the states, Routes will have seperate delete functionality)
			// TODO: Loop over the newDrawnRouteResults array and clear the source and layers

			// map.current.removeLayer("route-between-2-points1")
			// map.current.removeSource("route-between-2-points")
		}
		catch (err) {
			// toast.error(`${err}. Please try again later`)
			console.log('err', err)
		}
	};

	const onClickLoadExistingFileInput = (e) => {
		loadExistingFileInput.current.click();
	};

	const onLoadExistingFile = (e) => {
		// console.log("e loading map", e);
		// setSelectedFiles(e.target.files);
		// map.current.addSource("route", { type: "geojson", data: SOURCE });
		// map.current.addLayer(LAYER);
		// if (showDrawnRegions) {
		// 	console.log("showDrawnRegions", showDrawnRegions);
		// 	setShowDrawnRegions(false);
		// }
	};


	const onAddStopButtonClick = async () => {
		if (searchedLocations.length < 5) {
			geocoder.current.clear();
		}
	};

	const onClickDeleteSearchedLocation = (index) => {
		if (searchedLocations.length === 1) {
			setSearchedResultClick(false);
		}
		const tempSearchLocations = [...searchedLocations];
		tempSearchLocations.splice(index, 1);
		setSearchedLocations([...tempSearchLocations])
	}

	const drawRouteAndLayers = async (resp, styleChange) => {
		try {
			const value = resp.data.data
			// setGeoJsonUrl(value.geo_json)
			fetch(value.geo_json)
				.then((response) => response.json())
				.then(async (json) => {
					const data = json.features[0];
					await map.current.addSource(`route-${newDrawnRoutesResults.length}-src`, { type: "geojson", data: value.geo_json });
					await map.current.addLayer({
						type: "line",
						source: `route-${newDrawnRoutesResults.length}-src`,
						id: `route-${newDrawnRoutesResults.length}-layer`,
						paint: {
							"line-color": "yellow",
							"line-width": 6,
							"line-opacity": 0.4,
						},
					});
					if (styleChange) {
						data.properties = {
							...data.properties,
							type: 'Route',
							name: searchedLocations.map(item => item.location.text).join(", ")
						}
						setNewDrawnRoutesResults([
							...newDrawnRoutesResults,
							{ ...data }
						])
						setNewDrawnRoutesArray([
							...newDrawnRoutesArray,
							[...searchedLocations]
						])
						setShowDrawnRoutes(true);
						for (let i = 0; i < searchedLocations.length; ++i) {
							searchedLocations[i].marker.remove();
						}
						geocoder.current.clear();
					}
					setSearchedLocations([]);
					setSearchedResultClick(false);
					setRouteLoad(false);
					setLoadingRoute(false);
					// map.current.removeLayer("route-between-2-points1")
					// map.current.removeSource("route-between-2-points")
				})
		} catch (error) {
			console.log('Routes and layers are not drawn',)
		}
	}

	const onClickFindRoute = async (e) => {
		try {
			// if (respRef.current) {
			// 	drawRouteAndLayers(respRef.current, false)
			// 	return
			// }
			const tempCoordinates = searchedLocations.map(item => item['location']['center'])
			const payload = { coordinates: tempCoordinates };

			console.log('payload', payload)
			setRouteLoad(true)
			setLoadingRoute(true);
			const resp = await postOddMultiPointRequirement(payload)
			console.log('resp', resp)
			respRef.current = resp;
			if (resp.status === 200) {
				await drawRouteAndLayers(resp, true)
			} else {
				console.log('findMultiPointRoute error', resp)
				setRouteLoad(false)
				setLoadingRoute(false);
			}
		}
		catch (err) {
			console.log('err', err)
			setLoadingRoute(false);
			setRouteLoad(false)
		}
	}

	const onClickDeletePolygon = (e) => {
		try {

			const temp = [...newPolygonsGeoJsonFeatures.current];
			temp.splice(parseInt(e.target.id), 1);
			newPolygonsGeoJsonFeatures.current = [...temp];
			setNewDrawnPolygonsArray([...temp]);
		}
		catch (err) {
			console.log('err', err);
		}

	}



	// const onClickNext = (data) => {
	// 	console.log("data in the odd definition", data);
	// 	navigate(`/projects/${data.projectId}/applications/odd/odd-definition`, {
	// 	});
	// }


	const onClickNext = async (data) => {
		setIsLoading(true);
		const payload = {
			odd_zone: {
				type: "FeatureCollection",
				features: [...newDrawnPolygonsArray, ...newDrawnRoutesResults],
			},
			
		};
		ReduxUpdateOddJourney({ oddPayload: payload.odd_zone });
		const oddAttributesData = oddAttributesRef.current;

		navigate(
			`/projects/${Odd.pralion_id}/applications/odd/odd-definition`,
			{
				state: { oddAttributesData },
			}
		);
		console.log('payload', payload);
		// TODO: Call the requirements api to get GID, then call the submit put request api to submit the data
		// if (Odd.type === 'Edit' && Odd.gid) {
		// 	const resp = await putOddRequirement(Odd.gid, payload);
		// 	console.log('resp', resp)
		// 	if (resp.status === 200) {
		// 		setIsLoading(false)
		// 		toast.success("ODD updated successfully")
		// 		setTimeout(() => {
		// 			navigate(`/projects/${projectId}/applications/odd`)
		// 		}, 2000)
		// 	} else {
		// 		console.log('findMultiPointRoute update error', resp)
		// 		setIsLoading(false)
		// 	}
		// }
		// else {
		// 	const resp = await postOddRequirement(payload);
		// 	if (resp.status === 200) {
		// 		const response = await putOddProjectService({ pralion_id: Odd.pralion_id, gid: resp.data.odd_requirement_id });
		// 		if (response?.status === 200) {
		// 			console.log('response', response)
		// 			setIsLoading(false)
		// 			toast.success("ODD created successfully")
		// 			setTimeout(() => {
		// 				navigate(`/projects/${projectId}/applications/odd`)
		// 			}, 2000)
		// 		} else {
		// 			const err = response?.message || 'Failed to get request'
		// 			toast.error(`${err}. Please try again later`)
		// 			setIsLoading(false)
		// 		}
		// 	} else {
		// 		console.log('findMultiPointRoute error', resp)
		// 		setIsLoading(false)
		// 	}

		// }
	}

	const pralionId = Odd.pralionId;
	const projectId = Projects.projectId;

	const onChangeRegionName = (e, index) => {
		const temp = [...newDrawnPolygonsArray];
		temp[index].properties['name'] = e.target.value || e.target.id;
		setNewDrawnPolygonsArray([...temp]);
	}

	const handleOpenModal = () => {
		setOpenInfoModal(true);
	};

	const closeInfoModal = () => setOpenInfoModal(false);

	return (
		<>
			<ModalX
				open={openInfoModal}
				heading={<span>Guide to ODD</span>}
				subheading={
					<span>
						Creating and Managing ODD's
					</span>
				}
				close={closeInfoModal}
			>
				<InstructionGuide type={"odd"} />
			</ModalX>
			{isLoading && <ProgressX fullscreen={true} />}
			<div className={`container-fluid`}>
				<input
					type="file"
					ref={loadExistingFileInput}
					accept=".geojson"
					onChange={onLoadExistingFile}
					style={{ display: "none" }}
				/>
				<div className={`row`}>
					<div className={`col-12 d-flex justify-content-between`}>
						<div className={``}>
							{Odd?.type === "Edit" && Odd.gid
								? <ReadOnlyTag />
								: <IconButton sx={{ ml: 0 }} onClick={handleOpenModal}>
									<InfoIcon />
								</IconButton>
							}
						</div>
						<div className={`d-flex`}>
							<ButtonX disabled={false} onClick={() => navigate(-1)}>
								Back
							</ButtonX>
							<ButtonX
								className={`ms-2`}
								disabled={
									(newDrawnRoutesResults.length === 0 &&
										newDrawnPolygonsArray.length === 0) 						
								}
								onClick={onClickNext}
							>
								<span>Next</span>
								{isLoading ? (
									<CircularProgress
										size={20}
										style={{ marginLeft: "8px" }}
										color="inherit"
									/>
								) : (
									""
								)}
							</ButtonX>
						</div>
					</div>
				</div>
				<div className={`row mt-3`}>
					<div className={`col-lg-12`}>
						<div className={`map-container`}>
							<div className={`odd-zone-map-sidebar`}>
								<div className={`odd-zone-map-sidebar-item px-3 py-2`}>
									<div
										className={`d-flex justify-content-between align-items-center`}
									>
										<Typos.Body1 color={"white"} className={`fw-bold`}>
											ODD Zone - Regions
										</Typos.Body1>
										<IconButtonX
											icon={showDrawnRegions ? "ExpandLess" : "ExpandMore"}
											onClick={() => setShowDrawnRegions(!showDrawnRegions)}
										/>
									</div>
									<CollapseContent show={showDrawnRegions}>
										<div className="user-maps-list">
											{newDrawnPolygonsArray.length > 0 ? (
												newDrawnPolygonsArray.map((item, i) => (
													<div
														className={`mt-2 d-flex align-items-center`}
														key={i}
													>
														<Typos.Caption className={`me-2 fw-bold`}>
															#{i + 1}
														</Typos.Caption>
														<input
															id={item.properties.name || `poly-${i}`}
															className={`w-100 form-control form-control-sm`}
															disabled={Odd?.type === "Edit" && Odd.gid}
															style={{
																backgroundColor: "#203c68",
																borderColor:
																	selectedPolygon ===
																		item.properties.ui_generated_id
																		? "#FF7D00"
																		: "#00A2BB",
																borderRadius: "10px",
																color: "#fff",
															}}
															placeholder={item.properties.name}
															value={
																item.properties.name ===
																	item.properties.ui_generated_id
																	? ""
																	: item.properties.name
															}
															onChange={(e) => onChangeRegionName(e, i)}
														/>
														{/* <IconButtonX icon={"ClearRounded"} id={i} onClick={(e) => onClickDeletePolygon(e)} /> */}
													</div>
												))
											) : (
												<AlertX
													badge={"info"}
													text={"Use the above tools to define regions"}
												/>
											)}
										</div>
									</CollapseContent>
								</div>
								<div className={`odd-zone-map-sidebar-item px-3 py-2 mt-2`}>
									<div
										className={`d-flex justify-content-between align-items-center`}
									>
										<Typos.Body1 color={"white"} className={`fw-bold`}>
											ODD Zone - Routes
										</Typos.Body1>
										<IconButtonX
											icon={showDrawnRoutes ? "ExpandLess" : "ExpandMore"}
											onClick={() => setShowDrawnRoutes(!showDrawnRoutes)}
										/>
									</div>
									<CollapseContent
										orientation={"vertical"}
										show={showDrawnRoutes}
									>
										<div className="user-maps-list">
											{newDrawnRoutesResults.length === 0 && !loadingRoute && (
												<AlertX
													badge={"info"}
													text={
														"Use the above tools or search bar to define routes"
													}
												/>
											)}
											{newDrawnRoutesResults.map((item, i) => {
												const stops = item.properties.name.split(",");
												return (
													<Box
														className={`p-1 d-flex justify-content-between w-100`}
														sx={{ borderRadius: "12px" }}
														key={i}
														component={ButtonBase}
													>
														{/* first element */}
														<div>
															<Typos.Caption>{stops[0]}</Typos.Caption>
														</div>
														<Box
															sx={{
																flexGrow: 1,
																borderBottom: "2px solid #e74c3c",
																columnGap: "10px",
															}}
														></Box>
														<div className={`d-flex`}>
															{stops
																.filter(
																	(x, y) => y !== 0 && y !== stops.length - 1
																)
																.map((nitem, ni) => {
																	return (
																		<AvatarX
																			hint={nitem}
																			size={25}
																			key={`${i}-${ni}`}
																			className={`mx-1`}
																		>
																			{ni + 1}
																		</AvatarX>
																	);
																})}
														</div>
														<Box
															sx={{
																flexGrow: 1,
																borderBottom: "2px solid #e74c3c",
																columnGap: "10px",
															}}
														></Box>
														{/* last element */}
														<div>
															<Typos.Caption noWrap className={"text-end"}>
																{stops[stops.length - 1]}
															</Typos.Caption>
														</div>
													</Box>
												);
											})}
										</div>
										{loadingRoute && <ProgressX block={true} height={28} />}
									</CollapseContent>
								</div>
							</div>

							<div className={`map-search-field-on-search`}>
								<CollapseContent show={searchedResultClick}>
									<div className={`p-2`}>
										<Stepper orientation="vertical">
											{searchedLocations.map((item, i) => (
												<Step
													key={i}
													className={`px-2`}
													sx={{
														backgroundColor: "#203c68",
														borderRadius: "12px",
														border: "1px solid #00A2BB",
													}}
												>
													<StepLabel className={`py-1`}>
														<div
															className={`d-flex justify-content-between align-items-center`}
														>
															<Typos.Caption>
																{item.location.place_name}
															</Typos.Caption>
															<IconButtonX
																icon={"ClearRounded"}
																size={"small"}
																onClick={() => onClickDeleteSearchedLocation(i)}
															/>
														</div>
													</StepLabel>
												</Step>
											))}
										</Stepper>
										<UxSpace />
										<div className={`d-flex justify-content-center w-100 px-3`}>
											{loadingRoute ? (
												<ProgressX />
											) : (
												<>
													<ButtonWithIcon
														className={`me-1`}
														disabled={searchedLocations.length === 5}
														icon={<RouteRounded />}
														fullwidth={true}
														onClick={onAddStopButtonClick}
													>
														Add Stop
													</ButtonWithIcon>
													<ButtonWithIcon
														className={`ms-1`}
														disabled={
															searchedLocations.length < 2 || isRouteLoad
																? true
																: false
														}
														icon={<DirectionsRounded />}
														fullwidth={true}
														onClick={onClickFindRoute}
													>
														Find Route
													</ButtonWithIcon>
												</>
											)}
										</div>
										{searchedResultClick && (
											<div className={`mt-2 mb-1 px-3`}>
												<ButtonX
													fullwidth={true}
													color="error"
													size={"small"}
													onClick={onClickClearAllLocations}
												>
													Clear
												</ButtonX>
											</div>
										)}
									</div>
								</CollapseContent>
							</div>

							<div className={`map-search-field`}>
								<div id="geocoder"></div>
							</div>
							{/* <div className="map-location-info">
							Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
						</div> */}
							<div style={{ position: 'relative' }} className="map-container">
								<div className={`map-types-bar ${isLoading ? 'disabled' : ''}`}>
									<IconButtonX wrapper size={"small"} icon={"StreetviewRounded"} onClick={() => onClickChangeMapType(mapTypes.streetView)} disabled={isLoading} />
									<IconButtonX wrapper size={"small"} icon={"SatelliteRounded"} onClick={() => onClickChangeMapType(mapTypes.satelliteView)} disabled={isLoading} />
								</div>
								<div ref={mapContainer} className="map-view" />
							</div>
						</div>
					</div>
				</div>
				<UxSpace />
			</div>
		</>
	);
}

function getStateToProps(state) {
	return {
		ReduxHighFidelity: state.ODD,
	};
}
export default connect(getStateToProps, { ReduxUpdateOddJourney })(OddZone);
