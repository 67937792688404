import React, { Fragment, useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  TextField,
  Chip,
  Grid,
} from "@mui/material";
import "./maps.css";
import ButtonX from "components/buttonX";
import {
  AlertX,
  CollapseContent,
  IconButtonX,
  ModalX,
  ReadOnlyTag,
  Typos,
  UxSpace,
} from "components";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import { httpGet } from "utils/httpMethods";
import { useNavigate } from "react-router";
import { ODD_ZONE_AVAILABILITY_ENDPOINT } from "constants/endpoints";
import { mapTypes } from "utils";
import {
  DEFAULT_LINE_LAYER,
  DEFAULT_MULTI_LINE_LAYER,
  DEFAULT_POINT_LAYER,
  DEFAULT_POLYGON_LAYER,
  DEFAULT_POLYGON_LAYER_WITH_DYNAMIC_COLORS,
} from "constants/defaults";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createMapStatus,
  createVersatileMap,
  getMapLogStatus,
  getMapStatus,
  updateMapProjects,
} from "endpointServices/mapsServices";
import STORE from "services/redux-store/store";
import ReactDOMServer from "react-dom/server";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
  ExpandMoreRounded,
  HorizontalRuleRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";
import { getMapGidListByPralionId } from "endpointServices/myScenarioServices";
import InfoIcon from "@mui/icons-material/Info";
import InstructionGuide from "components/instructionGuide";
import { styled } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import StatusModal from "components/statusModal";
import MapStatusModal from "pages/maps/mapsComponent/mapStatusModal";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "xyz";

const PopupComponent = ({ data }) => {
  const checkTypeOfElement = (element, returnParsedObject = false) => {
    try {
      const parsed = JSON.parse(element);
      const isArray = Array.isArray(parsed);
      if (isArray) {
        return returnParsedObject ? ["array", parsed] : "array";
      } else {
        return returnParsedObject ? ["object", parsed] : "object";
      }
    } catch (error) {
      return returnParsedObject ? ["string", element] : "string";
    }
  };
  return (
    <div>
      <h3>Map</h3>
      <Box>
        <List dense disablePadding>
          {Object.keys(data.properties).map((nrow, ni) => {
            return (
              <ListItem key={`${ni}`} sx={{ pl: 0, pt: 0, pb: 0 }}>
                <ListItemText
                  primary={
                    <div className={`d-flex w-100`}>
                      <Typos.Caption className={`fw-bold me-2`}>
                        {nrow}:
                      </Typos.Caption>
                      {checkTypeOfElement(data.properties[nrow]) === "array" ? (
                        <div>
                          {JSON.parse(data.properties[nrow]).map(
                            (nnrow, nni) => {
                              return (
                                <div className={`d-flex`} key={`${ni}-${nni}`}>
                                  <HorizontalRuleRounded fontSize="small" />
                                  <Typos.Caption>
                                    {nnrow || "---"}
                                  </Typos.Caption>
                                </div>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        <Typos.Caption>
                          {data.properties[nrow] || "---"}
                        </Typos.Caption>
                      )}
                    </div>
                  }
                />
              </ListItem>
            );
          })}
        </List>
      </Box>
    </div>
  );
};

function MainMaps(props) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const selectedRegionsFromGeojsonRef = useRef([]);
  const popup = useRef(null);
  const fetchedGeoJSONDataRef = useRef(null);

  const navigate = useNavigate();
  const { Maps } = STORE.getState();
  const { Projects } = STORE.getState();
  const { oddId, pralionId, oddPralionId } = Maps;
  const projectId = Projects.projectId;

  const [lng, setLng] = useState(7.76286); // 7.76286, 50.40618
  const [lat, setLat] = useState(50.40618);
  const [zoom, setZoom] = useState(8);

  const [loadingRegions, setLoadingRegions] = useState(false);
  const [selectedRegionsFromGeojson, setSelectedRegionsFromGeojson] = useState(
    []
  );

  const [showSelectedRegions, setShowSelectedRegions] = useState(true);
  const [activeSelectedRegionInList, setActiveSelectedRegionInList] =
    useState(null);
  const [currentMapType, setCurrentMapType] = useState(mapTypes.streetView);
  const [isLoading, setIsLoading] = useState(false);
  // Geo Json Data
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [geoJsonFeatures, setGeoJsonFeatures] = useState([]);
  const [geojsonAttributes, setGeojsonAttributes] = useState([]);
  const [geojsonAttributesValues, setGeojsonAttributesValues] = useState([]);
  const [selectedGeojsonAttribute, setSelectedGeojsonAttribute] =
    useState(null);
  const [selectedGeojsonAttributeValue, setSelectedGeojsonAttributeValue] =
    useState(null);
  const [searchedMapRegions, setSearchedMapRegions] = useState([]);
  const [activeSearchedMapRegion, setActiveSearchedMapRegion] = useState(null);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  const [selectedFile, setSelectedFile] = useState([]);
  console.log("selectedFile", selectedFile);
  const [mapGId] = useState([]);
  const [statusActive, setStatusActive] = useState(false);
  const [statusCheck, setStatusCheck] = useState("");
  const [open, setOpen] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(true);
  const [externalFileRoute] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [routeLog, setRouteLog] = useState(null);
  const [logStatus, setLogStatus] = useState(false);
  const [logStatusModal, setLogStatusModal] = useState(false);
  const [statusData, setStatusData] = useState([]);
  const [selectedJsonFeatures, setSelectedJsonFeatures] = useState([]);
  const [filesArrayLength, setFilesArrayLength] = useState(0);

  useEffect(() => {
    let intervalStatus;
    if (statusActive) {
      if (statusCheck !== "success") {
        intervalStatus = setInterval(() => {
          handleMapStatus();
        }, 3000);
      }
    }
    return () => clearInterval(intervalStatus);
  }, [statusActive, statusCheck, selectedRegionsFromGeojson]);

  const handleFileChange = (event) => {
    if (event.target.files.length > 10) {
      toast.info("Not allowed more then 10 files");
    } else {
      // Concatenate the new files with the existing selected files
      const newFiles = Array.from(event.target.files);
      const updatedFiles = [...selectedFile, ...newFiles];

      setSelectedFile(updatedFiles); // Use event.target.files[0] to get the first file
      setIsFileSelected(false);
    }
  };

  const fileUploadApiCall = async (file) => {
    console.log("file", file);
    const formdata = new FormData();
    formdata.append("odr_map_file", file, "odr_map.xodr");
    formdata.append("publication_status", "published");
    formdata.append("source", "source");
    const response = await createVersatileMap(formdata);
    if (response.status === 200) {
      const resp = response.data.data;
      mapGId.push(resp.gid);
      // makeRouteStructure(resp.gid)
      return true;
    } else {
      const error = response;
      // setStatusActive(false);
      console.log("error", error);
      return false;
    }
  };

  const makeRouteStructure = async () => {
    const route = mapGId.map((idx) => {
      return {
        type: "Feature",
        state: {},
        external: true,
        geometry: {
          type: "MultiLineString",
          coordinates: [[[0, 0]]],
        },
        properties: {
          "Region Name": "N/A",
          "Max Lanes": "N/A",
          "Min Lanes": "N/A",
          "Max Speed": "N/A",
          "Min Speed": "N/A",
          "Is Lit": "N/A",
          Surface: "N/A",
          "Surface Exists": "N/A",
        },
        id: idx,
        status: "running",
        layer: {
          id: "route1-point",
          type: "symbol",
          source: "route1",
          layout: {
            "icon-image": "marker",
            "icon-size": 1,
            "icon-allow-overlap": true,
          },
          paint: {},
        },
        source: "route1",
      };
    });
    return route;
  };
  console.log("statusActive", statusActive);
  const handleFileUpload = async () => {
    setIsLoad(true);
    setLoadingRegions(true)
    const filesArray = Array.from(selectedFile);
    console.log("filesArray", filesArray);
    setFilesArrayLength(filesArray.length);
    const uploadPromises = filesArray.map((file) => fileUploadApiCall(file));
    setTimeout(() => {
      toast.info("Uploading files... This may take a few moments.");
    }, 1000);
    const results = await Promise.all(uploadPromises);
    if (results.every((result) => result)) {
      setIsLoad(false);
      const routeStruc = await makeRouteStructure();
      const updatedRegions = [...selectedRegionsFromGeojson, ...routeStruc];
      selectedRegionsFromGeojsonRef.current = updatedRegions;
      setSelectedRegionsFromGeojson(updatedRegions);
      setStatusActive(true);
      setOpen(false);
      setLoadingRegions(false)
      setSelectedFile([]);
      toast.success("File uploaded successfuly");
    } else {
      setIsLoad(false);
      // setStatusActive(false);
      setLoadingRegions(false)
      toast.info("Faild to upload file");
    }
  };

  const mapStatusApiCall = async (id) => {
    const mapId = id;
    const response = await getMapStatus(id);
    if (response.status === 200) {
      const resp = response.data.data.status;
      const updatedRegions = selectedRegionsFromGeojson.map((reg) => {
        if (resp === "success" && reg.id === mapId) {
          reg.status = "success";
        } else if (resp === "failed" && reg.id === mapId) {
          reg.status = "failed";
        }
        return reg;
      });
      setSelectedRegionsFromGeojson(updatedRegions);
      return resp;
    } else {
      const error = response;
      toast.info("Faild in status api calling");
      console.log("error", error);
      return "failed";
    }
  };

  const handleMapStatus = async () => {
    const allMapStatus = mapGId.map((ids) => mapStatusApiCall(ids));
    const createPromise = await Promise.all(allMapStatus);
    const results = createPromise.every((status) => status !== "running");
    if (results === true) {
      console.log("true ----------", true);
      setStatusCheck("success");
      setStatusActive(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: mapTypes.streetView,
        center: [lng, lat],
        zoom: zoom,
      });
      popup.current = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: true,
      });
      map.current.on("load", () => {
        getCurrentLocation();
      });
      map.current.on("style.load", () => {
        fetchGivenOddMapRegions();
      });

      // map.current.addControl(geocoder);
      // document.getElementById("geocoder").prepend(geocoder.current.onAdd(map.current));
    }
    map.current.on("move", handleOnMapMove);

    // geocoder.current.on("result", handleGeocoderResult);

    return () => {
      // geocoder.current.off("result", handleGeocoderResult);
      // map.current.off("click", "route", handleOnClickPolygon);
    };
  }, []);

  const handleOnClickRegion = (e) => {
    const feature = e.features[0];
    const divElem = window.document.createElement("div");
    divElem.style =
      "background-color: #203c68d8; padding: 10px; border-radius: 10px; width:300px";
    divElem.innerHTML = ReactDOMServer.renderToString(
      <PopupComponent data={feature} onButtonClick={addSelectedRegion} />
    );

    if (!Maps.readonly) {
      const btnElem = window.document.createElement("div");
      btnElem.innerHTML = `<button class="btn btn-info btn-simple btn-sm btn-block text-white">Add to Project</button`;
      divElem.appendChild(btnElem);
      btnElem.addEventListener("click", () => addSelectedRegion(feature));
    }

    popup.current
      .setLngLat([e.lngLat.lng, e.lngLat.lat])
      .setDOMContent(divElem)
      .addTo(map.current);
  };

  const addSelectedRegion = (region) => {
    console.log("region ---", region.id);
    if (
      !selectedRegionsFromGeojsonRef.current.find(
        (item) => item.id === region.id
      )
    ) {
      const temp = [...selectedRegionsFromGeojsonRef.current, region];
      console.log("temp ---", temp);
      setSelectedRegionsFromGeojson([...temp]);
      selectedRegionsFromGeojsonRef.current = [...temp];
    } else {
      toast.info("Map already added.");
    }
  };

  const removeMapSourceAndLayers = (sourceId) => {
    const layersToRemove = map.current.getStyle().layers.filter((layer) => {
      return layer.source === sourceId;
    });

    layersToRemove.forEach((layer) => {
      map.current.removeLayer(layer.id);
    });

    if (map.current.getSource(sourceId)) {
      map.current.removeSource(sourceId);
    }
  };

  const setupMapSourceAndLayers = (json) => {
    map.current.addSource("route1", {
      type: "geojson",
      data: json,
      generateId: true,
    });

    map.current.addLayer(
      DEFAULT_LINE_LAYER(
        "route1-polygon-border",
        "route1",
        "#FF7D00",
        "Polygon"
      )
    );

    map.current.addLayer(DEFAULT_POLYGON_LAYER("route1-polygon", "route1"));

    map.current.addLayer(
      DEFAULT_LINE_LAYER("route1-line", "route1", "#ff0000")
    );

    map.current.addLayer(DEFAULT_POINT_LAYER("route1-point", "route1"));

    map.current.addLayer(
      DEFAULT_MULTI_LINE_LAYER("route1-multiline", "route1", "#05f51d")
    );

    const cords = json.features[0].geometry.coordinates[0][0];
    setLng(cords[0]);
    setLat(cords[1]);
    map.current.setCenter(cords);

    map.current.on("click", "route1-polygon", handleOnClickRegion);
    map.current.on("click", "route1-line", handleOnClickRegion);
    map.current.on("click", "route1-point", handleOnClickRegion);

    const attr = json.features.map((item) => Object.keys(item.properties));
    const flattened = attr.reduce((acc, curr) => acc.concat(curr), []);
    const uniqueItems = [...new Set(flattened)];

    setGeojsonAttributes(
      uniqueItems.filter(
        (item) => !["Surface Exists", "length_km"].includes(item)
      )
    );
  };

  const fetchSelectedMapStatus = async (fetchedData) => {
    console.log("fetchedData !!!!", fetchedData);
    const payload = { gids: fetchedData };
    const response = await createMapStatus(payload);
    if (response && response.status === 200) {
      console.log("response ---", response);
      setSelectedJsonFeatures(response?.data?.features);
      setGeoJsonData(response?.data);
      setGeoJsonFeatures(response?.data?.features);
      setupMapSourceAndLayers(response?.data);

      const selectedRegions = response?.data.features.filter((odd) =>
        fetchedData.includes(odd.id)
      );
      setSelectedRegionsFromGeojson(selectedRegions);
      selectedRegionsFromGeojsonRef.current = selectedRegions;
    } else {
      console.log("error", response.error);
    }
  };

  const fetchGivenOddMapRegions = async () => {
    try {
      if (fetchedGeoJSONDataRef.current) {
        setupMapSourceAndLayers(fetchedGeoJSONDataRef.current);
        return;
      }
      setLoadingRegions(true);
      if (Maps.readonly === false) {
        const response = await httpGet({
          url: `${ODD_ZONE_AVAILABILITY_ENDPOINT}/${oddId}`,
        });
        console.log("JSON RESPONSE", response);
        const geoJsonResponse = await fetch(response.data.data.geo_json);
        console.log("geoJsonResponse", geoJsonResponse);
        const json = await geoJsonResponse.json();
        console.log("json", json);
        fetchedGeoJSONDataRef.current = json;
        setGeoJsonData(json);
        console.log("json.selectedJsonFeatures", json.selectedJsonFeatures);
        setGeoJsonFeatures(json.features);
        setupMapSourceAndLayers(json);
      }

      if (Maps.readonly) {
        const response = await getMapGidListByPralionId(Maps.pralionId);
        if (response && response.status === 200) {
          const fetchedData = response.data.data.map((item) => item.gid_kwh);
          console.log("fetchedData ---", fetchedData);
          fetchSelectedMapStatus(fetchedData);
          // const selectedRegions = json.features.filter((odd) =>
          //   fetchedData.includes(odd.id)
          // );
          // console.log("selectedRegions ---", selectedRegions);
          // setSelectedRegionsFromGeojson(selectedRegions);
          // selectedRegionsFromGeojsonRef.current = selectedRegions;
        }
      }

      setLoadingRegions(false);
    } catch (err) {
      console.log("Error fetching map data and layers:", err);
      setLoadingRegions(false);
    }
  };

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        // Set the map viewport with the current location
        setLat(latitude);
        setLng(longitude);

        map.current.flyTo({
          center: [longitude, latitude], // Specify the destination coordinates
          zoom: 15, // Specify the zoom level of the destination
          speed: 0.8, // Control the speed of the flyTo animation
          curve: 1.42, // Control the curve of the flight path
          easing: (t) => t, // Customize the easing function
        });
      },
      (error) => {
        console.error("Error getting current location:", error);
      }
    );
  };

  const onClickChangeMapType = (type) => {
    let mapStyle;
    switch (type) {
      case mapTypes.streetView:
        mapStyle = "mapbox://styles/intakhabkhan/clikoeo8o00co01qpdpe9euh9";
        break;
      case mapTypes.satelliteView:
        mapStyle = "mapbox://styles/mapbox/satellite-v9";
        break;
      default:
        console.error("Invalid map type:", type);
        return;
    }

    if (type !== currentMapType) {
      // If changing to a different map type, remove existing map source and layers
      if (fetchedGeoJSONDataRef.current) {
        removeMapSourceAndLayers("route1");
      } else {
        console.log("GeoJSON data is not present. Fetching data...");
      }

      map.current.setStyle(mapStyle);

      if (fetchedGeoJSONDataRef.current && type === mapTypes.satelliteView) {
        // If switching to satellite view and data is fetched, re-add map source and layers
        setupMapSourceAndLayers(fetchedGeoJSONDataRef.current);
      }

      // Update the current map type
      setCurrentMapType(type);
    } else {
      console.log("Already on the selected map type:", type);
    }

    getCurrentLocation();
  };

  const handleOnMapMove = (e) => {
    setLng(map.current.getCenter().lng.toFixed(4));
    setLat(map.current.getCenter().lat.toFixed(4));
    setZoom(map.current.getZoom().toFixed(2));
  };

  // console.log('selectedRegionsFromGeojson', selectedRegionsFromGeojson)
  // console.log('geoJsonFeatures', geoJsonFeatures)

  const onClickNext = async () => {
    setIsLoading(true);
    const selectedPolygonIds = selectedRegionsFromGeojson.map(
      (item) => item.id
    );
    const geoJson = geoJsonFeatures.filter((item, idx) =>
      selectedPolygonIds.includes(idx)
    );

    const filterExternalIds =
      selectedPolygonIds.filter((typ) => typeof typ === "string") || [];
    const gettingExternalRoute =
      selectedRegionsFromGeojson.filter((item) =>
        filterExternalIds.includes(item.id)
      ) || [];

    const geoIds = [...gettingExternalRoute, ...geoJson].map((item, i) => ({
      gid: item["id"],
      name: `${Maps.projectName}_${i}`,
    }));

    // const
    const payload = {
      pralion_id: pralionId,
      gids: geoIds,
    };

    console.log("payload", payload);

    const resp = await updateMapProjects(payload);
    // console.log("resp", resp);
    if (resp.status === 200) {
      console.log("resp 200", resp);
      // setIsLoading(false);
      toast.success("Map created successfully");
      setTimeout(() => {
        navigate(`/projects/${projectId}/applications/maps`);
      }, 3000);
    } else {
      const err = resp?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setIsLoading(false);
    }
  };

  const checkTypeOfElement = (element, returnParsedObject = false) => {
    try {
      const parsed = JSON.parse(element);
      const isArray = Array.isArray(parsed);
      if (isArray) {
        return returnParsedObject ? ["array", parsed] : "array";
      } else {
        return returnParsedObject ? ["object", parsed] : "object";
      }
    } catch (error) {
      return returnParsedObject ? ["string", element] : "string";
    }
  };

  const onChangeGeojsonAttribute = (e, val) => {
    setSelectedGeojsonAttribute(val);
    const values = [
      ...new Set(geoJsonFeatures.map((item) => item.properties[val])),
    ].sort();
    setGeojsonAttributesValues(values);
    if (val === null) {
      setSearchedMapRegions([]);
    }
  };

  // console.log('geoJso', geoJsonFeatures)

  const onChangeGeojsonAttributeValue = (e, val) => {
    setSelectedGeojsonAttributeValue(val);
    if (val === null) {
      setSearchedMapRegions([]);
      setActiveSearchedMapRegion(null);
      resetColorsOfPolygons();
    } else {
      const items = geoJsonFeatures.filter(
        (item) => item.properties[selectedGeojsonAttribute] === val
      );
      if (items.length > 0) {
        setSearchedMapRegions(items);
        setActiveSearchedMapRegion(0);
        //TODO fly to 1st map
        // const tempjson = {...geoJsonData};
        // const indexToUpdateColor = geoJsonData.features.findIndex(item => item.id === items[0].id);
        console.log("items[0].id", items[0].id);
        map.current.setPaintProperty("route1-polygon", "fill-color", [
          "case",
          ["==", ["get", selectedGeojsonAttribute], val],
          "rgba(255, 0, 0, 0.4)",
          "rgba(0, 163, 181, 0.4)",
        ]);
        map.current.flyTo({
          center: items[0].geometry.coordinates[0][0], // Specify the destination coordinates
          zoom: 15, // Specify the zoom level of the destination
          speed: 0.8, // Control the speed of the flyTo animation
          curve: 1.42, // Control the curve of the flight path
          easing: (t) => t, // Customize the easing function
        });
        // tempjson.features[indexToUpdateColor].properties.fill = "#000";
        // map.current.getSource("route1").setData(tempjson);
      } else {
        setActiveSearchedMapRegion(null);
      }
    }
  };

  const resetColorsOfPolygons = (key, value) => {
    map.current.setPaintProperty(
      "route1-polygon",
      "fill-color",
      "rgba(0, 163, 181, 0.4)"
    );
  };

  useEffect(() => {
    // console.log('activeSelectedRegionInList', activeSelectedRegionInList)
    if (activeSelectedRegionInList !== null) {
      if (
        ["Polygon", "MultiLineString"].includes(
          selectedRegionsFromGeojson[activeSelectedRegionInList].geometry.type
        )
      ) {
        const lnglat =
          selectedRegionsFromGeojson[activeSelectedRegionInList].geometry
            .coordinates[0][0];
        // TODO: Fly to that odd
        map.current.flyTo({
          center: lnglat, // Specify the destination coordinates
          zoom: 15, // Specify the zoom level of the destination
          speed: 0.8, // Control the speed of the flyTo animation
          curve: 1.42, // Control the curve of the flight path
          easing: (t) => t, // Customize the easing function
        });
      } else {
        const lnglat =
          selectedRegionsFromGeojson[activeSelectedRegionInList].geometry
            .coordinates[0];
        // TODO: Fly to that odd
        map.current.flyTo({
          center: lnglat, // Specify the destination coordinates
          zoom: 15, // Specify the zoom level of the destination
          speed: 0.8, // Control the speed of the flyTo animation
          curve: 1.42, // Control the curve of the flight path
          easing: (t) => t, // Customize the easing function
        });
      }
    }
  }, [activeSelectedRegionInList]);

  const onClickIncShowMapButton = (e) => {
    if (activeSearchedMapRegion + 1 >= searchedMapRegions.length) {
      return;
    }
    const indexToUpdateColor = geoJsonData.features.findIndex(
      (item) => item.id === searchedMapRegions[activeSearchedMapRegion + 1].id
    );
    if (indexToUpdateColor === -1) {
      console.log("indexToUpdateColor", indexToUpdateColor);
      return;
    }
    setActiveSearchedMapRegion(activeSearchedMapRegion + 1);
    map.current.flyTo({
      center:
        geoJsonData.features[indexToUpdateColor].geometry.coordinates[0][0], // Specify the destination coordinates
      zoom: 15, // Specify the zoom level of the destination
      speed: 0.8, // Control the speed of the flyTo animation
      curve: 1.42, // Control the curve of the flight path
      easing: (t) => t, // Customize the easing function
    });
  };

  const onClickDecShowMapButton = (e) => {
    if (activeSearchedMapRegion === 0) {
      return;
    }
    const indexToUpdateColor = geoJsonData.features.findIndex(
      (item) => item.id === searchedMapRegions[activeSearchedMapRegion - 1].id
    );
    if (indexToUpdateColor === -1) {
      console.log("indexToUpdateColor", indexToUpdateColor);
      return;
    }
    setActiveSearchedMapRegion(activeSearchedMapRegion - 1);
    map.current.flyTo({
      center:
        geoJsonData.features[indexToUpdateColor].geometry.coordinates[0][0], // Specify the destination coordinates
      zoom: 15, // Specify the zoom level of the destination
      speed: 0.8, // Control the speed of the flyTo animation
      curve: 1.42, // Control the curve of the flight path
      easing: (t) => t, // Customize the easing function
    });
  };

  const handleOpenModal = () => {
    setOpenInfoModal(true);
  };

  const closeInfoModal = () => setOpenInfoModal(false);

  const deleteRoute = (item, id) => {
    const list = [...selectedRegionsFromGeojson];
    list.splice(id, 1);
    selectedRegionsFromGeojsonRef.current.splice(id, 1);
    setSelectedRegionsFromGeojson(list);

    // Remove the item.id from the mapGId array 
    const filteredMapGId = mapGId.filter((gid) => gid !== item.id); 
    mapGId.splice(0, mapGId.length, ...filteredMapGId);

    console.log("list", list);
    const externalListCheck = list.filter(item => Object.keys(item).includes('external')).length
    console.log('externalListCheck', externalListCheck)
    setFilesArrayLength(externalListCheck)
    setStatusCheck("")
  };

  const singleRouteLogs = async (item, name) => {
    console.log("name ---", name);
    const routeId = item.id;
    setRouteLog({ ...item, name: name });
    setLogStatus(true);
    const response = await getMapLogStatus(routeId);
    if (response.status === 200) {
      console.log("resp 200", response);
      setStatusData(response.data.data);
      setLogStatus(false);
      setLogStatusModal(true);
    } else {
      setLogStatus(false);
      console.log("resp error", response);
    }
  };

  const handleDeleteFile = (index) => {
    const updatedFiles = [...selectedFile];
    updatedFiles.splice(index, 1);
    setSelectedFile(updatedFiles);
    if (updatedFiles.length === 0) {
      setIsFileSelected(true);
    }
  };

  return (
    <>
      <ModalX
        open={logStatusModal}
        close={() => setLogStatusModal(false)}
        heading={`Log Status ${routeLog?.name}`}
        subheading={`Status`}
      >
        <MapStatusModal statusObject={statusData} handleClose={handleClose} />
      </ModalX>
      <ModalX
        open={openInfoModal}
        heading={<span>Guide to MyMaps</span>}
        subheading={<span>Creating and Managing Maps</span>}
        close={closeInfoModal}
      >
        <InstructionGuide />
      </ModalX>
      <ModalX
        open={open}
        close={handleClose}
        heading={<span>Map file upload</span>}
        subheading={<span>Select xodr file</span>}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          sx={{
            border: "1px solid #31496e",
            borderRadius: "4px",
            maxHeight: "30em",
            overflowY: "auto",
          }}
        >
          <List style={{ padding: "0.5em 2em" }}>
            {(selectedFile && Object.values(selectedFile).length > 0 && (
              <Grid container direction="row">
                {Object.values(selectedFile).map((file, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteFile(index)}
                        disabled={isLoad}
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar
                        style={{ color: "#fff", background: "#ffffff4d" }}
                      >
                        <InsertDriveFileIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={file.name} />
                  </ListItem>
                ))}
              </Grid>
            )) || (
              <ListItem
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <ListItemText primary="No file found" />
              </ListItem>
            )}
          </List>
        </Grid>
        <p
          style={{
            marginTop: "1rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Click to select files */}
          <Button
            component="label"
            role={undefined}
            tabIndex={-1}
            sx={{
              mt: 2,
              backgroundColor: "#00A3B5",
              color: "white",
              height: "40px",
              textTransform: "capitalize",
              fontSize: "16px",
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "#00A3B5",
              },
            }}
          >
            Select files
            <VisuallyHiddenInput
              type="file"
              multiple
              accept=".xodr"
              onChange={handleFileChange}
            />
          </Button>
        </p>
        <Grid sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <ButtonX
            onClick={handleFileUpload}
            disabled={isFileSelected || isLoad}
          >
            Publish
            {isLoad ? (
              <CircularProgress
                size={20}
                style={{ marginLeft: "8px" }}
                color="inherit"
              />
            ) : (
              ""
            )}
          </ButtonX>
        </Grid>
      </ModalX>
      <div className={`container-fluid`}>
        <div className={`row`}>
          <div className={`col-12 d-flex justify-content-between`}>
            <div className={``}>
              {Maps.readonly ? (
                <ReadOnlyTag />
              ) : (
                <>
                  <IconButton sx={{ ml: 0, mr: 1 }} onClick={handleOpenModal}>
                    <InfoIcon />
                  </IconButton>
                  <IconButton
                    sx={{ ml: 0, mr: 1 }}
                    onClick={handleOpen}
                    disabled={filesArrayLength || loadingRegions}
                  >
                    <CloudUploadIcon />
                  </IconButton>
                </>
              )}
            </div>
            <div className={`d-flex align-items-center`}>
              <ButtonX onClick={() => navigate(-1)}>Back</ButtonX>
              <ButtonX
                className={`ms-2`}
                disabled={
                  Maps.readonly ||
                  !selectedRegionsFromGeojson.length ||
                  isLoading ||
                  loadingRegions ||
                  statusActive
                }
                onClick={onClickNext}
              >
                Submit
                {isLoading ? (
                  <CircularProgress
                    size={20}
                    style={{ marginLeft: "8px" }}
                    color="inherit"
                  />
                ) : (
                  ""
                )}
              </ButtonX>
            </div>
          </div>
        </div>
        <div className={`row mt-3`}>
          <div className={`col-lg-12`}>
            <div className={`maps-map-container`}>
              <div className={`maps-map-sidebar`}>
                <div className={`maps-map-sidebar-item`}>
                  <div
                    className={`d-flex justify-content-between align-items-center p-2`}
                  >
                    {/* Changed Selected Regions -> Selected Maps */}
                    <Typos.H6 className={`fw-bold`}>Selected Maps</Typos.H6>
                    <IconButtonX
                      icon={showSelectedRegions ? "ExpandLess" : "ExpandMore"}
                      onClick={() =>
                        setShowSelectedRegions(!showSelectedRegions)
                      }
                    />
                  </div>
                  {showSelectedRegions && <Divider />}
                  {showSelectedRegions &&
                    selectedRegionsFromGeojson.length === 0 &&
                    !loadingRegions && (
                      <AlertX
                        text={"Select Regions from the map"}
                        badge={"info"}
                      />
                    )}
                  <CollapseContent show={showSelectedRegions}>
                    {loadingRegions ? (
                      [...Array(7).keys()].map((item, i) => (
                        <Skeleton
                          animation="wave"
                          key={i}
                          width={"100%"}
                          height={36}
                        />
                      ))
                    ) : (
                      <div className={`maps-list`}>
                        <List dense>
                          {selectedRegionsFromGeojson.map((row, i) => {
                            return (
                              <>
                                {i !== 0 && <Divider />}
                                <Grid sx={{ display: "flex" }}>
                                  <ListItemButton
                                    disabled={row.external ? true : false}
                                    key={i}
                                    onClick={() =>
                                      setActiveSelectedRegionInList(
                                        activeSelectedRegionInList === i
                                          ? null
                                          : i
                                      )
                                    }
                                  >
                                    {row.external ? (
                                      ""
                                    ) : (
                                      <ListItemIcon>
                                        {activeSelectedRegionInList === i ? (
                                          <ExpandMoreRounded />
                                        ) : (
                                          <KeyboardArrowRightRounded />
                                        )}
                                      </ListItemIcon>
                                    )}

                                    <ListItemText
                                      primary={`${Maps.projectName}_${i}`}
                                      primaryTypographyProps={{
                                        className: "fw-bold",
                                      }}
                                    />
                                    {row.external ? (
                                      <Chip
                                        label={row.status}
                                        variant="outlined"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </ListItemButton>
                                  {Maps.readonly ? '' :
                                    <IconButtonX
                                      disabled={
                                        row.status === "running" ||
                                        Maps.readonly ||
                                        statusActive
                                      }
                                      onClick={() => deleteRoute(row, i)}
                                      icon="DeleteRounded"
                                    />
                                  }
                                  {Maps.readonly || row.external ? (
                                    row.id === routeLog?.id && logStatus ? (
                                      <CircularProgress
                                        style={{
                                          width: "1.5em",
                                          height: "1.5em",
                                          marginTop: "7px",
                                        }}
                                      />
                                    ) : (
                                      <IconButtonX
                                        // disabled={
                                        //   Maps.readonly
                                        // }
                                        onClick={() =>
                                          singleRouteLogs(
                                            row,
                                            `${Maps.projectName}_${i}`
                                          )
                                        }
                                        icon="Assessment"
                                      />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </Grid>

                                <Collapse
                                  in={activeSelectedRegionInList === i}
                                  unmountOnExit
                                  className={`mb-2`}
                                >
                                  <List dense component="div" disablePadding>
                                    {Object.keys(row.properties).map(
                                      (nrow, ni) => {
                                        return (
                                          <Box
                                            key={`${i}-${ni}`}
                                            sx={{ pl: 4, pt: 0, pb: 0 }}
                                          >
                                            <ListItemButton
                                              sx={{ pl: 0, pt: 0, pb: 0 }}
                                            >
                                              <ListItemText
                                                primary={
                                                  <div className={`d-flex`}>
                                                    <Typos.Caption
                                                      className={`fw-bold me-2`}
                                                    >
                                                      {nrow}:
                                                    </Typos.Caption>
                                                    {checkTypeOfElement(
                                                      row.properties[nrow]
                                                    ) === "array" ? (
                                                      <div>
                                                        {JSON.parse(
                                                          row.properties[nrow]
                                                        ).map((nnrow, nni) => {
                                                          return (
                                                            <div
                                                              className={`d-flex`}
                                                              key={`${i}-${ni}-${nni}`}
                                                            >
                                                              <HorizontalRuleRounded fontSize="small" />
                                                              <Typos.Caption>
                                                                {nnrow}
                                                              </Typos.Caption>
                                                            </div>
                                                          );
                                                        })}
                                                      </div>
                                                    ) : (
                                                      <Typos.Caption>
                                                        {row.properties[nrow]}
                                                      </Typos.Caption>
                                                    )}
                                                  </div>
                                                }
                                              />
                                            </ListItemButton>
                                            <Divider />
                                          </Box>
                                        );
                                      }
                                    )}
                                  </List>
                                  {/* <div className={`d-flex justify-content-end`}>
                                    <IconButtonX icon="DeleteRounded" />
                                    <IconButtonX icon="FmdGoodRounded" />
                                  </div> */}
                                </Collapse>
                              </>
                            );
                          })}
                        </List>
                      </div>
                    )}
                  </CollapseContent>
                </div>
              </div>
              {!Maps.readonly && (
                <div className={`maps-map-sidebar-right`}>
                  <div className={``}>
                    <Typos.H5>Search Maps</Typos.H5>
                    <hr />
                    <Autocomplete
                      options={geojsonAttributes}
                      onChange={onChangeGeojsonAttribute}
                      value={selectedGeojsonAttribute}
                      disabled={selectedGeojsonAttributeValue !== null}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Attribute to search"
                          variant="filled"
                        />
                      )}
                    />

                    <Autocomplete
                      options={geojsonAttributesValues}
                      onChange={onChangeGeojsonAttributeValue}
                      value={selectedGeojsonAttributeValue}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Search"
                          variant="filled"
                        />
                      )}
                    />
                  </div>
                  {selectedGeojsonAttribute &&
                    selectedGeojsonAttributeValue && (
                      <Fragment>
                        <hr />
                        <div
                          className={`d-flex justify-content-between align-items-center`}
                        >
                          <Typos.H6 color="primary">
                            Map {activeSearchedMapRegion + 1} of{" "}
                            {searchedMapRegions.length}
                          </Typos.H6>
                          <ButtonGroup size="small">
                            <Button
                              onClick={onClickDecShowMapButton}
                              disabled={activeSearchedMapRegion === 0}
                            >
                              <ArrowBackIosRounded />
                            </Button>
                            <Button
                              onClick={onClickIncShowMapButton}
                              disabled={
                                activeSearchedMapRegion + 1 >=
                                searchedMapRegions.length
                              }
                            >
                              <ArrowForwardIosRounded />
                            </Button>
                          </ButtonGroup>
                        </div>
                        {
                          activeSearchedMapRegion !== null && (
                            <div className={``}>
                              <hr />
                              <List>
                                {Object.keys(
                                  searchedMapRegions[activeSearchedMapRegion]
                                    .properties
                                ).map((row, i) => (
                                  <ListItemButton sx={{ pt: 0, pb: 0 }} key={i}>
                                    <ListItemText
                                      primary={
                                        <div
                                          className={`d-flex ${
                                            checkTypeOfElement(
                                              searchedMapRegions[
                                                activeSearchedMapRegion
                                              ].properties[row]
                                            ) === "array"
                                              ? ""
                                              : "justify-content-between"
                                          }`}
                                        >
                                          <Typos.Caption
                                            color="primary"
                                            className={`fw-bold me-2`}
                                          >
                                            {row}:
                                          </Typos.Caption>
                                          {checkTypeOfElement(
                                            searchedMapRegions[
                                              activeSearchedMapRegion
                                            ].properties[row]
                                          ) === "array" ? (
                                            <div>
                                              {JSON.parse(
                                                searchedMapRegions[
                                                  activeSearchedMapRegion
                                                ].properties[row]
                                              ).map((nrow, ni) => {
                                                return (
                                                  <div
                                                    className={`d-flex`}
                                                    key={`${i}-${ni}`}
                                                  >
                                                    <HorizontalRuleRounded fontSize="small" />
                                                    <Typos.Caption>
                                                      {nrow}
                                                    </Typos.Caption>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          ) : (
                                            <Typos.Caption className="text-end">
                                              {
                                                searchedMapRegions[
                                                  activeSearchedMapRegion
                                                ].properties[row]
                                              }
                                            </Typos.Caption>
                                          )}
                                        </div>
                                      }
                                    />
                                  </ListItemButton>
                                ))}
                              </List>
                            </div>
                          )
                          // <Typos.Body1>{}</Typos.Body1>
                        }
                      </Fragment>
                    )}
                </div>
              )}

              <div className={`maps-map-types-bar`}>
                <IconButtonX
                  wrapper
                  size={"small"}
                  icon={"StreetviewRounded"}
                  onClick={() => onClickChangeMapType(mapTypes.streetView)}
                />
                <IconButtonX
                  wrapper
                  size={"small"}
                  icon={"SatelliteRounded"}
                  onClick={() => onClickChangeMapType(mapTypes.satelliteView)}
                />
              </div>
              <div ref={mapContainer} className="maps-map-view" />
            </div>
          </div>
        </div>
        <UxSpace />
      </div>
    </>
  );
}
export default MainMaps;
