import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { replicaSimRDetailsTable } from "utils";

export default function VehicleProfile({ selectedCar, fields }) {
  const carObject = selectedCar || []
  const selectedCarObject = Object.entries(carObject)
  const { trafficPreset, testVehicle } = fields;



  const matchingKey = (parameter) => {
    const matchingData = selectedCarObject.find(([key, value]) =>
      key.toLowerCase().includes(parameter.toLowerCase().replace(/\s/g, "_"))
    );
    return matchingData ? matchingData[1] : "";
  };


  return (
    <Grid

    >
      <Grid >
        <Typography
          color="text.main"
          sx={{ marginLeft: "1rem", marginTop: "1.5rem" }}
        >
          Traffic Preset
        </Typography>
      </Grid>
      {trafficPreset}

      {/* <Grid >
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Para meter</TableCell>
                <TableCell>Default Value</TableCell>
                <TableCell>Units</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>-</TableCell>
                <TableCell>
                  <Grid style={{ display: "flex" }}>
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                    <IconButton>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid> */}

      <Grid >
        <Typography
          color="text.main"
          sx={{ marginLeft: "1rem", marginTop: "1rem" }}
        >
          Test Vehicle Search
        </Typography>
      </Grid>
      {testVehicle}

      <div style={{ maxHeight: "30vh", overflowX: "hidden", overflowY: "auto", marginTop: "1rem" }}>
        {/* <TableContainer>
          <Table size="small" style={{ opacity: 0.5, pointerEvents: "none" }}>
            <TableHead>
              <TableRow>
                <TableCell>Para meter</TableCell>
                <TableCell>Default Value</TableCell>
                <TableCell>Units</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {replicaSimRDetailsTable.map((item) => {
                const value = matchingKey(item.parameter);

                return (
                  <TableRow key={item.parameter}>
                    <TableCell>{item.parameter}</TableCell>
                    <TableCell>{value}</TableCell>
                    <TableCell>{item.units}</TableCell>
                    <TableCell>
                      <Grid style={{ display: "flex", opacity: 0.5, pointerEvents: "none" }}>
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer> */}
      </div>
    </Grid>
  );
}
