import { Button, Grid, CircularProgress } from "@mui/material";
import MainTable from "components/mainTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import STORE from "services/redux-store/store";
import { useParams, useNavigate } from "react-router-dom";
import { Fragment } from "react";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { putTestSuits } from "endpointServices/testSuitServices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function TestSuitsExecute() {
  const navigate = useNavigate();
  const param = useParams();
  const [isLoading, setIsLoading] = useState(false)
  const [scenarios, setScenarios] = useState({
    autoScenarios: [],
    myScenarios: [],
  });

  const { TestSuits } = STORE.getState();
  const {
    pralionId,
    autoScenarioNumberOfScenarios,
    myScenarioNumberOfScenarios,
  } = TestSuits;

  const autoSce = autoScenarioNumberOfScenarios || []
  const mySce = myScenarioNumberOfScenarios || []

  useEffect(() => {
    getScenariosList();
  }, []);

  const getScenariosList = () => {
    setIsLoading(true)
    const autoScenarios = autoScenarioNumberOfScenarios || [];
    const myScenarios = myScenarioNumberOfScenarios || [];
    setScenarios({ autoScenarios, myScenarios });
    setIsLoading(false)
  };

  const columns = [
    {
      field: "name",
      renderHeader: () => <span className="heading-2">{"Scenarios Name "}</span>,
      width: 550,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Button
              className="Main-link-simple row-heading"
              sx={{ color: "white" }}
            >
              {params.value}
            </Button>
          </Grid>
        </Grid>
      ),
    },
   
    {
      field: "output.MinTTC",
      renderHeader: () => <span className="heading-2">{"Min TTC (s)"}</span>,
      width: 175,
      renderCell: (params) => (
        <Grid>
          {params.row.output?.MinTTC?.toFixed(3)}
        </Grid>
      ),
    },
    {
      field: "output.MinTHW",
      renderHeader: () => <span className="heading-2">{"Min THW (s) "}</span>,
      width: 175,
      renderCell: (params) => (
        <Grid>
          {params.row.output?.MinTHW?.toFixed(3)}
        </Grid>
      ),
    },
    {
      field: "output.LJavg",
      renderHeader: () => <span className="heading-2">{"LJ Avg (m/s^3)  "}</span>,
      width: 175,
      renderCell: (params) => (
        <Grid>
          {params.row.output?.LJavg?.toFixed(3)}
        </Grid>
      ),
    },
    // {
    //   field: "output.TTC_Pass",
    //   renderHeader: () => <span className="heading-2">{"TTC Pass"}</span>,
    //   width: 149,
    //   renderCell: (params) => (
    //     <Grid container spacing={1} alignItems="center" sx={{marginLeft:'1em'}}>
    //       {params.row.output?.TTC_Pass === true ? (
    //         <p style={{ marginTop: "16px" }}>True</p>
    //       ) : params.row.output?.TTC_Pass === false ? (
    //         <p style={{ marginTop: "16px" }}>False</p>
    //       ) : (
    //         ""
    //       )}
    //     </Grid>
    //   ),
    // },
    // {
    //   field: "output.LJ_Pass",
    //   headerName: "LJ Pass",
    //   renderHeader: () => <span className="heading-2">{"LJ Pass"} </span>,
    //   width: 149,
    //   renderCell: (params) => (
    //     <Grid container spacing={1} alignItems="center" sx={{marginLeft:'1em'}}>
    //       {params.row.output?.LJ_Pass === true ? (
    //         <p style={{ marginTop: "16px" }}>True</p>
    //       ) : params.row.output?.LJ_Pass === false ? (
    //         <p style={{ marginTop: "16px" }}>False</p>
    //       ) : (
    //         ""
    //       )}
    //     </Grid>
    //   ),
    // },
    // {
    //   field: "output.THW_Pass",
    //   renderHeader: () => <span className="heading-2">{"THW Pass"}</span>,
    //   width: 149,
    //   renderCell: (params) => (
    //     <Grid container spacing={1} alignItems="center" sx={{marginLeft:'1em'}}>
    //       {params.row.output?.THW_Pass === true ? (
    //         <p style={{ marginTop: "16px" }}>True</p>
    //       ) : params.row.output?.THW_Pass === false ? (
    //         <p style={{ marginTop: "16px" }}>False</p>
    //       ) : (
    //         ""
    //       )}
    //     </Grid>
    //   )
    // },
    // {
    //   field: "output.ScenarioPass",
    //   renderHeader: () => <span className="heading-2">{"Scenario Pass"}</span>,
    //   width: 149,
    //   renderCell: (params) => (
    //     <Grid container spacing={1} alignItems="center" sx={{marginLeft:'1em'}}>
    //       {params.row.output?.ScenarioPass === true ? (
    //         <p style={{ marginTop: "16px" }}><DoneIcon sx={{ color: "green" }} /></p>
    //       ) : params.row.output?.ScenarioPass === false ? (
    //         <p style={{ marginTop: "16px" }}><CloseIcon sx={{ color: "red" }} /></p>
    //       ) : (
    //         ""
    //       )}
    //     </Grid>
    //   ),
    // },
  ];

  const onExecute = async () => {
    const autoScenarios = autoSce.map(autoScenario => autoScenario.id)
    const myScenarios = mySce.map(myScenario => myScenario.id)
    const scenariosIds = [...myScenarios, ...autoScenarios]
    const payload = {
      pralion_id: parseInt(pralionId),
      executable_scenario_ids:scenariosIds
    };
    const response = await putTestSuits(payload)
    if (response?.status === 200) {
      console.log('response', response)
      toast.success(`Successfuly saved scenarios`);
      setTimeout(() => {
        navigate(`/projects/${param.id}/applications`)
      }, 3000);
      
    }else {
      const errr = response?.data?.message
      toast.error(errr);
     
    }
  };

  return (
    <Fragment>
      <Grid container justifyContent={"end"}>
        <Button
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            color: "text.main",
            fontWeight: "bold",
            marginRight: "10px",
          }}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Button
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            color: "white",
            fontWeight: "bold",
            // width: "130px",
          }}
          onClick={onExecute}
        >
          Save
          {isLoading ? (
            <CircularProgress
              size={20}
              style={{ marginLeft: "8px" }}
              color="inherit"
            />
          ) : (
            ""
          )}
        </Button>
      </Grid>
      <MainTable
        table={[...scenarios.autoScenarios, ...scenarios.myScenarios]}
        headers={columns}
      />
    </Fragment>
  );
}
