import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import {
  Button,
  Divider,
  Grid,
  Typography,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Alert,
  Collapse,
} from "@mui/material";
import CodeEditor from "components/codeEditor";
import { Link, useNavigate, useParams } from "react-router-dom";
import CodeIcon from "@mui/icons-material/Code";
import {
  getRilInterfaces,
  getRilSidePanelInterfaces,
  postRilValidateInterfaces,
  getRilUpdateCodeInterfaces,
} from "endpointServices/educAgentServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AUTHENTICATED_ROUTES } from "router/constants";

export default function EducDefineRewards() {
  const navigator = useNavigate();
  const param = useParams();
  const [codeDoc, setCodeDoc] = useState("");
  const [codeEditor, setCodeEditor] = useState("");

  const [metric, setMetric] = useState([]);
  const [open, setOpen] = useState(false);
  const [collapseId, setCollapseId] = useState("");
  const [errorCode, setErrorCode] = useState("");
  const [next, setNext] = useState(false);
  const [executed, setExecuted] = useState(true)
  const [isNextButtonDisabled, setNextButtonDisabled] = useState(false);

  useEffect(() => {
    getCodeSample();
    getCodeObserverMetric();
    localStorage.removeItem("observer_id");
  }, []);

  const handleClick = (id) => {
    setCollapseId(id);
    setOpen(!open);
  };

  const getCodeSample = async () => {
    const response = await getRilInterfaces(param.aid);
    console.log("response", response);
    if (response?.status === 200) {
      const sample = response?.data.data;
      const code = sample?.output?.output || "";
      console.log("code", code);
      setCodeDoc(code);
      setCodeEditor(code);
    } else {
      const err = response?.message || "Request failed";
      toast.error(`${err}. Please try again later`);
    }
  };

  const updateCodeSample = async (row) => {
    const body = {
      name: row,
      pralionId: param.aid,
    };
    setExecuted(true)
    const response = await getRilUpdateCodeInterfaces(body);
    console.log("response", response);
    if (response?.status === 200) {
      const resp = response?.data.data;
      console.log("resp", resp);
      const code = codeDoc + "\n" + resp?.output.output;
      console.log("updateCodeSample", code);
      setCodeEditor(code);
      setExecuted(false)
      // const decodeData = atob(code)
    } else {
      const err = response?.message || "Request failed";
      toast.error(`${err}. Please try again later`);
      setExecuted(false)
    }
  };

  const getCodeObserverMetric = async () => {
    const response = await getRilSidePanelInterfaces(param.aid);
    console.log("getCodeObserverMetric", response);
    if (response?.status === 200) {
      const sample = response?.data?.data?.output.output;
      setMetric(sample);
    } else {
      const err = response?.message || "Request failed";
      toast.error(`${err}. Please try again later`);
    }
  };

  const handleCodeInput = React.useCallback((value) => {
    setCodeEditor(value);
  }, []);

  const presetListRender = () => {
    return (
      metric &&
      metric.map((row, idx) => {
        return (
          <>
            <ListItemButton
              key={idx}
              sx={{ borderBottom: "1px solid #5c616642" }}
            >
              <ListItemIcon>
                {idx}
                <CodeIcon sx={{ marginLeft: ".5em" }} />
              </ListItemIcon>
              <ListItemText
                primary={row}
                onClick={() => updateCodeSample(row)}
              />
            </ListItemButton>
          </>
        );
      })
    );
  };

  const onSubmitaCodeEditor = async (e) => {
    e.preventDefault();
    setNext(true)
    const payload = {
      pralion_id: param.aid,
      code: btoa(codeEditor),
    };
    console.log("Payload:", payload);
    const response = await postRilValidateInterfaces(payload);
    console.log("response", response);
    if (response?.status === 200) {
      const resp = response?.data?.data?.output;
      setErrorCode(resp);
      console.log("response", response);
      setNext(false)
      setNextButtonDisabled(response?.data?.data?.success === true);
    } else {
      const err = response?.message || "Failed to sumbit request";
      toast.error(`${err}. Please try again later`);
      setNextButtonDisabled(false)
      setNext(false)
    }
  };

  return (
    <Fragment>
      {/* <Grid container alignItems="end" justifyContent="space-between"> */}
      {/* <Grid item display="flex">
          <Typography variant="caption" sx={{ fontSize: 14 }}>
            Define or Select Rewards from List
          </Typography>
          <Typography
            sx={{ fontSize: 10, marginLeft: "2px", marginTop: "5px" }}
          >
            - an rewards is a computational entity or algorithm that monitors
            the behavior of a Highly Automated Driving (HAD) system
          </Typography>
        </Grid> */}
      <Grid item sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            color: "white",
            width: "130px",
            marginRight: "10px",
          }}
          onClick={() => navigator(-1)}
        >
          Back
        </Button>

        <Link
          to={AUTHENTICATED_ROUTES.educTestCases
            .replace(":id", param.id)
            .replace(":aid", param.aid)
            .replace(":oddid", param.oddid)
            .replace(":strategy", param.strategy)}
        >
          <Button
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "white",
              width: "130px",
            }}
            disabled={!isNextButtonDisabled}
          >
            Next
          </Button>
        </Link>
      </Grid>
      {/* </Grid> */}

      <Divider
        className="mb-3 mt-3"
        sx={{ borderBottom: "1px solid #D9D9D9" }}
      />

      <Grid container sx={{ backgroundColor: "black" }}>
        <Grid item xs={8}>
          <Grid container direction="column">
            <Grid
              item
              sx={{
                height: "60vh",
                width: "100%",
                borderBottom: "1px solid",
                borderColor: "primary.main",
                overflowY: "auto",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "80%" }}>
                <Typography
                  variant="h6"
                  sx={{
                    backgroundColor: "secondary.dark",
                    color: "white",
                    padding: "12px",
                    textAlign: "center",
                    width: "85px",
                    lineHeight: 1,
                  }}
                >
                  Editor
                </Typography>
                <CodeEditor
                  onChange={handleCodeInput}
                  value={codeEditor}
                  isEdit={true}
                  className="code-editor"
                />
              </div>
              <div>
                <Button
                  onClick={onSubmitaCodeEditor}
                  sx={{
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                    color: "white",
                    marginTop: "10px",
                    marginRight: "1rem",
                    width: "130px",
                  }}
                  disabled={next || executed}
                >
                  Execute
                </Button>
              </div>
            </Grid>
            <Grid item sx={{ height: "30vh", overflowY: "auto" }}>
              <Typography
                variant="h6"
                sx={{
                  backgroundColor: "secondary.dark",
                  color: "white",
                  padding: "12px",
                  textAlign: "center",
                  width: "85px",
                  lineHeight: 1,
                }}
              >
                Result
              </Typography>
              <Box p={2}>
                <Typography variant="body2" gutterBottom>
                  {errorCode === "" ? (
                    <Alert severity="info" icon={false}>
                      Please try to execute the code
                    </Alert>
                  ) : (
                    <Alert
                      severity={isNextButtonDisabled ? "success" : "error"}
                      icon={false}
                    >
                      {errorCode || `API response empty`}
                    </Alert>
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            borderLeft: "1px solid",
            borderColor: "primary.main",
            height: "90vh",
            overflowY: "auto",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              backgroundColor: "secondary.dark",
              color: "white",
              padding: "12px",
              textAlign: "center",
              width: "85px",
              lineHeight: 1,
            }}
          >
            List
          </Typography>
          <List
            sx={{ width: "100%", marginTop: ".5em" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader=""
          >
            {presetListRender()}
          </List>
        </Grid>
      </Grid>
    </Fragment>
  );
}
