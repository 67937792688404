import React, { Fragment } from "react";
import "./App.css";
import RouterX from "router";
import { BrowserRouter } from "react-router-dom";
import STORE from "services/redux-store/store";

function App() {
	return (
		<Fragment>
			<BrowserRouter>
				<RouterX />
			</BrowserRouter>
			{process.env.NODE_ENV === "development" && (
				<button style={{ zIndex: 10000, position: "absolute" }} onClick={() => console.log("STORE", STORE.getState())}>
					Print Redux Store
				</button>
			)}
		</Fragment>
	);
}

export default App;
