import React, { useEffect, useState } from "react"
import {
  Modal,
  Box,
  Slide,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { Typos } from "components";


const style = {
    backgroundColor: "#22385B",
    color: "#fff",
    zIndex: 1,
    position: "absolute",
    top: "20%",
    right: "0",
    opacity: "0.9",
    padding: "20px",
    margin: "25px",
    borderRadius: "10px",
    width: "320px",
    height: "65vh",
    borderStyle: "ridge",
    borderColor: "#00A2BB",
}

export default function LeftSideModalX({ width, open, close, heading, subheading, children }) {
  const [windowWidth, set_windowWidth] = useState(window.innerWidth)
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    // getStatusList();
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [])

//   if (width) {
//     if (windowWidth > 768) style.width = width
//     else style.width = "95%"
//   }


  const handleWindowSizeChange = () => {
    set_windowWidth(window.innerWidth);
  }

return(
  <>
    <Modal
        open={open}
        onClose={heading == 'Camera' ? false : close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
      <Slide direction="left" in={open}>
        <Box sx={{ ...style }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}>
            <Typos.H6>{heading}</Typos.H6>
              <CloseIcon
                onClick={close}
                cursor="pointer"
                sx={{ marginRight: "1rem", marginTop: "1rem" }}
              />
          </div>
          <Typos.Caption className={`d-flex align-items-center`}>{subheading}</Typos.Caption>
          {/* <hr /> */}
          {/* <Box sx={{ml:'7%'}}> */}
          {children}
          {/* </Box> */}
        </Box>
        </Slide>
    </Modal>
  </>
)
}


