import {  CREATE_PROJECT, GET_ALL_PROJECT_LIST_PROJECT, PROJECT_TRACEBILITY } from "constants/endpoints"
import { httpGet, httpPost } from "utils/httpMethods"

export const getAllProjectService = async (body, pagination) => {
    const {pageNumber = 1, testsPerPage = 10 } = pagination || {}
    return await httpGet({ url:`${GET_ALL_PROJECT_LIST_PROJECT}?project_id=${body}&page=${pageNumber}&page_size=${testsPerPage}&order=desc`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const createProjectService = async (body) => {
    return await httpPost({ url: CREATE_PROJECT, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getProjectTraceService = async (body) => {
    return await httpGet({ url:`${PROJECT_TRACEBILITY}?project_id=${body}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}
