import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Radio,
  Typography,
  Stack,
  Alert,
  ImageList,
  Checkbox,
} from "@mui/material";
import {
  getCosimClientRegister,
  getCosimClientService,
  getDownloadableBuilds,
} from "endpointServices/simulationServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { ButtonX, ModalX, ProgressX } from "components";
import ConfirmationModal from "components/confirmationModal";
import { SIMULATION_CLIENT_BUILD } from "utils";
import DownloadIcon from "@mui/icons-material/Download";

const clusterStyle = {
  backgroundColor: "secondary.dark",
  border: "2px solid",
  borderColor: "primary.main",
  borderRadius: "12px",
  padding: "5px",
  m: 1,
};

const SimulationClientBuild = (downloadList) => {
  const VERSATILE_BUILDS = downloadList?.data;
  return [
    {
      id: 1,
      title: "Windows",
      icons: <DownloadIcon />,
      // url: '',
      isDisabled: true,
    },
    {
      id: 2,
      title: "Linux",
      icons: <DownloadIcon />,
      url: VERSATILE_BUILDS?.replicar_cosim_client_linux?.url,
      isDisabled: false,
    },
  ];
};

export default function Simulations() {
  const [cosimClient, setCosimClient] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedMachine, setSelectedMachine] = useState({});
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [downloadList, setDownloadList] = useState({});

  useEffect(() => {
    getCosimClient();
  }, []);

  useEffect(() => {
    getDownloadList();
  }, []);

  const getDownloadList = async () => {
    const response = await getDownloadableBuilds();
    console.log("response", response);
    if (response?.status === 200) {
      const data = response?.data;
      setDownloadList(data);
    } else {
      const err = response?.message || "Failed to get request";
    }
  };

  const handleOpenModal = () => {
    setOpenConfirmationModal(true);
  };

  const closeConfirmationModal = () => setOpenConfirmationModal(false);

  const handleConfirmation = () => {
    handleDelete();
  };

  const handleDelete = async () => {
    const macAddress = selectedMachine.mac_address;
    const response = await getCosimClientRegister(macAddress);
    if (response?.status === 200) {
      const message = response?.data.message;
      toast.success(message);
      getCosimClient();
      closeConfirmationModal();
    } else {
      const err = response?.message;
      toast.error(`${err}. Please try again later`);
    }
  };

  const getCosimClient = async () => {
    setLoader(true);
    const response = await getCosimClientService();
    console.log("response", response);
    if (response?.status === 200) {
      const { data } = response?.data;
      console.log("data", data);
      setCosimClient(data || []);
      setLoader(false);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setCosimClient([]);
      setLoader(false);
    }
  };

  const handleMachineCheck = (value) => {
    console.log("value", value);
    setSelectedMachine(value);
  };

  return (
    <Fragment>
      <ConfirmationModal
        open={openConfirmationModal}
        heading={<span>Confirm Action</span>}
        subheading={<span>Reregistering the machine</span>}
        close={closeConfirmationModal}
        confirmationMessage={
          <span>
            Are you sure you want to deregister the{" "}
            {selectedMachine?.client_name} ?
          </span>
        }
        onConfirm={handleConfirmation}
      />
      <Grid
        container
        sx={{
          backgroundColor: "secondary.dark",
          border: "2px solid",
          borderColor: "primary.main",
          borderRadius: "6px",
          height: "35rem",
          overflow: "auto",
          marginTop: "1rem",
          p: 7,
        }}
      >
        <Grid
          item
          sx={{
            backgroundColor: "#00A3B5",
            position: "absolute",
            top: "8.4rem",
            left: "16.6rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "9rem",
            padding: 2,
            borderTopLeftRadius: "6px",
            borderBottomRightRadius: "6px",
          }}
        >
          <Typography sx={{ marginLeft: "1.5rem" }}>
            <b>Clusters</b>
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          sx={{
            height: "1em",
          }}
        >
          <ButtonX
            disabled={!Object.keys(selectedMachine).length}
            onClick={handleOpenModal}
          >
            Deregister
          </ButtonX>
        </Grid>
        <Grid container className="mt-5 mb-4">
          <ImageList
            sx={{
              width: "100%",
              maxHeight: "78vh",
              // padding: "12px",
            }}
            gap={30}
            cols={3}
            rowHeight={260}
          >
            {loader ? (
              [...Array(6).keys()].map((x, i) => (
                <div key={i}>
                  <ProgressX block={true} height={250} width={480} />
                </div>
              ))
            ) : cosimClient && cosimClient.length === 0 ? (
              <Stack sx={{ width: "100%", marginTop: "2em" }} spacing={2}>
                <Alert
                  icon={false}
                  style={{
                    background: "#0c130d00",
                    border: "1px solid #00a2b9",
                  }}
                >
                  No clients found!
                </Alert>
              </Stack>
            ) : (
              cosimClient?.map((cluster, index) => (
                <Grid
                  key={index}
                  item
                  sx={{
                    backgroundColor: "secondary.dark",
                    border: "2px solid",
                    borderColor: "primary.main",
                    borderRadius: "12px",
                  }}
                >
                  <Grid container justifyContent="space-between" padding="10px">
                    <Grid item>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Checkbox
                            checked={cluster.id === selectedMachine.id}
                            onChange={() => handleMachineCheck(cluster)}
                            disabled={cluster.status === "active"}
                          />
                        </Grid>
                        <Grid item>
                          <Typography>Machine-{cluster.id}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      display="grid"
                      justifyContent="center"
                      alignContent="center"
                    >
                      <Typography>
                        Status
                        <Radio
                          color={
                            cluster.status === "active" ? "success" : "error"
                          }
                          checked={cluster.status}
                          sx={{
                            marginLeft: "10px",
                            transform: "scale(0.6)",
                            width: "2px",
                            height: "2px",
                            "& > input": {
                              backgroundColor:
                                cluster.status === "active"
                                  ? "#4caf50"
                                  : "#f44336",
                              color:
                                cluster.status === "active"
                                  ? "#4caf50"
                                  : "#f44336",
                              "&:checked + span": {
                                backgroundColor:
                                  cluster.status === "active"
                                    ? "#4caf50"
                                    : "#f44336",
                                color:
                                  cluster.status === "active"
                                    ? "#4caf50"
                                    : "#f44336",
                                borderRadius: "16px",
                                width: "22px",
                                height: "22px",
                              },
                            },
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item className="cluster_box_size" sx={clusterStyle}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography sx={{ marginLeft: "5px" }}>
                        <b>Client Name</b>: {cluster.client_name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item sx={clusterStyle}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography sx={{ marginLeft: "5px" }}>
                        <b>Machine Address</b>: {cluster.mac_address}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item sx={clusterStyle}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography sx={{ marginLeft: "5px" }}>
                        <b>OS Info</b>: {cluster.os_info}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item sx={clusterStyle}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography sx={{ marginLeft: "5px" }}>
                        <b>GPU Info</b>:{" "}
                        {cluster?.gpu_info.map((row) => {
                          return <>{row}</>;
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))
            )}
          </ImageList>
        </Grid>
      </Grid>
      <Grid className="client-build application-container d-flex">
        <Grid
          item
          sx={{
            backgroundColor: "#00A3B5",
            position: "absolute",
            top: "44.4rem",
            left: "16.6rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            width: "10rem",
            padding: 2,
            borderTopLeftRadius: "6px",
            borderBottomRightRadius: "6px",
          }}
        >
          <Typography sx={{ marginLeft: "1.5rem" }}>
            <b>Client Build</b>
          </Typography>
        </Grid>
        <Grid
          className="d-flex flex-row mt-5 mb-2"
          sx={{ marginLeft: "30rem" }}
        >
          {SimulationClientBuild(downloadList).map((application, index) => (
            <Grid
              item
              xs={3}
              sm={2}
              linkcomponent={Link}
              // to={`${projectPath}/${application.url}`}
              className={`d-flex flex-column justify-content-center`}
              key={index}
              style={{
                marginRight: "8rem",
                width: "11rem",
                height: "9rem",
                borderColor: application.isDisabled
                  ? "#7b7d7e"
                  : "secondary.dark",
              }}
              sx={{
                border: "2px solid",
                borderColor:
                  application.url !== "none" ? "primary.main" : "primary.main", // #e9ecef
                // backgroundColor: application.isDisabled
                // ? "#c0cdd9ad"
                // : "secondary.dark",
                textAlign: "center",
                borderRadius: "10px",
                m: 1,
              }}
            >
              <Link
                className="Main-link-simple"
                aria-disabled={true}
                to={application.isDisabled ? "" : `${application.url}`}
              >
                <Typography
                  sx={{
                    color: "text.main",
                  }}
                >
                  {application.title}
                </Typography>
                <div>{application.icons}</div>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Fragment>
  );
}
