export const TRAFFIC_CONFIG_INSTRUCTIONS = [
    { text: "There are three steps to configure." },
    { text: "Set Vehicle profile by adjusting the sliders." },
    { text: "Total count should be 100." },
    { text: "Click on edit to set driver profile." },
    { text: "Total count should be 1." },
    { text: "Further click on edit to set algorithm attributes." },
    { text: "You can set aggressiveness here of which total count should be 1." },
    
    { text: "You will only be able to execute if you have set correct value counts." },
    
]