import ButtonX from "components/buttonX";
import React, { useEffect, useRef, useState, Fragment } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import { mapTypes, miniWrapText, wrapText } from "utils";
import { DEFAULT_POINT_LAYER } from "constants/defaults";
import { httpGet } from "utils/httpMethods";
import { GET_MY_SCENARIO_SCENE_ANALYTICS } from "constants/endpoints";
import { connect } from "react-redux";
import Draggable from "react-draggable";
import { CollapseContent, IconButtonX, ProgressX, Typos } from "components";
import "./my-analytics-map.css"
import { Divider } from "@mui/material";
import { toast } from "react-toastify";

function mapStateToProps(state) {
	return {
		SceneAnalytics: state.SceneAnalytics,
	};
}
export default connect(mapStateToProps)(function MyAnalyticsList({ SceneAnalytics }) {
	const mapContainer = useRef(null);
	const map = useRef(null);
  const popup = useRef(null);

	const [lng, setLng] = useState(7.42446); //7.42446, 51.580984
	const [lat, setLat] = useState(51.580984);
	const [zoom, setZoom] = useState(15);
  const [selectedAreaPoints, setSelectedAreaPoints] = useState([])
	const [showInfoPanel, setShowInfoPanel] = useState(false);
  const [initiallng, setInitialLng] = useState(7.42446); //7.42446, 51.580984
	const [initiallat, setInitialLat] = useState(51.580984);
	const [initialzoom, setInitialZoom] = useState(15);
  const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (!map.current) {
			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: mapTypes.streetView,
				center: [lng, lat],
				zoom: zoom,
			});
			map.current.on("load", () => {
        popup.current = new mapboxgl.Popup({
          closeButton: true,
          closeOnClick: true,
        })
				map.current.on("move", handleOnMapMove);
				// map.current.on("mousemove", "route", handleOnMouseMove);
				// map.current.on("click", "state-fills", handleOnClickPolygon);
				loadMapLayers();
			});
		}
	}, []);

	const loadMapLayers = async () => {
    setLoading(true);
		await httpGet({ url: GET_MY_SCENARIO_SCENE_ANALYTICS, params: { pralion_id: SceneAnalytics.pralionId } })
			.then(async (res) => {
				console.log("res", res);
        if (res.status === 200) {
				await fetch(res.data.data.s3_url)
					.then((data) => data.json())
					.then((json) => {
						console.log("json", json);
						map.current.addSource("points", {
							type: "geojson",
							data: json,
						});
						map.current.addLayer(DEFAULT_POINT_LAYER("route-points", "points", 3, "rgba(0, 163, 181,0.7)"));
						map.current.setCenter(json["features"][0]["geometry"]["coordinates"]);
						map.current.setZoom(zoom);
						setInitialLng(json["features"][0]["geometry"]["coordinates"][0]);
						setInitialLat(json["features"][0]["geometry"]["coordinates"][1]);

						map.current.on("click", "route-points", onClickPoint);
						map.current.on("mouseenter", "route-points", onMouseEnterPoint);
						map.current.on("mouseleave", "route-points", onMouseLeavePoint);
					});
        }
        else if (res.status === 204) {
          toast.info("Ah!! You forgot to create a scenario. Please go back and select another scenario from the list to analyze.")
        }
			})
			.catch((err) => {
				console.log("err", err);
			}).finally(() => {
        setLoading(false);
      });
	};

  const onClickPoint = (e) => {
    console.log("e point", e.features);
    setSelectedAreaPoints(e.features);
    setShowInfoPanel(true)
  }

  const onClickRecenter = () => {
		map.current.setCenter([initiallng, initiallat]);
		map.current.setZoom(initialzoom);
	};

  const onMouseEnterPoint = (e) => {
    const customHTML = `
    <div style="background-color: black; padding: 10px; border: 1px solid #ccc; border-radius: 5px;">
        <h3 style="margin: 0; font-size: 16px;">Points info</h3>
        <p style="margin: 0; font-size: 14px;">Total points: ${e.features.length}</p>
        <div style="margin: 0; font-size: 14px;">IDs: ${e.features.map((item) => item.properties.id)}</div>
    </div>`
    popup.current
      .setLngLat([e.lngLat.lng, e.lngLat.lat])
      .setHTML(customHTML)
      .addTo(map.current);
  }

  const onMouseLeavePoint = (e) => {
    popup.current.remove();
  }

	const handleOnMapMove = (e) => {
		setLng(map.current.getCenter().lng.toFixed(4));
		setLat(map.current.getCenter().lat.toFixed(4));
		setZoom(map.current.getZoom().toFixed(2));
	};

  const onClickVisualizer = (url) => {
    window.open(url, "_blank");
  }

	return (
		<div className={`container-fuild`}>
			{loading && <ProgressX fullscreen={true} />}
			<div className={`map-container mt-3`}>
				<div className={`rbs-title-bar`}>
					<Typos.H6>Scene Analytics</Typos.H6>
				</div>
				<div className="my-analytics-left-panel">
					<div className={`d-flex mb-2`}>
						<ButtonX disabled={selectedAreaPoints.length === 0} className={"me-2"} size={"small"} onClick={onClickRecenter} variant={"outlined"} color={"inherit"}>
							Re-Center
						</ButtonX>
						{/* <IconButtonX icon={"InfoRounded"} onClick={() => setShowInfoPanel(!showInfoPanel)} /> */}
					</div>
					<Draggable>
						<div className="my-analytics-left-info-panel">
							<CollapseContent show={showInfoPanel} orientation={"vertical"}>
								<div className="px-3 py-2">
									<div className="d-flex justify-content-between align-items-center">
										<Typos.H6>Selected Points</Typos.H6>
										<IconButtonX icon={"ClearRounded"} onClick={() => setShowInfoPanel(false)} />
									</div>
									<hr />
									{selectedAreaPoints.map((item, i) => {
										return (
											<Fragment key={i}>
												<div className="row" key={i}>
													<div className="col-1">#{item?.properties?.id}</div>
													<div className="col-7">
														<Typos.Body1>{miniWrapText(item?.properties?.name)}</Typos.Body1>
													</div>
													<div className="col-2"></div>
													<div className={`col-1`}>
														<IconButtonX icon={"OpenInNewRounded"} size={"small"} onClick={() => onClickVisualizer(item.properties.url)} />
													</div>
												</div>
												<Divider />
											</Fragment>
										);
									})}
								</div>
							</CollapseContent>
						</div>
					</Draggable>
				</div>
				<div className="sidebar">
					Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
				</div>
				<div ref={mapContainer} className="map-view" />
			</div>
		</div>
	);
});
