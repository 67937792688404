import { SET_CARLA } from ".."

export const ReduxCreateCarlaJourney = (autoScen) => {
    return {
        type: SET_CARLA,
        payload: { 
            scenario: autoScen,
        }
    }
}
