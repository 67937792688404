import React, { useEffect } from "react";
import EgoParameter from "./modules/egoParameter";
import PerceptionParameter from "./modules/perceptionParameter";
import MapParameter from "./modules/mapParameter";
import {
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonX } from "components";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import STORE from "services/redux-store/store";
import { getEducAgentFeatureDescription, udateEducAgentProjects } from "endpointServices/educAgentServices";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { getTestVehicleProjectsById } from "endpointServices/testVehicleService";
import { ReduxUpdateEducAgentsJourney } from "services/redux-store/actions/actions-educAgents";
import { connect } from "react-redux";
import { httpGet } from "utils/httpMethods";
import { GET_AUTOSCENARIO_VEHICLE_LIST, GET_VEHICLE_LIST } from "constants/endpoints";


function spawnLaneInfo(inputData) {
  let outputData = { spawn_lanes_info: [] };
  inputData.forEach((item) => {
    let laneString = item.polygon.properties.road_id + ":0:" + item.polygon.properties.lane_id;
    let weightValue = item.rate;
    outputData.spawn_lanes_info.push({ lane: laneString, weight: parseInt(weightValue) });
  });
  console.log('outputData', outputData)
  return outputData;
}

function flattenVehicleProfileData(inputData) {
  let result = [];
  for (let key in inputData) {
    if (inputData.hasOwnProperty(key)) {
      result.push(...inputData[key]);
    }
  }
  return result;
}


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const AttributeSelection = ({ReduxUpdateEducAgentsJourney}) => {
  const [value, setValue] = React.useState(0);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = React.useState(false);
  const [storedValues, setStoredValues] = React.useState(null);
  const [storedLanes, setStoredLanes] = React.useState(null);
  const [list, setList] = React.useState([]);
  const [selectedVehicle, setSelectedVehicle] = React.useState("");
  const [isFetching, setFetching] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [egoFeatures, setEgoFeatures] = React.useState([]);
  const [actorsFeatures, setActorsFeatures] = React.useState([]);
  const [mapFeatures, setMapFeatures] = React.useState([]);
  const [allFieldsFilled, setAllFieldsFilled] = React.useState(false);

  const navigate = useNavigate();
  const param = useParams();

  const { EducAgents } = STORE.getState();

  const hyperParameterEgo = EducAgents.hyperParameterEgo?.map(
    (param) => param.json_representation
  );
  const hyperParameterPerception = EducAgents.hyperParameterPerception?.map(
    (param) => param.json_representation
  );
  const mapParameter = EducAgents.mapParameter?.features?.map(
    (param) => param.json_representation
  );
  const mapParameterLocation = EducAgents.mapParameterLocation

  useEffect(() => {
    getAllList();
    
  }, []);

  useEffect(() => {
    getFeatureDescription();
  }, [])

  useEffect(() => {
    if(EducAgents.selectedVehicle) {
      setSelectedVehicle(EducAgents.selectedVehicle)
    }
  }, [EducAgents.selectedVehicle])

  useEffect(() => {
    isNextButtonDisabled();
  }, [
    hyperParameterEgo,
    hyperParameterPerception,
    mapParameter,
    mapParameterLocation,
    selectedVehicle,
  ]);

  const handleStoredValues = (values) => {
    setStoredValues(values);
  };

  const handleStoredLanes = (values) => {
    setStoredLanes(values);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleVehicleChange = (event) => {
    setSelectedVehicle(event.target.value);
    ReduxUpdateEducAgentsJourney({selectedVehicle: event.target.value})
  };

  const handleNext = () => {
    navigate(
      AUTHENTICATED_ROUTES.educDefineRewards
        .replace(":id", param.id)
        .replace(":aid", param.aid)
        .replace(":oddid", param.oddid)
        .replace(":strategy", param.strategy)
    );
  };

  const isNextButtonDisabled = () => {
    console.log("mapParameterLocation", EducAgents.mapParameterLocation);
    if(hyperParameterEgo?.length && hyperParameterPerception?.length && ((mapParameter?.length && mapParameterLocation?.length) || mapParameterLocation?.length === 0) && selectedVehicle!== '')
      {
        setAllFieldsFilled(true);
      } else {
        setAllFieldsFilled(false);
      }
  };

  const renderSegmentsAndVehicles = () => {
    const result = [];

    for (let segment = 0; segment < EducAgents.numberOfSegment; segment++) {
      for (
        let vehicle = 0;
        vehicle < EducAgents.vehicleNumberOfSegment;
        vehicle++
      ) {
        result.push(`${segment}-${vehicle}`);
      }
    }
    return result;
  };

  const onSave = async () => {
    setLoading(true);

    let locations;

    if (EducAgents.vehicleSelectionType.type === "segmented") {
      locations = renderSegmentsAndVehicles();
    } else {
      locations = EducAgents.hyperParameterPerceptionAgent;
    }

    // Spwaning Staretiegies 
    let mappedSpawnStrategy = "";
    let params = {};
    if (EducAgents.EducSpawningStrategies === "everywhere") {
      mappedSpawnStrategy = "everywhere";
      params = { "ego.sensor_range": 200 };
    } else if (EducAgents.EducSpawningStrategies === "educ-perception-based") {
      mappedSpawnStrategy = EducAgents.EducPerceptionBased.onCommingTraffic ? "around_ego_vision" : "around_ego";
      params = { "ego.sensor_range": EducAgents.EducPerceptionBased.perceptionRange };
    } else if (EducAgents.EducSpawningStrategies === "educ-rate-based") {
      mappedSpawnStrategy = "rate_based";
      params = { ...spawnLaneInfo(EducAgents.EducRateBased.savedMarkers), "ego.sensor_range": 200 };
    }

    // Vehicle Profiles
    const vehiclesList = await httpGet({ url: GET_AUTOSCENARIO_VEHICLE_LIST });

    const payload = {
      pralion_id: EducAgents.pralionId,
      test_vehicle_id: selectedVehicle,
      perception: EducAgents.vehicleSelectionType,
      features: {
        ego: hyperParameterEgo,
        vehicles: {
          locations: locations,
          features: hyperParameterPerception,
        },
        map: {
          locations: EducAgents.mapParameterLocation,
          features: EducAgents.mapParameter?.features?.map(
            (param) => param.json_representation
          ) || [],
        },
      },
      traffic: EducAgents.AsTrafficConfig,
      map_gid: EducAgents.selectedMap.gid_kwh,
      traffic: {
        spawn_strategy: {
          traffic_amount_hint: EducAgents.AsTrafficConfig.vehicleCount,
          traffic_density: EducAgents.EducSpawningStrategies === "as-perception-based" ? (EducAgents?.AsPerceptionBased?.trafficDensity || 10) : 10,
          spawn_strategy: mappedSpawnStrategy,
          params,
        },
        vehicle_profiles: flattenVehicleProfileData(vehiclesList.data.data),
        composition: EducAgents.AsTrafficConfig.trafficConfig,
      },
    };
    console.log("payload", payload);
    const response = await udateEducAgentProjects(payload);
    if (response?.status === 200) {
      setLoading(false);
      toast.success(`Successfuly submitted the initial configuration`);
      setIsSubmitSuccessful(true);
      setTimeout(() => {
        handleNext()
      },1000)
    } else {
      const errr = response?.response?.data?.message;
      toast.error(errr);
      setIsSubmitSuccessful(false);
      setLoading(false);
    }
  };

  const getFeatureDescription = async () => {
    setFetching(true);
    const response = await getEducAgentFeatureDescription();
    console.log("getFeatureDescription", response?.data)
    if (response?.status === 200) {
      setFetching(false);
      const resp = response?.data?.data
      console.log('resp', resp)
      const data = JSON.parse(resp?.output?.output);
      setEgoFeatures(data?.ego || [])
      setActorsFeatures(data?.actors || [])
      setMapFeatures(data?.map || [])
      console.log("data", data);
    } else {
      setFetching(false);
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
    }
  };

  const getAllList = async () => {
    const projectId = param?.id;
    const response = await getTestVehicleProjectsById(projectId);
    if (response?.status === 200) {
      const { data } = response?.data;
      const filteredVehicle = data.test_vehicles.filter(application => application?.application_version_id !== null);
      console.log("filteredVehicle", filteredVehicle);
      setList(filteredVehicle);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setList([]);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 3,
        }}
      >
        <TextField
          label="Select Vehicle"
          name="selectCategory"
          type="select"
          size="small"
          variant="outlined"
          value={selectedVehicle}
          onChange={handleVehicleChange}
          sx={{ maxWidth: "23rem" }}
          helperText={"The defined Test Vehicles can only be referred"}
          fullWidth
          select
          disabled={false}
          SelectProps={{
            MenuProps: {
              className: "customDropdown",
            },
          }}
        >
          {list.map((item, i) => {
            return (
              <MenuItem key={i} value={item.pralion_id}>
                {item.name}
              </MenuItem>
            );
          })}
        </TextField>
        <Grid>
          <ButtonX
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "text.main",
              fontWeight: "bold",
              marginRight: "10px",
            }}
            onClick={() => navigate(-1)}
          >
            Back
          </ButtonX>
          {/* {isSubmitSuccessful ? (
            <ButtonX className={`ms-2`} onClick={handleNext}>
              Next
            </ButtonX>
          ) : ( */}
            <ButtonX
              className={`ms-2`}
              onClick={onSave}
              disabled={!allFieldsFilled || isLoading}
            >
              Next {isLoading ?<CircularProgress size={20} style={{ marginLeft: "8px" }} color="inherit" />: '' }
            </ButtonX>
          {/* )} */}
        </Grid>
      </Grid>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTab-root": {
              fontSize: "1.2rem",
            },
            padding: 0,
            backgroundColor: "#203c68eb",
          }}
        >
          <Tab label="Ego Parameters" />
          <Tab label="Perception Parameters" />
          <Tab label="Map Parameters" />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <EgoParameter getMapValues={(values) => handleStoredValues(values)} egoDescription={egoFeatures} isLoading={isFetching} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <PerceptionParameter
          getMapValues={(values) => handleStoredValues(values)}
          actorsDescription={actorsFeatures}
          isLoading={isFetching}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <MapParameter getMapValues={(values) => handleStoredValues(values)} getLaneValues={(values) => handleStoredLanes(values)} mapDescription={mapFeatures} isLoading={isFetching} />
      </CustomTabPanel>
    </Box>
  );
}
export default connect(null, { ReduxUpdateEducAgentsJourney })(AttributeSelection);