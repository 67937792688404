import React, { Fragment, useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "mapbox-gl/dist/mapbox-gl.css";
import "./eduTextVehicle.css";
import { ButtonX, IconButtonX, SquareImage } from "components";
import { AUTHENTICATED_ROUTES } from "router/constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, ListItemText, MenuItem, TextField } from "@mui/material";
import { httpPost } from "utils/httpMethods";
import { ODD_TO_GEOJSON } from "constants/endpoints";
import { mapTypes, GetInfoFromLocalStorage } from "utils";
import { getAllTestVehicleProjects } from "endpointServices/testVehicleService";
import { getAutoScenarioTrafficProfileList } from "endpointServices/autoScenarioServices";
import { getVehicleProfile } from "pages/educAgents/modules/stateMaintain";
import VehicleProfile from "./vehicleProfiles";
import DynamicActorForm from "./dynamicActorForm";
import StaticActorForm from "./staticActorForm";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  createMapProjects,
  updateMapProjects,
} from "endpointServices/mapsServices";
import { ReduxUpdateEducAgentsJourney } from "services/redux-store/actions/actions-educAgents";
import { connect } from "react-redux";
import STORE from "services/redux-store/store";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "xyz";

const laneColors = {
  selection: "#00FF00",
  option: "#EFCB00",
};

export const LAYER2 = {
  id: "state-fills",
  type: "fill",
  source: "states",
  layout: {},
  filter: ["==", "$type", "Polygon"],
  paint: {
    // "fill-color": "#0080ff", // blue color fill
    // "fill-opacity": 0.2,
    // "fill-color": "rgba(0, 163, 181, 0.2)",
    "fill-outline-color": "rgba(0, 188, 212, 1)",
    "fill-color": [
      "case",
      ["boolean", ["feature-state", "click"], false],
      "#64bdbb", // if selected true, paint in blue
      "#ff0000", // else paint in red
    ],
  },
};

const TrafficConfiguration = ({ ReduxUpdateEducAgentsJourney }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const { EducAgents } = STORE.getState();

  const navigate = useNavigate();
  const location = useLocation();
  const param = useParams();

  const [lng, setLng] = useState(13.405252);
  const [lat, setLat] = useState(52.517644);
  const [zoom, setZoom] = useState(8);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(null);
  const [carObject, setCarObject] = useState({});
  const carObjectPlaced = useRef(false);

  const [vehicleList, setVehicleList] = useState([]);
  const [selectCategory, setSelectCategory] = useState("");

  const [trafficPresetList, setTrafficPresetList] = useState([]);
  const [selectTraficPreset, setSelectTraficPreset] = useState("");
  const [geojsonFilesURLs, setGeojsonFilesURLs] = useState({
    lane_polygons: "./lane_polygons.geojson",
    sign_polygons: "./sign_polygons.geojson",
    pole_polygons: "./pole_polygons.geojson",
    barrier_polygons: "./barrier_polygons.geojson",
    road_polygons: "./road_polygons.geojson",
  });

  const [clickNextStage, setClickNextStage] = useState(0);

  const [isSave, setIsSave] = useState(true);

  const mapGenerationFunc = async () => {
    const data = location.state;
    const proj_id = GetInfoFromLocalStorage("p_id");
    console.log("proj_id", proj_id);
    const createMapPayload = {
      project_id: parseInt(proj_id),
      name: data?.name,
      description: "",
    };
    console.log("createMapPayload", createMapPayload, data);
    const response = await createMapProjects(createMapPayload);
    if (response?.status === 200) {
      const resp = response?.data?.data;
      const updateMapPayload = {
        pralion_id: resp.pralion_id,
        name: data.name,
        description: "",
        open_drive_local: data.localOpenDrive,
        open_drive_s3: data.openDrive,
      };
      const updateResponse = await updateMapProjects(updateMapPayload);
      if (updateResponse?.status === 200) {
        const updateResp = response?.data?.data;
        localStorage.setItem("map_id", updateResp.pralion_id);
      } else {
        const err = response?.response?.data?.message;
        console.log("err", err);
      }
    } else {
      const err = response?.response?.data?.message;
      console.log("err", err);
    }
  };

  useEffect(() => {
    mapGenerationFunc();
  }, [location]);

  React.useEffect(() => {
    getAllList();
    getTrafficProfileList();
  }, []);

  useEffect(() => {
    getStoreValues();
  }, [param]);

  const getStoreValues = () => {
    if (
      EducAgents.trafficPreset !== undefined &&
      EducAgents.trafficPreset !== null
    ) {
      setSelectTraficPreset(EducAgents.trafficPreset);
    }
    if (
      EducAgents.testVehicle !== undefined &&
      EducAgents.testVehicle !== null
    ) {
      setSelectCategory(EducAgents.testVehicle);
    }
    setIsSave(false);
  };

  const handleSelectCategory = (value) => {
    setIsSave(false);
    ReduxUpdateEducAgentsJourney({
      testVehicle: value,
    });
  };

  const handleSelectTrafficPreset = (value) => {
    setSelectTraficPreset(value);
    ReduxUpdateEducAgentsJourney({
      trafficPreset: value,
    });
  };

  const getAllList = async () => {
    const projectId = GetInfoFromLocalStorage("p_id");
    const response = await getAllTestVehicleProjects(parseInt(projectId));
    if (response?.status === 200) {
      const data = response?.data?.data?.test_vehicles || [];
      // const listData = data.slice(0, 10);
      const list = data.map((row) => {
        const returnValue = {
          label: row["name"],
          value: row["id"],
        };
        return returnValue;
      });
      setVehicleList(list);
    } else {
      const err = response?.message || "Failed to sumbit request";
      toast.error(`${err}. Please try again later`);
      setVehicleList([]);
    }
  };

  const getTrafficProfileList = async () => {
    const response = await getAutoScenarioTrafficProfileList();
    if (response?.status === 200) {
      const { data } = response?.data;
      const setKeysName = Object.keys(data);
      setTrafficPresetList(setKeysName);
    } else {
      const err = response?.message || "Failed to sumbit request";
      toast.error(`${err}. Please try again later`);
      setTrafficPresetList([]);
    }
  };

  const trafficPreset = (
    <Box p={1} pt={2}>
      <TextField
        label="Select Traffic preset"
        name="selectTraficPreset"
        required
        size="small"
        type="select"
        variant="outlined"
        value={selectTraficPreset}
        onChange={(e) => {
          const { value } = e.target;
          setSelectCategory(value);
          handleSelectTrafficPreset(value);
        }}
        fullWidth
        disabled={false}
        select
        SelectProps={{
          MenuProps: {
            className: "customDropdown",
          },
        }}
      >
        {trafficPresetList.map((item, id) => {
          return (
            <MenuItem key={id} value={item}>
              <ListItemText primary={item} />
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );

  const testVehicle = (
    <Box p={1} pt={2}>
      <TextField
        label="Select Test Vehicle"
        name="category"
        required
        type="select"
        size="small"
        variant="outlined"
        value={selectCategory}
        onChange={(e) => {
          const { value } = e.target;
          setSelectCategory(value);
          handleSelectCategory(value);
        }}
        fullWidth
        disabled={false}
        select
        SelectProps={{
          MenuProps: {
            className: "customDropdown",
          },
        }}
      >
        {vehicleList.map((item, id) => {
          return (
            <MenuItem key={id} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    console.log("location", location);
    fetchMapLayers();
    // fetchVehicles();
  }, []);

  const fetchMapLayers = async () => {
    setLoading(true);
    let lanePolygon = null;
    if (!location.state) {
      return;
    } else {
      if (location.state.isGeojsonAvailable) {
        // geojsons are all available
        const data = {
          lane_polygons: location.state.availableGeojsons.lane_polygons,
          sign_polygons: location.state.availableGeojsons.sign_polygons,
          pole_polygons: location.state.availableGeojsons.pole_polygons,
          barrier_polygons: location.state.availableGeojsons.barrier_polygons,
          road_polygons: location.state.availableGeojsons.road_polygons,
        };
        // setUrl(location.state.availableGeojsons.lane_polygons);
        setGeojsonFilesURLs(data);
        setLoading(false);
      } else {
        try {
          await httpPost({
            url: ODD_TO_GEOJSON,
            payload: {
              gid: location.state.gId,
              odd_polygon: location.state.geojson,
              map_id: location.state.mapId,
            },
          })
            .then((resp) => {
              // console.log("resp", resp);
              const data = {
                lane_polygons: resp.data.data.lane_polygons,
                sign_polygons: resp.data.data.sign_polygons,
                pole_polygons: resp.data.data.pole_polygons,
                barrier_polygons: resp.data.data.barrier_polygons,
                road_polygons: resp.data.data.road_polygons,
              };
              lanePolygon = resp.data.data.lane_polygons;
              setUrl(resp.data.data.lane_polygons);
              setGeojsonFilesURLs(data);
              // "https://temp-rayan.s3.eu-central-1.amazonaws.com/lane_polygons_bc29ccac-fdc2-40a6-862b-9011418a8608.geojson"
              setLoading(false);
            })
            .catch((err) => {
              toast.error(`${err}. Please try again later`);
              setLoading(false);
            });
        } catch (err) {
          console.log("err", err);
          setLoading(false);
        }
      }
    }
  };

  const handleOnMapMove = (e) => {
    setLng(map.current.getCenter().lng.toFixed(4));
    setLat(map.current.getCenter().lat.toFixed(4));
    setZoom(map.current.getZoom().toFixed(2));
  };

  const onClickChangeMapType = (type) => {
    map.current.setStyle(type);
  };

  const saveLocalInfo = () => {
    const pralionId = localStorage.getItem("pralion_id");
    if (pralionId === null || pralionId === undefined) {
      localStorage.setItem("pralion_id", param.id);
    }
  };

  const onClickNext = () => {
    switch (clickNextStage) {
      case 0:
        const payload = {
          test_vehicle_id: selectCategory.id,
          selected_traffic_preset: selectTraficPreset,
        };
        getVehicleProfile(payload);
        setClickNextStage(1);
        saveLocalInfo();
        break;
      // case 1:
      // 	setClickNextStage(2);
      // 	break;
      case 1:
        navigate(
          `/projects/${param.id}/applications/educ-agents/modal-input-agents`
        );
        break;
      default:
        break;
    }
    // navigate();
  };

  const onClickBack = () => {
    switch (clickNextStage) {
      case 0:
        navigate(-2);
        break;
      case 1:
        setClickNextStage(0);
        break;
      case 2:
        setClickNextStage(1);
        break;
      default:
        break;
    }
    // navigate();
  };

  return (
    <>
      <div className={`container-fluid`}>
        <div className={`row`}>
          <div className={`col-12 d-flex justify-content-between`}>
            <div className={`d-flex`}></div>
            <div className={`d-flex`}>
              <ButtonX className={`me-2`} onClick={onClickBack}>
                Back
              </ButtonX>
              <ButtonX onClick={onClickNext} disabled={isSave}>
                Next
              </ButtonX>
            </div>
          </div>
        </div>
        <div className={`map-container mt-3`}>
          <div className={`map-elements`}>
            {clickNextStage === 0 && (
              <VehicleProfile
                selectedCar={selectCategory}
                fields={{ trafficPreset, testVehicle }}
              />
            )}
            {clickNextStage === 1 && <DynamicActorForm setIsSave={setIsSave} />}
            {clickNextStage === 2 && <StaticActorForm />}
          </div>
        </div>
      </div>
    </>
  );
};
export default connect(null, { ReduxUpdateEducAgentsJourney })(
  TrafficConfiguration
);
