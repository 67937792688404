import { Button, Grid, CircularProgress } from "@mui/material";
import MainTable from "components/mainTable";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import STORE from "services/redux-store/store";
import { useParams, useNavigate } from "react-router-dom";
import { Fragment } from "react";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { getCarla } from "endpointServices/testSuitServices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export default function CarlaExecute() {
  const navigate = useNavigate();
  const param = useParams();
  const [isLoading, setIsLoading] = useState(false)
  const [isDownload, setisDownload] = useState(false)
  const [scenarios, setScenarios] = useState([]);
  const { Carla } = STORE.getState();
  const { scenario } = Carla;
  useEffect(() => {
    getScenariosList();
  }, []);
  const getScenariosList = () => {
    setIsLoading(true)
    setScenarios([scenario]);
    setIsLoading(false)
  };
  const columns = [
    {
      field: "name",
      renderHeader: () => <span className="">{"Scenarios Name "}</span>,
      width: 400,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          <Grid item>
            <Button
              className="Main-link-simple row-heading"
              sx={{ color: "white" }}
            >
              {params.value}
            </Button>
          </Grid>
        </Grid>
      ),
    },
    { field: "map_name", headerName: "Map Name", width: 249 },
    {
      field: "output.ScenarioPass",
      headerName: "Scenario Pass",
      width: 149,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {params.row.output?.ScenarioPass === true ? (
            <p style={{ marginTop: "16px" }}><DoneIcon sx={{ color: "green" }} /></p>
          ) : params.row.output?.ScenarioPass === false ? (
            <p style={{ marginTop: "16px" }}><CloseIcon sx={{ color: "red" }} /></p>
          ) : (
            ""
          )}
        </Grid>
      ),
    },
    {
      field: "output.MinTTC",
      headerName: "Min TTC",
      width: 149,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {params.row.output?.MinTTC?.toFixed(3)}
        </Grid>
      ),
    },
    {
      field: "output.MinTHW",
      headerName: "Min THW",
      width: 149,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {params.row.output?.MinTHW?.toFixed(3)}
        </Grid>
      ),
    },
    {
      field: "output.LJavg",
      headerName: "L Javg",
      width: 149,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {params.row.output?.LJavg?.toFixed(3)}
        </Grid>
      ),
    },
    {
      field: "output.TTC_Pass",
      headerName: "TTC Pass",
      width: 149,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {params.row.output?.TTC_Pass === true ? (
            <p style={{ marginTop: "16px" }}>True</p>
          ) : params.row.output?.TTC_Pass === false ? (
            <p style={{ marginTop: "16px" }}>False</p>
          ) : (
            ""
          )}
        </Grid>
      ),
    },
    {
      field: "output.LJ_Pass",
      headerName: "LJ Pass",
      width: 149,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {params.row.output?.LJ_Pass === true ? (
            <p style={{ marginTop: "16px" }}>True</p>
          ) : params.row.output?.LJ_Pass === false ? (
            <p style={{ marginTop: "16px" }}>False</p>
          ) : (
            ""
          )}
        </Grid>
      ),
    },
    {
      field: "output.THW_Pass",
      headerName: "THW Pass",
      width: 149,
      renderCell: (params) => (
        <Grid container spacing={1} alignItems="center">
          {params.row.output?.THW_Pass === true ? (
            <p style={{ marginTop: "16px" }}>True</p>
          ) : params.row.output?.THW_Pass === false ? (
            <p style={{ marginTop: "16px" }}>False</p>
          ) : (
            ""
          )}
        </Grid>
      ),
    },
  ];
  const onExecute = async () => {
    const scenarioId = scenario.id;
    const response = await getCarla(scenarioId)
    console.log("response", response)
    setisDownload(true)
    if (response?.status === 200) {
      const data = response.data.data
      // Fetch the file as a Blob
      const resp = await fetch(data);
      const blob = await resp.blob();
      // Create an object URL
      const objectURL = URL.createObjectURL(blob);
      // Create an invisible anchor element
      const downloadLink = document.createElement('a');
      // Set the href attribute with the object URL
      downloadLink.href = objectURL;
      // Set the download attribute with the desired file name
      downloadLink.download = `${scenario.id}.zip`;
      // Append the anchor element to the document
      document.body.appendChild(downloadLink);
      // Trigger a click event to initiate the download
      downloadLink.click();
      // Remove the anchor element from the document
      document.body.removeChild(downloadLink);
      // Revoke the object URL
      URL.revokeObjectURL(objectURL);
      toast.success(`Successfuly downloaded zip file`);
      setisDownload(false)
    }else {
      const errr = response?.data?.message
      toast.error(errr);
      setisDownload(false)
    }
  };
  return (
    <Fragment>
      <Grid container justifyContent={"end"}>
        <Button
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            color: "text.main",
            fontWeight: "bold",
            marginRight: "10px",
          }}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Button
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            color: "white",
            fontWeight: "bold",
            width: "130px",
          }}
          disabled={isDownload ? true : false}
          onClick={onExecute}
        >
          Download
          {isDownload ? (
            <CircularProgress
              size={20}
              style={{ marginLeft: "8px" }}
              color="inherit"
            />
          ) : (
            ""
          )}
        </Button>
      </Grid>
      <MainTable
        table={scenarios}
        headers={columns}
      />
    </Fragment>
  );
}