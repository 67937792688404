import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Box, Grid, Radio, Button } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  getAutoScenarioExecListByPralionId,
  getAutoScenarioProjectsById,
} from "endpointServices/autoScenarioServices";
import AutoScenarioStatus from "../../autoScenarios/autoScenariosComponent/autoScenarioStatus";
import dateConverter from "utils/dateConverter";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { wrapText, checkDagStatus } from "utils";
import { ModalX } from "components";
import { pageSize } from "utils/staticsData";
import MainTable from "components/mainTable";
import ScenarioVersionList from "./versionList";
import { ReduxUpdateTestSuitsJourney } from "services/redux-store/actions/actions-testSuits";
import { connect } from "react-redux";

const TestSuitsAutoScenarioList = ({
  ReduxUpdateTestSuitsJourney,
  getTestValues,
}) => {
  const param = useParams();
  let navigator = useNavigate();

  const [list, setList] = useState([]);
  const [isFetching, setFetching] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);
  const [statusOpen, setStatusOpen] = useState(false);
  const [statusData, setStatusData] = useState({
    name: "",
    pralionId: "",
  });

  // To Open version modal
  const [versionIds, setVersionIds] = useState({
    key: "",
    list: [],
  });
  const [versionModalOpen, setVersionModalOpen] = useState(false);

  const handleStatusOpenModal = (data) => {
    setStatusData({
      name: data.name,
      pralionId: data.pralion_id,
    });
    setStatusOpen(true);
  };

  const handleClose = () => setStatusOpen(false);

  useEffect(() => {
    getProjectList({ pageNumber: page, testsPerPage: pageSize });
    localStorage.removeItem("p_id");
    localStorage.removeItem("pralion_id");
  }, [page]);

  const getProjectList = async (pagination) => {
    setFetching(true);
    const projectId = parseInt(param.id);
    const response = await getAutoScenarioProjectsById(projectId, pagination);
    console.log("response", response);
    if (response?.status === 200) {
      setFetching(false);
      const data = response?.data?.data?.applications || [];
      // const listFilter = data.filter(ele => ele.no_of_scenarios !== 0)
      setList(data);
      setTotalRows(response?.data?.data?.no_of_applications);
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      setList([]);
      setFetching(false);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleVersionModal = (data, name) => {
    console.log("test suits autoScenario version id", data);
    ReduxUpdateTestSuitsJourney({
      autoScenarioPralionId: data.pralion_id,
      autoScenarioProjectName: data.name,
    });
    setVersionIds({
      key: name,
      list: data.versions,
    });
    setVersionModalOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        field: "name",
        renderHeader: () => <span className="heading-2">{"Scenario ID "}</span>,
        width: 550,
        renderCell: (params) => {
          const isRowDisabled = params.row?.dag_state?.dag_state;
          return (
            <Grid>
              <Button
                className="Main-link-simple"
                onClick={() => handleVersionModal(params.row, "autoScenario")}
                sx={{ color: "white" }}
                disabled={params.row.dag_state.dag_state !== "success"}
                // disabled={checkDagStatus(isRowDisabled)}
              >
                {wrapText(params.value)}
              </Button>
            </Grid>
          );
        },
      },
      {
        field: "date_created",
        renderHeader: () => <span className="heading-2">{"Created At"}</span>,
        width: 183,
        renderCell: (params) => (
          <span className="row-heading">{dateConverter(params.value)}</span>
        ),
      },
      {
        field: "action",
        renderHeader: () => <span className="heading-2">{"Status"}</span>,
        width: 235,
        renderCell: (params) => {
          const status = params.row?.dag_state?.dag_state;
          return (
            <>
              <div className="statusRadioButton">
                <Radio
                  checked={
                    status === "pending" || !params.row.dag_state.dag_state
                  }
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "gray",
                      color: "red",
                      "&:checked + span": {
                        backgroundColor: "gray",
                        color: "gray",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
                <Radio
                  checked={status === "queued"}
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "#ffff00",
                      color: "#ffff00",
                      "&:checked + span": {
                        backgroundColor: "#ffff00",
                        color: "#ffff00",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
                <Radio
                  checked={status === "running"}
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "#ff9800",
                      color: "#ff9800",
                      "&:checked + span": {
                        backgroundColor: "#ff9800",
                        color: "#ff9800",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
                <Radio
                  color="success"
                  checked={status === "success"}
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "#4caf50",
                      color: "#4caf50",
                      "&:checked + span": {
                        backgroundColor: "#4caf50",
                        color: "#4caf50",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
                <Radio
                  checked={status === "failed"}
                  sx={{
                    transform: "scale(0.6)",
                    width: "1px",
                    height: "1px",
                    "& > input": {
                      backgroundColor: "red",
                      color: "red",
                      "&:checked + span": {
                        backgroundColor: "red",
                        color: "red",
                        borderRadius: "16px",
                        width: "22px",
                        height: "22px",
                      },
                    },
                  }}
                />
              </div>
              <Button
                sx={{ color: "text.main" }}
                onClick={() => handleStatusOpenModal(params.row)}
              >
                <MoreVertIcon />
              </Button>
            </>
          );
        },
      },
    ],
    [list, page]
  );

  return (
    <Fragment>
      <MainTable
        table={list}
        headers={columns}
        fetching={isFetching}
        totalRows={totalRows}
        pageSize={pageSize}
        page={page}
        pageHandler={handlePageChange}
      />

      <ModalX
        open={versionModalOpen}
        close={() => setVersionModalOpen(false)}
        heading={"Generated Scenarios"}
        subheading={"AutoScenario"}
      >
        <ScenarioVersionList
          versionList={versionIds}
          handleClose={handleClose}
          getAutoScenarioList={getAutoScenarioExecListByPralionId}
          getTestValues={getTestValues}
        />
      </ModalX>

      <ModalX
        open={statusOpen}
        close={() => setStatusOpen(false)}
        heading={"State Management"}
        subheading={`${statusData.name}`}
      >
        <AutoScenarioStatus
          statusObject={statusData}
          handleClose={handleClose}
        />
      </ModalX>
    </Fragment>
  );
};
export default connect(null, { ReduxUpdateTestSuitsJourney })(
  TestSuitsAutoScenarioList
);
