import { SET_LOW_FIDELITY, UPDATE_LOW_FIDELITY } from ".."

export const ReduxCreateLowFidelityJourney = (pid,pname,vehicleprop, vehiclesuits, obserId) => {
    return {
        type: SET_LOW_FIDELITY,
        payload: { 
            pralionId: pid, 
            projectName: pname, 
            vehicleProperties: vehicleprop, 
            vehicleSuits: vehiclesuits,
            lowFidelityObserverId: obserId,
        }
    }
}

export const ReduxUpdateLowFidelityJourney = (obj) => {
    return {
        type: UPDATE_LOW_FIDELITY,
        payload: obj
    }
}