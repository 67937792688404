import React, { useState } from "react";
import {
  Button,
  CardHeader,
  CardContent,
  Grid,
  MenuItem,
  TextField,
  Box,
  ListItemText,
} from "@mui/material";
import { ProjectsACCCategory, specialValue } from "utils";
import { createEducAgentProjects } from "endpointServices/educAgentServices";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function EducAgentsForm({ handleClose, syncList }) {
  const param = useParams()
  const [state, setState] = useState({
    name: "",
    // objective: "",
    description: ""
  });
  const [loading, setLoading] = useState(false);
  const [error, set_error] = useState("");

  const [selectAccCategory, setSelectAccCategory] = useState([])

  const handleInputs = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      if (!specialValue.test(value)) {
        setLoading(false);
      } else {
        setLoading(true);
      }
    }
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // const handleAccSelectMultiple = (event) => {
  //   const { value } = event.target;
  //   setSelectAccCategory(value);
  // };

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const payload = {
      "project_id": parseInt(param.id),
      "name": state.name,
      "description": state.description,
      // "function": selectAccCategory[0],
      // "objective": state.objective,
      "is_educ_agent": true,
    };
    const response = await createEducAgentProjects(payload)
    if (response?.status === 200) {
      const { data } = response
      toast.success(`EducAgents Project Created`);
      console.log('data', data)
      handleClose()
      setLoading(false);
      syncList()
    }
    else {
      const errorResponse = response;
      const err = response?.message || "Failed to submit request";
      if (errorResponse?.response.request.status === 400) {
        toast.error(errorResponse.response.data.message);
      } else {
        toast.error(`${err}. Please try again later`);
      }
      setLoading(false);
    }
  };


  return (
    <Box>
      <CardContent>
        <form onSubmit={onSubmitLogin}>
          <Grid item xs={6}>
            <TextField
              name="name"
              required
              type="text"
              variant="outlined"
              label="EducAgent ID"
              placeholder="Enter educAgent id"
              inputProps={{ maxLength: 40 }}
              value={state.name}
              onChange={handleInputs}
              fullWidth
              // disabled={loading}
              helperText="Enter alphanumeric characters and hyphens only"
            />
          </Grid>
          <br />
          {/* <Grid item xs={6}>
            <TextField
              label="Category"
              name="category"
              required
              variant="outlined"
              value={selectAccCategory}
              onChange={handleAccSelectMultiple}
              fullWidth
              disabled={loading}
              select
              SelectProps={{
                MenuProps: {
                  className: 'customDropdown',
                },
              }}
            >
              {ProjectsACCCategory.map((item, id) => (
                <MenuItem key={id} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="objective"
              required
              type="text"
              variant="outlined"
              label="Goal"
              placeholder="Write goal"
              value={state.objective}
              onChange={handleInputs}
              fullWidth
              disabled={loading}
              multiline
              rows={3}
              helperText="Based on ISO, KBA, UN, NHTSA requirements"
            />
          </Grid>
          <br />
          <Grid item xs={6}>
            <TextField
              name="Claim"
              type="text"
              variant="outlined"
              label="Claim"
              placeholder="Enter Claim"
              // value={state.name}
              // onChange={handleInputs}
              fullWidth
              disabled={loading}
              helperText="Based on ISO, KBA, UN, NHTSA requirements"
            />
          </Grid>
          <br /> */}
          <Grid item xs={6}>
            <TextField
              name="description"
              type="text"
              variant="outlined"
              label="EducAgent Description"
              placeholder="Write educAgent description"
              value={state.description}
              onChange={handleInputs}
              fullWidth
              // disabled={loading}
              multiline
              rows={3}
              // helperText="Based on ISO, KBA, UN, NHTSA requirements"
            />
          </Grid>
          <br />
          <div className={`d-flex justify-content-end w-100`}>
            <Button
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                color: "text.main",
                textTransform: "none",
                fontSize: "13px",
              }}
              disabled={loading}
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </CardContent>
    </Box>
  )
}
