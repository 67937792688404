import { CARLA, CARLA_EXECUTION, GET_PROJECT_STATUS, TEST_SUITE_EXPORT, TEST_SUITS,TEST_SUITS_SCENARIOS } from "constants/endpoints"
import { httpGet, httpPost, httpPut } from "utils/httpMethods"

export const createTestSuits = async (body) => {
    return await httpPost({ url: TEST_SUITS, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getTestSuits = async (body) => {
    return await httpGet({ url: `${TEST_SUITS}?project_id=${body}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const putTestSuits = async (body) => {
    return await httpPut({ url: `${TEST_SUITS}?project_id=${body}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const getCarla = async (body) => {
    return await httpGet({ url: `${CARLA_EXECUTION}?scenario_id=${body}`, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}


export const getScenariosByIds = async (body,pagination) => {
    const {pageNumber = 1, testsPerPage = 10 } = pagination || {}
   return await httpGet({ url: `${TEST_SUITS_SCENARIOS}?pralion_id=${body}&page=${pageNumber}&page_size=${testsPerPage}&order=desc`, payload: body })
       .then((res) => {
           return res
           
       })
       .catch((err) => {
           return err?.response
       })
}

export const startTestSuiteExport = async (body) => {
    return await httpPost({ url: TEST_SUITE_EXPORT, payload: body })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}

export const GetTestSuiteExport = async (body) => {
    return await httpGet({ url: `${TEST_SUITE_EXPORT}?pralion_id=${body}` })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.response
        })
}


export const getStatusListByPralionId = async (pralionId) => {
    return await httpGet({ url: `${GET_PROJECT_STATUS}?pralion_id=${pralionId}` })
        .then((res) => {
            return res
        })
        .catch((err) => {
            return err?.responses
        })
}