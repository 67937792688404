import React,{useEffect, useState } from "react";

import { LEFTSIDEBAR_ELEMENTS, LEFTSIDE_APPLICATIONS, DOWNLOAD_MENULIST, SCENARIO_EXECUTION } from "./container/leftSideBarList";
import TraceIcon from "assets/icons/trace.svg";
import { GetInfoFromLocalStorage } from "utils";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function BreadCrumb() {
  const currentUrl = window.location.pathname;
  const taskName = currentUrl.replace(/\\/g, '')
  const navList = LEFTSIDEBAR_ELEMENTS;
  const [element, setElement] = useState({});
  const [titleValue , setTitleValue]  = useState('')

  useEffect(() => {
    const filterElem = navList.filter((ele) => currentUrl === ele.url)[0];
    setElement(filterElem);
    if(currentUrl === '/') {
      localStorage.removeItem('title')
    }
  }, [currentUrl]);

  // ------
  useEffect(() => {
    console.log('current url***', currentUrl)
    if (currentUrl.includes('/downloads/download')){
      const filterElem = DOWNLOAD_MENULIST.filter((ele) => currentUrl === ele.url)[0];
      console.log('filter elem***', filterElem)
      if (filterElem) {
        setTitleValue(filterElem.title);
      } else {
        setTitleValue('');
      }
    } else if (currentUrl === '/downloads') {
      setTitleValue('');
    }
  }, [currentUrl]);
 
  const mainHeading = () => {
    let path = taskName.replace(/\//g, " ")
    const projectName = GetInfoFromLocalStorage('title') || ''
    console.log('path', projectName)

    return (
      <h5 className="breadcrum-heading-main heading-2">
      Project: <span className="project-name">{projectName}</span>
    </h5>
    )
  }

  const subHeading = () => {
    const urlValue = taskName;
    console.log(urlValue, "url value");

    let filterElem = LEFTSIDE_APPLICATIONS.filter((ele) => urlValue.includes(ele.url))[0] || SCENARIO_EXECUTION.filter((ele) => urlValue.includes(ele.url))[0] || '';

    if (!filterElem) {
      const urlParts = urlValue.split('/');
      let extractedTitle = urlParts[4] ? urlParts[4] : '';
      extractedTitle = extractedTitle.toLowerCase().split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');
      filterElem = { title: extractedTitle };
    }

    let setUrl = filterElem.title;

    if (setUrl === "TestSuits" && urlValue.includes("test-suits")) {
      setUrl = "TestSuites";
    }

    return (
      <h6 className="breadcrum-heading heading-2">
        Application: <span className="project-name">{setUrl ? setUrl : titleValue}</span>
      </h6>
    );
  }
  return (
    <React.Fragment>
      <div className="hard-coded-breadcrum">
        {/* <img src={TraceIcon} style={{ fontSize: "large" }} /> */}
        {/* <ExitToAppIcon sx={{fontSize:"1.8rem", marginTop:'2px'}} /> */}
        {mainHeading()} <span style={{ fontSize: '1.5rem', marginLeft: "10px", marginRight: "10px" }}> 
        <ArrowForwardIosIcon sx={{fontSize:"18px"}} />
        </span> {subHeading()}
      </div>
      <div className="hard-coded-breadcrum mb-2">
        {/* <img src={TraceIcon} style={{ fontSize: "large" }} /> */}
        {/* <ExitToAppIcon sx={{fontSize:"1.8rem", marginTop:'2px'}} /> */}
        {/* {subHeading()} */}
      </div>
    </React.Fragment>
  );
}
