import React, { Fragment } from 'react'
import SynthDataTable from './synthDataComponent/synthDataTable';

export default function SynthData() {

    return (
        <Fragment>
            <SynthDataTable />
        </Fragment>
    )
}
