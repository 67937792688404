import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, Button, Divider, Skeleton, Slider, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { IconButtonX, ProgressX, Typos } from "components";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { ReduxUpdateEducAgentsJourney } from "services/redux-store/actions";

function mapStateToProps(state) {
	return {
		ReduxEducAgents: state.EducAgents,
	}
}

function VehicleComposition({ loading, selectVehicleCallback, ReduxEducAgents, ReduxUpdateEducAgentsJourney, setVehicleCountModalCallback, canExecute }) {
	
	
	const handleSliderChange = (e) => {
		const tempAsTrafficConfigs = _.cloneDeep(ReduxEducAgents.AsTrafficConfig.trafficConfig);
		tempAsTrafficConfigs[e.target.name].value = e.target.value;
		ReduxUpdateEducAgentsJourney({ AsTrafficConfig: { ...ReduxEducAgents.AsTrafficConfig, trafficConfig: tempAsTrafficConfigs } });
	}

	const handleSliderValueChangeByButton = (key, incOrDec = true) => { // true for increment
		const tempAsTrafficConfigs = _.cloneDeep(ReduxEducAgents.AsTrafficConfig.trafficConfig);
		// console.log('tempAsTrafficVonfigs', tempAsTrafficConfigs, key)
		tempAsTrafficConfigs[key].value = incOrDec ? tempAsTrafficConfigs[key].value + 1 : tempAsTrafficConfigs[key].value - 1;
		if (tempAsTrafficConfigs[key].value >= 0 && tempAsTrafficConfigs[key].value <= ReduxEducAgents.AsTrafficConfig.vehicleCount) {
			ReduxUpdateEducAgentsJourney({ AsTrafficConfig: { ...ReduxEducAgents.AsTrafficConfig, trafficConfig: tempAsTrafficConfigs } });
		}
	}

	const calculateCurrentCompositionSum = () => {
		if (ReduxEducAgents?.AsTrafficConfig?.trafficConfig) {
			// Extract the values of the first-level properties.
			const vehiclesCounts = Object.values(ReduxEducAgents.AsTrafficConfig.trafficConfig).map((item) => item.value || 0);

			// Calculate the sum using reduce.
			const sum = vehiclesCounts.reduce((acc, currentValue) => acc + currentValue, 0);

			// Check if the sum is exactly 100.
			return { is100: sum === ReduxEducAgents.AsTrafficConfig?.vehicleCount, sum };
		} else {
			return { is100: false, sum: 0 };
		}
	}

	const valuesAudit = calculateCurrentCompositionSum();

	canExecute(valuesAudit.is100)

	return (
		<div className={``}>
			<Typos.H6>Vehicle Composition</Typos.H6>
			<Typos.Caption className={`d-flex align-items-center`}>
				Complete sum of{" "}
				<Button onClick={() => setVehicleCountModalCallback()} disabled={ReduxEducAgents.readonly}>
				<Typos.Caption color={valuesAudit.is100 ? "lightgreen" : "red"} className={`mx-1`}>
					({valuesAudit.sum}/{ReduxEducAgents.AsTrafficConfig?.vehicleCount})
				</Typos.Caption>{" "}
				</Button>
				to proceed
			</Typos.Caption>
			<ProgressX linear={true} progress={100*(valuesAudit.sum/ReduxEducAgents.AsTrafficConfig?.vehicleCount)} color={valuesAudit.is100 ? "primary" : "error"} />
			<Divider />
			<div className={``}>{loading && [...Array(10).keys()].map((item, i) => <Skeleton animation="wave" key={i} width={"100%"} height={36} />)}</div>
			<div className={`mt-3`}>
				<Typos.Caption color="grey">Values ranges from 0 to {ReduxEducAgents.AsTrafficConfig?.vehicleCount}</Typos.Caption>
				<Box sx={{ maxHeight: '49vh', overflowX: 'hidden', overflowY: 'auto' }}>
					{ReduxEducAgents?.AsTrafficConfig?.trafficConfig &&
						!loading &&
						Object.keys(ReduxEducAgents.AsTrafficConfig.trafficConfig).map((vehicle, i) => (
							<div className={``} key={i}>
								<div className={`d-flex justify-content-between align-items-center`}>
									<Typos.Body1 className={`d-flex align-items-end`}>
										{vehicle} <Typos.Caption className={`ms-1`}>({ReduxEducAgents.AsTrafficConfig.trafficConfig[vehicle].value})</Typos.Caption>
									</Typos.Body1>
									<div className={`d-flex`}>
										<ToggleButtonGroup disabled={ReduxEducAgents.readonly} size="small">
											<ToggleButton onClick={() => handleSliderValueChangeByButton(vehicle, false)}><ArrowBackIos sx={{ width: "10px", height: "10px" }} fontSize="small" /></ToggleButton>
											<ToggleButton onClick={() => handleSliderValueChangeByButton(vehicle, true)}><ArrowForwardIos sx={{ width: "10px", height: "10px" }} fontSize="small" /> </ToggleButton>
										</ToggleButtonGroup>
										<IconButtonX disabled={!valuesAudit.is100} icon={ReduxEducAgents.readonly ? "RemoveRedEyeRounded" : "EditRounded"} size={"small"} onClick={() => selectVehicleCallback(vehicle)} />
									</div>
								</div>
								<Slider
									name={vehicle}
									size="small"
									color="primary"
									disabled={ReduxEducAgents.readonly}
									min={0}
									max={ReduxEducAgents.AsTrafficConfig?.vehicleCount}
									step={1}
									value={ReduxEducAgents.AsTrafficConfig.trafficConfig[vehicle].value}
									onChange={handleSliderChange}
								/>
							</div>
						))}
				</Box>
			</div>
		</div>
	);
}
export default connect(mapStateToProps, { ReduxUpdateEducAgentsJourney }) (VehicleComposition);
