import { CardContent, Grid, Typography } from '@mui/material'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom';
import ApplicationTraceableTable from './applicationTraceableComponent/applicationTraceableTable';

const style = {
    bgcolor: "secondary.dark",
    padding: "7px",
    borderRadius: "8px"
};

export default function ApplicationTraceable(props) {
    const { name, id } = useParams();
    const location = useLocation()
    console.log("location", location)

    const columns = [
      {
        field: "version_number",
        headerName: (
          <span className="heading-2">Version Number</span>
        ),
        width: 355,
        renderCell: (params) => (
          <span className="row-heading">{params.value}</span>
        ),
      },
        {
          field: "test_vehicle_id",
          headerName: (
            <span className="heading-2">Test Vehicle Id</span>
          ),
          width: 355,
          renderCell: (params) => (
            <span className="row-heading">{params.value}</span>
          ),
        },
      
        {
          field: "map_generation_id",
          headerName: (
            <span className="heading-2">Map Generation Id</span>
          ),
          width: 330,
          renderCell: (params) => (
            <span className="row-heading">{params.value}</span>
          ),
        },
        {
          field: "change_log",
          headerName: (
            <span className="heading-2">Change Log</span>
          ),
          width: 355,
          renderCell: (params) => (
            <span className="row-heading">{params.value}</span>
          ),
        },
        {
          field: "date_created",
          headerName: (
            <span className="heading-2">Created At</span>
          ),
          width: 310,
          renderCell: (params) => (
            <span className="row-heading">{params.value}</span>
          ),
        },
      ];


    return (
        <>
        <Grid sx={style}>
            <Typography variant='h6'>Project Name: Traceable - {name} - {id}</Typography>
        </Grid>
        <ApplicationTraceableTable headers={columns} location={location} />
        </>
    )
}
