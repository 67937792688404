import { Collapse } from "@mui/material";
import Box from "@mui/material/Box";
import Grow from '@mui/material/Grow'
import React from "react";

function CollapseContent({ show, children, orientation }) {
	return (
			<Collapse in={show} orientation={orientation} classes={{
                wrapperInner: 'w-100'
            }}>{children}</Collapse>
			// <Grow in={show} style={{ transformOrigin: "0 0 0" }} {...(show ? { timeout: 1000 } : {})}>
			// 	{children}
			// </Grow>
	);
}
export default CollapseContent;
