import React from "react";
import Box from "@mui/material/Box";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Radio,
  Button,
  TextareaAutosize,
  CardContent,
  Chip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ModalX } from "components";
import { ProgressX } from "components";
import { getScenarioStatusListByPralionId } from "endpointServices/myScenarioServices";



export default function LowFidelityStatus({ statusObject, close, open }) {
  console.log('statusObject', statusObject)
  const [state, setState] = React.useState({
    status: '',
    time: ''

  })
  const [list, setList] = React.useState([])
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedTask, setSelectedTask] = React.useState(null);
  const [isLoad, setLoad] = React.useState(true);

  React.useEffect(() => {
    getStatusList()
  }, [])

  const getStatusList = async () => {
    const pralionId = statusObject?.pralionId;
    setLoad(true)
    const response = await getScenarioStatusListByPralionId(pralionId);
    console.log("API response:", response);
    if (response?.status === 200) {
      const resp = response?.data?.data;
      console.log("status data", resp);
      setState({
        status: resp?.dag_state,
        time: resp?.duration
      });
      setList(resp?.task_state)
      setLoad(false)

    } else {
      setLoad(false)
      console.error("Failed to fetch status data");
    }
  };

  const handleVisibilityClick = (task) => {
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setSelectedTask(null);
    setIsModalOpen(false);
  };

  const renderContent = () => {
    if (!Object.values(state) || !list) {
      return (
        <p>No execution information avaiable for this Application Instance!</p>
      )
    } else {
      return (
        <div>
          <div className="status-table-data p-3 mb-3">
            <h6><b>State:</b>
              {state?.status === "success" ? (
                <Chip label="Success" color="success" className="chip-text-color" />
              ) : state?.status === "failed" ? (
                <Chip label="Failed" className="chip-failed-danger" />
              ) : state?.status === "running" ? (
                <Chip label="Running" color="primary" className="chip-text-color" />
              ) : (
                <Chip label="Queued" color="secondary" className="chip-text-color" />
              )}
            </h6>
            <h6><b>Duration:</b> { typeof state?.time === 'string' ? state?.time : state?.time.toFixed(2)} sec</h6>
          </div>
          <div className="status-table-data mb-3">
            <TableContainer>
              <Table size="medium">
                <TableHead>
                  <TableRow>
                    <TableCell>Task Id</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Log</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list && list.map((task) => (
                    <TableRow key={task.task_id}>
                      <TableCell>{task.task_id}</TableCell>
                      <TableCell>{typeof task?.duration === 'string' ? task?.duration: task?.duration.toFixed(2)} sec</TableCell>
                      <TableCell>
                        {task.state === "success" ? (
                          <Chip label="Success" color="success" className="chip-text-color" />
                        ) : task.state === "failed" || task.state === "upstream_failed" ? (
                          <Chip label="Failed" className="chip-failed-danger" />
                        ) : task.state === "running" ? (
                          <Chip label="Running" color="primary" className="chip-text-color" />
                        ) : task.state === "queued" ? (
                          <Chip label="Queued" color="secondary" className="chip-text-color" />
                        ) : <Chip label="Wait" color="secondary" className="chip-text-color" />}
                      </TableCell>
                      <TableCell>
                        <VisibilityIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => handleVisibilityClick(task)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )
    }

  }

  return (
    <Box>
      <CardContent>
        {isLoad ? <ProgressX /> : renderContent()}
      </CardContent>
      {isModalOpen && (
        <ModalX
          open={isModalOpen}
          close={handleModalClose}
          heading={`${statusObject?.name}`}
          subheading={"Logs Details"}
        >
          {selectedTask && (
            <div>
              <TextareaAutosize className="logs-detail" style={{ overflow: 'auto' }} value={selectedTask.logs} readOnly />

            </div>
          )}
        </ModalX>
      )}
    </Box>
  );
}
