import reducers from './reducers';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise';
import { composeWithDevTools } from '@redux-devtools/extension';
import storage from 'redux-persist/lib/storage'; // You can change this to use another storage type
import { persistStore, persistReducer } from 'redux-persist';


const persistConfig = {
	key: 'root', // key for the localStorage
	storage,
  };

const persistedReducer = persistReducer(persistConfig, reducers);

const STORE = createStore((persistedReducer), composeWithDevTools(applyMiddleware(promise, thunk)));
export const persistor = persistStore(STORE);

export default STORE;