import React, { Suspense, useState, useEffect } from "react";
import {
  Box,
  Button,
  CardContent,
  CircularProgress,
  TextField,
  MenuItem,
  Grid,
  IconButton,
  Typography,
  Modal,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Checkbox,
  Tab,
} from "@mui/material";
import ThreeCanvas from "../../../../../../components/3DCanvas";
import LeftSideModal from "../../../../../../components/leftSlideModal";
import ThreeVehicleScene from "./3DVehicleScene";
import { useParams } from "react-router-dom";
import EdgesensorLowIcon from "@mui/icons-material/EdgesensorLow";
import SensorsIcon from "@mui/icons-material/Sensors";
import ExpandIcon from "@mui/icons-material/Expand";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DVehicleForm from "./3DVehicleForm";
import SensorSetupForm from "./SensorSetupForm";
import {
  getSensorSetups,
  addSensorSetups,
  editSensorSetups,
  editSensorPreset,
  getTestVehicleDetails,
} from "../../../../../../endpointServices/testVehicleService";
import {
  LIDAR_DEFAULT_OBJECT,
  GT_DEFAULT_OBJECT,
  Static_Properties_Cam_Lidar_GT,
} from "../../../../../../utils/staticsData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LeftSideModalX from "components/leftSideModalX";
import VehicleDetailsTable from "./vehicleDetailsTable";
import STORE from "services/redux-store/store";
import { degreesToMeters, getSensorSetupDetails } from "utils";
import { ModalX, ProgressX } from "components";
import InfoIcon from "@mui/icons-material/Info";
import InstructionGuide from "components/instructionGuide";
import { metersToDegrees } from "utils";
import { ReduxUpdateMyVehicleJourney } from "../../../../../../services/redux-store/actions/actions-vehicles";
import { connect } from "react-redux";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const tabStyle = {
  color: "#fff",
  cursor: "pointer",
  backgroundColor: "#0a294d",
  width: "45%",
  padding: "10px 12px",
  margin: "6px",
  border: "none",
  borderRadius: "7px",
  display: "flex",
  textTransform: "none",
  justifyContent: "center",
  "&.Mui-selected": {
    backgroundColor: "#00A3B5",
    color: "#FFFFF",
  },
};

const centerStyle = {
  height: "532px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px solid #00A3B5",
  borderRadius: "12px",
  // marginTop: '3rem',
  marginRight: "2rem",
  marginLeft: "1.5rem",
};

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "secondary.dark",
  border: "1px solid #00A3B5",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const VehicleDetailsDForm = ({
  projectId,
  currentVehicle,
  onSubmitHandler,
  disabledSubmit,
  handleCameraPresets,
  ReduxUpdateMyVehicleJourney,
}) => {
  const { MyVehicle, Projects } = STORE.getState();
  const { readOnly } = MyVehicle;
  const [sensorSetups, setSensorSetups] = useState([]);
  const [selectedSensorSetup, setSelectedSensorSetups] = useState({});
  const [cameraSensor, setCameraSensor] = useState(false);
  const [groundTruth, setGroundTruth] = useState(false);
  const [lidars, setLidars] = useState(false);
  const [open, setOpen] = useState(false);
  const [configureSensor, setConfigureSensor] = useState();
  const [avbSensorList, setAvbSensorList] = useState([]);
  const [sensorDescp, setSensorDescp] = useState();
  const [sensorPreset, setSensorPreset] = useState([]);
  const [enableAdd, setEnableAdd] = useState(false);
  const [sensorName, setSensorName] = useState("");
  const [arrayLength, setArrayLength] = useState(0);
  const [sensorSetupOpener, setSensorSetupOpener] = useState(false);
  const [reload, setReload] = useState(false);
  const [payloadData, setPayloadData] = useState([]);
  const [disable, setDisable] = useState(false);
  const [selectedCamera, setSelectedCamera] = useState("");
  const [cameraSensorPositions, setCameraSensorPositions] = useState({});
  const [lidarSensorPositions, setLidarSensorPositions] = useState({});
  const [gTSensorPositions, setGTSensorPositions] = useState({});
  const [value, setValue] = React.useState(0);
  const [selectedCameraId, setSelectedCameraId] = useState(null);
  const [enableCameraAdd, setEnableCameraAdd] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [isLidarPayload, setLidarPayload] = useState(true);
  const [isGTPayload, setGTPayload] = useState(true);
  const [sensorSelected, setSensorSelected] = useState([]);

  console.log("isLidarPayload", isLidarPayload);

  const handleAddIcon = () => {
      console.log("sensorSelected[0]?.sensor_presets", sensorSelected)
    if (sensorSelected[0]?.sensor_presets.length === 0) {
      toast.info(
        "Please add at least one camera sensor in created sensor setup before creating new sensor setup"
      );
    } else {
      setEnableAdd(true);
    }
  };

  const tabArray = [
    { label: "Sensor Setup", id: 0 },
    { label: "Vehicle Details", id: 1 },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [checkSensorLength, setCheckSensorLength] = useState([]);

  const param = useParams();

  useEffect(() => {
    fetchSensorSetups();
  }, [reload]);

  useEffect(() => {
    getVehicleDetails();
  }, [sensorSetups]);

  const handleOpenModal = () => {
    setOpenInfoModal(true);
  };

  const closeInfoModal = () => setOpenInfoModal(false);

  const handleAddModal = () => setEnableAdd(false);
  const closeSensorSetupModal = () => setSensorSetupOpener(false);

  const handleLidarDisable = (e) => {
    const targetValue = !isLidarPayload;
    setLidarPayload(targetValue);
    localStorage.setItem("isLidar", JSON.stringify(targetValue));
    const newTargetValue =
      Object.keys(selectedSensorSetup).length === 0 && isGTPayload
        ? targetValue
        : false;
    // if (newTargetValue === false) {
    //   disabledSubmit(true);
    // } else {
    //   disabledSubmit(true);
      setLidars(false);
    // }
  };

  const handleGTDisable = (e) => {
    const targetValue = !isGTPayload;
    setGTPayload(targetValue);
    localStorage.setItem("isGT", JSON.stringify(targetValue));
    const newTargetValue =
      Object.keys(selectedSensorSetup).length === 0 && isLidarPayload
        ? targetValue
        : false;
    // if (newTargetValue === false) {
    //   disabledSubmit(true);
    // } else {
    //   disabledSubmit(true);
      setGroundTruth(false);
    // }
  };

  const handleAddCameraIcon = (value) => {
    console.log("valueee", value)
    setSelectedSensorSetups(value);
  }

  const selectSensorSetups = (event) => {
    const { value } = event.target;
    handleAddCameraIcon(value);
    setEnableCameraAdd(value.id);
    let selectedSensor = sensorSetups.filter((item) => item.id === value.id);
    setSensorSelected(selectedSensor || []);
    ReduxUpdateMyVehicleJourney({ sensorSetupId: value.id });
    if (
      (isLidarPayload === true && isGTPayload === true) ||
      Object.keys(value).length > 1
    ) {
      const staticProperties = Static_Properties_Cam_Lidar_GT;
      staticProperties.camera.name = value?.name;
      const camProperties = value?.sensor_presets?.[0];
      staticProperties.camera.cameraSensorFields.name = camProperties?.name;
      staticProperties.camera.cameraSensorFields.key = camProperties?.id;
      staticProperties.camera.cameraSensorFields.pos_x = camProperties?.pos_x;
      staticProperties.camera.cameraSensorFields.pos_y = camProperties?.pos_y;
      staticProperties.camera.cameraSensorFields.pos_z = camProperties?.pos_z;
      staticProperties.camera.cameraSensorFields.pitch = camProperties?.rot_x;
      staticProperties.camera.cameraSensorFields.yaw = camProperties?.rot_y;
      staticProperties.camera.cameraSensorFields.roll = camProperties?.rot_z;
      staticProperties.camera.rendering_type[0].camera_name =
        camProperties?.name;
      staticProperties.camera.rendering_type[0].key = camProperties?.id;
      staticProperties.camera.selected_camera_presets = [camProperties?.id];
      const cameraPayload = staticProperties.camera;
      const payload = {
        project_id: projectId,
        vehicle_profile_id: param.id,
        sensors: {
          camera: cameraPayload,
        },
      };

      console.log("payload", payload);
      onSubmitHandler(payload);
      handleCameraPresets([selectedSensor[0]]);
    }

    // Set isShow to true for all items in the sensor_presets array
    const updatedSensorPresets = selectedSensor[0].sensor_presets.map(
      (preset) => ({
        ...preset,
        isShow: true,
      })
    );

    const updatedSelectedSensor = {
      ...selectedSensor[0],
      sensor_presets: updatedSensorPresets,
    };
    setSensorPreset([updatedSelectedSensor]);
    getSensorSetupDetails(selectedSensor[0]);
    const checkSensorLength = updatedSelectedSensor.sensor_presets;
    setCheckSensorLength(checkSensorLength);

    if (updatedSelectedSensor.sensor_presets.length > 4) {
      setDisable(true);
    } else {
      setDisable(false);
    }
    
    disabledSubmit(false);
  };

  const sensorHandler = (event) => {
    setSensorName(event.target.value);
  };

  const cameraSelected = (event) => {
    console.log("event.target.value", event.target.value);
    setSelectedCamera(event.target.value);
    setCameraSensorPositions({});
  };

  const fetchSensorSetups = async () => {
    const projectId = Projects.projectId;
    const { data } = await getSensorSetups(projectId);
    if (data.data.length) {
      setArrayLength(data.data.length);
      let currentVehicleId = currentVehicle.vehicleProfileId;
      let relevantSetups = data.data.filter(
        (item) => item.vehicle_profile_id === currentVehicleId
      );

      setSensorSetups(relevantSetups);
    }
  };

  const addSensorSetup = (e) => {
    e.preventDefault();
    const temp = [
      {
        id: arrayLength + 1,
        name: sensorName,
        is_default_setup: false,
        origin_pos_x: 0,
        origin_pos_y: 0,
        origin_pos_z: 0,
        origin_rot_x: 0,
        origin_rot_y: 0,
        origin_rot_z: 0,
        unique_id: currentVehicle.vehicleProperties.unique_id,
        sensor_presets: [],
      },
    ];
    setSensorSetups([...sensorSetups, ...temp]);
    setSensorName("");
    handleAddModal();
    toast.success(
      "Sensor setup added, select sensor setup again from dropdown"
    );
  };

  const handleSensorEditClick = (sensorName, id = 0) => {
    setConfigureSensor(sensorName);
    let description = "";
    if (sensorName === "Camera") {
      description = "List of available Camera Sensors";
      let response = sensorPreset[0]?.sensor_presets.filter(
        (item) => item.id === id
      );
      if (response?.length) {
        setAvbSensorList(response[0]);
        setOpen(true);
      } else {
        toast.warning(
          `Click on save button to store this ${sensorName} camera`
        );
      }
    } else if (sensorName === "Ground Truth") {
      description = "Ground Truth Configuration";
      setAvbSensorList(vehicleDetails?.ground_truth[0]);
      setOpen(true);
    } else if (sensorName === "Lidar") {
      description = "List of available Lidar Sensors";
      setAvbSensorList(vehicleDetails?.lidar[0]);
      setOpen(true);
    }
    setSensorDescp(description);
  };

  const handleClose = () => setOpen(false);

  const handleCameraSensorChange = (id) => {
    const updatedSensorPresets = sensorPreset[0].sensor_presets.map(
      (preset) => {
        if (preset.id === id) {
          return {
            ...preset,
            isShow: !preset.isShow,
          };
        }
        return preset;
      }
    );

    const updatedSensorPreset = {
      ...sensorPreset[0],
      sensor_presets: updatedSensorPresets,
    };

    setSensorPreset([updatedSensorPreset]);
  };

  const handleGroundTruthChange = () => {
    setGroundTruth(!groundTruth);
  };

  const handleLidarsChange = () => {
    setLidars(!lidars);
  };

  const handleSubmit = (payloadResponse) => {
    // setCameraSensorPositions(payloadResponse?.camera?.cameraSensorFields)
    const targetSave = payloadResponse.key;
    let cameraPayload, gtSensorPayload, lidarPayload;
    console.log("selectedSensorSetup", selectedSensorSetup);
    if (configureSensor === "Camera") {
      cameraPayload = {
        name: selectedSensorSetup.name,
        ...payloadResponse.camera,
      };
    } else if (configureSensor === "Lidar") {
      lidarPayload = payloadResponse.lidar;
    } else if (configureSensor === "Ground Truth") {
      gtSensorPayload = payloadResponse.ground_truth;
    }
    console.log("cameraPayload", cameraPayload);
    console.log("lidarPayload", lidarPayload);

    let staticProperties = Static_Properties_Cam_Lidar_GT;
    if (cameraPayload === undefined) {
      staticProperties.camera.name = selectedSensorSetup?.name;
      const camProperties = selectedSensorSetup?.sensor_presets?.[0];
      staticProperties.camera.cameraSensorFields.name = camProperties?.name;
      staticProperties.camera.cameraSensorFields.key = camProperties?.id;
      staticProperties.camera.cameraSensorFields.pos_x = camProperties?.pos_x;
      staticProperties.camera.cameraSensorFields.pos_y = camProperties?.pos_y;
      staticProperties.camera.cameraSensorFields.pos_z = camProperties?.pos_z;
      staticProperties.camera.cameraSensorFields.pitch = camProperties?.rot_x;
      staticProperties.camera.cameraSensorFields.yaw = camProperties?.rot_y;
      staticProperties.camera.cameraSensorFields.roll = camProperties?.rot_z;
      staticProperties.camera.rendering_type[0].camera_name =
        camProperties?.name;
      staticProperties.camera.rendering_type[0].key = camProperties?.id;
      staticProperties.camera.selected_camera_presets = [camProperties?.id];
      cameraPayload = staticProperties.camera;
    }

    if (lidarPayload === undefined) {
      lidarPayload = staticProperties.lidar[0];
    }

    if (gtSensorPayload === undefined) {
      gtSensorPayload = staticProperties.ground_truth[0];
    }

    const payload = {
      project_id: projectId,
      vehicle_profile_id: param.id,
      sensors: {
        camera: cameraPayload
          ? cameraPayload
          : {
              name: selectedSensorSetup.name,
              ...MyVehicle?.sensorCameraPayload?.camera,
            },
        lidar: lidarPayload
          ? [lidarPayload]
          : [MyVehicle?.sensorLidarPayload?.lidar],
        ground_truth: gtSensorPayload ? [gtSensorPayload] : GT_DEFAULT_OBJECT,
      },
    };

    console.log("payload", payload);
    onSubmitHandler(payload);
    handleCameraPresets(sensorPreset, cameraPayload);
    if (targetSave === "Camera") {
      console.log("Camera");
      onEditSensorSetup();
    } else if (targetSave === "Lidar") {
      console.log("Lidar");
      onEditLidarSetup(lidarPayload);
    } else {
      console.log("GT");
      onEditGTSetup(gtSensorPayload);
    }
  };

  const onEditSensorSetup = async () => {
    const editPayload = sensorPreset[0];
    let editBody = sensorPreset[0].sensor_presets.map((item) => {
      const { sensor_type_id, sensor_id, ...rest } = item;
      return {
        ...rest,
        sensor_type: "camera",
        sensor_setup_id: editPayload.id,
        camera_sensor_id: sensor_id,
        // rot_x: metersToDegrees(item.rot_x),
        // rot_y: metersToDegrees(item.rot_y),
        // rot_z: metersToDegrees(item.rot_z)
      };
    });

    const payloadEdit = {
      sensor_presets: editBody,
    };
    console.log("payloadEdit", payloadEdit);
    const response = await editSensorPreset(payloadEdit);
    if (response?.status === 200) {
      toast.info(
        "Camera preset coordinates updated, Can be lost upon changing sensor setup"
      );
      getVehicleDetails();
      // setSensorPreset([]);
      // setReload(true);
      // fetchSensorSetups();
    } else {
      const err = response?.response?.data?.message;
      toast.error(`${err}`);
      console.log("errorresponse", response);
    }
  };

  const onEditLidarSetup = async (lidarPayload) => {
    console.log("lidarPayload", lidarPayload);
    const payloadEdit = {
      lidar: lidarPayload,
    };

    const response = await editSensorPreset(payloadEdit);
    if (response?.status === 200) {
      toast.info("Lidar values can be saved upon clicking the submit button.");
      // setSensorPreset([]);
      // setReload(true);
      // fetchSensorSetups();
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      console.log("errorresponse", response);
    }
  };

  const onEditGTSetup = async (gtSensorPayload) => {
    console.log("gtSensorPayload", gtSensorPayload);
    const payloadEdit = {
      ground_truth: gtSensorPayload,
    };

    const response = await editSensorPreset(payloadEdit);
    if (response?.status === 200) {
      // toast.success("GT preset coordinates successfully  updated");
      // setSensorPreset([]);
      // setReload(true);
      // fetchSensorSetups();
    } else {
      const err = response?.message || "Failed to get request";
      toast.error(`${err}. Please try again later`);
      console.log("errorresponse", response);
    }
  };

  const getVehicleDetails = async () => {
    setLoader(true);
    const response = await getTestVehicleDetails(MyVehicle.pralionId);
    if (response?.status === 200) {
      const resp = response?.data?.data?.sensor_config;
      console.log("resp", resp);
      if (readOnly) {
        const selectedSensorId = resp.camera[0].sensor_setup_id;
        const selectedSensor = sensorSetups.find(
          (item) => item.id === selectedSensorId
        );
        console.log("selectedSensor", selectedSensor);
        setSelectedSensorSetups(selectedSensor);
        setSensorPreset([selectedSensor]);

        if (resp.lidar.length > 0) {
          setLidarPayload(false);
        }
        if (resp.ground_truth.length > 0) {
          setGTPayload(false);
        }
      }
      setVehicleDetails(resp);
      const lidarResp = resp?.lidar[0] || "";
      handleLidarSensor(lidarResp);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    } else {
      setLoader(false);
      const err = response?.message || "Failed to get request";
    }
  };

  const onSaveHandler = (payloadResponse) => {
    const sensorPayloadData = [...payloadData, payloadResponse];
    setPayloadData(sensorPayloadData);
    if (payloadData.length) {
      payloadData[0].sensor_presets = [
        ...payloadData[0].sensor_presets,
        ...payloadResponse.sensor_presets,
      ];
    }
    let temp = sensorPreset[0];

    temp.sensor_presets = [
      ...sensorPreset[0].sensor_presets,
      ...payloadResponse.sensor_presets,
    ];
    setSensorPreset([temp]);
    onSubmitSensorSetup(temp);
  };

  const onSubmitSensorSetup = async (sensorPayload) => {
    console.log("sensorPayload.sensor_presets", sensorPayload);
    const camSensorData = sensorPayload.sensor_presets.map((item) => {
      const { sensor_type_id, ...rest } = item;
      return { ...rest, sensor_type: "camera" };
    });
    sensorPayload.sensor_presets = camSensorData;
    if (camSensorData.length) {
      const sensorCameraLength = camSensorData.length > 1;
      if (sensorCameraLength) {
        sensorPayload.id = selectedSensorSetup.id;
        sensorPayload.project_id = projectId;
        delete sensorPayload.unique_id;
      } else {
        sensorPayload.project_id = projectId;
      }

      let editPayloadData = sensorPayload.sensor_presets.map((item) => {
        const { sensor_type_id, sensor_id, ...rest } = item;
        return {
          ...rest,
          sensor_type: "camera",
          sensor_setup_id: sensorPayload.id,
          camera_sensor_id: sensor_id,
        };
      });

      const payloadEdit = {
        sensor_presets: editPayloadData,
      };
      console.log("sensorCameraLength", sensorCameraLength);
      const response = sensorCameraLength
        ? await editSensorPreset(payloadEdit)
        : await addSensorSetups(sensorPayload);

      if (response?.status === 200) {
        if (sensorCameraLength === true) {
          toast.success("Sensor added & list updated now please Select the sensor setup again");
          setSensorPreset([]);
          setReload(true);
          fetchSensorSetups();
          disabledSubmit(true);
        } else {
          toast.info(
            "Camera positions is set to default. Select again sensor setup & click on editing icon for updating position"
          );
          setSensorPreset([]);
          setReload(true);
          fetchSensorSetups();
        }
      } else {
        setSensorPreset([]);
        const err = `${response?.response?.data?.message.replace('.', ' setup.')}` || "Failed to get request";
        toast.error(`${err}`);
        console.log("errorresponse", response);
      }
    }
  };

  const sensorSetupModalHandler = () => {
    if (sensorPreset[0]?.sensor_presets?.length >= 4) {
      toast.warning(`You exceed the limit to add cameras. Maximum limit is 4`);
    } else setSensorSetupOpener(true);
  };

  const handleCameraSensorSelection = (id) => {
    setSelectedCameraId(id);
    // Add any additional logic you want to perform when a camera sensor is selected
  };

  const handleCameraSensor = (cameraSensorFieldsData) => {
    const updatedSensorPresets = sensorPreset[0].sensor_presets.map(
      (preset) => {
        console.log("preset", preset);
        console.log("cameraSensorFieldsData.name", cameraSensorFieldsData);
        if (preset.id === cameraSensorFieldsData.key) {
          // Update the properties with values from cameraSensorFieldsData
          return {
            ...preset,
            pos_x: cameraSensorFieldsData.pos_x,
            pos_y: cameraSensorFieldsData.pos_y,
            pos_z: cameraSensorFieldsData.pos_z,
            rot_x: cameraSensorFieldsData.pitch,
            rot_y: cameraSensorFieldsData.yaw,
            rot_z: cameraSensorFieldsData.roll,
          };
        }
        return preset;
      }
    );

    const updatedSensorPreset = {
      ...sensorPreset[0],
      sensor_presets: updatedSensorPresets,
    };
    setSensorPreset([updatedSensorPreset]);
    setCameraSensorPositions(cameraSensorFieldsData);
  };

  const handleLidarSensor = (lidarSensorFieldsData) => {
    console.log("lidarSensorFieldsData", lidarSensorFieldsData);
    setLidarSensorPositions(lidarSensorFieldsData);
  };

  const handleGTSensor = (gTSensorFieldsData) => {
    console.log("gTSensorFieldsData", gTSensorFieldsData);
    setGTSensorPositions(gTSensorFieldsData);
  };

  const sceneProps = {
    cameraSensor,
    groundTruth,
    lidars,
    cameraSensorPositions,
    lidarSensorPositions,
    gTSensorPositions,
    sensorPreset,
    selectedCameraId,
  };
  return (
    <>
      <LeftSideModalX
        open={open}
        close={handleClose}
        heading={configureSensor}
        subheading={sensorDescp}
      >
        <DVehicleForm
          open={open}
          handleClose={handleClose}
          name={configureSensor}
          sensorObject={avbSensorList}
          submitHandler={handleSubmit}
          handleCameraSensorFields={handleCameraSensor}
          handleLidarsFields={handleLidarSensor}
          handleGTFields={handleGTSensor}
          sensorState={vehicleDetails}
          sensorSetup={selectedSensorSetup}
        />
      </LeftSideModalX>

      <LeftSideModalX
        open={sensorSetupOpener}
        close={closeSensorSetupModal}
        heading={"Sensor Setup"}
        subheading={"Add Sensor Setup"}
      >
        <Box>
          <SensorSetupForm
            sensorSetup={sensorPreset[0]}
            onClose={closeSensorSetupModal}
            onSave={onSaveHandler}
            disabledSubmit={disabledSubmit}
            handleAddCameraIcon={handleAddCameraIcon}
            setSensorSelected={setSensorSelected}
          />
        </Box>
      </LeftSideModalX>
      <ModalX
        open={openInfoModal}
        heading={<span>Guide to Sensor Setup</span>}
        subheading={
          <span>
            Creating and Managing Sensors, Cameras, Lidar, and Ground Truth
          </span>
        }
        close={closeInfoModal}
      >
        <InstructionGuide type={"testVehicle"} />
      </ModalX>
      {loader && <ProgressX fullscreen={true} />}
      <div style={{ marginRight: "1rem", marginTop: "1.3em" }}>
        <Grid
          container
          sx={{
            backgroundColor: "secondary.dark",
            border: "2px solid",
            borderColor: "primary.main",
            borderRadius: "8px",
            // height: "42.5rem",
            p: 2,
          }}
        >
          <Grid item xs={8}>
            <Grid item xs={6} sx={{ marginLeft: ".7rem" }}>
              <Typography
                variant="h6"
                color="text.main"
                sx={{ marginLeft: "1rem" }}
              >
                <IconButton sx={{ ml: 0 }} onClick={handleOpenModal}>
                  <InfoIcon />
                </IconButton>
                Vehicle in 3D
              </Typography>
              <Typography
                variant="caption"
                color="text.main"
                sx={{ marginLeft: "1.4rem" }}
              >
                with sensor view and placement
              </Typography>
            </Grid>
            <Grid item xs={12} className="mt-3">
              <Box style={centerStyle}>
                <Suspense fallback={<CircularProgress />}>
                  <ThreeCanvas>
                    <ThreeVehicleScene {...sceneProps} />
                  </ThreeCanvas>
                </Suspense>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                "& .MuiTab-root": {
                  fontSize: "1.2rem",
                },
                "& .MuiTabs-indicator": {
                  height: "0px",
                },
                backgroundColor: "secondary.dark",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "space-between",
              }}
            >
              {tabArray.map((tab) => (
                <Tab key={tab.id} label={tab.label} sx={tabStyle} />
              ))}
            </Tabs>
            <Box
              sx={{
                // width: "95%",
                // height: "62.5vh",
                backgroundColor: "secondary.dark",
                mt: 1.5,
                marginRight: "1.6rem",
                border: "2px solid",
                borderColor: "primary.main",
                borderRadius: "12px",
                p: 2,
              }}
            >
              <CustomTabPanel value={value} index={0}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={12} sx={{ ml: "2rem", mr: "2rem" }}>
                    <Box
                      className="mb-1"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      Select Sensor Setup
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: "secondary.dark",
                        borderRadius: "4px",
                        p: 1,
                        border: "1px solid #ccc",
                        height: "6vh",
                        transition: "border-color 0.3s ease",
                        "&:focus-within": {
                          borderColor: "primary.main",
                        },
                      }}
                    >
                      <TextField
                        select
                        variant="outlined"
                        // label="Select Sensor Setup"
                        fullWidth
                        value={selectedSensorSetup}
                        onChange={selectSensorSetups}
                        disabled={readOnly}
                        SelectProps={{
                          MenuProps: {
                            className: "customDropdown",
                          },
                        }}
                        sx={{
                          color: "white",
                          flex: 1,
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      >
                        {sensorSetups &&
                          sensorSetups.map((option) => {
                            return (
                              <MenuItem key={option.id} value={option}>
                                {option.name}
                              </MenuItem>
                            );
                          })}
                      </TextField>
                      <IconButton
                        onClick={() => handleAddIcon()}
                        disabled={readOnly}
                      >
                        <AddIcon className={readOnly ? "" : "D-icon-color"} />
                      </IconButton>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Grid
                    item
                    xs={12}
                    sx={{
                      borderRadius: "8px",
                      ml: "2rem",
                      mr: "2rem",
                    }}
                  >
                    <form>
                      <Grid item>
                        <Box
                          className="mb-1 mt-1"
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ marginTop: "16px" }}>
                            Cameras
                          </Typography>
                          <IconButton
                            onClick={() => sensorSetupModalHandler(true)}
                            disabled={!enableCameraAdd || !Object.keys(selectedSensorSetup).length}
                          >
                            <AddIcon
                              className={enableCameraAdd && Object.keys(selectedSensorSetup).length ? "D-icon-color" : ""}
                            />
                          </IconButton>
                        </Box>
                        <Box
                          className="mb-3"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            backgroundColor: "secondary.dark",
                            borderRadius: "4px",
                            p: 1,
                            border: "1px solid #ccc",
                            height: "25vh",
                            // overflowX: "auto",
                            transition: "border-color 0.3s ease",
                            "&:focus-within": {
                              borderColor: "primary.main",
                            },
                          }}
                        >
                          <Grid
                            name="cameras"
                            fullWidth
                            sx={{ color: "white", flex: 1 }}
                          >
                            <List
                              sx={{
                                marginRight: ".1rem",
                                marginLeft: "1rem",
                              }}
                            >
                              {sensorPreset[0]?.sensor_presets?.length ? (
                                sensorPreset[0]?.sensor_presets.map(
                                  (value, id) => (
                                    <ListItem
                                      // sx={{ mr: "4rem" }}
                                      key={id}
                                      disableGutters
                                      selected={selectedCameraId === value.id}
                                      onClick={() =>
                                        handleCameraSensorSelection(value.id)
                                      }
                                      secondaryAction={
                                        <div>
                                          {value.name && (
                                            <>
                                              <IconButton
                                                onClick={() =>
                                                  handleSensorEditClick(
                                                    "Camera",
                                                    value.id
                                                  )
                                                }
                                                disabled={readOnly}
                                              >
                                                <EditIcon
                                                  className={
                                                    readOnly
                                                      ? ""
                                                      : "D-icon-color"
                                                  }
                                                />
                                              </IconButton>
                                              <IconButton
                                                onClick={() =>
                                                  handleCameraSensorChange(
                                                    value.id
                                                  )
                                                }
                                              >
                                                <SensorsIcon className="D-icon-color" />
                                              </IconButton>
                                            </>
                                          )}
                                        </div>
                                      }
                                    >
                                      <ListItemText primary={value.name} />
                                    </ListItem>
                                  )
                                )
                              ) : (
                                <Typography sx={{ ml: 3, mb: 5 }}>
                                  {" "}
                                  No sensor found!
                                </Typography>
                              )}
                            </List>
                          </Grid>
                        </Box>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "row",
                  }}
                  spacing={3}
                >
                  <Grid item xs={12} sx={{ ml: "2rem", mr: "0.5rem" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: "secondary.dark",
                        borderRadius: "4px",
                        height: "6vh",
                        p: 1,
                        border: "1px solid #ccc",
                        transition: "border-color 0.3s ease",
                        "&:focus-within": {
                          borderColor: "primary.main",
                        },
                      }}
                    >
                      <div
                        name="lidars"
                        fullWidth
                        style={{
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={!isLidarPayload}
                          value={isLidarPayload}
                          onChange={handleLidarDisable}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 22 } }}
                          disabled={readOnly}
                        />
                        <Typography sx={{ ml: 1.6 }}>Lidar</Typography>
                      </div>
                      <div>
                        <IconButton
                          onClick={() => handleSensorEditClick("Lidar")}
                          disabled={isLidarPayload ? isLidarPayload : readOnly}
                          sx={{
                            color: isLidarPayload ? "grey" : "#00A3B5",
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={handleLidarsChange}
                          disabled={isLidarPayload}
                          sx={{
                            color: isLidarPayload ? "grey" : "#00A3B5",
                          }}
                        >
                          <EdgesensorLowIcon />
                        </IconButton>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ ml: "2rem", mr: "0.5rem" }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        backgroundColor: "secondary.dark",
                        borderRadius: "4px",
                        height: "6vh",
                        p: 1,
                        border: "1px solid #ccc",
                        transition: "border-color 0.3s ease",
                        "&:focus-within": {
                          borderColor: "primary.main",
                        },
                      }}
                    >
                      <div
                        name="groundTruth"
                        fullWidth
                        style={{
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          checked={!isGTPayload}
                          value={isGTPayload}
                          onChange={handleGTDisable}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 22 } }}
                          disabled={readOnly}
                        />
                        <Typography sx={{ ml: 1.6 }}>Ground Truth</Typography>
                      </div>
                      <div>
                        <IconButton
                          onClick={() => handleSensorEditClick("Ground Truth")}
                          disabled={isGTPayload ? isGTPayload : readOnly}
                          sx={{
                            color: isGTPayload ? "grey" : "#00A3B5",
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={handleGroundTruthChange}
                          disabled={isGTPayload}
                          sx={{
                            color: isGTPayload ? "grey" : "#00A3B5",
                          }}
                        >
                          <ExpandIcon />
                        </IconButton>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <VehicleDetailsTable carData={MyVehicle.vehicleProperties} />
              </CustomTabPanel>
            </Box>
          </Grid>
        </Grid>
      </div>

      {enableAdd && (
        <Modal open={enableAdd} onClose={handleAddModal}>
          <Box sx={modalStyle}>
            <div
              style={{
                marginBottom: "1rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" component="h2">
                Add Sensor Setup
              </Typography>
              <CloseIcon
                onClick={handleAddModal}
                cursor="pointer"
                sx={{ marginLeft: "3rem", marginTop: "0.5rem" }}
              />
            </div>
            <form onSubmit={addSensorSetup}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  type="text"
                  variant="outlined"
                  label="Sensor Name"
                  fullWidth
                  value={sensorName}
                  onChange={sensorHandler}
                  sx={{ width: "100%" }}
                  required
                />
              </Grid>
              <div style={{ marginTop: "2rem", marginLeft: "80%" }}>
                <Button
                  sx={{
                    backgroundColor: "primary.main",
                    "&:hover": {
                      backgroundColor: "primary.main",
                    },
                    color: "white",
                    fontSize: "13px",
                  }}
                  type="submit"
                >
                  Add
                </Button>
              </div>
            </form>
          </Box>
        </Modal>
      )}
    </>
  );
};
export default connect(null, { ReduxUpdateMyVehicleJourney })(
  VehicleDetailsDForm
);
