import { SET_TEST_SUITS, UPDATE_TEST_SUITS } from ".."

export const ReduxCreateTestSuitsJourney = (pid,pname, versionId, praId, autoPName, autoScen, myPraId, myPName, myScen) => {
    return {
        type: SET_TEST_SUITS,
        payload: { 
            pralionId: pid, 
            projectName: pname,
            applicationVersionId: versionId,
            autoScenarioProjectName: autoPName,
            autoScenarioPralionId: praId,
            autoScenarioNumberOfScenarios: autoScen,
            myScenarioPralionId: myPraId,
            myScenarioProjectName: myPName,
            myScenarioNumberOfScenarios: myScen,
        }
    }
}

export const ReduxUpdateTestSuitsJourney = (obj) => {
    return {
        type: UPDATE_TEST_SUITS,
        payload: obj
    }
}