import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import {
    Box,
    Grid,
    CardContent,
    TextField,
    MenuItem,
    InputLabel,
    Button,
    IconButton,
  } from "@mui/material"
import Slider from "@mui/material/Slider";
import { Typos } from "components";
import EditIcon from '@mui/icons-material/Edit'
import { cameraSensorDumpTypes} from '../../../../../../utils/staticsData'
import { ReduxUpdateMyVehicleJourney } from '../../../../../../services/redux-store/actions/actions-vehicles'
import RenderingTypeForm  from './RenderingTypeForm'
import STORE from "services/redux-store/store";
import AddCameraSensorModal from 'components/addCameraSensorModal';
import { degreesToMeters, metersToDegrees } from 'utils';


const DVehicleForm = ({handleClose, name, sensorState, handleGTFields, sensorObject, submitHandler, ReduxUpdateMyVehicleJourney, handleCameraSensorFields, handleLidarsFields}) => {
  console.log('name', name)
  console.log("sensorObject", sensorObject)
  const sensorObjectData = sensorObject === undefined ? {} :sensorObject
  const [open, setOpen] = useState()
  const [rederingType, setRenderingType] = useState([])
  const [GTTextFields, setGTTextFields] = useState({
    name: 'GtSensor',
    pos_x: 0,
    pos_y: 0,
    pos_z: 0,
    roll: 0,
    pitch: 0,
    yaw: 0,
    radius: 30,

  })
  const [lidarTextFields, setLidarTextFields] = useState({
    name: 'Default Lidar',
    pos_x: 0,
    pos_y: 0,
    pos_z: 0,
    roll: 0,
    pitch: 0,
    yaw: 0,
    radius: 1,
    accuracy: 0.02,
    channels: 32,
    fov_x: 360,
    fov_y: -30,
    horizontal_frequency: 25,
    sample_frequency:  4000


  })
  console.log('lidarTextFields', lidarTextFields)
  const [cameraSensorFields, setCameraSensorFields] = useState({
    name: '',
    dumpType: '',
    monitor: false,
    key: 0,
    pos_x: 0,
    pos_y: 0,
    pos_z: 0,
    roll: 0,
    pitch: 0,
    yaw: 0
    })

    const {MyVehicle} = STORE.getState()
    const closeHandler = () => setOpen(false)


  useEffect(()=> {
    if(Object.keys(sensorObjectData).length) {
      if(name === 'Ground Truth') {
        setGTTextFields({
        name:  sensorObjectData.name ,
        pos_x: sensorObjectData.pos_x ,
        pos_y: sensorObjectData.pos_y ,
        pos_z: sensorObjectData.pos_z ,
        roll:  sensorObjectData.roll ,
        pitch: sensorObjectData.pitch  ,
        yaw: sensorObjectData.yaw ,
        radius:  30 
        })
      }
      else if(name === 'Lidar') {
        setLidarTextFields({
          name: sensorObjectData.name ,
          pos_x:  sensorObjectData.pos_x,
          pos_y:   sensorObjectData.pos_y ,
          pos_z: sensorObjectData.pos_z,
          roll:  sensorObjectData.roll,
          pitch:  sensorObjectData.pitch ,
          yaw:  sensorObjectData.yaw ,
          radius:  sensorObjectData.radius === undefined ? 1 : sensorObjectData.radius ,
          accuracy:  sensorObjectData.accuracy ,
          channels:  sensorObjectData.channels,
          fov_x:  sensorObjectData.fov_x,
          fov_y:  sensorObjectData.fov_y ,
          horizontal_frequency: sensorObjectData.horizontal_frequency,
          sample_frequency:  sensorObjectData.sample_frequency
          })
      }
      else if(name === 'Camera') {
      setCameraSensorFields({
        name: sensorObjectData.name,
        dumpType: sensorObjectData?.sensor_presets?.dump_type ? sensorObjectData?.dump_type : cameraSensorDumpTypes[0].id,
        monitor: sensorObjectData?.sensor_presets?.monitor ? sensorObjectData?.sensor_presets?.monitor : false,
        key: sensorObjectData.id,
        pos_x: sensorObjectData.pos_x,
        pos_y: sensorObjectData.pos_y,
        pos_z: sensorObjectData.pos_z,
        roll: sensorObjectData.rot_z,
        pitch: sensorObjectData.rot_x,
        yaw: sensorObjectData.rot_y
      })
      }
    }
  },[sensorObjectData])


  const handleRenderingType = (redering_type) => {
    console.log('redering_type', redering_type)
    if(MyVehicle.rendering_Type) {
      const {rendering_Type} = MyVehicle
      ReduxUpdateMyVehicleJourney({"rendering_Type": [...rendering_Type, ...redering_type]})
    }
    else {
    setRenderingType(redering_type)
    ReduxUpdateMyVehicleJourney({"rendering_Type": redering_type})
    }
    
  }

  const handlePayload = () => {
    const sensor_presets = [{
      camera_name: sensorObjectData.name,
      raw: true,
      segmented: false,
      depth: false,
      normals: false,
      save_images: false,
      key: sensorObjectData.id
    }]

    const sensorCameraPayload = {
      "camera": {
        "selected_camera_presets": [sensorObjectData.id],
        "cameraSensorFields": cameraSensorFields,
        "rendering_type": MyVehicle?.rendering_Type === undefined ? sensor_presets : MyVehicle?.rendering_Type,
        "monitor": cameraSensorFields.monitor,
        "dump_type_id": cameraSensorFields.dumpType,
        "sensor_raw_data": {
          "fov_x": 360
        }
      },
    }
    console.log("sensorCameraPayload", sensorCameraPayload)
    const sensorLidarPayload = {
      "lidar": {
        "type": "Lidar",
        "monitor": false,
        "name": 'Default Lidar',
        "pos_x": lidarTextFields.pos_x,
        "pos_y": lidarTextFields.pos_y,
        "pos_z": lidarTextFields.pos_z,
        "yaw": lidarTextFields.yaw,
        "roll": lidarTextFields.roll,
        "pitch": lidarTextFields.pitch,
        "sensor_raw_data": {
          "radius": lidarTextFields.radius,
          "fov_x": lidarTextFields.fov_x,
          "fov_y": lidarTextFields.fov_y,
          "channels": lidarTextFields.channels,
          "accuracy": lidarTextFields.accuracy,
          "sample_frequency": lidarTextFields.sample_frequency,
          "horizontal_frequency": lidarTextFields.horizontal_frequency,
        },
      }
    }
    console.log('sensorLidarPayload', sensorLidarPayload)
    if (name === "Camera") {
      ReduxUpdateMyVehicleJourney({ "sensorCameraPayload": sensorCameraPayload })
    }

    if (name === "Lidar") {
      ReduxUpdateMyVehicleJourney({ "sensorLidarPayload": sensorLidarPayload })
    }

    const payloadData = {
      "key": name,
      "camera": {
        "selected_camera_presets": [sensorObjectData.id],
        "cameraSensorFields": cameraSensorFields,
        "rendering_type": MyVehicle?.rendering_Type === undefined ? sensor_presets : MyVehicle?.rendering_Type,
        "monitor": cameraSensorFields.monitor,
        "dump_type_id": cameraSensorFields.dumpType,
        "sensor_raw_data": {
          "fov_x": 360
        }
      },
      "ground_truth": {
        "type": "GroundTruth",
        "monitor": false,
        "name": "GtSensor",
        "pos_x": 0,
        "pos_y": 1.29,
        "pos_z": 0.6,
        "roll": 0,
        "pitch": 0,
        "yaw": 0,
        "sensor_raw_data": {
          "radius": GTTextFields.radius
        }
      },
      "lidar": {
        "type": "Lidar",
        "monitor": false,
        "name": 'Default Lidar',
        "pos_x": lidarTextFields.pos_x,
        "pos_y": lidarTextFields.pos_y,
        "pos_z": lidarTextFields.pos_z,
        "roll": lidarTextFields.roll,
        "pitch": lidarTextFields.pitch,
        "yaw": lidarTextFields.yaw,
        "sensor_raw_data": {
          "fov_x": lidarTextFields.fov_x,
          "fov_y": lidarTextFields.fov_y,
          "channels": lidarTextFields.channels,
          "accuracy": lidarTextFields.accuracy,
          "sample_frequency": lidarTextFields.sample_frequency,
          "horizontal_frequency": lidarTextFields.horizontal_frequency,
          "radius": lidarTextFields.radius,
        },
      }
    }
    console.log('payloadData', payloadData)
    submitHandler(payloadData)
    handleClose()
  }

  const GTSensorHandler = (e) => {
    const { name, value } = e.target
    setGTTextFields((prev) => ({
			...prev,
			[name]: value,
		}));

    handleGTFields({
      ...GTTextFields,
      [name]: value,
    })
  }

  const lidarFieldsHandler = (e) => {
    const { name, value } = e.target;
    if (name === "lidar") {
      setLidarTextFields((prev) => ({
        ...prev,
        pos_x: value.pos_x,
        pos_y: value.pos_y,
        pos_z: value.pos_z,
        roll: value.roll,
        pitch: value.pitch,
        yaw: value.yaw,
        radius: value.radius,
      }));
    } else
      setLidarTextFields((prev) => ({
        ...prev,
        [name]: value,
      }));

    handleLidarsFields({
      ...lidarTextFields,
      [name]: value,
    });
  };

  const GTSensorData = () => {
    return (
      <Box className="rbs-left-cameraSensor-form" sx={{ overflowX: "auto" }}>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <TextField
              name="name"
              type="text"
              variant="outlined"
              label="Name"
              value={GTTextFields.name}
              onChange={GTSensorHandler}
              disabled
            />
          </Grid>
          {/* <Grid item xs={10} sx={{ mt: 1 }}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Position X"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({GTTextFields.pos_x})
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={GTTextFields.pos_x}
              onChange={(e, value) =>
                GTSensorHandler({ target: { name: "pos_x", value } })
              }
              min={0}
              max={100}
              step={1}
            />
          </Grid>
          <Grid item xs={10}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Position Y"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({GTTextFields.pos_y})
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={GTTextFields.pos_y}
              onChange={(e, value) =>
                GTSensorHandler({ target: { name: "pos_y", value } })
              }
              min={0}
              max={100}
              step={1}
            />
          </Grid>
          <Grid item xs={10}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Position Z"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({GTTextFields.pos_z})
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={GTTextFields.pos_z}
              onChange={(e, value) =>
                GTSensorHandler({ target: { name: "pos_z", value } })
              }
              min={0}
              max={100}
              step={1}
            />
          </Grid>
          <Grid item xs={10}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Roll"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({GTTextFields.roll})
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={GTTextFields.roll}
              onChange={(e, value) =>
                GTSensorHandler({ target: { name: "roll", value } })
              }
              min={0}
              max={100}
              step={1}
            />
          </Grid>
          <Grid item xs={10}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Pitch"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({GTTextFields.pitch})
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={GTTextFields.pitch}
              onChange={(e, value) =>
                GTSensorHandler({ target: { name: "pitch", value } })
              }
              min={0}
              max={100}
              step={1}
            />
          </Grid>
          <Grid item xs={10}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"YAW"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({GTTextFields.yaw})
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={GTTextFields.yaw}
              onChange={(e, value) =>
                GTSensorHandler({ target: { name: "yaw", value } })
              }
              min={0}
              max={100}
              step={1}
            />
          </Grid> */}
          <Grid item xs={10}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Radius"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({GTTextFields.radius} m)
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={GTTextFields.radius}
              onChange={(e, value) =>
                GTSensorHandler({ target: { name: "radius", value } })
              }
              min={30}
              max={120}
              step={10}
              disabled={false}
            />
          </Grid>
        </Grid>
        <div
          style={{
            marginTop: "2rem",
            marginBottom: "2rem",
            marginLeft: "68%",
          }}
        >
          <Button
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "white",
              fontSize: "13px",
            }}
            onClick={() => handleClose()}
          >
            Save
          </Button>
        </div>
      </Box>
    );
  };

  const lidarSensorData = () => {
    return (
      <>
        <Box className="rbs-left-cameraSensor-form" sx={{ overflowX: "auto" }}>
          <Grid container spacing={1} sx={{ mt: 2 }}>
            <Grid item xs={12}>
              <TextField
                name="name"
                type="text"
                variant="outlined"
                label="Name"
                value={lidarTextFields.name}
                onChange={(e, value) =>
                  lidarFieldsHandler({ target: { name: "name", value } })}
                sx={{ width: "90%" }}
                disabled
              />
            </Grid>
            <Grid item xs={10} sx={{ mt: 1 }}>
              <Typos.Body1 className={`d-flex align-items-end`}>
                {"Position X"}{" "}
                <Typos.Caption className={`ms-1`}>
                  ({lidarTextFields.pos_x} m)
                </Typos.Caption>
              </Typos.Body1>
              <Slider
                sx={{ marginLeft: "10px" }}
                value={lidarTextFields.pos_x}
                onChange={(e, value) =>
                  lidarFieldsHandler({ target: { name: "pos_x", value } })
                }
                min={-0.99}
                max={0.99}
                step={0.1}
              />
            </Grid>
            <Grid item xs={10} sx={{ mt: 1 }}>
              <Typos.Body1 className={`d-flex align-items-end`}>
                {"Position Y"}{" "}
                <Typos.Caption className={`ms-1`}>
                  ({lidarTextFields.pos_y} m)
                </Typos.Caption>
              </Typos.Body1>
              <Slider
                sx={{ marginLeft: "10px" }}
                value={lidarTextFields.pos_y}
                onChange={(e, value) =>
                  lidarFieldsHandler({ target: { name: "pos_y", value } })
                }
                min={0}
                max={4}
                step={0.1}
              />
            </Grid>
            <Grid item xs={10} sx={{ mt: 1 }}>
              <Typos.Body1 className={`d-flex align-items-end`}>
                {"Position Z"}{" "}
                <Typos.Caption className={`ms-1`}>
                  ({lidarTextFields.pos_z} m)
                </Typos.Caption>
              </Typos.Body1>
              <Slider
                sx={{ marginLeft: "10px" }}
                value={lidarTextFields.pos_z}
                onChange={(e, value) =>
                  lidarFieldsHandler({ target: { name: "pos_z", value } })
                }
                min={-7}
                max={7}
                step={0.1}
              />
            </Grid>
            <Grid item xs={10}>
              <Typos.Body1 className={`d-flex align-items-end`}>
                {"Roll"}{" "}
                <Typos.Caption className={`ms-1`}>
                  ({lidarTextFields.roll} deg)
                </Typos.Caption>
              </Typos.Body1>
              <Slider
                sx={{ marginLeft: "10px" }}
                value={lidarTextFields.roll}
                onChange={(e, value) =>
                  lidarFieldsHandler({ target: { name: "roll", value } })
                }
                min={0}
                max={360}
                defaultValue={0}
                step={5}
              />
            </Grid>
            <Grid item xs={10}>
              <Typos.Body1 className={`d-flex align-items-end`}>
                {"Pitch"}{" "}
                <Typos.Caption className={`ms-1`}>
                  ({lidarTextFields.pitch} deg)
                </Typos.Caption>
              </Typos.Body1>
              <Slider
                sx={{ marginLeft: "10px" }}
                value={lidarTextFields.pitch}
                onChange={(e, value) =>
                  lidarFieldsHandler({ target: { name: "pitch", value } })
                }
                min={0}
                max={360}
                defaultValue={0}
                step={5}
              />
            </Grid>
            <Grid item xs={10}>
              <Typos.Body1 className={`d-flex align-items-end`}>
                {"YAW"}{" "}
                <Typos.Caption className={`ms-1`}>
                  ({lidarTextFields.yaw} deg)
                </Typos.Caption>
              </Typos.Body1>
              <Slider
                sx={{ marginLeft: "10px" }}
                value={lidarTextFields.yaw}
                onChange={(e, value) =>
                  lidarFieldsHandler({ target: { name: "yaw", value } })
                }
                min={0}
                max={360}
                defaultValue={0}
                step={5}
              />
            </Grid>
            {/* <Grid item xs={10} sx={{ mt: 1 }}>
              <Typos.Body1 className={`d-flex align-items-end`}>
                {"Radius"}{" "}
                <Typos.Caption className={`ms-1`}>
                  ({lidarTextFields.radius})
                </Typos.Caption>
              </Typos.Body1>
              <Slider
                sx={{ marginLeft: "10px" }}
                value={lidarTextFields.radius}
                onChange={(e, value) =>
                  lidarFieldsHandler({ target: { name: "radius", value } })}
                min={0}
                max={10}
                step={0.1}
                // disabled={true}
              />
            </Grid> */}
            <Grid item xs={10}>
              <Typos.Body1 className={`d-flex align-items-end`}>
                {"Accuracy"}{" "}
                <Typos.Caption className={`ms-1`}>
                  ({lidarTextFields.accuracy} m)
                </Typos.Caption>
              </Typos.Body1>
              <Slider
                sx={{ marginLeft: "10px" }}
                value={lidarTextFields.accuracy}
                onChange={(e, value) =>
                  lidarFieldsHandler({ target: { name: "accuracy", value } })
                }
                min={0}
                max={0.03}
                step={0.01}
                // disabled={true}
              />
            </Grid>
            <Grid item xs={10}>
              <Typos.Body1 className={`d-flex align-items-end`}>
                {"Channels"}{" "}
                <Typos.Caption className={`ms-1`}>
                  ({lidarTextFields.channels})
                </Typos.Caption>
              </Typos.Body1>
              <Slider
                sx={{ marginLeft: "10px" }}
                value={lidarTextFields.channels}
                onChange={(e, value) =>
                  lidarFieldsHandler({ target: { name: "channels", value } })
                }
                min={16}
                max={32}
                step={16}
              />
            </Grid>
            <Grid item xs={10}>
              <Typos.Body1 className={`d-flex align-items-end`}>
                {"Vertical FoV"}{" "}
                <Typos.Caption className={`ms-1`}>
                  ({lidarTextFields.fov_y} degrees)
                </Typos.Caption>
              </Typos.Body1>
              <Slider
                sx={{ marginLeft: "10px" }}
                value={lidarTextFields.fov_y}
                onChange={(e, value) =>
                  lidarFieldsHandler({ target: { name: "fov_y", value } })
                }
                min={-30}
                max={30}
                step={1}
                disabled={false}
              />
            </Grid>
            <Grid item xs={10}>
              <Typos.Body1 className={`d-flex align-items-end`}>
                {"Horizontal FoV"}{" "}
                <Typos.Caption className={`ms-1`}>
                  ({lidarTextFields.fov_x} degrees)
                </Typos.Caption>
              </Typos.Body1>
              <Slider
                sx={{ marginLeft: "10px" }}
                value={lidarTextFields.fov_x}
                onChange={(e, value) =>
                  lidarFieldsHandler({ target: { name: "fov_x", value: 360 } })
                }
                min={0}
                max={360}
                step={10}
                disabled={false}
              />
            </Grid>
            <Grid item xs={10}>
              <Typos.Body1 className={`d-flex align-items-end`}>
                {"Horizontal Frequency"}{" "}
                <Typos.Caption className={`ms-1`}>
                  ({lidarTextFields.horizontal_frequency} Hz)
                </Typos.Caption>
              </Typos.Body1>
              <Slider
                sx={{ marginLeft: "10px" }}
                value={lidarTextFields.horizontal_frequency}
                onChange={(e, value) =>
                  lidarFieldsHandler({
                    target: { name: "horizontal_frequency", value: 25 },
                  })
                }
                min={5}
                max={25}
                step={5}
                disabled={false}
              />
            </Grid>
            {/* <Grid item xs={10}>
              <Typos.Body1 className={`d-flex align-items-end`}>
                {"Sample Frequency"}{" "}
                <Typos.Caption className={`ms-1`}>
                  ({lidarTextFields.sample_frequency})
                </Typos.Caption>
              </Typos.Body1>
              <Slider
                sx={{ marginLeft: "10px" }}
                value={lidarTextFields.sample_frequency}
                onChange={(e, value) =>
                  lidarFieldsHandler({
                    target: { name: "sample_frequency", value },
                  })
                }
                min={0}
                max={5000}
                step={10}
                // disabled={true}
              />
            </Grid> */}
          </Grid>
          <div
            style={{
              marginTop: "2rem",
              marginBottom: "2rem",
              marginLeft: "68%",
            }}
          >
            <Button
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.main",
                },
                color: "white",
                fontSize: "13px",
              }}
              onClick={handlePayload}
            >
              Save
            </Button>
          </div>
        </Box>
      </>
    );
  }



  const cameraSensorHandler = (e) => {
    const { name, value } = e.target;
    console.log('name', name,value)
    if (name === "camera_preset") {
      setCameraSensorFields((prev) => ({
        ...prev,
        key: value.sensor_id,
        camera_preset: value.name,
        roll: value.rot_z,
        pitch: value.rot_x,
        yaw: value.rot_y,
        pos_x: parseFloat(value.pos_x),
        pos_y: parseFloat(value.pos_y),
        pos_z: parseFloat(value.pos_z),
      }));
    } else
      setCameraSensorFields((prev) => ({
        ...prev,
        [name]: parseFloat(value),
      }));

    handleCameraSensorFields({
      ...cameraSensorFields,
      [name]: parseFloat(value),
    });
  };

  const CameraSensorData = () => {
    return (
      <Box className="rbs-left-cameraSensor-form" sx={{ overflowX: "auto" }}>
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <TextField
              name="name"
              type="text"
              variant="outlined"
              label="Name"
              value={cameraSensorFields.name}
              onChange={cameraSensorHandler}
              sx={{ width: "90%" }}
              disabled
            />
          </Grid>
          {/* <Grid item xs={12} sx={{ mt: 1 }}>
            <TextField
              select
              name="monitor"
              variant="outlined"
              label="Select Monitor Value"
              value={cameraSensorFields}
              onChange={cameraSensorHandler}
              sx={{ width: "90%" }}
              SelectProps={{
                MenuProps: {
                  className: "customDropdown",
                },
              }}
            >
              {[
                { id: 0, value: "true" },
                { id: 1, value: "false" },
              ].map((option) => (
                <MenuItem key={option.id} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
          {/* <Grid item xs={12} sx={{ mt: 1 }}>
            <TextField
              select
              name="dumpType"
              variant="outlined"
              label="Select Dump Types"
              value={cameraSensorFields.dumpType}
              onChange={cameraSensorHandler}
              sx={{ width: "90%" }}
              SelectProps={{
                MenuProps: {
                  className: "customDropdown",
                },
              }}
            >
              {cameraSensorDumpTypes.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
          <Grid item xs={10} sx={{ mt: 1 }}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Position X"}{""}
              <Typos.Caption className={`ms-1`}>
                ({cameraSensorFields.pos_x} m)
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={cameraSensorFields.pos_x}
              onChange={(e, value) =>
                cameraSensorHandler({ target: { name: "pos_x", value } })
              }
              min={-2}
              max={2}
              step={0.1}
            />
          </Grid>
          <Grid item xs={10}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Position Y"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({cameraSensorFields.pos_y} m)
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={cameraSensorFields.pos_y}
              onChange={(e, value) =>
                cameraSensorHandler({ target: { name: "pos_y", value } })
              }
              min={0}
              max={4}
              step={0.1}
            />
          </Grid>
          <Grid item xs={10}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Position Z"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({cameraSensorFields.pos_z} m)
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={cameraSensorFields.pos_z}
              onChange={(e, value) =>
                cameraSensorHandler({ target: { name: "pos_z", value } })
              }
              min={-7}
              max={7}
              step={0.1}
            />
          </Grid>
          <Grid item xs={10}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Roll"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({cameraSensorFields.roll} deg)
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={cameraSensorFields.roll}
              onChange={(e, value) =>
                cameraSensorHandler({ target: { name: "roll", value } })
              }
              min={0}
              max={360}
              defaultValue={0}
              step={5}
            />
          </Grid>
          <Grid item xs={10}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"Pitch"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({cameraSensorFields.pitch} deg)
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={cameraSensorFields.pitch}
              onChange={(e, value) =>
                cameraSensorHandler({ target: { name: "pitch", value } })
              }
              min={0}
              max={360}
              defaultValue={0}
              step={5}
            />
          </Grid>
          <Grid item xs={10}>
            <Typos.Body1 className={`d-flex align-items-end`}>
              {"YAW"}{" "}
              <Typos.Caption className={`ms-1`}>
                ({cameraSensorFields.yaw} deg)
              </Typos.Caption>
            </Typos.Body1>
            <Slider
              sx={{ marginLeft: "10px" }}
              value={cameraSensorFields.yaw}
              onChange={(e, value) =>
                cameraSensorHandler({ target: { name: "yaw", value } })
              }
              min={0}
              max={360}
              defaultValue={0}
              step={5}
            />
          </Grid>
        </Grid>
        {/* <Box sx={{ ml: 1, display: "flex", alignItems: "baseline" }}>
          <InputLabel sx={{ mb: 2, color: "white" }}>
            Rendering Types
          </InputLabel>
          <IconButton aria-label="edit" sx={{ ml: 5, mt: 2 }}>
            <EditIcon onClick={() => setOpen(true)} />
          </IconButton>
        </Box> */}
        <div style={{ marginTop: "2rem", marginBottom: "2rem", marginLeft: "70%" }}>
          <Button
            sx={{
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "primary.main",
              },
              color: "white",
              fontSize: "13px",
            }}
            onClick={handlePayload}
          >
            Save
          </Button>
        </div>
      </Box>
    );

  }




    return (
      <Box>
          {/* <CardContent className="rbs-left-3D-panel"> */}
            <form onSubmit={handlePayload}>
              {
                name === 'Camera' ? CameraSensorData() : 
                name === 'Lidar' ? lidarSensorData() : GTSensorData()
              }
              
            </form>
          {/* </CardContent> */}

        <AddCameraSensorModal 
          open={open} 
          close={closeHandler} 
          // width={'28%'} 
          heading={'Rendering Types'} 
          subheading={'Edit Rendering Type Values'}
        >
          <RenderingTypeForm
            handleClose={closeHandler}
            sensorObjectData={sensorObjectData}
            renderingType={handleRenderingType}
          />
        </AddCameraSensorModal>
      </Box>
    );
}
export default connect(null, { ReduxUpdateMyVehicleJourney })(DVehicleForm);