import { Box, Card} from "@mui/material";
import React from "react";
import MainLogo from 'assets/images/main-logo.png'
import logo from 'assets/images/aai-logo.png'
import { useNavigate } from "react-router-dom";

const styles = {
    columns: {
        minHeight: '100vh'
    }
}


export default function AuthWrapper({children}) {
    const navigator = useNavigate();
    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-12 col-12 d-flex flex-column justify-content-center" style={styles.columns}>
                        <Card className={`mx-lg-3`} sx={{ background: 'none', textAlign: 'center' }} elevation={0}>
                            <Box
                                component="img"
                                sx={{
                                    position: 'absolute',
                                    left: 20,
                                    top: 20,
                                    cursor:'pointer'
                                }}
                                src={logo}
                                onClick={() => navigator('/auth')}

                            />
                            <Box
                                component="img"
                                sx={{
                                    width: '20em',
                                }}
                                alt="Logo"
                                src={MainLogo}
                            />
                          {children}
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}
