import { Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, TextField } from "@mui/material";
import { ButtonX } from "components";
import React, { useEffect, useState } from "react";
import STORE from "services/redux-store/store";
import execute from "assets/images/exe.png";
import { useNavigate } from "react-router-dom";
import { putScenarioExecutionList } from "endpointServices/scenarioExecutionServices";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { weatherConditionsCategory, weatherSeverityConditionsCategory } from "utils";
import { getCosimClients, triggerLowFidelityDag } from "endpointServices/lowFidelityServices";
import { ReduxUpdateLowFidelityJourney } from "services/redux-store/actions/actions-lowFidelity";
import { connect } from "react-redux";

const LowFidelityScenarios = ({ ReduxLowFidelity, ReduxUpdateLowFidelityJourney }) => {
  const navigate = useNavigate();

  const { LowFidelity } = STORE.getState();
  const { Projects } = STORE.getState();

  const [isFetching, setFetching] = useState(false);
  const [client, setClient] = useState("");
  const [selectClient, setSelectClient] = useState([]);
  const [selectWeatherConditions, setSelectWeatherConditions] = useState([]);
  const [selectWeatherSeverityConditions, setSelectWeatherSeverityConditions] = useState([]);
  const [time, setTime] = useState("");
  const [checkDesktopMachine, setCheckDesktopMachine] = useState(true);
  const [isTimeValid, setTimeValid] = useState(false);
  const [allFieldsFilled, setAllFieldsFilled] = useState(false);
  const [streamDump, setStreamDump] = useState(true);
  const [uploadPlanningModule, setUploadPlanningModule] = useState(false);
  const [uploadPerceptionModule, setUploadPerceptionModule] = useState(false);
  const [uploadVisualizaion, setUploadVisualizaion] = useState(true);
  const [visualizaion, setVisualizaion] = useState(true);

  useEffect(() => {
    getClientsList();
  }, []);

  useEffect(() => {
    checkFields();
  }, [
    // selectWeatherConditions,
    // selectWeatherSeverityConditions,
    // time,
    checkDesktopMachine,
    client,
  ]);

  const onSaveData = async () => {
    setFetching(true);
    const payload = {
      pralion_id: LowFidelity?.pralionId,
      test_vehicle_id: LowFidelity?.vehicleProperties?.id,
      test_suite_id: LowFidelity?.vehicleSuits?.application_version_id,
      observer_id: LowFidelity?.lowFidelityObserverId,
      desktop_client_id: client,
      // environment: {
      //   time_of_day: ReduxLowFidelity?.time,
      //   precipitation: ReduxLowFidelity?.precipitation,
      //   precipitation_rate: ReduxLowFidelity?.precipitationRate,
      // },
      // items_to_process: {
      //   osi3: streamDump,
      //   planning_ros2_bag: uploadPlanningModule,
      //   perception_ros2_bag: uploadPerceptionModule,
      //   aai_ros1_viz: visualizaion,
      // },
    };
    console.log("payload", payload);
    const response = await putScenarioExecutionList(payload);
    if (response?.status === 200) {
      const projectId = Projects.projectId;
      const secondPayload = {
        pralion_id: LowFidelity?.pralionId,
      } 
      const secondResponse = await triggerLowFidelityDag(secondPayload);
      if (secondResponse?.status === 200) {
        toast.success("Successfuly started execution-setting generate");
        setFetching(false);
        setTimeout(() => {
          navigate(`/projects/${projectId}/applications/low-fidelity`);
        }, 3000);

      }else {
        setFetching(false);
        toast.error(
          `Error! Failed to start execution-setting. Please see the logs on low-fidelity project listing page.`
        );
      }
     
    } else {
      const errr = response?.response?.data?.message;
      console.log("errr", errr);
      toast.error(`Error! Failed to start low-fidelity. Please see the logs`);
      setFetching(false);
    }
  };

  const handleDateTimeChange = (field, value) => {
    const formattedTime = dayjs(value).format("HH:mm");
    setTime(formattedTime);
    ReduxUpdateLowFidelityJourney({ time: formattedTime });

    // Validate the time input
    const [hours, minutes] = formattedTime.split(":");
    if (minutes === "00") {
      setTimeValid(true);
    } else {
      setTimeValid(false);
    }
  };

  const handleWeatherConditions = (event) => {
    const { value } = event.target;
    setSelectWeatherConditions(value);
    ReduxUpdateLowFidelityJourney({ precipitation: value });
  };

  const handleWeatherSeverityConditions = (event) => {
    const { value } = event.target;
    setSelectWeatherSeverityConditions(value);
    ReduxUpdateLowFidelityJourney({ precipitationRate: value });
  };

  const handleDesktopChange = () => {
    const value = !checkDesktopMachine;
    setCheckDesktopMachine(value);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    console.log("value", value);
    setClient(value);
    ReduxUpdateLowFidelityJourney({ desktopClientId: value });
  };

  const getClientsList = async () => {
    const response = await getCosimClients();
    console.log("response", response);
    if (response?.status === 200) {
      const list = response.data.data || [];
      setSelectClient(list);
    } else {
      console.log("error");
    }
  };

  const checkFields = () => {
    if (
      // selectWeatherConditions.length &&
      // selectWeatherSeverityConditions.length &&
      // time &&
      !checkDesktopMachine 
      &&
      client
    ) {
      setAllFieldsFilled(true);
    } else {
      setAllFieldsFilled(false);
    }
  };

  const handleStreamDumpCheckboxChange = () => {
    const value = !streamDump;
    setStreamDump(value);
  };

  const handleUploadPlanningModuleCheckboxChange = () => {
    const value = !uploadPlanningModule;
    setUploadPlanningModule(value);
  };

  const handleUploadPerceptionModuleCheckboxChange = () => {
    const value = !uploadPerceptionModule;
    setUploadPerceptionModule(value);
  };

  const handleVisualizaionCheckboxChange = () => {
    const value = !visualizaion;
    setVisualizaion(value);
  };

  return (
    <div>
      <Grid item sx={{ display: "flex", justifyContent: "end" }}>
        <Button
          sx={{
            backgroundColor: "primary.main",
            "&:hover": {
              backgroundColor: "primary.main",
            },
            color: "text.main",
            fontWeight: "bold",
            marginRight: "10px",
            textTransform: "none",
            fontSize: "inherit",
          }}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <ButtonX onClick={onSaveData} style={{ textTransform: "none" }} disabled={
          isFetching || 
          !allFieldsFilled 
          // || !isTimeValid
          }>
          Execute
          {isFetching ? 
            <CircularProgress
              size={20}
              style={{ marginLeft: "8px" }}
              color="inherit"
            />
           : 
            ""
          }
        </ButtonX>
      </Grid>
      <div>
        <Divider style={{ marginTop: "1rem" }} />
      </div>
      <Grid
        container
        sx={{
          marginTop: "2rem",
        }}
      >
        <span>
          {/* <div style={{ position: 'absolute', top: '50%', left: '45%' }}>
            <p>To begin, press the "Execute" button and check</p>
            <p style={{ textAlign: 'center' }}>the results in the corresponding application</p>
          </div> */}
          <img
            src={execute}
            style={{ width: "100%", height: "40rem", marginBottom: 0 }}
            alt="icons"
          />
          <div className={`low-execution-setting-logs-sidebar `}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h5 className={"execution-setting-h5"}>Execution Setting</h5>
              {/* <CloseIcon
                cursor="pointer"
                sx={{
                  position: "absolute",
                  // top: "3.5rem",
                  right: "2.5rem",
                  fontSize: "1.5rem",
                }}
              /> */}
            </div>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                sx={{ marginLeft: "10px", width: "29rem", marginTop: "1rem" }}
                value={time ? dayjs(time, "HH:mm") : null}
                ampm={false}
                minutesStep={60}
                onChange={(newValue) => handleDateTimeChange("time", newValue)}
                label="Time of Day"
                disabled={true}
              />
            </LocalizationProvider>
            <FormControl
              sx={{ marginLeft: "10px", width: "14rem", marginTop: "1rem" }}
            >
              <TextField
                label="Weather Condition"
                name="weather condition"
                variant="outlined"
                value={selectWeatherConditions}
                onChange={handleWeatherConditions}
                fullWidth
                disabled={true}
                select
                SelectProps={{
                  MenuProps: {
                    className: "customDropdown",
                  },
                }}
              >
                {weatherConditionsCategory.map((item, id) => (
                  <MenuItem key={id} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <FormControl
              sx={{ marginLeft: "10px", width: "14.3rem", marginTop: "1rem" }}
            >
              <TextField
                label="Weather Severity Condition"
                name="weather severity condition"
                variant="outlined"
                value={selectWeatherSeverityConditions}
                onChange={handleWeatherSeverityConditions}
                fullWidth
                disabled={true}
                select
                SelectProps={{
                  MenuProps: {
                    className: "customDropdown",
                  },
                }}
              >
                {weatherSeverityConditionsCategory.map((item, id) => (
                  <MenuItem key={id} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>

            <h6 className="execution-setting-h6">Test Execution Environment</h6>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <Grid xs={6}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Cloud"
                    disabled
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={checkDesktopMachine}
                        onChange={handleDesktopChange}
                      />
                    }
                    label="Desktop Machines"
                  />
                </FormGroup>
              </Grid>
              <Grid xs={6}>
                <FormControl
                  sx={{ width: "14rem", marginTop: "2rem" }}
                >
                  <TextField
                    labelId="demo-simple-select-label"
                    disabled={checkDesktopMachine}
                    id="demo-simple-select"
                    type="select"
                    size="small"
                    variant="outlined"
                    value={client}
                    onChange={handleChange}
                    sx={{
                      border: "1px solid #00A3B5",
                      backgroundColor: "#24262b",
                    }}
                    fullWidth
                    select
                    SelectProps={{
                      MenuProps: {
                        className: "customDropdown",
                      },
                    }}
                  >
                    {selectClient.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.client_name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </FormControl>
              </Grid>
            </div>         
            <h6 className="execution-setting-h6">
              Data Processing - RR Cosim Client
            </h6>
            <div
              style={{
                marginLeft: "10px",
              }}
            >
              <FormGroup>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <FormControlLabel
                      sx={{ display: "block" }}
                      control={
                        <Checkbox
                          checked={streamDump}
                          onChange={handleStreamDumpCheckboxChange}
                          disabled
                        />
                      }
                      label="OSI3 Stream Dump"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={uploadPlanningModule}
                          onChange={handleUploadPlanningModuleCheckboxChange}
                          disabled
                        />
                      }
                      label={
                        <div style={{ marginTop: "21px" }}>
                          Bag Dump for Planning Module
                        </div>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                  <FormControlLabel
                      sx={{ marginTop: "-14px" }}
                      control={
                        <Checkbox
                          checked={uploadPerceptionModule}
                          onChange={handleUploadPerceptionModuleCheckboxChange}
                          disabled
                        />
                      }
                      label={
                        <div style={{ marginTop: "21px" }}>
                          Bag Dump for Perception Module
                        </div>
                      }
                    />
                    <FormControlLabel
                      sx={{ marginTop: "-14px" }}
                      control={
                        <Checkbox
                          checked={visualizaion}
                          onChange={handleVisualizaionCheckboxChange}
                          disabled
                        />
                      }
                      label={
                        <div style={{ marginTop: "21px" }}>
                          Bag Dump for AAI Visualizaion
                        </div>
                      }
                    />
                  </Grid>
                </Grid>
              </FormGroup>
            </div>
          </div>
        </span>
      </Grid>
    </div>
  );
}

function getStateToProps(state) {
  return {
    ReduxLowFidelity: state.LowFidelity,
  };
}

export default connect(getStateToProps, { ReduxUpdateLowFidelityJourney })(
  LowFidelityScenarios
);